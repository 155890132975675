import { useEffect } from 'react';
export const isEventInBounds = function (e, ref) {
  let offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const {
    clientX,
    clientY
  } = e || {};
  const ref_ = typeof ref === 'function' ? ref() : ref;
  if (ref_.current && typeof clientX === 'number' && typeof clientY === 'number') {
    const {
      bottom,
      top,
      left,
      right
    } = ref_.current.getBoundingClientRect();
    return top - offset < clientY && bottom + offset > clientY && left - offset < clientX && right + offset > clientX;
  } else {
    return false;
  }
};
export const useClickOutside = function (refs, callback) {
  let attachHandler = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  useEffect(() => {
    let mouseDownWasOutside = false;
    const handleMouseUp = e => {
      if (mouseDownWasOutside && refs.every(ref => !isEventInBounds(e, ref))) {
        requestAnimationFrame(() => callback?.());
        mouseDownWasOutside = false;
      }
    };

    // Since the click handler is attached to the document, it will be triggered
    // even if the click was initiated inside the element and finished outside,
    // this way, we check the mousedown was executed outside.
    const handleMouseDown = e => {
      mouseDownWasOutside = refs.every(ref => !isEventInBounds(e, ref));
    };
    if (attachHandler) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [attachHandler, callback, refs]);
};