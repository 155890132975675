import { useQuery } from './useQuery';
export function useNodesQuery(query, _ref) {
  let {
    ids,
    fallbackToPreviousData,
    ...options
  } = _ref;
  const {
    data,
    previousData,
    ...rest
  } = useQuery(query, {
    variables: {
      ids
    },
    ...options
  });
  const dataTarget = (() => {
    if (!data && fallbackToPreviousData) return previousData;
    return data;
  })();
  const nodes = dataTarget?.nodes;
  return {
    nodes: nodes || null,
    ...rest
  };
}