import "core-js/modules/es.array.push.js";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import without from 'lodash/without';
import { useMemo } from 'react';
import { getTint } from '@clubhouse/shared/color';
import { NONE_VALUE } from '@clubhouse/shared/constants';
import { getFieldValueIcon } from '@clubhouse/shared/utils/customFields';
import { Select, SelectOptionAllNone, SelectOptionChecked, SelectOptionDefault, SelectOptionDivider, SelectOptionSubLabelWithSelectAll } from '../Select';
import { CustomFieldsSelectFilterTarget } from './CustomFieldsSelectFilterTarget';
import { jsx as ___EmotionJSX } from "@emotion/react";
const getValueSelectItem = _ref => {
  let {
    field,
    value,
    isSelected
  } = _ref;
  return {
    Icon: getFieldValueIcon(field, value.id),
    iconColor: getTint(value.color_key),
    value: String(value.id),
    name: value.value,
    additionalSearchTerms: [value.value, field.name],
    Component: isSelected ? SelectOptionDefault : SelectOptionChecked
  };
};
const getFieldHeadingItem = (field, selectedValues, onChange) => {
  const enabledValues = field.values.filter(_ref2 => {
    let {
      enabled
    } = _ref2;
    return enabled !== false;
  });
  return {
    isSearchOnly: false,
    additionalSearchTerms: [field.name, ...enabledValues.map(_ref3 => {
      let {
        value
      } = _ref3;
      return value;
    })],
    name: field.name,
    Component: props => ___EmotionJSX(SelectOptionSubLabelWithSelectAll, _extends({}, props, {
      sectionValues: field.values.map(_ref4 => {
        let {
          id
        } = _ref4;
        return id;
      }),
      selectedValues: selectedValues,
      onChange: onChange
    })),
    key: `section-${field.name}`
  };
};
export const CustomFieldsSelect = _ref5 => {
  let {
    selectedValues,
    fields,
    onChange,
    TargetComponent = CustomFieldsSelectFilterTarget,
    isOpen
  } = _ref5;
  const items = useMemo(() => {
    const items = [{
      value: 'ALL',
      name: 'All Fields & Values',
      Component: SelectOptionAllNone,
      onClick: () => onChange([])
    }, {
      value: NONE_VALUE,
      name: 'No Fields or Values',
      Component: SelectOptionAllNone
    }];
    for (const field of fields) {
      items.push({
        Component: SelectOptionDivider,
        key: `divider-${field.name}`
      });
      items.push(getFieldHeadingItem(field, selectedValues, onChange));
      items.push({
        value: `NONE:${field.id}`,
        name: `No ${field.name}`,
        Component: SelectOptionAllNone
      });
      const enabledValues = field.values.filter(_ref6 => {
        let {
          enabled
        } = _ref6;
        return enabled !== false;
      });
      for (const value of enabledValues) {
        const isSelected = selectedValues.includes(value.value);
        items.push(getValueSelectItem({
          field,
          value,
          isSelected
        }));
      }
    }
    return items;
  }, [fields, selectedValues, onChange]);
  const handleChange = values => {
    onChange(without(values, 'ALL'));
  };
  return ___EmotionJSX(Select, {
    isOpen: isOpen,
    items: items,
    TargetComponent: TargetComponent,
    selectedValues: selectedValues.length < 1 ? ['ALL'] : selectedValues,
    onChange: handleChange,
    isSearchable: true,
    hideClose: true
  });
};
CustomFieldsSelect.displayName = "CustomFieldsSelect";