import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ConfirmationDialog(_ref) {
  let {
    buttonKind,
    onClick,
    cta,
    title,
    body,
    onClose,
    visible
  } = _ref;
  return ___EmotionJSX(Dialog, {
    ActionComponent: _ref2 => {
      let {
        onClose
      } = _ref2;
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: onClose
      }, "Cancel"), ___EmotionJSX(Button, {
        kind: buttonKind,
        onClick: onClick
      }, cta));
    },
    onClose: onClose,
    visible: visible
  }, ___EmotionJSX(DialogTitle, null, title), ___EmotionJSX(DialogText, null, body));
}
ConfirmationDialog.displayName = "ConfirmationDialog";