import * as Sentry from '@sentry/browser';
export const startNavigationTransaction = _ref => {
  let {
    pattern,
    tags
  } = _ref;
  try {
    const sentryClient = Sentry.getClient();
    if (!sentryClient) {
      return;
    }
    Sentry.startBrowserTracingNavigationSpan(sentryClient, {
      op: 'navigation',
      name: pattern ?? '__PATTERN_NOT_FOUND__',
      attributes: {
        [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route'
      },
      tags
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};