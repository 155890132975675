import { StoryGroupByTechnicalAreaFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { TechnicalAreaWithIcon } from 'components/gql/technicalArea/TechnicalAreaWithIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByTechnicalAreaFragment = StoryGroupByTechnicalAreaFragmentFragmentDoc;
export const TechnicalAreaGroupHeader = _ref => {
  let {
    technicalArea
  } = _ref;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.TechnicalArea
  }, typeof technicalArea?.stringValue === 'string' && typeof technicalArea?.colorKey === 'string' ? ___EmotionJSX(TechnicalAreaWithIcon, {
    spacing: 12,
    name: technicalArea.stringValue,
    color: technicalArea.colorKey
  }) : 'No Technical Area');
};
TechnicalAreaGroupHeader.displayName = "TechnicalAreaGroupHeader";