import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '@clubhouse/shared/colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Feature" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Certificate = _ref => {
  let {
    fill = COLORS.YELLOW45,
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    fill: fill,
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Feature"
  }));
};
Certificate.displayName = "Certificate";