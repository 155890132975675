import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const WorkflowState = _ref => {
  let {
    columnName,
    storyCount,
    workflowName,
    children
  } = _ref;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 4
  }, ___EmotionJSX("div", null, ___EmotionJSX(Text, {
    el: "span",
    size: Text.SIZE.XSMALL,
    bold: true
  }, ___EmotionJSX(Emojify, null, columnName)), ' ', ___EmotionJSX(Text, {
    el: "span",
    color: Text.COLOR.LIGHT,
    size: Text.SIZE.XSMALL,
    bold: true
  }, "(", storyCount, ")"), !!workflowName && ___EmotionJSX(Text, {
    el: "span",
    color: Text.COLOR.LIGHT,
    size: Text.SIZE.XSMALL
  }, ___EmotionJSX("em", null, ' ', ___EmotionJSX(Emojify, null, workflowName)))), children);
};
WorkflowState.displayName = "WorkflowState";