import { isLoggedInPage } from 'utils/is';
import { FEATURE_TOGGLES, useVariation } from 'utils/launch-darkly';
import { notRouteException, usePagePathname } from 'utils/navigation';
import { ComponentErrorBoundary } from '../../ComponentErrorBoundary';
import { DockComponent } from './DockComponent';
import { DockComponentV2 } from './DockComponentV2';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Dock = () => {
  const {
    value: isUpdatesEndpointEnabled
  } = useVariation(FEATURE_TOGGLES.DATALAYER_UPDATES_ENDPOINT);
  const pathname = usePagePathname();
  return ___EmotionJSX(ComponentErrorBoundary, {
    componentName: "Dock"
  }, pathname && isLoggedInPage() && notRouteException(pathname) ? isUpdatesEndpointEnabled ? ___EmotionJSX(DockComponentV2, null) : ___EmotionJSX(DockComponent, null) : null);
};
Dock.displayName = "Dock";