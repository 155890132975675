import { StoryNameFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { getHref } from 'components/gql/stories/links';
import { TableNameText, TableNameWithLabelsAndBlockers } from 'components/shared/table/base';
import { NameField as SharedNameField } from 'components/shared/table/fields';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryNameFieldFragment = StoryNameFieldFragmentFragmentDoc;
const NameFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const {
    publicId,
    name
  } = entity;
  const url = getHref(publicId, name);
  return ___EmotionJSX(TableNameWithLabelsAndBlockers, {
    url: url
  }, ___EmotionJSX(TableNameText, {
    text: name
  }));
};
NameFieldComponent.displayName = "NameFieldComponent";
export const NameField = () => SharedNameField({
  Component: NameFieldComponent,
  width: 330,
  sort: true
});