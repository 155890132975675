import { ConfirmationDialogProvider } from '@clubhouse/shared/components/ConfirmationDialog/ConfirmationDialogProvider';
import { ContextProvider } from './Context';
import { ArchivedMessageBar } from './components/ArchivedMessageBar';
import { BodyAndSidebar } from './components/BodyAndSidebar';
import { BreadcrumbsAndActions } from './components/BreadcrumbsAndActions';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import { PrimaryButton } from './components/PrimaryButton';
import { SecondaryButton } from './components/SecondaryButton';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EntityPage = _ref => {
  let {
    children,
    isLoading,
    type
  } = _ref;
  return ___EmotionJSX(ContextProvider, {
    isLoading: isLoading,
    type: type
  }, ___EmotionJSX(ConfirmationDialogProvider, null, children, ___EmotionJSX(ConfirmationDialog, null)));
};
EntityPage.displayName = "EntityPage";
EntityPage.ArchivedMessageBar = ArchivedMessageBar;
EntityPage.BodyAndSidebar = BodyAndSidebar;
EntityPage.BreadcrumbsAndActions = BreadcrumbsAndActions;
EntityPage.PrimaryButton = PrimaryButton;
EntityPage.SecondaryButton = SecondaryButton;