import { useCallback, useEffect } from 'react';
import { isRightClick } from '@clubhouse/shared/utils/mouse';
import { FloatingElement } from '../FloatingElement';
import { useActiveContextMenu } from './useActiveContextMenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
const createRect = function (x, y) {
  let w = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let h = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  return {
    x,
    y,
    left: x,
    top: y,
    right: x + w,
    bottom: y + h,
    width: w,
    height: h
  };
};
const FloatingMenu = _ref => {
  let {
    trigger,
    children,
    onClose
  } = _ref;
  useEffect(() => {
    const onMouseDown = e => {
      if (isRightClick(e)) {
        onClose();
      }
    };
    document.body.addEventListener('mousedown', onMouseDown, {
      capture: true
    });
    return () => {
      document.body.removeEventListener('mousedown', onMouseDown, {
        capture: true
      });
    };
  }, [onClose]);
  return ___EmotionJSX(FloatingElement, {
    trigger: trigger,
    offsetMainAxis: 5,
    onClose: onClose
  }, children);
};
FloatingMenu.displayName = "FloatingMenu";
export function ContextMenuRoot() {
  const {
    oy,
    ox,
    Menu,
    target,
    clickTarget,
    close,
    onEvent
  } = useActiveContextMenu();
  const trigger = useCallback(() => ({
    getBoundingClientRect() {
      const rect = target?.getBoundingClientRect();
      if (!rect) return createRect(0, 0);
      return createRect(rect.x + (ox ?? 0), rect.y + (oy ?? 0));
    },
    contextElement: target || undefined
  }), [target, ox, oy]);
  const handleClose = useCallback(() => {
    onEvent?.('close');
    close();
  }, [close, onEvent]);
  return Menu ? ___EmotionJSX(FloatingMenu, {
    trigger: trigger,
    onClose: handleClose
  }, ___EmotionJSX(Menu, {
    rootElement: target,
    clickedElement: clickTarget
  })) : null;
}