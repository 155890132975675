import { EventNamesEnum } from '@useshortcut/events';
import { getAllFeatureToggles } from '@clubhouse/feature-toggles';
import { isPlaywrightTestEnv } from '../../isPlaywrightTestEnv';
import { SEGMENT_EVENTS } from '../ownedEvents';
import { NOOP_MONITOR, getNamedEventGlobalAttributes, initializeAsync } from '../utils';
import { getUserParameters } from './segment';
export const NAME = 'Chameleon';
export const shouldInitialize = _ref => {
  let {
    env
  } = _ref;
  if (isPlaywrightTestEnv() || env === 'test') {
    return false;
  }
  return true;
};
export const shouldOwnEvent = event => SEGMENT_EVENTS.has(event);
export const shouldOwnNamedEvent = event => Object.values(EventNamesEnum).includes(event);
export const initialize = (_, _ref2) => {
  let {
    onError
  } = _ref2;
  const {
    callWhenReady,
    triggerInitialization
  } = initializeAsync(async attrs => {
    await loadDeps();
    setSessionAttributes(attrs);
  }, {
    triggerManually: true,
    onError
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes: attrs => {
      triggerInitialization(attrs);
    },
    logEvent: callWhenReady(sendEvent),
    logNamedEvent: callWhenReady(logNamedEvent)
  };
};
let sessionAttributes;
const setSessionAttributes = attrs => {
  const {
    userId
  } = attrs;
  const userParams = getUserParameters(attrs);
  sessionAttributes = {
    userId,
    ...userParams
  };
  if (!userId) throw new Error('Chameleon integration requires a "userId" and it is missing! Please fix otherwise this event will not track properly');
  const feature_toggles = getAllFeatureToggles();
  identify(userId, {
    ...userParams,
    feature_toggles
  });
};
const loadDeps = async () => {
  await import( /* webpackChunkName: "chameleon" */'static/lib/chameleon');
};
const identify = (userId, attrs) => window.chmln?.identify(userId, attrs);
const sendEvent = async (eventName, attrs) => {
  return new Promise(resolve => {
    if (sessionAttributes.userId && SEGMENT_EVENTS.has(eventName)) {
      window.chmln?.track(eventName, {
        ...attrs,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    }
  });
};
const logNamedEvent = async (eventName, data) => {
  return new Promise(resolve => {
    const sendEvent = async (eventName, data) => {
      window.chmln?.track(eventName, {
        ...data,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    };
    if (sessionAttributes.userId) {
      const namedEventGlobalAttrs = getNamedEventGlobalAttributes(eventName, {
        organizationId: sessionAttributes.organizationId,
        workspaceId: sessionAttributes.workspaceId,
        userId: sessionAttributes.userId
      });
      sendEvent(eventName, {
        ...data,
        ...namedEventGlobalAttrs
      });
    }
  });
};