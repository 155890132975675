import { DisplayableNameFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DisplayableNameFragment = DisplayableNameFragmentDoc;
export const DisplayableName = _ref => {
  let {
    displayable
  } = _ref;
  return ___EmotionJSX(Emojify, null, displayable.displayableName);
};
DisplayableName.displayName = "DisplayableName";