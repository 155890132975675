import { Radio as RadioDefault } from './default';
import { Radio as RadioOutline } from './outline';
import { KIND } from './types';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Radio = props => {
  const {
    kind = KIND.DEFAULT
  } = props;
  if (kind === KIND.OUTLINE) {
    return ___EmotionJSX(RadioOutline, props);
  } else {
    return ___EmotionJSX(RadioDefault, props);
  }
};
Radio.KIND = KIND;