import { NavigationAccordion } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TeamItem(_ref) {
  let {
    children,
    id,
    isSelectedTeam,
    isExpandedOrHoveredOrMobile,
    isActive,
    onAddTeamNav,
    onRemoveTeamNav,
    onRemoveInvalidTeam
  } = _ref;
  return ___EmotionJSX(NavigationAccordion.Item, {
    key: id,
    value: id,
    isSelectedTeam: isSelectedTeam
  }, ___EmotionJSX(NavigationAccordion.TeamHeaderV2, {
    id: id,
    isNavExpanded: isExpandedOrHoveredOrMobile,
    isActive: isActive,
    isSelectedTeam: isSelectedTeam,
    onAddTeamNav: onAddTeamNav,
    onRemoveTeamNav: onRemoveTeamNav,
    onRemoveInvalidTeam: onRemoveInvalidTeam
  }), ___EmotionJSX(NavigationAccordion.Panel, {
    isNavExpanded: isExpandedOrHoveredOrMobile
  }, children));
}
TeamItem.displayName = "TeamItem";