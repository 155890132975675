import { isMac } from '@clubhouse/shared/utils';
import { blockquoteCommand } from './blockquote';
import { fileCommand, uploadFiles } from './files';
import { linkCommand } from './links';
import { orderedListCommand, taskListCommand, unorderedListCommand } from './lists';
import { pasteCommand } from './paste';
import { tableCommand } from './table';
export const getCommands = _ref => {
  let {
    disableAutoQuotes,
    disableAutoLists,
    disableAutoTables,
    disablePasteWithFormatting
  } = _ref;
  return {
    H1: {
      key: '1',
      name: 'H1',
      icon: 'H1',
      prefix: '# ',
      modifiers: 'ctrl+shift'
    },
    H2: {
      key: '2',
      keyAlias: '@',
      name: 'H2',
      icon: 'H2',
      prefix: '## ',
      modifiers: 'ctrl+shift'
    },
    H3: {
      key: '3',
      name: 'H3',
      icon: 'H3',
      prefix: '### ',
      modifiers: 'ctrl+shift'
    },
    Bold: {
      key: 'b',
      name: 'Bold',
      icon: 'Bold',
      prefix: '**',
      postfix: '**'
    },
    Italic: {
      key: 'i',
      name: 'Italic',
      icon: 'Italic',
      prefix: '_',
      postfix: '_'
    },
    Strikethrough: {
      key: 's',
      name: 'Strikethrough',
      icon: 'Strikethrough',
      prefix: '~~',
      postfix: '~~',
      modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift'
    },
    Code: {
      key: 'e',
      modifiers: isMac() ? 'cmd' : 'ctrl',
      name: 'Code',
      icon: 'Code',
      prefix: '`',
      postfix: '`'
    },
    CodeBlock: {
      key: 'e',
      modifiers: isMac() ? 'cmd+shift' : 'ctrl+shift',
      name: 'CodeBlock',
      icon: 'Code',
      prefix: '```\n',
      postfix: '\n```'
    },
    Blockquote: blockquoteCommand({
      disableAutoQuotes
    }),
    UnorderedList: unorderedListCommand({
      disableAutoLists
    }),
    OrderedList: orderedListCommand({
      disableAutoLists
    }),
    TaskList: taskListCommand({
      disableAutoLists
    }),
    Link: linkCommand,
    File: fileCommand(uploadFiles),
    Table: tableCommand({
      disableAutoTables
    }),
    Paste: pasteCommand({
      disablePasteWithFormatting
    })
  };
};