import { useState } from 'react';
import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { getById as getTeamById } from 'data/entity/team';
import { getByIdOrDefault as getWorkflowByIdOrDefault } from 'data/entity/workflow';
import { PAGE_NAMES, generatePathForPage, getCurrentSlug, navigateOnClick } from 'utils/navigation';
import { WorkflowStateDropdown } from '../stories/WorkflowStateDropdown';
import { Actions } from './shared/Actions';
import { MappingHeader, MappingList, MappingRow } from './shared/MappingList';
import { Para } from './shared/Para';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const MapToWorkflowState = _ref => {
  let {
    platformLabelObj,
    goBack,
    workflowId,
    onDone,
    workflowTypes
  } = _ref;
  const manageWorkflowUrl = generatePathForPage(PAGE_NAMES.SETTINGS_WORKFLOW, {
    slug: getCurrentSlug()
  });
  const setInitialData = function (teamId) {
    let workflowStates = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const currentWorkflowStates = getTeamById(teamId).workflow.states;
    const defaultState = currentWorkflowStates.find(w => w.type === 'unstarted');
    const sampleWorkflowState = workflowStates[0];
    const selectedDifferentWorkflowThanPreviouslySaved = sampleWorkflowState && sampleWorkflowState.target_id && currentWorkflowStates.some(i => i.id === sampleWorkflowState.target_id) ? false : true;
    return workflowStates.map(workflowState => {
      if (!workflowState.target_id || selectedDifferentWorkflowThanPreviouslySaved) {
        workflowState.target_id = defaultState.id;
      }
      return workflowState;
    });
  };
  const workflow = getWorkflowByIdOrDefault(workflowId);
  const [workflowStates, setWorkflowtypes] = useState(setInitialData(workflow.team_id, workflowTypes));
  const select = (selectedVal, index) => {
    const clone = workflowStates.slice(0);
    clone[index].target_id = selectedVal;
    setWorkflowtypes(clone);
  };
  const to = (index, initial) => ___EmotionJSX(WorkflowStateDropdown, {
    teamId: workflow.team_id,
    onSelect: val => select(val, index),
    initial: initial
  });
  const isJiraWorkflow = platformLabelObj.platform === 'Jira';
  const headerLabel = isJiraWorkflow ? 'Jira Workflow State' : 'Workflow State';
  return ___EmotionJSX(React.Fragment, null, isJiraWorkflow ? ___EmotionJSX(Para, null, "Map your Jira Workflow to Workflow States in ", BRAND.NAME, ". We will also apply a Label with the previous Jira Workflow State.") : null, ___EmotionJSX(AlertMessage, {
    kind: AlertMessage.KIND.INFO
  }, ___EmotionJSX("div", null, "Want to create a new Workflow State?", ' ', ___EmotionJSX("a", {
    href: manageWorkflowUrl,
    onClick: navigateOnClick(manageWorkflowUrl)
  }, "Visit Settings"), ' ', "to add new States, then revisit the Import menu to map your data.")), ___EmotionJSX(MappingList, null, ___EmotionJSX(MappingHeader, {
    fromLabel: headerLabel,
    toLabel: `${BRAND.NAME} Workflow State`
  }), workflowStates.length ? workflowStates.map((w, i) => ___EmotionJSX(MappingRow, {
    key: w.id,
    from: w.name,
    to: to(i, w.target_id)
  })) : ___EmotionJSX(Para, null, "No Jira Workflow States found. Add an issue to your Jira Project, then reimport to continue.")), ___EmotionJSX(Actions, null, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    onClick: goBack
  }, "Go Back"), ___EmotionJSX(Button, {
    isDisabled: !workflowStates.length,
    kind: Button.KIND.PRIMARY,
    onClick: () => onDone(workflowStates)
  }, "Next: Map Users")));
};