import { useTheme } from '@emotion/react';
import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Trash" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Trash = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill
  } = _ref;
  const theme = useTheme();
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill || theme.iconRedColor
  }, ___EmotionJSX(Icon, {
    icon: "Trash"
  }));
};
Trash.displayName = "Trash";