import { Icon } from '@useshortcut/shapes-ds';
import BurndownChartSample from '@clubhouse/assets/svg/Burndown.svg';
import CFDChartSample from '@clubhouse/assets/svg/CFD.svg';
import CycleTimeChartSample from '@clubhouse/assets/svg/CycleTime.svg';
import { Chip, IconChip } from '@clubhouse/shared/components/Chip';
import SVG from 'components/shared/SVG';
import { ChartUnstartedContainer, Content, Heading, IconWrapper, ImageSection, SampleChip } from './ChartUnstartedUtils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SAMPLE_CHART_IMAGE = {
  burndown: BurndownChartSample,
  'cumulative-flow': CFDChartSample,
  'cycle-time': CycleTimeChartSample
};
const CHART_CONTENT_CENTER = {
  burndown: {
    icon: 'Check',
    heading: 'Are we on track?',
    content: 'View remaining work against an ideal burndown pace to gauge progress.'
  },
  'cumulative-flow': {
    icon: 'Workflow',
    heading: 'Is our workflow consistent?',
    content: 'Quickly identify bottlenecks in work.'
  },
  'cycle-time': {
    icon: 'RecentlyViewed',
    heading: 'What is our average story time?',
    content: 'Get an at-a-glance understanding of how long stories take to complete.'
  }
};
const CHART_CONTENT_END = {
  burndown: {
    icon: 'Estimate',
    heading: 'Did our scope change?',
    content: 'View work added, removed and completed each day.'
  },
  'cumulative-flow': {
    icon: 'Filter',
    heading: 'What is the status of work?',
    content: 'View work in progress over cycle time.'
  },
  'cycle-time': {
    icon: 'Bug',
    heading: 'How long do bug fixes take?',
    content: 'View time-to-complete by story type.'
  }
};
export const ChartUnstarted = _ref => {
  let {
    chartType
  } = _ref;
  const chartContentCenter = CHART_CONTENT_CENTER[chartType];
  const chartContentEnd = CHART_CONTENT_END[chartType];
  return ___EmotionJSX(ChartUnstartedContainer, null, ___EmotionJSX(ImageSection, null, ___EmotionJSX(SVG, {
    rawSVG: SAMPLE_CHART_IMAGE[chartType]
  }), ___EmotionJSX(SampleChip, null, ___EmotionJSX(Chip, null, "Sample Chart"))), ___EmotionJSX("div", null, ___EmotionJSX(IconWrapper, null, ___EmotionJSX(IconChip, {
    kind: "blue"
  }, ___EmotionJSX(Icon, {
    fill: "currentColor",
    icon: chartContentCenter.icon
  }))), ___EmotionJSX(Heading, null, chartContentCenter.heading), ___EmotionJSX(Content, null, chartContentCenter.content)), ___EmotionJSX("div", null, ___EmotionJSX(IconWrapper, null, ___EmotionJSX(IconChip, {
    kind: "blue"
  }, ___EmotionJSX(Icon, {
    fill: "currentColor",
    icon: chartContentEnd.icon
  }))), ___EmotionJSX(Heading, null, chartContentEnd.heading), ___EmotionJSX(Content, null, chartContentEnd.content)));
};
ChartUnstarted.displayName = "ChartUnstarted";