import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="More" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EllipsisMenu = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill
  }, ___EmotionJSX(Icon, {
    icon: "More"
  }));
};
EllipsisMenu.displayName = "EllipsisMenu";