import { ReportSelectFilterReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { ReportSelectFilterSelectItemEdgeUnionFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import DOMPurify from 'dompurify';
import { useRef, useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { DropdownMenu } from '@clubhouse/shared/components/DropdownMenu';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { ROLE_DROPDOWN_Z_INDEX } from 'components/shared/RoleDropdown';
import { CheckboxItem, FilterLabel, SecondaryText, SelectContainer, ToggleButton } from './ReportSelectUtils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportSelectFilterSelectItemEdgeUnionFragment = ReportSelectFilterSelectItemEdgeUnionFragmentDoc;
export const ReportSelectFilterReport_SelectorFragment = ReportSelectFilterReport_SelectorFragmentDoc;
export const TARGET_OPTION_VALUES = ['custom', 'ALL'];
function isTargetOptionValue(optionValue) {
  return TARGET_OPTION_VALUES.includes(optionValue);
}
export const ReportSelectFilter = _ref => {
  let {
    label,
    selector,
    isLoading,
    LoadingComponent,
    descriptions,
    onChange
  } = _ref;
  /* [sc-258088]: Fixes the issue with handling multiple dropdowns open at the same time
   * Remove When https://github.com/radix-ui/primitives/issues/1836 is resolved
   */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const focusRef = useRef(null);
  const handleFocus = event => {
    if (focusRef.current) {
      focusRef.current.focus();
      focusRef.current = null;
      if (event) event.preventDefault();
    }
  };
  const selectedDisplayableName = (() => {
    if (isLoading) {
      // We only want to show the loading component if the displayable name is not available
      // as it could be available earlier from the cache, even though `isLoading` is true.
      if (LoadingComponent && !selector) {
        return LoadingComponent;
      }
    }
    return selector?.valueDisplayable.displayableName ?? '';
  })();
  return ___EmotionJSX(SelectContainer, null, ___EmotionJSX(FilterLabel, null, label), ___EmotionJSX(DropdownMenu, {
    open: dropdownOpen,
    onOpenChange: setDropdownOpen
  }, ___EmotionJSX(DropdownMenu.Trigger, {
    asChild: true
  }, ___EmotionJSX(ToggleButton, null, selectedDisplayableName)), ___EmotionJSX(DropdownMenu.Portal, null, ___EmotionJSX(DropdownMenu.Content, {
    align: "start",
    onInteractOutside: () => {
      handleFocus();
      setDropdownOpen(false);
    },
    onCloseAutoFocus: event => {
      handleFocus(event);
    }
  }, !isLoading && selector?.valueOptions.edges ? selector.valueOptions.edges.map(option => {
    if (option.__typename !== 'SelectItemEdgeOption') return null;
    const {
      value,
      displayable,
      isSelected,
      isDisabled
    } = option;
    const description = descriptions?.[value];
    const showDisabledOptionTooltip = isTargetOptionValue(value) && isDisabled;
    const tooltipContent = value === 'custom' ? 'Select a workflow other than “All” to use Custom states.' : 'Select a type other than “Custom” to view All workflows.';
    return showDisabledOptionTooltip ? ___EmotionJSX(Tooltip, {
      key: value,
      content: tooltipContent
      /* Required for the tooltip to be visible on hover of 'disabled' DOM elements */,
      disabled: false
      /* Set z-index so that the select tooltip sits above the report dropdowns */,
      zIndex: ROLE_DROPDOWN_Z_INDEX + 1
    }, ___EmotionJSX(CheckboxItem, {
      onSelect: () => onChange(value),
      checked: isSelected,
      disabled: Boolean(isDisabled)
    }, displayable.displayableName, description ? ___EmotionJSX(SecondaryText, {
      dangerouslySetInnerHTML: {
        __html: DOMPurify.sanitize(description)
      }
    }) : null)) : ___EmotionJSX(CheckboxItem, {
      key: value,
      onSelect: () => onChange(value),
      checked: isSelected,
      disabled: Boolean(isDisabled)
    }, displayable.displayableName, description ? ___EmotionJSX(SecondaryText, {
      dangerouslySetInnerHTML: {
        __html: DOMPurify.sanitize(description)
      }
    }) : null);
  }) : ___EmotionJSX(ComboboxSelect.ListLoading, null)))));
};
ReportSelectFilter.displayName = "ReportSelectFilter";