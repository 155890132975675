import "core-js/modules/es.array.push.js";
// Q: Why is this in core?
// A: Because of migrations, and the fact that users can override
//    the /stories space from other views, like /epics, /labels or /projects.
import arrayMove from 'array-move';
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import { deprecatedGetTeamScopeIdForNonReactAccess } from 'components/team-navigation';
import { getTeamScopedApplicationStateKey } from 'components/team-navigation/utils/getTeamScopedApplicationStateKey';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import * as StoryData from 'data/entity/story';
import * as Stories from 'data/page/stories';
import * as Log from 'utils/log';
import { EVENTS, logEvent } from 'utils/monitoring';
import { buildSpacesList, getTeamScopedSpaces } from 'utils/space';
import ColumnModel from './column';
import EpicModel from './epic';
import FilterModel from './filter';
import GroupModel from './group';
import IterationModel from './iteration';
import LabelModel from './label';
import OrganizationModel from './organization';
import PermissionModel from './permission';
import ProfileModel from './profile';
import ProjectModel from './project';
import StoryModel from './story';
import TeamModel from './team';
import UserModel from './user';
import WorkflowModel from './workflow';
import StoriesIterationModel from '../../../stories/js/models/storiesIteration';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import MessageController from '../controllers/message';
import SampleWorkspaceController from '../controllers/sampleWorkspace';
import ApplicationState from '../modules/applicationState';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Format from '../modules/format';
import Url from '../modules/url';
import Utils from '../modules/utils';
const exports = {};

/*

Example entity:

{
  "id": 6585,
  "created_at": "2016-02-09T19:50:40Z",
  "updated_at": "2016-02-09T19:50:40Z",
  "name": "Updated in Last Week",
  "data": "[JSON]",
  "shared": false,
  "author_id": "53ba2bf3-b6ab-40bf-a02a-2bffd32e295a"
}

*/

const DEFAULT_NAME = 'Unsaved Space';
Collection.create('Space', exports);
exports.createTempSpace = (url, name, data) => {
  const space = {
    temp: true,
    readymade: false,
    scratch: false,
    shared: false,
    name,
    url,
    data: _.assign({
      displayStoriesNotInEpics: true,
      displayStoriesNotInIterations: true,
      filterType: 'AND',
      scratch: false,
      readymade: false,
      Column: '*',
      Epic: '*',
      Project: '*',
      Iteration: '*',
      Filter: []
    }, data)
  };
  FilterModel.flush();
  space.data.Filter.forEach(filter => {
    FilterModel.addFixture(filter.name, filter.value, {
      quiet: true
    });
  });
  space.data.Filter = exports.compileSpaceFilters();
  Globals.set('tempSpace', space);
  Event.trigger('temporarySpaceCreated', space);
  return space;
};
exports.on('fetched.collection', () => {
  // Active space may have been hidden in another tab
  const activeSpace = exports.get({
    active: true
  });
  if (activeSpace && isSpaceHidden(activeSpace)) {
    exports.loadFirstVisibleSpace();
  }
});
exports.fetchPreferencesAndRecompile = () => new Promise(res => {
  if (!ApplicationState.hasPendingUpdate(getPreferencesApplicationStateKey())) {
    PermissionModel.fetchCurrentApplicationState(() => {}, undefined, data => {
      const preferences = Utils.parseJSON(data)?.[getPreferencesApplicationStateKey()];
      exports.recompilePreferences(preferences);
      res();
    });
  }
});
exports.on('updated.collection', () => {
  if (exports.isNotBulk()) {
    exports.fetchPreferencesAndRecompile();
  }
});

// New spaces that are added after the initial fetch should trigger
// the same preferences logic as the initial fetch
exports.on('added', () => {
  if (exports.isNotBulk()) {
    exports.fetchPreferencesAndRecompile();
  }
});
exports.on('removed.collection', async space => {
  if (exports.isNotBulk()) {
    await exports.fetchPreferencesAndRecompile();

    // if this space was the active one, we need to load another one as active
    if (space.active === true) {
      exports.loadFirstVisibleSpace();
    }
  }
});
exports.normalize = space => {
  if (_.isString(space.data)) {
    space.originalData = space.data;
    space.data = JSON.parse(space.data || '{}');
  }

  // Temporary until backend migration has been run after launching the rename.
  if (space.name === 'Unsaved Space') {
    space.name = DEFAULT_NAME;
  }
  space.scratch = !!space.data.scratch;
  space.readymade = !!space.data.readymade;
  space.author = _normalizeAuthor(space);
  space.icon = _getSpaceIcon(space);
  space.url = _getSpaceURL(space);
};
function _getSpaceIcon(space) {
  const icons = {
    readymade: {
      active: 'star',
      inactive: 'star-o'
    },
    shared: {
      active: 'feed',
      inactive: 'feed'
    },
    scratch: {
      active: 'pencil-square-o',
      inactive: 'pencil-square-o'
    }
  };
  const type = Object.keys(icons).find(type => {
    return space[type];
  });
  return type ? icons[type] : {
    active: 'circle',
    inactive: 'circle-o'
  };
}
function _normalizeAuthor(space) {
  return ProfileModel.getAllDetailsById(space.author_id);
}
function _getSpaceURL(space) {
  return Url.getSlugPath() + `/stories/space/${space.id}/${Utils.slugify(space.name, {
    limit: 100
  })}`;
}
const fetchRecentStoriesForAllWorkflows = async callback => {
  await StoryData.fetchRecentStoriesForWorkflows({
    workflowIds: WorkflowModel.all().map(w => w.id)
  });
  callback();
};
exports.getStoryLoadStrategyForSpace = space => {
  const fetchRecentStoriesForSpace = async callback => {
    const team = WorkflowModel.getById(space.team_id) ?? TeamModel.getActive('stories');
    const currentWorkflowId = team?.workflow?.id;
    if (currentWorkflowId) {
      await StoryData.fetchRecentStoriesForWorkflow(currentWorkflowId);
      callback();
      const otherWorkflowIds = WorkflowModel.all().filter(w => w.id !== currentWorkflowId).map(w => w.id);
      StoryData.fetchRecentStoriesForWorkflows({
        workflowIds: otherWorkflowIds
      });
    } else {
      await fetchRecentStoriesForAllWorkflows(callback);
    }
  };
  if (!space) {
    return fetchRecentStoriesForAllWorkflows;
  }
  if (space.readymade && space.name === 'Owned by Me') {
    return StoryModel.fetchOwnedByMe;
  } else if (space.readymade && space.name === 'Updated in Last Week') {
    return StoryModel.fetchUpdatedInLastWeek;
  } else {
    const strategy = exports.generateCustomStoryLoadStrategy(space);
    return strategy || fetchRecentStoriesForSpace;
  }
};
exports.generateCustomStoryLoadStrategy = space => {
  const query = {};
  const d = space.data;

  // We don't currently support an OR-based story search, so fall back to default.
  if (d.filterType === 'OR' && _.isArray(d.Filter) && d.Filter.length > 1) {
    return false;
  }
  if (isProjectsFeatureEnabled() && _.isArray(d.Project) && d.Project.length) {
    // Too many projects, fall back to default load strategy.
    if (d.Project.length > 2) {
      return false;
    }
    query.project_ids = d.Project;
  }
  if (_.isArray(d.Iteration) && d.Iteration.length) {
    // Too many iterations, fall back to default load strategy.
    if (d.Iteration.length > 10 || d.displayStoriesNotInIterations) {
      return false;
    }
    query.iteration_ids = d.Iteration;
  }
  const groupIds = Stories.toQuery(d);
  if (!Stories.hasSelectedAllGroups(d)) {
    if (groupIds.length > 10 || groupIds.includes(Stories.NO_ENTITY_ID)) {
      return false;
    }
    query.group_ids = groupIds;
  }
  if (_.isArray(d.Epic) && d.Epic.length) {
    // 10 is probably too many epics, fall back to default load strategy.
    // We also can't currently handle cases where a space has individual epics
    // selected AND 'No Epic' selected as well.
    if (d.Epic.length > 10 || d.displayStoriesNotInEpics) {
      return false;
    }
    query.epic_ids = d.Epic;
    return _generateSimpleSearchFn(query);
  }
  if (d.displayStoriesNotInEpics === true && _.isEmpty(d.Epic)) {
    query.epic_id = null;
  }
  const relevantFilters = _.reject(d.Filter, f => {
    // We don't currently support inverse searches
    // and we ignore deactivated filters.
    return f.inverse || !f.active || _.includes(['noOwner', 'noEpic', 'hasStoryType', 'noStory'], f.fnName);
  });
  relevantFilters.forEach(f => {
    if (f.fnName === 'createdInTheLastWeek') {
      // Adding an extra day to sidestep TZ complexity.
      query.created_at_start = moment().subtract(8, 'days').format();
    }
    if (f.fnName === 'createdToday') {
      // Adding an extra day to sidestep TZ complexity.
      query.created_at_start = moment().subtract(2, 'days').format();
    }
    if (f.fnName === 'updatedInTheLastWeek') {
      // Adding an extra day to sidestep TZ complexity.
      query.updated_at_start = moment().subtract(8, 'days').format();
    }
    if (f.fnName === 'updatedToday') {
      // Adding an extra day to sidestep TZ complexity.
      query.updated_at_start = moment().subtract(2, 'days').format();
    }
    if (f.fnName === 'ownedBy') {
      query.owner_ids = query.owner_ids || [];
      query.owner_ids.push(f.value);
    }
    if (f.fnName === 'ownedByMe') {
      query.owner_ids = query.owner_ids || [];
      query.owner_ids.push(UserModel.getLoggedInUserPermissionID());
    }
  });

  // If we haven't constructed a query yet, fall back to default.
  if (_.keys(query).length === 0) {
    return false;
  }
  return StoryModel.generateCustomQueryFetchFn(query);
};
function _generateSimpleSearchFn(query) {
  return callback => {
    StoryModel.searchStoriesAndThenFetchAllActiveProjects(query, callback);
  };
}
exports.getCurrentSpaceState = () => {
  const epics = EpicModel.allNotArchived();
  const projects = ProjectModel.allNotArchived();
  const columns = ColumnModel.all();
  const iterations = IterationModel.all();
  const data = {
    displayStoriesNotInEpics: EpicModel.shouldDisplayStoriesNotInEpics(),
    displayStoriesNotInIterations: StoriesIterationModel.shouldDisplayStoriesNotInIterations(),
    filterType: FilterModel.getFilterType() || 'AND',
    Filter: exports.compileSpaceFilters(),
    Epic: exports.compileEntityData(epics),
    Iteration: exports.compileEntityData(iterations),
    Project: Stories.getProjectsAsSpaceData(projects),
    Column: exports.compileEntityData(columns),
    Group: Stories.getGroupsAsSpaceData()
  };
  if (areCustomFieldsEnabled()) {
    data.CustomField = Stories.getCustomFieldsAsSpaceData();
  }
  return data;
};
exports.compileData = space => {
  const data = exports.getCurrentSpaceState();
  space.data = {
    scratch: !!space.scratch,
    readymade: !!space.readymade,
    ...data
  };
};
exports.compileEntityData = collection => {
  // Ignoring archived epics, if all remaining epics are active, set as wildcard.
  if (_.filter(collection, {
    active: false
  }).length === 0) {
    return '*';
  }
  return _.map(_.filter(collection, 'active'), 'id');
};
exports.isValid = obj => {
  return obj && obj.id && obj.data;
};
exports.updateIfValid = obj => {
  if (exports.isValid(obj)) {
    if (_isDoubleEncoded(obj.data)) {
      obj.data = JSON.parse(obj.data);
    }
    const currentSpace = exports.getById(obj.id);
    const lastUpdatedAt = currentSpace && currentSpace.updated_at;
    exports.update(obj);
    if (exports.isNotBulk() && currentSpace && currentSpace.active === true && obj.updated_at !== lastUpdatedAt) {
      exports.load(currentSpace);
    }
  }
};
exports.toElement = space => {
  return $(`.space[data-id="${space.id}"]`);
};
exports.unsetActive = () => {
  exports.filter({
    active: true
  }).forEach(activeSpace => {
    activeSpace.active = false;
  });
};
exports.setActive = space => {
  exports.unsetActive();
  space.active = true;
  space.hidden = false;
  ApplicationState.set(getLastActiveApplicationStateKey(), space.id);
  exports.update(space);
  Event.trigger('activeSpaceSet');
};
const getLastActiveApplicationStateKey = () => {
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  return getTeamScopedApplicationStateKey(teamId, 'Space.lastActive');
};
const getSpacePreferences = function (spaceId) {
  let preferences = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : exports._getPreferences();
  return preferences.find(pref => pref.id === spaceId);
};
exports.getSpacePreferences = getSpacePreferences;
exports.getActiveID = () => {
  return BaseUtils.toNumber(ApplicationState.get(getLastActiveApplicationStateKey()));
};
exports.getActive = () => {
  return exports.getById(exports.getActiveID()) ?? exports.find({
    readymade: true,
    name: 'Everything'
  });
};
exports.getVisibleSpaces = () => {
  return getTeamScopedSpacesByHiddenPreference(false);
};
const getTeamScopedSpacesByHiddenPreference = hidden => {
  const prefs = exports._getPreferences();
  const spaces = getTeamScopedSpaces(exports.all()).filter(space => {
    const pref = getSpacePreferences(space.id, prefs);
    const matchesVisibility = (pref?.hidden ?? false) === hidden;
    return matchesVisibility && (hidden ? space.data.scratch === false : true);
  });
  return sortSpacesByPosition(spaces, prefs);
};
const sortSpacesByPosition = (spaces, preferences) => {
  const getPrefIndexById = id => preferences.findIndex(pref => pref.id === id);
  const sortedSpaces = spaces.sort((a, b) => getPrefIndexById(a.id) - getPrefIndexById(b.id));
  return sortedSpaces;
};
exports.sortSpacesByPosition = sortSpacesByPosition;
const isSpaceHidden = space => {
  return getSpacePreferences(space.id)?.hidden ?? false;
};
exports.getHiddenSpaces = () => {
  return getTeamScopedSpacesByHiddenPreference(true);
};
exports.getVisibleDraggableSpaces = () => exports.filter(space => !isSpaceHidden(space));
exports.getScratchSpace = () => {
  return exports.get(space => {
    return space.data.scratch === true;
  });
};
const getPreferencesApplicationStateKey = () => {
  const teamScopeId = deprecatedGetTeamScopeIdForNonReactAccess();
  return getTeamScopedApplicationStateKey(teamScopeId, 'Space.preferences');
};
exports._getPreferences = () => {
  let prefs = ApplicationState.get(getPreferencesApplicationStateKey());
  if (!_.isArray(prefs)) {
    Log.log('[ch12910] Space preferences not found! Initializing...');
    prefs = exports._compileDefaultPreferences();
    exports._setPreferences(prefs);
  } else {
    // Guard against preferences for spaces that have been deleted.
    _.remove(prefs, pref => {
      return !exports.getById(pref.id);
    });
  }
  return prefs ?? [];
};
exports._setPreferences = preferences => {
  if (preferences && preferences.length > 0) {
    ApplicationState.set(getPreferencesApplicationStateKey(), preferences);
  } else {
    Log.error(new Error('[ch12910] Attempt to set empty space preferences...'));
  }
};
const toPref = space => ({
  id: space.id,
  hidden: space.hidden ?? false
});
exports._compileDefaultPreferences = () => {
  const sorted = buildSpacesList(getTeamScopedSpaces(exports.all()));
  return sorted.map(toPref);
};
exports.recompilePreferences = function () {
  let oldPreferences = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports._getPreferences();
  Log.debug('Space: recompiling preferences');

  // We want to grab both visible and hidden spaces
  const visibleSpaces = exports.getVisibleDraggableSpaces();
  const hiddenSpaces = exports.getHiddenSpaces();
  const teamScopedSpaces = getTeamScopedSpaces([...visibleSpaces, ...hiddenSpaces]);

  // Filter out preferences for spaces that may no longer exist or be in scope
  const prunedPreferences = oldPreferences.filter(pref => Boolean(teamScopedSpaces.find(space => space.id === pref.id)))
  /**
   * This map is just to be very explicit about what we want in the preferences object
   * and to clean up any old/different pref objects that may exist locally for users.
   */.map(pref => ({
    id: pref.id,
    hidden: pref.hidden
  }));

  // Add in new preferences for spaces that may be new/in scope
  const recompiledPreferences = [...prunedPreferences];
  teamScopedSpaces.forEach(space => {
    const isTeamInPreferences = Boolean(getSpacePreferences(space.id, recompiledPreferences));
    if (!isTeamInPreferences) {
      recompiledPreferences.push(toPref(space));
    }
  });

  /**
   * We sort by hidden so that prefs match what you see.
   * When you hide and then show a space again, it will be at the bottom of the visible list, not where it was before.
   */
  recompiledPreferences.sort((a, b) => a.hidden - b.hidden);

  /**
   * We are adding position back in for now to enable backward compatibility with the old code
   * (this would technically only apply to "All Work", as other preferences did not exist before)
   * so we could remove this forEach once we know that we do not need to roll back code
   * That being said, do not use the position property for anything, it is considered deprecated.
   */
  recompiledPreferences.forEach((pref, i) => {
    pref.position = i + 1;
  });
  exports._setPreferences(recompiledPreferences);
  return recompiledPreferences;
};
exports.loadFirstVisibleSpace = () => {
  const spaces = exports.getVisibleSpaces();
  if (spaces[0]) {
    exports.load(spaces[0]);
  }
};
exports.hide = space => {
  const preferences = exports._getPreferences();
  const pref = getSpacePreferences(space.id, preferences);
  pref.hidden = true;
  const newPrefs = arrayMove(preferences, preferences.indexOf(pref), preferences.length - 1);
  exports._setPreferences(newPrefs);
  if (space.active === true) {
    exports.loadFirstVisibleSpace();
  }
};
exports.unhide = space => {
  const preferences = exports._getPreferences();
  const pref = getSpacePreferences(space.id, preferences);
  pref.hidden = false;
  exports._setPreferences(preferences);
  exports.load(space);
};
exports.isActive = space => {
  return !!space.active;
};
exports.isModelInSpace = (space, key, model) => {
  const activeModelIds = _.get(space, ['data', key], []);

  // Newer wildcard value
  // Example: "Epic": "*"
  return (
    // some users may still have Columns: "**" stored in their space see:
    // https://app.shortcut.com/internal/story/238803/fix-workflow-spaces-to-not-filtered-out-backlog-by-default
    activeModelIds === '**' || activeModelIds === '*' ||
    // Older wildcard value (should be going away soon)
    // Example: "Epic": { "*": { "active": true } }
    _.get(activeModelIds, ['*', 'active']) ||
    // Older object-based property (should be going away soon)
    // Example: "Epic": { "123": { "active": true } }
    _.get(activeModelIds, [model.id, 'active']) ||
    // Newer array-based property
    // Example: "Epic": [ "123" ]
    _.includes(activeModelIds, model.id)
  );
};
exports.isModelActiveInActiveSpace = (key, model) => {
  const space = exports.getActive();
  return exports.isModelInSpace(space, key, model);
};

// Extracts 1234 from a path like /org-slug/stories/space/1234/my-space-name
function _getIDFromURL(url) {
  const path = url.split('#')[0];
  const parts = _.compact(path.split('/'));
  return BaseUtils.toNumber(parts[3]);
}
exports.getSpaceTitleFromURL = url => {
  url = url || Url.getCurrentPathname();
  const parts = _.compact(url.split('/'));
  const entityType = parts[2];
  const id = _getIDFromURL(url);
  switch (entityType) {
    case 'project':
      {
        const project = ProjectModel.getById(id);
        return project ? `Project: ${project.name}` : null;
      }
    case 'epic':
      {
        const epic = EpicModel.getById(id);
        return epic ? `Epic: ${epic.name}` : null;
      }
    case 'label':
      {
        if (id === 'unlabeled') {
          return 'Unlabeled Stories';
        }
        const label = LabelModel.getById(id);
        return label ? `Label: ${label.name}` : null;
      }
    case 'team':
      {
        const group = GroupModel.getById(id);
        return group ? `Team: ${group.name}` : null;
      }
    default:
      {
        if (id === 'unsaved') {
          return DEFAULT_NAME;
        }
        const existingSpace = exports.getById(id);
        return existingSpace ? existingSpace.name : null;
      }
  }
};
function _getSpaceForEntity(_ref) {
  let {
    url,
    entityType,
    id
  } = _ref;
  switch (entityType) {
    case 'project':
      return _getTempProjectSpace(url, id);
    case 'epic':
      return _getTempEpicSpace(url, id);
    case 'label':
      return id === 'unlabeled' ? _getTempUnlabeledSpace(url) : _getTempLabelSpace(url, id);
    case 'team':
      /*
      [maronov 9/23/2021]
      'Team' is the new name for 'Group', but this name change is pending dev work
      The legacy 'Team' is now called 'Workflow' (not a 1:1 swap, see team.js)
      See this epic about transitioning to the new naming:
        https://app.shortcut.com/internal/epic/162939
      */
      return _getTempGroupSpace(url, id);
    default:
      return exports.getById(id);
  }
}
exports.getSpaceFromURL = url => {
  url = url || Url.getCurrentPathname();
  const parts = _.compact(url.split('/'));
  const entityType = parts[2];
  const id = _getIDFromURL(url);
  const space = id === 'unsaved' ? exports.get({
    scratch: true
  }) //fallback to default unsaved space
  : _getSpaceForEntity({
    url,
    entityType,
    id
  });
  if (space?.data) {
    //apply search params from URL
    const {
      team,
      workflow
    } = Url.parseLocationSearch() || {};
    if (team && entityType !== 'team') {
      space.data.Group = [team];
    }
    if (workflow) {
      const workflowEntity = WorkflowModel.getById(workflow);
      TeamModel.setActiveTeamById(workflowEntity?.team_id);
    }
  }
  return space;
};
function _getTempProjectSpace(url, id) {
  const project = ProjectModel.getById(id);
  if (!project) {
    return null;
  }
  TeamModel.setActiveTeamById(project.team_id);
  return exports.createTempSpace(url, `Project: ${project.name}`, {
    Project: [id]
  });
}
function _getTempGroupSpace(url, id) {
  const group = GroupModel.getById(id);
  if (!group) {
    return null;
  }
  return exports.createTempSpace(url, `Team: ${group.name}`, {
    Group: [id]
  });
}
function _getTempEpicSpace(url, id) {
  const epic = EpicModel.getById(id);
  if (!epic) {
    return null;
  }
  const epicTeam = EpicModel.findTeamForEpic(epic);
  if (epicTeam) {
    TeamModel.setActiveTeamById(epicTeam.id);
  }
  return exports.createTempSpace(url, `Epic: ${epic.name}`, {
    Epic: [id],
    team_id: epicTeam?.id,
    displayStoriesNotInEpics: false
  });
}
function _getTempUnlabeledSpace(url) {
  return exports.createTempSpace(url, 'Unlabeled Stories', {
    Filter: [{
      name: 'unlabeled'
    }]
  });
}
function _getTempLabelSpace(url, id) {
  const label = LabelModel.getById(id);
  if (!label) {
    return null;
  }
  return exports.createTempSpace(url, `Label: ${label.name}`, {
    Filter: [{
      name: 'byLabel',
      value: label
    }]
  });
}
exports.restore = function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  Log.debug('Space: restoring current space...');
  const spaces = exports.all();
  if (spaces && spaces.length > 0) {
    const urlSpace = exports.getSpaceFromURL();
    const spaceIDFromURL = _getIDFromURL(Url.getCurrentPathname());
    const activeSpace = exports.getActive();
    const spaceToLoad = urlSpace ?? activeSpace ?? exports.getVisibleSpaces()?.[0];
    const teamScopedSpace = getTeamScopedSpaces([spaceToLoad])?.[0] ?? getTeamScopedSpaces(spaces)?.[0];
    exports.load(teamScopedSpace);

    // This might be a hot-reloaded story page, in which case we don't want to trigger an event that shows a message.
    if (spaceIDFromURL && !urlSpace && Url.getCurrentPage() !== 'story') {
      Event.trigger('spaceNotFound');
    }
    callback();
  } else {
    // We should never get into this situation now that readymades are fixtured server-side.
    Log.log('No spaces found!');
  }
};
exports.compileSpaceFilters = () => {
  const filters = [];
  FilterModel.each(filter => {
    filters.push(FilterModel.translateFilterToData(filter));
  });
  return filters;
};
exports.overwriteApplicationState = function (space, spaceData) {
  let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _.noop;
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.updateSpace(space, {
    data: JSON.stringify(spaceData)
  }, (err, updatedSpace) => {
    if (updatedSpace) {
      exports.setActive(updatedSpace);
    }
    callback(err, updatedSpace);
  });
};
exports.save = function (options) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _.noop;
  callback = _.isFunction(callback) ? callback : _.noop;
  options = options || {};
  const space = {
    name: options.name,
    shared: false,
    readymade: false,
    hidden: false,
    scratch: !!options.scratch
  };
  exports.compileData(space);
  exports.saveNew(space, (err, savedSpace) => {
    if (savedSpace) {
      if (!options.quiet) {
        MessageController.success(`Space <strong>${Format.sanitize(space.name)}</strong> saved.`, {
          id: 'space',
          icon: 'fa-desktop',
          timeout: 2000
        });
      }
    }
    callback(err, savedSpace);
  });
  return false;
};
exports.allVisibleAreActive = (epics, active_ids) => {
  return _.every(epics, epic => {
    return _.includes(active_ids, epic.id);
  });
};

// This is used when a new team is selected on the Stories page.
exports.reloadActiveSpace = () => {
  const space = exports.getActive();
  exports.load(space);
};
exports.load = space => {
  if (!space) {
    return;
  }
  Log.debug('Space: Loading space ' + space.name);
  if (space.temp === true) {
    exports.unsetActive();
  } else if (space.active !== true) {
    exports.setActive(space);
  }
  TeamModel.setActiveTeamById(space.team_id);
  const data = space.data;
  FilterModel.setFiltersForSpace(data);
  EpicModel.setActiveEpicsForSpace(data);
  IterationModel.setActiveIterationsForSpace(data);
  Stories.setGroupIdsFromSpaceData(data);
  Stories.setProjectIdsFromSpaceData(data);
  if (areCustomFieldsEnabled()) {
    Stories.setCustomFieldValuesFromSpaceData(data);
  }
  ColumnModel.setActiveColumnsForSpace(space);
  if (data.displayStoriesNotInEpics) {
    EpicModel.displayStoriesNotInEpics();
  } else {
    EpicModel.hideStoriesNotInEpics();
  }
  if (data.displayStoriesNotInIterations === false) {
    StoriesIterationModel.hideStoriesNotInIterations();
  } else {
    StoriesIterationModel.displayStoriesNotInIterations();
  }
  _logPlainTextFilters(space);
  FilterModel.setFilterType(data.filterType === 'AND' ? 'AND' : 'OR');
  Event.trigger('spaceLoaded', space);
  if (space.name !== DEFAULT_NAME && !space.data.scratch && !SampleWorkspaceController.isSampleObserver()) {
    logEvent(EVENTS.Space_Viewed, {
      space_name: space.name,
      workspace_id: OrganizationModel.getCurrentID(),
      workflow_id: WorkflowModel.getActive().id,
      shared: space.shared,
      filter_count: space.data.Filter.length,
      space_filters: space.data.Filter.map(filter => filter.name)
    });
  }
};
function _logPlainTextFilters(space) {
  const fullTextFilterCount = space.data.Filter.filter(filterData => {
    const filter = FilterModel.translateDataToFilter(filterData);
    return _isPlainTextFilter(filter);
  }).length;
  if (fullTextFilterCount > 0) {
    Log.log('User loaded space with plain-text filter', {
      spaceName: space.name,
      filterCount: fullTextFilterCount
    });
  }
}
function _isPlainTextFilter(filter) {
  if (filter.type !== 'search') {
    return false;
  }
  const terms = Utils.tokenizeQuery(filter.name);
  return _.some(terms, term => {
    return term.indexOf(':') === -1;
  });
}
exports.spaceDataRelatesToAnotherTeam = (collection, filterData) => {
  if (!_.isArray(filterData) || filterData.length === 0) {
    return false;
  }
  const collectionIDs = _.map(collection, 'id');
  return _.intersection(collectionIDs, filterData).length === 0;
};
exports.isOwnedByCurrentUser = space => {
  const currentUser = UserModel.getLoggedInUserPermission();
  return space.author?.id === currentUser.id;
};
exports.removeDuplicateSpaces = function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  const fns = exports._findDuplicateSpaces().map(space => {
    return next => {
      Log.debug('Space: Removing duplicate space: ' + space.name);
      Log.log('Removing duplicate space', {
        name: space.name
      });
      exports.deleteSpace(space, next);
    };
  });
  Async.eachInSequenceThen(fns, callback);
};
function _firstNotActive(spaces) {
  return spaces.find(space => {
    return !space.active;
  });
}
exports._findDuplicateSpaces = () => {
  const dupes = [];
  const scratchSpaces = exports.filter({
    scratch: true
  });
  if (scratchSpaces.length > 1) {
    Log.log('Multiple scratch spaces detected!', {
      spaces: scratchSpaces
    });
    dupes.push(_firstNotActive(scratchSpaces));
  }
  return dupes;
};

/** @deprecated Use 'useUpdateSpacePosition' instead */
exports.reorder = (oldIndex, newIndex) => {
  exports.move(oldIndex, newIndex);
  exports.recompilePreferences();
};
exports.fetchAll = function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  let retryCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const MAX_RETRIES = 3;
  Backend.get('/api/private/spaces', {
    onComplete: (res, xhr) => {
      if (_.isArray(res)) {
        exports.fetchAllHandler(res, callback);
      } else {
        retryCount += 1;
        if (retryCount > MAX_RETRIES) {
          Log.log('[ch12910] List Spaces endpoint did not respond with an array! Already retried.', {
            statusCode: xhr.status,
            response: res
          });
          callback();
        } else {
          exports.fetchAll(callback, retryCount);
        }
      }
    }
  });
};
exports.fetchAllHandler = (spaces, callback) => {
  let activeWasUpdatedRemotely = false;
  const active = exports.get({
    active: true
  });
  exports.trigger('bulkStart');
  spaces.forEach(space => {
    if (active && active.shared && space.id === active.id && space.updated_at !== active.updated_at) {
      activeWasUpdatedRemotely = true;
    }
    exports.updateIfValid(space);
  });
  exports.trigger('bulkEnd');
  exports.trigger('fetched');
  if (activeWasUpdatedRemotely) {
    Log.debug('Space: Active space was updated remotely.');
    exports.load(active);
  }
  exports.removeDuplicateSpaces(callback);
};
function _isDoubleEncoded(data) {
  return typeof data === 'string' && data.indexOf('"{\\"') === 0;
}
function _ensureDataIsNotDoubleEncoded(data) {
  if (_.isString(data)) {
    Log.log('Space data is already a string!!', {
      data
    });
    return data;
  }
  return JSON.stringify(data);
}
exports.saveNew = function (space) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _.noop;
  Backend.post('/api/private/spaces', {
    data: {
      name: space.name,
      shared: space.shared,
      data: _ensureDataIsNotDoubleEncoded(space.data)
    },
    onComplete: res => {
      if (exports.isValid(res)) {
        exports.update(res);
        callback(false, exports.getById(res.id));
        deleteSpacesCache();
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.updateSpace = function (space, updates) {
  let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _.noop;
  if (space.readymade) {
    Log.error(new Error('Attempting to modify a readymade Space'), {
      data: updates.data
    });
    return;
  }
  if (updates.data && _isDoubleEncoded(updates.data)) {
    Log.log('Space data is double-encoded!!', {
      data: updates.data
    });
    updates.data = JSON.parse(updates.data);
  }
  Backend.put('/api/private/spaces/' + space.id, {
    data: updates,
    onComplete: res => {
      if (exports.isValid(res)) {
        exports.update(res);
        callback(false, exports.getById(res.id));
        deleteSpacesCache();
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.deleteSpace = function (space) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _.noop;
  Backend.delete('/api/private/spaces/' + space.id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204 || xhr.status === 404) {
        exports.remove(space);
        exports.recompilePreferences();
        callback(false);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
function deleteSpacesCache() {
  return ServiceWorkerUtils.deleteRequestCacheByUrl('/api/private/spaces');
}
exports.Promises = {};
exports.Promises.updateSpace = BaseUtils.promisify(exports.updateSpace);
exports.Promises.save = BaseUtils.promisify(exports.save);
exports.Promises.saveNew = BaseUtils.promisify(exports.saveNew);
export { exports as default };