import { LoadingDots } from '@clubhouse/shared/animations/LoadingDots';
import { Button } from '@clubhouse/shared/components/Button';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LoadMoreButton = _ref => {
  let {
    loading,
    onClick
  } = _ref;
  return ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    IconLeft: loading ? LoadingDots : null,
    onClick: onClick,
    isDisabled: loading
  }, "Load more results");
};
LoadMoreButton.displayName = "LoadMoreButton";