import { ArchiveStoryDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { ArchiveStoryDataFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
const ArchiveStoryDataFragment = ArchiveStoryDataFragmentDoc;
const ArchiveStoryMutation = ArchiveStoryDocument;
export function useArchiveStoryMutation() {
  const [setArchived] = useMutation(ArchiveStoryMutation);
  return useCallback(story => setArchived({
    variables: {
      id: story.id
    },
    optimisticResponse: {
      __typename: 'Mutation',
      storyUpdate: {
        __typename: 'StoryPayload',
        story: {
          __typename: 'Story',
          id: story.id,
          archived: true
        }
      }
    }
  }), [setArchived]);
}