import { useRequiredParent } from '@useshortcut/shapes-ds';
import { useContext } from 'react';
import { Label, Root, Thumb } from './components';
import { LabelContext } from './components/Label/LabelContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Switch = _ref => {
  let {
    isChecked,
    isDisabled = false,
    onChange,
    size = 'medium'
  } = _ref;
  const context = useContext(LabelContext);
  useRequiredParent({
    child: 'Switch',
    context: context
  });
  const {
    id
  } = context;
  return ___EmotionJSX(Root, {
    "aria-disabled": isDisabled,
    checked: isChecked,
    disabled: isDisabled,
    id: id,
    onCheckedChange: onChange,
    size: size
  }, ___EmotionJSX(Thumb, {
    isChecked: isChecked,
    size: size
  }));
};
Switch.displayName = "Switch";
Switch.Label = Label;