import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="GraduationCap" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const GraduationCap = _ref => {
  let {
    fill = '#333333',
    width = '23px'
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    fill: fill,
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "GraduationCap"
  }));
};
GraduationCap.displayName = "GraduationCap";