import { DuplicateEpicDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { DuplicateEpicActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ObjectivePageDocument } from '@clubhouse/datalayer/types';
import { Button } from '@clubhouse/shared/components/Button';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { sanitize } from '@clubhouse/shared/utils';
import { useMutation } from 'gql';
import { navigateOnClick } from 'utils/navigation';
import { isLoggedInUserObserver } from 'data/entity/user';
import { getHref } from '../links';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DuplicateEpicActionFragment = DuplicateEpicActionFragmentDoc;
const DuplicateEpicMutation = DuplicateEpicDocument;
export const DuplicateEpicAction = _ref => {
  let {
    epic
  } = _ref;
  const [duplicateEpic] = useMutation(DuplicateEpicMutation, {
    refetchQueries: [ObjectivePageDocument]
  });
  const onDuplicate = useCallback(async () => {
    try {
      const result = await duplicateEpic({
        variables: {
          epicId: epic.id
        }
      });
      const {
        name,
        publicId
      } = result?.data?.epicDuplicate?.epic ?? {};
      if (!name || !publicId) throw new Error('Response from duplication is missing expected properties.');
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space2"
        }, ___EmotionJSX(ToastText, null, "Duplicate of ", ___EmotionJSX("strong", null, sanitize(name)), " was created."), ___EmotionJSX(Button, {
          kind: Button.KIND.SECONDARY,
          size: Button.SIZE.SMALL,
          onClick: navigateOnClick(getHref(publicId))
        }, "View Epic"))
      });
    } catch {
      addToast({
        kind: 'alert',
        timeout: 5000,
        Content: () => ___EmotionJSX(ToastText, null, "Duplication failed. Try again or contact support.")
      });
    }
  }, [duplicateEpic, epic.id]);
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Copy",
    onClick: onDuplicate,
    isDisabled: isLoggedInUserObserver()
  }, "Duplicate ", Nouns.Epic.singular);
};
DuplicateEpicAction.displayName = "DuplicateEpicAction";