import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import CompanyModel from 'app/client/core/js/models/company';
import OrganizationModel from 'app/client/core/js/models/organization';
import OrganizationProfileModel from 'app/client/core/js/models/organizationProfile';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import { useEntities, useEntity } from './collection';
export const getCurrentOrgId = () => OrganizationModel.getCurrentID();
export const getCurrentOrg = () => OrganizationModel.getCurrent();
export const getBranchFormat = () => OrganizationModel.getBranchFormat();
export const updateGitBranchFormat = format => new Promise((resolve, reject) => {
  OrganizationModel.updateGitBranchFormat(format, err => err ? reject(err) : resolve());
});
export const getOrgById = id => OrganizationModel.getById(id);
export const getSlackConfig = () => OrganizationModel.getSlackConfiguration();
export const useCurrentOrgId = () => {
  const [id, setId] = useState(getCurrentOrgId());

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only run this when we don't have an id on initial load.
  useEffect(() => {
    // If we already have the current org id, no need to listen for it
    if (id) return;
    const eventName = `added.${nanoid()}`;
    let timeoutId;
    OrganizationModel.on(eventName, () => {
      // These `added` events seem to fire before the organization is fully available in the
      // collection, so we wait for the next tick before we try to get the currentOrgId again
      timeoutId = setTimeout(() => {
        setId(getCurrentOrgId());
      });

      // Once we get the current org id we can stop listening because the org won't change without a
      // full page refresh
      OrganizationModel.off(eventName);
    });

    // In the unlikely event we unmount before the organization is added to the collection, we also
    // stop listening
    return () => {
      clearTimeout(timeoutId);
      OrganizationModel.off(eventName);
    };
  }, []);
  return id;
};
export const getActiveFilter = org => OrganizationModel.getActiveFilter(org);
export const isOrgDisabled = id => OrganizationModel.isDisabled(id);
export const isScimEnabled = id => OrganizationModel.isScimEnabled(id);
export const templatesAreEnabled = () => OrganizationModel.templatesAreEnabled();
export const useOrganizations = () => {
  const {
    entities
  } = useEntities({
    model: OrganizationModel
  });
  return entities;
};
export const useOrganization = id => {
  const {
    entity: org
  } = useEntity({
    model: OrganizationModel,
    id
  });
  return {
    org
  };
};
export const useCurrentOrg = () => useOrganization(useCurrentOrgId());
export const useWorkspaceUtcOffset = () => {
  const {
    org
  } = useCurrentOrg();
  return org ? org.utc_offset : undefined;
};
export const isIntegrationEnabled = id => OrganizationModel.isIntegrationEnabled(id);
export const allowIntegration = id => new Promise((resolve, reject) => OrganizationModel.allowIntegration(id, err => err ? reject(err) : resolve()));
export const disallowIntegration = id => new Promise((resolve, reject) => OrganizationModel.disallowIntegration(id, err => err ? reject(err) : resolve()));
export const fetchCurrent = () => new Promise(resolve => OrganizationModel.fetchCurrent(resolve));
export const fetchAutoJoinEnabled = () => OrganizationModel.Promises.fetchAutoJoinEnabled();
export const setAutoJoinEnabled = data => OrganizationModel.Promises.setAutoJoinEnabled(data);
export const fetchAvailableDomains = () => OrganizationModel.Promises.fetchAvailableDomains();
export const fetchEnabledDomains = () => OrganizationModel.Promises.fetchEnabledDomains();
export const setEnabledDomains = data => OrganizationModel.Promises.setEnabledDomains(data);
export const enableIterations = () => OrganizationModel.enableIterations();
export const disableIterations = () => OrganizationModel.disableIterations();
export const getCurrentUtcOffset = () => OrganizationModel.getCurrentUtcOffset();
export const useIterationsEnabledForCurrentOrg = () => {
  const {
    org
  } = useCurrentOrg();
  return Boolean(org.iterations_enabled);
};
export const isIterationsEnabledForCurrentOrg = () => {
  return OrganizationModel.iterationsAreEnabled();
};
export const getPaymentPlanForOrganization = org => PaymentPlan2Model.get({
  companyId: CompanyModel.getFromOrg(org).id
});
export const getOrganizationOwners = org => {
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id);
  return profile?.company?.owners || [];
};
export const getOrgWorkingDays = () => OrganizationModel.getCurrent().working_days_in_a_week;
export const getOrgProfileForLoggedInUser = id => OrganizationProfileModel.getOrgProfileForLoggedInUser(id);
export const getAllActive = () => OrganizationModel.getAllActive();
export const useIntegrationState = id => {
  const [isEnabled, setEnabled] = useState(null);
  useEffect(() => {
    fetchCurrent().then(() => {
      setEnabled(isIntegrationEnabled(id));
    });
  }, [id]);
  const enable = useCallback(async () => {
    try {
      setEnabled(true);
      await allowIntegration(id);
      return true;
    } catch (err) {
      setEnabled(false);
      return false;
    }
  }, [id]);
  const disable = useCallback(async () => {
    try {
      setEnabled(false);
      await disallowIntegration(id);
      return true;
    } catch (err) {
      setEnabled(true);
      return false;
    }
  }, [id]);
  const toggle = useCallback(async () => {
    if (typeof isEnabled !== 'boolean') return;
    return isEnabled ? disable() : enable();
  }, [isEnabled, disable, enable]);
  return [isEnabled, Object.assign(toggle, {
    enable,
    disable
  })];
};