import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import ShortcutLoading from '@clubhouse/assets/gif/shortcut-loading.gif';
import dottyErrorIllustration from '@clubhouse/assets/png/DotError.png';
import { THEME_NAMES, ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { GlobalErrorProvider } from 'components/signup/useGlobalError';
import { SignupProvider } from './SignupContext';
import { SignupPageContent } from './SignupPageContent';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const SignupPage = () => {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Helmet, null, ___EmotionJSX("link", {
    rel: "prefetch",
    href: ShortcutLoading
  }), ___EmotionJSX("link", {
    rel: "prefetch",
    href: dottyErrorIllustration
  })), ___EmotionJSX(ThemeContextProvider, {
    themeName: THEME_NAMES.LIGHT
  }, ___EmotionJSX(GlobalErrorProvider, null, ___EmotionJSX(Router, null, ___EmotionJSX(SignupProvider, null, ___EmotionJSX(SignupPageContent, null))))));
};