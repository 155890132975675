import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LightArrow = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX("svg", {
    width: width,
    height: width,
    viewBox: "0 0 13 8",
    fill: fill
  }, ___EmotionJSX("path", {
    d: "M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.26921 0.659728 8.26921 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.26921 7.02369 8.26921 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0 4.5H12V3.5H0V4.5Z"
  }));
};
LightArrow.displayName = "LightArrow";