import classNames from 'classnames';
import { useState } from 'react';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { getNumberOfImports } from 'data/entity/import';
import { createSync, useActiveSync } from 'data/entity/sync';
// eslint-disable-next-line import/no-restricted-paths
import { SettingsPageHeader } from 'pages/settings/SettingsPageHeader';
import { ActiveJiraSyncPage } from './ActiveJiraSyncPage';
import { Importer } from './Importer';
import { PastImports } from './PastImports';
import { TeamLinkDialog } from './TeamLinkDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const NO_TEAM_VALUE = 'no-team';
export const ImportDataPage = _ref => {
  let {
    workflows,
    projects,
    clubhouseUsers,
    importModel,
    loadImportDetails,
    pushRouterState,
    resetRouterState
  } = _ref;
  const [activeTab, setActiveTab] = useState('importer');
  const [syncImport, setSyncImport] = useState();
  const activeSync = useActiveSync();
  const isCreateSyncDialogVisible = Boolean(syncImport);
  const closeCreateSyncDialog = () => setSyncImport(undefined);
  const openCreateSyncDialog = imp => setSyncImport(imp);
  const handleSyncSuccess = () => {
    addToast({
      Content: () => ___EmotionJSX(ToastText, null, "Jira Sync Created"),
      kind: 'success',
      timeout: 2000
    });
    setActiveTab('jira-sync');
  };
  const handleSyncError = () => {
    addToast({
      Content: () => ___EmotionJSX(ToastText, null, "JIRA Sync was unsuccessful. Please try again, or contact our support team."),
      kind: 'alert',
      timeout: 6000
    });
  };
  const handleCreateSync = async (teamId, importId) => {
    const id = importId ?? syncImport?.id;
    try {
      if (!id) throw new Error('Missing import ID');
      await createSync(id, teamId !== NO_TEAM_VALUE ? teamId : undefined);
      handleSyncSuccess();
    } catch (error) {
      handleSyncError();
    }
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SettingsPageHeader, {
    breadcrumbs: [{
      label: 'Import / Sync'
    }],
    activeItem: 'importData'
  }), ___EmotionJSX(TeamLinkDialog, {
    key: syncImport?.id,
    visible: isCreateSyncDialogVisible,
    syncImport: syncImport,
    onClose: closeCreateSyncDialog,
    createSync: handleCreateSync
  }), ___EmotionJSX("div", {
    className: "wrapper"
  }, ___EmotionJSX("div", {
    role: "tablist",
    className: "tabs file-tabs mini clearfix"
  }, ___EmotionJSX("button", {
    id: "importer-tab",
    role: "tab",
    "aria-selected": activeTab === 'importer',
    "aria-controls": "importer-panel",
    className: classNames('tab', {
      active: activeTab === 'importer'
    }),
    onClick: () => setActiveTab('importer'),
    style: {
      fontSize: 15
    }
  }, "Importer"), getNumberOfImports() > 0 && ___EmotionJSX("button", {
    id: "past-imports-tab",
    role: "tab",
    "aria-selected": activeTab === 'past-imports',
    "aria-controls": "past-imports-panel",
    className: classNames('tab', {
      active: activeTab === 'past-imports'
    }),
    onClick: () => setActiveTab('past-imports'),
    style: {
      fontSize: 15
    }
  }, "Past Imports"), !!activeSync && ___EmotionJSX("button", {
    id: "jira-sync-tab",
    role: "tab",
    "aria-selected": activeTab === 'jira-sync',
    "aria-controls": "jira-sync-panel",
    className: classNames('tab', {
      active: activeTab === 'jira-sync'
    }),
    onClick: () => setActiveTab('jira-sync'),
    style: {
      fontSize: 15
    }
  }, "Jira Sync")), activeTab === 'importer' ? ___EmotionJSX("div", {
    id: "importer-panel",
    role: "tabpanel",
    "aria-labelledby": "importer-tab"
  }, ___EmotionJSX(Importer, {
    existingWorkflows: workflows,
    existingProjects: projects,
    clubhouseUsers: clubhouseUsers,
    importModel: importModel,
    createSync: handleCreateSync,
    openCreateSyncDialog: openCreateSyncDialog,
    pushRouterState: pushRouterState,
    resetRouterState: resetRouterState
  })) : activeTab === 'past-imports' ? ___EmotionJSX("div", {
    id: "past-imports-panel",
    role: "tabpanel",
    "aria-labelledby": "past-imports-tab"
  }, ___EmotionJSX(PastImports, {
    openCreateSyncDialog: openCreateSyncDialog,
    loadImportDetails: loadImportDetails
  })) : activeTab === 'jira-sync' && activeSync ? ___EmotionJSX("div", {
    id: "jira-sync-panel",
    role: "tabpanel",
    "aria-labelledby": "jira-sync-tab"
  }, ___EmotionJSX(ActiveJiraSyncPage, {
    sync: activeSync,
    onRemove: () => setActiveTab('importer')
  })) : null));
};