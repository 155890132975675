import * as ModalDialogTemplate from 'app/client/core/views/templates/modalDialog.html';
import { PinButton } from 'components/shared/pin-items';
import { SIZE } from '@clubhouse/shared/utils/theme';
import AutocompleteController from '../controllers/autocomplete';
import DropdownModel from '../models/dropdown';
import Globals from '../_frontloader/globals';
import Is from './is';
import Log from './log';
import Router from '../_frontloader/router';
import Tooltip from './tooltip';
import Utils from './utils';
import User from './user';
import View from './view';
import { SelectState } from '@clubhouse/shared/utils/isSelectOpen';
import { createBackgroundLocationState, getCurrentBackgroundLocationState } from 'utils/navigation/backgroundLocationState';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const DEFAULT_WIDTH = 960;
const DIALOG_SELECTOR = '.modal-dialog';
const DIALOG_OVERLAY_SELECTOR = '.modal-dialog-overlay';
const PREVIOUS_URL_KEY = 'preDialogOpenUrl';
const defaultOptions = {
  classNames: false,
  left: false,
  fullscreen: false,
  top: false,
  type: '',
  width: false,
  autoHeight: false,
  verticallyCenter: false,
  containerClassNames: false,
  expandable: false,
  pinnableButtonProps: undefined,
  onDialogVisible: () => {},
  shouldAnimate: true,
  closeOnEscape: false,
  onExpand: null
};
exports.setPreviousUrl = function () {
  let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
  Globals.set(PREVIOUS_URL_KEY, url);
};
exports.getPreviousUrl = () => Globals.get(PREVIOUS_URL_KEY);
exports.open = (content, options) => {
  exports.unhide();
  const isAlreadyOpen = exports.isOpen();
  const state = exports.getState();
  state.isOpening = true;
  options = options || {};
  if (!options.rerender) {
    exports.close({
      force: true
    });

    // If it's still open after calling close, it's been cancelled.
    if (exports.isOpen()) {
      Log.log('Dialog.open has been cancelled.');
      state.isOpening = false;
      return false;
    }
  }
  options = _.assignIn({}, defaultOptions, options);
  state.onClose = options.onClose;
  state.onPrev = options.onPrev;
  state.onNext = options.onNext;
  state.onExpand = options.onExpand;
  state.onResize = options.onResize;
  state.closeOnEscape = options.closeOnEscape;
  state.disallowCloseOnEscape = options.disallowCloseOnEscape;
  state.shouldNotCloseFn = options.shouldNotCloseFn || _.noop;
  state.onDestroy = options.onDestroy;
  const classNames = (exports.isSlideshow() ? 'slideshow ' : '') + (options.classNames || '');
  $('body').addClass('has-modal-dialog');
  if (!options.rerender) {
    const html = ModalDialogTemplate.render({
      arrowTopLeft: options.arrowTopLeft,
      classNames,
      id: options.id,
      content,
      type: options.type,
      expandable: options.expandable,
      pinnableButtonProps: options.pinnableButtonProps,
      breadcrumbsProps: options.breadcrumbsProps
    });
    View.attach(html, 'body');
  } else {
    $('.modal-dialog .content').attr('class', 'content').addClass(classNames);
    $(DIALOG_SELECTOR).find('.scrollable-content').html(content);
    exports.renderPinButton(options.pinnableButtonProps);
    exports.renderBreadcrumbs(options.breadcrumbsProps);
  }
  exports.bindToEscapeKey();
  if (!Is.mobile()) {
    View.freezeBodyOverflow();
  }
  const EVENT_NAME = 'resize.DialogModule';
  $(window).off(EVENT_NAME).on(EVENT_NAME, _.throttle(() => exports.onResize(options), 50));
  if (!isAlreadyOpen && !Utils.animationDisabled() && options.shouldAnimate) {
    $(DIALOG_SELECTOR).addClass('large-element-is-hiding');
    setTimeout(() => {
      $(DIALOG_SELECTOR).removeClass('large-element-is-hiding').addClass('element-is-open');
      options.onDialogVisible();
    }, 0);
  } else {
    options.onDialogVisible();
  }
  if (options.route) {
    Router.pushState(_.isFunction(options.route) ? options.route() : options.route, options.title, createBackgroundLocationState());
  }
  exports.initSize(options);
  exports.onResize();
  if (_.isFunction(options.onOpen)) {
    options.onOpen();
  }
  state.isOpening = false;
};
exports.rerender = (content, options) => {
  const state = exports.getState();
  state.isOpening = true;
  options.rerender = true;
  exports.executeCloseFn();
  exports.destroy(true);
  exports.open(content, options);
};
exports.onResize = () => {
  const win = $(window);
  const dialogElement = $(DIALOG_SELECTOR);
  const dialogContainerWidth = dialogElement.find('.container').width();
  if (win.width() - dialogContainerWidth < 150) {
    dialogElement.addClass('under-max-width');
  } else {
    dialogElement.removeClass('under-max-width');
  }
  const state = exports.getState();
  if (_.isFunction(state.onResize)) {
    state.onResize($(DIALOG_SELECTOR));
  }
};
exports.initSize = options => {
  const dialogElement = $(DIALOG_SELECTOR);
  const containerElement = dialogElement.find('.container');
  containerElement.addClass(options.containerClassNames);
  containerElement.css({
    width: options.width || DEFAULT_WIDTH,
    top: options.top,
    left: options.left,
    transform: options.top && options.left ? 'none' : undefined
  });
};
exports.setWidth = width => {
  const dialogElement = $(DIALOG_SELECTOR);
  const containerElement = dialogElement.find('.container');
  containerElement.css({
    width
  });
  $(containerElement).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
    exports.onResize();
  });
};
exports.bindToEscapeKey = () => {
  const KEYDOWN_EVENT_NAME = 'keydown.DialogModule';
  const KEYUP_EVENT_NAME = 'keyup.DialogModule';
  let receivedEscapeKeyDown = false;
  $('body').off(KEYDOWN_EVENT_NAME).on(KEYDOWN_EVENT_NAME, e => {
    // If the window doesn't have focus when the keydown happens, we don't want to close the dialog.
    // Eg when the native "Find" dialog is opened, it will capture (and not propagate) the keydown event
    // In this case we don't want to close the dialog on keyup, since the user only meant to close the Find.
    if (Utils.keyPressed(e, 'ESCAPE')) {
      const state = exports.getState();
      const disallowCloseOnEscape = _.isFunction(state.disallowCloseOnEscape) ? state.disallowCloseOnEscape : _.noop;
      if (state.closeOnEscape && disallowCloseOnEscape() || SelectState.isSelectOpen) return;
      receivedEscapeKeyDown = true;
    }
  });
  $('body').off(KEYUP_EVENT_NAME).on(KEYUP_EVENT_NAME, e => {
    const _receivedEscapeKeyDown = receivedEscapeKeyDown;
    receivedEscapeKeyDown = false;
    const state = exports.getState();
    const disallowCloseOnEscape = _.isFunction(state.disallowCloseOnEscape) ? state.disallowCloseOnEscape : _.noop;
    if (state.closeOnEscape && Utils.keyPressed(e, 'ESCAPE')) {
      if (disallowCloseOnEscape() || SelectState.isSelectOpen || !_receivedEscapeKeyDown) {
        return;
      }
      exports.close();
    }
    if (Utils.isFocusedInFormElement()) {
      return;
    }
    if (Utils.keyPressed(e, ['LEFT_ARROW', 'J'], {
      shouldMatchWithAllModifiers: false
    }) && exports.prevArrowIsVisible()) {
      if (exports.disallowNavigation() || disallowCloseOnEscape()) {
        return;
      }
      exports.prev();
    } else if (Utils.keyPressed(e, ['RIGHT_ARROW', 'K'], {
      shouldMatchWithAllModifiers: false
    }) && exports.nextArrowIsVisible()) {
      if (exports.disallowNavigation() || disallowCloseOnEscape()) {
        return;
      }
      exports.next();
    }
  });
};
exports.disallowNavigation = () => {
  return Globals.get('imagePreviewIsOpen');
};
exports.shouldNotClose = force => {
  const state = exports.getState();
  return (state.shouldNotCloseFn || _.noop)() || force !== true && (DropdownModel.size() > 0 && !DropdownModel.isOnlyQuickSearch() || AutocompleteController.isClosing);
};
exports.executeCloseFn = () => {
  const state = exports.getState();
  if (_.isFunction(state.onClose)) {
    state.onClose();
    state.onClose = null;
  }
};
exports.hide = () => {
  const dialogEl = document.querySelector(DIALOG_SELECTOR);
  const dialogOverlayEl = document.querySelector(DIALOG_OVERLAY_SELECTOR);
  if (dialogEl) dialogEl.style.display = 'none';
  if (dialogOverlayEl) dialogOverlayEl.style.display = 'none';
};
exports.unhide = () => {
  const dialogEl = document.querySelector(DIALOG_SELECTOR);
  const dialogOverlayEl = document.querySelector(DIALOG_OVERLAY_SELECTOR);
  if (dialogEl) dialogEl.style.removeProperty('display');
  if (dialogOverlayEl) dialogOverlayEl.style.removeProperty('display');
};
exports.close = options => {
  const state = exports.getState();
  const {
    title: backgroundTitle,
    href
  } = getCurrentBackgroundLocationState().backgroundLocation ?? {};
  options = options || {};
  if (exports.shouldNotClose(options.force)) {
    return false;
  }
  exports.executeCloseFn();
  if (!exports.isOpening()) {
    Router.pushState(href || exports.getPreviousUrl() || Globals.get('initialHref'), backgroundTitle);
    exports.setPreviousUrl(null);
  }
  exports.destroy();
  if (!Is.mobile()) {
    View.unfreezeBodyOverflow();
  }
  state.disallowCloseOnEscape = null;
  state.shouldNotCloseFn = _.noop;
  if (!Utils.animationDisabled()) {
    state.isClosing = true;
    setTimeout(() => {
      state.isClosing = false;
    }, 0);
  }
  return false;
};
exports.expand = () => {
  const state = exports.getState();
  Tooltip.close();
  $(DIALOG_SELECTOR).find('.container').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
    exports.toggleExpandButtonLabel();
  });
  if (_.isFunction(state.onExpand)) {
    state.onExpand();
  }
};
const EXPAND_LABELS = {
  decrease: {
    label: 'Decrease Width',
    icon: 'fa-compress'
  },
  increase: {
    label: 'Increase Width',
    icon: 'fa-expand'
  }
};
exports.getStoryDialogExpandedLabel = () => {
  return User.isStoryDialogExpanded() ? EXPAND_LABELS.decrease : EXPAND_LABELS.increase;
};
exports.toggleExpandButtonLabel = function () {
  let $element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $(DIALOG_SELECTOR).find('.expand-button');
  const labels = exports.getStoryDialogExpandedLabel();
  $element.removeClass((i, name) => (name.match(/(^|\s)fa-\S+/g) || []).join('')).addClass(labels.icon).attr('data-tooltip', labels.label);
};
exports.isSlideshow = () => {
  const state = exports.getState();
  return _.isFunction(state.onPrev) || _.isFunction(state.onNext);
};
exports.prev = () => {
  const state = exports.getState();
  if (_.isFunction(state.onPrev)) {
    state.onPrev();
  }
  return false;
};
exports.next = () => {
  const state = exports.getState();
  if (_.isFunction(state.onNext)) {
    state.onNext();
  }
  return false;
};
exports.hidePrevButton = () => {
  $(DIALOG_SELECTOR).find('.prev').hide();
};
exports.showPrevButton = () => {
  $(DIALOG_SELECTOR).find('.prev').show();
};
exports.prevArrowIsVisible = () => {
  return $(DIALOG_SELECTOR).find('.prev').is(':visible');
};
exports.hideNextButton = () => {
  $(DIALOG_SELECTOR).find('.next').hide();
};
exports.showNextButton = () => {
  $(DIALOG_SELECTOR).find('.next').show();
};
exports.nextArrowIsVisible = () => {
  return $(DIALOG_SELECTOR).find('.next').is(':visible');
};
exports.destroy = rerender => {
  const state = exports.getState();
  state.onPrev = null;
  state.onNext = null;
  state.onExpand = null;
  state.onResize = null;
  $(window).off('resize.DialogModule');
  $('body').removeClass('has-modal-dialog').off('.DialogModule');
  if (rerender !== true) {
    $(DIALOG_SELECTOR + ', .modal-dialog-overlay').remove();
  }
  state?.onDestroy?.();
};
exports.getElement = () => {
  return $(DIALOG_SELECTOR);
};
exports.isOpen = () => {
  return exports.getElement().length > 0;
};
exports.isOpening = () => {
  return exports.getState().isOpening;
};
exports.isClosing = () => {
  return exports.getState().isClosing;
};
exports.closeIfOpen = function () {
  if (exports.isOpen()) {
    exports.close(...arguments);
  }
};
exports.getState = () => {
  return Globals.setOnlyIfMissing('DialogState', {});
};
exports.renderPinButton = props => {
  if (!props) return '';
  return View.renderComponentDelayed({
    componentKey: `pin-button-story-dialog`,
    cssClass: 'story-pin-button',
    component: props.globalId ? ___EmotionJSX(PinButton, {
      id: props.globalId,
      size: SIZE.SMALL,
      loggingContext: {
        typename: 'Story'
      }
    }) : ___EmotionJSX(React.Fragment, null)
  }).html;
};
exports.renderBreadcrumbs = props => {
  if (!props) return '';
  const {
    globalId,
    publicId,
    Component
  } = props;
  return View.renderComponentDelayed({
    componentKey: 'breadcrumbs-story-dialog',
    cssClass: 'story-breadcrumbs',
    component: globalId && Component ? ___EmotionJSX(Component, {
      publicId: publicId,
      globalId: globalId
    }) : ___EmotionJSX(React.Fragment, null)
  }).html;
};
export { exports as default };