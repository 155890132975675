function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { EpicProgressFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { TableProgress } from 'components/shared/table/base';
import { getProgressStats } from '../../progress';
import { jsx as ___EmotionJSX } from "@emotion/react";
EpicProgressFieldFragmentFragmentDoc;
function ProgressFieldComponent(_ref) {
  let {
    entity: {
      stats
    },
    unit
  } = _ref;
  const isUsingPoints = unit === 'point';
  const progressStats = useMemo(() => getProgressStats(stats, isUsingPoints), [stats, isUsingPoints]);
  return ___EmotionJSX(TableProgress, {
    entityType: Nouns.Epic.singular,
    progressStats: progressStats,
    unit: isUsingPoints ? Nouns.Point : Nouns.Story
  });
}
ProgressFieldComponent.displayName = "ProgressFieldComponent";
export const ProgressField = unit => ({
  name: 'progress',
  displayName: 'Progress',
  Component: props => ___EmotionJSX(ProgressFieldComponent, _extends({}, props, {
    unit: unit
  })),
  width: 110,
  sort: true
});