import { CopyInput } from '@clubhouse/shared/components/CopyInput';
import { generateStoryUrl } from 'data/entity/story';
import { getCurrentOrigin } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryPermalink = _ref => {
  let {
    story
  } = _ref;
  return ___EmotionJSX("div", {
    className: "attribute"
  }, ___EmotionJSX("span", {
    className: "name"
  }, "Permalink"), ___EmotionJSX(CopyInput, {
    value: `${getCurrentOrigin()}${generateStoryUrl(story)}`,
    className: "clipboard"
  }));
};
StoryPermalink.displayName = "StoryPermalink";