import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { id } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IdFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(TableText, {
    text: entity.id
  });
};
IdFieldComponent.displayName = "IdFieldComponent";
export const IdField = function () {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: FIELD_ID.ID,
    displayName: 'ID',
    sort: id,
    headerProps: {
      centered: true
    },
    Component: IdFieldComponent,
    width: 75,
    maxWidth: 90,
    ...overrides
  };
};