import { useCallback } from 'react';
import { saveChanges } from 'data/entity/story';
import { storyType } from 'utils/sort';
import { FIELD_ID } from 'components/shared/table/types';
import { TableStoryType } from './tableComponents';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TypeFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  const save = useCallback(_ref2 => {
    let [newStoryType] = _ref2;
    saveChanges(entity.id, {
      story_type: newStoryType
    });
  }, [entity.id]);
  return ___EmotionJSX(TableStoryType, {
    onChange: save,
    type: entity.story_type
  });
}
TypeFieldComponent.displayName = "TypeFieldComponent";
export const TypeField = () => ({
  name: FIELD_ID.TYPE,
  displayName: 'Type',
  sort: storyType,
  Component: TypeFieldComponent,
  width: 120
});