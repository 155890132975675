function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useMemo } from 'react';
import { SelectOptionChecked, SelectOptionDefault, SelectOptionDivider, SelectOptionSubLabel, ViewSettingsHeader } from '@clubhouse/shared/components/Select';
import { Switch } from '@clubhouse/shared/components/Switch';
import { useToggleState } from '@clubhouse/shared/hooks';
import { CardDensityOptions } from '@clubhouse/shared/types';
import { capitalize } from '@clubhouse/shared/utils';
import ViewSettingsController from 'app/client/core/js/controllers/viewSettings';
import { useCustomFields } from 'data/entity/customField';
import { useFeature } from 'data/entity/feature';
import { useAppState } from 'utils/appState';
import { noop } from '../nodash';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CollapseEmptyColumnsOption = props => {
  // Internal toggle state for smoother UI feedback on toggle change
  const {
    value,
    onClick
  } = props;
  const [toggleState, toggle] = useToggleState(Boolean(value));
  return ___EmotionJSX(SelectOptionDefault, _extends({}, props, {
    onClick: () => {
      toggle();
      onClick?.();
    },
    isSelected: false
  }), ___EmotionJSX(Switch.Label, null, ___EmotionJSX(Switch, {
    size: "small",
    isChecked: toggleState,
    onChange: noop
  })));
};
CollapseEmptyColumnsOption.displayName = "CollapseEmptyColumnsOption";
export const useCardColorMenuItems = onChange => {
  const {
    on: areProjectsEnabled
  } = useFeature({
    id: 'projects'
  });
  const {
    fields
  } = useCustomFields({
    filter: _ref => {
      let {
        enabled
      } = _ref;
      return enabled;
    }
  });
  const items = useMemo(() => {
    const customFieldOptions = fields.map(_ref2 => {
      let {
        name
      } = _ref2;
      return {
        name: capitalize(name),
        value: name
      };
    });
    return [{
      name: 'Team',
      value: 'team'
    }, {
      name: 'Project',
      value: 'project'
    }, {
      name: 'Story Type',
      value: 'story-type'
    }].filter(option => {
      if (!areProjectsEnabled) return option.value !== 'project';
      return true;
    }).concat(customFieldOptions).map(_ref3 => {
      let {
        name,
        value
      } = _ref3;
      return {
        Component: SelectOptionChecked,
        name,
        value,
        key: value,
        onClick() {
          onChange(value);
        }
      };
    });
  }, [areProjectsEnabled, fields, onChange]);
  return items;
};
export const useViewSettingsMenuItems = () => {
  const [appStateCollapseState] = useAppState({
    appStateKey: 'CollapseEmptyColumns',
    default: false
  });
  const cardColorMenuItems = useCardColorMenuItems(ViewSettingsController.cardColor.set);
  const collapseEmptyColumnsMenuItem = useMemo(() => ({
    name: 'Collapse empty columns',
    Component: CollapseEmptyColumnsOption,
    value: appStateCollapseState,
    key: 'collapse-state',
    onClick() {
      ViewSettingsController.collapseState.toggle();
    }
  }), [appStateCollapseState]);
  const cardDensityMenuItems = useMemo(() => CardDensityOptions.map(density => ({
    Component: SelectOptionChecked,
    name: capitalize(density.replace(/-/g, ' ')),
    value: density,
    key: density,
    onClick() {
      ViewSettingsController.density.set(density);
    }
  })), []);
  const items = useMemo(() => [{
    Component: SelectOptionSubLabel,
    key: 'collapse-columns',
    name: 'Workflow States'
  }, collapseEmptyColumnsMenuItem, {
    Component: SelectOptionDivider,
    name: undefined,
    value: undefined
  }, {
    Component: ViewSettingsHeader,
    key: 'view-density-title',
    name: 'View Density'
  }, ...cardDensityMenuItems, {
    Component: SelectOptionDivider,
    name: undefined,
    value: undefined
  }, {
    Component: ViewSettingsHeader,
    key: 'card-color-title',
    name: 'Card Color'
  }, ...cardColorMenuItems], [cardColorMenuItems, cardDensityMenuItems, collapseEmptyColumnsMenuItem]);
  return items;
};