function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { DisplayableIconFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { DisplayIcon } from '../DisplayIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DisplayableIconFragment = DisplayableIconFragmentDoc;
export const DisplayableIcon = _ref => {
  let {
    displayable,
    size
  } = _ref;
  if (!displayable.displayableIcon) {
    return null;
  }
  return ___EmotionJSX(DisplayIcon, _extends({}, displayable.displayableIcon, {
    size: size
  }));
};
DisplayableIcon.displayName = "DisplayableIcon";