import last from 'lodash/last';
import { useEffect, useState } from 'react';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import ColumnModel from 'app/client/core/js/models/column';
import TeamModel from 'app/client/core/js/models/team';
import WorkflowModel from 'app/client/core/js/models/workflow';
import { getById as getColumnById } from 'data/entity/column';
import { useEntities, useEntity } from './collection';
import { getRequiredWorkflows } from './group';
export const settingsUrl = () => WorkflowModel.settingsUrl();
export const useWorkflows = () => {
  const {
    entities: workflows
  } = useEntities({
    model: WorkflowModel
  });
  return {
    workflows
  };
};
export const useWorkflow = _ref => {
  let {
    id
  } = _ref;
  const {
    entity: workflow
  } = useEntity({
    model: WorkflowModel,
    id
  });
  return {
    workflow
  };
};

/**
 *  @deprecated Use `useTeams()` from data/entity/teams instead.
 */
export const useWorkflowTeams = () => {
  const {
    entities: workflows
  } = useEntities({
    model: TeamModel
  });
  return {
    workflows
  };
};
export const useWorkflowStates = (id, type) => {
  const {
    workflow
  } = useWorkflow({
    id
  });
  return (workflow?.states || []).filter(state => (!type || state.type === type) && getColumnById(state.id)).map(state => {
    const column = getColumnById(state.id);
    return {
      ...column,
      isDefault: workflow.default_state_id === column.id
    };
  });
};
export const getStateWithName = (workflow, stateName) => WorkflowModel.getStateWithName(workflow, stateName);
export const getById = id => WorkflowModel.getById(id);
export const getByIdOrDefault = id => id && getById(id) || WorkflowModel.get();
export const getStatesForWorkflow = workflow_id => {
  return ColumnModel.filter({
    workflow_id
  });
};
export const getFirstStateOfTypeForWorkflow = (workflowId, type) => {
  return getStatesForWorkflow(workflowId).filter(workflowState => workflowState.type === type).sort((a, b) => a.position - b.position).shift();
};
export const getFirstStartedStateForWorkflow = workflowId => getFirstStateOfTypeForWorkflow(workflowId, WORKFLOW_STATE_TYPES.STARTED);
export const getFirstDoneStateForWorkflow = workflowId => getFirstStateOfTypeForWorkflow(workflowId, WORKFLOW_STATE_TYPES.DONE);
export const getLastDoneStateForWorkflow = workflowId => last(getStatesForWorkflow(workflowId));
export const getWorkflowStateIfInWorkflow = (workflowId, currentWorkflowStateId) => {
  const workflowStatesInWorkflow = getStatesForWorkflow(workflowId);
  const currentWorkflowState = workflowStatesInWorkflow.find(workflowState => workflowState.id === Number(currentWorkflowStateId));
  return currentWorkflowState;
};
export const getAllWorkflows = () => TeamModel.all().map(team => team.workflow);
export const getDefaultStateIdForWorkflow = workflowId => {
  const workflow = getById(workflowId);
  return workflow?.default_state_id;
};
export const getDefaultWorkflow = () => {
  return WorkflowModel.first();
};
export const useCurrentWorkflowTeam = () => {
  const {
    workflows
  } = useWorkflowTeams();
  return {
    workflow: workflows.length ? TeamModel.getActive() : null
  };
};
/**
 * This hook gives you a function that can be used to figure out whether a group can be removed from
 * a workflow. The backend has an endpoint which tells us which workflows are "required" for a
 * group.
 *
 * A "required" workflow is one which has at least one story, in that workflow, assigned to the
 * group in question.
 *
 * Gotchas:
 *  - canRemove will return false while the data is still loading
 *  - This data is not reactive. If a story is added or removed in a different tab, it is not
 *    updated. Although the hook is reset every time the parent component unmounts, so if someone
 *    were to navigate away and come back the data would be fresh.
 *  - You have to provide all of the N groupIds and M workflowIds you are interested in ahead of
 *    time. Then canRemove will return a value for any tuple in [N,M]. If you call it with some
 *    other group or workflow ID it will throw an error
 */
export const useCanRemoveGroupFromWorkflow = _ref2 => {
  let {
    workflowIds,
    groupIds
  } = _ref2;
  const [requiredWorkflowIdsByGroupId, setRequiredWorkflowIds] = useState({});

  // biome-ignore lint/correctness/useExhaustiveDependencies: ?
  useEffect(() => {
    if (groupIds.length < 1) return;
    const promises = groupIds.map(groupId => {
      if (requiredWorkflowIdsByGroupId[groupId]) return null; // this means we already requested for this group

      const promise = getRequiredWorkflows(groupId);
      setRequiredWorkflowIds(existing => ({
        ...existing,
        [groupId]: promise // stick the promise in there temporarily so we don't try to request it again
      }));
      return promise;
    });
    (async () => {
      const responses = await Promise.all(promises);
      for (const [i, groupId] of groupIds.entries()) {
        const response = responses[i];
        if (response == null) continue; // We already fetched this one in a previous tickt

        const {
          required_workflows: requiredWorkflowIds
        } = response;
        setRequiredWorkflowIds(existing => ({
          ...existing,
          [groupId]: requiredWorkflowIds
        }));
      }
    })();
  }, [workflowIds, groupIds, requiredWorkflowIdsByGroupId]);
  const canRemove = _ref3 => {
    let {
      workflowId,
      groupId
    } = _ref3;
    if (!workflowId) {
      return true;
    }
    if (!workflowIds.includes(workflowId)) {
      throw new Error('Cannot say whether this group can be removed from this workflow because the workflowId was not one of the workflowIds you passed to useCanRemoveGroupFromWorkflow');
    }
    if (!groupIds.includes(groupId)) {
      throw new Error('Cannot say whether this group can be removed from this workflow because the groupId was not one of the groupIds you passed to useCanRemoveGroupFromWorkflow');
    }
    const requiredWorkflowIds = requiredWorkflowIdsByGroupId[groupId];
    if (!requiredWorkflowIds) return false; // We haven't even started fetching yet
    if (requiredWorkflowIds instanceof Promise) return false; // We started fetching this one, but haven't received a response yet

    return !requiredWorkflowIds.includes(workflowId);
  };
  return canRemove;
};
export const verifyWorkflowIds = values => {
  return values.some(v => !WorkflowModel.getById(v));
};
export const updateAutoAssignOwner = (workflow, auto_assign_owner, callback) => WorkflowModel.updateAutoAssignOwner(workflow, auto_assign_owner, callback);
export const updateDefaultWorkflowState = (workflow, id, callback) => WorkflowModel.updateDefaultState(workflow, id, callback);