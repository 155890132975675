function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useContextMenu } from '@clubhouse/shared/components/ContextMenu';
import { logContextMenuAction, logContextMenuClose, logContextMenuOpen } from './logging';
import { jsx as ___EmotionJSX } from "@emotion/react";
const createEventLogger = (loggingContext, logOptionalEvents) => (eventType, details) => {
  if (eventType === 'open') {
    logContextMenuOpen(loggingContext);
  } else if (eventType === 'close') {
    logContextMenuClose(loggingContext);
  } else if (eventType === 'action') {
    logContextMenuAction({
      ...loggingContext,
      action: details
    });
    logOptionalEvents?.();
  }
};
export const useContextMenuWithLogging = (Menu, loggingContext) => {
  return useContextMenu(Menu ? p => ({
    Menu: props => ___EmotionJSX(Menu, _extends({}, p, props)),
    onEvent: createEventLogger(loggingContext)
  }) : null);
};
export const useContextMenuWithLoggingOptionalEvents = (Menu, loggingContext, logOptionalEvents) => {
  return useContextMenu(Menu ? p => ({
    Menu: props => ___EmotionJSX(Menu, _extends({}, p, props)),
    onEvent: createEventLogger(loggingContext, logOptionalEvents)
  }) : null);
};
export const useDynamicContextMenuWithLogging = getMenuAndLoggingContext => {
  return useContextMenu(function () {
    const data = getMenuAndLoggingContext(...arguments);
    if (!data) return null;
    const {
      loggingContext,
      Menu
    } = data;
    return {
      Menu,
      onEvent: createEventLogger(loggingContext)
    };
  });
};