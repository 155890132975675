/* All descriptions are of ASCENDING behavior. */

import isNil from 'lodash/isNil';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { MilestoneState } from '@clubhouse/shared/types';
export const getPropertySort = getter => (a, b) => {
  const aValue = getter(a);
  const bValue = getter(b);
  if (!isNil(aValue) && !isNil(bValue)) return aValue.toString().localeCompare(bValue.toString());
  if (!isNil(aValue) && isNil(bValue)) return -1;
  if (isNil(aValue) && !isNil(bValue)) return 1;
  return 0;
};
export const workflow = getPropertySort(e => e.workflow_id);
export const workflowState = getPropertySort(e => e.position);
export const storyEpic = getPropertySort(e => e.epic?.name);
export const storyIteration = getPropertySort(e => e.iteration?.start_date);
export const storyDeadline = getPropertySort(e => e.deadline);
export const storyProject = getPropertySort(e => e.project?.name);
export const storyType = getPropertySort(e => e.story_type);
export const storyEstimate = (a, b) => {
  if (a.estimate === b.estimate) return 0;
  if (isNil(a.estimate)) return 1;
  if (isNil(b.estimate)) return -1;
  return a.estimate - b.estimate;
};
export const getCustomFieldSort = _ref => {
  let {
    values,
    id
  } = _ref;
  return (a, b) => {
    const aValue = a.custom_fields.find(f => f.field_id === id);
    const bValue = b.custom_fields.find(f => f.field_id === id);
    if (!isNil(aValue) && !isNil(bValue)) {
      const aValuePosition = values.findIndex(v => v.id === aValue.value_id);
      const bValuePosition = values.findIndex(v => v.id === bValue.value_id);
      return aValuePosition - bValuePosition;
    }
    if (!isNil(aValue) && isNil(bValue)) return -1;
    if (isNil(aValue) && !isNil(bValue)) return 1;
    return 0;
  };
};
/* Lowest position -> Highest position */
export const position = (a, b) => {
  return a.position - b.position;
};
export const id = (a, b) => {
  return Number.parseInt(a.id, 10) - Number.parseInt(b.id, 10);
};
export const publicId = (a, b) => Number.parseInt(a.publicId) - Number.parseInt(b.publicId);

/* Color A -> Z */
export const color = (a, b) => {
  const aColor = (a.color || '').toLowerCase();
  const bColor = (b.color || '').toLowerCase();
  if (aColor === bColor) return 0;
  return aColor > bColor ? 1 : -1;
};

/* A -> Z */
export const name = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

/* A -> Z */
export const maybeName = (a, b) => {
  if ((a.name || '').toLowerCase() < (b.name || '').toLowerCase()) return -1;
  if ((a.name || '').toLowerCase() > (b.name || '').toLowerCase()) return 1;
  return 0;
};
/**
 * @deprecated Use sort.lastUpdated if you want the model's last update, not just when its last story updated
 */
/* Most Recently Modified -> Least Recently Modified */
export const modified = (a, b) => {
  const aLast = a.last_story_update || a.created_at;
  const bLast = b.last_story_update || b.created_at;
  if (!aLast && bLast) return 1;
  if (!bLast && aLast) return -1;
  if (aLast === bLast) return 0;
  return new Date(aLast) > new Date(bLast) ? -1 : 1;
};
/* Most Recently Modified -> Least Recently Modified */
export const lastUpdated = (a, b) => {
  const aLast = a.updated_at || a.created_at;
  const bLast = b.updated_at || b.created_at;
  if (!aLast && bLast) return 1;
  if (!bLast && aLast) return -1;
  if (aLast === bLast) return 0;
  return new Date(aLast) > new Date(bLast) ? -1 : 1;
};

/* Most Recently Created -> Least Recently Created */
export const created = (a, b) => {
  if (a.created_at === b.created_at) return 0;
  return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1;
};

/* Least # Stories -> Most # Stories */
/* Least Recently Modified -> Most Recently Modified */
export const totalStories = (a, b) => {
  if (a.totalStories === b.totalStories) return modified(a, b) * -1;
  return a.totalStories > b.totalStories ? 1 : -1;
};

/* Least # Epics -> Most # Epics */
/* Least Recently Modified -> Most Recently Modified */
export const totalEpics = (a, b) => {
  if (a.epics.length === b.epics.length) return modified(a, b) * -1;
  return a.epics.length > b.epics.length ? 1 : -1;
};

/* Least # Points -> Most # Points */
/* Least Recently Modified -> Most Recently Modified */
export const points = (a, b) => {
  if (a.num_points === b.num_points) return modified(a, b) * -1;
  return a.num_points > b.num_points ? 1 : -1;
};

/* Least # Docs -> Most # Docs */
/* Least Recently Modified -> Most Recently Modified */
export const docs = (a, b) => {
  if (a.stats.num_related_documents === b.stats.num_related_documents) return modified(a, b) * -1;
  return a.stats.num_related_documents > b.stats.num_related_documents ? 1 : -1;
};
export const requester = getPropertySort(story => story.requested_by?.name);

/* No Owners -> Owners */
/* 1st Owner Name A -> Z */
/* Least Recently Modified -> Most Recently Modified */
export const owners = (a, b) => {
  const aOwners = a.owners.filter(owner => !owner.deactivated && !owner.disabled);
  const bOwners = b.owners.filter(owner => !owner.deactivated && !owner.disabled);
  if (aOwners.length === 0 && bOwners.length > 0) {
    return 1;
  }
  if (aOwners.length > 0 && bOwners.length === 0) {
    return -1;
  }
  const diff = aOwners.length - bOwners.length;
  if (diff !== 0) {
    return diff;
  }
  const aNames = aOwners.map(owner => owner.lowercase_name).join('-');
  const bNames = bOwners.map(owner => owner.lowercase_name).join('-');
  if (aNames === bNames && a.entity_type === 'epic') return modified(a, b) * -1;
  return aNames > bNames ? 1 : -1;
};

/* No Labels -> Labels */
/* 1st Label Name A -> Z */
export const labels = (a, b) => {
  if (a.labels.length > b.labels.length) return 1;
  if (a.labels.length < b.labels.length) return -1;
  const aNames = a.labels.map(label => label.name.toLowerCase()).join('-');
  const bNames = b.labels.map(label => label.name.toLowerCase()).join('-');
  if (aNames === bNames) return 0;
  return aNames > bNames ? 1 : -1;
};
const sortAndJoinByName = list => {
  return list.sort((a, b) => a.name.localeCompare(b.name, undefined, {
    sensitivity: 'base'
  })).map(listItem => listItem.name.toLowerCase()).join('-');
};

/* No Categories -> Categories */
/* 1st Categories Name A -> Z */
export const categories = (a, b) => {
  const diff = a.categories.length - b.categories.length;
  if (diff !== 0) {
    return diff;
  }
  const aCategoriesSortedByName = sortAndJoinByName(a.categories);
  const bCategoriesSortedByName = sortAndJoinByName(b.categories);
  if (aCategoriesSortedByName === bCategoriesSortedByName) return 0;
  return aCategoriesSortedByName > bCategoriesSortedByName ? 1 : -1;
};
const WORKFLOW_STATE_TYPE_ORDER = [WORKFLOW_STATE_TYPES.BACKLOG, WORKFLOW_STATE_TYPES.UNSTARTED, WORKFLOW_STATE_TYPES.STARTED, WORKFLOW_STATE_TYPES.DONE];

/**
 * This function will first sort by the Workflow State:
 * - If states are of different types, order is backlog -> unstarted -> started -> done
 * - If states are of same type, order is based on the state position.
 *   - If position is the same, we sort by state name (A-Z).
 * - If states are the same (ie exact same state) we sort by the story position.
 */
export const stateOrPosition = (a, b) => {
  if (a.stateObject.id === b.stateObject.id) return position(a, b);
  if (a.stateObject.type === b.stateObject.type) {
    if (a.stateObject.position === b.stateObject.position) return name(a.stateObject, b.stateObject);
    return a.stateObject.position - b.stateObject.position;
  }
  return WORKFLOW_STATE_TYPE_ORDER.indexOf(a.stateObject.type) - WORKFLOW_STATE_TYPE_ORDER.indexOf(b.stateObject.type);
};

/* Lowest State Position -> Highest State Position */
export const epicState = (a, b) => {
  if (a.stateObject.id === b.stateObject.id) return 0;
  return a.stateObject.position > b.stateObject.position ? 1 : -1;
};

/* [no state] -> Todo -> In Progress -> Done */
export const milestoneState = (a, b) => {
  const orderedStates = [MilestoneState.ToDo, MilestoneState.InProgress, MilestoneState.Done];
  const ai = orderedStates.indexOf(a.state);
  const bi = orderedStates.indexOf(b.state);
  if (ai === bi) return 0;
  if (ai === -1 && bi >= 0) return -1;
  if (bi === -1 && ai >= 0) return 1;
  return ai > bi ? 1 : -1;
};

/* Lowest Milestone position -> Highest Milestone position */
export const milestonePriority = (a, b) => {
  const aPosition = a.milestone?.position ?? null;
  const bPosition = b.milestone?.position ?? null;
  if (aPosition === bPosition) return 0;
  if (!isNil(aPosition) && !isNil(bPosition)) return aPosition > bPosition ? 1 : -1;
  if (isNil(aPosition) && !isNil(bPosition)) return 1;
  if (!isNil(aPosition) && isNil(bPosition)) return -1;
  return 0;
};

/* No Stories -> Stories */
/* Lowest % Stories Completed -> Highest % Stories Completed */
/* Lowest % Stories Active -> Highest % Stories Active */
/* Lowest # Stories -> Highest # Stories */
export const epicProgressByStories = (a, b) => {
  if (a.totalStories === 0 && b.totalStories > 0) return -1;
  if (b.totalStories === 0 && a.totalStories > 0) return 1;
  if (a.percentCompleted === b.percentCompleted) {
    if (a.percentActive === b.percentActive) return totalStories(a, b);
    return a.percentActive > b.percentActive ? 1 : -1;
  }
  return a.percentCompleted > b.percentCompleted ? 1 : -1;
};

/* No Points -> Points */
/* Lowest % Points Completed -> Highest % Points Completed */
/* Lowest % Points Active -> Highest % Points Active */
/* Lowest # Points -> Highest # Points */
export const epicProgressByPoints = (a, b) => {
  if (a.num_points === 0 && b.num_points > 0) return -1;
  if (b.num_points === 0 && a.num_points > 0) return 1;
  if (a.percentCompletedPoints === b.percentCompletedPoints) {
    if (a.percentActivePoints === b.percentActivePoints) return points(a, b);
    return a.percentActivePoints > b.percentActivePoints ? 1 : -1;
  }
  return a.percentCompletedPoints > b.percentCompletedPoints ? 1 : -1;
};
export const milestoneProgressByPoints = (a, b) => {
  if (a.num_points === 0 && b.num_points > 0) return -1;
  if (b.num_points === 0 && a.num_points > 0) return 1;
  if (a.percent_completed_points === b.percent_completed_points) {
    if (a.percent_active_points === b.percent_active_points) return points(a, b);
    return a.percent_active_points > b.percent_active_points ? 1 : -1;
  }
  return a.percent_completed_points > b.percent_completed_points ? 1 : -1;
};
export const milestoneProgressByStories = (a, b) => {
  if (a.num_stories_total === 0 && b.num_stories_total > 0) return -1;
  if (b.num_stories_total === 0 && a.num_stories_total > 0) return 1;
  if (a.percent_completed === b.percent_completed) {
    if (a.percent_active === b.percent_active) return totalEpics(a, b);
    return a.percent_active > b.percent_active ? 1 : -1;
  }
  return a.percent_completed > b.percent_completed ? 1 : -1;
};

// TODO: Figure out if getPropertySort above can be used instead.
const getSortByValue = key => (a, b) => {
  const aVal = a[key] ?? null;
  const bVal = b[key] ?? null;
  if (aVal === bVal) return 0;
  if (!isNil(aVal) && !isNil(bVal)) return aVal > bVal ? 1 : -1;
  if (!isNil(aVal) && isNil(bVal)) return 1;
  if (isNil(aVal) && !isNil(bVal)) return -1;
  return 0;
};

/* Earliest Start Date -> Latest Start Date */
export const milestoneStartDate = getSortByValue('started_at_override');

/* Earliest End Date -> Latest End Date */
export const milestoneEndDate = getSortByValue('completed_at_override');

/* Earliest Start Date -> Latest Start Date */
export const epicStartDate = getSortByValue('planned_start_date');

/* Earliest Due Date -> Latest Due Date */
export const epicDueDate = getSortByValue('deadline_timestamp');

/* Lowest Cycle Time -> Highest Cycle Time */
export const epicAvgCycleTime = getSortByValue('average_cycle_time');

/* No Milestone -> Milestone */
/* Milestone Name A -> Z */
export const epicMilestone = (a, b) => {
  if (a.milestone && !b.milestone) return 1;
  if (b.milestone && !a.milestone) return -1;
  if (a.milestone?.name === b.milestone?.name) return 0;
  return a.milestone.name > b.milestone.name ? 1 : -1;
};

// Sort epics by: State -> Name
export const sortByEpicStateThenName = (e1, e2) => {
  const sortingByState = epicState(e1, e2);
  if (sortingByState !== 0) return sortingByState;
  return name(e1, e2);
};