import { convertToGQL } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LABEL_BY_STATE = {
  toDo: 'To Do',
  inProgress: 'In Progress',
  done: 'Done'
};
export const ObjectiveStateDisplay = _ref => {
  let {
    state
  } = _ref;
  const objectiveState = convertToGQL(state);
  return ___EmotionJSX("span", null, LABEL_BY_STATE[objectiveState]);
};
ObjectiveStateDisplay.displayName = "ObjectiveStateDisplay";