import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function NewEntityDescriptionEditor(_ref) {
  let {
    initialValue,
    onChange,
    onSave,
    renderId
  } = _ref;
  return ___EmotionJSX("div", {
    key: renderId
  }, ___EmotionJSX(MarkdownEditor, {
    context: "Description",
    autoFocus: false,
    initialValue: initialValue,
    onChange: onChange,
    onCommandSave: onSave
  }));
}
NewEntityDescriptionEditor.displayName = "NewEntityDescriptionEditor";