import "core-js/modules/es.array.push.js";
import EpicController from 'app/client/stories/js/controllers/epic.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const EpicController = exports;
  [[['Model', 'Epic'], EpicModel], [['Controller', 'Epic'], EpicController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as EpicListTemplate from 'app/client/stories/views/templates/epicList.html';
import * as EpicListItemsTemplate from 'app/client/stories/views/templates/epicListItems.html';
import * as EpicListItemTemplate from 'app/client/stories/views/templates/epicListItem.html';
import * as ToggleMoreEpicsTemplate from 'app/client/stories/views/templates/toggleMoreEpics.html';
import * as EpicStateIconTemplate from 'app/client/core/views/templates/epicStateIcon.html';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import ArchiveWarningController from '../../../core/js/controllers/archiveWarning';
import AutocompleteController from '../../../core/js/controllers/autocomplete';
import ContextMenuController from '../../../core/js/controllers/contextMenu';
import EpicModel from '../../../core/js/models/epic';
import EpicStateModel from '../../../core/js/models/epicState';
import * as Event from '../../../core/js/_frontloader/event';
import FilterModel from '../../../core/js/models/filter';
import Format from '../../../core/js/modules/format';
import Globals from '../../../core/js/_frontloader/globals';
import Is from '../../../core/js/modules/is';
import MessageController from '../../../core/js/controllers/message';
import StoriesEpicModel from '../models/storiesEpic';
import StoriesStoryController from './storiesStory';
import StoriesView from '../modules/storiesView';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const EPIC_STATE_ICONS = {
  unstarted: 'fa-flag-o',
  started: 'fa-flag',
  done: 'fa-flag-checkered'
};
exports.refresh = err => {
  if (err) {
    MessageController.error(err, {
      secondary: 'Unable to update epic.'
    });
  }
  exports.render();
  Event.trigger('spaceUpdate');
  StoriesView.resetAndDrawStories();
};
exports.render = () => {
  renderHtml($('#epic-nav'), EpicListTemplate);
  $('#epic-nav-bucket-header').html(EpicModel.getListDisplayTypeHeader());
  return false;
};
exports.renderListItems = () => {
  const $el = $('#epic-nav');
  if ($el.find('.epic-nav-items').length) {
    return;
  }
  renderHtml($el, EpicListItemsTemplate);
};
const renderHtml = ($el, template) => {
  $el.find('.epic-parent.ui-droppable').droppable('destroy');
  let epics = EpicModel.getVisibleEpics();
  const teamScopeId = getTeamScopedCollectionizeId();
  if (teamScopeId) {
    epics = _.sortBy([...epics.filter(epic => epic.group_id === teamScopeId), ...EpicModel.getVisibleAssociatedEpicsForTeam(teamScopeId)], 'position');
  }
  const html = template.render({
    epics,
    moreEpics: EpicModel.getMoreEpics(epics),
    displayStoriesNotInEpics: EpicModel.shouldDisplayStoriesNotInEpics()
  });
  $el.html(html);
  ContextMenuController.refresh();
  exports.initNavDroppable();
};
function _reRenderEpicInList(epic) {
  const existingElement = $('.epic-parent[data-id="' + epic.id + '"]');
  const html = EpicListItemTemplate.render(epic);
  View.replaceElement(existingElement, html);
}
exports.toggleMoreEpics = () => {
  Globals.set('showMoreEpics', !Globals.get('showMoreEpics'));
  $('.more-epics').slideToggle(200);
  $('.toggle-more-epics').html(ToggleMoreEpicsTemplate.render());
  return false;
};
exports.disableAllEpics = () => {
  StoriesEpicModel.disableAll();
  EpicModel.displayStoriesNotInEpics();
  exports.refresh();
  return false;
};
exports.enableAllEpics = () => {
  StoriesEpicModel.enableAll();
  EpicModel.displayStoriesNotInEpics();
  exports.refresh();
  return false;
};
exports.initNavDroppable = () => {
  $('#epic-nav .epic-parent').droppable({
    hoverClass: 'drag-hover',
    drop: function (e, ui) {
      const element = ui.draggable[0];

      // Guard against sortable usage:
      if (!$(element).hasClass('story')) {
        return false;
      }
      const epic_id = Utils.data(this, 'id');
      StoriesStoryController.updateOneOrManyOnDrop(element, {
        epic_id
      });
    },
    tolerance: 'pointer'
  });
};
exports.toggleOrphanVisibility = function (e) {
  if (e.shiftKey) {
    const activeEpics = EpicModel.filter({
      active: true
    });
    if (activeEpics.length === 0) {
      return exports.enableAllEpics.call(this);
    } else {
      return exports.disableAllEpics.call(this);
    }
  }
  if (EpicModel.shouldDisplayStoriesNotInEpics()) {
    EpicModel.hideStoriesNotInEpics();
  } else {
    EpicModel.displayStoriesNotInEpics();
  }
  exports.refresh();
  return false;
};
exports.toggle = function (e, options) {
  options = options || {};
  const epic = Utils.getModelFromContext(this);
  if (e.shiftKey || options.force) {
    const activeEpics = EpicModel.filter({
      active: true
    });
    if (activeEpics.length === 1 && activeEpics[0].id === epic.id) {
      return exports.enableAllEpics.call(this);
    } else {
      return exports.highlight.call(this);
    }
  }
  StoriesEpicModel.toggle(epic);
  StoriesView.resetAndDrawStories();
  _reRenderEpicInList(epic);
  return false;
};
exports.selectDisplayType = type => {
  EpicModel.setListDisplayType(type);
  exports.refresh();
  ContextMenuController.close();
  return false;
};
exports.selectAllDisplayType = () => {
  exports.selectDisplayType('ALL');
};
exports.selectStartedDisplayType = () => {
  return exports.selectDisplayType('STARTED');
};
exports.selectFollowedDisplayType = () => {
  return exports.selectDisplayType('FOLLOWED');
};
exports.selectAllWithDoneDisplayType = () => {
  exports.selectDisplayType('INCLUDE_DONE');
};
const showOnlyEpicsFromSelections = value => {
  const isSelected = EpicModel.showEpicsFromSelectedGroups();
  if (isSelected === value) {
    return false;
  }
  EpicModel.setShowEpicsFromSelectedGroups(value);
  exports.render();
  return false;
};
exports.highlight = function () {
  EpicModel.hideStoriesNotInEpics();
  EpicModel.each(epic => {
    epic.active = false;
  });
  const epic = Utils.getModelFromContext(this);
  StoriesEpicModel.toggle(epic);
  exports.refresh();
  return false;
};
exports.moreActionsDropdownItems = () => {
  const epicListDisplayType = EpicModel.getListDisplayType();
  const showEpicsFromSelectedGroups = EpicModel.showEpicsFromSelectedGroups();
  const teamScopeId = getTeamScopedCollectionizeId();
  const menuItems = [{
    html: '<div class="caption"><em>Show in this list...</em></div>'
  }, ...(teamScopeId ? [] : [{
    name: 'Any Epics',
    iconLeft: showEpicsFromSelectedGroups ? 'fa-circle-o' : 'fa-circle',
    value: () => showOnlyEpicsFromSelections(false)
  }, {
    name: 'Only Epics from Selected Teams',
    iconLeft: showEpicsFromSelectedGroups ? 'fa-circle' : 'fa-circle-o',
    value: () => showOnlyEpicsFromSelections(true)
  }, {
    hr: true
  }]), {
    name: 'All Unfinished Epics',
    iconLeft: epicListDisplayType === 'ALL' ? 'fa-circle' : 'fa-circle-o',
    value: exports.selectAllDisplayType
  }, {
    name: 'Only In Progress Epics',
    iconLeft: epicListDisplayType === 'STARTED' ? 'fa-circle' : 'fa-circle-o',
    value: exports.selectStartedDisplayType
  }, {
    name: 'Only Epics I Follow',
    iconLeft: epicListDisplayType === 'FOLLOWED' ? 'fa-circle' : 'fa-circle-o',
    value: exports.selectFollowedDisplayType
  }, {
    name: 'All Epics',
    iconLeft: epicListDisplayType === 'INCLUDE_DONE' ? 'fa-circle' : 'fa-circle-o',
    value: exports.selectAllWithDoneDisplayType
  }];
  return menuItems;
};
exports.openEpicFilterDropdown = function () {
  AutocompleteController.open({
    items: _getEpicFilterItems(),
    className: 'epic-filter-autocomplete',
    target: this,
    targetSelector: '#epic-nav-bucket button',
    showInput: true,
    multiSelect: true,
    width: 260
  });
  return false;
};
exports.deselectEpicStateType = function () {
  const epicStateType = Utils.data(this, 'epic-state-type');
  const epics = EpicModel.getVisibleEpics().filter(epic => {
    return EpicStateModel.getById(epic.epic_state_id).type === epicStateType;
  });
  FilterModel.trigger('bulkStart');
  epics.forEach(epic => {
    FilterModel.remove({
      type: 'epic',
      value: epic.id
    });
  });
  FilterModel.trigger('bulkEnd');
  AutocompleteController.updateItems(_getEpicFilterItems());
  Event.trigger('spaceUpdate');
};
exports.selectEpicStateType = function () {
  const epicStateType = Utils.data(this, 'epic-state-type');
  const epics = EpicModel.getVisibleEpics().filter(epic => {
    return EpicStateModel.getById(epic.epic_state_id).type === epicStateType;
  });
  FilterModel.trigger('bulkStart');
  epics.forEach(epic => {
    FilterModel.addFixture('byEpic', epic, {
      quiet: true
    });
  });
  FilterModel.trigger('bulkEnd');
  AutocompleteController.updateItems(_getEpicFilterItems());
  Event.trigger('spaceUpdate');
};
function _getCurrentFilteredEpicIds() {
  return _.map(FilterModel.filter({
    type: 'epic'
  }), 'value');
}
function _isStateTypeSelected(epics, epicIds) {
  return _.difference(_.map(epics, 'id'), epicIds).length === 0;
}
function _getEpicFilterItems() {
  const visibleEpics = EpicModel.getVisibleEpics();
  const groupedEpics = _.groupBy(EpicModel.sortedByPositionAndState(visibleEpics), epic => {
    return EpicStateModel.getById(epic.epic_state_id).type;
  });
  const currentFilteredEpicIds = _getCurrentFilteredEpicIds();
  const noEpicFilter = FilterModel.get({
    fnName: 'noEpic'
  });
  const initialItems = [{
    name: '<em>None</em>',
    className: noEpicFilter ? 'selected' : '',
    value: () => {
      noEpicFilter ? _removeFilter(noEpicFilter) : FilterModel.addFixture('noEpic');
      AutocompleteController.updateItems(_getEpicFilterItems());
    }
  }];
  const epicFilters = _.reduce(groupedEpics, (result, epics, epicStateType) => {
    result.push({
      hr: true
    });
    const selectAllButton = _isStateTypeSelected(epics, currentFilteredEpicIds) ? `<a href="#"
          class="select-all-autocomplete-section"
          data-controller="Epic"
          data-on-click="deselectEpicStateType"
          data-epic-state-type="${epicStateType}"
        >Deselect all</a>` : `<a href="#"
          class="select-all-autocomplete-section"
          data-controller="Epic"
          data-on-click="selectEpicStateType"
          data-epic-state-type="${epicStateType}"
        >Select all</a>`;
    result.push({
      html: `<div class="caption">${_.startCase(epicStateType)} ${selectAllButton}</div>`
    });
    return result.concat(epics.map(epic => {
      const isSelected = _.includes(currentFilteredEpicIds, epic.id);
      return {
        className: isSelected ? 'selected' : '',
        name: Format.sanitizeAndEmojify(epic.name),
        iconLeft: EPIC_STATE_ICONS[epicStateType],
        value: () => {
          isSelected ? _removeFilter({
            type: 'epic',
            value: epic.id
          }) : FilterModel.addFixture('byEpic', epic);
          AutocompleteController.updateItems(_getEpicFilterItems());
        }
      };
    }));
  }, initialItems);
  const moreActionsDropdownItems = exports.moreActionsDropdownItems();
  return moreActionsDropdownItems.concat([{
    hr: true
  }]).concat(epicFilters);
}
function _removeFilter(filter) {
  FilterModel.remove(filter);
  exports.refresh();
}
exports.generalContextMenuItems = () => {
  return [{
    name: 'All',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: 'Show All Epics',
    value: exports.enableAllEpics
  }, {
    name: 'None',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "ViewOff"
    }))),
    tooltip: 'Deselect All Epics',
    value: exports.disableAllEpics
  }, {
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "More"
    }))),
    tooltip: 'More actions',
    value: function () {
      ContextMenuController.openMore({
        items: exports.moreActionsDropdownItems(),
        showCloseButton: true,
        target: this,
        width: 250
      });
      return false;
    }
  }];
};
exports.noEpicContextMenuItems = function () {
  const showAll = !EpicModel.allActive().length;
  return [{
    name: showAll ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: showAll ? 'Show all Epics' : 'Show only this Epic',
    tooltipShortcut: '(shift-click)',
    value: () => {
      if (showAll) {
        exports.enableAllEpics.call(this);
      } else {
        exports.disableAllEpics.call(this);
      }
      ContextMenuController.refresh();
      return false;
    }
  }];
};
function _markEpicAsUpdating(epic) {
  const parent = $('#epic-' + epic.id);
  parent.css({
    opacity: 0.5
  }).find('.fa').removeClass('fa-flag fa-flag-o fa-flag-checkered').addClass('fa-spin fa-star');
}
function _updateEpicState(epic, epic_state_id, options) {
  options = options || {};
  const originalStateID = epic.epic_state_id;
  _markEpicAsUpdating(epic);
  ContextMenuController.close();
  EpicModel.saveChanges(epic, {
    epic_state_id
  }, (err, updatedEpic) => {
    if (options.isUndo) {
      MessageController.closeById('update-epic-state-message');
    }
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update epic state.'
      });
    } else {
      const messageOptions = {};
      const verb = options.isUndo ? 'reverted' : 'updated';
      if (options.isUndo !== true) {
        messageOptions.timeout = 16000;
        messageOptions.id = 'update-epic-state-message';
        messageOptions.actions = '<button class="action mini elevated" data-controller="Epic" ' + 'data-on-click="undoEpicStateChange" data-model="Epic" data-id="' + epic.id + '" data-original-state-id="' + originalStateID + '">Undo</button>';
      }
      MessageController.success('Epic <strong>' + Format.sanitizeAndEmojify(updatedEpic.name) + '</strong> state ' + verb + ' to <strong>' + EpicStateModel.getById(epic_state_id).name + '</strong>.', messageOptions);
    }
  });
}
exports.undoEpicStateChange = function () {
  const epic = Utils.getModelFromContext(this);
  const originalStateID = Utils.data(this, 'original-state-id');
  $(this).html('<span class="fa fa-spin fa-star"></span> Reverting...').addClass('disabled').attr('disabled', 'disabled');
  _updateEpicState(epic, originalStateID, {
    isUndo: true
  });
  return false;
};
exports.contextMenuItems = function () {
  const epic = Utils.getModelFromContext(this);
  const me = UserModel.getLoggedInUserPermission();
  const isReadOnly = Is.readOnly(me);
  const isFollower = EpicModel.isFollower(epic, me);
  const showAll = StoriesEpicModel.onlyCurrentEpicActive(epic);
  const dropdownActions = [{
    name: isFollower ? 'Unfollow this Epic' : 'Follow this Epic',
    customIcon: isFollower ? renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Remove"
    }))) : renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Add"
    }))),
    value: () => {
      if (isFollower) {
        EpicModel.removeMeAsFollower(epic, exports.refresh);
      } else {
        EpicModel.addMeAsFollower(epic, exports.refresh);
      }
      ContextMenuController.close();
    }
  }];
  if (!isReadOnly) {
    EpicStateModel.each(state => {
      if (epic.epic_state_id !== state.id) {
        const action = {
          name: 'Move to <strong>' + state.name + '</strong>',
          customIconLeft: EpicStateModel.getRenderedCustomIcon(state.customIcon),
          value: () => {
            _updateEpicState(epic, state.id);
          }
        };
        dropdownActions.push(action);
      }
    });
    dropdownActions.push({
      name: epic.archived ? 'Unarchive this Epic' : 'Archive this Epic',
      customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
        width: 15
      }, ___EmotionJSX(Icon, {
        icon: "Archive"
      }))),
      value: function () {
        if (epic.archived) {
          EpicModel.unarchiveEpic(epic, exports.refresh);
        } else {
          ArchiveWarningController.epic.call(this, epic, {
            onApprove: () => {
              EpicModel.archiveEpic(epic, exports.refresh);
            }
          });
        }
      }
    });
  }
  const actions = [{
    name: showAll ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: showAll ? 'Show all Epics' : 'Show only this Epic',
    tooltipShortcut: '(shift-click)',
    value: target => exports.toggle.call(target, epic, {
      force: true
    })
  }, {
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Link"
    }))),
    name: 'Epic page',
    url: epic.url
  }, {
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "More"
    }))),
    tooltip: 'More actions',
    value: function () {
      ContextMenuController.openMore({
        items: dropdownActions,
        title: Format.sanitize(epic.name),
        target: this,
        width: 200
      });
      return false;
    }
  }];
  return actions;
};
exports.epicSidebarTooltip = function () {
  const epic = Utils.getModelFromContext(this);
  const icon = EpicStateIconTemplate.render(epic);
  return `<span style="display: flex; align-items: center; gap: 4px;">${icon}${Format.sanitize(epic.name)}</span>`;
};
export { exports as default };