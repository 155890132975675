import "core-js/modules/es.array.push.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController], [['Controller', 'HotReload'], HotReloadController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useCallback } from 'react';
import { ContextMenu, ContextMenuDivider, ContextMenuItem, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { useTableGroup } from '@clubhouse/shared/components/Table/TableGroup';
import { ToastText, ToastTitle, addToast, useToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/stories/links';
import { CollectionizeStoryAutoLink } from 'components/shared/CollectionizeStoryAutoLink/CollectionizeStoryAutoLink';
import { generateURL, getCurrentIterationForGroup, getNextIterationForGroup } from 'data/entity/iteration';
import { getBranchName, isOwner, saveChanges, setIteration, toggleOwner, useStoryWithFetch } from 'data/entity/story';
import { getLoggedInUserPermission, isLoggedInUserObserver } from 'data/entity/user';
import { sanitize } from '../format';
import { success } from '../message';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ToggleOwnerContextMenuItem = _ref2 => {
  let {
    story
  } = _ref2;
  const currentUser = getLoggedInUserPermission();
  const willRemove = isOwner(story, currentUser);
  const {
    addToast
  } = useToast();
  return ___EmotionJSX(ContextMenuItem, {
    isDisabled: isLoggedInUserObserver(),
    icon: willRemove ? 'UserRemove' : 'UserAdd',
    label: `${willRemove ? 'Remove' : 'Add'} me as an Owner`,
    onClick: () => {
      toggleOwner(story, currentUser);
      addToast({
        Content: () => ___EmotionJSX(ToastText, null, willRemove ? ___EmotionJSX(React.Fragment, null, "You have been removed as an owner from", ' ', ___EmotionJSX(CollectionizeStoryAutoLink, {
          id: story.id,
          name: story.name,
          type: story.story_type
        })) : ___EmotionJSX(React.Fragment, null, "You have been added as an owner to", ' ', ___EmotionJSX(CollectionizeStoryAutoLink, {
          id: story.id,
          name: story.name,
          type: story.story_type
        }))),
        timeout: 3500
      });
    }
  });
};
ToggleOwnerContextMenuItem.displayName = "ToggleOwnerContextMenuItem";
const MoveToIterationContextMenuItem = _ref3 => {
  let {
    iteration,
    story
  } = _ref3;
  const {
    group_id,
    iteration_id
  } = story;
  let foundIteration = null;
  if (group_id) {
    if (iteration === 'current') foundIteration = getCurrentIterationForGroup(group_id);else if (iteration === 'next') foundIteration = getNextIterationForGroup(group_id);
  }

  // Don't move to the same as currently assigned iteration
  if (iteration_id && foundIteration?.id === iteration_id) foundIteration = null;
  return ___EmotionJSX(ContextMenuItem, {
    isDisabled: !foundIteration || isLoggedInUserObserver(),
    icon: "Iteration",
    label: iteration === 'current' ? 'Move to current iteration' : 'Move to next iteration',
    onClick: () => {
      if (!foundIteration) return;
      const it = foundIteration;
      setIteration(story, Number(it.id), () => {
        success(`Story was moved to <strong>${sanitize(it.name)}</strong>.`, {
          actions: `<a href="${generateURL(it)}" data-controller="HotReload" data-on-click="load" data-page="iterations" class="action mini elevated">
                  <span class="fa fa-eye"></span>
                  View iteration
                </a>`,
          timeout: 5000
        });
      });
    }
  });
};
MoveToIterationContextMenuItem.displayName = "MoveToIterationContextMenuItem";
export const CollectionizeStoryContextMenu = _ref4 => {
  let {
    id,
    onMove
  } = _ref4;
  const {
    story,
    loading
  } = useStoryWithFetch({
    id
  });
  if (loading) return ___EmotionJSX(ContextMenuLoading, {
    template: onMove ? '0-00-000-0000-0' : '0-000-0000-0'
  });
  if (!story) return null;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getHref(id, story.name)
  }), ___EmotionJSX(ContextMenuDivider, null), !!onMove && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ContextMenuItem, {
    icon: "ChevronUp",
    label: "Move to top",
    onClick: () => onMove('top'),
    isDisabled: isLoggedInUserObserver()
  }), ___EmotionJSX(ContextMenuItem, {
    icon: "ChevronDown",
    label: "Move to bottom",
    onClick: () => onMove('bottom'),
    isDisabled: isLoggedInUserObserver()
  }), ___EmotionJSX(ContextMenuDivider, null)), ___EmotionJSX(ToggleOwnerContextMenuItem, {
    story: story
  }), ___EmotionJSX(MoveToIterationContextMenuItem, {
    story: story,
    iteration: "current"
  }), ___EmotionJSX(MoveToIterationContextMenuItem, {
    story: story,
    iteration: "next"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(id, story.name, true),
    label: "Link"
  }), ___EmotionJSX(CopyContextMenuItem, {
    value: String(id),
    label: "ID"
  }), ___EmotionJSX(CopyContextMenuItem, {
    icon: "CopyDocument",
    value: story.name,
    label: "Title"
  }), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Branch",
    value: getBranchName(story),
    label: "Branch Name"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: story.global_id,
    entityType: Nouns.Story,
    loggingContext: {
      typename: 'Story'
    }
  }));
};
CollectionizeStoryContextMenu.displayName = "CollectionizeStoryContextMenu";
export const useCollectionizeStoryContextMenu = storyId => {
  const {
    canReorder
  } = useTableGroup();
  const onMove = useCallback(async dir => {
    try {
      await saveChanges(storyId, {
        move_to: dir === 'top' ? 'first' : 'last'
      });
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space2"
        }, ___EmotionJSX(ToastText, null, "Story was moved to the ", dir, " of its current workflow state."))
      });
    } catch (e) {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space2"
        }, ___EmotionJSX(ToastTitle, null, "Story could not be moved."), e && typeof e === 'object' && 'message' in e && ___EmotionJSX(ToastText, null, e.message))
      });
    }
  }, [storyId]);
  const {
    props
  } = useContextMenuWithLogging(useCallback(() => ___EmotionJSX(CollectionizeStoryContextMenu, {
    id: storyId,
    onMove: canReorder ? dir => onMove(dir) : undefined
  }), [storyId, canReorder, onMove]), {
    entityType: 'Story'
  });
  return props;
};
export const useCollectionizeStoryContextMenuWithQuickSearchSessionLogging = (storyId, logQuickSearchInteractions) => {
  const {
    canReorder
  } = useTableGroup();
  const onMove = useCallback(async dir => {
    try {
      await saveChanges(storyId, {
        move_to: dir === 'top' ? 'first' : 'last'
      });
      addToast({
        kind: 'success',
        timeout: 5000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space2"
        }, ___EmotionJSX(ToastText, null, "Story was moved to the ", dir, " of its current workflow state."))
      });
    } catch (e) {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space2"
        }, ___EmotionJSX(ToastTitle, null, "Story could not be moved."), e && typeof e === 'object' && 'message' in e && ___EmotionJSX(ToastText, null, e.message))
      });
    }
  }, [storyId]);
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(useCallback(() => ___EmotionJSX(CollectionizeStoryContextMenu, {
    id: storyId,
    onMove: canReorder ? dir => onMove(dir) : undefined
  }), [storyId, canReorder, onMove]), {
    entityType: 'Story'
  }, logQuickSearchInteractions);
  return props;
};