import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Edit" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Pencil = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill = '#DDD'
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    fill: fill,
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Edit"
  }));
};
Pencil.displayName = "Pencil";