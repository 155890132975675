import { professionInputLabel, professions } from '@clubhouse/shared/constants';
import { Select } from 'components/shared/Select';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ProfessionSelect = () => ___EmotionJSX("div", {
  "data-validate": "notEmpty",
  className: "form-input profession"
}, ___EmotionJSX(Select, {
  id: "signup-profession",
  name: "profession",
  label: professionInputLabel,
  options: professions,
  required: true
}));
ProfessionSelect.displayName = "ProfessionSelect";