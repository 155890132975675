import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { SecondaryActionLink } from 'components/shared/DetailPageSidebar';
import { IconContainer } from 'components/shared/DetailPageSidebar/DetailPageSidebarRow';
import { navigateTo } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function GotoUrl(_ref) {
  let {
    url,
    tooltipText
  } = _ref;
  return ___EmotionJSX(Tooltip, {
    content: tooltipText
  }, ___EmotionJSX(SecondaryActionLink, {
    href: url,
    onClick: e => {
      if (isClickedWithModifier(e)) {
        e.stopPropagation();
      } else {
        e.preventDefault();
        navigateTo({
          url
        });
      }
    },
    "aria-label": tooltipText
  }, ___EmotionJSX(IconContainer, null, ___EmotionJSX(SizedIcon, {
    icon: "NewTab",
    fill: "main",
    size: 12
  }))));
}
GotoUrl.displayName = "GotoUrl";