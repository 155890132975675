import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { hasLabel } from 'data/entity/label';
import { getBlockersCount } from 'data/entity/storyLink';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BlockedChip = () => {
  const story = useContext(StoryContext);
  if (!story) return null;
  const hasBlockedLabel = hasLabel(story, 'blocked');
  if (!story.blocked && !hasBlockedLabel) return null;
  const blockersCount = getBlockersCount(story);
  return ___EmotionJSX(StoryCardInternal.Chips.BlockedChip, {
    blockersCount: blockersCount,
    hasBlockedLabel: hasBlockedLabel
  });
};
BlockedChip.displayName = "BlockedChip";