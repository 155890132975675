import { useEffect, useRef } from 'react';
import { closestAncestor } from '@clubhouse/shared/utils';
import { ContextMenuState } from './context';
import { activeContextMenu } from './useActiveContextMenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
const createState = () => {
  const menus = new Map();
  const register = (id, getMenu) => {
    menus.set(id, getMenu);
    return () => {
      menus.delete(id);
    };
  };
  const get = id => menus.get(id) ?? null;
  return {
    register,
    get
  };
};
const getContextMenuTarget = el => {
  if (el.matches('[data-cm]')) return el;
  return closestAncestor(el, '[data-cm]');
};
const findRegisteredMenu = (state, el) => {
  const result = {
    rootElement: null,
    Menu: null,
    onEvent: null
  };
  const rootElement = getContextMenuTarget(el);
  if (!rootElement) return result;
  const id = rootElement.dataset.cm;
  if (!id) return result;
  const getMenu = state.get(id);
  if (!getMenu || !rootElement) return result;
  const menu = getMenu({
    clickedElement: el,
    rootElement
  });
  if (!menu) return result;
  result.rootElement = rootElement;
  result.Menu = menu.Menu;
  result.onEvent = menu.onEvent ?? null;
  return result;
};
export const ContextMenuProvider = _ref => {
  let {
    children
  } = _ref;
  const state = useRef(createState());
  useEffect(() => {
    const onKeyDown = e => {
      if (e.key !== 'F10' || !e.shiftKey) return;
      const el = e.target;
      const {
        Menu,
        onEvent,
        rootElement
      } = findRegisteredMenu(state.current, el);
      const {
        Menu: CurrentMenu,
        onEvent: currentOnEvent
      } = activeContextMenu();
      if (!Menu) return;
      e.preventDefault();
      e.stopPropagation();
      if (CurrentMenu) currentOnEvent?.('close');
      onEvent?.('open');
      let ox, oy, x, y;
      if (rootElement) {
        const rootRect = rootElement.getBoundingClientRect();
        const elRect = el.getBoundingClientRect();
        x = elRect.x;
        y = elRect.y;
        ox = elRect.x - rootRect.x;
        oy = elRect.y - rootRect.y;
      }
      activeContextMenu({
        Menu: Menu ?? null,
        // If this is null the context menu is closed
        onEvent,
        target: rootElement,
        clickTarget: el,
        x,
        y,
        ox,
        oy
      });
    };
    const onRightClick = e => {
      if (e.altKey) return;
      const clickedElement = e.target;
      const {
        Menu,
        onEvent,
        rootElement
      } = findRegisteredMenu(state.current, clickedElement);
      const {
        Menu: CurrentMenu,
        onEvent: currentOnEvent
      } = activeContextMenu();
      if (CurrentMenu) {
        currentOnEvent?.('close');
      }
      if (Menu) {
        e.preventDefault();
        onEvent?.('open');
      }
      let ox, oy;
      if (rootElement) {
        const rect = rootElement.getBoundingClientRect();
        ox = e.clientX - rect.x;
        oy = e.clientY - rect.y;
      }
      activeContextMenu({
        Menu: Menu ?? null,
        // If this is null the context menu is closed
        onEvent,
        target: rootElement,
        clickTarget: clickedElement,
        x: e.clientX,
        y: e.clientY,
        ox,
        oy
      });
    };
    document.body.addEventListener('keydown', onKeyDown);
    document.body.addEventListener('contextmenu', onRightClick);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
      document.body.removeEventListener('contextmenu', onRightClick);
    };
  }, []);
  return ___EmotionJSX(ContextMenuState.Provider, {
    value: state.current
  }, children);
};
ContextMenuProvider.displayName = "ContextMenuProvider";