import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Settings" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Gear = _ref => {
  let {
    fill = '#333333',
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill
  }, ___EmotionJSX(Icon, {
    icon: "Settings"
  }));
};
Gear.displayName = "Gear";