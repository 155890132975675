import { ArchiveStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useArchiveStoryMutation } from '../hooks/useArchiveStoryMutation';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { useUnarchiveStoryMutation } from '../hooks/useUnarchiveStoryMutation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ArchiveStoryActionFragment = ArchiveStoryActionFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => ___EmotionJSX(ToastText, null, message)
  });
};
export const ArchiveStoryAction = _ref => {
  let {
    story
  } = _ref;
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const archiveStory = useArchiveStoryMutation();
  const unarchiveStory = useUnarchiveStoryMutation();
  const onArchive = useCallback(() => {
    openDialog('archive', {
      onClick: async () => {
        try {
          closeDialog();
          await archiveStory(story);
          showToast(true, ___EmotionJSX(React.Fragment, null, "Story", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, story.name)), ' ', "archived."));
        } catch {
          showToast(false, ___EmotionJSX(React.Fragment, null, "Unable to archive", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, story.name)), "."));
        }
      }
    });
  }, [archiveStory, closeDialog, openDialog, story]);
  const onUnarchive = useCallback(async () => {
    try {
      await unarchiveStory(story);
      showToast(true, ___EmotionJSX(React.Fragment, null, "Story", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, story.name)), ' ', "unarchived."));
    } catch {
      showToast(false, ___EmotionJSX(React.Fragment, null, "Unable to unarchive", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, story.name)), "."));
    }
  }, [story, unarchiveStory]);
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Archive",
    onClick: story.archived ? onUnarchive : onArchive,
    isDisabled: isLoggedInUserObserver()
  }, story.archived ? 'Unarchive' : 'Archive', " ", Nouns.Story.singular);
};
ArchiveStoryAction.displayName = "ArchiveStoryAction";