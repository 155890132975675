import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { MarkdownDisplay } from 'components/shared/MarkdownDisplay';
import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { save, useStory } from 'data/entity/story';
import { hasSnapshot } from 'utils/snapshot';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function StoryDescriptionEditor(_ref) {
  let {
    id
  } = _ref;
  const snapshotKey = `story.${id}.description`;
  const [isEditing, {
    on: startEditing,
    off: stopEditing
  }] = useToggleState(hasSnapshot(snapshotKey));
  const story = useStory({
    id
  });
  const handleClickOnDescription = useCallback(e => {
    if (!e.shiftKey || e.button !== 0) return;
    e.preventDefault();
    e.stopPropagation();
    if (!isEditing) {
      startEditing();
    }
  }, [isEditing, startEditing]);
  if (!story) return ___EmotionJSX("div", null);
  return ___EmotionJSX("div", null, isEditing ? ___EmotionJSX(MarkdownEditor, {
    context: "Description",
    snapshotKey: snapshotKey,
    initialValue: story.description || '',
    onSave: async value => {
      try {
        stopEditing();
        await save({
          id: story.id,
          description: value
        });
      } catch {
        startEditing();
        addToast({
          kind: 'alert',
          timeout: 5000,
          Content: () => ___EmotionJSX(ToastText, null, "The change could not be saved.")
        });
      }
    },
    onCancel: stopEditing
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    onClick: handleClickOnDescription,
    role: "presentation"
  }, ___EmotionJSX(MarkdownDisplay, {
    key: story.description,
    onChange: async value => {
      try {
        await save({
          id: story.id,
          description: value
        });
      } catch {
        addToast({
          kind: 'alert',
          timeout: 5000,
          Content: () => ___EmotionJSX(ToastText, null, "The change could not be saved.")
        });
      }
    }
  }, story.description || '')), ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.SMALL,
    fit: Button.FIT.MEDIUM,
    IconLeft: () => ___EmotionJSX(SizedIcon, {
      icon: "Edit",
      fill: "disabled",
      size: 16
    }),
    onClick: startEditing
  }, "Edit Description")));
}
StoryDescriptionEditor.displayName = "StoryDescriptionEditor";