function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { getCurrentPage } from 'utils/navigation';
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as ___EmotionJSX } from "@emotion/react";
const chartStyle = `
  .c3-bar {
    fill-opacity: .75;
  }
  .c3-bar._expanded_ {
    fill-opacity: 1;
  }
`;
export const CumulativeFlowReportCard = props => {
  const page = getCurrentPage();
  return ___EmotionJSX(ReportCard, _extends({}, props, {
    title: "Cumulative Flow Diagram",
    description: `Work ${page === 'reports' || page === 'project' ? 'completed' : 'activity'} over time, grouped by workflow state.`,
    chartStyle: chartStyle,
    Explanation: Explanation,
    Filters: Filters
  }));
};
CumulativeFlowReportCard.displayName = "CumulativeFlowReportCard";