import Logo from '@clubhouse/assets/png/third-party-logos/slab_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Slab() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.SLAB
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Slab"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Slab Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Stay on top of Shortcut stories from within ", ___EmotionJSX(ExternalLink, {
    href: "https://www.slab.com/"
  }, "Slab"), ' ', "with link unfurling. Hovering over Shortcut links in Slab will show more details, including the due date, description, and labels."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://slab.com/integrations/shortcut/"
  }, "Learn more"), " on how the Slab integration works in Shortcut.")));
}
Slab.displayName = "Slab";