import { StoryGroupByIterationFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TableGroupHeaderLinks } from '@clubhouse/shared/components/Table';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { IterationWithIcon } from 'components/gql/iteration/IterationWithIcon';
import { getHref } from 'components/gql/iteration/links';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByIterationFragment = StoryGroupByIterationFragmentFragmentDoc;
export const IterationGroupHeader = _ref => {
  let {
    iteration
  } = _ref;
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: 12,
    inline: true,
    align: "center"
  }, ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.Iteration
  }, typeof iteration?.name === 'string' && iteration?.state ? ___EmotionJSX(IterationWithIcon, {
    name: iteration.name,
    state: iteration.state
  }) : 'No Iteration'), typeof iteration?.publicId === 'number' && ___EmotionJSX(TableGroupHeaderLinks, {
    entityName: "Iteration",
    url: getHref(iteration.publicId, true)
  }));
};
IterationGroupHeader.displayName = "IterationGroupHeader";