import { EpicMoreActionsDataFragmentFragmentDoc } from "../../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { MoreActions as MoreActionsRoot } from '@clubhouse/shared/components/MoreActions';
import { useAsyncSelect } from '@clubhouse/shared/components/Select';
import { useUpdateEpicState } from 'components/gql/epicState/useUpdateEpicState';
import { ArchiveEpicAction } from 'components/gql/epics/actions/ArchiveEpicAction';
import { DeleteEpicAction } from 'components/gql/epics/actions/DeleteEpicAction';
import { DuplicateEpicAction } from 'components/gql/epics/actions/DuplicateEpicAction';
import { useWorkspaceEpicStatesAsyncSelectQuery } from 'pages/epics-apollo/entities/epicStates/hooks/useWorkspaceEpicStatesAsyncSelectQuery';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicMoreActionsDataFragment = EpicMoreActionsDataFragmentFragmentDoc;
export const MoreActions = _ref => {
  let {
    epic,
    onChangeEpicState
  } = _ref;
  const fetchEpicStates = useWorkspaceEpicStatesAsyncSelectQuery();
  const {
    sections,
    fetchSections
  } = useAsyncSelect(fetchEpicStates);
  const updateEpicState = useUpdateEpicState();
  const items = useMemo(() => {
    if (!sections) return null;
    return sections.reduce((acc, _ref2) => {
      let {
        items
      } = _ref2;
      return acc.concat(items.filter(item => String(item.value) !== String(epic.epicState.id)).map(item => ({
        ...item,
        onClick: async () => {
          if (epic.epicState.type && item.additionalData.type) {
            await updateEpicState(epic, item.additionalData);
            onChangeEpicState({
              from: epic.epicState.type,
              to: item.additionalData.type
            });
          }
        }
      })));
    }, []);
  }, [epic, onChangeEpicState, sections, updateEpicState]);
  const isLoading = items === null;
  return ___EmotionJSX(MoreActionsRoot, {
    onOpen: fetchSections
  }, ___EmotionJSX(MoreActionsRoot.Button, null), ___EmotionJSX(MoreActionsRoot.Menu, null, isLoading ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MoreActionsRoot.Loading, null), ___EmotionJSX(MoreActionsRoot.Loading, null), ___EmotionJSX(MoreActionsRoot.Loading, null)) : ___EmotionJSX(React.Fragment, null, (items || []).map(_ref3 => {
    let {
      value,
      Icon,
      name,
      onClick
    } = _ref3;
    return ___EmotionJSX(MoreActionsRoot.Item, {
      key: value,
      icon: Icon ? ___EmotionJSX(Icon, null) : null,
      onClick: onClick
    }, "Move To ", name);
  }), ___EmotionJSX(MoreActionsRoot.Divider, null), ___EmotionJSX(ArchiveEpicAction, {
    epic: epic
  }), ___EmotionJSX(DuplicateEpicAction, {
    epic: epic
  }), ___EmotionJSX(DeleteEpicAction, {
    epic: epic
  }))));
};
MoreActions.displayName = "MoreActions";