import { COLORS } from '@clubhouse/shared/colors';
import { BadgeLabel } from 'components/shared/BadgeLabel';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TableNameWithLabelsAndBlockersLabels = _ref => {
  let {
    labels
  } = _ref;
  const labelsDisplay = labels.map(label => ___EmotionJSX(BadgeLabel, {
    key: label.id,
    label: {
      color: label.color ?? COLORS.YELLOW45,
      name: label.name
    }
  }));
  return ___EmotionJSX(React.Fragment, null, labelsDisplay);
};