import { jsx as ___EmotionJSX } from "@emotion/react";
export const Right = _ref => {
  let {
    fill,
    width,
    className
  } = _ref;
  return ___EmotionJSX("svg", {
    className: className,
    width: width,
    height: width,
    viewBox: "0 0 5 9",
    fill: fill
  }, ___EmotionJSX("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0.500061 0.998047C0.635394 0.998047 0.752728 1.04738 0.852061 1.14605L4.35206 4.64605C4.45073 4.74471 4.50006 4.86205 4.50006 4.99805C4.50006 5.13405 4.45073 5.25138 4.35206 5.35005L0.852061 8.85005C0.753394 8.94871 0.636061 8.99805 0.500061 8.99805C0.364061 8.99805 0.246728 8.94871 0.148061 8.85005C0.0493944 8.75138 6.10352e-05 8.63405 6.10352e-05 8.49805L6.10352e-05 1.49805C6.10352e-05 1.36271 0.0493944 1.24538 0.148061 1.14605C0.246728 1.04671 0.364061 0.99738 0.500061 0.998047Z"
  }));
};
Right.displayName = "Right";