import { usePendingInvites } from 'data/entity/invite';
import { maybeName as sortByMaybeName } from 'utils/sort';
import { Invite } from './Invite';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Invites() {
  const pendingInvites = usePendingInvites();
  return ___EmotionJSX(React.Fragment, null, pendingInvites.sort(sortByMaybeName).map(invite => ___EmotionJSX(Invite, {
    key: invite.id,
    id: invite.id
  })));
}