import { useRef } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { sanitizeAndEmojify } from '@clubhouse/shared/utils';
import { trigger } from 'utils/event';
import { addColumn, removeColumn } from 'utils/storiesView';
import { jsx as ___EmotionJSX } from "@emotion/react";
const HideToastMessage = _ref => {
  let {
    name,
    undo
  } = _ref;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 8
  }, ___EmotionJSX(ToastText, null, "State ", ___EmotionJSX("strong", {
    dangerouslySetInnerHTML: {
      __html: sanitizeAndEmojify(name)
    }
  }), " hidden. To unhide, click on the state at the bottom of the left sidebar."), ___EmotionJSX(Button, {
    onClick: undo,
    kind: Button.KIND.SECONDARY
  }, "Undo"));
};
HideToastMessage.displayName = "HideToastMessage";
const UndoHideToastMessage = _ref2 => {
  let {
    name
  } = _ref2;
  return ___EmotionJSX(ToastText, null, "State ", ___EmotionJSX("strong", {
    dangerouslySetInnerHTML: {
      __html: sanitizeAndEmojify(name)
    }
  }), " unhidden.");
};
UndoHideToastMessage.displayName = "UndoHideToastMessage";
export const useHideColumn = column => {
  const toastRef = useRef(null);
  const {
    addToast,
    removeToast
  } = useToast();
  const hideColumn = () => {
    removeColumn(column);
    trigger('spaceUpdate');
    toastRef.current = addToast({
      kind: 'success',
      timeout: 15000,
      Content: () => ___EmotionJSX(HideToastMessage, {
        name: column.name,
        undo: undoHideColumn
      })
    });
  };
  const undoHideColumn = () => {
    addColumn(column, 1);
    trigger('spaceUpdate');
    if (toastRef.current) removeToast(toastRef.current);
    addToast({
      timeout: 5000,
      kind: 'success',
      Content: () => ___EmotionJSX(UndoHideToastMessage, {
        name: column.name
      })
    });
  };
  return hideColumn;
};