import { CreateEpicHealthStatusDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicHealthUpdateSelectFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useRef } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { EpicHealthPopover } from 'components/gql/epics/EpicHealthPopover';
import { useMutation } from 'gql';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { HealthStatusBadge } from '../../shared/healthStatus/HealthStatusBadge';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicHealthUpdateSelectFragment = EpicHealthUpdateSelectFragmentDoc;
const CreateEpicHealthStatus = CreateEpicHealthStatusDocument;
export function EpicHealthUpdateSelect(_ref) {
  let {
    id,
    currentHealthStatus,
    stats,
    onOpen,
    onClose,
    onChangeCurrent,
    onChangeHistory,
    showCommentCount
  } = _ref;
  const [isOpen, toggle] = useToggleState(false, open => open ? onOpen?.() : onClose?.());
  const triggerRef = useRef(null);
  const slug = useWorkspaceSlug();
  const [create] = useMutation(CreateEpicHealthStatus);
  const handleSave = useCallback(async (status, text) => {
    try {
      toggle.off();
      const hasComment = text.length > 0;
      await create({
        variables: {
          slug,
          input: {
            epic: id,
            status,
            text: hasComment ? text : null
          }
        }
      });
      onChangeCurrent?.(status, hasComment ? text : null);
    } catch (error) {
      addToast({
        kind: 'alert',
        timeout: 10_000,
        Content: () => ___EmotionJSX(ToastText, null, "Unable to save epic health.")
      });
      logError(error);
    }
  }, [toggle, create, slug, id, onChangeCurrent]);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    ref: triggerRef
  }, ___EmotionJSX(UnstyledButton, {
    onClick: toggle
  }, ___EmotionJSX(HealthStatusBadge, {
    status: currentHealthStatus ?? 'noHealth'
  }, !!showCommentCount && ___EmotionJSX(HealthStatusBadge.CommentCount, {
    count: stats.numHealthComments
  })))), isOpen && ___EmotionJSX(EpicHealthPopover, {
    triggerRef: triggerRef,
    onClose: toggle.off,
    id: id,
    currentHealthStatus: currentHealthStatus ?? null,
    onSaveCurrent: handleSave,
    onChangeHistory: onChangeHistory
  }));
}