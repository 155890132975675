function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { IterationSearchCardIterationFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { DateRange } from '@clubhouse/shared/components/DateRange';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { IterationStatus } from '@clubhouse/shared/types';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { GroupProfileIconTooltip } from 'components/shared/GroupProfileIconTooltip';
import { useIterationContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useIterationContextMenu';
import { loadURL } from 'utils/hotReload';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationSearchCardIterationFragment = IterationSearchCardIterationFragmentDoc;
export const IterationSearchCard = _ref => {
  let {
    iteration,
    openModalOnClick,
    onClick
  } = _ref;
  const {
    startEpochDay,
    endEpochDay,
    iterationName,
    iterationState,
    team,
    publicId
  } = iteration;
  const showDate = !!startEpochDay || !!endEpochDay;
  const iterationUrl = generatePathWithSlug(getPathForPage(PAGE_NAMES.ITERATION), {
    id: publicId
  });
  const contextMenuProps = useIterationContextMenuWithQuickSearchSessionLogging(iteration.publicId, useCallback(() => {
    onClick?.({
      ...iteration,
      url: iterationUrl
    });
  }, [iteration, iterationUrl, onClick]));
  const handleClick = e => {
    onClick?.({
      ...iteration,
      url: iterationUrl
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }

    // This handles whether we navigate to a iteration or add a badge in docs
    if (openModalOnClick) {
      loadURL(iterationUrl, 'iterations');
    }
  };
  return ___EmotionJSX(SearchCardLink, _extends({
    onClick: handleClick,
    href: iterationUrl,
    "data-focusable": true
  }, contextMenuProps), ___EmotionJSX(SearchCard, null, ___EmotionJSX(SearchCard.Icon, {
    icon: "Iteration",
    kind: iterationState === IterationStatus.UNSTARTED ? 'default' : iterationState === IterationStatus.DONE ? 'green' : 'blue',
    label: "Iteration",
    isCompleted: iterationState === IterationStatus.DONE
  }), ___EmotionJSX(SearchCard.Title, null, ___EmotionJSX(Emojify, null, iterationName ?? '')), ___EmotionJSX(SearchCard.Metadata, null, ___EmotionJSX(SearchCard.MetadataItem, null, iterationState), ___EmotionJSX(SearchCard.MetadataItem, null, showDate ? ___EmotionJSX(DateRange, {
    start: startEpochDay,
    end: endEpochDay,
    fontSize: 12,
    format: DATE_FORMAT.SHORT_DATE_NO_YEAR
  }) : null)), team?.id && ___EmotionJSX(SearchCard.Avatars, null, ___EmotionJSX(GroupProfileIconTooltip, {
    groupId: team.id
  }))));
};
IterationSearchCard.displayName = "IterationSearchCard";
export function IterationSearchCardAdapter(props) {
  const iteration = {
    __typename: 'Iteration',
    id: props.iteration.global_id,
    endEpochDay: props.iteration.end_date,
    iterationName: props.iteration.name,
    publicId: props.iteration.id,
    iterationState: props.iteration.status,
    startEpochDay: props.iteration.start_date,
    team: props.iteration.group_ids?.length > 0 ? {
      __typename: 'Team',
      id: props.iteration.group_ids[0]
    } : null
  };
  return ___EmotionJSX(IterationSearchCard, _extends({}, props, {
    iteration: iteration
  }));
}
IterationSearchCardAdapter.displayName = "IterationSearchCardAdapter";