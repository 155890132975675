import "core-js/modules/es.array.push.js";
import StoryModel from 'app/client/core/js/models/story.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Story'], StoryModel], [['Model', 'Story'], StoryModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { groupExternalLinksByType } from 'data/entity/externalLink';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ExternalLink = _ref2 => {
  let {
    external_link
  } = _ref2;
  return ___EmotionJSX("li", {
    key: external_link.id,
    "data-model": "Story",
    "data-id": external_link.id
  }, external_link.icon && ___EmotionJSX("img", {
    src: external_link.icon,
    alt: "External Link Icon",
    className: external_link.invertForDarkMode ? 'invert-for-dark-mode' : ''
  }), !external_link.icon && ___EmotionJSX("span", {
    className: "fa fa-link"
  }), ___EmotionJSX("a", {
    href: external_link.url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, external_link.url), ___EmotionJSX("span", {
    className: "fa fa-trash remove",
    "data-on-click": "removeExternalLink",
    "data-value": external_link.url,
    "data-tooltip": "Remove External Link"
  }));
};
ExternalLink.displayName = "ExternalLink";
export function ExternalLinks(_ref3) {
  let {
    story,
    addExternalLink
  } = _ref3;
  if (!story.external_links.length) return null;
  const externalLinkGroups = groupExternalLinksByType(story.external_links);
  return ___EmotionJSX("div", {
    className: "story-attachment",
    "data-type": "external-link"
  }, ___EmotionJSX("div", {
    className: "section-head"
  }, "External Links"), ___EmotionJSX("div", {
    className: "external-links"
  }, externalLinkGroups.map((_ref4, j) => {
    let [type, external_links] = _ref4;
    return ___EmotionJSX("div", {
      key: j
    }, ___EmotionJSX("h4", null, type, " ", ___EmotionJSX("span", {
      className: "external-link-count"
    }, "(", external_links.length, ")")), ___EmotionJSX("ul", null, external_links.map((external_link, i) => ___EmotionJSX(ExternalLink, {
      key: i,
      external_link: external_link
    }))));
  }), !externalLinkGroups.length && story.readOnly && ___EmotionJSX("p", {
    className: "none-found"
  }, "No external links.")), ___EmotionJSX("div", {
    className: "add-external-link-button"
  }, ___EmotionJSX("button", {
    className: "action micro flat-white add-story-link",
    onClick: addExternalLink,
    "data-tabindex": true
  }, ___EmotionJSX("span", {
    className: "fa fa-plus"
  }), " Add External Link...")));
}
ExternalLinks.displayName = "ExternalLinks";