import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useEffect, useState } from 'react';
import SmallLogo from '@clubhouse/assets/png/third-party-logos/slack_32x32.png';
import Logo from '@clubhouse/assets/png/third-party-logos/slack_logo.png';
import { LoadingDots } from '@clubhouse/shared/animations';
import { getTint } from '@clubhouse/shared/color';
import { Circle } from '@clubhouse/shared/components/Icons';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { getFieldValueIcon } from '@clubhouse/shared/utils/customFields';
import { IntegrationTable } from 'components/integrations/slack/IntegrationTable';
import { Select } from 'components/integrations/slack/Select';
import { GroupProfileIconRenderer } from 'components/shared/GroupProfileIconRenderer';
import { areCustomFieldsEnabled, getById as getFieldById, getValueById } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { getById as getGroupById } from 'data/entity/group';
import { deleteFieldIntegration, deleteProjectIntegration, deleteTeamIntegration, enableIntegration, fetchAll, fetchSlackIntegrationFields, fetchSlackIntegrationProjects, fetchSlackIntegrationTeams, getIntegration, getSlackRedirectUrl, useIntegration } from 'data/entity/integration';
import { getSlackConfig, useIntegrationState } from 'data/entity/organization';
import { getById as getProjectById } from 'data/entity/project';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { getCurrentSlug, navigateOnClick } from 'utils/navigation';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
const AUTH_URL_PREFIX = 'https://slack.com/oauth/v2/authorize';
const INTEGRATION_TYPE_TEAM = 'team';
const INTEGRATION_TYPE_PROJECT = 'project';
const INTEGRATION_TYPE_FIELD = 'field';
const buildGlobalAuthUrl = _ref => {
  let {
    state = getCurrentSlug(),
    scope,
    userScope
  } = _ref;
  const slack_config = getSlackConfig();
  const redirect_uri = getSlackRedirectUrl();
  const client_id = slack_config.client_id;
  return `${AUTH_URL_PREFIX}?scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}${userScope ? `&user_scope=${encodeURIComponent(userScope)}` : ''}&${new URLSearchParams({
    client_id,
    redirect_uri
  }).toString()}`;
};
const getGlobalAuthUrl = () => {
  const slack_config = getSlackConfig();
  const bot_scope = slack_config.bot_scopes.join(',');
  const user_scope = slack_config.user_scopes.join(',');
  return buildGlobalAuthUrl({
    scope: bot_scope,
    userScope: user_scope
  });
};
const mapTeamIntegrationToItemsProps = function () {
  let {
    group_id,
    channel
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!group_id) return;
  const group = getGroupById(String(group_id));
  if (!group) return null;
  return {
    id: group.id,
    name: group.name,
    archived: group.archived,
    channel,
    IconComponent: () => ___EmotionJSX(GroupProfileIconRenderer, {
      values: group,
      width: "20px",
      height: "20px",
      alt: ""
    }),
    actionURL: buildGlobalAuthUrl({
      scope: 'incoming-webhook',
      state: `${getCurrentSlug()}|${group.id}|${INTEGRATION_TYPE_TEAM}`
    })
  };
};
const mapProjectIntegrationToItemsProps = function () {
  let {
    project_id,
    channel
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const project = getProjectById(project_id);
  if (!project) return null;
  return {
    id: project.id,
    name: project.name,
    archived: project.archived,
    channel,
    IconComponent: () => ___EmotionJSX(Circle, {
      width: "16px",
      fill: project.color
    }),
    actionURL: buildGlobalAuthUrl({
      scope: 'incoming-webhook',
      state: `${getCurrentSlug()}|${project.id}|${INTEGRATION_TYPE_PROJECT}`
    })
  };
};
const mapFieldIntegrationToItemsProps = function () {
  let {
    field_id,
    field_value_id,
    channel
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!field_id || !field_value_id) return null;
  const field = getFieldById(field_id);
  const fieldValue = getValueById(field_id, field_value_id);
  if (!field || !fieldValue) return null;
  const Icon = getFieldValueIcon(field, fieldValue.id);
  if (!Icon) return null;
  return {
    id: fieldValue.id,
    name: fieldValue.value,
    enabled: fieldValue.enabled,
    channel,
    IconComponent: () => ___EmotionJSX(Icon, {
      fill: getTint(fieldValue.color_key),
      width: "16px"
    }),
    actionURL: buildGlobalAuthUrl({
      scope: 'incoming-webhook',
      state: `${getCurrentSlug()}|${field.id}:${fieldValue.id}|${INTEGRATION_TYPE_FIELD}`
    })
  };
};
export function Slack() {
  const notificationsPageUrl = `/${getCurrentSlug()}/settings/account/notifications`;
  const [isReady, toggleIsReady] = useToggleState();
  const readOnly = isReadOnly();
  const [isEnabled] = useIntegrationState(INTEGRATION_TYPE.SLACK);
  const integration = useIntegration(INTEGRATION_TYPE.SLACK);
  const isLoading = !isReady || typeof isEnabled !== 'boolean';
  const [teams, setTeams] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fields, setFields] = useState([]);
  const hasInstalledIntegration = !!integration && !integration.disabled;
  useEffect(() => {
    const fetchInOrder = async () => {
      await fetchAll();
      await Promise.all([fetchSlackIntegrationFields(), fetchSlackIntegrationTeams(), fetchSlackIntegrationProjects()]);
      const integration = getIntegration(INTEGRATION_TYPE.SLACK);
      if (integration) {
        setTeams((integration.teamIntegrations || []).map(mapTeamIntegrationToItemsProps).filter(Boolean));
        setProjects((integration.projectIntegrations || []).map(mapProjectIntegrationToItemsProps).filter(Boolean));
        setFields((integration.fieldIntegrations || []).map(mapFieldIntegrationToItemsProps).filter(Boolean));
      }
    };
    fetchInOrder().then(toggleIsReady.on);
  }, [toggleIsReady.on]);
  useEffect(() => {
    if (integration && integration.disabled) enableIntegration(integration);
  }, [integration]);
  const version = integration?.version ?? 'v1';
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.SLACK
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Slack"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Slack Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "http://slack.com"
  }, "Slack "), " is a platform for team communication. Enable the", ' ', BRAND.NAME, " to Slack integration in your Slack Workspace to:", ___EmotionJSX("ul", null, ___EmotionJSX("li", null, "receive @-mention", ' ', ___EmotionJSX("a", {
    href: notificationsPageUrl,
    onClick: navigateOnClick(notificationsPageUrl)
  }, "notifications"), ' ', "as Slack DMs."), ___EmotionJSX("li", null, "unfurl Story details in Slack by posting the ", BRAND.NAME, " Story link"), ___EmotionJSX("li", null, "create a Story from any Slack message using a Slack Action"), ___EmotionJSX("li", null, "broadcast ", BRAND.NAME, " Story comments and Story updates from specific custom fields or Teams to designated Slack channels"), ___EmotionJSX("li", null, "Sync Slack threads to Story comments so teams don't lose important context or conversations"))), ___EmotionJSX(Layout.Blurb, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_SLACK
  }, "our instructions "), " for how to configure this integration in your Slack account."), readOnly ? ___EmotionJSX(Layout.HelpMessage, null, "Observers cannot make changes to integrations.") : ___EmotionJSX(React.Fragment, null, !hasInstalledIntegration && ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX("p", null, "Authorization is required to install the Slack integration."), ___EmotionJSX("div", {
    className: "authorize-v3 add-to-slack-button large"
  }, ___EmotionJSX("a", {
    href: getGlobalAuthUrl(),
    target: "_self",
    className: "action flat-white"
  }, ___EmotionJSX("img", {
    src: SmallLogo,
    alt: "",
    style: {
      width: 24,
      marginRight: 8
    }
  }), " Add to Slack"))), isLoading ? ___EmotionJSX(LoadingDots, null) : ___EmotionJSX(React.Fragment, null, hasInstalledIntegration && (version === 'v3' ? ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX("p", null, "Click to reinstall the Slack integration. (Your configuration will be preserved.)"), ___EmotionJSX("div", {
    className: "authorize-v3 add-to-slack-button large"
  }, ___EmotionJSX("a", {
    href: getGlobalAuthUrl(),
    target: "_self",
    className: "action flat-white"
  }, ___EmotionJSX("img", {
    src: SmallLogo,
    alt: "",
    style: {
      width: 24,
      marginRight: 8
    }
  }), " Reinstall in Slack"))) : ___EmotionJSX(Layout.HelpMessage, {
    kind: "gift"
  }, "New features are available for your Slack integration!", ___EmotionJSX("br", null), ___EmotionJSX("a", {
    className: "auth-link",
    href: getGlobalAuthUrl(),
    target: "_self"
  }, "To enable them, we need your authorization."))), hasInstalledIntegration && version === 'v1' && !!integration.webhook_url && ___EmotionJSX(Layout.HelpMessage, {
    kind: "important"
  }, ___EmotionJSX("strong", null, "You have a legacy Slack integration enabled."), ___EmotionJSX("br", null), "We've redesigned our integration to give you control over which projects broadcast activity to Slack. When you create a new integration below, your legacy integration will be disabled."), hasInstalledIntegration && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", null, ___EmotionJSX(IntegrationTable, {
    type: "Team",
    items: teams,
    onDismissItem: async id => {
      const teamIntegration = integration.teamIntegrations.find(t => t.group_id === id);
      if (teamIntegration) {
        try {
          await deleteTeamIntegration(teamIntegration);
          setTeams(curr => curr.filter(t => t.id !== id));
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, "Integration removed.")
          });
        } catch (err) {
          addToast({
            kind: 'alert',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, `Unable to delete integration. ${err}`)
          });
        }
      } else {
        setTeams(curr => curr.filter(t => t.id !== id));
      }
    },
    title: "Link Teams to Slack Channels",
    emptyStateMessage: "You have not linked any Teams to a Slack Channel yet."
  }), ___EmotionJSX(Select, {
    type: Select.KIND.TEAM,
    onChange: id => {
      if (!teams.find(t => t.id === id)) {
        const newItem = mapTeamIntegrationToItemsProps({
          group_id: id
        });
        if (newItem) setTeams(curr => [...curr, newItem]);
      }
    }
  })), areCustomFieldsEnabled() && ___EmotionJSX("div", null, ___EmotionJSX(IntegrationTable, {
    type: "Field Value",
    items: fields,
    onDismissItem: async id => {
      const fieldIntegration = integration.fieldIntegrations.find(f => f.field_value_id === id);
      if (fieldIntegration) {
        try {
          await deleteFieldIntegration(fieldIntegration);
          setFields(curr => curr.filter(f => f.id !== id));
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, "Integration removed.")
          });
        } catch (err) {
          addToast({
            kind: 'alert',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, `Unable to delete integration. ${err}`)
          });
        }
      } else {
        setFields(curr => curr.filter(f => f.id !== id));
      }
    },
    title: "Link Field Values to Slack Channels",
    emptyStateMessage: "You have not linked any Field Values to a Slack Channel yet."
  }), ___EmotionJSX(Select, {
    type: Select.KIND.FIELD,
    onChange: id => {
      const [field_id, field_value_id] = id.split(':');
      if (!fields.find(f => f.id === field_value_id)) {
        const newItem = mapFieldIntegrationToItemsProps({
          field_id,
          field_value_id
        });
        if (newItem) setFields(curr => [...curr, newItem]);
      }
    },
    title: "Select a Field Value"
  })), isProjectsFeatureEnabled() && ___EmotionJSX("div", null, ___EmotionJSX(IntegrationTable, {
    type: "Project",
    items: projects,
    onDismissItem: async id => {
      const projectIntegration = integration.projectIntegrations.find(p => String(p.project_id) === String(id));
      if (projectIntegration) {
        try {
          await deleteProjectIntegration(projectIntegration);
          setProjects(curr => curr.filter(p => String(p.id) !== String(id)));
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, "Integration removed.")
          });
        } catch (err) {
          addToast({
            kind: 'alert',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, `Unable to delete integration. ${err}`)
          });
        }
      } else {
        setProjects(curr => curr.filter(p => String(p.id) !== String(id)));
      }
    },
    title: "Link Projects to Slack Channels",
    emptyStateMessage: "You have not linked any Projects to a Slack Channel yet."
  }), ___EmotionJSX(Select, {
    type: Select.KIND.PROJECT,
    onChange: id => {
      if (!projects.find(p => String(p.id) === String(id))) {
        const newItem = mapProjectIntegrationToItemsProps({
          project_id: id
        });
        if (newItem) setProjects(curr => [...curr, newItem]);
      }
    }
  })))))));
}
Slack.displayName = "Slack";