import Logo from '@clubhouse/assets/png/third-party-logos/rootly_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Rootly() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.ROOTLY
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Rootly"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Rootly Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Manage incident action items seemlessly by pushing them directly to Shortcut from from within your", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.rootly.com"
  }, "Rootly"), " Slack incident channel."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX("a", {
    href: "https://rootly.com/integrations/shortcut"
  }, "Learn more"), " on how the Rootly integration works in Shortcut.")));
}
Rootly.displayName = "Rootly";