import noop from 'lodash/noop';
import { useSubmitSignup } from 'pages/signup/hooks/useSubmitSignup';
import { CompanyNameFormContent, CompanyNameFormNav } from '../form/CompanyNameForm';
import { PersonalDetailsFormContent, PersonalDetailsFormNav } from '../form/PersonalDetailsForm';
import { ProfileDetailsFormContent, ProfileDetailsFormNav } from '../form/ProfileDetailsForm';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function useSignupSteps() {
  const submitSignup = useSubmitSignup();
  const CompanyNameForm = {
    id: 'CompanyNameForm',
    nav: ___EmotionJSX(CompanyNameFormNav, null),
    content: ___EmotionJSX(CompanyNameFormContent, {
      goBack: noop,
      goNext: noop
    })
  };
  const ProfileDetailsForm = {
    id: 'ProfileDetailsForm',
    nav: ___EmotionJSX(ProfileDetailsFormNav, null),
    content: ___EmotionJSX(ProfileDetailsFormContent, {
      goBack: noop,
      goNext: noop
    })
  };
  const PersonalDetailsForm = {
    id: 'PersonalDetailsForm',
    nav: ___EmotionJSX(PersonalDetailsFormNav, null),
    content: ___EmotionJSX(PersonalDetailsFormContent, {
      goBack: noop,
      goNext: submitSignup
    })
  };
  return [CompanyNameForm, ProfileDetailsForm, PersonalDetailsForm];
}