import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TooltipContent = _ref => {
  let {
    blockersCount,
    hasBlockedLabel
  } = _ref;
  let text = 'This Story ';
  if (blockersCount) {
    text += 'is blocked by ' + pluralize({
      count: blockersCount,
      singular: 'Story',
      plural: 'Stories'
    });
  }
  if (hasBlockedLabel) {
    text += `${blockersCount ? ', and ' : ' '}has the "blocked" label`;
  }
  text += '.';
  return ___EmotionJSX(ChipTooltip.Text, null, text);
};
TooltipContent.displayName = "TooltipContent";
export const BlockedChip = _ref2 => {
  let {
    blockersCount,
    hasBlockedLabel
  } = _ref2;
  if (!blockersCount && !hasBlockedLabel) return null;
  const blockedChip = ___EmotionJSX(Chip, {
    kind: "red"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(Chip.Icon, {
    icon: "StoryBlocked",
    label: "Blocked"
  })), blockersCount || null);
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(TooltipContent, {
      blockersCount: blockersCount,
      hasBlockedLabel: hasBlockedLabel
    })
  }, blockedChip);
};
BlockedChip.displayName = "BlockedChip";