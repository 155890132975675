import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { MilestoneSearchCardAdapter } from './ObjectiveSearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ObjectiveResults = _ref => {
  let {
    fetchProps: {
      loading,
      data: milestones,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SkeletonSearchCard, {
      icon: "Objectives",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Objectives",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Objectives",
      kind: "blue"
    }));
  }
  const resultsList = loading ? previousData : milestones;
  if (hasFetched && !resultsList.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Objectives"
    });
  }
  return ___EmotionJSX(React.Fragment, null, resultsList.map((milestone, index) => ___EmotionJSX(MilestoneSearchCardAdapter, {
    key: milestone.id,
    onClick: entity => onClick?.(entity, index),
    openModalOnClick: openModalOnClick,
    milestone: milestone
  })), next && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: loadMore
  }));
};