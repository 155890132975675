import Logo from '@clubhouse/assets/png/third-party-logos/lambdatest_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function LambdaTest() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.LAMBDATEST
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "LambdaTest"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "LambdaTest Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "File browser testing issues to Shortcut, directly from the", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.lambdatest.com/"
  }, "LambdaTest"), " platform."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.lambdatest.com/support/docs/shortcut-integration/"
  }, "Learn more"), ' ', "on how the LambdaTest integration works in Shortcut.")));
}
LambdaTest.displayName = "LambdaTest";