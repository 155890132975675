import { jsx as ___EmotionJSX } from "@emotion/react";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const renderOption = _ref => {
  let {
    name,
    value
  } = _ref;
  return ___EmotionJSX("option", {
    key: value || name,
    value: value
  }, name);
};
renderOption.displayName = "renderOption";
export const Select = _ref2 => {
  let {
    value = '',
    label,
    hint,
    placeholder = 'Choose one...',
    options = [],
    validate,
    ...selectProps
  } = _ref2;
  return ___EmotionJSX("div", {
    className: "select",
    "data-validate": validate
  }, label && ___EmotionJSX("label", {
    htmlFor: selectProps.id
  }, label, " ", hint && ___EmotionJSX("span", {
    className: "label-optional"
  }, hint)), ___EmotionJSX("div", {
    className: "styled-select"
  }, ___EmotionJSX("select", _extends({
    defaultValue: value
  }, selectProps), ___EmotionJSX("option", {
    key: "",
    value: ""
  }, placeholder), options.map(renderOption)), ___EmotionJSX("span", {
    className: "fa fa-caret-down"
  })));
};
Select.displayName = "Select";
Select.Divider = {
  name: '----',
  disabled: true
};