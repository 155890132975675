function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CreatedVsCompletedReportCard = props => ___EmotionJSX(ReportCard, _extends({}, props, {
  title: "Created vs. Completed",
  description: "Work created and completed per interval over time.",
  Explanation: Explanation,
  Filters: Filters
}));
CreatedVsCompletedReportCard.displayName = "CreatedVsCompletedReportCard";