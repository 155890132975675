import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Nouns } from '@clubhouse/shared/constants';
import { pluralizeNoun } from '@clubhouse/shared/utils';
import { Icon } from '@useshortcut/shapes-ds';
import { useCompany } from 'data/entity/company';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOnly, ownerOnly } from 'utils/is';
import { DragHandle } from '../shared/DragHandle';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function CompanyInfo(_ref) {
  let {
    id
  } = _ref;
  const company = useCompany(id);
  const workspaceCount = company.organizations.length;
  const isLockedOut = company.locked_out;
  const isDisabled = company.disabled;
  const isOwner = company.organizations.some(org => ownerOnly(getLoggedInUserPermission(org.id)));
  const isAdmin = company.organizations.some(org => adminOnly(getLoggedInUserPermission(org.id)));
  const spaceTerm = pluralizeNoun(Nouns.Workspace, workspaceCount);
  return ___EmotionJSX("div", {
    className: "company-info"
  }, ___EmotionJSX("div", {
    className: "company-sort-actions top-level"
  }, ___EmotionJSX(DragHandle, null), ___EmotionJSX("span", {
    className: "tagged orange"
  }, "Organization")), ___EmotionJSX("h2", null, company.name), ___EmotionJSX("div", {
    className: "company-management-actions"
  }, isLockedOut ? ___EmotionJSX("span", {
    className: "tagged disabled"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 12
  }, ___EmotionJSX(Icon, {
    icon: "Lock"
  })), ' ', "Locked")) : isDisabled ? ___EmotionJSX("span", {
    className: "tagged disabled"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 12
  }, ___EmotionJSX(Icon, {
    icon: "Lock"
  })), ' ', "Disabled")) : null, !isLockedOut && ___EmotionJSX(React.Fragment, null, !isDisabled && (isOwner || isAdmin) && ___EmotionJSX("a", {
    href: `/signup?org=${id}`,
    className: "action micro white add-org-to-company"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 14,
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Add"
  })), ' ', "Add Workspace to Organization")), isOwner ? ___EmotionJSX("a", {
    href: `/organizations/${company.slug}/manage`,
    className: "action micro white manage-company"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 14
  }, ___EmotionJSX(Icon, {
    icon: "Edit"
  })), ' ', "Manage Organization and ", spaceTerm)) : isAdmin && !isDisabled ? ___EmotionJSX("a", {
    href: `/organizations/${company.slug}/manage`,
    className: "action micro white manage-company"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 14
  }, ___EmotionJSX(Icon, {
    icon: "Edit"
  })), ' ', "Manage ", spaceTerm)) : null)));
}
CompanyInfo.displayName = "CompanyInfo";