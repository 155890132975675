import { LoadingState } from './shared/LoadingState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ImportingState = _ref => {
  let {
    platformLabel = ''
  } = _ref;
  const label = platformLabel !== 'shortcut-csv-export' ? `${platformLabel} ` : '';
  return ___EmotionJSX(LoadingState, {
    title: `Uploading your ${label}data into ${BRAND.NAME}!`,
    message: `Almost there! Please wait while we upload your data into ${BRAND.NAME}. You will be notified when this is complete, so feel free to explore in the meantime.`
  });
};
ImportingState.displayName = "ImportingState";