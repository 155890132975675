import { jsx as ___EmotionJSX } from "@emotion/react";
export const errorMapper = messageFromServer => ({
  'User is already a member of this organization.': {
    title: 'User already belongs to the workspace.',
    message: ___EmotionJSX("a", {
      href: "/login"
    }, "Click here to log in.")
  }
})[messageFromServer] || {
  message: messageFromServer
};