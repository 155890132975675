import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { useEpicWithFetch } from 'data/entity/epic';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CollectionizeEpicContextMenu = _ref => {
  let {
    id
  } = _ref;
  const {
    epic,
    loading
  } = useEpicWithFetch({
    id
  });
  if (loading) return ___EmotionJSX(ContextMenuLoading, null);
  if (!epic) return null;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getHref(id)
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(id, true),
    label: "Link"
  }), ___EmotionJSX(CopyContextMenuItem, {
    value: String(id),
    label: "ID"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: epic.global_id,
    entityType: Nouns.Epic,
    loggingContext: {
      typename: 'Epic'
    }
  }));
};
CollectionizeEpicContextMenu.displayName = "CollectionizeEpicContextMenu";
export const useCollectionizeEpicContextMenu = epicId => {
  const {
    props
  } = useContextMenuWithLogging(() => ___EmotionJSX(CollectionizeEpicContextMenu, {
    id: epicId
  }), {
    entityType: 'Epic'
  });
  return props;
};
export const useCollectionizeEpicContextMenuWithQuickSearchSessionLogging = (epicId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => ___EmotionJSX(CollectionizeEpicContextMenu, {
    id: epicId
  }), {
    entityType: 'Epic'
  }, logQuickSearchInteractions);
  return props;
};