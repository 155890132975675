const componentStore = new Map();
const cancelCallbacks = state => {
  if (state.animationFrameId) cancelAnimationFrame(state.animationFrameId);
  state.observer?.disconnect();
  state.mutationObserver?.disconnect();
};
const createState = _ref => {
  let {
    componentId,
    batchSize
  } = _ref;
  return {
    componentId,
    numVisible: batchSize,
    observer: null,
    observedElement: null,
    mutationObserver: null,
    scrollTop: 0,
    scrollLeft: 0,
    animationFrameId: null,
    totalSize: null,
    itemSize: {
      width: 0,
      height: 0
    },
    onScroll: () => {}
  };
};
const getState = componentId => {
  return componentStore.get(componentId);
};
export const destroyAll = () => {
  componentStore.forEach(state => {
    cancelCallbacks(state);
  });
  componentStore.clear();
};
const getMountNode = componentId => document.getElementById(componentId);
const getItemContainer = _ref2 => {
  let {
    componentId,
    scrollContainerQuery
  } = _ref2;
  return scrollContainerQuery ? document.querySelector(scrollContainerQuery) : getMountNode(componentId);
};
const getNextPlaceholderId = componentId => `${componentId}-next-placeholder`;
const getNextPlaceHolder = componentId => document.getElementById(getNextPlaceholderId(componentId));
const renderMore = _ref3 => {
  let {
    componentId,
    items,
    renderItem,
    batchSize,
    onRenderMore,
    scrollContainer,
    onBeforeRenderMore
  } = _ref3;
  const state = getState(componentId);
  if (!state) return;
  const offset = state.numVisible;
  const itemsToRender = items.slice(offset, offset + batchSize);
  state.numVisible = offset + itemsToRender.length;
  onBeforeRenderMore?.();
  const html = itemsToRender.map(item => renderItem(item)).join('');
  getNextPlaceHolder(componentId).insertAdjacentHTML('beforebegin', html);
  setPlaceholderSize({
    state
  });
  initObserver({
    state,
    items,
    scrollContainer,
    renderItem,
    batchSize,
    onRenderMore,
    onBeforeRenderMore
  });
  onRenderMore?.();
};
const setPlaceholderSize = _ref4 => {
  let {
    state,
    nextPlaceholder = getNextPlaceHolder(state.componentId)
  } = _ref4;
  if (typeof state?.totalSize !== 'number') return;
  const nextPlaceholderHeight = (state.totalSize - state.numVisible) * state.itemSize.height;
  nextPlaceholder.style.height = `${nextPlaceholderHeight}px`;
  const nextPlaceholderWidth = (state.totalSize - state.numVisible) * state.itemSize.width;
  nextPlaceholder.style.width = `${nextPlaceholderWidth}px`;
};
const initObserver = _ref5 => {
  let {
    state,
    items,
    scrollContainer,
    renderItem,
    batchSize,
    onRenderMore,
    onBeforeRenderMore
  } = _ref5;
  const {
    componentId
  } = state;
  if ((scrollContainer.scrollHeight >= scrollContainer.clientHeight || scrollContainer.scrollWidth >= scrollContainer.clientWidth) && state.numVisible < items.length) {
    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          renderMore({
            componentId,
            items,
            renderItem,
            batchSize,
            onRenderMore,
            scrollContainer,
            onBeforeRenderMore
          });
        }
      });
    };
    state.observer?.disconnect();
    state.observer = new IntersectionObserver(callback, {
      root: scrollContainer,
      rootMargin: `${state.itemSize.height}px ${state.itemSize.height}px ${state.itemSize.height}px ${state.itemSize.height}px`,
      threshold: 0
    });
    const observeAndSetInState = () => {
      state.observedElement = getNextPlaceHolder(componentId);
      state.observer?.observe(state.observedElement);
    };
    observeAndSetInState();
    state.mutationObserver?.disconnect();
    state.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' && state.observedElement && !state.observedElement.isConnected) {
          state.observer?.disconnect();
          observeAndSetInState();
        }
      });
    });
    state.mutationObserver.observe(scrollContainer, {
      childList: true
    });
  }
};
export const render = _ref6 => {
  let {
    id,
    items,
    className,
    renderItem,
    batchSize = 10,
    onRenderMore,
    onBeforeRenderMore,
    itemSize,
    scrollContainerQuery
  } = _ref6;
  const componentId = id;
  const mountNode = getMountNode(componentId);
  let state = getState(componentId);
  if (!state) {
    state = createState({
      batchSize,
      componentId
    });
    componentStore.set(componentId, state);
  }
  state.totalSize = items.length;
  state.itemSize = itemSize;
  if (mountNode) {
    cancelCallbacks(state);
  }
  state.animationFrameId = requestAnimationFrame(() => {
    const state = getState(componentId);
    const mountNode = getMountNode(componentId);
    if (!state || mountNode && !mountNode.isConnected) {
      return;
    }
    const scrollContainer = getItemContainer({
      componentId,
      scrollContainerQuery
    });
    if (!scrollContainer) {
      return;
    }
    scrollContainer.removeEventListener('scroll', state.onScroll);
    state.onScroll = e => {
      const {
        scrollTop,
        scrollLeft
      } = e.target;
      state.scrollTop = scrollTop;
      state.scrollLeft = scrollLeft;
    };
    scrollContainer.addEventListener('scroll', state.onScroll, {
      passive: true
    });
    const {
      scrollTop,
      scrollLeft
    } = state;
    scrollContainer.scrollTop = scrollTop;
    scrollContainer.scrollLeft = scrollLeft;
    initObserver({
      state,
      items,
      scrollContainer,
      onRenderMore,
      batchSize,
      renderItem,
      onBeforeRenderMore
    });
  });
  const itemsToRender = items.slice(0, state.numVisible < batchSize ? batchSize : state.numVisible);
  state.numVisible = itemsToRender.length;
  const html = itemsToRender.map(item => renderItem(item)).join('');
  const nextPlaceholder = document.createElement('div');
  nextPlaceholder.id = getNextPlaceholderId(componentId);
  setPlaceholderSize({
    state,
    nextPlaceholder
  });
  return `<div id=${state.componentId} ${className ? `class="${className}""` : ''}>${html}${nextPlaceholder.outerHTML}</div>`;
};