import { Fragment } from 'react';
import { ReportHeader } from 'components/shared/reports/ReportHeader';
import { useDebounce } from '../hooks/useDebounce';
import { useRefreshKey } from '../hooks/useRefreshKey';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportCard = _ref => {
  let {
    children,
    onChangeFilter,
    title,
    className,
    description,
    Explanation,
    Filters = () => null,
    refetchChartCb,
    chartId
  } = _ref;
  const onChangeReportFilter = () => {
    if (onChangeFilter) onChangeFilter();
  };
  const [refreshKeys, handleRefresh] = useRefreshKey();
  const debouncedHandleRefresh = useDebounce(() => {
    if (refetchChartCb) {
      refetchChartCb();
    } else {
      handleRefresh(chartId);
    }
  }, 1000);
  return ___EmotionJSX(Fragment, {
    key: refreshKeys[chartId]
  }, ___EmotionJSX(ReportHeader, {
    title: title,
    reportExplanation: ___EmotionJSX(Explanation, {
      isEmbedded: false
    }),
    reportFilters: ___EmotionJSX(Filters, {
      onChange: onChangeReportFilter
    }),
    reportDescription: description,
    handleReportsRefresh: debouncedHandleRefresh,
    className: className
  }), children);
};
ReportCard.displayName = "ReportCard";