import { PinEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { usePinnedItem } from 'components/shared/pin-items/hooks/usePinnedItems';
import { jsx as ___EmotionJSX } from "@emotion/react";
const PinEpicActionFragment = PinEpicActionFragmentFragmentDoc;
export const PinEpicAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    isPinned,
    toggle
  } = usePinnedItem({
    id: epic.id,
    loggingContext: {
      typename: 'Epic'
    }
  });
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Pin",
    onClick: () => {
      toggle();
    }
  }, isPinned ? 'Unpin' : 'Pin', " ", Nouns.Epic.singular);
};
PinEpicAction.displayName = "PinEpicAction";