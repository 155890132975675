function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
import StoryModel from 'app/client/core/js/models/story.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Model', 'Story'], StoryModel], [['Tooltip'], Tooltip], [['Controller', 'Story'], StoryController], [['Model', 'Story'], StoryModel], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import isPlainObject from 'lodash/isPlainObject';
import { generateStoryUrl, getFormattedName, isValidForAutoLink, normalize } from 'data/entity/story';
import { useCollectionizeStoryContextMenu } from 'utils/contextMenus/useCollectionizeStoryContextMenu';
import { openStory } from 'utils/storyDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Name = _ref2 => {
  let {
    story
  } = _ref2;
  const contextMenuProps = useCollectionizeStoryContextMenu(story.id);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("a", _extends({
    href: generateStoryUrl(story),
    className: "entity-title-link",
    "data-model": "Story",
    "data-tooltip": true,
    "data-tooltip-fn": "App.Controller.Story.renderStoryTooltip",
    onClick: e => {
      e.preventDefault();
      openStory(story);
    },
    "data-id": story.id
  }, contextMenuProps, {
    dangerouslySetInnerHTML: {
      __html: getFormattedName(story) /* getFormattedName returns sanitized HTML */
    }
  })), ' ', story.archived ? ___EmotionJSX("span", _extends({
    className: "story-is-archived"
  }, contextMenuProps), "(archived)", ' ') : null);
};
export const StoryLinkName = _ref3 => {
  let {
    story
  } = _ref3;
  if (isValidForAutoLink(story)) {
    normalize(story);
    return ___EmotionJSX(Name, {
      story: story
    });
  } else if (story && !isPlainObject(story)) {
    return ___EmotionJSX("span", {
      className: "auto-story-link story-was-deleted",
      "data-tooltip": `Story ${story} has been deleted.`
    }, story);
  } else {
    return ___EmotionJSX("em", null, "Unknown Story");
  }
};