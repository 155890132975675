import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { getCurrent as getCurrentCompany } from 'data/entity/company';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function OrganizationsDashboardHeader() {
  const canAddOrg = getCurrentCompany()?.closed !== true;
  return ___EmotionJSX("div", {
    className: "header"
  }, ___EmotionJSX("h2", null, "Your Shortcut Organizations and Workspaces"), canAddOrg ? ___EmotionJSX("a", {
    href: "/signup-new-org",
    className: "action mini white"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "var(--iconGreenColor)"
  }, ___EmotionJSX(Icon, {
    icon: "Add"
  }), " Add new organization"))) : null);
}
OrganizationsDashboardHeader.displayName = "OrganizationsDashboardHeader";