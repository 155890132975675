import { QueryWorkspaceProjectsFeatureDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export const QUERY_WORKSPACE_PROJECTS_FEATURE = QueryWorkspaceProjectsFeatureDocument;
export function useWorkspaceProjectsFeature() {
  const slug = useWorkspaceSlug();
  const {
    data
  } = useQuery(QUERY_WORKSPACE_PROJECTS_FEATURE, {
    variables: {
      slug
    }
  });
  return data?.workspace2?.features.projects.enabled || false;
}