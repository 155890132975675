function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useDraggableItemState } from '@clubhouse/shared/components/DragAndDrop';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DragHandle(_ref) {
  let {
    isWorkspace
  } = _ref;
  const {
    dragHandleProps
  } = useDraggableItemState();
  return ___EmotionJSX("a", _extends({
    href: "#",
    className: `drag ${isWorkspace ? 'drag-org-within-company' : 'drag-org-or-company'}`
  }, dragHandleProps), ___EmotionJSX("span", {
    className: "fa fa-arrows"
  }));
}
DragHandle.displayName = "DragHandle";