import { usePlanStatus } from 'data/entity/paymentPlan2';
import { toMoney } from 'utils/format';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CardStatuses = _ref => {
  let {
    paymentPlan,
    organization,
    card
  } = _ref;
  const deliquentStatus = usePlanStatus('delinquent', paymentPlan);
  const trialingStatus = usePlanStatus('trialing', paymentPlan);
  const availableFreeSeats = usePlanStatus('has_available_free_seats', paymentPlan);
  const hasAvailableFreeSeats = !!availableFreeSeats?.available_seats;
  const orgDisabledOrLocked = organization.locked_out || organization.disabled;
  const cardText = card ? 'updated card' : 'card';
  switch (true) {
    case !!deliquentStatus:
      return ___EmotionJSX(React.Fragment, null, "Your card will be charged to cover your unpaid balance of", ' ', ___EmotionJSX("strong", null, "$", toMoney(deliquentStatus?.account_balance ?? 0), "."));
    case !!trialingStatus:
      return hasAvailableFreeSeats ? `Your card will only be charged if use the remaining ${availableFreeSeats.available_seats} seats at the end of your trial.` : 'Your card will not be charged until the end of your trial.';
    case orgDisabledOrLocked:
      return ___EmotionJSX(React.Fragment, null, "Your card will be charged immediately for the current plan.");
    case hasAvailableFreeSeats:
      return ___EmotionJSX(React.Fragment, null, "Your ", cardText, " will be used for future charges, if you upgrade your Plan.");
    default:
      return ___EmotionJSX(React.Fragment, null, "Your ", cardText, " will be used for future charges. You will not be charged immediately.");
  }
};