import { StoryCardInternal } from '../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Owners = _ref => {
  let {
    showEmail,
    users
  } = _ref;
  return ___EmotionJSX(StoryCardInternal.GroupAndOwners.LegacyOwners, {
    showEmail: showEmail,
    users: users
  });
};
Owners.displayName = "Owners";