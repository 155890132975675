import { QueryTeamNodeForTooltipDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { TeamTooltip as TeamTooltipShared } from '@clubhouse/shared/components/TeamTooltip';
import { AsyncTooltip } from '@clubhouse/shared/components/Tooltip';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { useLazyQuery } from 'gql';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const QUERY_TEAM_NODE_FOR_TOOLTIP = QueryTeamNodeForTooltipDocument;
export const TeamTooltip = _ref => {
  let {
    teamId,
    children
  } = _ref;
  const [fetchTeam] = useLazyQuery(QUERY_TEAM_NODE_FOR_TOOLTIP);
  const fetchTooltipData = useCallback(async () => {
    if (!teamId) return null;
    const {
      data
    } = await fetchTeam({
      variables: {
        id: teamId
      }
    });
    const {
      node: team
    } = data || {};
    if (team?.__typename !== 'Team') return null;
    return ___EmotionJSX(TeamTooltipShared.DefaultTeamTooltipContent, {
      name: team.name || undefined,
      mentionName: team.mentionName || undefined,
      totalMemberCount: team.members?.pageInfo.totalSize,
      members: team.members?.edges.map(_ref2 => {
        let {
          node: {
            id,
            email,
            mentionName,
            displayName,
            displayIcon
          }
        } = _ref2;
        return {
          id,
          email,
          mentionName,
          fullName: displayName,
          imageUrl: displayIcon?.thumbnailUrl
        };
      }),
      teamColor: getTeamColor(team),
      imageUrl: team.displayIcon?.thumbnailUrl || undefined
    });
  }, [fetchTeam, teamId]);
  return ___EmotionJSX(AsyncTooltip, {
    fetchContent: fetchTooltipData
  }, children);
};
TeamTooltip.displayName = "TeamTooltip";