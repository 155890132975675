function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { getDisplayName } from 'data/typeConfigs';
import { DeselectAllItems } from './DeselectAllItems';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const AllItem = props => ___EmotionJSX(DeselectAllItems, _extends({}, props, {
  isSelected: props.item.isSelected,
  text: props.item.text,
  IconLeft: null
}));
AllItem.displayName = "AllItem";
export const createAllItem = _ref => {
  let {
    selectedItemValues,
    type
  } = _ref;
  return {
    component: AllItem,
    isSelected: selectedItemValues.length === 0,
    selectedItemValues: [],
    text: `All ${getDisplayName({
      type,
      isSingular: false
    })}`,
    value: 'ALL'
  };
};