import InvitationCheckEmailPng from '@clubhouse/assets/png/envelope-illo.png';
import { CompositeImage } from 'components/shared/CompositeImage';
import { Problem } from '../shared/Problem';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const SUCCESS_TITLE = 'Check your email!';
export const SUCCESS_DESCRIPTION = 'We just emailed you a verification link. The link will expire shortly, so click on it soon!';
export const InviteLinkSuccess = () => ___EmotionJSX(Problem, {
  className: "invite-link-success",
  media: ___EmotionJSX(CompositeImage, {
    primaryImage: {
      src: InvitationCheckEmailPng,
      width: 100,
      height: 90,
      alt: ''
    }
  }),
  title: SUCCESS_TITLE,
  description: SUCCESS_DESCRIPTION
});
InviteLinkSuccess.displayName = "InviteLinkSuccess";