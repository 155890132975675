import { EventNamesEnum } from '@useshortcut/events';
import cookies from 'js-cookie';
import { getAllFeatureToggles } from '@clubhouse/feature-toggles';
import CompanyModel from 'app/client/core/js/models/company';
import OrganizationModel from 'app/client/core/js/models/organization';
import UserModel from 'app/client/core/js/models/user';
import Is from 'app/client/core/js/modules/is';
import { attachConsentPreferences } from 'components/consentManager/utils';
import { getCurrentPlan, getPlanDisplayName, getPlanLevel } from 'data/entity/paymentPlan2';
import { isPlaywrightTestEnv } from '../../isPlaywrightTestEnv';
import { SEGMENT_EVENTS } from '../ownedEvents';
import { NOOP_MONITOR, getNamedEventGlobalAttributes, initializeAsync } from '../utils';
import { analytics } from './analytics';
export const NAME = 'Segment';
export const shouldInitialize = _ref => {
  let {
    env
  } = _ref;
  if (isPlaywrightTestEnv() || env === 'test') {
    return false;
  }
  return true;
};
export const shouldOwnEvent = event => SEGMENT_EVENTS.has(event);
export const shouldOwnNamedEvent = event => Object.values(EventNamesEnum).includes(event);
export const initialize = (_, _ref2) => {
  let {
    onError
  } = _ref2;
  const {
    callWhenReady,
    triggerInitialization
  } = initializeAsync(async attrs => {
    attachConsentPreferences();
    setSessionAttributes(attrs);
  }, {
    triggerManually: true,
    onError
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes: attrs => {
      triggerInitialization(attrs);
    },
    setCurrentPage: callWhenReady(pageEvent),
    logEvent: callWhenReady(sendEvent),
    logNamedEvent: callWhenReady(logNamedEvent)
  };
};
let sessionAttributes;
const setSessionAttributes = attrs => {
  const {
    userId
  } = attrs;
  const userParams = getUserParameters(attrs);
  sessionAttributes = {
    userId,
    ...userParams
  };
  if (!userId) throw new Error('Analytic tracking requires a "userId" and it is missing! Please fix otherwise this event will not track properly');
  const feature_toggles = getAllFeatureToggles();
  identify(userId, {
    ...userParams,
    feature_toggles
  });
};
export const getUserParameters = _ref3 => {
  let {
    organizationId,
    workspaceId,
    email
  } = _ref3;
  const organization = CompanyModel.getById(organizationId);
  const workspace = OrganizationModel.getById(workspaceId);
  const emailAddress = email || UserModel.getPreferredEmailAddress();
  const platform = Is.mobile() ? 'mobile' : 'desktop';

  /**
   * Case happens when:
   *  - The user belonged to a company/org2 but was not only disabled but deactivated from the org2.
   *  - If a user is registering and has not yet created an org.
   *  - When on the "manage orgs" page
   **/
  if (!workspace || !organization) {
    return {
      email: emailAddress,
      platform
    };
  }

  // Shortcut is in a POC with Koala (getkoala.com) to help GTM pipelines. One of these involves better
  // identifying users on the Marketing Website. Currently, we have a small tracking snippet on the Marketing
  // website that places a defined cookie "ko_id" that contains the unique visitor profile ID. In order to
  // facilitate our Koala Identity graph we proxy application website identify calls, where we have a known
  // email address and optionally a Koala ID (from parent top level domain cookie). In Segment, we'll forward
  // these to Koala whenever we have both the Koala ID (e.g. the user also visited the corporate website)
  // and email address. This in turn builds out a better Identify Graph which helps enable strategic
  // conversion/GTM pipelines that would start from defined Koala sequences.
  //
  // Contact References: Mark J, Devin R

  // Get the cookie value or if not specified, will return undefined, which will omit value in upstream
  // trait properties.
  const koalaId = cookies.get('ko_id');
  const paymentPlan = getCurrentPlan();
  return {
    organizationId: organization.id,
    organizationName: organization.name,
    planType: getPlanLevel(paymentPlan),
    plan: paymentPlan ? getPlanDisplayName(paymentPlan) : undefined,
    workspaceId: workspace.id,
    workspaceName: workspace.name,
    email: emailAddress,
    platform,
    koalaId
  };
};
const identify = (userId, attrs) => analytics.identify(userId, attrs);
const pageEvent = () => analytics.page();

// The 4th parameter to `track` is just a callback function that gets called after
// a ~300ms delay. It doesn't necessarily guarantee that the event is sent, but it suffices.
// We Might need to upgrade to v2 of the SDK
const sendEvent = async (eventName, attrs) => {
  return new Promise(resolve => {
    if (sessionAttributes.userId && SEGMENT_EVENTS.has(eventName)) {
      analytics.track(eventName, {
        ...attrs,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    }
  });
};
const logNamedEvent = async (eventName, data) => {
  return new Promise(resolve => {
    const sendEvent = async (eventName, data) => {
      analytics.track(eventName, {
        ...data,
        userId: sessionAttributes.userId
      }, {}, () => resolve());
    };
    if (sessionAttributes.userId) {
      const namedEventGlobalAttrs = getNamedEventGlobalAttributes(eventName, {
        organizationId: sessionAttributes.organizationId,
        workspaceId: sessionAttributes.workspaceId,
        userId: sessionAttributes.userId
      });
      sendEvent(eventName, {
        ...data,
        ...namedEventGlobalAttrs
      });
    }
  });
};