import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { MenuItem } from 'components/shared/menu';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const MenuItemWithTeam = _ref => {
  let {
    team,
    SubMenu
  } = _ref;
  const teamColor = team ? getTeamColor(team) : undefined;
  return ___EmotionJSX(MenuItem, {
    SubMenu: SubMenu,
    icon: ___EmotionJSX(TeamIcon, {
      iconUrl: team?.display_icon?.url,
      size: 24,
      backgroundColor: teamColor,
      iconType: "new-team-icon"
    }),
    label: team?.name ?? 'No Team'
  });
};
MenuItemWithTeam.displayName = "MenuItemWithTeam";