import { ItemIndicator, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { Icon, ToggleTrigger } from '../MenuStyles';
import { Callout } from './Callout';
import { CheckboxItem } from './CheckboxItem';
import { Content } from './Content';
import { Footer } from './Footer';
import { Item } from './Item';
import { Label } from './Label';
import { Loading } from './Loading';
import { Separator } from './Separator';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DropdownMenu = props => ___EmotionJSX(Root, props);
DropdownMenu.displayName = "DropdownMenu";
DropdownMenu.Callout = Callout;
DropdownMenu.CheckboxItem = CheckboxItem;
DropdownMenu.Content = Content;
DropdownMenu.Footer = Footer;
DropdownMenu.Icon = Icon;
DropdownMenu.Item = Item;
DropdownMenu.ItemIndicator = ItemIndicator;
DropdownMenu.Label = Label;
DropdownMenu.Loading = Loading;
DropdownMenu.Portal = Portal;
DropdownMenu.Separator = Separator;
DropdownMenu.ToggleButton = ToggleTrigger;
DropdownMenu.Trigger = Trigger;