function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ChartTypeSelectReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ChartTypeSelectReport_SelectorFragment = ChartTypeSelectReport_SelectorFragmentDoc;
const CHART_TYPE_DESCRIPTIONS = {
  cycleTime: 'Time between any <b>started</b> state and any <b>done</b> state.',
  leadTime: 'Time between any <b>created-in</b> state and any <b>done</b> state',
  custom: `Limit stories to specific start and end states.`
};
export const ChartTypeSelect = props => {
  return ___EmotionJSX(ReportSelectFilter, _extends({}, props, {
    label: "Type",
    LoadingComponent: ___EmotionJSX(SkeletonRectangle, {
      width: 65,
      animate: true
    }),
    descriptions: CHART_TYPE_DESCRIPTIONS
  }));
};
ChartTypeSelect.displayName = "ChartTypeSelect";