function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import * as StoryRelationshipsTemplate from 'app/client/core/views/templates/storyRelationships.html';
import { ContextualStoryCardWithViewSettings, WithFullStoryById } from 'components/shared/ContextualStoryCard';
import { ModifyStoryRelationshipDropdown } from 'components/stories/ModifyStoryRelationshipDropdown';
import MessageController from './message';
import StoryLinkModel from '../models/storyLink';
import UserModel from '../models/user';
import Is from '../modules/is';
import View from '../modules/view';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const updateStoryLink = async _ref => {
  let {
    existingStoryLink: {
      id
    },
    update
  } = _ref;
  try {
    await StoryLinkModel.Promises.update({
      id,
      ...update
    });
  } catch (e) {
    MessageController.error(`Story Relationship update failed: ${e}`);
  }
};
const removeStoryLink = async _ref2 => {
  let {
    existingStoryLink: {
      id
    }
  } = _ref2;
  try {
    await StoryLinkModel.Promises.delete(id);
  } catch (e) {
    MessageController.error(`Story Relationship delete failed: ${e}`);
  }
};
exports.updateStoryLinkComponent = function (story, element) {
  let {
    update = updateStoryLink,
    remove = removeStoryLink
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const container = $(element).find('.story-relationships');
  if (container.length > 0) {
    const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
    View.renderWithComponents({
      templateMountNode: container[0],
      template: StoryRelationshipsTemplate,
      templateData: {
        readOnly: isReadOnly,
        ...story
      },
      restoreActiveElement: true,
      components: {
        modifyStoryRelationship: {
          component: ModifyStoryRelationshipDropdown,
          getProps: _ref3 => {
            let {
              props: {
                storyLinkKey
              }
            } = _ref3;
            const storyLink = story.story_links.find(link => StoryLinkModel.getKey(link) === storyLinkKey);
            const relatedStory = StoryLinkModel.getRelatedStory(storyLink);
            return {
              story,
              storyLink,
              relatedStory,
              update,
              remove
            };
          }
        }
      }
    });
  }
};
exports.renderStoryCard = story => {
  return View.renderComponentDelayed({
    componentKey: `storyCard-${story.id}`,
    cssClass: 'js-story-card react-wrapper story',
    containerProps: {
      'data-model': 'Story',
      'data-id': story.id,
      'data-updated-at': story.updated_at,
      role: 'listitem',
      'aria-labelledby': `story-${story.id}`
    },
    component: ___EmotionJSX(WithFullStoryById, {
      key: story.id,
      showCardColor: true,
      showSelectToggle: true,
      openModalOnClick: false,
      component: props => ___EmotionJSX(ContextualStoryCardWithViewSettings, _extends({}, props, {
        useDensity: true,
        level: "1"
      })),
      storyId: story.id
    })
  }).html;
};
exports.removeInvalidStoryLinks = async links => {
  const storyLinkIsValidStatuses = await Promise.all(links.map(link => StoryLinkModel.isValidWithFetch(link)));
  return links.filter((_, index) => storyLinkIsValidStatuses[index]);
};
export { exports as default };