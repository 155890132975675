import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import moment from 'moment';
import { useEffect, useState } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/github_logo.png';
import { Loading } from '@clubhouse/shared/components/Loading';
import { FixedSpacer, Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { AddGithubAccount } from 'components/integrations/AddGithubAccount';
import { fetchAll as fetchAllEvents } from 'data/entity/event';
import { ApplicationIds, fetchAll as fetchAllInstallations, getInstallationsForUser, installCopyToCurrentWorkspace } from 'data/entity/installation';
import { getCurrentOrgId } from 'data/entity/organization';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { fetchAll as fetchAllWebhooks } from 'data/entity/webhook';
import { isDevelopment, isProduction, isStaging } from 'utils/is';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { PAGE_NAMES, generatePathForPage, getCurrentSlug, navigateOnClick, redirect } from 'utils/navigation';
import { CopyWebhookUrl } from './components/CopyWebhookUrl';
import { CreateEventDefinition } from './components/CreateEventDefinition';
import { EventDefinitionsTable } from './components/EventDefinitionsTable';
import { EventHandlerToggle } from './components/EventHandlerToggle';
import { ExternalLink } from './components/ExternalLink';
import { GitBranchFormatting } from './components/GitBranchFormatting';
import { GithubInstallationTable } from './components/GithubInstallationTable';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function GitHub() {
  const manageWorkflowUrl = generatePathForPage(PAGE_NAMES.SETTINGS_WORKFLOW, {
    slug: getCurrentSlug()
  });
  const admin = isLoggedInUserAdmin();
  const [isReady, toggleReady] = useToggleState();
  const [githubEnterpriseInstallation, setGithubEnterpriseInstallation] = useState(null);
  const [currentInstallations, setCurrentInstallations] = useState([]);
  const [availableInstallations, setAvailableInstallations] = useState([]);
  const isInstalled = !!currentInstallations?.length;
  useEffect(() => {
    Promise.all([fetchAllWebhooks(), fetchAllEvents(), fetchAllInstallations()]).then(() => getInstallationsForUser(ApplicationIds.Github)).then(allInstallations => {
      setGithubEnterpriseInstallation(allInstallations.find(i => i.github_enterprise) || null);
      const workspaceId = getCurrentOrgId();
      allInstallations.sort((a, b) => moment(a.created_at).isBefore(b.created_at) ? -1 : 1);
      const currentInstallations = allInstallations.filter(i => !i.github_enterprise && i.workspace2_id === workspaceId);
      const currentInstallationExternalIds = new Set(currentInstallations.map(i => i.external_id));
      const availableInstallations = allInstallations.filter(i => !i.github_enterprise && i.workspace2_id !== workspaceId && !currentInstallationExternalIds.has(i.external_id));
      const uniqueAvailableInstallations = availableInstallations.filter((v, index) => availableInstallations.findIndex(i => i.external_id === v.external_id) === index);
      setCurrentInstallations(currentInstallations);
      setAvailableInstallations(uniqueAvailableInstallations);
      toggleReady.on();
    });
  }, [toggleReady, toggleReady.on]);
  const onAuthorize = () => {
    if (isProduction()) {
      redirect(`https://github.com/apps/shortcut-integration/installations/new?state=${getCurrentOrgId()}`);
    } else if (isStaging() || isDevelopment()) {
      redirect(`https://github.com/apps/shortcut-integration-staging/installations/new?state=${getCurrentOrgId()}`);
    }
  };
  const onAddExistingInstallation = async installation_id => {
    try {
      await installCopyToCurrentWorkspace(installation_id);
      addToast({
        kind: 'success',
        timeout: 5_000,
        Content: () => ___EmotionJSX(ToastText, null, "You've added a new account!")
      });
    } catch (err) {
      addToast({
        kind: 'alert',
        timeout: 10_000,
        Content: () => ___EmotionJSX(Spaced, {
          vertically: true,
          amount: "space1"
        }, ___EmotionJSX(ToastTitle, null, "Failed to add account"), ___EmotionJSX(ToastText, null, err), ",")
      });
    }
  };
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.GITHUB
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "GitHub"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "GitHub Logo",
    invertOnDarkMode: true
  }), !admin && ___EmotionJSX(Layout.ReadOnlyMessage, null), ___EmotionJSX(Layout.Blurb, null, "With our GitHub integration, you can link stories to commits, branches and pull requests, and use your GitHub workflow to move stories across your ", BRAND.NAME, " workflow. Check out our guides on", ' ', ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_GITHUB
  }, "how to set up"), " and", ' ', ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.GUIDE_VCS
  }, "use our GitHub integration"), ". Are you using", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://github.com/enterprise"
  }, "GitHub Enterprise"), "? If so, please have an admin ", ___EmotionJSX("a", {
    href: `mailto:${BRAND.SUPPORT_EMAIL}`
  }, "contact our support team"), "."), isReady ? ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space6"
  }, !isInstalled ? ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space1"
  }, ___EmotionJSX(Layout.SectionTitle, null, "GitHub Accounts"), ___EmotionJSX(AddGithubAccount, {
    onAddNew: onAuthorize,
    onAddExisting: onAddExistingInstallation,
    installations: availableInstallations,
    label: isInstalled ? 'Add Another Account' : 'Add Account'
  })) : ___EmotionJSX(React.Fragment, null, !!githubEnterpriseInstallation && ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 0
  }, ___EmotionJSX(Layout.SectionTitle, null, "GitHub Enterprise Integration"), ___EmotionJSX(IntegrationToggle.Status, {
    subject: "A GitHub Enterprise integration",
    isEnabled: !githubEnterpriseInstallation.disabled
  }), ___EmotionJSX(IntegrationToggle.Toggle, {
    isReadOnly: !admin,
    isEnabled: !githubEnterpriseInstallation.disabled,
    onChange: async () => {}
  }), ___EmotionJSX(CopyWebhookUrl, {
    label: "GitHub Enterprise Payload URL",
    installation: githubEnterpriseInstallation
  })), ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, ___EmotionJSX(Layout.SectionTitle, null, `${githubEnterpriseInstallation ? 'Hosted ' : ''}GitHub Accounts`), ___EmotionJSX(Layout.HelpMessage, {
    noMargin: true
  }, "If you want to remove an account from ", BRAND.NAME, ", you will need to delete the installation from your account settings within GitHub. Doing so will unlink the account from all of your ", BRAND.NAME, ' ', "Workspaces."), ___EmotionJSX(GithubInstallationTable, null), ___EmotionJSX(AddGithubAccount, {
    onAddNew: onAuthorize,
    onAddExisting: onAddExistingInstallation,
    installations: availableInstallations,
    label: isInstalled ? 'Add Another Account' : 'Add Account'
  })), ___EmotionJSX(GitBranchFormatting, null), ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 0
  }, ___EmotionJSX(Layout.SectionTitle, null, "Github Event Handlers"), ___EmotionJSX(Layout.HelpMessage, {
    noMargin: true
  }, "If you already use Pull Requests and Branches in GitHub, you can streamline your process by letting your GitHub workflow drive your", ' ', ___EmotionJSX("a", {
    href: manageWorkflowUrl,
    onClick: navigateOnClick(manageWorkflowUrl)
  }, BRAND.NAME, " workflow"), "."), ___EmotionJSX(FixedSpacer, {
    size: "space3"
  }), ___EmotionJSX(EventHandlerToggle, {
    type: "github"
  })), ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, ___EmotionJSX(EventDefinitionsTable, {
    type: "github"
  }), ___EmotionJSX(CreateEventDefinition, {
    type: "github"
  })))) : ___EmotionJSX(Loading, null)));
}
GitHub.displayName = "GitHub";