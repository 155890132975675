import moment from 'moment';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DeadlineChip = _ref => {
  let {
    completedAt,
    deadline
  } = _ref;
  if (!deadline || Boolean(completedAt)) return null;
  const isOverdue = getIsOverdue(deadline);
  const isDueSoon = getIsDueSoon(deadline);
  const date = moment(deadline);
  const longDeadline = date.format(DATE_FORMAT.SHORT_DATE);
  const shortDeadline = date.format(DATE_FORMAT.SHORT_DATE_NO_YEAR);
  const tooltipText = isOverdue ? `This Story was due on ${longDeadline}.` : `This Story has a due date of ${longDeadline}.`;
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(ChipTooltip.Text, null, tooltipText)
  }, ___EmotionJSX(Chip, {
    kind: isOverdue ? 'redInverted' : isDueSoon ? 'orangeInverted' : 'default'
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(Chip.Icon, {
    icon: "Calendar",
    label: isOverdue ? 'Overdue deadline' : 'Deadline'
  })), shortDeadline));
};
DeadlineChip.displayName = "DeadlineChip";