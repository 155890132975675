import { ThemeProvider } from '@emotion/react';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { LOCAL_THEME } from '../theme';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DatePickerThemeProvider = _ref => {
  let {
    children
  } = _ref;
  const themeName = useThemeName();
  return ___EmotionJSX(ThemeProvider, {
    theme: LOCAL_THEME[themeName]
  }, children);
};
DatePickerThemeProvider.displayName = "DatePickerThemeProvider";