import { MutateStoryEpicDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryEpicSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoriesTableEpicFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicSelectFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { EpicSelectTarget } from 'components/gql/epics/EpicSelectTarget';
import { getSection } from 'components/gql/epics/utils/sections';
import { useFieldOptionsQuery } from 'components/gql/stories/table/fields/useFieldOptionsQuery';
import { useMutation } from 'gql';
import { createOptimisticStoryMutationResponse } from '../../../utils/mutation';
import { useMutationContext } from './FieldContextProvider';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicSelectFragment = EpicSelectFragmentFragmentDoc;
export const StoriesTableEpicFragment = StoriesTableEpicFragmentFragmentDoc;
const QUERY_STORY_EPIC_SELECT = QueryStoryEpicSelectDocument;
const MUTATE_EPIC_FIELD = MutateStoryEpicDocument;
const EpicFieldComponent = _ref => {
  let {
    entity: {
      id: storyId,
      epic
    }
  } = _ref;
  const mutationContext = useMutationContext();
  const fetchEpics = useFieldOptionsQuery({
    query: QUERY_STORY_EPIC_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const [save] = useMutation(MUTATE_EPIC_FIELD);
  const handleChange = useCallback(epic => {
    save({
      variables: {
        storyId,
        input: {
          epic: epic
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        epic: epic ? {
          __typename: 'Epic',
          id: epic
        } : null
      }),
      ...mutationContext
    });
  }, [save, storyId, mutationContext]);
  return ___EmotionJSX(GroupedOptionsFieldSingleSelection, {
    selectedEntity: epic,
    fetchOptions: fetchEpics,
    getSection: getSection,
    TargetComponent: EpicSelectTarget,
    onChange: handleChange,
    unit: Nouns.Epic
  });
};
EpicFieldComponent.displayName = "EpicFieldComponent";
export const EpicField = () => ({
  name: 'epic',
  displayName: 'Epic',
  sort: 'epics',
  Component: EpicFieldComponent,
  width: 200
});