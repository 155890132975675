import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/sentry_logo.png';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast } from '@clubhouse/shared/components/Toast';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { ApplicationIds, fetchByApplicationId, getInstallation, useInstallationByApplicationId } from 'data/entity/installation';
import { fetchAll, updateSentryProject, useSentryProjects } from 'data/entity/sentryProject';
import { isLoggedInUserAdminOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { FieldValueSelect } from './components/FieldValueSelect';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { ProjectSelect } from './components/ProjectSelect';
import { RequesterSelect } from './components/RequesterSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
function useFetchSentryData() {
  const [isReady, toggleIsReady] = useToggleState();
  useEffect(() => {
    fetchByApplicationId(ApplicationIds.Sentry).then(sentry => {
      if (sentry && !sentry.disabled) {
        return fetchAll();
      }
    }).finally(toggleIsReady.on);
  }, [toggleIsReady]);
  return isReady;
}
const getExternalLink = () => {
  const link = 'https://sentry.io/settings/';
  const sentryOrgSlug = getInstallation(ApplicationIds.Sentry)?.sentry_organization_slug;
  return sentryOrgSlug ? link + sentryOrgSlug + '/integrations/' : link;
};
const saveSentryProjectChange = (slug, changes) => {
  return updateSentryProject(slug, changes).then(() => {
    addToast({
      kind: 'success',
      timeout: 5000,
      Content: () => ___EmotionJSX(ToastText, null, "Sentry project settings updated.")
    });
  }).catch(err => {
    addToast({
      kind: 'alert',
      timeout: 10000,
      Content: () => ___EmotionJSX(Spaced, {
        vertically: true,
        amount: "space1"
      }, ___EmotionJSX(ToastTitle, null, "Unable to update Sentry project settings."), err?.message ? ___EmotionJSX(ToastText, null, err.message) : null)
    });
  });
};
export function Sentry() {
  const isAdmin = isLoggedInUserAdminOnly();
  const isReady = useFetchSentryData();
  const installation = useInstallationByApplicationId(ApplicationIds.Sentry);
  const sentryProjects = useSentryProjects();
  const isEnabled = installation?.disabled === false;
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.SENTRY
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Sentry"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Sentry Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://sentry.io/"
  }, "Sentry"), " provides open-source error tracking that helps developers monitor and fix crashes in real time."), ___EmotionJSX(Layout.SectionTitle, null, "Integration Status"), !isReady ? ___EmotionJSX(IntegrationToggle.Loading, null) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IntegrationToggle, {
    isEnabled: isEnabled
  }), ___EmotionJSX(Layout.Blurb, null, "To ", isEnabled ? 'disable' : 'enable', " this integration, go to your", ' ', ___EmotionJSX(ExternalLink, {
    href: getExternalLink()
  }, "Sentry account"), ". You can also visit our", ' ', ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_SENTRY
  }, "Help Center"), " for more information."), !isAdmin && ___EmotionJSX(Layout.HelpMessage, null, "Only admins can manage this integration."), !!sentryProjects?.length && ___EmotionJSX("div", null, ___EmotionJSX("table", {
    className: "table",
    style: {
      border: 'none'
    },
    cellPadding: 0,
    cellSpacing: 0
  }, ___EmotionJSX("thead", null, ___EmotionJSX("tr", null, ___EmotionJSX("th", null, "Sentry Project"), isProjectsFeatureEnabled() && ___EmotionJSX("th", null, BRAND.NAME, " Project", ' ', ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX(Spaced, {
      vertically: true,
      amount: "space2"
    }, ___EmotionJSX("div", null, "Set the default ", BRAND.NAME, " Project for Stories created from the Sentry Project."), ___EmotionJSX("div", null, "This field is optional."))
  }, ___EmotionJSX("span", {
    className: "fa fa-question-circle-o"
  }))), areCustomFieldsEnabled() && ___EmotionJSX("th", null, "Field Value", ' ', ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX(Spaced, {
      vertically: true,
      amount: "space2"
    }, ___EmotionJSX("div", null, "Set the default Field and Value for Stories created from the Sentry Project."), ___EmotionJSX("div", null, "This field is optional."))
  }, ___EmotionJSX("span", {
    className: "fa fa-question-circle-o"
  }))), ___EmotionJSX("th", null, "Requester", ' ', ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX(Spaced, {
      vertically: true,
      amount: "space2"
    }, ___EmotionJSX("div", null, "Set the default Requester for Stories created from the Sentry Project."), ___EmotionJSX("div", null, "This field is optional."))
  }, ___EmotionJSX("span", {
    className: "fa fa-question-circle-o"
  }))))), ___EmotionJSX("tbody", null, sentryProjects.map(_ref => {
    let {
      sentry_project_slug,
      project,
      requester,
      fieldValue,
      field_id,
      value_id
    } = _ref;
    return ___EmotionJSX("tr", {
      key: sentry_project_slug
    }, ___EmotionJSX("td", null, sentry_project_slug), isProjectsFeatureEnabled() && ___EmotionJSX("td", null, ___EmotionJSX(ProjectSelect, {
      isDisabled: !isAdmin,
      project: project ?? null,
      onChange: selectedValue => {
        if (!selectedValue && !project) return;
        saveSentryProjectChange(sentry_project_slug, {
          project_id: selectedValue
        });
      }
    })), areCustomFieldsEnabled() && ___EmotionJSX("td", null, ___EmotionJSX(FieldValueSelect, {
      isDisabled: !isAdmin,
      label: fieldValue?.value,
      selectedValue: fieldValue ? `${field_id}:${value_id}` : undefined,
      onChange: _ref2 => {
        let [selectedValue] = _ref2;
        const isNoneValue = selectedValue === 'none';
        if (isNoneValue && !fieldValue) return;
        const [field_id = null, value_id = null] = isNoneValue ? [] : selectedValue.split(':');
        saveSentryProjectChange(sentry_project_slug, {
          field_id,
          value_id
        });
      }
    })), ___EmotionJSX("td", null, ___EmotionJSX(RequesterSelect, {
      isDisabled: !isAdmin,
      label: requester?.name,
      selectedValue: requester?.id,
      onChange: _ref3 => {
        let [selectedValue] = _ref3;
        if (!selectedValue && !requester) return;
        saveSentryProjectChange(sentry_project_slug, {
          permission_id: selectedValue ?? null
        });
      }
    })));
  })))))));
}
Sentry.displayName = "Sentry";