import { Content } from './components/Content';
import { Header } from './components/Header';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Section = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, children);
};
Section.Content = Content;
Section.Header = Header;