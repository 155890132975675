import { Icon } from '@useshortcut/shapes-ds';
import { FIELD_ID } from 'components/shared/table/types';
import { position } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
const HeaderComponent = () => ___EmotionJSX(Icon, {
  icon: "Handle",
  fill: "currentColor"
});
HeaderComponent.displayName = "HeaderComponent";
export const PriorityField = function () {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: FIELD_ID.PRIORITY,
    displayName: 'Priority',
    sort: position,
    HeaderComponent,
    width: 50,
    maxWidth: 50,
    headerProps: {
      centered: true
    },
    frozen: {
      left: 0
    },
    ...overrides
  };
};