import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { DEFAULT_ROW_HEIGHT } from '@clubhouse/shared/components/Table/config';
import { CellWrapper } from '@clubhouse/shared/components/Table/internalComponents/CellWrapper';
import { Row } from '@clubhouse/shared/components/Table/internalComponents/Row';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DefaultSkeletonRectangle = () => ___EmotionJSX(SkeletonRectangle, {
  width: "90%"
});
DefaultSkeletonRectangle.displayName = "DefaultSkeletonRectangle";
export const LoadingTableRow = _ref => {
  let {
    columns
  } = _ref;
  return ___EmotionJSX(Row, {
    minHeight: DEFAULT_ROW_HEIGHT,
    isSelected: false,
    role: "row"
  }, columns.map(_ref2 => {
    let {
      name,
      width,
      minWidth,
      maxWidth,
      LoadingComponent = DefaultSkeletonRectangle
    } = _ref2;
    return ___EmotionJSX(CellWrapper, {
      key: name,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth
    }, LoadingComponent ? ___EmotionJSX(LoadingComponent, null) : null);
  }));
};
LoadingTableRow.displayName = "LoadingTableRow";
export const createLoadingRows = count => new Array(count).fill(null).map((_, i) => ({
  id: `loading-${i}`
}));
const LOADING_ROWS = createLoadingRows(3);
export const LOADING_STATE = {
  RowComponent: LoadingTableRow,
  items: LOADING_ROWS,
  allItems: LOADING_ROWS
};