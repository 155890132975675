import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ICON_BY_STORY_TYPE = {
  feature: 'Feature',
  bug: 'Bug',
  chore: 'Chore'
};
const ICON_COLOR_BY_STORY_TYPE = {
  feature: 'var(--iconYellowColor)',
  bug: 'var(--shapes-errorMain)',
  chore: 'var(--shapes-iconMain)'
};
export const StoryTypeIcon = _ref => {
  let {
    storyType,
    size
  } = _ref;
  return ___EmotionJSX(SizedIcon, {
    customFill: ICON_COLOR_BY_STORY_TYPE[storyType],
    size: size,
    icon: ICON_BY_STORY_TYPE[storyType]
  });
};
StoryTypeIcon.displayName = "StoryTypeIcon";