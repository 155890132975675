import "core-js/modules/es.array.push.js";
import { KANBAN_DRAG_TYPE } from '@clubhouse/shared/constants';
import * as DropOverlayTemplate from 'app/client/core/views/templates/dropOverlay.html';
import * as FileDetailsTemplate from 'app/client/core/views/templates/fileDetails.html';
import AddNewStoryController from './addNewStory';
import DropdownController from './dropdown';
import MessageController from './message';
import PanelController from './panel';
import StoryDialogController from './storyDialog';
import EditOrganizationController from '../../../settingsShared/js/controllers/editOrganization';
import EditProfileController from '../../../settingsShared/js/controllers/editProfile';
import ManageLinkedAccountsController from '../../../settingsShared/js/controllers/manageLinkedAccounts';
import ManageLoadingIconsController from '../../../settingsShared/js/controllers/manageLoadingIcons';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import FileModel from '../models/file';
import IconModel from '../models/icon';
import LinkedFileModel from '../models/linkedFile';
import StoryModel from '../models/story';
import UploaderModel from '../models/uploader';
import UserModel from '../models/user';
import Asset from '../modules/asset';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Constants from '../modules/constants';
import Dom from '../modules/dom';
import Files from '../modules/files';
import Format from '../modules/format';
import Is from '../modules/is';
import Log from '../modules/log';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
const exports = {};
const NS = '.Uploader'; // Event namespace.
const DROP_TARGET_SELECTOR = '.is-drop-target';
const FILE_UPLOAD_PANEL_ID = 'file-updater';
const FILE_UPLOAD_PANEL_SELECTOR = '#panel-' + FILE_UPLOAD_PANEL_ID;
const CROPPER_SELECTOR = '#image-cropper';
const CROPPER_IMAGE_ID = 'image-selection';
const FILE_UPLOAD_XHR_TIMEOUT = 4 * 60 * 1000;
let cropStarted = false;
exports.initLibraries = () => {
  exports.initBox();
  exports.initDropbox();
  exports.initGooglePicker();
};
exports.initBox = () => {
  if (window.BoxSelect) {
    return false;
  }
  const ID = 'boxjs';
  $('#' + ID).remove();
  Dom.loadScript('https://app.box.com/js/static/select.js', {
    attributes: {
      id: ID
    }
  });
};
exports.initDropbox = () => {
  if (window.Dropbox && window.Dropbox.choose) {
    return false;
  }
  const ID = 'dropboxjs';
  $('#' + ID).remove();
  Dom.loadScript('https://www.dropbox.com/static/api/2/dropins.js', {
    attributes: {
      id: ID,
      'data-app-key': '8wsdumkjbkba2ae'
    }
  });
};
exports.initGooglePicker = () => {
  if (window.gapi && window.google && window.google.picker) {
    return false;
  }
  const PICKER_ID = 'googlepicker';
  const AUTH_ID = 'googleauth';

  // This needs to be Global :(
  window.loadGooglePicker = () => {
    window.gapi.load('picker');
  };

  // authorization via GSI
  $('#' + AUTH_ID).remove();
  Dom.loadScript('https://accounts.google.com/gsi/client', {
    attributes: {
      id: AUTH_ID
    }
  });
  $('#' + PICKER_ID).remove();
  Dom.loadScript('https://apis.google.com/js/api.js?onload=loadGooglePicker', {
    attributes: {
      id: PICKER_ID
    }
  });
};
exports.initCropper = function (callback) {
  const lib = Async.loadScriptAndWaitFor.bind(this, Asset.getPrefixedPath('/lib/cropper.min.js'), () => {
    return !!$.fn.cropper;
  });
  Async.eachInParallelThen([lib], callback);
};
exports.init = () => {
  Event.onlyOn('uploadError' + NS, err => {
    MessageController.error(err, {
      secondary: 'Unable to upload file.'
    });
  });
  Event.onlyOn('iconUploadResponse' + NS, response => {
    const icons = response.files;
    const type = IconModel.getTypeFromTarget(response.context);
    if (type === IconModel.TYPES.LOADING) {
      ManageLoadingIconsController.save(icons);
    } else if (type === IconModel.TYPES.ORGANIZATION) {
      EditOrganizationController.saveIcon(icons);
    } else if (type === IconModel.TYPES.USER) {
      EditProfileController.saveIcon(icons);
    }
  });
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const body = $('body');

  // The dragstart event doesn't work with files dragged into the window!
  // body.on('dragstart', function (e) { });

  body.off('dragenter' + NS).on('dragenter' + NS, e => {
    if (isDraggingStoryCard(e)) return;
    if (exports.isDraggingFile(e)) {
      const target = exports.getDropTarget(e.target);
      if (target.is('[data-drop-target]')) return;
      exports.displayOverlay(target);
    }
    return false;
  });
  body.off('dragover' + NS).on('dragover' + NS, e => {
    if (isDraggingStoryCard(e)) return;
    const cleanup = () => {
      _.defer(() => {
        Globals.set('isDraggingFile', false);
        $(DROP_TARGET_SELECTOR).removeClass('show-drop-target');
        exports.cleanupAllOverlays();
        Event.off('mouseMoved' + NS);
      });
    };
    const dropTarget = exports.getDropTarget(e.target);
    if (dropTarget.is('[data-drop-target]')) return cleanup();
    if (e.originalEvent) e = e.originalEvent;

    // Fix for SC-206657 - return and don't set dropEffect manually to 'copy' if inside a Doc
    // Long term we should remove all global event listeners
    if (document.querySelector('#doc_main')?.contains(e.target)) return;
    if (!e.dataTransfer) return;
    if (!Globals.get('isDraggingFile')) {
      Globals.set('isDraggingFile', true);
      $(DROP_TARGET_SELECTOR).addClass('show-drop-target');
      Event.onlyOn('mouseMoved' + NS, e => {
        if (!e.originalEvent.dataTransfer && !_isFileUploadDialogOpen()) {
          cleanup();
        }
      });
    }
    const effect = e.dataTransfer.effectAllowed;
    e.dataTransfer.dropEffect = 'move' === effect || 'linkMove' === effect ? 'move' : 'copy';
    return false;
  });
  body.off('drop' + NS).on('drop' + NS, e => {
    if (isDraggingStoryCard(e)) return;
    const target = exports.getDropTarget(e.target);
    if (target.is('[data-drop-target]')) return;
    if (e.originalEvent.dataTransfer) {
      const files = e.originalEvent.dataTransfer.files;
      exports.onDrop(target, files, e.shiftKey);
      return false;
    }
  });
  $(window).off('paste' + NS).on('paste' + NS, e => {
    if (exports.canPasteInContext() && e.originalEvent.clipboardData && e.originalEvent.clipboardData.items) {
      const clipboardData = e.originalEvent.clipboardData;
      const target = exports.isTextareaContext() ? exports.getActiveSupportedTextarea() : $(DROP_TARGET_SELECTOR).first();
      const matchType = /image.*/;
      let found = false;
      const types = clipboardData.types || [];
      types.forEach((type, i) => {
        if (found) {
          return;
        }
        if (type.match(matchType) || clipboardData.items[i].type.match(matchType)) {
          const file = clipboardData.items[i].getAsFile();
          if (!file) {
            return;
          }
          const formattedDate = moment().format('YYYY-DD-MM [at] h.mm.ss A');
          const filename = 'Clipboard ' + formattedDate + Files.getExtensionFromMimeType(file.type);
          exports.displayOverlay(target);
          exports.onDrop(target, [new window.File([file], filename, file)]);
          found = true;
        }
      });
      if (found && exports.isTextareaContext()) {
        return false;
      }
    }
  });
  Event.onlyOn('uploadResponse' + NS, response => {
    // This is a manual application of the context property, and not the one removed from jQuery
    const element = response.context;
    const entity = Utils.getModelFromContext(element);
    response.files.forEach(file => {
      FileModel.updateIfValid(file);
    });
    if (element.hasClass('comment')) {
      element.find('.add-comment').click();
      setTimeout(() => {
        const textarea = element.find('textarea');
        Utils.insertAtCursor(textarea, Files.mapFilesToMarkdown(response.files));
      }, 0);
    } else if (element.hasClass('add-comment')) {
      element.click();
      setTimeout(() => {
        const textarea = element.next().find('textarea');
        Utils.insertAtCursor(textarea, Files.mapFilesToMarkdown(response.files));
      }, 0);
    } else if (element.hasClass('in-place-textarea')) {
      const textarea = element.find('textarea');
      Utils.insertAtCursor(textarea, Files.mapFilesToMarkdown(response.files));
      textarea.trigger('input.textareaAutosize');
    } else if (StoryModel.isValid(entity) && !StoryModel.isNew(entity)) {
      StoryModel.attachFilesToStory(entity, response.files);
    } else if (element.hasClass('add-new-story')) {
      AddNewStoryController.attachFilesToStory(response.files);
    }
  });
};
exports.uploadFiles = function () {
  const target = exports.getDropTarget(this);
  if (target.is('[data-drop-target]')) return;
  const files = this.files;
  exports.displayOverlay(target);
  exports.onDrop(target, files);
  return false;
};
exports.getActiveSupportedTextarea = () => {
  return $(document.activeElement).closest(DROP_TARGET_SELECTOR);
};
exports.canPasteInContext = () => {
  return exports.isClipBoardUploadSupported() && (exports.isStoryContext() || exports.isTextareaContext());
};
exports.isStoryContext = () => {
  return StoryDialogController.isOpen() && !Utils.isFocusedInFormElement();
};
exports.isTextareaContext = () => {
  return Utils.isFocusedInTextarea() && exports.getActiveSupportedTextarea().length;
};
exports.isClipBoardUploadSupported = () => {
  // The constructor try/catch needed for Safari
  let error;
  try {
    new File([{}], 'test', {});
  } catch (e) {
    error = true;
  }
  return !error;
};
exports.validateFiles = (target, files, callback) => {
  const width = Utils.data(target, 'validate-file-width');
  const height = Utils.data(target, 'validate-file-height');
  const message = Utils.data(target, 'validate-file-error') || 'File upload failed';
  const validated = [];
  const failed = [];
  if (width || height) {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = fileDetails => {
        const image = new Image();
        image.src = fileDetails.target.result;
        image.onload = function () {
          let passed = true;
          if (width) {
            passed = this.width === width;
          }
          if (height) {
            passed = this.height === height;
          }
          if (passed) {
            validated.push(file);
          } else {
            failed.push(file);
          }
          if (validated.length + failed.length === files.length) {
            callback(target, validated);
            if (failed.length) {
              MessageController.error(message, {
                secondary: 'Unable to upload file.'
              });
            }
          } else {
            // TODO: Is it possible to end up here? cc: @adrian
            Log.error(new Error('Validated and failed arrays do not add up to files!'), {
              validatedLength: validated.length,
              failedLength: failed.length,
              filesLength: files.length
            });
          }
        };
      };
      reader.readAsDataURL(file);
    });
  } else {
    callback(target, files);
  }
};
function _isFileUploadDialogOpen() {
  return !!$('.file-upload').length;
}
function _isWithinMarkdownComponent(element) {
  return element.is('.comment, .add-comment, .in-place-textarea');
}
exports.updateFilesDetails = function (target, uploaderEntities, callback) {
  let runs = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  const file = _.find(uploaderEntities, {
    updated: false
  });
  const isIcon = IconModel.targetIsIcon(target);
  const isImage = Files.isImage(file.name);
  const isStillImage = Files.isStillImage(file.name);
  const aspectRatio = Number.parseInt(Utils.data(target, 'aspect-ratio'), 10);
  const width = Utils.data(target, 'width');
  const height = Utils.data(target, 'height');
  const note = Utils.data(target, 'uploader-note');
  const fileDetailsData = _.assign({}, file, {
    isIcon,
    isImage,
    isStillImage,
    note,
    count: uploaderEntities.length,
    index: runs
  });
  PanelController.open({
    id: FILE_UPLOAD_PANEL_ID,
    center: true,
    css: {
      'z-index': 2001,
      'max-height': '100%',
      'overflow-y': 'auto',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    html: FileDetailsTemplate.render(fileDetailsData),
    onOpen: panel => {
      panel.overlay.css({
        'z-index': 2000
      });
      Validator.init(FILE_UPLOAD_PANEL_SELECTOR);
      if (isStillImage) {
        exports.initCropper(() => {
          const cropper = $(CROPPER_SELECTOR);
          const cropperFileReader = new FileReader();
          const image = new Image();
          cropperFileReader.onload = () => {
            const options = {
              autoCropArea: 1,
              ready: () => {
                $(CROPPER_SELECTOR).find('#' + CROPPER_IMAGE_ID).map(function () {
                  this.addEventListener('cropstart', () => {
                    cropStarted = true;
                  });
                });
              }
            };
            if (aspectRatio) {
              options.aspectRatio = aspectRatio;
            }
            image.id = CROPPER_IMAGE_ID;
            image.src = cropperFileReader.result;
            Utils.data(image, 'width', width);
            Utils.data(image, 'height', height);
            cropper.html(image);
            cropper.find('#' + CROPPER_IMAGE_ID).cropper(options);
          };
          cropperFileReader.readAsDataURL(file.raw);
        });
      } else if (isImage) {
        const preview = $('#image-preview');
        const previewFileReader = new FileReader();
        const previewImage = new Image();
        previewFileReader.onload = () => {
          previewImage.src = previewFileReader.result;
          preview.html(previewImage);
        };
        previewFileReader.readAsDataURL(file.raw);
      }
    },
    onClose: () => {
      Validator.destroy(FILE_UPLOAD_PANEL_SELECTOR);
      UploaderModel.removeIfNotUpdated(file);
      const updatedFiles = UploaderModel.getRefreshedList(uploaderEntities);
      if (isStillImage) {
        exports.initCropper(() => {
          $(CROPPER_SELECTOR).find('#' + CROPPER_IMAGE_ID).cropper('destroy');
        });
      }
      if (_.some(updatedFiles, {
        updated: false
      })) {
        setTimeout(() => {
          exports.updateFilesDetails(target, updatedFiles, callback, runs + 1);
        }, 0);
      } else {
        callback(target, updatedFiles);
      }
    },
    target,
    width: 440
  });
};
exports.saveFileDetails = function () {
  if (Validator.isValid(FILE_UPLOAD_PANEL_SELECTOR)) {
    const file = Utils.getModelFromContext(this);
    const data = Utils.formData(FILE_UPLOAD_PANEL_SELECTOR) || {};
    const target = $(CROPPER_SELECTOR).find('#' + CROPPER_IMAGE_ID);
    if (Files.isStillImage(file.name) && target.length === 1) {
      const width = Number.parseInt(Utils.data(target, 'width'), 10);
      const height = Number.parseInt(Utils.data(target, 'height'), 10);
      if (cropStarted || width && target[0].width !== width || height && target[0].height !== height) {
        Log.log('Cropping image');
        cropStarted = false;
        const options = {
          imageSmoothingEnabled: false,
          imageSmoothingQuality: 'high'
        };
        if (width) {
          options.width = width;
        }
        if (height) {
          options.height = height;
        }
        const cropper = target.data('cropper');
        cropper.getCroppedCanvas(options).toBlob(blob => {
          file.raw = blob;
          UploaderModel.updateFile(file, _.assign(data, {
            updated: true
          }));
          exports.closeFileUploader();
        });
        return false;
      }
    }
    Log.log('Updating file without cropping');
    UploaderModel.updateFile(file, _.assign(data, {
      updated: true
    }));
    exports.closeFileUploader();
  }
  return false;
};
exports.closeFileUploader = () => {
  $('.html-file-upload').val('');
  PanelController.closeById(FILE_UPLOAD_PANEL_ID);
  return false;
};
exports.onDrop = (target, rawFiles, editFile) => {
  Globals.set('isDraggingFile', false);
  $(DROP_TARGET_SELECTOR).removeClass('show-drop-target');

  // pass in entity (file/loading icon/display icon) for template type
  const fileEntities = UploaderModel.mapNewFilesToEntities(rawFiles);
  const callback = (target, files) => {
    if (target.length > 0) {
      const isIcon = IconModel.targetIsIcon(target);
      const options = {
        files,
        target
      };
      if (isIcon) {
        options.url = '/api/private/' + IconModel.getEntityFromTarget(target) + '/icons';
        options.successEvent = 'iconUploadResponse';
      }
      target.addClass('is-uploading');
      target.find('.drop-overlay .while-uploading').show();
      Globals.set('isDraggingFile', false);
      exports.upload(options);
    } else if (files.length > 0) {
      Log.error(new Error('Drop target no longer exists!'), {
        filesLength: files.length
      });
    }
  };
  if (editFile && !_isWithinMarkdownComponent(target)) {
    exports.updateFilesDetails(target, fileEntities, callback);
  } else {
    callback(target, fileEntities);
  }
};

// Ref: http://stackoverflow.com/a/8494918/440094
// Ref: https://msdn.microsoft.com/en-us/library/hh772719(v=vs.85).aspx
// Ref: https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer#types.28.29
exports.isDraggingFile = e => {
  const CROSS_BROWSER_VALUE = 'Files';
  const OLD_FIREFOX_VALUE = 'application/x-moz-file';
  const dt = e.originalEvent.dataTransfer || e.originalEvent.clipboardData;
  return !!(dt && dt.types && (_.includes(dt.types, CROSS_BROWSER_VALUE) || _.includes(dt.types, OLD_FIREFOX_VALUE)));
};
exports.displayOverlay = target => {
  exports.cleanupOtherOverlays(target);
  if (target.length > 0 && !target.hasClass('is-uploading')) {
    const html = DropOverlayTemplate.render({
      title: exports.parseTitle(target),
      showModalTip: !_isWithinMarkdownComponent(target)
    });
    target.removeClass('drop-target-hover');
    if (target.find('.drop-overlay').length === 0) {
      target.append(html);
    }
    target.find('.drop-overlay .while-uploading').hide();
    target.addClass('drop-target-hover');
    const contentHeight = target.find('.drop-content').height();
    const margin = 8;
    let container = target;
    let offset = 0;
    if (target.hasClass('story-details')) {
      container = target.closest('.modal-dialog');
      offset = container.find('.content').scrollTop();
    }
    const top = container.height() / 2 - contentHeight / 2 - margin + offset;
    target.find('.drop-overlay .drop-content').css({
      top: Math.max(margin, top)
    });
  }
};
exports.cleanupOtherOverlays = target => {
  $('.drop-target-hover').each(function () {
    const me = $(this);
    if (!(this === target[0] || me.hasClass('is-uploading'))) {
      exports.destroy(me);
    }
  });
};
exports.cleanupAllOverlays = () => {
  $('.drop-target-hover').each(function () {
    const context = $(this);
    if (!context.hasClass('is-uploading')) {
      exports.destroy(context);
    }
  });
};
exports.cancelUpload = function () {
  const target = exports.getDropTarget(this);
  if (target.is('[data-drop-target]')) return;
  const xhr = target[0].uploadRequest;
  if (xhr) {
    xhr.abort();
  }
  exports.destroy(target);
  return false;
};
exports.getDropTarget = context => {
  const $target = $(context).closest(`[data-drop-target], ${DROP_TARGET_SELECTOR}`);
  return $target;
};
exports.parseTitle = target => {
  const title = target.data('upload-title') || 'Attach files';
  const titleFn = Utils.strToFunction(title);
  return _.isFunction(titleFn) ? titleFn.call(target) : title;
};
exports.createTitleFromContext = function () {
  if ($(this).closest('.comment, .comment-thread').length > 0) {
    return 'Attach files to comment';
  } else if ($(this).closest('.description-container').length > 0) {
    return 'Attach files to description';
  } else {
    return 'Attach files';
  }
};
exports.uploadIcon = options => {
  return exports.upload({
    ...options,
    url: '/api/private/workspace2/icons'
  });
};
exports.upload = options => {
  const {
    onSuccess = () => {},
    onProgress = () => {},
    onError = () => {}
  } = options;
  options.target = $(options.target);
  const url = options.url || '/api/private/files';
  const successEvent = options.successEvent || 'uploadResponse';
  const startTime = Date.now();
  let validCount = 0;
  const limit = Constants.UPLOAD_LIMIT_IN_MB * 1000 * 1000;
  const data = new FormData();
  options.files.forEach((file, i) => {
    if (_.get(file, 'raw.size') > limit) {
      MessageController.error('The size of <em>' + Format.sanitize(file.name) + '</em> exceeds the current limit of ' + Constants.UPLOAD_LIMIT_IN_MB + 'MB.', {
        secondary: 'Unable to upload file.'
      });
    } else {
      validCount++;
      data.append('files[' + i + ']', file.blob || file.raw, file.name);
    }
  });
  if (validCount === 0) {
    exports.destroy(options.target);
    return false;
  }
  options.target.find('.drop-overlay .note').remove();
  options.target[0].uploadRequest = Backend.post(url, {
    data,
    timeout: FILE_UPLOAD_XHR_TIMEOUT,
    cache: false,
    contentType: false,
    processData: false,
    xhr: () => {
      const xhr = jQuery.ajaxSettings.xhr(); // options.target[0].uploadRequest;

      if (xhr.upload) {
        xhr.upload.addEventListener('progress', e => {
          if (e.lengthComputable) {
            const completed = Number.parseInt(100 * e.loaded / e.total, 10);
            let remaining = '???';
            const elapsed = Date.now() - startTime;
            if (completed > 2) {
              remaining = ((elapsed / (completed / 100) - elapsed) / 1000).toPrecision(2);
            }
            if (remaining > 99) {
              remaining = '???';
            }
            const caption = completed + '% | ' + remaining + ' seconds left | ' + Utils.toFilesize(e.loaded) + ' of ' + Utils.toFilesize(e.total);
            onProgress({
              caption,
              completed
            });
            options.target.find('.drop-overlay h2').text(caption);
            options.target.find('.progressbar .bar').width(completed + '%');
          }
        }, false);
      } else {
        Log.log('xhr.upload not found!');
      }
      return xhr;
    },
    onError: (res, xhr) => {
      Event.trigger('uploadError', res.error);
      Log.log('An error occurred while uploading.', {
        type: 'upload',
        response: res,
        status: _.get(xhr, 'status'),
        level: 'error',
        fileSizes: _.map(options.files, 'raw.size')
      });
      options.target.val('');
      exports.destroy(options.target);
      onError({
        error: res.error
      });
    },
    onSuccess: res => {
      FileModel.updateMultipleFilesFromUploaderModel(res, files => {
        Event.trigger(successEvent, {
          files,
          context: options.target
        });
        options.target.val('');
        exports.destroy(options.target);
        onSuccess({
          files
        });
      });
    },
    onComplete: () => {
      $('.html-file-upload').val('');
      options.target.val('');
      exports.destroy(options.target);
    }
  });
  return options.target[0].uploadRequest;
};
exports.destroy = context => {
  context[0].uploadRequest = null;
  context.removeClass('is-uploading').removeClass('drop-target-hover');
  context.find('.drop-overlay').remove();
};
exports.uploadUsingBox = function () {
  const story = Utils.getModelFromContext(this);
  const options = StoryModel.isValid(story) || StoryModel.isNew(story) ? {
    story_id: story.id
  } : {};
  const boxSelect = new window.BoxSelect({
    clientId: '7z7dawz9qhtpn4t07fesnkv1jadwne9g',
    linkType: 'shared',
    multiselect: false
  });
  boxSelect.success(files => {
    LinkedFileModel.addBoxFile(files[0], options, exports.handleLinkedAttachmentResponse);
  });
  boxSelect.launchPopup();
};
exports.uploadUsingDropbox = function () {
  const story = Utils.getModelFromContext(this);
  const options = StoryModel.isValid(story) || StoryModel.isNew(story) ? {
    story_id: story.id
  } : {};
  window.Dropbox.choose({
    success: files => {
      LinkedFileModel.addDropboxFile(files[0], options, exports.handleLinkedAttachmentResponse);
    }
  });
};
exports.uploadUsingGoogle = function () {
  const story = Utils.getModelFromContext(this);
  const options = StoryModel.isValid(story) || StoryModel.isNew(story) ? {
    story_id: story.id
  } : {};
  exports.launchGooglePicker(options);
};
exports.launchGooglePicker = options => {
  if (ManageLinkedAccountsController.isGoogleTokenValid()) {
    const token = ManageLinkedAccountsController.getCurrentGoogleToken();
    const docsViewId = window.google.picker.ViewId.DOCS;
    const docsView = new window.google.picker.DocsView(docsViewId).setIncludeFolders(true);
    const docsTeamView = new window.google.picker.DocsView(docsViewId).setEnableTeamDrives(true).setIncludeFolders(true);
    const picker = new window.google.picker.PickerBuilder().enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES).addView(docsView).addView(docsTeamView).setOAuthToken(token).setCallback(save).build();
    picker.setVisible(true);
  } else {
    ManageLinkedAccountsController.requestNewGoogleToken({
      onComplete: err => {
        if (!err) {
          exports.launchGooglePicker(options);
        }
      }
    });
  }
  function save(data) {
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
      const file = data[window.google.picker.Response.DOCUMENTS][0];
      LinkedFileModel.addGoogleFile(file, options, exports.handleLinkedAttachmentResponse);
    }
  }
};
exports.attachURLFromForm = () => {
  const url = $('#url-attachment-path').val();
  const description = $('#url-attachment-desc').val();
  const storyID = $('#url-attachment-story-id').val();
  const options = storyID ? {
    story_id: storyID
  } : {};
  if (!url) {
    $('#url-attachment-path').focus();
    return false;
  }
  DropdownController.closeAll();
  if (url) {
    LinkedFileModel.addURLFile({
      path: url,
      description
    }, options, exports.handleLinkedAttachmentResponse);
  }
};
exports.handleLinkedAttachmentResponse = err => {
  if (err) {
    MessageController.error(err, {
      secondary: 'Unable to attach file.'
    });
  }
};
exports.submitURLFormOnEnter = function (e) {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    $(this).prop('readonly', true);
    exports.attachURLFromForm();
    return false;
  }
};
function isDraggingStoryCard(e) {
  return e.originalEvent?.dataTransfer?.types?.includes(KANBAN_DRAG_TYPE);
}
export { exports as default };