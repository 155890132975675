function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { Global, css } from '@emotion/react';
import { Button } from '../shared/DeprecatedButton';
import { GoogleIcon } from '../shared/GoogleIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
const styles = process.env.NODE_ENV === "production" ? {
  name: "v4aiyd",
  styles: "@import url('https://fonts.googleapis.com/css?family=Roboto:500');{}a.button.google-sign-in{font-family:'Roboto',sans-serif;font-weight:500;font-size:17px;letter-spacing:0.21px;padding:14px 0;}"
} : {
  name: "w93q1c-styles",
  styles: "@import url('https://fonts.googleapis.com/css?family=Roboto:500');{}a.button.google-sign-in{font-family:'Roboto',sans-serif;font-weight:500;font-size:17px;letter-spacing:0.21px;padding:14px 0;};label:styles;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3QvQGNsdWJob3VzZS93ZWIvY29tcG9uZW50cy9sb2dpbi9TaWduaW5XaXRoR29vZ2xlLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFNa0IiLCJmaWxlIjoiL2hvbWUvY2lyY2xlY2kvcHJvamVjdC9AY2x1YmhvdXNlL3dlYi9jb21wb25lbnRzL2xvZ2luL1NpZ25pbldpdGhHb29nbGUudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgR2xvYmFsLCBjc3MgfSBmcm9tICdAZW1vdGlvbi9yZWFjdCc7XG5pbXBvcnQgdHlwZSB7IE1vdXNlRXZlbnQgfSBmcm9tICdyZWFjdCc7XG5cbmltcG9ydCB7IEJ1dHRvbiB9IGZyb20gJy4uL3NoYXJlZC9EZXByZWNhdGVkQnV0dG9uJztcbmltcG9ydCB7IEdvb2dsZUljb24gfSBmcm9tICcuLi9zaGFyZWQvR29vZ2xlSWNvbic7XG5cbmNvbnN0IHN0eWxlcyA9IGNzc2BcbiAgQGltcG9ydCB1cmwoJ2h0dHBzOi8vZm9udHMuZ29vZ2xlYXBpcy5jb20vY3NzP2ZhbWlseT1Sb2JvdG86NTAwJyk7XG4gIGEuYnV0dG9uLmdvb2dsZS1zaWduLWluIHtcbiAgICBmb250LWZhbWlseTogJ1JvYm90bycsIHNhbnMtc2VyaWY7XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgICBmb250LXNpemU6IDE3cHg7XG4gICAgbGV0dGVyLXNwYWNpbmc6IDAuMjFweDtcbiAgICBwYWRkaW5nOiAxNHB4IDA7XG4gIH1cbmA7XG5cbmNvbnN0IG9uQ2xpY2tEZWZhdWx0ID0gKCkgPT4ge307XG5cbnR5cGUgU2lnbmluV2l0aEdvb2dsZVByb3BzID0ge1xuICBocmVmOiBzdHJpbmc7XG4gIHRleHQ/OiBzdHJpbmc7XG4gIG9uQ2xpY2s/OiAoZTogTW91c2VFdmVudCkgPT4gdm9pZDtcbn07XG5cbmV4cG9ydCBjb25zdCBTaWduaW5XaXRoR29vZ2xlID0gKHtcbiAgaHJlZixcbiAgdGV4dCA9ICdTaWduIGluIHdpdGggR29vZ2xlJyxcbiAgb25DbGljayA9IG9uQ2xpY2tEZWZhdWx0LFxufTogU2lnbmluV2l0aEdvb2dsZVByb3BzKSA9PiB7XG4gIHJldHVybiAoXG4gICAgPD5cbiAgICAgIDxHbG9iYWwgc3R5bGVzPXtzdHlsZXN9IC8+XG4gICAgICA8QnV0dG9uIGNsYXNzTmFtZT1cImdvb2dsZS1zaWduLWluXCIgdGV4dD17dGV4dH0gaHJlZj17aHJlZn0gSWNvbj17R29vZ2xlSWNvbn0gb25DbGljaz17b25DbGlja30gaGFzQXJyb3cgLz5cbiAgICA8Lz5cbiAgKTtcbn07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const onClickDefault = () => {};
export const SigninWithGoogle = _ref => {
  let {
    href,
    text = 'Sign in with Google',
    onClick = onClickDefault
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Global, {
    styles: styles
  }), ___EmotionJSX(Button, {
    className: "google-sign-in",
    text: text,
    href: href,
    Icon: GoogleIcon,
    onClick: onClick,
    hasArrow: true
  }));
};