import Globals from '../_frontloader/globals';
import Log from './log';
import Url from './url';
import MessageController from '../controllers/message';
const exports = {};
const ALERT_OFFLINE_ID = 'user-is-offline-alert';
const ALERT_ONLINE_ID = 'user-is-online-alert';
const EVENT_NS = '.OfflineModule';
exports.showAlert = () => {
  if (MessageController.hasToast(ALERT_OFFLINE_ID)) {
    return false;
  }
  const isWrite = Url.getCurrentPage() === 'write';
  const html = !isWrite ? `<strong style="display: block; margin: 0 0 3px;">Reconnecting...</strong>
    You will not be able to save changes until your connection is restored.` : `<strong style="display: block; margin: 0 0 3px;">You're Offline</strong>
    We’re trying to reconnect you now...`;
  if (isWrite) {
    MessageController.info(html, {
      id: ALERT_OFFLINE_ID,
      icon: 'fa-circle-o-notch fa-spin',
      timeout: 0
    });
  } else {
    MessageController.alert(html, {
      id: ALERT_OFFLINE_ID,
      icon: 'fa-circle-o-notch fa-spin',
      timeout: 0
    });
  }
};
exports.hideAlert = () => {
  if (MessageController.hasToast(ALERT_OFFLINE_ID)) {
    // Log.log('Offline alert removed.');
    MessageController.closeById(ALERT_OFFLINE_ID);
    MessageController.info('<strong>Connection restored.</strong>', {
      id: ALERT_ONLINE_ID,
      icon: 'fa-thumbs-o-up',
      timeout: 2500
    });
  }
};
exports.init = () => {
  const body = $('body');
  body.off('offline' + EVENT_NS).on('offline' + EVENT_NS, () => {
    Globals.set('UserIsOnline', false);
    Log.log('Offline event occurred.');
  });
  body.off('online' + EVENT_NS).on('online' + EVENT_NS, () => {
    Globals.set('UserIsOnline', true);
    Log.log('Online event occurred.');
  });
};
export { exports as default };