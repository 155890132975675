import Logo from '@clubhouse/assets/png/third-party-logos/notion_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { NotionIntegration } from 'components/settings/integrations/NotionIntegration';
import { isReadOnly } from 'data/entity/user';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Notion() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.NOTION
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Notion"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Notion Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(NotionIntegration, {
    readOnly: readOnly
  })));
}
Notion.displayName = "Notion";