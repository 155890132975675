import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/iterations/less/app.less';
import Async from '../../../core/js/modules/async';
import ApplicationState from '../../../core/js/modules/applicationState';
import Boot from '../../../core/js/modules/boot';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import IterationsPageController from '../controllers/iterationsPage';
import IterationPageController from '../controllers/iterationPage';
import IterationModel from '../../../core/js/models/iteration';
import Layout from '../../../core/js/modules/layout';
import StoriesPanelController from '../controllers/storiesPanel';
import Updates from '../../../core/js/modules/updates';
import Url from '../../../core/js/modules/url';
import IterationUtils from './iterationUtils';
import * as StoryData from 'data/entity/story';
import WorkflowModel from '../../../core/js/models/workflow';
const exports = {};
const init = () => {
  initializePage();
  if (IterationUtils.isViewingDetailPage()) {
    return IterationPageController.init();
  }
  IterationsPageController.init();
  Async.eachInSequenceThen([ConsolidatedFetch.fetchConsolidatedDataAndActivity], () => {
    _renderIfStillOnIterationsPage();
    _fetchStoriesForSidePanel();
  });
};
const reInit = () => {
  initializePage();
  if (IterationUtils.isViewingDetailPage()) {
    return IterationPageController.reInit();
  }
  IterationsPageController.init();
  _renderIfStillOnIterationsPage();
  _fetchStoriesForSidePanel();
};
const initializePage = () => {
  Layout.renderContent('<div id="iterations" class="page-content"></div>');
  Updates.init(handleUpdates);
  ApplicationState.setLastView();
};
const handleUpdates = updates => {
  if (updates === null) {
    return _renderIfStillOnIterationsPage();
  }
  const {
    updated_iterations
  } = updates;
  if (updated_iterations && (updated_iterations.modified.length || updated_iterations.deleted.length)) {
    if (IterationUtils.isViewingDetailPage()) {
      const id = IterationPageController.getIdFromUrl();
      IterationModel.fetch({
        id
      }).then(() => {
        _renderIfStillOnIterationsPage();
      });
    } else {
      _renderIfStillOnIterationsPage();
    }
  }
};
function _fetchStoriesForSidePanel() {
  (async () => {
    await StoryData.fetchRecentStoriesForWorkflows({
      workflowIds: WorkflowModel.all().map(w => w.id)
    });
    _renderStoriesPanelIfStillOnIterationsPage();
  })();
}
function _renderStoriesPanelIfStillOnIterationsPage() {
  if (Url.getCurrentPage() === 'iterations') {
    StoriesPanelController.render();
  }
}
function _renderIfStillOnIterationsPage() {
  if (Url.getCurrentPage() === 'iterations') {
    IterationsPageController.render();
  } else if (IterationUtils.isViewingDetailPage()) {
    IterationPageController.reRender();
  }
}
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(init, reInit);
};
export { exports as default };