import { useCallback } from 'react';
import { TableState } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import { getById as getColumnById, getTeamFromColumn } from 'data/entity/column';
import { saveChanges } from 'data/entity/story';
import { getActive as getActiveTeam } from 'data/entity/team';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { stateOrPosition } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StateFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const column = getColumnById(entity.workflow_state_id);
  const team = getTeamFromColumn(column) || getActiveTeam();
  const workflowForSelectedProject = getWorkflowById(team.workflow.id);
  const states = workflowForSelectedProject?.states ?? [];
  const handleChange = useCallback(update => {
    if (!update) return false;
    const {
      id
    } = update;
    const foundColumn = getColumnById(id);
    if (!foundColumn) {
      return false;
    }
    if (id === entity.workflow_state_id) {
      return false;
    }
    saveChanges(entity.id, {
      workflow_state_id: id
    });
  }, [entity.id, entity.workflow_state_id]);
  return ___EmotionJSX(TableState, {
    isSelectionDisabled: false,
    state: entity.stateObject,
    states: states,
    onChange: handleChange
  });
};
StateFieldComponent.displayName = "StateFieldComponent";
export const StateField = () => ({
  name: FIELD_ID.STATE,
  displayName: 'State',
  sort: stateOrPosition,
  Component: StateFieldComponent,
  width: 170
});