function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useCallback } from 'react';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import EpicModel from 'app/client/core/js/models/epic'; // eslint-disable-line import/no-restricted-paths
import { CommentInput } from 'components/shared/Comments/CommentInput';
import { useCommentNotificationContext } from 'components/shared/Comments/CommentNotifications';
import { Actions, Content, Item, Loading, Section } from 'components/shared/Comments/components';
import { MarkdownEditor } from 'components/shared/MarkdownEditor/MarkdownEditor';
import { getLoggedInUserPermissionID, isLoggedInUserAdmin } from 'data/entity/user';
import { error } from 'utils/message';
import { CommentReply, SimplifiedReply } from '../CommentReply';
import { AuthorAvatar, AuthorName, CommentContent, CommentDate, CommentReplies, Root } from './components';

// Public id until GQL is used
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicComment = Object.assign(Root, {
  AuthorAvatar,
  AuthorName,
  CommentDate,
  CommentContent,
  CommentReply,
  Section,
  Item,
  Actions,
  ContentLayout: Content,
  CommentReplies,
  Loading
});
export const SimplifiedEpicComment = _ref => {
  let {
    epicId,
    comment
  } = _ref;
  return ___EmotionJSX(Root, {
    id: epicId,
    comment: comment
  }, ___EmotionJSX(AuthorAvatar, null), ___EmotionJSX(Content, null, ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 0
  }, ___EmotionJSX(AuthorName, null), ___EmotionJSX(CommentDate, {
    isLinkable: false
  })), ___EmotionJSX(CommentContent, {
    renderEmbeds: false,
    className: "popover"
  }), ___EmotionJSX(SimplifiedReply, null), ___EmotionJSX(CommentReplies, null, c => ___EmotionJSX(SimplifiedEpicComment, {
    epicId: epicId,
    comment: c
  }))));
};
SimplifiedEpicComment.displayName = "SimplifiedEpicComment";
export const DefaultComment = _ref2 => {
  let {
    epicId,
    comment,
    isActive
  } = _ref2;
  const isEditable = !comment.deleted && comment.author_id === getLoggedInUserPermissionID();
  const isDeletable = !comment.deleted && (comment.author_id === getLoggedInUserPermissionID() || isLoggedInUserAdmin());
  const [isCollapsed, toggleCollapsed] = useToggleState();
  const [isEditing, toggleEditing] = useToggleState();
  const [isDeleting, toggleDeleting] = useToggleState();
  const {
    onCommentDeleted,
    onCommentUpdated
  } = useCommentNotificationContext();
  const deleteComment = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      toggleDeleting.on();
      EpicModel.deleteComment({
        id: epicId
      }, comment.id, () => {
        onCommentDeleted();
        toggleDeleting.off();
      });
    }
  }, [comment.id, epicId, onCommentDeleted, toggleDeleting]);
  const editOnShiftClick = useCallback(e => {
    if (e.shiftKey && isEditable) toggleEditing.on();
  }, [isEditable, toggleEditing]);
  const saveComment = useCallback(async value => {
    if (value) {
      EpicModel.updateComment({
        id: epicId
      }, comment.id, value, err => {
        if (err) {
          error(err, {
            secondary: 'Your comment has been restored.'
          });
        } else {
          onCommentUpdated();
          toggleEditing.off();
        }
      });
    } else {
      error('Your comment is empty!');
    }
  }, [comment.id, epicId, onCommentUpdated, toggleEditing]);
  return ___EmotionJSX(Root, {
    id: epicId,
    comment: comment,
    isActive: isActive
  }, ___EmotionJSX(AuthorAvatar, {
    size: AuthorAvatar.SIZE.M
  }), ___EmotionJSX(Content, null, ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space2",
    align: "center"
  }, ___EmotionJSX(AuthorName, {
    size: AuthorName.SIZE.MEDIUM
  }), ___EmotionJSX(CommentDate, {
    size: CommentDate.SIZE.XSMALL
  }), ___EmotionJSX(Actions, null, ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center"
  }, !!comment.comments?.length && ___EmotionJSX(Tooltip, {
    content: "Toggle Replies"
  }, ___EmotionJSX(UnstyledButton, {
    onClick: toggleCollapsed
  }, ___EmotionJSX(SizedIcon, {
    icon: isCollapsed ? 'Expand' : 'Collapse',
    size: 18,
    fill: "disabled"
  }))), isEditable && ___EmotionJSX(Tooltip, {
    content: "Edit comment"
  }, ___EmotionJSX(UnstyledButton, {
    onClick: toggleEditing.on
  }, ___EmotionJSX(SizedIcon, {
    icon: "Edit",
    size: 16,
    fill: "disabled"
  }))), isDeletable && ___EmotionJSX(Tooltip, {
    content: "Delete comment"
  }, ___EmotionJSX(UnstyledButton, {
    onClick: deleteComment
  }, ___EmotionJSX(SizedIcon, {
    icon: "Trash",
    size: 16,
    fill: "disabled"
  })))))), isEditing ? ___EmotionJSX(CommentInput, {
    snapshotKey: `epic.editEpicComment.${comment.id}`,
    onSave: saveComment,
    onCancel: toggleEditing.off
  }, _ref3 => {
    let {
      isSaving,
      onSave,
      onCancel,
      ...props
    } = _ref3;
    return ___EmotionJSX(MarkdownEditor, _extends({
      context: "Comment",
      isReadOnly: isSaving,
      initialValue: comment.text,
      onSave: onSave,
      onCancel: onCancel
    }, props));
  }) : ___EmotionJSX(CommentContent, {
    className: "epic-page",
    onClick: editOnShiftClick,
    isDeleting: isDeleting,
    onChange: newValue => new Promise((resolve, reject) => {
      EpicModel.updateComment({
        id: epicId
      }, comment.id, newValue, err => {
        if (err) {
          reject(err);
        } else {
          onCommentUpdated();
          resolve();
        }
      });
    })
  }), ___EmotionJSX(CommentReply, null)), !isCollapsed && ___EmotionJSX(CommentReplies, null, c => ___EmotionJSX(DefaultComment, {
    epicId: epicId,
    comment: c
  })));
};
DefaultComment.displayName = "DefaultComment";