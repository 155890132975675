import { TableText } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { totalStories } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TotalStoriesFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(TableText, {
    text: entity.totalStories
  });
};
TotalStoriesFieldComponent.displayName = "TotalStoriesFieldComponent";
export const TotalStoriesField = function () {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: FIELD_ID.TOTAL_STORIES,
    displayName: 'Stories',
    sort: totalStories,
    headerProps: {
      centered: true
    },
    Component: TotalStoriesFieldComponent,
    width: 60,
    ...overrides
  };
};