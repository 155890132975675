import { StoryCardInternal } from '../../internal';
import { BlockedChip, BlockerChip, CustomFieldChips, DeadlineChip, DocCountChip, DoneChip, EstimateChip, ExternalLinkChips, IterationChip, JiraSyncChip, LabelChips, ProjectChip, TaskChip, TypeChip } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Chips = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(StoryCardInternal.Chips, null, children);
};
Chips.displayName = "Chips";
Chips.BlockedChip = BlockedChip;
Chips.BlockerChip = BlockerChip;
Chips.CustomFieldChips = CustomFieldChips;
Chips.DeadlineChip = DeadlineChip;
Chips.DocCountChip = DocCountChip;
Chips.DoneChip = DoneChip;
Chips.EstimateChip = EstimateChip;
Chips.ExternalLinkChips = ExternalLinkChips;
Chips.IterationChip = IterationChip;
Chips.LabelChips = LabelChips;
Chips.ProjectChip = ProjectChip;
Chips.TaskChip = TaskChip;
Chips.TypeChip = TypeChip;
Chips.JiraSyncChip = JiraSyncChip;