import ReactDOM from 'react-dom';
import { ShapesProvider } from '@useshortcut/shapes-ds';
import { Badges } from './Badges';
import { ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { getMilestoneById, useMilestone } from 'data/entity/milestone';
import { milestoneToSearchProps } from '../../../searchResults/utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const MilestoneBadges = _ref => {
  let {
    id
  } = _ref;
  const milestone = useMilestone({
    id
  });
  const searchData = milestoneToSearchProps(milestone);
  return ___EmotionJSX(Badges, {
    data: searchData
  });
};
MilestoneBadges.displayName = "MilestoneBadges";
export const formatMilestone = (_ref2, themeName) => {
  let {
    id
  } = _ref2;
  const stateObject = getMilestoneById(id);
  let badges = null;
  try {
    badges = div => {
      ReactDOM.render(___EmotionJSX(ThemeContextProvider, {
        themeName: themeName
      }, ___EmotionJSX(ShapesProvider, {
        themeName: themeName
      }, ___EmotionJSX(MilestoneBadges, {
        id: id
      }))), div);
    };
  } catch (e) {}
  return {
    label: stateObject?.name ?? '',
    url: stateObject?.app_url,
    __extraHTML: badges
  };
};
export const formatMilestones = (milestones, themeName) => milestones.map(milestone => formatMilestone(milestone, themeName));