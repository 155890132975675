import NotionLogo from '@clubhouse/assets/png/third-party-logos/notion_104x104.png';
import { useSingleClick } from '@clubhouse/shared/hooks';
import { getLoggedInUserProfiles } from 'data/entity/user';
import { usePageSearch, useSearchParams } from 'utils/navigation';
import { allowNotionAuthorization, denyNotionAuthorization } from 'utils/notionAuthorization';
import { getPermissionsCopy } from 'utils/oauth';
import { IntegrationInterstitial } from './IntegrationInterstitial';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const NotionIntegrationInterstitial = () => {
  // We grab the query params from the URI to help validate and populate the UI
  const search = usePageSearch();
  const {
    params
  } = useSearchParams(['response_type', 'client_id', 'redirect_uri', 'scope', 'state', 'code']);
  const {
    response_type,
    client_id,
    redirect_uri,
    scope,
    state,
    code
  } = params;
  const paramsExist = params => params.every(param => param.length);

  // We need the redirect_uri in order to redirect if user denies the auth
  const canDeny = paramsExist([redirect_uri]);
  // We need all other uri params in order to allow, as the backend requires them to be there
  const canAllow = canDeny && paramsExist([response_type, client_id, scope, state, code]);
  const permissions = getPermissionsCopy(scope);
  const {
    fn: onAllow
  } = useSingleClick(currentWorkspace => {
    const params = search + '&workspace_slug=' + currentWorkspace;
    return allowNotionAuthorization(params);
  });
  const {
    fn: onDeny
  } = useSingleClick(() => denyNotionAuthorization(params.redirect_uri));
  const workspaces = getLoggedInUserProfiles().flatMap(x => x.permissions).map(x => x.workspace2).filter(x => !x.disabled && !x.is_public_demo);
  return ___EmotionJSX(IntegrationInterstitial, {
    name: "Notion",
    logo: ___EmotionJSX("img", {
      alt: "",
      src: NotionLogo
    }),
    canDeny: canDeny,
    canAllow: canAllow,
    onDeny: onDeny,
    onAllow: onAllow,
    permissions: permissions,
    workspaces: workspaces
  });
};
NotionIntegrationInterstitial.displayName = "NotionIntegrationInterstitial";