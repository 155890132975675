import { useProjectFeature } from 'data/entity/feature';
import { setPageTitle } from 'utils/page';
import { PageNotFoundWithInit } from '../404/404';
import { LegacyPage } from '../legacy/LegacyPage';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ProjectsPage = _ref => {
  let {
    renderId
  } = _ref;
  const projectFeature = useProjectFeature();
  if (!projectFeature || !projectFeature.on) {
    setPageTitle(`404 | ${BRAND.NAME}`);
    return ___EmotionJSX(PageNotFoundWithInit, {
      renderId: renderId
    });
  }
  setPageTitle(`Projects | ${BRAND.NAME}`);
  return ___EmotionJSX(LegacyPage, {
    pageInitializerName: "projects",
    renderId: renderId
  });
};
ProjectsPage.displayName = "ProjectsPage";