import { StoryCreatedOnFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base/TableDate';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryCreatedOnFieldFragment = StoryCreatedOnFieldFragmentDoc;
const StoryCreatedDate = _ref => {
  let {
    entity: {
      createdAt
    }
  } = _ref;
  return ___EmotionJSX(TableDate, {
    value: createdAt
  });
};
StoryCreatedDate.displayName = "StoryCreatedDate";
export const StoryCreatedOnField = () => ({
  name: 'createdAt',
  displayName: 'Created On',
  Component: StoryCreatedDate,
  sort: true,
  width: 110
});