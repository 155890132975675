function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as ___EmotionJSX } from "@emotion/react";
const chartStyle = `
  .average-cycle-time-line line {
    stroke: var(--progressBarCompletedColor);
  }
  .c3-target-HIDDEN-DATA-SO-DATES-ALWAYS-RENDER {
    opacity: 0 !important;
    pointer-events: none !important;
  }
  .c3-areas-Standard-Deviation2 {
    opacity: 0.2;
  }
  .c3-lines-Standard-Deviation2 {
    opacity: 0;
  }
  .c3-circles-Standard-Deviation2 {
    opacity: 0;
    pointer-events: none;
  }
  .c3-lines-Standard-Deviation {
    opacity: 0;
  }
  .c3-circles-Standard-Deviation {
    opacity: 0;
    pointer-events: none;
  }
  .c3-areas-Standard-Deviation {
    opacity: 0;
  }
  .c3-event-rect {
    cursor: default !important;
    &.hover-over-circle {
      cursor: pointer !important;
    }
  }
  .c3-legend-item.c3-defocused {
    opacity: 0.75;
  }
  .c3-legend-item-7-Day-Trailing-Average {
    .c3-legend-item-tile {
      stroke-width: 2px;
    }
  }
`;
export const CycleTimeReportCard = props => ___EmotionJSX(ReportCard, _extends({}, props, {
  title: "Cycle Time / Lead Time Chart",
  Explanation: Explanation,
  Filters: Filters,
  chartStyle: chartStyle
}));
CycleTimeReportCard.displayName = "CycleTimeReportCard";