import { Icon } from '@useshortcut/shapes-ds';
import { AlertMessage, AlertWrapper } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ArchiveMessage = _ref => {
  let {
    children,
    className,
    typeName,
    onUnarchive,
    onDelete
  } = _ref;
  return ___EmotionJSX(AlertWrapper, {
    className: className,
    kind: AlertMessage.KIND.ARCHIVED
  }, ___EmotionJSX(AlertWrapper.Icon, null, ___EmotionJSX(Icon, {
    icon: "Archive"
  })), ___EmotionJSX(AlertWrapper.Text, {
    size: "large"
  }, ___EmotionJSX(Spaced, {
    amount: "space1"
  }, ___EmotionJSX(Text, {
    bold: true,
    size: Text.SIZE.LARGE
  }, "This ", typeName.toLowerCase(), " is archived."), children && ___EmotionJSX(Text, {
    size: Text.SIZE.SMALL
  }, children))), ___EmotionJSX(AlertWrapper.CTA, null, ___EmotionJSX(Button, {
    "data-perma-id": "archive-message-unarchive",
    onClick: onUnarchive,
    kind: Button.KIND.SECONDARY
  }, "Unarchive"), onDelete && ___EmotionJSX(Button, {
    "data-perma-id": "archive-message-delete",
    onClick: onDelete,
    kind: Button.KIND.WARNING
  }, "Delete")));
};
ArchiveMessage.displayName = "ArchiveMessage";