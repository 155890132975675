function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useCallback, useRef } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Dialog } from '@clubhouse/shared/components/Dialog';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Heading } from '@clubhouse/shared/components/Typography/Heading';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { useToggleState } from '@clubhouse/shared/hooks';
import { Spacer } from 'components/shared/Flex';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ConfirmCloseDialog = _ref => {
  let {
    visible,
    onConfirm,
    onCancel
  } = _ref;
  return ___EmotionJSX(Dialog, {
    visible: visible,
    onClose: onCancel
  }, ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space4"
  }, ___EmotionJSX(Heading, {
    size: Heading.SIZE.SMALL
  }, "Unsaved Changes"), ___EmotionJSX(Text, null, "It looks like you have been editing categories. If you leave before saving, your changes will be lost."), ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space2"
  }, ___EmotionJSX(Spacer, null), ___EmotionJSX(Button, {
    type: "button",
    kind: Button.KIND.SECONDARY,
    onClick: onCancel
  }, "Cancel"), ___EmotionJSX(Button, {
    type: "button",
    onClick: onConfirm
  }, "Leave & Discard Changes"))));
};
ConfirmCloseDialog.displayName = "ConfirmCloseDialog";
const hasUnsavedChanges = dialog => {
  if (!dialog) return false;
  return dialog.querySelectorAll('[data-is-dirty="true"]').length > 0;
};
export const DialogWithUnsavedChanges = _ref2 => {
  let {
    onClose,
    ...rest
  } = _ref2;
  const dialogRef = useRef(null);
  const [showConfirmDialog, toggleConfirmDialog] = useToggleState();
  const handleConfirm = useCallback(() => {
    toggleConfirmDialog.off();
    onClose?.();
  }, [onClose, toggleConfirmDialog]);
  const handleClose = useCallback(() => {
    if (!hasUnsavedChanges(dialogRef.current)) onClose?.();else toggleConfirmDialog.on();
  }, [onClose, toggleConfirmDialog]);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Dialog, _extends({}, rest, {
    ref: dialogRef,
    onClose: handleClose
  })), ___EmotionJSX(ConfirmCloseDialog, {
    visible: showConfirmDialog,
    onCancel: toggleConfirmDialog.off,
    onConfirm: handleConfirm
  }));
};