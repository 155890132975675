import { Spaced } from '../Spaced';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ButtonSet = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    inline: true,
    amount: 12
  }, children);
};
ButtonSet.displayName = "ButtonSet";