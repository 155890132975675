import "core-js/modules/es.array.push.js";
//Templates
import * as StoriesPanelTemplate from 'app/client/iterations/views/templates/storiesPanel.html';
import * as TeamLabelTemplate from 'app/client/core/views/templates/teamLabel.html';

//Models
import BulkSelectionModel from 'app/client/core/js/models/bulkSelection';
import ColumnModel from 'app/client/core/js/models/column';
import EpicModel from 'app/client/core/js/models/epic';
import StoryModel from 'app/client/core/js/models/story';
import TeamModel from 'app/client/core/js/models/team';

//Modules
import ApplicationState from 'app/client/core/js/modules/applicationState';
import Format from 'app/client/core/js/modules/format';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';

//Controllers
import AutocompleteController from 'app/client/core/js/controllers/autocomplete';
import DropdownController from 'app/client/core/js/controllers/dropdown';

//Components
import { ContextualStoryCardWithViewSettings, WithFullStoryById } from 'components/shared/ContextualStoryCard';
import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import * as Event from 'app/client/core/js/_frontloader/event';
import * as InfiniteScroll from 'utils/infiniteScroll';
import { getTeamScopedApplicationStateKey } from 'components/team-navigation/utils/getTeamScopedApplicationStateKey';
import { deprecatedGetTeamScopeIdForNonReactAccess } from 'components/team-navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const STORIES_PANEL_SELECTOR = '#storiesPanel';
let DRAGGABLE_SELECTOR = '.story-link';
const EPIC = 'Epic';
const WORKFLOW = 'Workflow';
const SELECTABLE_ENTITIES = [EPIC, WORKFLOW];
const ENTITIES_ARTICLES = {
  Epic: 'an',
  Workflow: 'a'
};
const DEFAULT_FILTER_STATE = {
  epicId: null,
  workflowTeam: null,
  workflowStateId: null
};
exports.STORAGE_KEY_ENTITY = 'Iterations.EntitySelected';
exports.STORAGE_KEY_ENTITY_STATE = 'Iterations.EntityStateFilter';
exports.isLoading = false;
exports.fetchedEpics = [];
exports.render = () => {
  DRAGGABLE_SELECTOR = '.js-story-card';
  const entity = _getEntitySelected();
  const entityStateFilter = _getEntityStateFilter();
  const stories = _getStoriesFromFilters();
  $(STORIES_PANEL_SELECTOR).html(StoriesPanelTemplate.render({
    isOpen: exports.showStoriesPanel,
    stories: _.sortBy(stories, 'position'),
    isLoading: exports.isLoading,
    entityStateFilter,
    entity
  }));
  exports.initDraggable();
  Event.onlyOn('pageDestroy.IterationsStoriesPanel', () => {
    try {
      InfiniteScroll.destroyAll();
      destroyDragAndDrop();
    } catch {}
  });
};
const debouncedInitDraggable = debounce(() => {
  exports.initDraggable();
}, 50);
const StoriesPanelSideEffectCard = _ref => {
  let {
    storyId
  } = _ref;
  useEffect(() => {
    debouncedInitDraggable();
  });
  return ___EmotionJSX(WithFullStoryById, {
    component: ContextualStoryCardWithViewSettings,
    storyId: storyId,
    level: "3"
  });
};
StoriesPanelSideEffectCard.displayName = "StoriesPanelSideEffectCard";
exports.renderStoryCard = story => {
  return View.renderComponentDelayed({
    componentKey: `panel-storyCard-${story.id}`,
    cssClass: 'js-story-card react-wrapper story',
    containerProps: {
      'data-model': 'Story',
      'data-id': story.id
    },
    component: ___EmotionJSX(StoriesPanelSideEffectCard, {
      key: story.id,
      storyId: story.id
    })
  }).html;
};
exports.renderStoriesInPanel = _ref2 => {
  let {
    id,
    items,
    className
  } = _ref2;
  return InfiniteScroll.render({
    id,
    items,
    className,
    itemSize: {
      width: 0,
      height: 75
    },
    renderItem: exports.renderStoryCard,
    onBeforeRenderMore: () => destroyDragAndDrop()
  });
};
const destroyDragAndDrop = () => {
  try {
    $draggable?.draggable?.('destroy');
    debouncedInitDraggable.cancel();
  } catch (e) {}
};
function _getStoriesFromFilters() {
  const entity = _getEntitySelected();
  const entityStateFilter = _getEntityStateFilter();
  if (entity === WORKFLOW) {
    if (!entityStateFilter.workflowStateId) {
      const states = _.get(entityStateFilter, 'workflowTeam.workflow.states');
      const statesIds = states ? _.map(states, 'id') : [];
      return StoryModel.filter(story => {
        return statesIds.includes(story.workflow_state_id) && !story.archived && !story.iteration_id;
      });
    } else {
      return StoryModel.filter(story => {
        return story.workflow_state_id === entityStateFilter.workflowStateId && !story.archived && !story.iteration_id;
      });
    }
  } else if (entity === EPIC && entityStateFilter.epicId) {
    const epicId = entityStateFilter.epicId;
    if (exports.fetchedEpics.includes(epicId)) {
      return StoryModel.filter(story => {
        return story.epic_id === epicId && !story.archived && !story.iteration_id;
      });
    } else {
      exports.isLoading = true;
      EpicModel.fetchStoriesForEpics([{
        id: epicId
      }], () => {
        exports.fetchedEpics.push(epicId);
        exports.isLoading = false;
        exports.render();
      });
    }
  }
}
exports.getEpicFilterButtonName = () => {
  const defaultText = 'None';
  const entityStateFilter = _getEntityStateFilter();
  if (entityStateFilter && entityStateFilter.epicId) {
    const epic = EpicModel.getById(entityStateFilter.epicId);
    return epic ? epic.name : defaultText;
  } else {
    return defaultText;
  }
};
exports.getTeamFilterButtonName = () => {
  const defaultText = 'None';
  const entityStateFilter = _getEntityStateFilter();
  if (entityStateFilter && entityStateFilter.workflowTeam) {
    return entityStateFilter.workflowTeam.name;
  } else {
    return defaultText;
  }
};
exports.getWorkflowStateButtonName = () => {
  const defaultText = 'All';
  const entityStateFilter = _getEntityStateFilter();
  if (entityStateFilter && entityStateFilter.workflowStateId) {
    const state = ColumnModel.getById(entityStateFilter.workflowStateId);
    return state ? state.name : defaultText;
  } else {
    return defaultText;
  }
};
let $draggable;
exports.initDraggable = () => {
  $draggable = $(STORIES_PANEL_SELECTOR).find(DRAGGABLE_SELECTOR);
  $draggable.draggable({
    revert: 'invalid',
    revertDuration: 100,
    zIndex: 9999,
    helper: 'clone',
    handle: '.story-summary, .js-sortable-handle',
    appendTo: '.content-container',
    containment: '.content-container',
    scroll: false,
    distance: 10,
    start: (event, ui) => {
      appendDraggingMultipleStoriesNote(ui.helper);
      $(event.target).closest(DRAGGABLE_SELECTOR).hide();
    },
    stop: event => {
      $(event.target).closest(DRAGGABLE_SELECTOR).show();
    }
  });
};
function appendDraggingMultipleStoriesNote(draggedStory) {
  const story = Utils.getModelFromContext(draggedStory);
  const storyIsSelected = BulkSelectionModel.isSelected(story);
  const selectedStoryCount = BulkSelectionModel.getSelectionCount();
  if (storyIsSelected && selectedStoryCount > 1) {
    draggedStory.append('<span class="dragging-multiple-stories-note">+' + Format.pluralize(selectedStoryCount - 1, 'Story', 'Stories') + '</span>');
  }
}
exports.toggleStoriesPanel = () => {
  const storiesPanel = $(STORIES_PANEL_SELECTOR + ' .iterations-stories-panel');
  const isActive = exports.showStoriesPanel;
  if (isActive) {
    storiesPanel.find('.stories-panel-contents').fadeOut(100, () => {
      storiesPanel.removeClass('active');
      storiesPanel.find('.stories-panel-toggle-open').fadeIn();
    });
    exports.showStoriesPanel = false;
  } else {
    storiesPanel.find('.stories-panel-toggle-open').fadeOut(100, () => {
      storiesPanel.addClass('active');
      storiesPanel.find('.stories-panel-contents').fadeIn();
    });
    exports.showStoriesPanel = true;
  }
  exports.render();
};
exports.openEpicSelector = function () {
  DropdownController.closeAll();
  AutocompleteController.open({
    target: this,
    title: 'Select Epic',
    items: EpicModel.getItemsForAutocomplete({
      filterByTeamScope: true
    }),
    showInput: true,
    onApply: epicId => {
      _setEntityStateFilter({
        epicId
      });
    }
  });
};
exports.openTeamSelector = function () {
  DropdownController.closeAll();
  const activeTeam = _getEntityStateFilter().workflowTeam || {};
  DropdownController.open({
    target: this,
    title: TeamLabelTemplate.render(),
    items: TeamModel.getItemsForAutocomplete({
      activeTeam,
      filterByTeamScope: true
    }),
    onApply: team => {
      _setEntityStateFilter({
        workflowTeam: team,
        workflowStateId: null
      });
    }
  });
};
exports.openWorkflowStateDropdown = function () {
  const entityStateFilter = _getEntityStateFilter();
  const states = _.get(entityStateFilter, 'workflowTeam.workflow.states');
  if (states) {
    const element = $(this);
    DropdownController.closeAll().then(() => {
      exports.stateDropdown = DropdownController.open({
        target: element,
        onApply: stateId => {
          _setEntityStateFilter({
            workflowStateId: stateId
          });
        },
        items: [{
          name: 'All',
          value: null,
          className: entityStateFilter.workflowStateId ? '' : 'selected'
        }, {
          hr: true
        }, ...states.map(state => {
          // The workflowState names cached in the EntityStateFilter can be stale,
          // but those in the Collectionize model are guaranteed to be fresh.
          const workflowState = ColumnModel.getById(state.id);
          return {
            name: workflowState.name,
            value: state.id,
            className: entityStateFilter.workflowStateId === state.id ? 'selected' : ''
          };
        })]
      });
    });
  }
};
function _setEntityStateFilter(state) {
  const currentState = _getEntityStateFilter();
  const newState = {
    ...currentState,
    ...state
  };
  ApplicationState.set(getTeamScopedStorageKey(), newState);
  exports.render();
}
function _getEntityStateFilter() {
  return ApplicationState.get(getTeamScopedStorageKey()) || DEFAULT_FILTER_STATE;
}
function _setEntitySelected(entity) {
  ApplicationState.set(exports.STORAGE_KEY_ENTITY, entity);
  exports.render();
}
function _getEntitySelected() {
  return ApplicationState.get(exports.STORAGE_KEY_ENTITY) || SELECTABLE_ENTITIES[0];
}
exports.getArticleForEntity = entity => {
  return ENTITIES_ARTICLES[entity] || 'a';
};
exports.openStoriesPanelEntityDropdown = function () {
  const currentEntity = _getEntitySelected();
  const items = SELECTABLE_ENTITIES.filter(entity => entity !== currentEntity).map(entity => ({
    name: entity,
    value: () => {
      _setEntitySelected(entity);
    }
  }));
  DropdownController.closeAll();
  DropdownController.open({
    items,
    target: this,
    width: 140
  });
};
exports.shouldShowEmptyMessage = stories => {
  const entity = _getEntitySelected();
  const entityStateFilter = _getEntityStateFilter();
  if (!stories) return false;
  if (entity === EPIC && entityStateFilter.epicId && !stories.length) {
    return true;
  } else if (entity === WORKFLOW && entityStateFilter.workflowTeam && !stories.length) {
    return true;
  }
};
const getTeamScopedStorageKey = () => {
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  return getTeamScopedApplicationStateKey(teamId, exports.STORAGE_KEY_ENTITY_STATE);
};
export { exports as default };