import "core-js/modules/es.array.push.js";
import { QueryWorkspaceEpicStatesDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { insertIf } from '@clubhouse/shared/utils/object';
import { WorkflowStateIcon } from 'components/shared/WorkflowStateIcon';
import { useLazyQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const QUERY_WORKSPACE_EPIC_STATES = QueryWorkspaceEpicStatesDocument;
const StateTitleByType = {
  [WORKFLOW_STATE_TYPES.UNSTARTED]: 'Unstarted',
  [WORKFLOW_STATE_TYPES.STARTED]: 'Started',
  [WORKFLOW_STATE_TYPES.DONE]: 'Done'
};
export function useWorkspaceEpicStatesAsyncSelectQuery() {
  const [executeQuery] = useLazyQuery(QUERY_WORKSPACE_EPIC_STATES);
  const slug = useWorkspaceSlug();
  const fetchItems = useCallback(async _ref => {
    let {
      inputValue
    } = _ref;
    const trimmedInputValue = inputValue.trim();
    const {
      data
    } = await executeQuery({
      variables: {
        slug,
        input: {
          pagination: {
            limit: 50
          },
          orderBy: [{
            value: 'position',
            direction: 'ascending'
          }],
          where: {
            ...insertIf(!!trimmedInputValue, {
              name: trimmedInputValue
            })
          }
        }
      }
    });
    const hasMultiplePages = !!data?.workspace2?.epicStates?.pageInfo.hasNextPage;
    const statesByType = (data?.workspace2?.epicStates?.edges || []).reduce((acc, _ref2) => {
      let {
        node
      } = _ref2;
      const {
        id,
        name,
        type
      } = node;
      if (!acc.length || acc[acc.length - 1].sectionId !== type) {
        acc.push({
          sectionId: type ?? undefined,
          sectionTitle: type ? StateTitleByType[type] : undefined,
          allowBulkSelect: !hasMultiplePages && !inputValue,
          items: []
        });
      }
      acc[acc.length - 1].items.push({
        value: id,
        name,
        Icon: type ? () => ___EmotionJSX(WorkflowStateIcon, {
          type: type
        }) : undefined,
        additionalData: node
      });
      return acc;
    }, []);
    return {
      sections: statesByType,
      totalItemCount: data?.workspace2?.epicStates?.pageInfo.totalSize || 0
    };
  }, [executeQuery, slug]);
  return fetchItems;
}