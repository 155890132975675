import { Spaced } from '@clubhouse/shared/components/Spaced';
import { DetailPageSidebarDivider } from './DetailPageSidebarDivider';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DetailPageSidebarSection(_ref) {
  let {
    children,
    divider
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, !!divider && divider !== 'bottom' && ___EmotionJSX(DetailPageSidebarDivider, null), ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space1"
  }, children), !!divider && divider !== 'top' && ___EmotionJSX(DetailPageSidebarDivider, null));
}