import { TeamInfo } from '../../TeamInfo';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const AssociatedTeam = _ref => {
  let {
    count,
    imageUrl,
    name,
    teamColor
  } = _ref;
  return ___EmotionJSX(TeamInfo, null, ___EmotionJSX(TeamInfo.Icon, {
    iconUrl: imageUrl,
    iconFill: teamColor
  }), ___EmotionJSX(TeamInfo.Info, null, ___EmotionJSX(TeamInfo.Name, null, name), ___EmotionJSX(TeamInfo.StoryCount, {
    count: count
  })));
};
AssociatedTeam.displayName = "AssociatedTeam";