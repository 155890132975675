import { Icon } from '@useshortcut/shapes-ds';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useMemo } from 'react';
import CalendarPng from '@clubhouse/assets/png/empty-due-date.png';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
import { isActiveEpic, isDone as isEpicDone, useEpics } from 'data/entity/epic';
import { isArchived as isStoryArchived, isDoneState as isStoryDone, useStories } from 'data/entity/story';
import { getLoggedInUserPermissionID } from 'data/entity/user';
import { useAppState } from 'utils/appState';
import { hasUpcomingDeadline } from 'utils/deadline';
import { getIconForStoryType } from 'utils/getIconForStoryType';
import { EpicAutoLink } from '../shared/EpicAutoLink';
import { DisplaySelect } from './DisplaySelect';
import { StoryLinkName } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DISPLAY_ITEMS = [{
  name: 'Owned By Me',
  note: 'Only my stories and epics.',
  value: 'OWNER'
}, {
  name: 'Everything',
  note: 'All stories and epics.',
  value: 'EVERYTHING'
}];
const defaultDisplayItem = DISPLAY_ITEMS[1];
function isEpic(entity) {
  return entity.entity_type === 'epic';
}
const isOwner = (entity, currentUserId) => Boolean(entity?.owner_ids?.includes(currentUserId));
export function DueDates() {
  const [dueDatesFilter, setDueDatesFilter] = useAppState({
    appStateKey: 'dueDateFilter',
    default: defaultDisplayItem.value
  });
  const normalizedTaskFilter = useMemo(() => {
    const displayItem = DISPLAY_ITEMS.find(_ref => {
      let {
        value
      } = _ref;
      return value === dueDatesFilter;
    });
    return displayItem?.value || defaultDisplayItem.value;
  }, [dueDatesFilter]);
  const {
    stories
  } = useStories();
  const {
    epics
  } = useEpics();
  const dueDates = useMemo(() => {
    const ownerOnly = normalizedTaskFilter === 'OWNER';
    const profileId = getLoggedInUserPermissionID();
    const _stories = stories.filter(story => {
      const ownerFilter = ownerOnly ? isOwner(story, profileId) : true;
      const storyIsNotDoneOrArchived = !isStoryDone(story) && !isStoryArchived(story);
      return storyIsNotDoneOrArchived && hasUpcomingDeadline(story) && ownerFilter;
    });
    const _epics = epics.filter(epic => {
      const ownerFilter = ownerOnly ? isOwner(epic, profileId) : true;
      const epicIsNotDoneOrArchived = !isEpicDone(epic) && isActiveEpic(epic);
      return epicIsNotDoneOrArchived && hasUpcomingDeadline(epic) && ownerFilter;
    });
    return sortBy([..._stories, ..._epics], entity => entity.deadline ? new Date(entity.deadline) : null);
  }, [stories, epics, normalizedTaskFilter]);
  const hasDueDates = dueDates.length > 0;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "dashboard-header"
  }, ___EmotionJSX("h3", null, "Upcoming Due Dates"), ___EmotionJSX("div", {
    className: "bucket-actions"
  }, ___EmotionJSX(DisplaySelect, {
    label: "Select which due dates to show",
    current: normalizedTaskFilter,
    options: DISPLAY_ITEMS,
    onChange: setDueDatesFilter
  }))), hasDueDates ? ___EmotionJSX("div", {
    className: "due-dates"
  }, ___EmotionJSX("ul", null, dueDates.map(entity => {
    let StoryIcon;
    if (!isEpic(entity)) StoryIcon = getIconForStoryType(entity);
    const isOverdue = getIsOverdue(entity?.deadline);
    const isDueSoon = getIsDueSoon(entity?.deadline);
    return ___EmotionJSX("li", {
      key: entity.id
    }, isEpic(entity) ? ___EmotionJSX("div", {
      className: "epic-wrapper"
    }, ___EmotionJSX(DeprecatedIconAdapter, {
      width: 18
    }, ___EmotionJSX(Icon, {
      icon: "Epic",
      fill: "interactive"
    })), ___EmotionJSX(EpicAutoLink, {
      epic: entity,
      showTooltip: true
    })) : ___EmotionJSX("div", {
      style: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        gap: 4,
        width: '100%'
      }
    }, ___EmotionJSX(StoryIcon, {
      width: 18
    }), " ", ___EmotionJSX(StoryLinkName, {
      story: entity
    })), ___EmotionJSX(Chip, {
      kind: isOverdue ? 'redInverted' : isDueSoon ? 'orangeInverted' : 'default'
    }, moment(entity.deadline).format(DATE_FORMAT.SHORT_DATE_NO_YEAR)));
  }))) : ___EmotionJSX("div", {
    className: "empty"
  }, ___EmotionJSX("img", {
    src: CalendarPng,
    className: "dashboard__image",
    alt: ""
  }), ___EmotionJSX("p", {
    className: "heading"
  }, "All Clear"), ___EmotionJSX("p", {
    id: "empty_tasks",
    className: "subheading"
  }, "Nothing is due in the next 30 days.")));
}