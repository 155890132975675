import { SideDrawer } from 'components/shared/DeprecatedSideDrawer';
import { createBackgroundLocationState, getCurrentBackgroundLocationState } from 'utils/navigation/backgroundLocationState';
import Dialog from './dialog';
import View from './view';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Router from '../_frontloader/router';
import DropdownController from '../controllers/dropdown';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const _CLOSE_EVENT_NAME = 'SideDrawer.close';
const ROOT_ID = 'side-drawer-root';
const INNER_ID = 'side-drawer-inner';
exports.getMountNode = () => {
  const exisitingNode = document.getElementById(ROOT_ID);
  if (document.getElementById(ROOT_ID)) {
    return exisitingNode;
  }
  const mountNode = document.createElement('div');
  mountNode.id = ROOT_ID;
  document.body.appendChild(mountNode);
  return mountNode;
};
exports.isOpen = () => !!document.querySelector(`#${INNER_ID}`);
exports.close = () => Event.trigger(_CLOSE_EVENT_NAME);
exports.closeIfOpen = () => {
  if (exports.isOpen()) {
    exports.close();
  }
};
const _makeChildren = html => ___EmotionJSX("div", {
  dangerouslySetInnerHTML: {
    __html: html
  },
  id: INNER_ID
});
_makeChildren.displayName = "_makeChildren";
exports.render = _ref => {
  let {
    rerender,
    route,
    pageTitle,
    breadcrumbs,
    onClose,
    onMount,
    innerHTML = '',
    children: _children,
    componentKey,
    ...restProps
  } = _ref;
  if (DropdownController.isOpen()) DropdownController.closeAll();
  if (Dialog.isOpen()) Dialog.close({
    force: true
  });
  const routeStr = typeof route === 'function' ? route() : route;
  if (route) {
    Router.pushState(routeStr, pageTitle, createBackgroundLocationState());
  }
  const children = _children ? ___EmotionJSX("div", {
    id: INNER_ID
  }, _children) : _makeChildren(innerHTML);

  // Must unmount the previous instance. Otherwise, it does not actually
  // fire 'mount' events again, causing unexpected results.
  if (exports.unmount && !rerender) exports.unmount();
  View.renderComponent({
    component: SideDrawer,
    getProps: _ref2 => {
      let {
        unmountComponent,
        rerender
      } = _ref2;
      exports.unmount = () => {
        unmountComponent();
        exports.unmount = undefined;
      };
      const props = {
        onClose: () => {
          rerender({
            props: {
              ...props,
              isOpen: false
            },
            children
          });
          onClose && onClose();
        },
        onCloseEnd: () => {
          if (route) {
            const {
              title: backgroundTitle,
              href
            } = getCurrentBackgroundLocationState().backgroundLocation ?? {};
            Router.pushState(href ?? Globals.get('initialHref'), backgroundTitle);
          }
          unmountComponent();
        },
        isOpen: exports.isOpen(),
        ...restProps
      };
      if (breadcrumbs) {
        props.breadcrumbs = breadcrumbs;
      }
      Event.on(_CLOSE_EVENT_NAME, props.onClose);
      return {
        ...props,
        onMount: () => {
          rerender({
            props: {
              ...props,
              isOpen: true
            },
            children
          });
          onMount?.();
        }
      };
    },
    children,
    mountNode: exports.getMountNode(),
    componentKey
  });
};
export default exports;