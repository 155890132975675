import { Text } from '@clubhouse/shared/components/Typography/Text';
import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function EmptyState(_ref) {
  let {
    state
  } = _ref;
  const {
    hasClearableFilters
  } = useClearableCurrentFilters();
  return ___EmotionJSX("div", null, ___EmotionJSX(Text, {
    color: Text.COLOR.LIGHT,
    size: Text.SIZE.XXSMALL
  }, `There are no ${state} epics${hasClearableFilters ? ' matching your filters' : ''}.`));
}
EmptyState.displayName = "EmptyState";