import { getEpochFromOffsetDate } from '@clubhouse/datalayer/utils/getEpochFromOffsetDate';
import { deleteAllKeys } from '@clubhouse/shared/utils';
import { mergeBacklogConnections } from 'pages/backlog/entities/stories/components/table/mergeBacklogConnections'; // eslint-disable-line import/no-restricted-paths
import { mergeSpaceResultsConnectionsV1 } from 'pages/stories-v2/components/StoriesPage/utils/mergeSpaceResultsConnections/mergeSpaceResultsConnectionsV1'; // eslint-disable-line import/no-restricted-paths

import { parseCurrentUserPermissionSpaceData } from './applicationState';
import { createConnectionMerger, mergeConnections } from './mergeConnections';
const infiniteScrollMerger = createConnectionMerger(variables => {
  if (variables?.infiniteScroll) return variables;
  return null;
});
const objectiveEpicsConnectionMerger = createConnectionMerger(variables => {
  if (variables?.useEpicListsV2) return null;
  if (variables?.epicInput) return {
    input: variables.epicInput
  };
  return variables;
});
export const getTypePolicies = function () {
  let _options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    Features: {
      merge: true
    },
    EpicStats: {
      merge: true
    },
    ObjectiveStats: {
      merge: true
    },
    SpaceConfig: {
      fields: {
        kanbanResults2: {
          keyArgs: ['@connection', ['key']],
          merge: mergeSpaceResultsConnectionsV1
        },
        storiesInGroup: {
          merge: false,
          keyArgs: ['input', ['groupId']]
        }
      }
    },
    SpaceResultsEdge: {
      fields: {
        isStale: {
          read(data) {
            return data ?? false;
          }
        }
      }
    },
    StoryEdge: {
      fields: {
        isStale: {
          read(data) {
            return data ?? false;
          }
        }
      }
    },
    SpaceQuery: {
      fields: {
        matchAllFilterPills: {
          merge: false
        },
        matchAnyFilterPills: {
          merge: false
        }
      }
    },
    StoriesPageTabList: {
      fields: {
        tabs: {
          merge: false
        }
      }
    },
    Reportable: {
      fields: {
        // Derives the `reportNowValueEpoch` field from the `reportNowValue` field, by turning an `DateTimeUTCOffset` into an Epoch.
        reportNowValueEpoch: {
          read(_, _ref) {
            let {
              readField
            } = _ref;
            const reportNowValue = readField('reportNowValue');
            if (!reportNowValue) return null;
            return getEpochFromOffsetDate(reportNowValue);
          }
        }
      }
    },
    Report_LayerDataPoint: {
      fields: {
        // Derives the `sampledAtEpoch` field from the `sampledAt` field, by turning an `DateTimeUTCOffset` into an Epoch.
        sampledAtEpoch: {
          read(_, _ref2) {
            let {
              readField
            } = _ref2;
            const sampledAt = readField('sampledAt');
            if (!sampledAt) return null;
            return getEpochFromOffsetDate(sampledAt);
          }
        }
      }
    },
    Workspace2: {
      fields: {
        parsedCurrentUserPermissionSpaceData: {
          read: (data, _ref3) => {
            let {
              readField,
              storage
            } = _ref3;
            const currentUserPermissionSpaceData = readField('currentUserPermissionSpaceData');
            if (!currentUserPermissionSpaceData) {
              return currentUserPermissionSpaceData;
            }
            if (!storage[currentUserPermissionSpaceData]) {
              deleteAllKeys(storage);
              try {
                storage[currentUserPermissionSpaceData] = parseCurrentUserPermissionSpaceData(currentUserPermissionSpaceData);
              } catch (e) {
                return null;
              }
            }
            return storage[currentUserPermissionSpaceData];
          }
        },
        stories: {
          keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
          merge: mergeBacklogConnections
        },
        objectives: {
          keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
          merge: infiniteScrollMerger
        },
        categories: {
          keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
          merge: mergeConnections
        }
      }
    },
    Objective: {
      fields: {
        directEpics: {
          keyArgs: false,
          merge: objectiveEpicsConnectionMerger
        },
        healthHistory: {
          keyArgs: false,
          merge: mergeConnections
        },
        docsSharedToWorkspace2: {
          keyArgs: false,
          merge: infiniteScrollMerger
        }
      }
    },
    Epic: {
      fields: {
        healthHistory: {
          keyArgs: false,
          merge: mergeConnections
        }
      }
    },
    Story: {
      fields: {
        advancedCustomFields: {
          merge: false
        }
      }
    },
    SelectItemsSingleSelectImpl: {
      merge: true
    }
  };
};