import Logo from '@clubhouse/assets/png/third-party-logos/instabug-full.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Instabug() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.INSTABUG
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Instabug"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Instabug Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://instabug.com"
  }, "Instabug"), " provides in-app feedback and bug reporting for mobile apps."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_INSTABUG
  }, "our instructions"), " for how to configure this integration in your Instabug account.")));
}
Instabug.displayName = "Instabug";