import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Nouns } from '@clubhouse/shared/constants';
import * as IterationsPageTemplate from 'app/client/iterations/views/templates/iterationsPage.html';
import * as IterationsColumnViewTemplate from 'app/client/iterations/views/templates/iterationsColumnView.html';
import IterationPngLight from '@clubhouse/assets/png/Iterations-illo-light.png';
import { ContextualStoryCardWithViewSettings, WithFullStoryById } from 'components/shared/ContextualStoryCard';
import { Button } from 'components/shared/DeprecatedButton';
import { ButtonWithSampleLink } from 'components/shared/ButtonWithSampleLink';
import { Problem } from 'components/shared/Problem';
import { EmptyStateImage, NoResultImage } from 'components/shared/EmptyStateComponent';
import { CompositeImage } from 'components/shared/CompositeImage';
import { IterationsPageContextMenu } from 'components/iterations/IterationsPageContextMenu';
import { TeamScopedPageTitle, deprecatedGetTeamScopeIdForNonReactAccess } from 'components/team-navigation';
import { getTeamScopedApplicationStateKey } from 'components/team-navigation/utils/getTeamScopedApplicationStateKey';
import { IterationProgressBar } from 'components/iterations/IterationProgressBar';
import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { TeamTooltip } from '@clubhouse/shared/components/TeamTooltip';
import { TeamCohort } from '@clubhouse/shared/components/TeamCohort';
import BulkSelectionModel from 'app/client/core/js/models/bulkSelection';
import IterationModel from 'app/client/core/js/models/iteration';
import StoryModel from 'app/client/core/js/models/story';
import { getById, getTeamByTeamScopedId, getTeamScopedCollectionizeId } from 'data/entity/group';
import { getAllActiveProfileDetails } from 'data/entity/profile';
import ApplicationState from 'app/client/core/js/modules/applicationState';
import Format from 'app/client/core/js/modules/format';
import StoryAnimation from '../../../stories/js/modules/storyAnimation';
import Tests from 'app/client/core/js/modules/tests';
import Url from 'app/client/core/js/modules/url';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import { simpleCompleteTask } from 'utils/quickstart';
import IterationsPageFiltersController from './iterationsPageFilters';
import ProblemController from 'app/client/core/js/controllers/problem';
import StoriesPanelController from './storiesPanel';
import SampleWorkspaceController from 'app/client/core/js/controllers/sampleWorkspace';
import * as Event from 'app/client/core/js/_frontloader/event';
import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import * as InfiniteScroll from 'utils/infiniteScroll';
import { renderAddNewIteration } from 'utils/addNew';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const CONTENT_SELECTOR = '#iterations';
const COLUMNS_SELECTOR = '#iterations-columns';
const SCROLLABLE_SELECTOR = '.iterations-columns-outer';
const NS = 'iterationsPage';
const FILTER_KEY = `${NS}.iterationsFilter`;
const SCROLL_POSITION_KEY = `${NS}.scrollPosition`;
let loadingIterations = false;
let DRAGGABLE_SELECTOR = '.story-link, .js-story-card';
exports.renderNoIterations = () => {
  const team = getTeamByTeamScopedId();
  const buttonProps = {
    text: 'Create Iteration',
    onClick: () => {
      renderAddNewIteration(team ? {
        group: team
      } : undefined);
    },
    color: Button.colors.blue,
    size: Button.sizes.small
  };
  const sampleButtonProps = {
    text: 'View Sample Iterations',
    onClick: SampleWorkspaceController.join
  };
  const learnButtonProps = {
    text: 'Learn about Iterations',
    onClick: () => toggleHelpHub()
  };
  return View.renderComponentDelayed({
    componentKey: 'iterations-page-no-iterations',
    component: Problem,
    props: {
      height: ProblemController.getContentHeight(ProblemController.EXTRA_OFFSETS.M),
      title: 'Break it down with Iterations',
      description: 'Use iterations to complete work in a set period of time.',
      media: ___EmotionJSX(EmptyStateImage, {
        wrapperWidth: 72
      }, ___EmotionJSX(CompositeImage, {
        primaryImage: {
          src: IterationPngLight,
          alt: '',
          darkModeOpacity: 1
        },
        width: "100%"
      })),
      buttons: React.createElement(ButtonWithSampleLink, {
        buttonProps,
        sampleButtonProps,
        learnButtonProps
      })
    }
  }).html;
};
exports.render = function () {
  let {
    force = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!Tests.usesIterations()) {
    Utils.redirect(Url.getSlugPath() + '/dashboard');
    return false;
  }
  if (!force && Url.getCurrentPathname() !== Url.getSlugPath() + '/iterations') {
    return false;
  }
  IterationsPageFiltersController.updateFilters();
  const iterations = getTeamScopedIterations(IterationModel.getAllSortedBy());
  if (iterations.length) {
    const {
      iterations: filteredIterations,
      length
    } = IterationsPageFiltersController.filterIterations(iterations);
    exports.renderIterationsPage(filteredIterations, length);
  } else {
    $(CONTENT_SELECTOR).html(IterationsPageTemplate.render({
      iterations,
      totalCount: 0
    }));
  }
  View.unmountDetachedComponents();
  Event.trigger('pageRendered', 'Iterations');
};
const reRender = _.debounce(exports.render, 50);
exports.renderNoMatchingIterations = () => {
  const buttonProps = {
    text: 'Clear filters',
    onClick: () => {
      IterationsPageFiltersController.clearAllFilters();
      reRender();
    },
    color: Button.colors.blue,
    size: Button.sizes.small,
    style: {
      flexBasis: '125px',
      margin: 0
    }
  };
  return View.renderComponentDelayed({
    cssClass: 'no-matching-iterations',
    componentKey: 'iterations-page-no-matching-iterations',
    component: Problem,
    props: {
      className: 'no-iterations-found',
      height: '70%',
      description: 'No Iterations found matching your filters.',
      media: ___EmotionJSX(NoResultImage, null),
      buttons: React.createElement(Button, buttonProps)
    }
  }).html;
};
exports.renderIterationsPage = (iterations, length) => {
  return new Promise(resolve => {
    IterationModel.fetchStoriesForIterations({
      iteration_ids: _.map(iterations, 'id')
    }, () => {
      IterationModel.addStoryDataToIterations();
      $(CONTENT_SELECTOR).html(IterationsPageTemplate.render({
        iterations,
        totalCount: IterationModel.size()
      }));
      StoriesPanelController.render();
      IterationsPageFiltersController.render();
      renderIterationsColumns(iterations, length);
      resolve();
    });
  });
};
const IterationsPageSideEffectStoryCard = _ref => {
  let {
    storyId
  } = _ref;
  useEffect(() => {
    debouncedInitDraggable();
  });
  return ___EmotionJSX(WithFullStoryById, {
    showSelectToggle: true,
    component: ContextualStoryCardWithViewSettings,
    storyId: storyId
  });
};
IterationsPageSideEffectStoryCard.displayName = "IterationsPageSideEffectStoryCard";
const renderStoryCard = story => {
  return View.renderComponentDelayed({
    componentKey: `iteration-storyCard-${story.id}`,
    cssClass: 'js-story-card react-wrapper story',
    containerProps: {
      'data-model': 'Story',
      'data-id': story.id
    },
    component: ___EmotionJSX(IterationsPageSideEffectStoryCard, {
      key: story.id,
      storyId: story.id
    })
  }).html;
};
const debouncedInitDraggable = debounce(initDraggable, 50);
exports.renderIterationProgressBar = iterationId => View.renderComponentDelayed({
  componentKey: `iteration-card-progressbar-${iterationId}`,
  cssClass: 'iteration-progress-bar-container',
  props: {
    iterationId
  },
  component: IterationProgressBar
}).html;
const renderIterationsColumns = (iterations, length) => {
  View.renderWithComponents({
    templateMountNode: document.querySelector(COLUMNS_SELECTOR),
    template: IterationsColumnViewTemplate,
    templateData: {
      iterations,
      length,
      usesAssociatedTeams: true
    },
    components: {
      iterationsContextMenu: {
        component: IterationsPageContextMenu,
        props: {}
      }
    }
  });
  if (iterations.length) {
    $(SCROLLABLE_SELECTOR).scrollLeft(getStoredScrollPosition());
    initDragAndDrop();
    initOnScrollListener();
    if (iterations.length < length) {
      IterationsPageFiltersController.initPaginationScrollListener(SCROLLABLE_SELECTOR, onFilterChange);
    }
  }
  View.unmountDetachedComponents();
};
function onFilterChange() {
  if (!loadingIterations) {
    loadingIterations = true;
    const {
      length,
      iterations
    } = IterationsPageFiltersController.filterIterations(IterationModel.getAllSortedBy());
    if (iterations.length >= length) {
      IterationsPageFiltersController.stopPaginationScrollListener(SCROLLABLE_SELECTOR);
    }
    IterationModel.fetchStoriesForIterations({
      iteration_ids: _.map(iterations, 'id')
    }, () => {
      IterationModel.addStoryDataToIterations();
      renderIterationsColumns(iterations, length);
      loadingIterations = false;
    });
  }
}
exports.renderStoryIcon = (id, width) => View.renderComponentDelayed({
  componentKey: `story-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Story"
  }))
}).html;
exports.renderDocumentIcon = (id, width) => View.renderComponentDelayed({
  componentKey: `document-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Document"
  }))
}).html;
exports.renderMoreIcon = (id, width) => View.renderComponentDelayed({
  componentKey: `more-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "More"
  }))
}).html;
exports.renderEstimateIcon = (id, width) => View.renderComponentDelayed({
  componentKey: `estimate-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Estimate"
  }))
}).html;
function initOnScrollListener() {
  $(SCROLLABLE_SELECTOR).off(`scroll.${NS}`).on(`scroll.${NS}`, _.debounce(setStoredScrollPosition, 50));
}
function setStoredScrollPosition() {
  const scrollLeft = $(SCROLLABLE_SELECTOR).scrollLeft() || 0;
  ApplicationState.set(SCROLL_POSITION_KEY, scrollLeft);
}
function getStoredScrollPosition() {
  return ApplicationState.get(SCROLL_POSITION_KEY) || 0;
}
function initDraggable() {
  $(DRAGGABLE_SELECTOR).draggable({
    addClasses: false,
    revert: 'invalid',
    revertDuration: 100,
    zIndex: 9999,
    helper: 'clone',
    appendTo: '.iterations-columns-outer',
    containment: '.iterations-columns-inner',
    handle: '.story-summary, .js-sortable-handle',
    distance: 10,
    start: (event, ui) => {
      appendDraggingMultipleStoriesNote(ui.helper);
      $(event.target).closest(DRAGGABLE_SELECTOR).hide().closest('.iteration-card-content').droppable('disable');
    },
    stop: event => {
      $(event.target).closest(DRAGGABLE_SELECTOR).show().closest('.iteration-card-content').droppable('enable');
    }
  });
}
let $draggable, $droppable;
const destroyDragAndDrop = () => {
  try {
    $droppable.droppable('destroy');
    $draggable.draggable('destroy');
    debouncedInitDraggable.cancel();
  } catch (e) {}
};
function initDragAndDrop() {
  DRAGGABLE_SELECTOR = '.js-story-card';
  $droppable = $(CONTENT_SELECTOR + ' .iteration-card-content');
  $draggable = $droppable.find(DRAGGABLE_SELECTOR);
  $droppable.droppable({
    accept: DRAGGABLE_SELECTOR,
    over: event => getOverlayElement(event.target).addClass('hovering'),
    out: event => getOverlayElement(event.target).removeClass('hovering'),
    drop: (event, ui) => {
      ui.draggable.remove();
      const dropTarget = $(event.target).closest('.iteration-card');
      dropTarget.find('.drop-overlay').addClass('loading');
      const iterationId = Utils.data(dropTarget, 'id');
      const story = Utils.getModelFromContext(ui.draggable);
      handleUpdate(story, iterationId);
    }
  });
  initDraggable();
  Event.onlyOn('pageDestroy.Iterations', () => {
    destroyDragAndDrop();
  });
}
function appendDraggingMultipleStoriesNote(draggedStory) {
  const story = Utils.getModelFromContext(draggedStory);
  const storyIsSelected = BulkSelectionModel.isSelected(story);
  const selectedStoryCount = BulkSelectionModel.getSelectionCount();
  if (storyIsSelected && selectedStoryCount > 1) {
    draggedStory.append('<span class="dragging-multiple-stories-note">+' + Format.pluralize(selectedStoryCount - 1, 'Story', 'Stories') + '</span>');
  }
}
function getOverlayElement(element) {
  return $(element).closest('.iteration-card').find('.drop-overlay');
}
function handleUpdate(story, iterationId) {
  const revealStoryInIteration = () => {
    const storyElement = $(`#story-${story.id}`);
    const iterationElement = $(`.iteration-card[data-id=${iterationId}] .iteration-card-content`);
    StoryAnimation.scrollToAndFlashStory(storyElement, iterationElement);
  };
  if (BulkSelectionModel.isSelected(story)) {
    BulkSelectionModel.save({
      iteration_id: iterationId
    }, revealStoryInIteration);
  } else {
    StoryModel.setIteration(story, iterationId, {
      afterRender: revealStoryInIteration
    });
  }
}
const getNamespacedEventName = eventName => {
  return `${eventName}.IterationsPage`;
};
exports.init = () => {
  const teamId = deprecatedGetTeamScopeIdForNonReactAccess();
  const key = getTeamScopedApplicationStateKey(teamId, FILTER_KEY);
  simpleCompleteTask('visit-iterations');
  IterationsPageFiltersController.init(key, {
    onFilterChange
  });
  const iterationEventNames = ['iterationCreated', 'iterationDeleted', 'iterationChangesSaved'].map(getNamespacedEventName);
  iterationEventNames.forEach(eventName => {
    IterationModel.off(eventName);
    IterationModel.on(eventName, reRender);
  });
  const storyEventNames = ['storySaved', 'latestVersionFetched', 'bulkStoriesUpdated'].map(getNamespacedEventName);
  storyEventNames.forEach(eventName => {
    StoryModel.off(eventName);
    StoryModel.on(eventName, reRender);
  });
  const eventNames = ['storySelectionUpdated', 'storyElementTearDown'].map(getNamespacedEventName);
  Event.onlyOn(eventNames.join(' '), () => {
    initDragAndDrop();
    StoriesPanelController.initDraggable();
  });
  Event.onlyOn(getNamespacedEventName('pageDestroy'), () => {
    iterationEventNames.forEach(eventName => IterationModel.off(eventName));
    storyEventNames.forEach(eventName => StoryModel.off(eventName));
    $(SCROLLABLE_SELECTOR).off(`scroll.${NS}`);
    InfiniteScroll.destroyAll();
  });
  Event.onlyOn(getNamespacedEventName('storyDeleted'), () => {
    exports.render({
      force: true
    });
  });
};
exports.renderIterationCard = _ref2 => {
  let {
    id,
    items,
    className
  } = _ref2;
  return InfiniteScroll.render({
    id,
    items,
    className,
    itemSize: {
      width: 0,
      height: 75
    },
    renderItem: renderStoryCard,
    onBeforeRenderMore: () => destroyDragAndDrop(),
    onRenderMore: () => initDragAndDrop()
  });
};
exports.renderHeader = () => View.renderComponentDelayed({
  componentKey: 'iterations-page-title',
  cssClass: 'header',
  component: ___EmotionJSX(TeamScopedPageTitle, {
    title: "Iterations"
  })
}).html;
const getTeamScopedIterations = iterations => {
  const teamId = getTeamScopedCollectionizeId();
  const teamScopedIterations = teamId ? iterations.filter(iteration => {
    return [iteration.group_ids[0], ...(iteration.associated_groups || []).map(_ref3 => {
      let {
        group_id
      } = _ref3;
      return group_id;
    })].includes(teamId);
  }) : iterations;
  return teamScopedIterations;
};
exports.renderTeamCohort = data => {
  const owningTeam = getById(data.group_ids[0]);
  const associatedGroups = data.associated_groups || [];
  const users = getAllActiveProfileDetails();
  const teamScopeId = getTeamScopedCollectionizeId();
  const associatedGroupsWithGroup = associatedGroups.map(_ref4 => {
    let {
      group_id,
      associated_stories_count
    } = _ref4;
    const group = getById(group_id);
    if (!group) return null;
    return {
      group: {
        id: group.id,
        name: group.name,
        imageUrl: group.display_icon?.url,
        colorKey: group.color_key
      },
      numStoriesOwned: associated_stories_count
    };
  }).filter(group => Boolean(group));
  let primaryTeam;
  let otherTeams = associatedGroupsWithGroup;
  if (teamScopeId) {
    primaryTeam = associatedGroupsWithGroup.find(_ref5 => {
      let {
        group
      } = _ref5;
      return group.id === teamScopeId && owningTeam?.id !== teamScopeId;
    });
    if (primaryTeam) {
      otherTeams = associatedGroupsWithGroup.filter(_ref6 => {
        let {
          group
        } = _ref6;
        return group.id !== primaryTeam?.group?.id;
      });
    }
  }
  const displayTeams = primaryTeam ? [primaryTeam, ...otherTeams] : otherTeams;
  const owningTeamNumberOfStories = associatedGroupsWithGroup.reduce((acc, _ref7) => {
    let {
      numStoriesOwned
    } = _ref7;
    return acc - numStoriesOwned;
  }, data.num_stories);
  return View.renderComponentDelayed({
    componentKey: `team-cohort-${data.id}`,
    component: ___EmotionJSX(TeamTooltip, {
      associatedEntityType: Nouns.Iteration.singular,
      associatedTeams: displayTeams,
      team: owningTeam,
      users: users,
      owningTeamNumberOfStories: owningTeamNumberOfStories
    }, ___EmotionJSX("div", null, ___EmotionJSX(TeamCohort, {
      size: 24
    }, ___EmotionJSX(TeamIcon, {
      key: owningTeam?.group_id,
      backgroundColor: getTeamColor(owningTeam),
      iconUrl: owningTeam?.display_icon?.url,
      alt: owningTeam?.name
    }), displayTeams.map(_ref8 => {
      let {
        group
      } = _ref8;
      return ___EmotionJSX(TeamIcon, {
        key: group?.id,
        iconUrl: group?.imageUrl,
        backgroundColor: getTeamColor(group),
        alt: group?.name
      });
    }))))
  }).html;
};
export { exports as default };