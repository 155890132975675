import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { isDisabled as isEstimateScaleDisabled } from 'data/entity/estimateScale';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EstimateChip = () => {
  const story = useContext(StoryContext);
  if (!story || isEstimateScaleDisabled() || typeof story.estimate !== 'number') return null;
  const estimate = story.estimate;
  return ___EmotionJSX(StoryCardInternal.Chips.EstimateChip, {
    estimate: estimate
  });
};
EstimateChip.displayName = "EstimateChip";