import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { EpicSearchCardAdapter } from './EpicSearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicResults = _ref => {
  let {
    fetchProps: {
      loading,
      data: epics,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SkeletonSearchCard, {
      icon: "Epic",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Epic",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Epic",
      kind: "blue"
    }));
  }
  const resultsList = loading ? previousData : epics;
  if (hasFetched && !resultsList?.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Epics"
    });
  }
  return ___EmotionJSX(React.Fragment, null, resultsList?.map((epic, index) => ___EmotionJSX(EpicSearchCardAdapter, {
    key: epic.id,
    epic: epic,
    onClick: entity => onClick?.(entity, index),
    openModalOnClick: openModalOnClick
  })), next && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: loadMore
  }));
};