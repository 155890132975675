import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/iteration/links';
import { useIterationWithFetch } from 'data/entity/iteration';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationContextMenu = _ref => {
  let {
    id
  } = _ref;
  const {
    iteration,
    loading
  } = useIterationWithFetch({
    id
  });
  if (loading) return ___EmotionJSX(ContextMenuLoading, null);
  if (!iteration) return null;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getHref(id)
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(id, true),
    label: "Link"
  }), ___EmotionJSX(CopyContextMenuItem, {
    value: String(id),
    label: "ID"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: iteration.global_id,
    entityType: Nouns.Iteration,
    loggingContext: {
      typename: 'Iteration'
    }
  }));
};
IterationContextMenu.displayName = "IterationContextMenu";
export const useIterationContextMenu = iterationId => {
  const {
    props
  } = useContextMenuWithLogging(() => ___EmotionJSX(IterationContextMenu, {
    id: iterationId
  }), {
    entityType: 'Iteration'
  });
  return props;
};
export const useIterationContextMenuWithQuickSearchSessionLogging = (iterationId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => ___EmotionJSX(IterationContextMenu, {
    id: iterationId
  }), {
    entityType: 'Iteration'
  }, logQuickSearchInteractions);
  return props;
};