import { Chip } from '@clubhouse/shared/components/Chip';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DocCountChip = _ref => {
  let {
    count
  } = _ref;
  if (!count) return null;
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(ChipTooltip.Text, null, pluralize({
      count,
      singular: 'Doc',
      plural: 'Docs'
    }))
  }, ___EmotionJSX(Chip, null, ___EmotionJSX(Chip.Icon, {
    icon: "Document",
    label: "Docs count: "
  }), count));
};
DocCountChip.displayName = "DocCountChip";