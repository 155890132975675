import { EpicCard } from '../shared/EpicCard';
import { LoadMore } from './LoadMore';
import { Loading } from './Loading';
import { NoResults } from './NoResults';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicResults = _ref => {
  let {
    fetchProps: {
      loading,
      data: epics,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return ___EmotionJSX(Loading, null);
  }
  if (hasFetched && !epics?.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Epics"
    });
  }
  return ___EmotionJSX(React.Fragment, null, epics && epics.map(epic => ___EmotionJSX(EpicCard, {
    key: epic.id,
    epic: epic,
    onClick: onClick,
    openModalOnClick: openModalOnClick,
    type: "elevated"
  })), next && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: loadMore
  }));
};