import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DoneChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.completed) return null;
  return ___EmotionJSX(StoryCardInternal.Chips.DoneChip, {
    completed: story.completed
  });
};
DoneChip.displayName = "DoneChip";