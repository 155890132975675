import { StoryGroupByProductAreaFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { ProductAreaWithIcon } from 'components/gql/productArea/ProductAreaWithIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByProductAreaFragment = StoryGroupByProductAreaFragmentFragmentDoc;
export const ProductAreaGroupHeader = _ref => {
  let {
    productArea
  } = _ref;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.ProductArea
  }, typeof productArea?.stringValue === 'string' ? ___EmotionJSX(ProductAreaWithIcon, {
    spacing: 12,
    name: productArea.stringValue,
    colorKey: productArea.colorKey
  }) : 'No Product Area');
};
ProductAreaGroupHeader.displayName = "ProductAreaGroupHeader";