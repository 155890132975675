import throttle from 'lodash/throttle';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { updateMultipleFilesFromUploaderModel } from 'data/entity/file';
import { mapNewFilesToEntities } from 'data/entity/uploader';
import { post } from 'utils/backend';
import { getSelection } from '../utils';

// This upload code is mostly copy/pasted from the legacy upload.js. We will have to remove the dependency on jQuery at some point.
import { jsx as ___EmotionJSX } from "@emotion/react";
export const uploadFiles = (files, textarea, setValue) => {
  const fileEntities = mapNewFilesToEntities(files);
  const data = new FormData();
  fileEntities.forEach((file, i) => {
    if (file.raw.size > 50_000_000) {
      addToast({
        kind: 'alert',
        timeout: 5000,
        Content: () => ___EmotionJSX(ToastText, null, "The size of", ' ', ___EmotionJSX("em", null, ___EmotionJSX(Emojify, null, file.name)), ' ', "exceeds the current limit of 50 MB.")
      });
    } else {
      data.append(`files[${i}]`, file.raw, file.name);
    }
  });

  //Do we have at least one file?
  if (data.has(`files[0]`)) {
    const {
      before,
      after,
      selectionStart,
      selectionEnd
    } = getSelection(textarea);
    textarea.setAttribute('readonly', 'true');
    setValue(`${before}[Uploading files...]()${after}`, {
      selectionStart,
      selectionEnd,
      skipHistoryEntry: true
    });
    post('/api/private/files', {
      data,
      timeout: 2_400_000,
      // 4 minutes
      cache: false,
      contentType: false,
      processData: false,
      xhr() {
        const xhr = jQuery.ajaxSettings.xhr();
        if (xhr.upload) {
          xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
              setValue(`${before}[Uploading files... ${Math.round(100 * e.loaded / (e.total || 1))}% completed]()${after}`, {
                selectionStart,
                selectionEnd,
                skipHistoryEntry: true
              });
            }
          }, false);
        }
        return xhr;
      },
      onError() {
        const errorMessage = '[Upload failed. Please try again]()';
        setValue(`${before}${errorMessage}${after}`, {
          selectionStart,
          selectionEnd: selectionStart + errorMessage.length
        });
      },
      onSuccess(res) {
        updateMultipleFilesFromUploaderModel(res, files => {
          const fileContent = files.map(file => `${file.content_type.startsWith('image') ? '!' : ''}[${file.name}](${file.url})`).join('\n');
          const selStart = selectionStart + fileContent.length;
          setValue(`${before}${fileContent}${after}`, {
            selectionStart: selStart,
            selectionEnd: selStart
          });
        });
      },
      onComplete() {
        textarea.removeAttribute('readonly');
      }
    });
  }
};

// Find the best location to insert the file based on the mouse cursor
const findBestDropLocation = (textarea, mx, my) => {
  const spans = [...(getCharsEl(textarea)?.querySelectorAll('span') || [])];
  if (!spans.length) {
    textarea.setSelectionRange(0, 0);
    return;
  }
  let bestRowOffset = -1;
  let firstInBestRow = -1;
  let firstInRow = -1;
  let lastRowOffset = -1;

  // First we find the row that best matches the cursor position
  for (let i = 0; i < spans.length; ++i) {
    const {
      offsetTop
    } = spans[i];
    if (my < offsetTop) {
      // Mouse cursor is above this row, which means the previous row was the best match
      firstInBestRow = Math.max(firstInRow, 0);
      bestRowOffset = Math.max(lastRowOffset, 0);
      break;
    } else if (offsetTop > lastRowOffset) {
      // New row. Save the first item in the row.
      firstInRow = i;
      lastRowOffset = offsetTop;
    }
  }

  // If the best match is the last row, it isn't captured in the for loop. So we need to capture it here
  if (bestRowOffset < 0 && lastRowOffset >= 0) {
    bestRowOffset = lastRowOffset;
    firstInBestRow = Math.max(firstInRow, 0);
  }

  // No match found for some reason.
  if (bestRowOffset < 0 || firstInBestRow < 0) return;
  let closestSpan = -1;

  // Now we try to find the best position in the row to insert the file
  for (let i = firstInBestRow; i < spans.length; ++i) {
    const {
      offsetTop,
      offsetLeft,
      offsetWidth
    } = spans[i];
    if (mx < offsetLeft) {
      // If the cursor is to the left of the span, we insert before the span.
      closestSpan = i;
      break;
    } else if (mx < offsetLeft + offsetWidth) {
      // If the cursor is to the current span, we insert after it.
      closestSpan = i + 1;
      break;
    } else if (offsetTop > bestRowOffset) {
      // If we reached a new row, we insert at the end of the row.
      closestSpan = Math.max(i - 1, 0);
      break;
    }
  }

  // If we didn't find a match, it is because we are dropping after the last row. So we insert at the end of the last row
  if (closestSpan < 0) closestSpan = spans.length;
  textarea.setSelectionRange(closestSpan, closestSpan);
};
const throttledFindBestDropLocation = throttle(findBestDropLocation, 100);
const getCharsEl = textarea => {
  const id = textarea.getAttribute('data-chars-id');
  if (!id) return null;
  return document.getElementById(id) ?? null;
};
export function fileCommand(uploadFn) {
  return {
    name: 'Attach File',
    icon: 'File',
    fn(textarea, setValue) {
      const input = document.createElement('input');
      input.type = 'file';
      input.setAttribute('multiple', '');
      input.onchange = e => {
        const files = e.target.files;
        uploadFn(files, textarea, setValue);
      };
      input.click();
    },
    events: {
      dragenter(e, textarea) {
        const charsEl = getCharsEl(textarea);
        if (charsEl) charsEl.innerHTML = (textarea.value || '').split('').map(char => `<span>${char.replace(/^\n$/g, '.\n')}</span>`).join('');
      },
      dragleave(e, textarea) {
        const charsEl = getCharsEl(textarea);
        if (charsEl) charsEl.innerHTML = '';
      },
      dragover(e, textarea) {
        if (!e.dataTransfer?.items?.length) return;
        if (!e.dataTransfer.types.includes('Files')) return;
        if (e.defaultPrevented) return;

        // Required or dragging files won't work on Firefox.
        e.preventDefault();
        if (textarea.selectionStart === textarea.selectionEnd) {
          textarea.focus();
          const {
            top,
            left
          } = textarea.getBoundingClientRect();
          const mx = e.clientX - left + textarea.scrollLeft;
          const my = e.clientY - top + textarea.scrollTop;
          throttledFindBestDropLocation(textarea, mx, my);
        }
      },
      drop(e, textarea, setValue) {
        if (!e.dataTransfer?.items?.length) return;
        if (!e.dataTransfer.types.includes('Files')) return;
        if (e.defaultPrevented) return;
        e.preventDefault();
        e.stopPropagation();
        if (textarea.selectionStart === textarea.selectionEnd) {
          const {
            top,
            left
          } = textarea.getBoundingClientRect();
          const mx = e.clientX - left + textarea.scrollLeft;
          const my = e.clientY - top + textarea.scrollTop;
          findBestDropLocation(textarea, mx, my);
        }
        uploadFn(e.dataTransfer.files, textarea, setValue);
        const charsEl = getCharsEl(textarea);
        if (charsEl) charsEl.innerHTML = '';
      },
      paste(e, textarea, setValue) {
        if (!e.clipboardData?.items?.length) return;
        if (!e.clipboardData.types.includes('Files')) return;
        if (e.defaultPrevented) return;
        e.preventDefault();
        e.stopPropagation();
        uploadFn(e.clipboardData.files, textarea, setValue);
      }
    }
  };
}