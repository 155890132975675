import * as IterationsPageFiltersTemplate from 'app/client/iterations/views/templates/iterationsPageFilters.html';
import { GroupFilterDropdown, NO_GROUP_ID } from 'components/groups/GroupFilterDropdown';
import { IterationStatusDropdown } from 'components/shared/IterationStatusDropdown';
import FilterModel from '../../../core/js/models/filter';
import IterationModule from '../../../core/js/modules/iteration';
import IterationModel from '../../../core/js/models/iteration';
import View from '../../../core/js/modules/view';
import Utils from '../../../core/js/modules/utils';
import UserModel from '../../../core/js/models/user';
import { isScopedToTeam } from 'components/team-navigation';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { ShowAssociated } from 'components/shared/ShowAssociated';
import { IterationAutomationsCTA } from 'components/iterations/IterationAutomationsCTA';
const exports = {};
const FILTERS_SELECTOR = '#iterations-page-filters';
const SCROLL_THRESHOLD = 370;
const PAGE_SIZE = 10;
exports.FILTER_KEY = undefined;
exports.onFilterChange = () => {};
exports.init = (FILTER_KEY, _ref) => {
  let {
    onFilterChange
  } = _ref;
  exports.FILTER_KEY = FILTER_KEY;
  exports.onFilterChange = onFilterChange;
  exports.render();
};
exports.render = () => {
  const templateMountNode = document.querySelector(FILTERS_SELECTOR);
  if (!templateMountNode) {
    return;
  }
  const {
    statuses,
    query,
    group_ids,
    show_associated
  } = exports.getFilters();
  if (show_associated) {
    exports.updateFilters({
      show_associated: undefined
    });
  }
  const teamId = getTeamScopedCollectionizeId();
  View.renderWithComponents({
    templateMountNode,
    template: IterationsPageFiltersTemplate,
    templateData: {
      query,
      shouldRenderTeamsFilter: !isScopedToTeam(),
      shouldRenderAssociatedTeamsFilter: true
    },
    restoreActiveElement: true,
    components: {
      iterationStatusFilter: {
        component: IterationStatusDropdown,
        getProps: () => {
          return {
            selectedValues: statuses,
            onChange: statuses => {
              setAndRenderStatusFilter(statuses);
            }
          };
        }
      },
      groupFilter: {
        component: GroupFilterDropdown,
        props: {
          selectedGroupIds: group_ids,
          onItemsSelected: groupIds => {
            setAndRenderGroupFilter(groupIds);
          },
          currentProfileId: UserModel.getLoggedInUserPermissionID(),
          includeNoGroup: true,
          noGroupTriggerText: 'Iterations with No Team'
        }
      },
      showAssociatedTeamsCheckbox: {
        component: ShowAssociated,
        props: {
          isChecked: show_associated,
          content: teamId ? "Show other teams' iterations which contain stories owned by this team." : "Show other teams' iterations which contain stories owned by the filtered teams.",
          onClick: setAndRenderAssociatedTeamsCheckbox
        }
      },
      automationsCTA: {
        component: IterationAutomationsCTA
      }
    }
  });
  return false;
};
const setAndRenderStatusFilter = statuses => {
  if (statuses.length > Object.values(IterationModel.STATUS_VALUES).length) {
    statuses = [];
  }
  exports.updateFilters({
    statuses: statuses.length === 0 ? undefined : statuses
  });
  exports.onFilterChange();
  exports.render();
};
const setAndRenderGroupFilter = groupIds => {
  exports.updateFilters({
    group_ids: groupIds.length === 0 ? undefined : groupIds
  });
  exports.onFilterChange();
  exports.render();
};
const setAndRenderAssociatedTeamsCheckbox = showAssociated => {
  exports.updateFilters({
    show_associated: showAssociated === true ? undefined : false
  });
  exports.onFilterChange();
  exports.render();
};
exports.updateFilters = updates => {
  const values = _.omitBy({
    ...exports.getFilters(),
    ...updates
  }, v => v === undefined);
  FilterModel.setCurrentFilters(exports.FILTER_KEY, values);
};
function getCurrentSearchQueryValue() {
  const element = $('#page-search-query');
  if (element.length > 0) {
    return element.val().trim();
  }
  return '';
}
exports.getFilters = () => {
  const isPageInUrl = !!FilterModel.getFiltersFromURL().page;
  const currentFilters = {
    query: FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'query'),
    page: isPageInUrl ? Number(FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'page')) || 0 : 0,
    statuses: IterationModule.toValidStatusFilter(FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'statuses')),
    show_associated: FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'show_associated')?.[0] === 'false' || FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'show_associated') === false ? false : true
  };
  const group_ids = FilterModel.getCurrentFiltersAt(exports.FILTER_KEY, 'group_ids');
  return {
    ...currentFilters,
    group_ids
  };
};
exports.filterIterations = function () {
  let iterations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const {
    page,
    query,
    statuses,
    group_ids: groupIdsFilterValue,
    show_associated: showAssociatedTeams
  } = exports.getFilters();
  const teamId = getTeamScopedCollectionizeId();
  const group_ids = teamId ? [teamId] : groupIdsFilterValue;
  const filteredIterations = iterations.filter(iteration => {
    if (statuses.length === 0) {
      return true;
    }
    return statuses.some(status => iteration.status === status);
  }).filter(iteration => {
    const tokens = query ? Utils.tokenizeQuery(query) : [];
    if (tokens.length) {
      return _.every(tokens, token => iteration.name.toLowerCase().includes(token.toLowerCase()));
    } else {
      return true;
    }
  }).filter(iteration => {
    if (!group_ids) {
      return true;
    }
    if (iteration.group_ids?.length === 0 && group_ids.includes(NO_GROUP_ID)) {
      return true;
    }
    if (showAssociatedTeams) {
      const associatedGroupIds = iteration.associated_groups?.map(_ref2 => {
        let {
          group_id
        } = _ref2;
        return group_id;
      }) || [];
      return _.intersection(group_ids, [...iteration.group_ids, ...associatedGroupIds]).length > 0;
    }
    return _.intersection(group_ids, iteration.group_ids).length > 0;
  });
  return {
    length: filteredIterations.length,
    iterations: filteredIterations.slice(0, page * PAGE_SIZE + PAGE_SIZE)
  };
};
exports.clearAllFilters = () => {
  exports.updateFilters({
    query: undefined,
    page: undefined,
    statuses: undefined,
    group_ids: undefined
  });
  exports.render();
  exports.onFilterChange();
};
exports.clearQuery = () => {
  exports.updateFilters({
    query: undefined,
    page: undefined
  });
  exports.render();
  exports.onFilterChange();
};
exports.onSearchQueryKeyUp = _.debounce(() => {
  const searchQuery = getCurrentSearchQueryValue();
  if (searchQuery) {
    $('.search-query-parent').addClass('has-query');
  } else {
    $('.search-query-parent').removeClass('has-query');
  }
  exports.updateFilters({
    query: searchQuery ? searchQuery : undefined
  });
  exports.onFilterChange();
}, 50);
exports.initPaginationScrollListener = (selector, onNextPage) => {
  $(selector).on('scroll', _onScroll(onNextPage));
};
exports.stopPaginationScrollListener = selector => {
  $(selector).off('scroll');
};
const _onScroll = function () {
  let onNextPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
  return _.throttle(function () {
    const $this = $(this);
    const outerWidth = $this.outerWidth();
    const contentWidth = $this.children().outerWidth();
    const contentPos = $this.children().position();
    if (contentWidth - outerWidth + contentPos.left < SCROLL_THRESHOLD) {
      const {
        page
      } = exports.getFilters();
      exports.updateFilters({
        page: page + 1
      });
      onNextPage();
    }
  }, 600);
};
export { exports as default };