import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Switch } from '@clubhouse/shared/components/Switch';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { ApplicationIds, update, useInstallationByApplicationId } from 'data/entity/installation';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { Layout } from './Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TypeToApplicationId = {
  bitbucket: ApplicationIds.Bitbucket,
  github: ApplicationIds.Github,
  gitlab: ApplicationIds.Gitlab
};
export function CommentOnPrToggle(_ref) {
  let {
    type
  } = _ref;
  const isAdmin = isLoggedInUserAdmin();
  const installation = useInstallationByApplicationId(TypeToApplicationId[type]);
  if (!installation) return null;
  const isChecked = !installation.vcs_backlinking_disabled;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 0
  }, ___EmotionJSX(Layout.SectionTitle, null, "Commenting on ", type === 'gitlab' ? 'Merge Requests' : 'Pull Requests'), ___EmotionJSX(Tooltip, {
    content: "Only an admin can change this value.",
    disabled: isAdmin
  }, ___EmotionJSX(Switch.Label, null, ___EmotionJSX(Switch, {
    isDisabled: !isAdmin,
    isChecked: isChecked,
    onChange: isEnabled => {
      update(installation, {
        vcs_backlinking_disabled: !isEnabled
      });
    }
  }), ___EmotionJSX(Text, null, "When enabled, ", BRAND.NAME, " will create a comment on a ", type === 'gitlab' ? 'Merge Request' : 'Pull Request', ' ', "(if it is in a private ", type === 'gitlab' ? 'project' : 'repository', ") with a link to a Story when the", ' ', type === 'gitlab' ? 'Merge Request' : 'Pull Request', " is associated with a Story."))));
}
CommentOnPrToggle.displayName = "CommentOnPrToggle";