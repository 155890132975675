function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as ___EmotionJSX } from "@emotion/react";
const chartStyle = `
  .c3-chart-bars {
    .c3-chart-bar {
      path {
        stroke-width: 0px;
      }

      .c3-shapes-Features.c3-bars {
        path {
          fill: var(--reportFeature);
          fill-opacity: .75;
          &._expanded_ {
          fill-opacity: 1;
          }
        }
      }
      .c3-shapes-Bugs.c3-bars {
        path {
          fill: var(--reportBug);
          fill-opacity: .75;
          &._expanded_ {
          fill-opacity: 1;
          }
        }
      }
      .c3-shapes-Chores.c3-bars {
        fill: var(--reportChore);
        fill-opacity: .75;
          &._expanded_ {
          fill-opacity: 1;
          }
      }
    }
  }
  .c3-tooltip-container {
    th {
      div {
        font-weight: normal;
        padding-top: 5px;
      }
    }
    hr {
      margin: 2px;
    }
  }
  .average-velocity-line line {
    stroke: var(--progressBarCompletedColor);
  }

  .c3-legend-item-7-Day-Trailing-Average {
    .c3-legend-item-tile {
      stroke-width: 2px;
    }
  }
`;
export const VelocityReportCard = props => ___EmotionJSX(ReportCard, _extends({}, props, {
  title: "Velocity Chart",
  description: "Completed Stories broken down by story type.",
  Explanation: Explanation,
  Filters: Filters,
  chartStyle: chartStyle
}));
VelocityReportCard.displayName = "VelocityReportCard";