import { openHelp } from 'utils/help';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { getSlugPath } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportsHelpFooter = () => ___EmotionJSX(React.Fragment, null, 'Any questions? ', ___EmotionJSX("a", {
  href: KNOWLEDGE_BASE_SLUGS.HELP_HOME,
  target: "_blank",
  rel: "noreferrer"
}, "Check out our Help Center documentation", ___EmotionJSX("span", {
  className: "fa fa-external-link"
})), ' or ', ___EmotionJSX("a", {
  href: `${getSlugPath()}/help`,
  onClick: openHelp
}, "get in touch!"));