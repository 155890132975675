import Logo from '@clubhouse/assets/png/third-party-logos/productboard_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { ProductboardIntegration } from 'components/integrations/productboard/ProductboardIntegration';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Productboard() {
  const admin = isLoggedInUserAdmin();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.PRODUCTBOARD
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Productboard"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Productboard Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX("p", null, ___EmotionJSX(ExternalLink, {
    href: "https://productboard.com/"
  }, "Productboard"), " helps product managers organize customer feedback and prioritize what to build next. With our Productboard integration, you can seamlessly connect your product discovery and planning to development work in ", BRAND.NAME, "."), ___EmotionJSX("p", null, "Once the integration is established, a Productboard Feature can be connected to a ", BRAND.NAME, " Epic. Visit our ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_PRODUCT_BOARD
  }, "Help Center"), " to learn more about setup and functionality.")), !admin ? ___EmotionJSX(Layout.HelpMessage, null, "Only admins can manage this integration.") : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Layout.SectionTitle, null, "Integration Status"), ___EmotionJSX(ProductboardIntegration, null))));
}
Productboard.displayName = "Productboard";