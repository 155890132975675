import Is from '../../../core/js/modules/is';
import IterationActionsController from './iterationActions';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
const exports = {};
exports.createStoryInIteration = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const iterationId = Utils.data($(this).closest('.iteration-card'), 'id');
  IterationActionsController.createStoryInIteration(iterationId);
  return false;
};
export { exports as default };