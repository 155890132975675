import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Controller', 'Story'], StoryController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import classnames from 'classnames';
import moment from 'moment';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { DATE_FORMAT } from '@clubhouse/shared/constants/dates';
import { copyToClipboard } from '@clubhouse/shared/utils';
import { EstimateSelect, StoryDialogTarget } from 'components/shared/EstimateSelect/EstimateSelect';
import { GroupMismatchIndicatorForOwners } from 'components/shared/GroupMismatchIndicator';
import { StoryTypeIcon } from 'components/shared/StoryTypeIcon';
import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { TYPE as SELECT_USERS_DROPDOWN_TYPE, SelectUsersDropdown } from 'components/shared/dropdowns/select-users';
import { useOptimizedEpic } from 'data/entity/epic';
import * as EstimateScale from 'data/entity/estimateScale';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { useOptimizedIteration } from 'data/entity/iteration';
import { getAllActiveProfileDetailsAndSpeciallyInclude, getCurrentUserProfileDetails } from 'data/entity/profile';
import { currentUserIsFollower, describeFollowersOrNull, getDeadlineClass, saveChanges, toBranchName, toggleOwner, useOptimizedStory } from 'data/entity/story';
import { isReadOnly, isSampleObserver } from 'data/entity/user';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { getById as getWorkflowStateById } from 'data/entity/workflowState';
import * as Integrations from 'utils/integrations';
import { getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { isUrl } from 'utils/validator';
import { CustomFieldAttributes } from './CustomFieldAttributes';
import { HotReload } from './HotReload';
import { StoryEpic } from './StoryEpic';
import { StoryId } from './StoryId';
import { StoryIteration } from './StoryIteration';
import { StoryJiraSync } from './StoryJiraSync';
import { StoryLabels } from './StoryLabels';
import { StoryPermalink } from './StoryPermalink';
import { TeamAndWorkflowSelector } from './TeamAndWorkflowSelector';
import { jsx as ___EmotionJSX } from "@emotion/react";
const useCopyBranchNameKeyboardShortcut = story => {
  useCommandBarCallback('copyStoryBranchName', () => {
    if (!story) return;
    const branchName = toBranchName(story);
    if (branchName) {
      copyToClipboard(branchName);
      addToast({
        kind: 'success',
        Content: () => ___EmotionJSX(ToastText, null, "Branch name copied to clipboard!"),
        timeout: 2500
      });
    }
  });
};
const useCopyBranchNameAndMoveToStartedKeyboardShortcut = story => {
  useCommandBarCallback('copyStoryBranchNameAndMoveToStarted', async () => {
    if (!story) return;
    let branchCopied = false;
    let workflowStateChanged = false;
    const workflow = getWorkflowById(story.workflow_id);
    const workflowState = getWorkflowStateById(story.workflow_state_id);
    if (workflow && (!workflowState || workflowState.type !== 'started')) {
      const newWorkflowState = workflow.states.find(s => s.type === 'started');
      try {
        if (newWorkflowState) {
          saveChanges(story.id, {
            workflow_state_id: newWorkflowState.id
          });
          workflowStateChanged = true;
        }
      } catch {}
    }
    const branchName = toBranchName(story);
    if (branchName) {
      copyToClipboard(branchName);
      branchCopied = true;
    }
    if (!branchCopied && !workflowStateChanged) return;
    addToast({
      kind: 'success',
      Content: () => branchCopied && workflowStateChanged ? ___EmotionJSX(ToastText, null, "Branch name copied to clipboard and story moved to Started.") : workflowStateChanged ? ___EmotionJSX(ToastText, null, "Story moved to Started.") : ___EmotionJSX(ToastText, null, "Branch name copied to clipboard!"),
      timeout: 2500
    });
  });
};
const useToggleCurrentUserAsOwner = story => {
  useCommandBarCallback('toggleCurrentUserAsOwner', () => {
    if (!story) return;
    if (isReadOnly()) return;
    const currentUser = getCurrentUserProfileDetails();
    if (!currentUser) return;
    toggleOwner(story, currentUser);
  });
};
export const StoryAttributes = _ref2 => {
  let {
    storyId,
    onInviteRequester,
    onRequesterChange,
    onInviteOwner,
    onOwnerChange
  } = _ref2;
  // Note: Using `?? 0` below to avoid TS error without having to update all the useOptimized* hooks.
  // The result is the same since neither would return an entity.
  const story = useOptimizedStory(storyId);
  const iteration = useOptimizedIteration(story?.iteration_id ?? 0);
  const epic = useOptimizedEpic(story?.epic_id ?? 0);
  useCopyBranchNameKeyboardShortcut(story);
  useCopyBranchNameAndMoveToStartedKeyboardShortcut(story);
  useToggleCurrentUserAsOwner(story);
  if (!story) return null;
  const isVCSEnabled = Integrations.isVCSEnabled();
  const isValidUrl = isUrl(story.external_id);
  const requester = story.requested_by_id ? [story.requested_by_id] : [];
  return ___EmotionJSX("div", {
    className: classnames('story-attributes', {
      'github-enabled': isVCSEnabled
    })
  }, ___EmotionJSX(StoryId, {
    story: story
  }), ___EmotionJSX(StoryPermalink, {
    story: story
  }), ___EmotionJSX(StoryJiraSync, {
    story: story
  }), ___EmotionJSX("div", {
    className: "attribute-spacer"
  }), ___EmotionJSX(TeamAndWorkflowSelector, {
    story: story
  }), isProjectsFeatureEnabled() && ___EmotionJSX("div", {
    id: "story-project-dropdown-container",
    className: "attribute editable-attribute story-project",
    "data-tabindex": true,
    "data-controller": "Story",
    "data-on-click": "openStoryProjectUpdater",
    "data-value": story.project?.name
  }, story.project?.color ? ___EmotionJSX("span", {
    className: "color-box",
    style: {
      background: story.project.color
    }
  }) : ___EmotionJSX("span", {
    className: "color-box color-box--no-project"
  }), ___EmotionJSX("span", {
    "data-testid": "attribute-name",
    className: "name"
  }, "Project"), ___EmotionJSX("span", {
    "data-testid": "attribute-value",
    className: "value"
  }, story.project?.raw_name ? ___EmotionJSX("em", null, "story.project.raw_name") : story.project?.name ? story.project.name : ___EmotionJSX("em", null, "None"))), ___EmotionJSX("div", {
    className: "attribute-spacer"
  }), !!story.epic?.url && ___EmotionJSX(HotReload, {
    page: "epics",
    url: story.epic.url,
    tooltip: "View Epic Page"
  }), ___EmotionJSX(StoryEpic, {
    story: story,
    epic: epic
  }), usesIterations() && ___EmotionJSX(React.Fragment, null, !!iteration && ___EmotionJSX(HotReload, {
    page: "iterations",
    url: `${getSlugPath()}/iteration/${iteration.id}`,
    tooltip: "View Iteration Page"
  }), ___EmotionJSX(StoryIteration, {
    story: story,
    iteration: iteration
  })), ___EmotionJSX("div", {
    className: "attribute editable-attribute story-type",
    id: "story-dialog-story-type-dropdown",
    "data-tabindex": true,
    "data-controller": "Story",
    "data-on-click": "openStoryTypeDropdown",
    "data-value": story.story_type
  }, ___EmotionJSX("span", {
    className: "custom-icon"
  }, ___EmotionJSX(StoryTypeIcon, {
    storyType: story.story_type,
    size: 25
  })), ___EmotionJSX("span", {
    "data-testid": "attribute-name",
    className: "name"
  }, "Type"), ___EmotionJSX("span", {
    className: "value"
  }, story.story_type)), ___EmotionJSX("div", {
    className: "attribute-spacer"
  }), ___EmotionJSX("div", {
    id: "updateStoryRequesterOwnerFields"
  }, ___EmotionJSX("div", {
    className: "stacked-attribute",
    "data-component-property-key": "RequesterField",
    "data-component-key": "RequesterField"
  }, ___EmotionJSX(SelectUsersDropdown, {
    type: SELECT_USERS_DROPDOWN_TYPE.REQUESTER,
    title: "Update Story Requester",
    initialSelectedUserIds: requester,
    users: getAllActiveProfileDetailsAndSpeciallyInclude(requester),
    currentUser: getCurrentUserProfileDetails(),
    onSelectAndSave: onRequesterChange,
    isRequired: true,
    groupIds: story.group_id ? [story.group_id] : [],
    onInvite: onInviteRequester
  })), ___EmotionJSX("div", {
    className: "stacked-attribute",
    "data-component-property-key": "OwnerField",
    "data-component-key": "OwnerField"
  }, ___EmotionJSX(SelectUsersDropdown, {
    type: SELECT_USERS_DROPDOWN_TYPE.OWNER,
    title: "Update Story Owner",
    initialSelectedUserIds: story.owner_ids,
    users: getAllActiveProfileDetailsAndSpeciallyInclude(story.owner_ids),
    currentUser: getCurrentUserProfileDetails(),
    onSelectAndSave: onOwnerChange,
    isRequired: false,
    groupIds: story.group_id ? [story.group_id] : [],
    SelectedUserIndicator: _ref3 => {
      let {
        selectedUsers,
        index
      } = _ref3;
      if (index > 0) {
        return null;
      }
      return ___EmotionJSX(GroupMismatchIndicatorForOwners, {
        story: story,
        selectedMembers: selectedUsers,
        memberIndex: index
      });
    },
    onInvite: onInviteOwner
  }))), ___EmotionJSX("div", {
    className: "attribute-spacer"
  }), !EstimateScale.isDisabled() && ___EmotionJSX(EstimateSelect, {
    value: story.estimate,
    onChange: newEstimate => {
      saveChanges(storyId, {
        estimate: newEstimate
      });
    },
    Target: StoryDialogTarget
  }), ___EmotionJSX("div", {
    className: "story-deadline-container"
  }, story.formatted_deadline && ___EmotionJSX("div", {
    className: "attribute-toggle attribute-action"
  }, ___EmotionJSX("button", {
    "data-controller": "Story",
    "data-on-click": "clearDeadline",
    "data-tabindex": true,
    "data-tooltip": "Clear Due Date"
  }, ___EmotionJSX("span", {
    className: "fa fa-times"
  }))), ___EmotionJSX("div", {
    id: "story-dialog-deadline-dropdown",
    className: classnames('attribute', 'editable-attribute', 'attribute-has-toggle', 'story-deadline', 'condensed', getDeadlineClass(story)),
    "data-tabindex": true,
    "data-controller": "Story",
    "data-on-click": "openDeadlineDatepicker",
    "data-value": story.deadline
  }, ___EmotionJSX("span", {
    "data-testid": "attribute-name",
    className: "name"
  }, "Due"), ' ', ___EmotionJSX("span", {
    className: "value"
  }, story.formatted_deadline || ___EmotionJSX("em", null, "No date")))), ___EmotionJSX("div", {
    className: "story-followers-container"
  }, ___EmotionJSX("div", {
    className: "attribute-toggle"
  }, isSampleObserver() ? ___EmotionJSX("div", {
    className: "placeholder"
  }, ___EmotionJSX("span", {
    className: "fa fa-plus"
  })) : currentUserIsFollower(story) ? ___EmotionJSX("button", {
    "data-controller": "Story",
    "data-on-click": "removeMeAsFollower",
    "data-tooltip": "Remove Yourself as Follower",
    "data-tabindex": true
  }, ___EmotionJSX("span", {
    className: "fa fa-times"
  })) : ___EmotionJSX("button", {
    "data-controller": "Story",
    "data-on-click": "addMeAsFollower",
    "data-tooltip": "Follow this Story",
    "data-tabindex": true
  }, ___EmotionJSX("span", {
    className: "fa fa-plus"
  })), ___EmotionJSX("div", {
    id: "story-dialog-follower-dropdown",
    className: "attribute editable-attribute attribute-has-toggle story-followers condensed",
    "data-tabindex": true,
    "data-controller": "Story",
    "data-on-click": "openStoryFollowerUpdater"
  }, ___EmotionJSX("span", {
    "data-testid": "attribute-name",
    className: "name"
  }, "Followers"), ' ', ___EmotionJSX("span", {
    className: "value"
  }, describeFollowersOrNull(story) || ___EmotionJSX("em", null, "Nobody"))))), ___EmotionJSX("div", {
    className: "attribute-spacer"
  }), ___EmotionJSX(CustomFieldAttributes, {
    storyId: story.id
  }), ___EmotionJSX(StoryLabels, {
    story: story
  }), ___EmotionJSX("div", {
    className: "story-actions",
    "data-controller": "Story"
  }, isVCSEnabled && ___EmotionJSX("button", {
    className: "story-actions-button",
    id: "open-git-helpers-dropdown",
    "data-tabindex": true,
    "data-on-click": "openGitHelpers",
    "data-tooltip": "Git Helpers"
  }, ___EmotionJSX("span", {
    className: "fa fa-code-fork"
  })), ___EmotionJSX("button", {
    className: "story-actions-button",
    id: "open-story-actions-dropdown",
    "data-tabindex": true,
    "data-on-click": "openStoryActionsDropdown",
    "data-tooltip": "Story Actions"
  }, ___EmotionJSX("span", {
    className: "fa fa-ellipsis-h"
  }))), ___EmotionJSX("div", {
    className: "attribute story-date-created"
  }, ___EmotionJSX("span", {
    className: "name"
  }, "Created"), ___EmotionJSX("span", {
    className: "value"
  }, moment(story.created_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT))), story.completed_at && ___EmotionJSX("div", {
    className: "attribute story-date-completed",
    "data-updated-at": story.completed_at
  }, ___EmotionJSX("span", {
    className: "name"
  }, "Completed"), ___EmotionJSX("span", {
    className: "value"
  }, moment(story.completed_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT))), ___EmotionJSX("div", {
    className: "attribute story-date-updated",
    "data-updated-at": story.updated_at
  }, ___EmotionJSX("span", {
    className: "name"
  }, "Last updated"), ___EmotionJSX("span", {
    className: "value"
  }, moment(story.updated_at).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT))), story.external_id && ___EmotionJSX("div", {
    className: "attribute external-id"
  }, ___EmotionJSX("span", {
    className: "name"
  }, "External ID", ' ', ___EmotionJSX("span", {
    className: "fa fa-question-circle",
    "data-tooltip": `An External ID is set using the ${BRAND.NAME} API only, and is often used during an import to link the Story to an external resource in another tool.`
  })), ___EmotionJSX("span", {
    className: "value"
  }, !isValidUrl ? story.external_id : ___EmotionJSX("a", {
    href: story.external_id,
    target: "_blank",
    rel: "noopener noreferrer"
  }, story.external_id))));
};
StoryAttributes.displayName = "StoryAttributes";