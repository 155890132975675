import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '../../colors';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Add" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Plus = _ref => {
  let {
    fill = COLORS.BLACK,
    width = '10px'
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill
  }, ___EmotionJSX(Icon, {
    icon: "Add"
  }));
};
Plus.displayName = "Plus";