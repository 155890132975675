function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.push.js";
import EpicsController from 'app/client/core/js/controllers/epics.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Epics'], EpicsController], [['Tooltip'], Tooltip], [['Controller', 'Epics'], EpicsController], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { getTokens } from '@useshortcut/shapes-ds';
import { EpicIcon } from '@clubhouse/shared/components/EpicIcon';
import { sanitizeAndEmojify } from '@clubhouse/shared/utils';
import { generateURL } from 'data/entity/epic';
import { useCollectionizeEpicContextMenu } from 'utils/contextMenus/useCollectionizeEpicContextMenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
const tokens = getTokens();
function _EpicAutoLink(_ref2) {
  let {
    epic,
    showTooltip
  } = _ref2;
  const contextMenuProps = useCollectionizeEpicContextMenu(epic.id);
  return ___EmotionJSX("a", _extends({
    href: epic.url || generateURL(epic),
    className: "entity-link-with-tooltip entity-title-link"
  }, showTooltip ? {
    'data-model': 'Epic',
    'data-id': epic.id,
    'data-tooltip': true,
    'data-tooltip-fn': 'App.Controller.Epics.renderEpicTooltip'
  } : {}, contextMenuProps), ___EmotionJSX("span", {
    className: "force-left-padding"
  }), ___EmotionJSX("div", {
    style: {
      marginRight: tokens.space.space1
    }
  }, ___EmotionJSX(EpicIcon, {
    epic: epic,
    size: 18
  }), ' '), ___EmotionJSX("span", {
    dangerouslySetInnerHTML: {
      __html: epic.formatted_name || sanitizeAndEmojify(epic.name)
    }
  }));
}
_EpicAutoLink.displayName = "_EpicAutoLink";
export const EpicAutoLink = _ref3 => {
  let {
    epic,
    showTooltip = false
  } = _ref3;
  if (!epic) return ___EmotionJSX("em", null, "Deleted Epic");
  return ___EmotionJSX(_EpicAutoLink, {
    epic: epic,
    showTooltip: showTooltip
  });
};
EpicAutoLink.displayName = "EpicAutoLink";