import { ToastProvider } from '@clubhouse/shared/components/Toast';
import { UserThemeProvider } from '@clubhouse/shared/utils/theme';
import { LaunchDarklyProvider } from 'utils/launch-darkly';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const AppContext = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(LaunchDarklyProvider, null, ___EmotionJSX(UserThemeProvider, null, ___EmotionJSX(ToastProvider, null, children)));
};
AppContext.displayName = "AppContext";