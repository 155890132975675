import "core-js/modules/es.array.push.js";
import ProfileModel from 'app/client/core/js/models/profile.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Profile'], ProfileModel], [['Model', 'Profile'], ProfileModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@useshortcut/shapes-ds';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { capitalize } from '@clubhouse/shared/utils';
import { RoleDropdown } from 'components/shared/RoleDropdown';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOnly, ownerOnly } from 'utils/is';
import { jsx as ___EmotionJSX } from "@emotion/react";
function ActiveUser(_ref2) {
  let {
    user,
    scimEnabled,
    onUpdateRole
  } = _ref2;
  const me = getLoggedInUserPermission();
  const [isSaving, toggle] = useToggleState();
  return ___EmotionJSX("div", {
    className: "profile-container",
    "data-model": "Profile",
    "data-id": user.id
  }, ___EmotionJSX(Avatar, {
    profile: user
  }), ___EmotionJSX("div", {
    className: "info-container"
  }, ___EmotionJSX("div", {
    className: "name"
  }, user.name, ' ', ownerOnly(user) && ___EmotionJSX(Chip, {
    kind: "blue"
  }, ___EmotionJSX("span", {
    className: "owner-indicator-title"
  }, "Owner"))), ___EmotionJSX("div", {
    className: "username"
  }, "@", user.mention_name), ___EmotionJSX("div", {
    className: "email"
  }, ___EmotionJSX("a", {
    href: `mailto:${user.email_address}`
  }, user.email_address))), ___EmotionJSX("div", {
    className: "two-factor-auth-container"
  }, ___EmotionJSX("span", {
    className: `fa fa-key two-factor-auth-${user.two_factor_auth_activated ? 'enabled' : 'disabled'}`,
    "data-tooltip": `Two-factor authentication ${user.two_factor_auth_activated ? '' : 'not '}enabled.`
  })), ___EmotionJSX("div", {
    className: "role-dropdown-container"
  }, !scimEnabled && user.id !== me.id && (ownerOnly(me) || adminOnly(me) && !ownerOnly(user)) ? ___EmotionJSX(RoleDropdown, {
    value: user.role,
    isDisabled: isSaving,
    onItemSelected: async newRole => {
      toggle.on();
      try {
        await onUpdateRole(user.id, newRole);
      } finally {
        toggle.off();
      }
    }
  }) : scimEnabled ? ___EmotionJSX("div", {
    className: "role-dropdown disabled",
    "data-tooltip": "Roles are managed through your identity provider."
  }, capitalize(user.role)) : user.id === me.id ? ___EmotionJSX("div", {
    className: "role-dropdown disabled",
    "data-tooltip": "You cannot change your own role. Ask another admin or owner to change your role for you."
  }, capitalize(user.role)) : ownerOnly(user) ? ___EmotionJSX("div", {
    className: "role-dropdown disabled",
    "data-tooltip": "Only owners can change the role of other owners."
  }, capitalize(user.role)) : ___EmotionJSX("div", {
    className: "role-dropdown disabled",
    "data-tooltip": "Only admins and owners can change roles."
  }, capitalize(user.role))), !scimEnabled && (ownerOnly(me) || adminOnly(me) && !ownerOnly(user)) && ___EmotionJSX("div", {
    className: "actions"
  }, user.id !== me.id && ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(UnstyledButton, {
    className: "disable-user",
    "data-on-click": "disableUser",
    "data-tooltip": `Disable ${user.name}`
  }, ___EmotionJSX(DeprecatedIconAdapter, null, ___EmotionJSX(Icon, {
    icon: "Clear"
  }))))));
}
ActiveUser.displayName = "ActiveUser";
export function ActiveUsers(_ref3) {
  let {
    users,
    scimEnabled,
    onUpdateRole
  } = _ref3;
  return ___EmotionJSX(React.Fragment, null, users.map(user => ___EmotionJSX(ActiveUser, {
    key: user.id,
    user: user,
    scimEnabled: scimEnabled,
    onUpdateRole: onUpdateRole
  })));
}