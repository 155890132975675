import { useEffectOnce } from 'react-use';
import { useIsMobile } from '@clubhouse/shared/hooks';
import { getLoggedInUserPermission, useCurrentUser } from 'data/entity/user';
import { withinOrgAndWithSlug } from 'utils/is';
import { EVENTS, logEvent } from 'utils/monitoring';
import { FEATURE_TOGGLES, useVariation } from './launch-darkly';
export function doesUserHaveQuickstart() {
  const permission = getLoggedInUserPermission();
  return permission.show_quickstart;
}
export const useShowQuickstart = () => {
  useCurrentUser();
  const {
    value: usesPyneAi
  } = useVariation(FEATURE_TOGGLES.PYNE_ROLLOUT);
  const showQuickstart = !useIsMobile() && withinOrgAndWithSlug() && !usesPyneAi;
  return showQuickstart && doesUserHaveQuickstart();
};
const importDependencies = () => Promise.all([import( /* webpackChunkName: "quickstart" */'components/quickstart'), import( /* webpackChunkName: "quickstart" */'data/entity/quickstart'), import( /* webpackChunkName: 'quickstart' */'data/store')]);
export const simpleCompleteTask = async taskName => {
  if (!doesUserHaveQuickstart()) return;
  const [{
    completeTask
  }, {
    isTaskNameValid
  }] = await importDependencies();
  if (isTaskNameValid(taskName)) {
    return completeTask(taskName);
  }
};
export const useAutoQuickstartComplete = taskName => {
  useEffectOnce(() => {
    simpleCompleteTask(taskName);
  });
};
export const toggleQuickstartDialog = async newVal => {
  const {
    store,
    quickstartSlice
  } = await import( /* webpackChunkName: "quickstart" */'data/store');
  store.dispatch(quickstartSlice.actions.toggleDialog(newVal));
};
export const openQuickstartDialog = async () => {
  toggleQuickstartDialog(true);
};
export const closeQuickstartDialog = async () => {
  toggleQuickstartDialog(false);
  logEvent(EVENTS.Quickstart_Closed);
};
const toggleQuickstartVideoModal = async newVal => {
  const {
    store,
    quickstartSlice
  } = await import( /* webpackChunkName: "quickstart" */'data/store');
  store.dispatch(quickstartSlice.actions.toggleVideoModal(newVal));
};
export const openQuickstartVideoModal = async () => {
  toggleQuickstartVideoModal(true);
};
export const closeQuickstartVideoModal = async () => {
  toggleQuickstartVideoModal(false);
};
export const initQuickstart = async () => {
  openQuickstartDialog();
};