import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getCurrentOrgId, isOrgDisabled } from 'data/entity/organization';
import { getApolloClient } from 'gql/utils/initApollo';
import { trigger } from 'utils/event';
import { setGlobal } from 'utils/globals';
import { FEATURE_TOGGLES, useVariation } from 'utils/launch-darkly';
import { getValue, setValue } from 'utils/localStorage';
import { PAGE_NAMES, getCurrentPage } from 'utils/navigation';
import { hideOfflineAlert, showOfflineAlert } from 'utils/offline';
import { getUpdatesUrl } from 'utils/updates';
import { useWorkspace2PublicId } from '../../hooks/useWorkspace2PublicId';
import { DataLayerUpdatesContext } from './DataLayerUpdatesContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
const getUrl = () => {
  const path = '/api/private/sse-updates';
  return getUpdatesUrl(path);
};
const clearCache = async () => {
  const client = await getApolloClient();
  await client?.cache.reset();
};
const getDbTime = () => getValue('datalayer-db-time', {
  defaultValue: 0
});
const setDbTime = dbTime => {
  const numDbTime = dbTime !== null ? Number(dbTime) : null;
  if (typeof numDbTime === 'number') setValue('datalayer-db-time', dbTime);
};
const getState = s => s === EventSource.OPEN ? 'open' : s === EventSource.CONNECTING ? 'connecting' : 'closed';
const parseUpdatePayload = payload => (payload || '').split('\n').map(str => {
  try {
    return JSON.parse(str.trim());
  } catch {
    return null;
  }
}).filter(Boolean);
const createEventSource = (url, onMessage, onStateChange) => {
  const eventSource = new EventSource(url, {
    withCredentials: true
  });
  onStateChange(getState(eventSource.readyState));
  eventSource.addEventListener('error', () => onStateChange(getState(eventSource.readyState)));
  eventSource.addEventListener('open', () => onStateChange(getState(eventSource.readyState)));
  eventSource.addEventListener('reset', event => onMessage({
    type: 'reset',
    data: null,
    dbTime: event.lastEventId
  }));
  eventSource.addEventListener('update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'update',
      data,
      dbTime: event.lastEventId
    });
  });
  eventSource.addEventListener('legacy-update', event => {
    const data = parseUpdatePayload(event.data);
    if (data?.length) onMessage({
      type: 'legacy-update',
      data,
      dbTime: event.lastEventId
    });
  });
  return eventSource;
};
const getEventSourceUrl = workspaceId => {
  return `${getUrl()}/${workspaceId}/${getDbTime()}?legacy-updates=1`;
};
const isBillingPageAndOrgOrWorkspaceIsDisabled = () => {
  return getCurrentPage() === PAGE_NAMES.SETTINGS_MANAGE_BILLING && isOrgDisabled(getCurrentOrgId());
};
export function DataLayerUpdatesSSEProvider(_ref) {
  let {
    children
  } = _ref;
  const publicId = useWorkspace2PublicId();
  const {
    value: datalayerUpdatesEndpointEnabled
  } = useVariation(FEATURE_TOGGLES.DATALAYER_UPDATES_ENDPOINT);
  const listenersRef = useRef(new Set());
  useEffect(() => {
    if (!datalayerUpdatesEndpointEnabled || typeof publicId !== 'string' || isBillingPageAndOrgOrWorkspaceIsDisabled()) return;
    const eventSource = createEventSource(getEventSourceUrl(publicId), async _ref2 => {
      let {
        type,
        data,
        dbTime
      } = _ref2;
      switch (type) {
        case 'reset':
          {
            setDbTime(dbTime);
            await clearCache();
            break;
          }
        case 'update':
          {
            setDbTime(dbTime);
            listenersRef.current.forEach(fn => fn(data));
            break;
          }
        case 'legacy-update':
          {
            trigger('datalayer-update', {
              data,
              dbTime
            });
            break;
          }
      }
    }, state => {
      console.debug('SSE - EventSource state changed: ', state);
      switch (state) {
        case 'open':
          setGlobal('enable-datalayer-updates', true);
          trigger('enable-datalayer', true);
          break;
        case 'closed':
          setGlobal('enable-datalayer-updates', false);
          trigger('enable-datalayer', false);
          break;
      }
    });
    return () => {
      trigger('enable-datalayer', false);
      eventSource.close();
    };
  }, [datalayerUpdatesEndpointEnabled, publicId]);
  useEffect(() => {
    window.addEventListener('online', hideOfflineAlert);
    window.addEventListener('offline', showOfflineAlert);
    return () => {
      window.removeEventListener('online', hideOfflineAlert);
      window.removeEventListener('offline', showOfflineAlert);
    };
  }, []);
  const registerListener = useCallback(fn => {
    listenersRef.current.add(fn);
  }, []);
  const unregisterListener = useCallback(fn => {
    listenersRef.current.delete(fn);
  }, []);
  return ___EmotionJSX(DataLayerUpdatesContext.Provider, {
    value: useMemo(() => ({
      registerListener,
      unregisterListener
    }), [registerListener, unregisterListener])
  }, children);
}
DataLayerUpdatesSSEProvider.displayName = "DataLayerUpdatesSSEProvider";