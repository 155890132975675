import Logo from '@clubhouse/assets/png/third-party-logos/bugsnag_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Bugsnag() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.BUGSNAG
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Bugsnag"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Bugsnag Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.bugsnag.com/"
  }, "Bugsnag"), " provides focused and powerful error monitoring for web, mobile, and server apps."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_BUGSNAG
  }, "our instructions"), " for how to configure this integration in your Bugsnag account.")));
}
Bugsnag.displayName = "Bugsnag";