function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.push.js";
import GroupController from 'app/client/core/js/controllers/group.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Group'], GroupController], [['Controller', 'Group'], GroupController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { cannotEndWithAPeriod, combineValidators, isUsername, maxLength, notEmpty, sanitizeAndEmojify, slugify } from '@clubhouse/shared/utils';
import Icons from 'components/shared/Icon';
import { CreateEntityDialogForm } from 'components/shared/forms/CreateEntityDialogForm';
import { ColorField } from 'components/shared/forms/fields/ColorField';
import { DescriptionField } from 'components/shared/forms/fields/DescriptionField';
import { MentionField } from 'components/shared/forms/fields/MentionField';
import { ProfileIconField } from 'components/shared/forms/fields/ProfileIconField';
import { StringField } from 'components/shared/forms/fields/StringField';
import { UserField, UserListDropdownTrigger } from 'components/shared/forms/fields/UserField';
import { WorkflowField } from 'components/shared/forms/fields/WorkflowField';
import { all, create as createGroup, getEmptyGroup } from 'data/entity/group';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { verifyWorkflowIds } from 'data/entity/workflow';
import { GroupDisplay } from 'data/typeConfigs';
import { getAppState } from 'utils/appState';
import { info as infoToast } from 'utils/message';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateOnClick } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const uniqueTeamName = data => {
  const name = data?.trim();
  if (!name) return undefined;
  const isUnique = all().every(group => group.name !== name);
  return isUnique ? undefined : `Team "${name}" already exists.`;
};
const UserFieldWithListTrigger = props => {
  return ___EmotionJSX(UserField, _extends({}, props, {
    DropdownTrigger: UserListDropdownTrigger,
    title: "Select Members"
  }));
};
UserFieldWithListTrigger.displayName = "UserFieldWithListTrigger";
const WorkflowFieldWithTrigger = _ref2 => {
  let {
    onClose,
    ...props
  } = _ref2;
  const handleOnCreateClick = () => {
    onClose();
    infoToast('Your new Team has not been lost. Open the Team to continue.', {
      actions: `<button class="action mini elevated" data-controller="Group" data-on-click="openCreateDialog">
        Open Team
      </button>`,
      id: 'create-team-saved'
    });
  };
  const isAdmin = isLoggedInUserAdmin();
  return ___EmotionJSX(WorkflowField, _extends({
    label: isAdmin ? 'Select or Create Workflow' : 'Select Workflow',
    maxSelected: null,
    footerType: isAdmin ? 'create' : 'none',
    onCreateClick: handleOnCreateClick
  }, props));
};
WorkflowFieldWithTrigger.displayName = "WorkflowFieldWithTrigger";
const SuccessMessage = _ref3 => {
  let {
    value
  } = _ref3;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, ___EmotionJSX("div", null, "Team ", ___EmotionJSX("strong", null, sanitizeAndEmojify(value.name)), " created."), ___EmotionJSX(Button, {
    onClick: navigateOnClick(generatePathWithSlug(getPathForPage(PAGE_NAMES.SETTINGS_TEAM), {
      id: value.id
    })),
    size: Button.SIZE.MEDIUM,
    kind: Button.KIND.SECONDARY,
    IconLeft: Icons.Eye
  }, "View Team"));
};
SuccessMessage.displayName = "SuccessMessage";
const CACHE_KEY = 'CreateGroup.Partial';
export const CreateTeamForm = _ref4 => {
  let {
    onClose,
    onCreate,
    triggerNode,
    save = createGroup
  } = _ref4;
  const saveWrapper = _ref5 => {
    let {
      changes
    } = _ref5;
    return save(changes);
  };
  const incompleteGroup = getAppState(CACHE_KEY) ?? {};
  if (incompleteGroup?.workflow_ids?.length > 0 && verifyWorkflowIds(incompleteGroup.workflow_ids)) {
    incompleteGroup.workflow_ids = [];
  }
  const initialGroup = {
    ...getEmptyGroup(),
    ...incompleteGroup
  };
  const rightFields = [{
    name: 'color_key',
    Component: ColorField
  }, {
    name: 'workflow_ids',
    Component: props => ___EmotionJSX(WorkflowFieldWithTrigger, _extends({}, props, {
      onClose: onClose
    })),
    validate: combineValidators([notEmpty]),
    labelProps: {
      children: '',
      helpText: 'Connect a Team to a Workflow to ensure all Stories follow a consistent process.'
    }
  }, {
    name: 'member_ids',
    Component: UserFieldWithListTrigger
  }].filter(Boolean);
  return ___EmotionJSX(CreateEntityDialogForm, {
    cacheKey: CACHE_KEY,
    dialogProps: {
      close: onClose,
      triggerNode
    },
    toastProps: {
      success: _ref6 => {
        let {
          value
        } = _ref6;
        return ___EmotionJSX(SuccessMessage, {
          value: value
        });
      },
      failure: _ref7 => {
        let {
          e
        } = _ref7;
        if (e) {
          return e.responseIntercepted ? null : ___EmotionJSX("div", null, typeof e === 'string' ? e : e.message);
        }
        return null;
      }
    },
    entityFormProps: {
      onCreate,
      initialEntity: initialGroup,
      leftFields: [{
        name: 'display_icon',
        labelProps: {
          children: 'Profile Icon'
        },
        Component: ProfileIconField
      }, {
        name: 'name',
        labelProps: {
          children: 'Name'
        },
        validate: combineValidators([notEmpty, maxLength(63), uniqueTeamName]),
        Component: StringField
      }, {
        name: 'mention_name',
        labelProps: {
          children: 'Mention Name',
          helpText: 'Used for @-mentions within this workspace only.'
        },
        validate: combineValidators([notEmpty, isUsername, cannotEndWithAPeriod, maxLength(63)]),
        Component: props => {
          const form = useForm();
          const name = useField('name');
          useEffect(() => {
            form.change('mention_name', slugify(name.input.value, {
              limit: 63
            }));
          }, [form, name.input.value]);
          return ___EmotionJSX(MentionField, props);
        }
      }, {
        name: 'description',
        labelProps: {
          children: 'Description',
          helpText: 'Optional.'
        },
        Component: DescriptionField
      }],
      rightFields,
      save: saveWrapper,
      typeConfig: GroupDisplay
    }
  });
};
CreateTeamForm.displayName = "CreateTeamForm";