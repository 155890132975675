import BarLoaderReverse from '@clubhouse/assets/gif/bar_loader_reverse.gif';
import { LoadingState } from './shared/LoadingState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const UndoingPage = () => ___EmotionJSX(LoadingState, {
  title: ___EmotionJSX("span", {
    style: {
      fontSize: 18
    }
  }, "Oh no! Your import has failed."),
  message: ___EmotionJSX(React.Fragment, null, "Please wait. We're undoing the import and removing all imported data.", ___EmotionJSX("br", null), " Please contact ", ___EmotionJSX("a", {
    href: `mailto:${BRAND.SUPPORT_EMAIL}`
  }, BRAND.SUPPORT_EMAIL), " with any questions."),
  hero: ___EmotionJSX("img", {
    "data-perma-id": "undoing-page-hero",
    src: BarLoaderReverse,
    alt: ""
  })
});
UndoingPage.displayName = "UndoingPage";