import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Arrow = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX("svg", {
    width: width,
    height: width,
    viewBox: "0 0 11 12",
    fill: fill
  }, ___EmotionJSX("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11 5.82139C11 6.09049 10.9079 6.31719 10.7236 6.50148L5.8587 11.3663C5.6642 11.5506 5.43751 11.6428 5.17861 11.6428C4.92481 11.6428 4.70067 11.5506 4.50617 11.3663L3.94565 10.8058C3.75626 10.6164 3.66157 10.3897 3.66157 10.1257C3.66157 9.86174 3.75626 9.63504 3.94565 9.44565L6.13513 7.25617H0.874261C0.615362 7.25617 0.404928 7.16275 0.242957 6.97591C0.0809855 6.78907 0 6.56365 0 6.29965V5.34313C0 5.07913 0.0809855 4.85371 0.242957 4.66687C0.404928 4.48003 0.615362 4.38661 0.874261 4.38661H6.13513L3.94565 2.18948C3.75626 2.01029 3.66157 1.78614 3.66157 1.51704C3.66157 1.24794 3.75626 1.0238 3.94565 0.844609L4.50617 0.284087C4.69557 0.0946957 4.91971 0 5.17861 0C5.44261 0 5.6693 0.0946957 5.8587 0.284087L10.7236 5.14896C10.9079 5.32304 11 5.54719 11 5.82139Z",
    fill: fill
  }));
};
Arrow.displayName = "Arrow";