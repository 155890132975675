import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { WithFullStory } from 'components/shared/ContextualStoryCard';
import { isDoneState } from 'data/entity/story';
import * as Is from 'utils/is';
import { StoryListItem } from '../../shared/StoryListItem';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { StorySearchCardAdapter } from './StorySearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryResults = _ref => {
  let {
    showCards,
    fetchProps: {
      loading,
      data: allStories,
      previousData,
      hasFetched,
      next
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick,
    excludeStories = []
  } = _ref;
  if (loading && !hasFetched) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SkeletonSearchCard, {
      icon: "Feature",
      kind: "yellow"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Bug",
      kind: "red"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Chore",
      kind: "blue"
    }));
  }
  const stories = (loading ? previousData : allStories)?.filter(story => story && !excludeStories.some(s => s.id === story.id)) || [];
  if (hasFetched && !stories?.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Stories"
    });
  }
  const StoryComponent = showCards ? StorySearchCardAdapter : StoryListItem;
  return ___EmotionJSX(React.Fragment, null, (stories || []).map((story, index) => ___EmotionJSX(WithFullStory, {
    key: story.id,
    component: StoryComponent,
    story: story,
    onClick: entity => onClick?.(entity, index),
    openModalOnClick: openModalOnClick,
    showReveal: !story.archived && Is.storiesPage(),
    isDone: isDoneState(story),
    level: "3"
  })), next && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: loadMore
  }));
};