/**
 * TODO
 * - Migrate to StoryCardInternal.GroupAndOwners.Group
 */

import { GroupProfileIconTooltip } from '../../../../GroupProfileIconTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SIZE = '24px';
export const Group = _ref => {
  let {
    groupId
  } = _ref;
  return ___EmotionJSX(GroupProfileIconTooltip, {
    width: SIZE,
    height: SIZE,
    groupId: groupId
  });
};
Group.displayName = "Group";