import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';

/**
 * @deprecated
 * This component is needed for "Create Story" dialog because it doesn't use React. This should be removed once it is in React.
 * (See StoryAttributes used for Story dialog for an alternative implementation)
 */
export function NewStoryKeyboardShortcuts(_ref) {
  let {
    toggleCurrentUserAsOwner,
    openIterationDropdown,
    openStateDropdown,
    openEstimateDropdown
  } = _ref;
  useCommandBarCallback('toggleCurrentUserAsOwner', toggleCurrentUserAsOwner);
  useCommandBarCallback('openIterationSelector', openIterationDropdown);
  useCommandBarCallback('openWorkflowStateSelector', openStateDropdown);
  useCommandBarCallback('openEstimateSelector', openEstimateDropdown);
  return null;
}