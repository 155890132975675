import Logo from '@clubhouse/assets/png/third-party-logos/roadmunk_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Roadmunk() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.ROADMUNK
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Roadmunk"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Roadmunk Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Connect to ", ___EmotionJSX(ExternalLink, {
    href: "https://www.roadmunk.com/"
  }, "Roadmunk"), " and visualize Shortcut entities in a roadmap."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://support.roadmunk.com/hc/en-us/articles/10274425506967-Setting-Up-the-Shortcut-Integration"
  }, "Learn more"), ' ', "on how the Roadmunk integration works with Shortcut.")));
}
Roadmunk.displayName = "Roadmunk";