import { useMemo } from 'react';
import ProfileModel from 'app/client/core/js/models/profile';
import { useEntities } from './collection';
export const findProfileByEmail = email => ProfileModel.findBy({
  email_address: email
});
export const getProfileByUsername = username => ProfileModel.getDetailsByUsername(username);
export const getProfileByGlobalId = globalId => ProfileModel.get({
  global_id: globalId
});
export const getProfileById = id => ProfileModel.getById(id);
export const getProfilesByIds = ids => ProfileModel.mapIDsToProfileDetails(ids);
export const getAllActiveProfileDetails = function () {
  return ProfileModel.getAllActiveProfileDetails(...arguments);
};
export const getAllActiveProfileDetailsAndSpeciallyInclude = function () {
  return ProfileModel.getAllActiveProfileDetailsAndSpeciallyInclude(...arguments);
};
export const getAllActiveOwners = id => ProfileModel.getAllActiveOwners(id);
export const getCurrentUserProfileDetails = function () {
  return ProfileModel.getCurrentUserProfileDetails(...arguments);
};
export const getAllDetailsById = function () {
  return ProfileModel.getAllDetailsById(...arguments);
};
export const getProfileDetails = rawProfile => ProfileModel.mapProfileDetails(rawProfile);
export const isActiveProfile = profile => Boolean(profile) && !profile.disabled && !profile.deactivated;
export const useProfiles = function () {
  let {
    ids
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    entities
  } = useEntities({
    model: ProfileModel
  });
  const filtered = useMemo(() => {
    if (!ids) return entities;
    return entities.filter(details => {
      if (!profileMatchesIds(details, ids)) return false;

      // Add additional filters here

      return true;
    });
  }, [entities, ids]);
  const profiles = filtered.map(getProfileDetails);
  return {
    profiles
  };
};
const profileMatchesIds = (_ref, ids) => {
  let {
    id
  } = _ref;
  return ids.includes(id);
};
export const useOptimizedProfiles = _ref2 => {
  let {
    filter
  } = _ref2;
  // TODO: This should use useOptimizedEntities, but currently doesn't work because it requires the full object for rendering
  let {
    entities: profiles
  } = useEntities({
    model: ProfileModel
  });
  if (filter) {
    profiles = filter(profiles);
  }
  return profiles.map(getProfileDetails);
};
export const groupStoriesByOwnerId = _ref3 => {
  let {
    stories = []
  } = _ref3;
  return stories.reduce((acc, story) => {
    const profiles = getProfilesByIds(story.owner_ids || []);
    profiles.forEach(profile => {
      const current = acc[profile?.id];
      acc[profile?.id] = {
        ...current,
        profile,
        count: (current?.count || 0) + 1
      };
    });
    return acc;
  }, {});
};
export const getAllRoles = () => ProfileModel.getAllRoles();