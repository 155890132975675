import { FilterField } from '@clubhouse/shared/components/FilterField';
import { TableColumnSelect } from '@clubhouse/shared/components/Table/TableColumnSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TableColumnSelectFilterField(_ref) {
  let {
    permaId
  } = _ref;
  return ___EmotionJSX(FilterField, {
    label: "Display",
    permaId: permaId
  }, ___EmotionJSX(TableColumnSelect, null));
}
TableColumnSelectFilterField.displayName = "TableColumnSelectFilterField";