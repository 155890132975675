import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TableText = _ref => {
  let {
    text
  } = _ref;
  return ___EmotionJSX(Text, {
    textAlign: "center",
    size: Text.SIZE.SMALL,
    lineClamp: 2
  }, text || '-');
};
TableText.displayName = "TableText";