function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useAsyncFn, usePromise } from 'react-use';
import { Tooltip } from '.';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function AsyncTooltip(_ref) {
  let {
    fetchContent,
    children,
    delay = 100,
    ...rest
  } = _ref;
  const mounted = usePromise();
  const [content, fetchTooltipContent] = useAsyncFn(async () => mounted(fetchContent()), [mounted, fetchContent]);
  return ___EmotionJSX(Tooltip, _extends({
    content: content.value ?? null,
    onHover: fetchTooltipContent,
    delay: delay
  }, rest), children);
}
AsyncTooltip.displayName = "AsyncTooltip";