import { DockedEpicFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref } from 'components/gql/epics/links';
import { useCollectionizeEpicContextMenu } from 'utils/contextMenus/useCollectionizeEpicContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DockedEpicFragment = DockedEpicFragmentDoc;
export const isEpic = node => {
  return node?.__typename === 'Epic';
};
export function DockedEpic(_ref) {
  let {
    node,
    index,
    onUnpinClicked
  } = _ref;
  const url = getHref(node.publicId);
  const contextMenuProps = useCollectionizeEpicContextMenu(node.publicId);
  return ___EmotionJSX(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Epic",
      fill: "interactive",
      size: 14
    }),
    title: ___EmotionJSX(Title, {
      url: url
    }, node.epicName),
    contextMenuProps: contextMenuProps
  });
}
DockedEpic.displayName = "DockedEpic";