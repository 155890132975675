import { useTheme } from '@emotion/react';
import { ExclamationTriangle } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const InvalidWorkflowStateIcon = () => {
  const theme = useTheme();
  return ___EmotionJSX(Tooltip, {
    maxWidth: 370,
    content: "Story must be moved into one of the Team's Workflows"
  }, ___EmotionJSX("span", {
    "aria-label": "Invalid workflow"
  }, ___EmotionJSX(ExclamationTriangle, {
    fill: theme.iconRedColor,
    width: 16
  })));
};
InvalidWorkflowStateIcon.displayName = "InvalidWorkflowStateIcon";