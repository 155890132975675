import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { usesIterations } from 'utils/tests';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationChip = () => {
  const story = useContext(StoryContext);
  if (!story || !usesIterations() || !story.iteration) return null;
  const {
    iteration,
    previous_iteration_ids: previousIterationIds
  } = story;
  return ___EmotionJSX(StoryCardInternal.Chips.IterationChip, {
    iterationName: iteration.name,
    iterationStatus: iteration.status,
    previousIterationsCount: previousIterationIds?.length
  });
};
IterationChip.displayName = "IterationChip";