function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { ConfirmationDialog as SharedConfirmationDialog } from '@clubhouse/shared/components/ConfirmationDialog/ConfirmationDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ConfirmationDialog = () => {
  const {
    isConfirmationDialogOpen,
    dialogProps,
    closeDialog
  } = useConfirmationDialogState();
  return dialogProps ? ___EmotionJSX(SharedConfirmationDialog, _extends({}, dialogProps, {
    onClose: closeDialog,
    visible: isConfirmationDialogOpen
  })) : null;
};