import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Icon } from '@useshortcut/shapes-ds';
import { DetailPageSidebarRow, IconContainer } from './DetailPageSidebarRow';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DetailPageSidebarStatsRow(_ref) {
  let {
    label,
    icon,
    value,
    children
  } = _ref;
  return ___EmotionJSX(DetailPageSidebarRow, {
    label: label,
    align: "center"
  }, ___EmotionJSX(IconContainer, {
    style: {
      marginRight: 5
    }
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16
  }, typeof icon === 'string' ? ___EmotionJSX(Icon, {
    icon: icon
  }) : icon)), ___EmotionJSX("span", null, value), children);
}
DetailPageSidebarStatsRow.displayName = "DetailPageSidebarStatsRow";