import { StatusIcon } from '@useshortcut/shapes-ds';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { DeprecatedIconAdapter } from '../Icons';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const mapStateType = {
  [WORKFLOW_STATE_TYPES.UNSTARTED]: 'unstarted',
  [WORKFLOW_STATE_TYPES.STARTED]: 'started',
  [WORKFLOW_STATE_TYPES.DONE]: 'done'
};
const ICON = {
  unstarted: 'Unstarted',
  started: 'Started',
  done: 'Done'
};
export const EpicStateIcon = _ref => {
  let {
    type,
    size = 24
  } = _ref;
  const IconName = ICON[type];
  return ___EmotionJSX("div", {
    style: {
      display: 'inline-flex'
    }
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: size
  }, ___EmotionJSX(StatusIcon, {
    icon: IconName
  })));
};
EpicStateIcon.displayName = "EpicStateIcon";
export const EpicIcon = _ref2 => {
  let {
    epic,
    size = 24
  } = _ref2;
  const {
    type
  } = epic.stateObject;
  return ___EmotionJSX(EpicStateIcon, {
    type: mapStateType[type],
    size: size
  });
};
EpicIcon.displayName = "EpicIcon";