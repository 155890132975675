import { jsx as ___EmotionJSX } from "@emotion/react";
export function ExternalLink(_ref) {
  let {
    children,
    href
  } = _ref;
  return ___EmotionJSX("a", {
    href: href,
    target: "_blank",
    rel: "noreferrer"
  }, children, " ", ___EmotionJSX("span", {
    className: "fa fa-external-link-square"
  }));
}
ExternalLink.displayName = "ExternalLink";