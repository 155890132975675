import { professionRoles, roleInputLabel } from '@clubhouse/shared/constants';
import { Select } from 'components/shared/Select';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const RoleSelect = () => ___EmotionJSX("div", {
  "data-validate": "notEmpty",
  className: "form-input role"
}, ___EmotionJSX(Select, {
  id: "signup-role",
  name: "role",
  label: roleInputLabel,
  options: professionRoles,
  required: true
}));
RoleSelect.displayName = "RoleSelect";