import "core-js/modules/es.array.push.js";
import { useMemo } from 'react';
import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { TableRequester } from 'components/shared/table/base/TableRequester';
import { FIELD_ID } from 'components/shared/table/types';
import { useGroup } from 'data/entity/group';
import { getAllActiveProfileDetails, getCurrentUserProfileDetails, getProfileById, getProfileDetails } from 'data/entity/profile';
import { saveChanges } from 'data/entity/story';
import { requester } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const RequesterFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const [selectedRequester, handleChange] = useInlineEditing(entity.requested_by_id, requesterId => saveChanges(entity.id, {
    requested_by_id: requesterId
  }));
  const currentUser = getCurrentUserProfileDetails();
  const users = useMemo(() => {
    const activeUsers = getAllActiveProfileDetails();
    if (selectedRequester && !activeUsers.find(user => user.id === selectedRequester)) {
      const profile = getProfileById(selectedRequester);
      activeUsers.push(getProfileDetails(profile));
    }
    return activeUsers;
  }, [selectedRequester]);
  const team = useGroup({
    id: entity.group_id
  });
  const teamMemberIds = useMemo(() => team?.member_ids ?? [], [team]);
  return ___EmotionJSX(TableRequester, {
    currentUser: currentUser,
    label: "Update Story Requester",
    onChange: handleChange,
    users: users,
    selectedValue: selectedRequester,
    teamMemberIds: teamMemberIds
  });
};
RequesterFieldComponent.displayName = "RequesterFieldComponent";
export const RequesterField = () => ({
  name: FIELD_ID.REQUESTER,
  displayName: 'Requester',
  sort: requester,
  headerProps: {
    centered: true
  },
  Component: RequesterFieldComponent,
  width: 110
});