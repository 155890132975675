import { uniqBy } from 'lodash';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { moveWithinArray } from '@clubhouse/shared/utils/reorder';
import { useApplicationStateByKey, useUpdateApplicationState } from 'gql';
import { logAddItem, logRemoveItem } from '../logging/logging';
import { applicationStateKey, createPinnedState } from './pinnedState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const useReorderPinnedItems = _ref => {
  let {
    updateQuery
  } = _ref;
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState,
    updateQuery
  });
  return _ref2 => {
    let {
      fromIndex,
      toIndex
    } = _ref2;
    return update(data => {
      moveWithinArray(data.pinnedItems, fromIndex, toIndex);
      return data;
    });
  };
};
export const useAddPinnedItem = () => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  return async _ref3 => {
    let {
      id,
      loggingContext
    } = _ref3;
    logAddItem(loggingContext);
    try {
      return await update(data => {
        const pinnedItems = uniqBy([...data.pinnedItems, {
          id
        }], 'id');
        if (pinnedItems.length > 20) {
          addToast({
            kind: 'alert',
            Content: () => ___EmotionJSX(ToastText, null, "The number of pins is limited to 20."),
            timeout: 5000
          });
          throw new Error('pinnedItem length exceeded');
        }
        data.pinnedItems = pinnedItems;
        return data;
      });
    } catch (e) {}
  };
};
export const useRemovePinnedItem = () => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  return _ref4 => {
    let {
      id,
      loggingContext
    } = _ref4;
    logRemoveItem(loggingContext);
    return update(data => {
      return {
        ...data,
        pinnedItems: data.pinnedItems.filter(item => item.id !== id)
      };
    });
  };
};
export const usePinnedItem = _ref5 => {
  let {
    id,
    loggingContext
  } = _ref5;
  const {
    pinnedItems
  } = usePinnedItems();
  const addPinnedItem = useAddPinnedItem();
  const removePinnedItem = useRemovePinnedItem();
  const isPinned = !!pinnedItems?.find(item => item.id === id);
  return {
    isPinned,
    toggle: () => isPinned ? removePinnedItem({
      id,
      loggingContext
    }) : addPinnedItem({
      id,
      loggingContext
    })
  };
};
export const usePinnedItems = function () {
  let {
    fetchPolicy
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    data,
    updateQuery,
    loading,
    refetch
  } = useApplicationStateByKey({
    applicationStateKey,
    toExpectedType: createPinnedState,
    fetchPolicy
  });
  return {
    pinnedItems: data?.pinnedItems,
    updateQuery,
    loading,
    refetch
  };
};