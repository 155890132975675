import Logo from '@clubhouse/assets/png/third-party-logos/dailybot_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DailyBot() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.DAILYBOT
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "DailyBot"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "DailyBot Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Integrate Shortcut with ", ___EmotionJSX(ExternalLink, {
    href: "https://www.dailybot.com/"
  }, "DailyBot"), " and automate workflows for your chat today."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.dailybot.com/integrations/shortcut"
  }, "Learn more"), " on how the DailyBot integration works in Shortcut.")));
}
DailyBot.displayName = "DailyBot";