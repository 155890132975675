function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { DocSearchCardDocFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { timestampToFromNowDate } from '@clubhouse/shared/utils/date';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { navigateToDoc } from 'pages/write/lib/navigateToDoc';
import { useDocContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useDocContextMenu';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { UserCohortAdapter } from '../../shared/UserCohortAdapter';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DocSearchCardFragment = DocSearchCardDocFragmentDoc;
export const DocSearchCard = _ref => {
  let {
    doc,
    onClick,
    openModalOnClick
  } = _ref;
  const url = generatePathWithSlug(getPathForPage(PAGE_NAMES.DOC), {
    docId: doc.urlId
  });
  const contextMenuProps = useDocContextMenuWithQuickSearchSessionLogging(doc.id, doc.urlId, useCallback(() => {
    onClick?.({
      ...doc,
      url
    });
  }, [doc, onClick, url]));
  const handleClick = e => {
    onClick?.({
      ...doc,
      url
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }
    if (openModalOnClick) {
      navigateToDoc(doc.urlId);
    }
  };
  return ___EmotionJSX(SearchCardLink, _extends({
    onClick: handleClick,
    href: url,
    "data-focusable": true
  }, contextMenuProps), ___EmotionJSX(SearchCard, {
    isArchived: doc.archived
  }, ___EmotionJSX(SearchCard.Icon, {
    icon: "Document",
    kind: "blue",
    label: "Doc",
    isArchived: doc.archived
  }), ___EmotionJSX(SearchCard.Title, null, ___EmotionJSX(Emojify, null, doc.title || 'Untitled')), ___EmotionJSX(SearchCard.Metadata, null, doc.archived && ___EmotionJSX(SearchCard.MetadataItem, null, "Archived"), ___EmotionJSX(SearchCard.MetadataItem, null, "Updated ", timestampToFromNowDate(doc?.updatedAt || ''))), ___EmotionJSX(SearchCard.Avatars, null, doc.creator ? ___EmotionJSX(UserCohortAdapter, {
    withTooltip: true,
    users: [doc.creator],
    numberOfUsers: 1
  }) : null)));
};
DocSearchCard.displayName = "DocSearchCard";
export function DocSearchCardAdapter(props) {
  const doc = {
    __typename: 'Doc',
    archived: props.doc.archived,
    id: props.doc.id,
    urlId: props.doc.id,
    title: props.doc.title || null,
    updatedAt: props.doc.contentLastUpdatedAt,
    accessControlScope: props.doc?.accessControlScope.toLowerCase() ?? 'workspace',
    creator: {
      __typename: 'Permission',
      displayName: props.doc.creator?.name,
      email: props.doc.creator?.email || null,
      id: props.doc.creator?.id,
      mentionName: props.doc.creator?.mentionName,
      publicId: props.doc.creator?.publicId,
      state: props.doc.creator?.deactivated || props.doc.creator?.disabled ? 'disabled' : 'full',
      displayIcon: {
        __typename: 'Icon',
        id: props.doc.creator?.avatarUrl,
        thumbnailUrl: props.doc.creator?.avatarUrl || null
      }
    }
  };
  return ___EmotionJSX(DocSearchCard, _extends({}, props, {
    doc: doc
  }));
}
DocSearchCardAdapter.displayName = "DocSearchCardAdapter";