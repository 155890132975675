import { Button } from '@clubhouse/shared/components/Button';
import { clearPersistentCache } from 'gql/utils/cacheUtils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const clearCacheAndReload = async () => {
  await clearPersistentCache();
  window.location.href = `${window.location.origin}${window.location.pathname}`;
};
export const ClearCacheAndReload = () => {
  return ___EmotionJSX(Button, {
    kind: Button.KIND.WARNING,
    onClick: clearCacheAndReload
  }, "Clear cache and reload page");
};
ClearCacheAndReload.displayName = "ClearCacheAndReload";