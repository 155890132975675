import { Combobox } from '@clubhouse/shared/components/Combobox';
import { getStoryReferenceFromUrl, isEmbeddableLink } from '../MarkdownEditor/utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryReferenceItem = _ref => {
  let {
    fragment
  } = _ref;
  const storyRef = getStoryReferenceFromUrl(fragment);
  if (!storyRef) return null;
  return ___EmotionJSX(Combobox.SelectableItem, {
    value: storyRef
  }, "Create Story Reference");
};
StoryReferenceItem.displayName = "StoryReferenceItem";
const EmbeddableLinkItem = _ref2 => {
  let {
    fragment
  } = _ref2;
  if (!isEmbeddableLink(fragment)) return null;
  return ___EmotionJSX(Combobox.SelectableItem, {
    value: fragment
  }, "Embed Link");
};
EmbeddableLinkItem.displayName = "EmbeddableLinkItem";
export function LinkMenu(_ref3) {
  let {
    triggerRef,
    fragment
  } = _ref3;
  return ___EmotionJSX(Combobox.Menu, {
    triggerRef: triggerRef,
    isModal: false
  }, ___EmotionJSX(Combobox.List, null, ___EmotionJSX(StoryReferenceItem, {
    fragment: fragment
  }), ___EmotionJSX(EmbeddableLinkItem, {
    fragment: fragment
  }), ___EmotionJSX(Combobox.SelectableItem, {
    value: `[link](${fragment})`
  }, "Create Link"), ___EmotionJSX(Combobox.SelectableItem, {
    value: fragment
  }, "Dismiss")));
}
LinkMenu.displayName = "LinkMenu";