import { Nouns } from '@clubhouse/shared/constants';
import { TableProgress } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import * as EstimateScale from 'data/entity/estimateScale';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ProgressFieldComponent(_ref) {
  let {
    entity,
    getProgressStats
  } = _ref;
  const usingPoints = EstimateScale.isUsingPoints();
  return ___EmotionJSX(TableProgress, {
    entityType: entity.entity_type,
    progressStats: getProgressStats(entity, usingPoints),
    unit: usingPoints ? Nouns.Point : Nouns.Story
  });
}
ProgressFieldComponent.displayName = "ProgressFieldComponent";
export const ProgressField = function (getProgressStats) {
  let overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    name: FIELD_ID.PROGRESS,
    displayName: 'Progress',
    Component: _ref2 => {
      let {
        entity
      } = _ref2;
      return ___EmotionJSX(ProgressFieldComponent, {
        entity: entity,
        getProgressStats: getProgressStats
      });
    },
    width: 110,
    ...overrides
  };
};