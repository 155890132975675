import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getSection = (_, options) => ({
  items: options.map(_ref => {
    let {
      node: {
        id,
        name
      }
    } = _ref;
    return {
      value: id,
      name: ___EmotionJSX(Emojify, null, name),
      additionalData: {
        category: {
          id,
          name
        }
      }
    };
  })
});