import { ButtonText } from '@clubhouse/shared/components/Button';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ObjectiveTypeIcon } from './ObjectiveTypeIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ObjectiveWithIcon(_ref) {
  let {
    name,
    type
  } = _ref;
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center"
  }, ___EmotionJSX(ObjectiveTypeIcon, {
    type: type
  }), ___EmotionJSX(ButtonText, null, ___EmotionJSX(Emojify, null, name)));
}
ObjectiveWithIcon.displayName = "ObjectiveWithIcon";