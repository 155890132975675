import { useCurrentPage } from 'utils/navigation';
import { shouldShowCustomLayoutForPage, useShouldShowLoadingSpinnerForPage } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LoadingScreen = () => {
  const currentPage = useCurrentPage();
  const isCustomLayoutPage = shouldShowCustomLayoutForPage(currentPage);
  const isLoadingSpinnerPage = useShouldShowLoadingSpinnerForPage(currentPage);
  const showLoading = !isCustomLayoutPage && isLoadingSpinnerPage;
  return showLoading ? ___EmotionJSX("div", {
    id: "loading-screen"
  }) : null;
};