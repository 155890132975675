import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { getTimeSpentInWFStateConfig } from 'data/entity/report';
import { getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendTimeSpentInWFStateEvent } from 'utils/segment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Filters = _ref => {
  let {
    onChange
  } = _ref;
  const filters = ['wf_state_workflow', 'wf_state_type', 'wf_state_calculation'].map(key => {
    const {
      defaultValue,
      title,
      values
    } = getTimeSpentInWFStateConfig(key);
    return ___EmotionJSX(ReportFilterDropdown, {
      key: key,
      configId: key,
      value: getParamFromUrl(key) || defaultValue,
      title: title,
      onChange: (configId, selection) => {
        sendTimeSpentInWFStateEvent({
          configId,
          selection
        });
        updateParamInUrl(configId, selection);
        onChange();
      },
      items: values
    });
  });
  return ___EmotionJSX(React.Fragment, null, filters);
};