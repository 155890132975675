import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Icon } from '@useshortcut/shapes-ds';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DocScopeToIcon = {
  private: () => ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16
  }, ___EmotionJSX(Icon, {
    icon: "Lock",
    fill: "currentColor"
  })),
  workspace: () => ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16
  }, ___EmotionJSX(Icon, {
    icon: "Document",
    fill: "currentColor"
  })),
  shared: () => ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16
  }, ___EmotionJSX(Icon, {
    icon: "Document",
    fill: "currentColor"
  }))
};

// On docs-api, enum values are by convention `ANGRY_SNAKE_CASE` but on datalayer they are `calmCamelCase`,
// so we need to expect both cases here until we migrate everything to datalayer.
export function getDocIconForAccessControlScope(accessControlScope) {
  return DocScopeToIcon[accessControlScope.toLowerCase()];
}