import { Nouns } from '@clubhouse/shared/constants';
import { EpicStatesFilter } from 'components/gql/epicState/EpicStatesFilter';
import { ClearAllCurrentFiltersButton } from 'components/gql/filters/ClearAllCurrentFiltersButton';
import { QueryFilter } from 'components/gql/filters/QueryFilter';
import { LabelsFilter } from 'components/gql/label/LabelsFilter';
import { ObjectivesFilter } from 'components/gql/objective/ObjectivesFilter';
import { OwnersFilter } from 'components/gql/owner/OwnersFilter';
import { ProjectsFilter } from 'components/gql/projects/ProjectsFilter';
import { TeamsFilter } from 'components/gql/team/TeamsFilter';
import { IfNoTeamScope } from 'components/team-navigation/utils/IfNoTeamScope';
import { ShowArchived } from '../../../components/filters/ShowArchived';
import { useWorkspaceProjectsFeature } from '../../../hooks/useWorkspaceProjectsFeature';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicsFilters = () => {
  return ___EmotionJSX(React.Fragment, null, useWorkspaceProjectsFeature() && ___EmotionJSX(ProjectsFilter, {
    entityType: Nouns.Epic
  }), ___EmotionJSX(OwnersFilter, {
    entityType: Nouns.Epic
  }), ___EmotionJSX(ObjectivesFilter, {
    entityType: Nouns.Epic
  }), ___EmotionJSX(IfNoTeamScope, null, ___EmotionJSX(TeamsFilter, {
    entityType: Nouns.Epic
  })), ___EmotionJSX(EpicStatesFilter, {
    entityType: Nouns.Epic
  }), ___EmotionJSX(LabelsFilter, {
    entityType: Nouns.Epic
  }), ___EmotionJSX(ClearAllCurrentFiltersButton, null), ___EmotionJSX(ShowArchived, null));
};
export const EpicsQueryFilters = () => {
  return ___EmotionJSX(QueryFilter, {
    entityType: Nouns.Epic
  });
};
EpicsQueryFilters.displayName = "EpicsQueryFilters";