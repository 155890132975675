import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportsRefreshButton = _ref => {
  let {
    handleReportsRefresh
  } = _ref;
  return ___EmotionJSX(Tooltip, {
    content: "Sync chart with latest data"
  }, ___EmotionJSX(UnstyledButton, {
    onClick: handleReportsRefresh
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16
  }, ___EmotionJSX(Icon, {
    icon: "Sync"
  }))));
};
ReportsRefreshButton.displayName = "ReportsRefreshButton";