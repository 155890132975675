import Logo from '@clubhouse/assets/png/third-party-logos/clockify_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Clockify() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.AIRFOCUS
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Clockify"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Clockify Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Track time on Stories right from Shortcut with just one click."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://clockify.me/shortcut-time-tracking"
  }, "Learn more"), " on how Clockify integrates with Shortcut.")));
}
Clockify.displayName = "Clockify";