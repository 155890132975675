/**
 * TODO:
 *
 * - suppressModalOnClick (default false) would be a better api than openModalOnClick (default true): <StoryCard suppressModalOnClick />
 * - Remove classNames that apply styles:
 *   - Remove `classNames` package
 *   - story
 *   - story-link
 * - Remove js-sortable-handle from <Summary />
 *   - Note: Relies on a full-on React replacement
 */

import classNames from 'classnames';
import { useCallback } from 'react';
import { noop } from 'utils/nodash';
import { Chips, EpicName, GroupAndOwners, Title } from './components';
import { StoryContext } from './contexts';
import { StoryCardInternal } from './internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryCard = _ref => {
  let {
    cardColor,
    children,
    density = 'minimal',
    onClick = noop,
    openModalOnClick = true,
    shouldSkipFilters,
    story,
    level,
    href
  } = _ref;
  return ___EmotionJSX(StoryContext.Provider, {
    value: story
  }, ___EmotionJSX(StoryCardInternal, {
    cardColor: cardColor,
    density: density,
    href: href,
    id: `story-${story.id}`,
    isArchived: story.archived,
    level: level,
    onClick: useCallback(() => onClick(story), [onClick, story]),
    shouldSkipFilters: shouldSkipFilters,
    storyId: story.id,
    suppressModalOnClick: !openModalOnClick,
    summaryProps: {
      className: 'js-sortable-handle'
    },
    wrapperProps: {
      className: classNames(`js-react-story-link story story-${story.id} story-link`),
      'data-focusable': true,
      'data-id': story.id
    }
  }, children));
};
StoryCard.displayName = "StoryCard";
StoryCard.Chips = Chips;
StoryCard.EpicName = EpicName;
StoryCard.GroupAndOwners = GroupAndOwners;
StoryCard.Title = Title;