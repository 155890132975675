function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useCallback, useEffect, useRef, useState } from 'react';
import { InternalSelect } from './InternalSelect';
import { SelectOptionChecked } from './SelectOption';
import { DEFAULT_MAX_LIST_ITEMS, WIDTH } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const CreateIfNoResultItem = _ref => {
  let {
    inputValue,
    unit
  } = _ref;
  return {
    Component: SelectOptionChecked,
    name: `Enter to create new ${unit} "${inputValue}"`,
    value: inputValue,
    key: 'no-results'
  };
};

/**
 * Important note: Select searchs for items by {value} as default. To search by other keys, pass to additionalSearchTerms
 * eg. {  additionalSearchTerms: [item.name, item.label] } ... etc.
 */
export function Select(_ref2) {
  let {
    items,
    canCreateIfNoResult,
    targetComponentProps,
    unit,
    onChange,
    ...props
  } = _ref2;
  const currFilter = useRef('');
  const [filteredItems, setFilteredItems] = useState(items);
  const handleFilter = useCallback(inputValue => {
    let newItems = items;
    currFilter.current = inputValue || '';
    const query = inputValue?.toLowerCase();
    newItems = items.filter(item => {
      if (!props.isSearchable) return true;
      if (!query) return !item.isSearchOnly;
      const value = item.value ? String(item.value) : undefined;
      const itemValueMatch = value?.toLowerCase().includes(query);
      if (itemValueMatch) return true;
      return item.additionalSearchTerms?.some(term => term.toLowerCase().includes(query));
    });
    if (!newItems.length && canCreateIfNoResult && inputValue) {
      newItems = [CreateIfNoResultItem({
        inputValue,
        unit
      })];
    }
    setFilteredItems(newItems);
  }, [items, props.isSearchable, canCreateIfNoResult, unit]);
  useEffect(() => {
    if (currFilter.current) handleFilter(currFilter.current);else setFilteredItems(items);
  }, [handleFilter, items]);
  const displayedItems = filteredItems.length > DEFAULT_MAX_LIST_ITEMS ? filteredItems.slice(0, DEFAULT_MAX_LIST_ITEMS) : filteredItems;
  return ___EmotionJSX(InternalSelect, _extends({}, props, {
    unit: unit,
    onFilter: handleFilter,
    items: displayedItems,
    onChange: onChange,
    totalItemCount: filteredItems.length,
    targetComponentProps: targetComponentProps,
    onEnterKey: useCallback(value => {
      if (canCreateIfNoResult && displayedItems.length === 1 && displayedItems[0].key === 'no-results') {
        onChange?.([value]);
        return true;
      }
    }, [onChange, displayedItems, canCreateIfNoResult])
  }));
}
Select.displayName = "Select";
Select.WIDTH = WIDTH;