function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { TitleBar } from '../TitleBar';
import { ItemList } from './ItemList';
import { SIZE } from './enums';
import { Input, SelectList, TitleContainer } from './layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DropdownList = _ref => {
  let {
    options,
    inputValue,
    highlightedIndex,
    getItemProps,
    getMenuProps,
    title,
    closeMenu,
    getLabelProps,
    getInputProps,
    isSearchable,
    isPositionReady,
    size,
    kind,
    isDisabled,
    width,
    itemReducer,
    FooterComponent
  } = _ref;
  return ___EmotionJSX(SelectList, _extends({
    className: "react-multiselect-list"
  }, getMenuProps(), {
    "aria-multiselectable": true
  }), title && ___EmotionJSX(TitleContainer, null, ___EmotionJSX(TitleBar, {
    title,
    onClickClose: closeMenu
  })), isSearchable ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("label", getLabelProps({
    style: {
      display: 'none'
    }
  }), "Search for item"), ___EmotionJSX(Input, _extends({
    key: isPositionReady ? 0 : 1
  }, getInputProps(), {
    onBlur: e => {
      e.preventDefault();
      e.stopPropagation();
    },
    autoFocus: isPositionReady
  }))) : null, ___EmotionJSX(ItemList, {
    options,
    inputValue,
    highlightedIndex,
    getItemProps,
    size,
    kind,
    isDisabled,
    width,
    itemReducer
  }), FooterComponent);
};
DropdownList.displayName = "DropdownList";
DropdownList.SIZE = SIZE;