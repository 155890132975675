import classNames from 'classnames';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TaskCheckbox(_ref) {
  let {
    checked,
    onClick,
    showSpinner
  } = _ref;
  const handleClick = e => {
    e.preventDefault();
    if (!showSpinner) {
      onClick?.();
    }
  };
  return ___EmotionJSX("a", {
    href: "#",
    className: "task-checkbox",
    onClick: handleClick,
    role: "checkbox",
    "aria-checked": checked,
    "data-tabindex": true
  }, ___EmotionJSX("span", {
    className: classNames('fa', {
      'fa-check-square': !showSpinner && checked,
      'fa-square-o': !showSpinner && !checked,
      'fa-star': showSpinner,
      'fa-spin': showSpinner
    })
  }));
}
TaskCheckbox.displayName = "TaskCheckbox";