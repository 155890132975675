import { getActiveSpan } from '@sentry/browser';
// eslint-disable-next-line import/no-extraneous-dependencies -- TODO: (@charpeni) Migrate the import to `@sentry/browser` as soon as it's available.
import { spanToJSON } from '@sentry/core';
import { useEffect, useRef } from 'react';
export const useFinishActiveTransaction = _ref => {
  let {
    shouldFinishTransaction,
    tags
  } = _ref;
  const tagsRef = useRef(tags);
  tagsRef.current = tags;
  const activeSpanId = getActiveSpan()?.spanContext().spanId;
  useEffect(() => {
    if (activeSpanId && shouldFinishTransaction) {
      finishActiveTransaction(tagsRef.current);
    }
  }, [activeSpanId, shouldFinishTransaction]);
};
export const finishActiveTransaction = tags => {
  const activeSpan = getActiveSpan();
  if (activeSpan) {
    const activeSpanStatus = spanToJSON(activeSpan).status;
    activeSpan.setStatus?.(activeSpanStatus ?? 'ok');
    Object.entries(tags ?? {}).forEach(_ref2 => {
      let [key, value] = _ref2;
      activeSpan.setAttribute(key, value);
    });
    if (activeSpan.isRecording()) {
      activeSpan.end?.();
    }
  }
};