import { useDayzed } from 'dayzed';
import noop from 'lodash/noop';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getYearAndMonth, isInRange } from '@clubhouse/shared/utils/date';
import { getFocusableChildren, getTabbableChildren } from '@clubhouse/shared/utils/focus';
import { DatePickerThemeProvider, Month, MonthBackButton, MonthForwardButton, Months } from './components';
import { DEFAULT_WORKING_DAYS, FIRST_DAY_OF_WEEK } from './constants';
import { jsx as ___EmotionJSX } from "@emotion/react";
const findCalendarIndex = (calendars, date) => {
  const monthAndYear = getYearAndMonth(date);
  return calendars.findIndex(calendar => `${calendar.year}-${String(calendar.month + 1).padStart(2, '0')}` === monthAndYear);
};
export const CalendarCarousel = _ref => {
  let {
    workingDays = DEFAULT_WORKING_DAYS,
    startDate,
    endDate,
    isOpen,
    onHover = noop,
    onChoose,
    onMouseOut,
    monthsShown,
    isEditable,
    minDate,
    maxDate,
    trapFocus
  } = _ref;
  const [openToDate, setOpenToDate] = useState(startDate);
  const [offset, setOffset] = useState(0);
  const [lastHoveredDate, setLastHoveredDate] = useState();
  const selected = openToDate ? new Date(openToDate) : undefined;

  // When the dates change from outside (e.g. via a text box in ReportsDateFilter) then we want to
  // move the calendar to show that month. However, these dates will also change if we just click a
  // new date, so we check the lastHoveredDate to make sure we're not in that scenario.

  // biome-ignore lint/correctness/useExhaustiveDependencies: See above
  useEffect(() => {
    if (!endDate || endDate === lastHoveredDate) return;
    setOpenToDate(endDate);
    setOffset(0);
  }, [endDate]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: See above
  useEffect(() => {
    if (!startDate || startDate === lastHoveredDate) return;
    setOpenToDate(startDate);
    setOffset(0);
  }, [startDate]);

  // Every time we re-open the date picker we want to re-center the calendars on the start date,
  // but generally we don't want this to shift around while we're using the calendar
  // biome-ignore lint/correctness/useExhaustiveDependencies: See above
  useEffect(() => {
    if (isOpen) {
      setOpenToDate(startDate);
      setOffset(0);
    }
  }, [isOpen]);
  const {
    calendars,
    getBackProps,
    getForwardProps
  } = useDayzed({
    // We add two months that will be hidden in the UI. This ensures that navigating between months using keyboard works as expected.
    // It is also required if we want to animate the transition in the future.
    // The carousel will render: [H,V,V,H] where H is hidden and V is visible.
    monthsToDisplay: monthsShown + 2,
    offset: offset - 1,
    date: selected,
    onOffsetChanged: o => {
      setOffset(o + 1); // "+1" because we render hidden prev/next months.
    },
    onDateSelected: noop,
    firstDayOfWeek: FIRST_DAY_OF_WEEK
  });
  const handleChooseDate = useCallback(date => {
    if (isEditable && onChoose && isInRange(date, minDate, maxDate)) onChoose(date);
  }, [isEditable, onChoose, minDate, maxDate]);
  const handleDayHover = useCallback(date => {
    if (!isEditable) return;
    setLastHoveredDate(date);
    onHover(date);
  }, [isEditable, onHover]);
  const handleChangeMonth = useCallback(_ref2 => {
    let {
      to
    } = _ref2;
    const toCalendar = findCalendarIndex(calendars, to);
    if (toCalendar === 0) {
      setOffset(o => o - 1);
    } else if (toCalendar === calendars.length - 1) {
      setOffset(o => o + 1);
    }
    return monthRefs.current[toCalendar];
  }, [calendars]);
  const handleTab = useCallback(e => {
    if (e.key === 'Tab') {
      const tabbableChildren = getTabbableChildren(e.currentTarget);
      const index = tabbableChildren.indexOf(e.target);
      if (index === 0 && e.shiftKey) {
        e.preventDefault();
        tabbableChildren[tabbableChildren.length - 1].focus();
      } else if (index === tabbableChildren.length - 1 && !e.shiftKey) {
        e.preventDefault();
        tabbableChildren[0].focus();
      } else {
        const allFocusableChildren = getFocusableChildren(e.currentTarget);
        const _i = allFocusableChildren.indexOf(e.target);
        for (let i = _i; i >= 0 && i < allFocusableChildren.length; i += e.shiftKey ? -1 : 1) {
          if (i === _i) continue;
          const el = allFocusableChildren[i];
          if (tabbableChildren.includes(el)) {
            e.preventDefault();
            el.focus();
            break;
          }
        }
      }
    }
  }, []);
  const monthRefs = useRef([]);
  monthRefs.current = [];
  if (!calendars.length) return null;
  return ___EmotionJSX(DatePickerThemeProvider, null, ___EmotionJSX(Months, {
    onMouseOut: onMouseOut,
    onKeyDown: trapFocus ? handleTab : undefined
  }, ___EmotionJSX(MonthBackButton, getBackProps({
    calendars
  })), calendars.map((calendar, i) => ___EmotionJSX(Month, {
    key: `${calendar.year}-${calendar.month}`,
    ref: node => {
      monthRefs.current[i] = node;
    },
    hidden: i === 0 || i === calendars.length - 1,
    calendar: calendar,
    workingDays: workingDays,
    isEditable: isEditable,
    startDate: startDate,
    endDate: endDate,
    minDate: minDate,
    maxDate: maxDate,
    onHover: handleDayHover,
    onChoose: handleChooseDate,
    onChangeMonth: handleChangeMonth
  })), ___EmotionJSX(MonthForwardButton, getForwardProps({
    calendars
  }))));
};
CalendarCarousel.displayName = "CalendarCarousel";