import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { LoadMore } from 'components/searchResults/LoadMore';
import { Loading } from 'components/searchResults/Loading';
import { NoResults } from 'components/searchResults/NoResults';
import { DocListItem } from '../../shared/DocListItem';
import { DocSearchCard } from './DocSearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DocResults = _ref => {
  let {
    showCards,
    fetchProps,
    actions: {
      fetchMoreDocs
    },
    onClick,
    openModalOnClick
  } = _ref;
  const {
    loading,
    called,
    data,
    previousData
  } = fetchProps;
  const edges = data?.workspace2?.docsSearch?.edges ?? emptyArray;
  const previousEdges = previousData?.workspace2?.docsSearch?.edges ?? emptyArray;
  const hasNextPage = data?.workspace2?.docsSearch?.pageInfo?.hasNextPage || false;
  if (loading && !called) {
    return ___EmotionJSX(Loading, null);
  }
  const resultsList = loading ? previousEdges : edges;
  if (called && !loading && resultsList && !resultsList.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Docs"
    });
  }
  const DocComponent = showCards ? DocSearchCard : DocListItem;
  const docs = resultsList.map(edge => edge?.node).filter(Boolean);
  return ___EmotionJSX(React.Fragment, null, docs.map((doc, index) => ___EmotionJSX(DocComponent, {
    key: doc.id,
    doc: doc,
    onClick: entity => onClick(entity, index),
    openModalOnClick: openModalOnClick
  })), hasNextPage && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: fetchMoreDocs
  }));
};