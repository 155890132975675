import moment from 'moment';
import { useMemo } from 'react';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { DetailPageSidebarRow } from './DetailPageSidebarRow';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DetailPageSidebarDateDisplay(_ref) {
  let {
    label,
    date
  } = _ref;
  const formattedDate = useMemo(() => moment(date).format(DATE_FORMAT.SHORT_DATE_TIME_FORMAT), [date]);
  return ___EmotionJSX(DetailPageSidebarRow, {
    label: label,
    align: "center"
  }, formattedDate);
}
DetailPageSidebarDateDisplay.displayName = "DetailPageSidebarDateDisplay";