import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { IterationSearchCardAdapter } from './IterationSearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationResults = _ref => {
  let {
    fetchProps: {
      loading,
      next,
      data: iterations,
      previousData,
      hasFetched
    },
    actions: {
      loadMore
    },
    onClick,
    openModalOnClick
  } = _ref;
  if (loading && !hasFetched) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(SkeletonSearchCard, {
      icon: "Iteration",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Iteration",
      kind: "blue"
    }), ___EmotionJSX(SkeletonSearchCard, {
      icon: "Iteration",
      kind: "blue"
    }));
  }
  const resultsList = loading ? previousData : iterations;
  if (hasFetched && !resultsList.length) {
    return ___EmotionJSX(NoResults, {
      entityType: "Iterations"
    });
  }
  return ___EmotionJSX(React.Fragment, null, resultsList.map((iteration, index) => ___EmotionJSX(IterationSearchCardAdapter, {
    key: iteration.id,
    iteration: iteration,
    onClick: entity => onClick?.(entity, index),
    openModalOnClick: openModalOnClick
  })), next && ___EmotionJSX(LoadMore, {
    loading: loading,
    loadMore: loadMore
  }));
};