import Logo from '@clubhouse/assets/png/third-party-logos/statushero_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function StatusHero() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.STATUS_HERO
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "StatusHero"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "StatusHero Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://statushero.com/"
  }, "Status Hero"), " provides daily goals, check-ins, and activity logs for software teams."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_STATUS_HERO
  }, "our instructions"), ' ', "for how to configure this integration in your Status Hero account.")));
}
StatusHero.displayName = "StatusHero";