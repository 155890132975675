import Icons from '@clubhouse/shared/components/Icons';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ErrorState(_ref) {
  let {
    state
  } = _ref;
  return ___EmotionJSX("div", null, ___EmotionJSX(Text, {
    size: Text.SIZE.SMALL
  }, ___EmotionJSX(Icons.Ban, null), " We failed to load ", state, " epics."));
}
ErrorState.displayName = "ErrorState";