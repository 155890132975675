import { useEffect } from 'react';
import { useContextMenuForCaveman } from 'utils/contextMenus/useContextMenuForCaveman';
export function IterationsPageContextMenu() {
  const contextMenuProps = useContextMenuForCaveman();
  const id = contextMenuProps['data-cm'];
  useEffect(() => {
    document.querySelector('#iterations')?.setAttribute('data-cm', id);
    return () => {
      document.querySelector('#iterations')?.removeAttribute('data-cm');
    };
  }, [id]);
  return null;
}