import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import { isPlaywrightTestEnv } from 'utils/isPlaywrightTestEnv';
import { EVENTS } from '../events';
import { NOOP_MONITOR, initializeAsync } from '../utils';
export const NAME = 'PyneAI';
export const shouldInitialize = _ref => {
  let {
    env
  } = _ref;
  if (isPlaywrightTestEnv() || env === 'test') {
    return false;
  }
  return true;
};
export const shouldOwnEvent = () => false;
export const shouldOwnNamedEvent = () => false;
const identify = (userId, attrs) => {
  if (window.pyne || !!userId) {
    window.pyne('identify', userId, attrs);
  }
};
const logEvent = async (eventName, attrs) => {
  return new Promise(resolve => {
    if (!window.analyticsNext) {
      return;
    }
    // send Pyne events to Segment
    window.analyticsNext.track(eventName, {
      ...attrs
    }, {}, () => resolve());
  });
};
const initListeners = () => {
  if (!window.pyne) {
    return;
  }
  window.pyne('onTourStarted', _ref2 => {
    let {
      id
    } = _ref2;
    logEvent(EVENTS.Pyne_Tour_Started, {
      tourId: id
    });
  });
  window.pyne('onTourFinished', _ref3 => {
    let {
      id
    } = _ref3;
    logEvent(EVENTS.Pyne_Tour_Finished, {
      tourId: id
    });
  });
  window.pyne('onTourTerminated', _ref4 => {
    let {
      id
    } = _ref4;
    logEvent(EVENTS.Pyne_Tour_Terminated, {
      tourId: id
    });
  });
};
export const pyneEndSession = async () => {
  if (window.pyne) {
    await window.pyne('identify', null);
  }
};
export const initialize = (_, _ref5) => {
  let {
    onError
  } = _ref5;
  const {
    triggerInitialization
  } = initializeAsync(async () => {
    window.pyneSettings = {
      app_id: window._PYNE_APP_ID
    };
    await import( /* webpackChunkName: "pyne" */'@pyne-ai/browser-enterprise');
    initListeners();
  }, {
    onError,
    triggerManually: true
  });
  return {
    ...NOOP_MONITOR,
    setSessionAttributes: async attrs => {
      // The feature flag needs to be checked here and not in "shouldInitialize" to give LD time to fetch the flag.
      if (getFeatureToggle(FEATURE_TOGGLES.PYNE_ROLLOUT)) {
        await triggerInitialization(attrs);
        identify(attrs.userId, attrs);
      }
    }
  };
};