import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '@clubhouse/shared/colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Trophy" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Trophy = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill = COLORS.YELLOW45
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    fill: fill,
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Trophy"
  }));
};
Trophy.displayName = "Trophy";