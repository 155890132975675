import { NavigationLink } from './NavigationLink';
import { useCurrentStoryDialogNavigation } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryDialogNavigation = _ref => {
  let {
    storyId
  } = _ref;
  const {
    prev,
    next,
    goNext,
    goPrevious
  } = useCurrentStoryDialogNavigation(storyId);
  return ___EmotionJSX(React.Fragment, null, prev ? ___EmotionJSX(NavigationLink, {
    key: prev,
    type: "prev",
    storyId: prev,
    onNavigate: goPrevious
  }) : null, next ? ___EmotionJSX(NavigationLink, {
    key: next,
    type: "next",
    storyId: next,
    onNavigate: goNext
  }) : null);
};