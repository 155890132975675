import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { currentUserIsFollower } from 'data/entity/epic';
import { EmptyValue } from './EmptyValue';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function EpicFollowers(_ref) {
  let {
    epic
  } = _ref;
  const isFollower = currentUserIsFollower(epic);
  const numFollowers = epic.follower_ids?.length ?? 0;
  const tooltipText = `${isFollower ? 'Unfollow' : 'Follow'} this Epic`;
  return ___EmotionJSX(DetailPageSidebarRow, {
    label: "Followers",
    "data-perma-id": "epic-followers-field"
  }, ___EmotionJSX(DetailPageSidebarAction, {
    icon: "User",
    id: "cid-followers-field",
    "data-on-click": "openEpicFollowerUpdater",
    secondarySlot: ___EmotionJSX(Tooltip, {
      content: tooltipText
    }, ___EmotionJSX(SecondaryActionButton, {
      "data-on-click": isFollower ? 'removeMeAsFollower' : 'addMeAsFollower'
    }, ___EmotionJSX(Icon, {
      icon: isFollower ? 'Remove' : 'Add',
      fill: "main",
      label: tooltipText
    })))
  }, numFollowers === 0 ? ___EmotionJSX(EmptyValue, null, "Nobody") : isFollower ? numFollowers === 1 ? 'Just You' : `You + ${numFollowers - 1}` : numFollowers));
}
EpicFollowers.displayName = "EpicFollowers";