import { GoToEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { navigateTo } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const GoToEpicFragment = GoToEpicActionFragmentFragmentDoc;
export const GoToEpicAction = _ref => {
  let {
    epic
  } = _ref;
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Zoom",
    onClick: () => navigateTo({
      url: getHref(epic.publicId)
    })
  }, "Go To ", Nouns.Epic.singular);
};
GoToEpicAction.displayName = "GoToEpicAction";