import noop from 'lodash/noop';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { closeHelpHub, toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { isSampleObserver } from 'data/entity/user';
// eslint-disable-next-line import/no-restricted-paths
import { OptInToggleButton } from 'pages/stories-v2';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { withinOrgAndWithSlug } from 'utils/is';
import { openNotifications } from 'utils/notifications';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const useHeaderLinks = _ref => {
  let {
    openMobileSearch
  } = _ref;
  const hasFetchedConsolidatedData = useConsolidatedDataFetched();
  const isWithinOrgAndWithSlug = withinOrgAndWithSlug();
  useCommandBarCallback('openActivityPanel', () => openNotifications());
  return [{
    permaId: 'top-nav-search-icon',
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Search",
      size: 16,
      fill: "currentColor"
    }),
    showOnDesktop: false,
    showOnTablet: isWithinOrgAndWithSlug,
    showOnMobile: isWithinOrgAndWithSlug,
    open: () => {
      openMobileSearch();
    }
  }, {
    permaId: 'top-nav-opt-in-stories-button',
    component: ___EmotionJSX(OptInToggleButton, null),
    showOnDesktop: true,
    showOnTablet: true,
    showOnMobile: true,
    open: noop,
    hide: !hasFetchedConsolidatedData
  }, {
    permaId: 'top-nav-training-icon',
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Help",
      size: 16,
      fill: "currentColor"
    }),
    text: 'Help',
    showOnTablet: true,
    id: 'training-link',
    open: toggleHelpHub,
    hide: !isWithinOrgAndWithSlug || !hasFetchedConsolidatedData
  }, {
    permaId: 'top-nav-news-icon',
    icon: ___EmotionJSX(SizedIcon, {
      icon: "News",
      size: 16,
      fill: "currentColor"
    }),
    text: 'News',
    id: 'news-and-updates-link',
    open: closeHelpHub,
    hide: !isWithinOrgAndWithSlug || !hasFetchedConsolidatedData,
    showOnTablet: true
  }, {
    permaId: 'top-nav-activity-icon',
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Activity",
      size: 16,
      fill: "currentColor"
    }),
    text: 'Activity',
    showOnMobile: true,
    id: 'notifications-link',
    disabled: isSampleObserver(),
    open: openNotifications,
    hide: !isWithinOrgAndWithSlug || !hasFetchedConsolidatedData,
    getBadgeProps: () => ({
      styleJSX: 'top:10px; right:7px;'
    })
  }, {
    permaId: 'top-nav-help',
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Help",
      size: 16,
      fill: "currentColor"
    }),
    tooltipText: 'Help',
    showOnMobile: true,
    open: toggleHelpHub,
    hide: isWithinOrgAndWithSlug
  }];
};