import "core-js/modules/es.array.push.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload.js';
import { jsx as ___EmotionJSX } from "@emotion/react";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController], [['Controller', 'HotReload'], HotReloadController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
export function HotReload(_ref2) {
  let {
    page,
    url,
    tooltip
  } = _ref2;
  return ___EmotionJSX("div", {
    className: "attribute-toggle"
  }, ___EmotionJSX("a", {
    href: url,
    "data-controller": "HotReload",
    "data-on-click": "load",
    "data-page": page,
    "data-tabindex": true,
    "data-tooltip": tooltip
  }, ___EmotionJSX("span", {
    className: "fa fa-external-link"
  })));
}
HotReload.displayName = "HotReload";