import { DeleteEpicActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ContextMenuItem } from '@clubhouse/shared/components/ContextMenu';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useDeleteEpicMutation } from '../useDeleteEpicMutation';
import { useEpicConfirmationDialog } from '../useEpicConfirmationDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DeleteEpicFragment = DeleteEpicActionFragmentFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => ___EmotionJSX(ToastText, null, message)
  });
};
const useDeleteEpic = epic => {
  const {
    openDialog,
    closeDialog
  } = useEpicConfirmationDialog();
  const deleteEpic = useDeleteEpicMutation();
  const onAction = useCallback(() => {
    return new Promise(resolve => {
      openDialog('delete', {
        onCancel: resolve,
        onClick: async () => {
          try {
            closeDialog();
            await deleteEpic(epic);
            showToast(true, ___EmotionJSX(React.Fragment, null, "Epic", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, epic.name)), ' ', "deleted."));
          } catch {
            showToast(false, ___EmotionJSX(React.Fragment, null, "Unable to delete", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, epic.name)), "."));
          } finally {
            resolve();
          }
        }
      });
    });
  }, [closeDialog, deleteEpic, epic, openDialog]);
  return {
    onAction
  };
};
export const DeleteEpicAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    onAction
  } = useDeleteEpic(epic);
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Trash",
    onClick: onAction,
    isDisabled: isLoggedInUserObserver()
  }, "Delete ", Nouns.Epic.singular);
};
DeleteEpicAction.displayName = "DeleteEpicAction";
export const DeleteEpicContextMenuItem = _ref2 => {
  let {
    epic
  } = _ref2;
  const {
    onAction
  } = useDeleteEpic(epic);
  return ___EmotionJSX(ContextMenuItem, {
    icon: "Trash",
    onClick: onAction,
    label: `Delete ${Nouns.Epic.singular}`,
    isDisabled: isLoggedInUserObserver()
  });
};
DeleteEpicContextMenuItem.displayName = "DeleteEpicContextMenuItem";