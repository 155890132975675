import Logo from '@clubhouse/assets/png/third-party-logos/dropbox_logo.png';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { useIntegrationState } from 'data/entity/organization';
import { isLoggedInUserAdmin, isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Dropbox() {
  const [isEnabled, toggleEnabled] = useIntegrationState(INTEGRATION_TYPE.DROPBOX);
  const isLoading = typeof isEnabled !== 'boolean';
  const readOnly = isReadOnly();
  const admin = isLoggedInUserAdmin();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.DROPBOX
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Dropbox"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Dropbox Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.dropbox.com/"
  }, "Dropbox"), " allows you to store, synchronize and share files online with public access. Our Dropbox integration allows you to attach files directly to a", ' ', BRAND.NAME, " story."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_DROPBOX
  }, "our instructions"), " for how to upload files from your Dropbox account."), ___EmotionJSX(Layout.SectionTitle, null, "Integration Status"), isLoading ? ___EmotionJSX(IntegrationToggle.Loading, null) : ___EmotionJSX(IntegrationToggle, {
    isReadOnly: !admin,
    isEnabled: isEnabled,
    onChange: async () => {
      const success = await toggleEnabled();
      if (!success) {
        const newValue = !isEnabled;
        addToast({
          kind: 'alert',
          timeout: 5000,
          Content: () => ___EmotionJSX(ToastText, null, "Unable to ", newValue ? 'enable' : 'disable', " integration.")
        });
      }
    }
  })));
}
Dropbox.displayName = "Dropbox";