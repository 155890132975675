import sighFailPng from '@clubhouse/assets/png/sigh-fail.png';
import { LoadingState } from './shared/LoadingState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const UndonePage = () => ___EmotionJSX(LoadingState, {
  title: ___EmotionJSX("span", {
    style: {
      fontSize: 18
    }
  }, "Your failed import has been removed."),
  message: ___EmotionJSX(React.Fragment, null, "Please contact ", ___EmotionJSX("a", {
    href: `mailto: ${BRAND.SUPPORT_EMAIL}`
  }, BRAND.SUPPORT_EMAIL), " with any questions."),
  hero: ___EmotionJSX("img", {
    src: sighFailPng,
    alt: "",
    style: {
      marginBottom: 20
    }
  })
});
UndonePage.displayName = "UndonePage";