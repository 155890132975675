import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useContext } from 'react';
import { getTint } from '@clubhouse/shared/color';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { TooltipLayout } from '@clubhouse/shared/components/Tooltip';
import { getFieldValueIcon } from '@clubhouse/shared/utils/customFields';
import { DensityContext } from '../../../../../internal/DensityContext';
import { ChipTooltip } from '../../../../../internal/components/Chips/components/ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
const colorKeyMap = {
  midnightBlue: 'midnight-blue',
  skyBlue: 'sky-blue',
  yellowGreen: 'yellow-green'
};
export const CustomFieldChip = _ref => {
  let {
    colorKey,
    fieldCanonicalName,
    fieldIconSetIdentifier,
    fieldPublicName,
    fieldValues,
    valueId,
    valueStringValue
  } = _ref;
  const CustomFieldIcon = getFieldValueIcon({
    canonical_name: fieldCanonicalName,
    icon_set_identifier: fieldIconSetIdentifier,
    values: fieldValues
  }, valueId);
  let displayValue = valueStringValue;
  if (['minimal'].includes(useContext(DensityContext)) && fieldCanonicalName === 'severity') {
    displayValue = '';
  }
  if (fieldCanonicalName === 'priority') {
    displayValue = '';
  }
  let color = colorKey;
  if (colorKey && colorKeyMap[colorKey]) {
    color = colorKeyMap[colorKey];
  }
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(TooltipLayout, {
      title: fieldPublicName || '',
      text: valueStringValue || ''
    }, ___EmotionJSX(TooltipLayout.Slot, {
      name: "Icon"
    }, ___EmotionJSX(CustomFieldIcon, {
      fill: "currentColor"
    })))
  }, ___EmotionJSX(Chip, null, ___EmotionJSX("span", {
    style: {
      color: getTint(color)
    }
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(CustomFieldIcon, {
    fill: "currentColor"
  }))), ___EmotionJSX(VisuallyHidden, null, fieldPublicName, ":"), displayValue));
};
CustomFieldChip.displayName = "CustomFieldChip";