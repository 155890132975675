import { GoToStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { navigateTo } from 'utils/navigation';
import { getHref } from '../links';
import { jsx as ___EmotionJSX } from "@emotion/react";
const GoToStoryActionFragment = GoToStoryActionFragmentDoc;
export function GoToStoryAction(_ref) {
  let {
    story
  } = _ref;
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Zoom",
    onClick: () => navigateTo({
      url: getHref(story.publicId)
    })
  }, "Open ", Nouns.Story.singular);
}
GoToStoryAction.displayName = "GoToStoryAction";