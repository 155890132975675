import { Header } from './components/Header';
import { Section } from './components/Section';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Body = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, children);
};
Body.Header = Header;
Body.Section = Section;