import { getDefaultLabelColor } from 'data/entity/label';
import { LabelChip } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LabelChips = _ref => {
  let {
    labels
  } = _ref;
  if (!labels?.length) return null;
  return ___EmotionJSX(React.Fragment, null, labels.map(_ref2 => {
    let {
      id,
      name,
      color
    } = _ref2;
    return ___EmotionJSX(LabelChip, {
      key: id,
      color: color || getDefaultLabelColor(),
      name: name
    });
  }));
};