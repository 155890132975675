import { useUniqueId } from '@useshortcut/shapes-ds';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const RadioListItem = _ref => {
  let {
    active,
    onClick,
    title,
    children
  } = _ref;
  const icon = active ? 'fa-circle' : 'fa-circle-o';
  const id = useUniqueId('radioli');
  return ___EmotionJSX("label", {
    className: `radio-list-item ${active ? 'active' : ''}`,
    htmlFor: id
  }, ___EmotionJSX("input", {
    type: "checkbox",
    id: id,
    checked: !!active,
    onChange: onClick
  }), ___EmotionJSX("span", {
    className: `radio fa ${icon}`
  }), ___EmotionJSX("span", {
    className: "radio-list-item-title"
  }, title), children);
};
RadioListItem.displayName = "RadioListItem";