import { EpicMoreActionsFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ArchiveEpicAction } from '../../actions/ArchiveEpicAction';
import { CopyEpicLinkAction } from '../../actions/CopyEpicLinkAction';
import { DeleteEpicAction } from '../../actions/DeleteEpicAction';
import { DuplicateEpicAction } from '../../actions/DuplicateEpicAction';
import { GoToEpicAction } from '../../actions/GoToEpicAction';
import { PinEpicAction } from '../../actions/PinEpicAction';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicMoreActionsFieldFragment = EpicMoreActionsFieldFragmentFragmentDoc;
function MoreActionsFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(MoreActions, null, ___EmotionJSX(MoreActions.TableButton, null), ___EmotionJSX(MoreActions.Menu, null, ___EmotionJSX(GoToEpicAction, {
    epic: entity
  }), ___EmotionJSX(MoreActions.Divider, null), ___EmotionJSX(PinEpicAction, {
    epic: entity
  }), ___EmotionJSX(CopyEpicLinkAction, {
    epic: entity
  }), ___EmotionJSX(MoreActions.Divider, null), ___EmotionJSX(ArchiveEpicAction, {
    epic: entity
  }), ___EmotionJSX(DuplicateEpicAction, {
    epic: entity
  }), ___EmotionJSX(DeleteEpicAction, {
    epic: entity
  })));
}
MoreActionsFieldComponent.displayName = "MoreActionsFieldComponent";
export const MoreActionsField = () => ({
  name: 'more_actions',
  displayName: '',
  Component: MoreActionsFieldComponent,
  LoadingComponent: null,
  width: 44,
  frozen: {
    right: -35
  }
});