function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { ConfirmationDialogProvider, useConfirmationDialogState } from '../ConfirmationDialog/ConfirmationDialogProvider';
import { Menu } from './shared';
import { jsx as ___EmotionJSX } from "@emotion/react";
const MenuWrapper = _ref => {
  let {
    children
  } = _ref;
  const {
    isConfirmationDialogOpen,
    dialogProps,
    closeDialog
  } = useConfirmationDialogState();
  return ___EmotionJSX(React.Fragment, null, dialogProps && ___EmotionJSX(ConfirmationDialog, _extends({}, dialogProps, {
    onClose: closeDialog,
    visible: isConfirmationDialogOpen
  })), !isConfirmationDialogOpen && ___EmotionJSX(Menu, null, children));
};
export const ContextMenu = _ref2 => {
  let {
    children
  } = _ref2;
  return ___EmotionJSX(ConfirmationDialogProvider, null, ___EmotionJSX(MenuWrapper, null, children));
};
ContextMenu.displayName = "ContextMenu";