import Router from 'app/client/core/js/_frontloader/router';
import DropdownController from 'app/client/core/js/controllers/dropdown';
import InstallationModel from 'app/client/core/js/models/installation';
import WebhookModel from 'app/client/core/js/models/webhook';
import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { IntegrationSettings } from 'components/settings/integrations/IntegrationSettings';
import { FEATURES, usesFeature } from 'utils/features';
import AirfocusIntegrationController from './airfocusIntegration';
import BitbucketIntegrationController from './bitbucketIntegration';
import BoxIntegrationController from './boxIntegration';
import BugseeIntegrationController from './bugseeIntegration';
import BugsnagIntegrationController from './bugsnagIntegration';
import CalendarIntegrationController from './calendarIntegration';
import ClockifyIntegrationController from './clockifyIntegration';
import CodeStreamIntegrationController from './codeStreamIntegration';
import DailybotIntegrationController from './dailybotIntegration';
import DropboxIntegrationController from './dropboxIntegration';
import EverhourIntegrationController from './everhourIntegration';
import FigmaIntegrationController from './figmaIntegration';
import FireHydrantIntegrationController from './fireHydrantIntegration';
import GithubIntegrationController from './githubIntegration';
import GitlabIntegrationController from './gitlabIntegration';
import GoogleCalendarIntegrationController from './googleCalendarIntegration';
import GoogleDriveIntegrationController from './googleDriveIntegration';
import GsheetsIntegrationController from './gsheetsIntegration';
import HoneyBadgerIntegrationController from './honeyBadgerIntegration';
import InstabugIntegrationController from './instabugIntegration';
import LambdaTestIntegrationController from './lambdaTestIntegration';
import LinearbIntegrationController from './linearbIntegration';
import LoomIntegrationController from './loomIntegration';
import MarkerIOIntegrationController from './markerIOIntegration';
import MiroIntegrationController from './miroIntegration';
import NotionIntegrationController from './notionIntegration';
import PlectoIntegrationController from './plectoIntegration';
import PomoDoneAppIntegrationController from './pomoDoneAppIntegration';
import ProductboardIntegrationController from './productboardIntegration';
import RoadmunkIntegrationController from './roadmunkIntegration';
import RollbarIntegrationController from './rollbarIntegration';
import RootlyIntegrationController from './rootlyIntegration';
import SentryIntegrationController from './sentryIntegration';
import SlabIntegrationController from './slabIntegration';
import SlackIntegrationController from './slackIntegration';
import StatusHeroIntegrationController from './statusHeroIntegration';
import TestlodgeIntegrationController from './testlodgeIntegration';
import WebhookIntegrationController from './webhookIntegration';
import YoutubeIntegrationController from './youtubeIntegration';
import ZapierIntegrationController from './zapierIntegration';
import ZendeskIntegrationController from './zendeskIntegration';
const exports = {};
const checkIfGsheetsEnabled = () => usesFeature(FEATURES.GOOGLE_SHEETS);
const getControllers = () => [AirfocusIntegrationController, BitbucketIntegrationController, BoxIntegrationController, BugseeIntegrationController, BugsnagIntegrationController, CalendarIntegrationController, CodeStreamIntegrationController, DailybotIntegrationController, ClockifyIntegrationController, DropboxIntegrationController, EverhourIntegrationController, FigmaIntegrationController, FireHydrantIntegrationController, GithubIntegrationController, GitlabIntegrationController, GoogleCalendarIntegrationController, GoogleDriveIntegrationController, ...(checkIfGsheetsEnabled() ? [GsheetsIntegrationController] : []), HoneyBadgerIntegrationController, InstabugIntegrationController, LambdaTestIntegrationController, LinearbIntegrationController, LoomIntegrationController, MarkerIOIntegrationController, MiroIntegrationController, NotionIntegrationController, PlectoIntegrationController, PomoDoneAppIntegrationController, ProductboardIntegrationController, RoadmunkIntegrationController, RollbarIntegrationController, RootlyIntegrationController, SentryIntegrationController, SlabIntegrationController, SlackIntegrationController, StatusHeroIntegrationController, TestlodgeIntegrationController, WebhookIntegrationController, YoutubeIntegrationController, ZapierIntegrationController, ZendeskIntegrationController];

// This is just used to navigate from a individual integration back to the top-level list
exports.init = () => {
  Router.addRoutesFromControllers(getControllers().concat(exports));
};

/**
 * @deprecated Use `IntegrationConfiguration.isIntegrationEnabled(INTEGRATION_KEYS.CALENDAR)` instead
 */
exports.isCalendarEnabled = () => {
  const calendar = WebhookModel.getCalendar();
  return calendar && !calendar.disabled;
};

/**
 * @deprecated Use `IntegrationConfiguration.isAnyVCSEnabled()` instead
 */
exports.isVCSEnabled = () => {
  const bitbucket = WebhookModel.getBitbucket();
  const githubEnabled = InstallationModel.isGitHubEnabled();
  const gitlab = InstallationModel.getGitlab();
  return bitbucket && !bitbucket.disabled || githubEnabled || gitlab && !gitlab.disabled;
};

/**
 * @deprecated Use `IntegrationConfiguration.isIntegrationEnabled(INTEGRATION_KEYS.ZENDESK)` instead
 */
exports.isZendeskEnabled = () => {
  return InstallationModel.isInstalled(InstallationModel.getZendesk());
};
exports.title = `Integrations | ${BRAND.NAME}`;
exports.route = () => {
  return `${Url.getSlugPath()}/settings/integrations`;
};
exports.open = () => {
  DropdownController.closeAll();
  Settings.open({
    route: exports.route,
    title: exports.title,
    template: View.componentToTemplate(IntegrationSettings)
  });
};
export { exports as default };