import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
import Iteration from 'app/client/core/js/modules/iteration.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const StoryController = exports;
  [[['Controller', 'Story'], StoryController], [['Iteration'], Iteration], [['Tooltip'], Tooltip], [['Controller', 'Story'], StoryController], [['Iteration'], Iteration], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@useshortcut/shapes-ds';
import { Chip, IconChip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ageInDays } from '@clubhouse/shared/dates';
import { OpinionatedFieldType, StoryTypes } from '@clubhouse/shared/types/entity';
import { capitalize } from '@clubhouse/shared/utils';
import * as PullRequestLinkTooltipTemplate from 'app/client/core/views/templates/pullRequestLinkTooltip.html';
import * as StoryLabelsTemplate from 'app/client/core/views/templates/storyLabels.html';
import * as StoryIterationBadgeTooltipTemplate from 'app/client/core/views/templates/storyIterationBadgeTooltip.html';
import * as StoryLinkedBranchesTemplate from 'app/client/core/views/templates/storyLinkedBranches.html';
import * as StoryPreviousIterationsTooltipTemplate from 'app/client/core/views/templates/storyPreviousIterationsTooltip.html';
import * as StoryTimelineTemplate from 'app/client/core/views/templates/storyTimeline.html';
import * as StoryTooltipTemplate from 'app/client/core/views/templates/storyTooltip.html';
import * as TaskTemplate from 'app/client/core/views/templates/task.html';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import * as StoryArchivedMessageTemplate from 'app/client/core/views/templates/storyArchivedMessage.html';
import * as StoryCodeTemplate from 'app/client/core/views/templates/storyCode.html';
import * as IconStoryTemplate from 'app/client/core/views/templates/icons/icon-story.html';
import * as IconFlagOutlinedTemplate from 'app/client/core/views/templates/icons/icon-flag-outlined.html';
import * as GitBranchDialogTemplate from 'app/client/core/views/templates/gitBranchDialog.html';
import * as DiffTooltipTemplate from 'app/client/core/views/templates/diffTooltip.html';
import { copyToClipboard } from '@clubhouse/shared/utils/copyToClipboard';
import { OverlappingPrs } from 'components/stories/OverlappingPrs';
import { StoryAttributes } from 'components/stories/StoryAttributes';
import { BlockerBadge } from 'components/stories/StoryAttributes/BlockerBadge';
import { getItemsForAutocomplete } from 'data/entity/column';
import { getIdForCanonicalName } from 'data/entity/customField';
import { createInvites } from 'data/entity/invite';
import { OptionalProject } from 'data/entity/project';
import * as Updates from 'utils/updates';
import AddNewStoryController from './addNewStory';
import ArchiveWarningController from './archiveWarning';
import ActivityModel from '../models/activity';
import BulkSelectionModel from '../models/bulkSelection';
import ApplicationState from '../modules/applicationState';
import Async from '../modules/async';
import AutocompleteController from './autocomplete';
import BulkEditorController from './bulkEditor';
import ColumnModel from '../models/column';
import CommentController from 'app/client/core/js/controllers/comment';
import Constants from '../modules/constants';
import ContextMenuController from './contextMenu';
import Dialog from '../modules/dialog';
import DropdownController from './dropdown';
import EpicModel from '../models/epic';
import EpicStateModel from '../models/epicState';
import EstimateScaleModel from '../models/estimateScale';
import * as Event from '../_frontloader/event';
import ExternalLinkModel from '../models/externalLink';
import Format from '../modules/format';
import Globals from '../_frontloader/globals';
import InPlaceEditorController from './inPlaceEditor';
import InPlaceTextareaController from './inPlaceTextarea';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import IntegrationsController from '../../../integrations/js/controllers/integrations';
import Is from '../modules/is';
import IterationModel from '../models/iteration';
import LabelModel from '../models/label';
import Log from '../modules/log';
import MessageController from './message';
import NotificationsController from './notifications';
import OrganizationModel from '../models/organization';
import ProfileModel from '../models/profile';
import ProjectModel from '../models/project';
import PullRequestModel from '../models/pullRequest';
import Router from '../_frontloader/router';
import StoryDialogController from './storyDialog';
import StoryHistoryModel from '../models/storyHistory';
import StoryLinkController from './storyLink';
import StoryLinkModel from '../models/storyLink';
import StoryModel from '../models/story';
import TaskController from './task';
import TaskModel from '../models/task';
import TeamModel from '../models/team';
import Tests from '../modules/tests';
import Tooltip from '../modules/tooltip';
import UploaderController from './uploader';
import UserModel from '../models/user';
import UserWarning from './userWarning';
import Utils from '../modules/utils';
import View from '../modules/view';
import WorkflowModel from '../models/workflow';
import { fetchAll } from 'data/entity/user';
import { diffString } from 'utils/jsdiff';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const GIT_HELPERS_TAB_OPTIONS = {
  LINK_TO_BRANCH: 'link-to-branch',
  LINK_TO_COMMIT: 'link-to-commit'
};
exports.renderBlockerBadge = () => View.renderComponentDelayed({
  componentKey: 'blockerBadge',
  component: BlockerBadge
}).html;
exports.init = () => {
  Event.onlyOn('branchHistoryFetched.CoreStoryController', story => {
    const element = StoryModel.getElements(story);
    if (element.length > 0) {
      exports.updateCodeComponent(story, element);
    }
  });
  Event.onlyOn('storyHistoryFetched.CoreStoryController', story => {
    const element = StoryModel.getElements(story);
    if (element.length > 0) {
      exports.updateHistoryComponent(story, element);
    }
  });
};
exports.getStoryElementInColumn = story => {
  return $('.column #story-' + story.id);
};
exports.onClickPermalink = function (event) {
  exports.copyURLToClipboard.call(this, event);
  exports.updateAnchorHighlight.call(this);
  return false;
};
exports.copyURLToClipboard = function (event) {
  const tooltipDataAttribute = 'data-tooltip';
  const href = this.getAttribute('href');
  const initialTooltip = this.getAttribute(tooltipDataAttribute);

  // Manually close and reinit the tooltip, because modules/Tooltip
  // does not update automatically when a data-attribute is updated.
  Tooltip.close();
  this.setAttribute(tooltipDataAttribute, 'Copied!');
  Tooltip.initTooltip(event);
  setTimeout(() => {
    this.setAttribute(tooltipDataAttribute, initialTooltip);
  }, 2000);
  copyToClipboard(`${window.location.origin}${href}`);
};
exports.renderLabels = story => {
  const html = StoryLabelsTemplate.render({
    labels: story.labels || [],
    readOnly: Is.readOnly(UserModel.getLoggedInUserPermission())
  });
  $('.story-' + story.id + ' .labels-container').html(html);
};
exports.getPullRequestTooltip = function () {
  const pullRequest = Utils.getModelFromContext(this, 'PullRequest');
  return PullRequestLinkTooltipTemplate.render({
    pullRequest
  });
};
exports.getProjectBadgeTooltip = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const project = story.project || ProjectModel.getById(story.project_id);
  return 'This Story is in the <span class="fa fa-circle" style="margin: 0 2px 0 3px; color: ' + project.color + '"></span> ' + Format.sanitize(project.name) + ' Project.';
};
exports.getIterationBadgeTooltip = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const iteration = story.iteration || IterationModel.getById(story.iteration_id);
  return StoryIterationBadgeTooltipTemplate.render({
    status: capitalize(iteration.status),
    iteration: story.iteration.name,
    previous_iteration_ids: story.previous_iteration_ids
  });
};
exports.getTaskBadgeTooltip = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const numTasksTotal = (story.task_ids || []).length;
  const numTasksCompleted = StoryModel.getNumberOfCompletedTasks(story);
  let tooltip = numTasksTotal === 1 ? `This Story has 1 Task, ${numTasksCompleted === 1 ? 'and it is complete.' : 'and it is not complete.'}` : `This Story has ${numTasksCompleted} of ${numTasksTotal} Tasks completed.`;
  if (numTasksCompleted === numTasksTotal) {
    tooltip += Utils.sample([' ✅', ' 🎉', ' 👍', ' 🏆']);
  }
  return tooltip;
};
let renderedStoryTypeCustomIcons;
exports.getStoryTypeCustomIcon = story => {
  renderedStoryTypeCustomIcons = renderedStoryTypeCustomIcons || {
    feature: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 12,
      fill: "var(--iconYellowColor)"
    }, ___EmotionJSX(Icon, {
      icon: "Feature"
    }))),
    bug: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 12,
      fill: "var(--iconRedColor)"
    }, ___EmotionJSX(Icon, {
      icon: "Bug"
    }))),
    chore: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 12,
      fill: "var(--iconGrayHoverColor)"
    }, ___EmotionJSX(Icon, {
      icon: "Chore"
    })))
  };
  return renderedStoryTypeCustomIcons[story.story_type] || renderedStoryTypeCustomIcons.feature;
};
exports.getSpecialTagsForStory = story => {
  const tags = [];
  if (story.iteration && Tests.usesIterations()) {
    const {
      name,
      status
    } = story.iteration;
    let fill;
    if (status === 'done') {
      fill = 'var(--iconGreenColor)';
    } else if (status === 'started') {
      fill = 'var(--iconBlueColor)';
    } else {
      fill = 'currentColor';
    }
    tags.push({
      text: name,
      annotation: story.previous_iteration_ids && story.previous_iteration_ids.length > 0 ? '+' + story.previous_iteration_ids.length : undefined,
      tooltipFn: 'App.Controller.Story.getIterationBadgeTooltip',
      className: `story-iteration ${status}`,
      customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
        width: 13,
        fill: fill
      }, ___EmotionJSX(Icon, {
        icon: "Iteration"
      })))
    });
  }
  return tags;
};

/**
 * @deprecated Use the New React Story Card widget instead. Not
 * removed yet because its used in Command Bar.
 */
exports.getNewBadgesForStory = story => {
  let badges = [];
  const hasBlockedLabel = LabelModel.hasLabel(story, 'blocked');
  const hasBlockerLabel = LabelModel.hasLabel(story, 'blocker');
  const hasBlockedLabelText = hasBlockedLabel ? ', and has the "blocked" label.' : '.';
  const hasBlockerLabelText = hasBlockerLabel ? ', and has the "blocker" label.' : '.';
  const workflowStateIcon = StoryModel.getWorkflowStateIcon(story);
  badges.push({
    className: 'story-id',
    customIcon: exports.getStoryTypeCustomIcon(story),
    text: '#' + story.id,
    iconRight: workflowStateIcon
  });
  const projectBadge = OptionalProject.getStoryBadge(story);
  if (projectBadge) {
    badges.push(projectBadge);
  }
  if (!EstimateScaleModel.isDisabled() && _.isNumber(story.estimate)) {
    badges.push({
      tooltip: 'This Story has an estimate of ' + Format.pluralize(story.estimate, 'point', 'points') + '.',
      className: 'has-estimate',
      text: story.estimate + '',
      // Coerce to a string, otherwise we won't display the "0".
      customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
        width: 12
      }, ___EmotionJSX(Icon, {
        icon: "Estimate",
        fill: "currentColor"
      })))
    });
  }
  if (story.task_ids && story.task_ids.length > 0) {
    // Using story.tasks here because we only get num_tasks_completed back in story slim map.
    const numTasksCompleted = StoryModel.getNumberOfCompletedTasks(story);
    badges.push({
      tooltipFn: 'App.Controller.Story.getTaskBadgeTooltip',
      className: numTasksCompleted === story.task_ids.length ? 'story-tasks-badge-completed' : 'story-tasks-badge',
      text: numTasksCompleted + '/' + story.task_ids.length,
      icon: 'fa-check-square'
    });
  }
  if (story.deadline) {
    badges.push({
      tooltip: 'This Story has a due date of ' + story.formatted_deadline + '.',
      className: story.is_overdue ? 'is-overdue' : 'has-deadline',
      text: story.formatted_short_deadline,
      customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
        width: 12
      }, ___EmotionJSX(Icon, {
        icon: "Calendar",
        fill: "currentColor"
      })))
    });
  }
  if (story.blocked) {
    const blockedStories = StoryLinkModel.getBlockersCount(story);
    badges.push({
      tooltip: 'This Story is blocked by ' + Format.pluralize(blockedStories, 'Story', 'Stories') + hasBlockedLabelText,
      className: 'is-blocked',
      text: blockedStories,
      icon: 'fa-square'
    });
  } else if (hasBlockedLabel) {
    badges.push({
      tooltip: 'This Story has the "blocked" label.',
      className: 'is-blocked',
      icon: 'fa-square'
    });
  }
  if (story.blocker) {
    const blockerStories = StoryLinkModel.getBlockedByCount(story);
    badges.push({
      tooltip: 'This Story is blocking ' + Format.pluralize(blockerStories, 'Story', 'Stories') + hasBlockerLabelText,
      className: 'is-blocker',
      text: blockerStories,
      icon: 'fa-exclamation-triangle'
    });
  } else if (hasBlockerLabel) {
    badges.push({
      tooltip: 'This Story has the "blocker" label.',
      className: 'is-blocker',
      icon: 'fa-exclamation-triangle'
    });
  }
  const daysOld = ageInDays(story.updated_at);
  const isStale = StoryModel.isStale(story, daysOld);
  if (isStale) {
    badges.push({
      tooltip: 'This Story was last updated ' + Format.pluralize(daysOld, 'day', 'days') + ' ago.',
      className: 'is-stale',
      text: daysOld,
      icon: StoryModel.getStaleIcon(story.project, daysOld)
    });
  }
  if (ExternalLinkModel.getExternalLinksCount(story)) {
    badges = badges.concat(ExternalLinkModel.getBadgesForStory(story));
  }
  const docCount = story.stats && story.stats.num_related_documents;
  if (Tests.usesWrite() && docCount) {
    badges.push({
      className: 'doc-count',
      customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
        width: 12
      }, ___EmotionJSX(Icon, {
        icon: "Document",
        fill: "currentColor"
      }))),
      text: docCount,
      tooltip: `${Format.pluralize(docCount, 'Doc', 'Docs')}`
    });
  }
  return badges;
};
exports.getDeadlineClass = story => {
  let className = '';
  if (!StoryModel.isDoneState(story)) {
    className = Utils.getDeadlineClass(story);
  }
  return className;
};
let lastStoryUpdate;
exports.updateComponents = (story, element, options) => {
  options = {
    onUpdate: () => {},
    ...options
  };
  element = $(element);

  // We don't force re-render on story lookup anymore, so do this regardless.
  StoryLinkController.updateStoryLinkComponent(story, element);
  const hasChanges = lastStoryUpdate !== story.updated_at;
  lastStoryUpdate = story.updated_at;
  if (hasChanges || options.force) {
    Log.debug('Story ' + story.id + ' has changed, updating components...');
    StoryModel.normalizeStoryDetails(story);
    exports.updateNameComponent(story, element);
    exports.updateExternalLinkComponent(story, element);
    exports.updateArchivedState(story, element);
    exports.updateTaskComponents(story, element);
    exports.updateLinkedBranchComponent(story, element);
    CommentController.updateComments(story);
    exports.updateCodeComponent(story, element);
    exports.updateHistoryComponent(story, element);
    exports.updateAttributeComponent(story, element);
    options.onUpdate({
      hasRenderedStoryData: StoryModel.isFullyLoaded(story),
      element
    });
  }
  $('video').on({
    mouseenter: function () {
      this.setAttribute('controls', 'controls');
    },
    mouseleave: function () {
      this.removeAttribute('controls');
    }
  });
};
const STILL_LOADING_CLASS_NAME = 'still-loading';
const getStoryDetails = element => element.find('.story-details');
const removeLoadingFlag = element => getStoryDetails(element).removeClass(STILL_LOADING_CLASS_NAME);
exports.removeLoadingIndicator = element => {
  removeLoadingFlag(element);
};
exports.updateNameComponent = (story, element) => {
  element.find('.story-name').html(StoryModel.getFormattedName(story));
};
exports.updateExternalLinkComponent = (story, element) => {
  const container = element.find('.external-links');
  if (container.length > 0) {
    const isReadOnly = Is.readOnly(UserModel.getLoggedInUserPermission());
    Object.assign({
      readOnly: isReadOnly
    }, story);
  }
};
exports.updateArchivedState = (story, element) => {
  const storyContainer = $('.story-container');
  const html = StoryArchivedMessageTemplate.render(story);
  if (story.archived) {
    storyContainer.addClass('archived');
  } else {
    storyContainer.removeClass('archived');
  }
  element.find('.archived-state').html(html);
};
exports.updateTaskComponents = (story, element) => {
  let n = 0;
  const taskParent = element.find('.sortable-tasks');
  if (taskParent.length === 0) {
    return;
  }
  const taskElements = taskParent.find('.task');
  let activeTask = null;
  let activeTaskValue = '';
  let activeTaskSelection = null;
  _.get(story, 'tasks', []).forEach(task => {
    if (taskElements[n]) {
      const element = taskElements.eq(n);
      if (Utils.data(element, 'id') === task.id) {
        exports.updateTaskComponent(task, element);
      } else {
        const inputElement = taskElements.eq(n).find('.task-description-editor');
        if ($(document.activeElement).is(inputElement)) {
          activeTask = Utils.getModelFromContext(taskElements[n]);
          activeTask.componentIsUpdating = true;
          activeTaskValue = inputElement.val();
          activeTaskSelection = inputElement.getSelection();
        }
        const html = TaskTemplate.render(task);
        View.replaceElement(taskElements.eq(n), html);
      }
    } else {
      taskParent.append(TaskTemplate.render(task));
    }
    n++;
  });
  for (; n < taskElements.length; n++) {
    taskElements.eq(n).remove();
  }
  if (n) {
    taskParent.find('.none-found').remove();
  }

  // activeTask might already be null, so we need to check for activeTaskValue.
  if (activeTaskValue) {
    const editingTaskElement = TaskModel.toElement(activeTask);
    if (editingTaskElement.length > 0) {
      // Somebody moved this task while the current user was editing.
      editingTaskElement.addClass('editing').find('.task-description-editor').val(activeTaskValue).focus().setSelection(activeTaskSelection.start, activeTaskSelection.end);
    }
    delete activeTask.componentIsUpdating;
  }
  TaskController.redrawTaskCounts();
};
exports.updateTaskComponent = (task, element) => {
  element.find('.task-description-inner').html(task.formatted_description);
  if (element.find('.task-description-editor').is(':hidden')) {
    element.find('.task-description-editor').val(task.description);
  }
  if (task.complete) {
    element.addClass('completed-task').find('.fa-square-o').removeClass('fa-square-o').addClass('fa-check-square');
  } else {
    element.removeClass('completed-task').find('.fa-check-square').removeClass('fa-check-square').addClass('fa-square-o');
  }
};
function constructPRTitle(story) {
  const productAreaFieldId = getIdForCanonicalName(OpinionatedFieldType.PRODUCT_AREA);
  const productAreaName = (story.custom_fields ?? []).find(_ref2 => {
    let {
      field_id
    } = _ref2;
    return field_id === productAreaFieldId;
  })?.value?.toLowerCase();
  const scope = productAreaName ? `(${productAreaName})` : ``;
  return (story.story_type === StoryTypes.BUG && 'fix' || story.story_type === StoryTypes.FEATURE && 'feat' || story.story_type) + `${scope}: [sc-${story.id}] ${story.name}`;
}
exports.updateLinkedBranchComponent = (story, element) => {
  const container = element.find('.story-branches-container');
  if (container.length > 0) {
    const formattedStoryTitle = constructPRTitle(story);
    StoryModel.getSoloBranches(story).forEach(branch => {
      const repo = branch.repository;
      switch (repo?.type) {
        case 'bitbucket':
          branch.open_pr_url = `${repo.url}/pull-requests/new?source=${branch.name}`;
          break;
        case 'github':
          branch.open_pr_url = `${repo.url}/compare/${branch.name}?expand=1&body=${encodeURIComponent(`Story details: ${story.app_url}`)}&title=${encodeURIComponent(formattedStoryTitle)}`;
          break;
        case 'gitlab':
          branch.open_pr_url = `${repo.url}/-/merge_requests/new?merge_request[source_branch]=${branch.name}&merge_request[title]=${encodeURIComponent(formattedStoryTitle)}&merge_request[description]=Story details: ${story.app_url}`;
          break;
      }
    });
    const html = StoryLinkedBranchesTemplate.render(story);
    container.html(html);
  }
};
exports.renderOverlappedPrs = (storyId, prId) => {
  return View.renderComponentDelayed({
    componentKey: `overlapping-prs-${prId}`,
    component: OverlappingPrs,
    props: {
      storyId,
      prId
    }
  }).html;
};
exports.updateCodeComponent = (story, element) => {
  const container = element.find('.story-code');
  if (container.length > 0) {
    const html = StoryCodeTemplate.render(story);
    container.html(html);
  }
};
exports.updateHistoryComponent = (story, element) => {
  const container = element.find('.story-timeline');
  if (container.length > 0) {
    const html = StoryTimelineTemplate.render(story);
    container.html(html);
  }
};
exports.updateAttributeComponent = (story, element) => {
  const container = element.find('.right-column');
  const attributes = element.find('.story-attributes');
  if (container.length > 0 && attributes.length < 1) {
    View.renderComponent({
      mountNode: container[0],
      componentKey: 'storyAttributes',
      component: StoryAttributes,
      props: {
        storyId: story.id,
        onRequesterChange: updateRequesterField(story),
        onOwnerChange: updateOwnerField(story),
        onInviteRequester: async invitedUser => {
          try {
            const inviteRequest = await getInvitePromise(['story_card', 'requester_field'], invitedUser);
            fetchAll(() => {
              const updatedStory = {
                ...story,
                requested_by_id: inviteRequest.permission_id
              };
              updateRequesterField(updatedStory)(null, inviteRequest.permission_id);
            });
            return inviteRequest;
          } catch (e) {
            return e;
          }
        },
        onInviteOwner: async invitedUser => {
          try {
            const inviteRequest = await getInvitePromise(['story_card', 'owner_field'], invitedUser);
            fetchAll(() => {
              const newOwners = story.owner_ids.concat([inviteRequest.permission_id]);
              const updatedStory = {
                ...story,
                owner_ids: newOwners
              };
              updateOwnerField(updatedStory)(newOwners);
            });
            return inviteRequest;
          } catch (e) {
            return e;
          }
        }
      }
    });
  }
};
exports.renderStoryTooltip = function (story) {
  story = _.get(story, 'id') ? story : Utils.getModelFromContext(this, 'Story');
  let html = '';
  if (story) {
    html = StoryTooltipTemplate.render(story);
    if (!StoryModel.isValid(story)) {
      _fetchStoryAndUpdateTooltip(story);
    }
  } else {
    const id = Number.parseInt(this.dataset.id);
    html = StoryTooltipTemplate.render({
      id
    });
    _fetchStoryAndUpdateTooltip({
      id
    });
  }
  return html;
};
function _fetchStoryAndUpdateTooltip(story) {
  StoryModel.fetchStory(story.id, () => {
    const parent = $('.story-tooltip');
    if (parent.length) {
      const loadedStory = Utils.getModelFromContext(parent, 'Story');
      let html = '';
      if (loadedStory.id === story.id) {
        html = StoryTooltipTemplate.render(_.assign({
          loaded: true
        }, loadedStory));
      } else if (!loadedStory) {
        html = StoryTooltipTemplate.render({
          deleted: true
        });
      }
      if (html) {
        View.replaceElement(parent, html);
        Tooltip.resetPosition();
      }
    }
  });
}
exports.storyProjectTooltip = function () {
  const project = Utils.getModelFromContext(this);
  return 'Project: ' + Format.sanitize(project.name);
};

// ----- Actions -----

exports.saveName = function () {
  const story = Utils.getModelFromContext(this);
  const editorElement = $(this).closest('.in-place-editor');
  const newName = editorElement.find('textarea').val().trim();
  if (!newName) {
    MessageController.error('A title is required.');
    return false;
  }
  if (story.name !== newName) {
    $('.story-' + story.id).find('.story-name').css('opacity', 0.5);
    StoryModel.saveTitle(story, newName, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'The original story name has been restored.'
        });
      }
      $('.story-' + story.id).find('.story-name').css('opacity', 1);
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.cancelNameEdit = function () {
  const editorElement = $(this).closest('.in-place-editor');
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.reverseStoryHistoryOrder = story => {
  const element = StoryModel.getElements(story);
  if (element.length > 0) {
    exports.updateHistoryComponent(story, element);
  }
};
exports.toggleArchivedStoryLinks = function () {
  const me = $(this);
  const archivedStoryContainer = me.closest('.archived-relationships').find('.archived-story-links');
  const story = Utils.getModelFromContext(this);
  if (story.showingArchivedStories) {
    me.html(me.html().replace('Hide', 'Show'));
    story.showingArchivedStories = false;
  } else {
    me.html(me.html().replace('Show', 'Hide'));
    story.showingArchivedStories = true;
  }
  archivedStoryContainer.slideToggle(160);
  return false;
};
exports.showAllCommits = function () {
  $(this).closest('.show-all-commits').hide();
  $(this).closest('.commit').addClass('expanded-commits').find('.commit-link').show();
  return false;
};
exports.createTemplateFromStory = story => {
  const templateStory = StoryModel.transformToTemplate(story);
  AddNewStoryController.updateState(templateStory);
  AddNewStoryController.render({
    isRenderedFromTemplate: true
  });

  // We need to wait for this dropdown to close before closing the dialog.
  setTimeout(Dialog.close, 0);

  // Handle case where user opens story from activity feed.
  setTimeout(NotificationsController.close, 0);
  return false;
};
exports.convertStoryToEpic = story => {
  if (window.confirm('This Story will be converted to an Epic and then archived. You will be able to find ' + 'the original Story in a comment on the Epic.')) {
    MessageController.info('Creating Epic...', {
      secondary: 'Stories in this Epic will be created from the tasks available.'
    });
    EpicModel.saveNew({
      name: story.name,
      description: story.description,
      deadline: story.deadline,
      epic_state_id: EpicStateModel.getFirstUnstarted().id,
      owner_ids: story.owner_ids,
      follower_ids: story.follower_ids,
      labels: story.labels,
      group_id: story.group_id
    }, function (err, epic) {
      if (err) {
        MessageController.error(err, {
          secondary: "The Story couldn't be converted into an Epic."
        });
      } else {
        Async.eachInSequenceThen([TaskModel.createStoriesFromTasks.bind(this, _.assign({}, story, {
          epic_id: epic.id
        })), StoryModel.saveComment.bind(this, story, `This Story was converted to the Epic [${epic.name}](${epic.url})`), StoryModel.archiveStory.bind(this, story), EpicModel.saveComment.bind(this, epic, 'This Epic was converted from the Story sc-' + story.id)], () => {
          MessageController.success('Epic successfully created.', {
            secondary: 'Redirecting you now...'
          });
          setTimeout(() => {
            Dialog.close();
            Router.navigateTo({
              url: epic.url
            });
          }, 2000);
        });
      }
    });
  }
  return false;
};
exports.openStoryActionsDropdown = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const isArchived = StoryModel.isArchived(story);
  let dropdown = null;
  Tooltip.close();
  function _openCreateBranchDialog() {
    DropdownController.close(dropdown);
    return exports.openCreateBranchDialog(story);
  }
  function _moveStoryToTop() {
    exports.reorderStory(story, {
      first: true
    });
  }
  function _moveStoryToBottom() {
    exports.reorderStory(story, {
      last: true
    });
  }
  let actions = [];

  // Even though GitHub isn't enabled, this is kept here for discoverability.
  if (!IntegrationsController.isVCSEnabled()) {
    actions = actions.concat([{
      name: 'Git Helpers',
      value: _openCreateBranchDialog,
      iconLeft: 'fa-code-fork'
    }, {
      hr: true
    }]);
  }
  if (StoryModel.changePriority && Is.storiesPage()) {
    actions = actions.concat([{
      name: 'Move to top',
      shortcut: Is.mac() ? 'cmd shift &uarr;' : 'ctrl shift &uarr;',
      value: _moveStoryToTop,
      iconLeft: 'fa-arrow-up'
    }, {
      name: 'Move to bottom',
      shortcut: Is.mac() ? 'cmd shift &darr;' : 'ctrl shift &darr;',
      value: _moveStoryToBottom,
      iconLeft: 'fa-arrow-down'
    }, {
      hr: true
    }]);
  }
  actions = actions.concat([{
    name: 'Duplicate Story...',
    value: _.wrap((() => {
      const storyTemplate = {
        ...story
      };

      // We don't want to duplicate the workflow state as we always want to
      // create a story in the left-most state (workflow's default workflow state),
      // unless it's a backlog story.
      if (storyTemplate.stateObject?.type !== 'backlog') {
        delete storyTemplate.stateObject;
        delete storyTemplate.workflow_state_id;
      }
      return storyTemplate;
    })(), exports.createTemplateFromStory),
    customIconLeft: IconStoryTemplate.render()
  }, {
    name: 'Convert Story to Epic',
    value: _.wrap(story, exports.convertStoryToEpic),
    customIconLeft: IconFlagOutlinedTemplate.render()
  }, {
    hr: true
  }, {
    name: isArchived ? 'Unarchive Story' : 'Archive Story',
    shortcut: 'shift A',
    value: function () {
      if (isArchived) {
        return exports.unarchiveStory.call(this, story);
      } else {
        return exports.archiveStory.call(this, story);
      }
    },
    iconLeft: 'fa-archive'
  }]);
  if (isArchived) {
    actions.push({
      name: 'Delete Story',
      shortcut: 'shift D',
      value: () => exports.deleteStory.call(this, story),
      iconLeft: 'fa-trash-o',
      id: 'story-delete-link'
    });
  }
  dropdown = DropdownController.open({
    items: actions,
    target: this,
    targetSelector: '#open-story-actions-dropdown',
    title: 'Story Actions',
    topOffset: 0,
    width: 240
  });
  return false;
};
exports.reorderStory = (story, options) => {
  const priorityChanges = StoryModel.changePriority(story, options);
  StoryModel.serverSave(story, priorityChanges, _.assign({
    callback: err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'The story position has not been updated.'
        });
      } else {
        const column = ColumnModel.getById(story.workflow_state_id);
        let message = 'Story position updated in <strong>' + Format.sanitizeAndEmojify(column.name) + '</strong>.';
        if (options.first) {
          message = 'Story moved to the top of <strong>' + Format.sanitizeAndEmojify(column.name) + '</strong>.';
        } else if (options.last) {
          message = 'Story moved to the bottom of <strong>' + Format.sanitizeAndEmojify(column.name) + '</strong>.';
        }
        MessageController.success('<strong>' + Format.sanitizeAndEmojify(story.name) + '</strong> has been updated.', {
          secondary: message
        });
      }
    }
  }, options));
};
exports.unarchiveStory = function (story) {
  if (!StoryModel.isValid(story)) {
    story = Utils.getModelFromContext(this);
  }
  $('.story-archived').animate({
    opacity: 0.5
  }, 200);
  StoryModel.unarchiveStory(story, err => {
    if (err) {
      $('.story-archived').css('opacity', 1);
      MessageController.error(err, {
        secondary: 'Unable to unarchive <strong>' + Format.sanitize(story.name) + '</strong>.'
      });
    } else {
      $('.story-archived').animate({
        height: 0,
        opacity: 0
      }, 200);
    }
  });
  return false;
};
exports.archiveStory = function (story) {
  if (!StoryModel.isValid(story)) {
    story = Utils.getModelFromContext(this);
  }
  ArchiveWarningController.story.call(this, story, {
    onApprove: () => {
      StoryModel.archiveStory(story, err => {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to archive <strong>' + Format.sanitize(story.name) + '</strong>.'
          });
        }
      });
    }
  });
  return false;
};
exports.deleteStory = function (story) {
  if (!StoryModel.isValid(story)) {
    story = Utils.getModelFromContext(this, 'Story');
  }
  const storyNameHtml = `<strong class="title">${Format.sanitize(story.name)}</strong>`;
  UserWarning.open({
    target: this,
    id: 'delete-story',
    title: 'Are you sure you want to delete this Story?',
    description: `Deleting this Story will permanently remove it from ${BRAND.NAME}, and you will not be able to recover it.`,
    question: `Continue deleting ${storyNameHtml}?`,
    onApprove: () => {
      StoryModel.deleteStory(story, err => {
        if (err) {
          MessageController.error(err, {
            secondary: `We were unable to delete ${storyNameHtml}.`
          });
        } else {
          Dialog.close();
          BulkSelectionModel.removeFromSelection(story.id);
          MessageController.success(`Story <strong>#${story.id}</strong> deleted.`);
        }
      });
    }
  });
  return false;
};
exports.openGitHelpers = function () {
  Tooltip.close();
  const story = Utils.getModelFromContext(this);
  return exports.openCreateBranchDialog(story);
};
exports.openCreateBranchDialog = story => {
  const activeTab = ApplicationState.get('GitHelpers.ActiveTab') || GIT_HELPERS_TAB_OPTIONS.LINK_TO_BRANCH;
  const html = GitBranchDialogTemplate.render({
    story,
    activeTab
  });
  // This will only return the button in the story dialog, even if multiple parent elements are found.
  const target = StoryModel.getElements(story).find('.story-actions-button');
  DropdownController.open({
    items: [],
    header: html,
    showCloseButton: true,
    targetSelector: '#open-git-helpers-dropdown',
    target,
    width: Is.mobile() ? 320 : 530
  });
  return false;
};
exports.switchToLinkToBranch = () => {
  ApplicationState.set('GitHelpers.ActiveTab', GIT_HELPERS_TAB_OPTIONS.LINK_TO_BRANCH);
  return false;
};
exports.switchToLinkToCommit = () => {
  ApplicationState.set('GitHelpers.ActiveTab', GIT_HELPERS_TAB_OPTIONS.LINK_TO_COMMIT);
  return false;
};
exports.openFileUploadDropdown = function () {
  const storySelector = AddNewStoryController.isOpen() ? '.add-new-story' : '.story';
  const storyElement = $(this).closest(storySelector);
  function computerUpload() {
    storyElement.find('.html-file-upload').click();
  }
  const actions = [];
  actions.push({
    name: 'My Computer',
    value: computerUpload,
    iconLeft: 'fa-hdd-o'
  });
  if (OrganizationModel.isIntegrationEnabled('dropbox')) {
    actions.push({
      name: 'Dropbox',
      value: UploaderController.uploadUsingDropbox.bind(this),
      iconLeft: 'fa-dropbox'
    });
  }
  if (OrganizationModel.isIntegrationEnabled('google-drive')) {
    actions.push({
      name: 'Google Drive',
      value: UploaderController.uploadUsingGoogle.bind(this),
      iconLeft: 'fa-google'
    });
  }
  if (OrganizationModel.isIntegrationEnabled('box')) {
    actions.push({
      name: 'Box',
      value: UploaderController.uploadUsingBox.bind(this),
      iconLeft: 'fa-cloud-upload'
    });
  }
  DropdownController.open({
    items: actions,
    className: 'oversized',
    footer: '<div class="autocomplete-footer-tip"><strong>Tip:</strong> ' + 'you can also attach files by dragging and dropping them into this story.</div>',
    target: this,
    targetSelector: '#story-dialog-file-upload-dropdown',
    title: 'Attach from...',
    width: 200,
    onClose: () => {
      if (AddNewStoryController.isOpen()) {
        AddNewStoryController.updateState();
      } else {
        const story = Utils.getModelFromContext(this);
        if (story) {
          StoryModel.trigger('storyTouched', story);
        }
      }
    }
  });
  return false;
};
exports.deleteFileAttachment = function () {
  const element = $(this).closest('.comment');
  const file_id = Utils.data(element, 'id');
  if (AddNewStoryController.isOpen()) {
    AddNewStoryController.updateState({
      files: _.reject(AddNewStoryController.getState().files, {
        id: file_id
      }),
      file_ids: _.without(AddNewStoryController.getState().file_ids, file_id)
    });
    View.fadeAndSlideUp(element);
  } else {
    const story = Utils.getModelFromContext(this, 'Story');
    if (window.confirm('Are you sure you want to remove this file?')) {
      View.fadeAndSlideUp(element, () => {
        StoryModel.deleteFileAttachment(story, file_id);
      });
    }
  }
  return false;
};
exports.deleteLinkedFileAttachment = function () {
  const element = $(this).closest('.comment');
  const fileID = Utils.data(element, 'id');
  if (AddNewStoryController.isOpen()) {
    const state = AddNewStoryController.getState();
    const updatedFileIDs = _.pull(state.linked_file_ids, fileID);
    AddNewStoryController.updateState({
      linked_file_ids: updatedFileIDs
    });
    View.fadeAndSlideUp(element);
  } else {
    const story = Utils.getModelFromContext(this, 'Story');
    if (window.confirm('Are you sure you want to remove this file?')) {
      View.fadeAndSlideUp(element, () => {
        StoryModel.deleteLinkedFileAttachment(story, fileID);
      });
    }
  }
  return false;
};
exports.removeLabel = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const label = Utils.getModelFromContext(this);
  const tagElement = $(this).closest('.tag');
  if (!label || tagElement.hasClass('is-being-deleted')) {
    return false;
  }
  ContextMenuController.close();
  tagElement.addClass('is-being-deleted').css({
    opacity: 0.5
  }).find('.delete, .more-items').removeAttr('data-context-menu').removeClass('fa-times fa-ellipsis-h').addClass('fa-spinner fa-spin');
  StoryModel.removeLabel(story, label, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to remove <strong>' + Format.sanitizeAndEmojify(label.name) + '</strong>.'
      });
      exports.renderLabels(story);
    }
  });
  return false;
};
exports.warnIfDirty = (type, entity, prop) => {
  const $warning = $(`.story-${entity.id} .dirty-save-warning`);
  const textareaIsDirty = InPlaceTextareaController.isDirty(type, entity, prop);
  if (textareaIsDirty) {
    $warning.show();
  } else {
    $warning.hide();
  }
};
exports.cancelStoryEdit = (context, statePrefix) => {
  const story = Utils.getModelFromContext(context);
  InPlaceTextareaController.clearSnapshot(story, statePrefix);
  InPlaceTextareaController.destroy(context);
  return false;
};
exports.openStoryTypeDropdown = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const story = Utils.getModelFromContext(this);
  const container = $(this);
  const dropdown = DropdownController.open({
    items: Utils.getStoryTypes(story),
    targetSelector: '#story-dialog-story-type-dropdown',
    title: 'Update Story Type',
    onApply: value => {
      if (value === story.story_type) {
        return false;
      }
      container.addClass('is-updating');
      const update = {
        story_type: value
      };
      StoryModel.serverSave(story, update, {
        callback: err => {
          container.removeClass('is-updating');
          if (err) {
            MessageController.error(err, {
              secondary: 'The story type has not been updated.'
            });
          }
        }
      });
    },
    onClose: () => {
      Utils.deferFocus(container);
    },
    target: this,
    topOffset: 0,
    width: 200
  });
  if (dropdown) {
    dropdown.dropdownElement.find('.active').focus();
  }
  return false;
};

/*
  DEPENDENCIES

  DropdownController
  DropdownController

  UserModel
  StoryMOdel

  Is
  Log
  Utils

  0 cyclical
*/

exports.openDeadlineDatepicker = function (overrides) {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const context = overrides.context || $(this);
  const story = overrides.story || Utils.getModelFromContext(this);
  const utcOffset = OrganizationModel.getCurrentUtcOffset();
  const dropdown = DropdownController.open({
    footer: `<div class="datepicker unselectable"></div>
    <div class="autocomplete-footer-tip" id="datepicker-tip">
      Due dates are relative to your Workspace's UTC offset (${utcOffset}).</div>`,
    beforeOutsideClick: DropdownController.isClickOnDatepicker,
    onClose: () => {
      Utils.destroyDatepicker(dropdown.dropdownElement.find('.datepicker'));
    },
    target: context,
    targetSelector: '#story-dialog-deadline-dropdown',
    width: Is.mobile() ? 229 : 410
  });
  if (!dropdown) {
    return false;
  }
  dropdown.dropdownElement.find('.datepicker').datepicker({
    defaultDate: new Date(story.formatted_deadline) || 0,
    nextText: 'Next',
    prevText: 'Previous',
    numberOfMonths: Is.mobile() ? 1 : 2,
    showAnim: '',
    onSelect: dateText => {
      let deadline = null;
      if (moment(dateText).format() !== moment(story.deadline).format()) {
        deadline = moment(dateText).format();
      }
      if (_.isFunction(overrides.callback)) {
        overrides.callback(deadline);
      } else {
        context.addClass('is-updating');
        StoryModel.serverSave(story, {
          deadline
        }, {
          callback: err => {
            context.removeClass('is-updating');
            if (err) {
              MessageController.error(err, {
                secondary: 'The story due date has not been updated.'
              });
            }
          }
        });
      }
      DropdownController.close(dropdown);
      Utils.deferFocus(context);
    }
  });
  DropdownController.bindToClicksOutsideDropdown(dropdown);
  return false;
};
exports.clearDeadline = function () {
  const story = Utils.getModelFromContext(this);
  const el = $(this);
  el.addClass('fa-plus fa-times').addClass('is-updating');
  StoryModel.serverSave(story, {
    deadline: null
  }, {
    callback: err => {
      if (err) {
        el.addClass('fa-plus fa-times').removeClass('is-updating');
        MessageController.error(err, {
          secondary: 'The story due date has not been updated.'
        });
      }
    }
  });
  return false;
};
exports.addMeAsFollower = function () {
  const story = Utils.getModelFromContext(this);
  const profile = ProfileModel.getCurrentUserProfileDetails();
  const el = $(this).find('.fa');
  el.addClass('is-updating');
  if (!StoryModel.isFollower(story, profile)) {
    StoryModel.addMeAsFollower(story, err => {
      el.removeClass('is-updating');
      exports.handleFollowerShortcutResponse(err);
    });
  }
  return false;
};
exports.removeMeAsFollower = function () {
  const story = Utils.getModelFromContext(this);
  const profile = ProfileModel.getCurrentUserProfileDetails();
  const el = $(this).find('.fa');
  el.addClass('is-updating');
  if (StoryModel.isFollower(story, profile)) {
    StoryModel.removeMeAsFollower(story, err => {
      el.removeClass('is-updating');
      exports.handleFollowerShortcutResponse(err);
    });
  }
  return false;
};
exports.handleFollowerShortcutResponse = err => {
  if (err) {
    MessageController.error(err, {
      secondary: 'Unable to update followers.'
    });
  }
};
exports.editTitle = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  if (Utils.dragDistance() < 10) {
    InPlaceEditorController.open.call(this);
  }
  Updates.delayPollingFrequencyForEditor();
  return false;
};
exports.createLinkedStory = (relationship, state_) => {
  const state = AddNewStoryController.newTemplate();
  state.story_links = [relationship];
  AddNewStoryController.updateState({
    ...state,
    ...state_
  });
  AddNewStoryController.render();

  // We need to wait for this dropdown to close before closing the dialog.
  setTimeout(Dialog.close, 0);

  // Handle case where user opens story from activity feed.
  setTimeout(NotificationsController.close, 0);
  return false;
};
exports.removeExternalLink = function () {
  const story = Utils.getModelFromContext('.story-details');
  const element = $(this);
  const url = Utils.data(element, 'value');
  element.removeClass('fa-trash').addClass('fa-star fa-spin');
  StoryModel.removeExternalLink(story, url, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to remove External Link.'
      });
    }
    exports.updateExternalLinkComponent(StoryModel.getById(story.id), $('.story-details'));
  });
  return false;
};
exports.toggleSelection = function (e) {
  const story = Utils.getModelFromContext(this, 'Story');
  BulkEditorController.toggleSelection(story);
  const lastToggledStoryId = Globals.get('lastToggledStoryId');
  if (e.shiftKey && lastToggledStoryId && lastToggledStoryId !== story.id) {
    BulkEditorController.toggleRange(this, story);
  }
  Globals.set('lastToggledStoryId', story.id);
  Event.trigger('storySelectionUpdated', story);
  return false;
};
exports.openActivityFocusDropdown = function () {
  const story = Utils.getModelFromContext(this);
  const whitelist = StoryHistoryModel.getWhiteList();
  const items = [{
    name: 'Important Changes Only',
    note: 'State changes, task completions, archiving',
    value: 'IMPORTANT',
    className: whitelist === 'IMPORTANT' ? 'active' : ''
  }, {
    name: 'Everything',
    note: 'All story events are displayed',
    value: 'EVERYTHING',
    className: whitelist === 'EVERYTHING' ? 'active' : ''
  }];
  DropdownController.open({
    items,
    targetSelector: '#story-dialog-activity-focus-dropdown',
    title: 'Select amount of detail',
    className: 'historical-detail-dropdown',
    target: this,
    onApply: value => {
      StoryHistoryModel.setWhiteList(story, value);
      const element = StoryModel.getElements(story);
      if (element.length > 0) {
        exports.updateHistoryComponent(story, element);
      }
    },
    width: 240
  });
  return false;
};
function _ensureString(str) {
  return typeof str === 'undefined' || str === null ? '' : str;
}
exports.showStoryDiff = function () {
  const model = Utils.data(this, 'model') === 'Activity' ? ActivityModel : StoryHistoryModel;
  const change = model.getById(Utils.data(this, 'id'));
  const prop = Utils.data(this, 'prop');
  let propDiff = ActivityModel.getPropDiff.call(this, change, prop);
  const profile = ProfileModel.getAllDetailsById(change.member_id);

  // TODO: This is a temporary fix until we figure out why the API
  // server isn't returning a change map for some story name updates.
  if (_.isString(propDiff)) {
    propDiff = {
      old: '',
      new: propDiff
    };
  }

  // diffString will escape HTML entity codes, so we don't do that here.
  const oldStr = Format.sanitize(_ensureString(propDiff.old));
  const newStr = Format.sanitize(_ensureString(propDiff.new));
  const diff = diffString(oldStr, newStr);
  const html = DiffTooltipTemplate.render({
    title: 'Story ' + prop + ' change by ' + Format.fullName(profile),
    columns: 2,
    subtitle: moment(change.changed_at).format(Constants.SHORT_DATE_TIME_FORMAT),
    diff: diff.trim().replace(/\n/g, '<br />')
  });
  DropdownController.closeById('text-diff');
  DropdownController.open({
    items: [],
    header: html,
    id: 'text-diff',
    showCloseButton: true,
    target: this,
    targetSelector: '#' + change.id,
    width: 620
  });
  return false;
};
exports.toggleCurrentUserAsOwner = () => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  if (AddNewStoryController.isOpen()) {
    AddNewStoryController.toggleOwnerState(profile);
    AddNewStoryController.renderRequesterOwnerFields();
  } else {
    const story = StoryDialogController.getCurrentlyDisplayedStory();
    if (story) {
      exports.toggleOwner(story, profile);
    }
  }
};
exports.toggleOwner = (story, profile) => {
  StoryModel.toggleOwner(story, profile, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update owners.'
      });
    }
  });
};
exports.toggleCurrentUserAsFollower = () => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  if (AddNewStoryController.isOpen()) {
    AddNewStoryController.toggleFollowerState(profile);
    return true;
  } else {
    const story = StoryDialogController.getCurrentlyDisplayedStory();
    if (story) {
      exports.toggleFollower(story, profile);
      return true;
    } else {
      return false;
    }
  }
};
exports.toggleFollower = (story, profile) => {
  _setStoryUpdatingState(story.id, '.story-followers');
  StoryModel.toggleFollower(story, profile, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to update followers.'
      });
      _revertStoryUpdatingState(story.id, '.story-followers');
    }
  });
};
function _setStoryUpdatingState(id, selector) {
  $('.story-' + id + ' ' + selector).addClass('is-updating');
}
function _revertStoryUpdatingState(id, selector) {
  $('.story-' + id + ' ' + selector).removeClass('is-updating');
}
exports.openTaskOwnerUpdater = function (callback) {
  const task = Utils.getModelFromContext(this, 'Task');
  callback = _.isFunction(callback) ? callback : _.noop;
  const updateTaskOwner = (err, task) => {
    if (!err && task) {
      TaskController.redrawTaskElement(task);
      AutocompleteController.enable();
    } else {
      AutocompleteController.close();
    }
    callback(err, task);
  };
  const value = $(this).attr('data-value');
  const target = $('.context-menu')[0] || this;
  if (!task) {
    Log.log('No task found when opening task owner dropdown!', {
      id: this ? this.id : 'unknown!'
    });
    ContextMenuController.close();
    return false;
  }
  exports.openTaskOwnerUpdaterForTask({
    target,
    task,
    value,
    callback: updateTaskOwner
  });
  return false;
};
exports.openTaskOwnerUpdaterForTask = _ref3 => {
  let {
    target,
    task,
    value,
    callback
  } = _ref3;
  callback = _.isFunction(callback) ? callback : _.noop;
  AutocompleteController.open({
    items: ProfileModel.getItemsForOwnerAutocomplete(task, ProfileModel.mapIDsToProfileDetails(task.owner_ids)),
    closeOnClicksOutside: false,
    multiSelect: true,
    title: 'Update Task Owners',
    noActive: false,
    onApply: profile => {
      // Handle case where user enters non-matching string in input field.
      // This will simply reset the autocomplete to its original state.
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      AutocompleteController.disable();
      AutocompleteController.input.val(profile ? profile.name : 'Nobody');
      if (!profile) {
        TaskModel.removeAllOwners(task, callback);
      } else if (TaskModel.isOwner(task, profile)) {
        TaskModel.removeOwner(task, profile, callback);
      } else {
        TaskModel.addOwner(task, profile, callback);
      }
    },
    showInput: true,
    target,
    value,
    topOffset: 4,
    width: 240
  });
};
exports.openStoryFollowerUpdater = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const story = Utils.getModelFromContext(this);
  return openStoryUpdater(this, {
    getItems: () => ProfileModel.getItemsForFollowerAutocomplete(story),
    footer: (InviteUsersController.getInviteUsersFooter() || '') + '<div class="autocomplete-footer-tip">Followers are ' + 'notified of story activity. Story requesters and owners are automatically added as followers.</div>',
    onResultsUpdate: InviteUsersController.getAutocompleteWithInviteUpdateHandler,
    multiSelect: true,
    targetSelector: '#story-dialog-follower-dropdown',
    title: 'Update Story Followers',
    width: 240,
    onApply: profile => {
      // Handle case where user enters non-matching string in input field.
      // This will simply reset the autocomplete to its original state.
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      if (profile) {
        AutocompleteController.disable();
        if (StoryModel.isFollower(story, profile)) {
          StoryModel.removeFollower(story, profile, callback);
        } else {
          StoryModel.addFollower(story, profile, callback);
        }
      }
      function callback(err) {
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to update followers.'
          });
          AutocompleteController.close();
        } else {
          AutocompleteController.enable();
        }
      }
    }
  });
};
exports.openStoryEpicUpdater = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const story = Utils.getModelFromContext(this);
  return openStoryUpdater(this, {
    getItems: () => EpicModel.getItemsForAutocomplete({
      selectedGroupId: story.group_id
    }),
    preserveCaptions: true,
    key: 'epic_id',
    inputValue: (story.epic || {}).name,
    value: (story.epic || {}).id,
    onResultsUpdate: _ref4 => {
      let {
        hasResults,
        value,
        setNoResults
      } = _ref4;
      if (!hasResults) {
        setNoResults('Enter to create new epic <strong>' + Format.sanitize(value) + '</strong>');
      }
    },
    targetSelector: '#story-dialog-epic-dropdown',
    title: 'Update or Create Epic',
    width: 280,
    onApply: id => {
      if (id) {
        _setStoryUpdatingState(story.id, '.story-epic');
        const epic = EpicModel.getById(id);
        if (!epic) {
          EpicModel.saveNew({
            name: id
          }, (err, epic) => {
            if (err) {
              return callback(err);
            }
            if (epic) {
              StoryModel.addEpic(story, epic, callback);
            }
          });
          return false;
        }
        if (StoryModel.hasEpic(story, epic)) {
          StoryModel.removeEpic(story, callback);
        } else {
          StoryModel.addEpic(story, epic, callback);
        }
      } else if (story.epic_id) {
        _setStoryUpdatingState(story.id, '.story-epic');
        StoryModel.removeEpic(story, callback);
      }
      function callback(err) {
        _revertStoryUpdatingState(story.id, '.story-epic');
        if (err) {
          MessageController.error(err, {
            secondary: 'Unable to update epic.'
          });
        }
      }
    }
  });
};
exports.openStoryStateUpdater = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const story = Utils.getModelFromContext(this);
  const column = ColumnModel.getById(story.workflow_state_id);
  const team = ColumnModel.getTeamFromColumn(column) || TeamModel.getActive();
  const workflowForSelectedProject = WorkflowModel.getById(team.workflow.id);
  return openStoryUpdater(this, {
    getItems: () => getItemsForAutocomplete({
      workflow: workflowForSelectedProject
    }),
    key: 'workflow_state_id',
    targetSelector: '#story-dialog-state-dropdown',
    title: 'Update Workflow State',
    onBeforeUpdate: update => {
      const foundColumn = ColumnModel.getById(update.workflow_state_id);
      if (!foundColumn) {
        return false;
      }
      if (update.workflow_state_id === story.workflow_state_id) {
        return false;
      }
    },
    inputValue: column.name,
    value: column.id,
    width: 240,
    onAfterUpdate: story => {
      _setStoryUpdatingState(story.id, '.story-state');
    },
    callback: err => {
      _revertStoryUpdatingState(story.id, '.story-state');
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to update story state.'
        });
      }
    }
  });
};
exports.openStoryProjectUpdater = function () {
  if (Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const story = Utils.getModelFromContext(this);
  return openStoryUpdater(this, {
    getItems: () => ProjectModel.getFilteredItemsForAutocomplete(story.workflow_id),
    key: 'project_id',
    targetSelector: '#story-project-dropdown-container',
    title: 'Move Story to Project',
    inputValue: Format.sanitize(story.project.name),
    value: story.project_id,
    width: 240,
    onBeforeUpdate: update => {
      if (update.project_id === null) {
        return true;
      }

      // Protect against non-matching input
      // Ref: https://app.shortcut.com/internal/story/1096
      const project = ProjectModel.getById(update.project_id);
      if (!project) {
        return false;
      }
      if (update.project_id === story.project_id) {
        return false;
      }
      if (project.team_id !== story.project.team_id) {
        return true;
      }
    },
    onAfterUpdate: story => {
      _setStoryUpdatingState(story.id, '.story-project');
    },
    callback: err => {
      _revertStoryUpdatingState(story.id, '.story-project');
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to update project.'
        });
      }
    }
  });
};
exports.addStoryLabel = function () {
  const story = Utils.getModelFromContext(this);
  let existingLabels = story.labels || [];
  AutocompleteController.open({
    items: LabelModel.getItemsForLabelsAutocomplete(story),
    footer: '<div class="autocomplete-footer-tip">You can use quotes ' + 'to create labels with spaces, e.g. "label with spaces". Labels can be edited on the Labels page.</div>',
    target: this,
    targetSelector: '#story-dialog-add-label-dropdown',
    onResultsUpdate: _ref5 => {
      let {
        hasResults,
        setNoResults,
        value
      } = _ref5;
      if (!hasResults) {
        setNoResults(LabelModel.createNewLabelText(value));
      }
    },
    onPartialMatch: LabelModel.createNewLabelText,
    multiSelect: true,
    noActive: true,
    placeholder: 'mockup, "tech debt"',
    title: 'Add or Remove Labels',
    showInput: true,
    onApply: label => {
      AutocompleteController.disable();
      if (_.isString(label)) {
        existingLabels = LabelModel.addLabelAsString(existingLabels, label);
      } else {
        existingLabels = LabelModel.addExistingLabel(existingLabels, label);
      }
      _markStoryLabelsAsUpdating(story);
      if (_.isString(label)) {
        exports.saveLabelsToStory(story, existingLabels);
        AutocompleteController.close();
      } else {
        exports.saveLabelsToStory(story, existingLabels, true);
      }
    },
    topOffset: 4,
    width: 240
  });
  return false;
};
function _markStoryLabelsAsUpdating(story) {
  const element = StoryModel.getElements(story);
  element.find('.labels-container .story-labels').addClass('is-updating');
}
exports.saveLabelsToStory = (story, existingLabels, reEnableAutocomplete) => {
  StoryModel.saveLabels(story, existingLabels, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to add label.'
      });
      AutocompleteController.close();
    } else {
      if (reEnableAutocomplete) {
        AutocompleteController.enable();
      }
      story.labels = existingLabels;
    }
    const element = StoryModel.getElements(story);
    element.find('.labels-container .story-labels').removeClass('is-updating');
  });
};
exports.associateBranch = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  // Branch may not be in model as it's deleted, so we reference the ID directly
  const branchId = Utils.data(this, 'id');
  $(this).closest('.history-action').html('<span class="fa fa-spin fa-star"></span>');
  StoryModel.associateBranch(story, branchId, err => {
    if (err) {
      const element = StoryModel.getElements(story);
      if (element.length) {
        exports.updateHistoryComponent(story, element);
      }
      MessageController.error(err, {
        secondary: 'Unable to associate branch.'
      });
    }
  });
  return false;
};
exports.dissociateBranch = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const branch = Utils.getModelFromContext(this);
  const branchId = branch.id;
  const isUsingGitLab = branch.repository?.type === 'gitlab';
  const msg = `Are you sure you would like to dissociate this Branch from the Story? ` + `All ${isUsingGitLab ? 'Merge Requests' : 'Pull Requests'} associated with this Branch will also be removed.`;
  if (window.confirm(msg)) {
    $(this).find('.fa-trash').removeClass('fa-trash').addClass('fa-spin fa-star');
    StoryModel.dissociateBranch(story, branchId, err => {
      if (err) {
        const element = StoryModel.getElements(story);
        if (element.length) {
          exports.updateCodeComponent(story, element);
        }
        MessageController.error(err, {
          secondary: `Unable to dissociate Branch.`
        });
      }
    });
  }
  return false;
};
exports.openPullRequestActionsDropdown = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const pullRequestId = Utils.data(this, 'id');
  const pr = PullRequestModel.getById(pullRequestId);
  const prBranch = story.branches.find(branch => {
    return branch.id === pr.branch_id;
  });
  Tooltip.close();
  const isUsingGitLab = pr.repository?.type === 'gitlab';
  function _dissociatePullRequest() {
    const msg = `Are you sure you would like to dissociate this ${isUsingGitLab ? 'Merge Request' : 'Pull Request'} from the Story?`;
    if (window.confirm(msg)) {
      StoryModel.dissociatePullRequest(story, pullRequestId, err => {
        if (err) {
          const element = StoryModel.getElements(story);
          if (element.length) {
            exports.updateCodeComponent(story, element);
          }
          MessageController.error(err, {
            secondary: `Unable to dissociate ${isUsingGitLab ? 'Merge Request' : 'Pull Request'}.`
          });
        }
      });
    }
    return false;
  }
  const actions = [{
    name: `Remove ${isUsingGitLab ? 'MR' : 'PR'}`,
    value: _dissociatePullRequest
  }];
  if (prBranch) {
    actions.unshift({
      name: `Remove ${isUsingGitLab ? 'MR' : 'PR'} and Branch`,
      value: exports.dissociateBranch
    });
  }
  DropdownController.open({
    items: actions,
    target: this,
    targetSelector: '#open-pull-request-actions-dropdown',
    topOffset: 0,
    width: 200,
    showCloseButton: false
  });
  return false;
};
exports.associateCommit = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  const commitId = Utils.data(this, 'id');
  $(this).closest('.history-action').html('<span class="fa fa-spin fa-star"></span>');
  StoryModel.associateCommit(story, commitId, err => {
    if (err) {
      const element = StoryModel.getElements(story);
      if (element.length) {
        exports.updateHistoryComponent(story, element);
      }
      MessageController.error(err, {
        secondary: 'Unable to associate commit.'
      });
    }
  });
  return false;
};
exports.dissociateCommit = function () {
  const commitId = Utils.data(this, 'id');
  if (window.confirm('Are you sure you would like to dissociate this commit from the Story?')) {
    const story = Utils.getModelFromContext(this, 'Story');
    $(this).find('.fa-trash').removeClass('fa-trash').addClass('fa-spin fa-star');
    StoryModel.dissociateCommit(story, commitId, err => {
      if (err) {
        const element = StoryModel.getElements(story);
        if (element.length) {
          exports.updateCodeComponent(story, element);
        }
        MessageController.error(err, {
          secondary: 'Unable to dissociate commit.'
        });
      }
    });
  }
  return false;
};
function openStoryUpdater(context, options) {
  const story = Utils.getModelFromContext(context);
  const callback = _.isFunction(_.get(options, 'callback')) ? options.callback : _.noop;
  AutocompleteController.open({
    items: _.isFunction(options.getItems) ? options.getItems(story) : options.items,
    preserveCaptions: options.preserveCaptions,
    closeOnClicksOutside: _.isBoolean(options.showInput) ? options.showInput : false,
    inputValue: options.inputValue,
    footer: options.footer,
    multiSelect: !!options.multiSelect,
    targetSelector: options.targetSelector,
    title: options.title,
    onResultsUpdate: options.onResultsUpdate,
    onClose: options.onClose,
    noActive: _.isBoolean(options.noActive) ? options.noActive : false,
    onApply: options.onApply || (value => {
      const update = {};
      update[options.key] = value === 'none' ? null : value;
      if (options.onBeforeUpdate && options.onBeforeUpdate(update) === false) {
        Utils.deferFocus(context);
        return false;
      }
      StoryModel.serverSave(story, update, {
        afterRender: () => {
          if (options.key === 'workflow_state_id') {
            StoryModel.trigger('storyStateChanged');
            Utils.deferFocus(context);
          }
        },
        callback
      });

      // Check why this is here instead of relying on `serverSave`
      // update.id = story.id;
      // StoryModel.update(update);
      // StoryModel.trigger('storySaved', story);

      if (options.onAfterUpdate) {
        options.onAfterUpdate(story);
      }
      Utils.deferFocus(context);
    }),
    showInput: _.isBoolean(options.showInput) ? options.showInput : true,
    target: context,
    topOffset: 4,
    value: options.value || $(context).attr('data-value'),
    width: options.width || 200
  });
  return false;
}
exports.previousIterationsTooltip = function () {
  const story = Utils.getModelFromContext(this, 'Story');
  if (story.previous_iteration_ids.length) {
    const iterations = StoryModel.getPreviousIterations(story);
    return StoryPreviousIterationsTooltipTemplate.render({
      iterations
    });
  }
};
const getInvitePromise = (actionContext, invitedUser) => new Promise((resolve, reject) => {
  const inviteRequestFn = async () => {
    try {
      const resp = await createInvites([invitedUser], {
        actionContext
      });
      if (Array.isArray(resp)) {
        MessageController.success(`${invitedUser.email} has been invited to the Workspace.`);
        resolve(resp[0]);
      } else {
        reject(resp);
      }
    } catch (e) {
      if (Array.isArray(e)) {
        reject(e[0]);
      } else {
        reject(e);
      }
    }
  };
  inviteRequestFn();
});
const updateRequesterField = story => (selectedValues, newVal) => StoryModel.Promises.serverSave(story, {
  requested_by_id: newVal
});
const updateOwnerField = story => selectedValues => StoryModel.Promises.serverSave(story, {
  owner_ids: selectedValues
});
exports.renderCheckIcon = id => View.renderComponentDelayed({
  componentKey: `check-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "var(--shapes-successMain)"
  }, ___EmotionJSX(Icon, {
    icon: "Check",
    label: "Build Successful"
  }))
}).html;
exports.renderCloseIcon = id => View.renderComponentDelayed({
  componentKey: `delete-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "var(--shapes-errorMain)"
  }, ___EmotionJSX(Icon, {
    icon: "Close",
    label: "Build Failed"
  }))
}).html;
exports.renderHelpIcon = id => View.renderComponentDelayed({
  componentKey: `help-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, null, ___EmotionJSX(Icon, {
    icon: "Help",
    label: "About Commits"
  }))
}).html;
exports.renderRemoveCircleIcon = id => View.renderComponentDelayed({
  componentKey: `remove-circle-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "RemoveCircle",
    label: "Lines Removed"
  }))
}).html;
exports.renderAddCircleIcon = id => View.renderComponentDelayed({
  componentKey: `add-circle-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "AddCircle",
    label: "Lines Added"
  }))
}).html;
exports.renderMoreIcon = id => View.renderComponentDelayed({
  componentKey: `more-icon-${id}`,
  component: ___EmotionJSX(Icon, {
    icon: "More",
    label: "PR Action Menu"
  })
}).html;
exports.renderTrashIcon = id => View.renderComponentDelayed({
  componentKey: `trash-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: "16",
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Trash",
    label: "Disassociate Branch from Story"
  }))
}).html;
exports.renderBranchStatus = (id, kind, status) => View.renderComponentDelayed({
  componentKey: `branch-status-${id}`,
  component: ___EmotionJSX(Chip, {
    kind: kind
  }, ___EmotionJSX("span", null, status))
}).html;
exports.renderBranchIcon = (id, kind) => View.renderComponentDelayed({
  componentKey: `branch-icon-${id}`,
  component: ___EmotionJSX(IconChip, {
    kind: kind
  }, ___EmotionJSX(IconChip.Icon, {
    icon: "Branch",
    label: "Branch"
  }))
}).html;
export { exports as default };