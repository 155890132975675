import { Loading } from '@clubhouse/shared/components/Loading/Loading';
import { TIERS } from '@clubhouse/shared/types';
import { pluralize } from '@clubhouse/shared/utils/pluralize';
import { getOrganizationOwners, useOrganization } from 'data/entity/organization';
import { getAvailableSeats, planIsTier, usePaymentPlanForOrganization, usePlanStatuses } from 'data/entity/paymentPlan2';
import { isLoggedInUserOrganizationOwner } from 'data/entity/user';
import { grammaticalJoin, toMoney } from 'utils/format';
import { jsx as ___EmotionJSX } from "@emotion/react";
const OutstandingBalanceReason = _ref => {
  let {
    balance
  } = _ref;
  return balance ? ___EmotionJSX("li", null, ___EmotionJSX("span", {
    className: "fa fa-usd"
  }), "You have an outstanding balance of ", ___EmotionJSX("strong", null, "$", toMoney(balance / 100)), ". This will be charged when your account is re-enabled.") : null;
};
const TrialingReason = _ref2 => {
  let {
    plan
  } = _ref2;
  const {
    planTrialInfo
  } = usePlanStatuses(plan);
  if (!planTrialInfo) return null;
  const dateText = planTrialInfo.trial_days_remaining === 0 ? ___EmotionJSX("strong", null, "today") : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, planTrialInfo.trial_end), " (in", ' ', pluralize({
    count: planTrialInfo.trial_days_remaining,
    singular: 'day',
    plural: 'days'
  }), ")");
  return ___EmotionJSX("li", null, "You still have an active trial, which will end ", dateText, ".");
};
TrialingReason.displayName = "TrialingReason";
const SeatEntitlementReason = _ref3 => {
  let {
    plan
  } = _ref3;
  const {
    planTrialInfo
  } = usePlanStatuses(plan);

  // Do not show seat information for disabled trials
  if (planTrialInfo) return null;
  const isFreePlan = planIsTier(plan, TIERS.FREE_LIMITED);
  const hasActiveSeatsLeft = getAvailableSeats(plan);
  const freePlanSeatLimit = 10;
  const freePlanEligibleMessage = `You are eligible for the Free Plan for up to ${freePlanSeatLimit} Users.`;
  const standardPlanForcedMessage = `Your account will be reactivated on the Team Plan if you have more than ${freePlanSeatLimit} Users.`;
  let message = null;
  if (isFreePlan) {
    message = hasActiveSeatsLeft ? freePlanEligibleMessage : standardPlanForcedMessage;
  }
  if (!isFreePlan) {
    message = hasActiveSeatsLeft ? freePlanEligibleMessage : standardPlanForcedMessage;
  }
  return ___EmotionJSX("li", null, message);
};
SeatEntitlementReason.displayName = "SeatEntitlementReason";
export const DisabledOrgReasons = _ref4 => {
  let {
    orgId
  } = _ref4;
  const {
    org
  } = useOrganization(orgId);
  const paymentPlan = usePaymentPlanForOrganization(orgId);
  const ownerNames = getOrganizationOwners(org).map(_ref5 => {
    let {
      name
    } = _ref5;
    return name;
  });

  // This check must come first, the payment plan might not be loaded. The plan is only loaded for users that are owners of the org.
  if (!isLoggedInUserOrganizationOwner(org)) return ___EmotionJSX("li", null, "This organization has been disabled and can only be re-enabled by an owner", ownerNames.length ? `, ${grammaticalJoin(ownerNames)}` : '', ".");
  if (!paymentPlan) return ___EmotionJSX("li", null, ___EmotionJSX(Loading, null));
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(OutstandingBalanceReason, {
    balance: paymentPlan.account_balance
  }), ___EmotionJSX(TrialingReason, {
    plan: paymentPlan
  }), ___EmotionJSX(SeatEntitlementReason, {
    plan: paymentPlan
  }));
};