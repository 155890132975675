import { ContextMenu, ContextMenuDivider } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { PAGE_NAMES, generatePathWithSlug, getCurrentOrigin, getPathForPage } from '../navigation';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
const getHref = function (id) {
  let withDomain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const path = getPathForPage(PAGE_NAMES.DOC);
  return path ? `${withDomain ? getCurrentOrigin() : ''}${generatePathWithSlug(path, {
    docId: id
  })}` : '';
};
export const DocContextMenu = _ref => {
  let {
    id,
    urlId
  } = _ref;
  // TODO: The `id` check can be removed once we always get the id.
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getHref(urlId)
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(urlId, true),
    label: "Link"
  }), id ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: id,
    entityType: Nouns.Doc,
    loggingContext: {
      typename: 'Doc'
    }
  })) : null);
};
DocContextMenu.displayName = "DocContextMenu";
export const useDocContextMenu = (globalId, urlId) => {
  const {
    props
  } = useContextMenuWithLogging(() => ___EmotionJSX(DocContextMenu, {
    id: globalId,
    urlId: urlId
  }), {
    entityType: 'Doc'
  });
  return props;
};
export const useDocContextMenuWithQuickSearchSessionLogging = (globalId, urlId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => ___EmotionJSX(DocContextMenu, {
    id: globalId,
    urlId: urlId
  }), {
    entityType: 'Doc'
  }, logQuickSearchInteractions);
  return props;
};