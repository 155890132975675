import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/zendesk_logo.png';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { ApplicationIds, fetchByApplicationId, install, reinstallZendesk, uninstallZendesk, useInstallationByApplicationId } from 'data/entity/installation';
import { fetchAll, getCredentialByInstallationId } from 'data/entity/oauth';
import { isLoggedInUserAdmin, isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { userListRoute } from 'utils/manageUsersController';
import { openManageUsers } from 'utils/settings';
import { ExternalLink } from './components/ExternalLink';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Zendesk() {
  const readOnly = isReadOnly();
  const admin = isLoggedInUserAdmin();
  useEffect(() => {
    fetchByApplicationId(ApplicationIds.Zendesk);
    fetchAll();
  }, []);
  const installation = useInstallationByApplicationId(ApplicationIds.Zendesk);
  const isLoading = !installation;
  const isEnabled = installation?.uninstalled === false;
  const credential = getCredentialByInstallationId(installation?.id);
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.ZENDESK
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Zendesk"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Zendesk Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "With our Zendesk integration, you can create links between stories and support tickets, and also manage them from within Zendesk."), (readOnly || !admin) && ___EmotionJSX(Layout.HelpMessage, null, readOnly ? 'ZendeskObservers cannot make changes to integrations.' : !admin ? 'Only admins can manage this integration.' : null, ___EmotionJSX("br", null), "Please", ' ', ___EmotionJSX("a", {
    href: userListRoute(),
    onClick: e => {
      e.preventDefault();
      openManageUsers();
    }
  }, "ask an owner or an admin"), ' ', "if you want to edit this page."), ___EmotionJSX(Layout.SectionTitle, null, "Integration Status"), isLoading ? ___EmotionJSX(IntegrationToggle.Loading, null) : ___EmotionJSX(IntegrationToggle, {
    isReadOnly: !admin,
    isEnabled: isEnabled,
    onChange: async newValue => {
      try {
        if (installation) {
          if (newValue) await reinstallZendesk(installation.id);else await uninstallZendesk(installation.id);
        } else {
          await install(ApplicationIds.Zendesk);
        }
      } catch (err) {
        addToast({
          kind: 'alert',
          timeout: 5000,
          Content: () => ___EmotionJSX(ToastText, null, "Unable to ", newValue ? 'enable' : 'disable', " integration.")
        });
      }
    }
  }), isEnabled && ___EmotionJSX(React.Fragment, null, credential ? ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX("strong", null, "Your"), " authorization expires on ", credential.formatted_expiry, ".") : ___EmotionJSX(Layout.Blurb, null, "To use this integration, once our app has been installed from the", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.zendesk.com/marketplace/apps/support/146871/shortcut"
  }, "Zendesk Marketplace"), ' ', "you can authenticate yourself from within Zendesk."), ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_ZENDESK
  }, "our instructions"), ' ', "for how to configure this integration in your Zendesk account."))));
}
Zendesk.displayName = "Zendesk";