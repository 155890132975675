import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { EmptyValue } from './EmptyValue';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function EpicStartDate(_ref) {
  let {
    epic
  } = _ref;
  return ___EmotionJSX(DetailPageSidebarRow, {
    label: "Start Date",
    "data-perma-id": "epic-startdate-field"
  }, ___EmotionJSX(DetailPageSidebarAction, {
    icon: "Calendar",
    "data-field-name": "planned_start_date",
    "data-on-click": "openDateControl",
    secondarySlot: epic.formatted_planned_start_date ? ___EmotionJSX(Tooltip, {
      content: "Clear Start Date"
    }, ___EmotionJSX(SecondaryActionButton, {
      "data-perma-id": "clearDate",
      "data-field-name": "planned_start_date",
      "data-on-click": "clearDate"
    }, ___EmotionJSX(Icon, {
      icon: "Remove",
      fill: "main",
      label: "Clear Start Date"
    }))) : undefined
  }, epic.formatted_planned_start_date ? epic.formatted_planned_start_date : ___EmotionJSX(EmptyValue, null, "No Date")));
}
EpicStartDate.displayName = "EpicStartDate";