import { StoryGroupBySkillSetFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { SkillSetWithIcon } from 'components/gql/skillSet/SkillSetSelectTarget';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupBySkillSetFragment = StoryGroupBySkillSetFragmentFragmentDoc;
export const SkillSetGroupHeader = _ref => {
  let {
    skillSet
  } = _ref;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.SkillSet
  }, typeof skillSet?.stringValue === 'string' && typeof skillSet.colorKey === 'string' ? ___EmotionJSX(SkillSetWithIcon, {
    spacing: 12,
    stringValue: skillSet.stringValue,
    colorKey: skillSet.colorKey
  }) : 'No Skill Set');
};
SkillSetGroupHeader.displayName = "SkillSetGroupHeader";