import BacklogPng from '@clubhouse/assets/png/Backlog-illo-light.png';
import { ButtonWithSampleLink } from 'components/shared/ButtonWithSampleLink';
import { CompositeImage } from 'components/shared/CompositeImage';
import { Button as DeprecatedButton } from 'components/shared/DeprecatedButton';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { Problem } from 'components/shared/Problem';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import { isSampleObserver } from 'data/entity/user';
import { join } from 'utils/sampleWorkspace';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Image = ___EmotionJSX(EmptyStateImage, {
  wrapperWidth: 72
}, ___EmotionJSX(CompositeImage, {
  primaryImage: {
    src: BacklogPng,
    alt: '',
    darkModeOpacity: 1
  }
}));
const learnAboutStoriesButtonProps = {
  text: 'Learn about Stories',
  onClick: toggleHelpHub
};
const sampleButtonProps = {
  text: 'View Sample Stories',
  onClick: () => join('stories')
};
export function NoStoriesInBacklogEmptyState(_ref) {
  let {
    onAddStory
  } = _ref;
  const createStoryButtonProps = {
    text: 'Create Story',
    color: DeprecatedButton.colors.green,
    onClick: () => onAddStory()
  };
  return ___EmotionJSX(Problem, {
    minHeight: "480px",
    title: "Manage Your Backlog",
    description: 'Easily manage and prioritize Stories in your Backlog to help power up your next Iteration.',
    media: Image,
    svgHeight: "180px",
    buttons: ___EmotionJSX(ButtonWithSampleLink, {
      buttonProps: createStoryButtonProps,
      learnButtonProps: learnAboutStoriesButtonProps,
      sampleButtonProps: isSampleObserver() ? undefined : sampleButtonProps
    })
  });
}
NoStoriesInBacklogEmptyState.displayName = "NoStoriesInBacklogEmptyState";