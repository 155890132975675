import { useCallback } from 'react';
import { AutocompleteTextArea } from './AutocompleteTextArea';
import { jsx as ___EmotionJSX } from "@emotion/react";
const autoResize = textarea => {
  const style = window.getComputedStyle(textarea, null);
  let offset = 0;
  if (style.boxSizing === 'content-box') {
    offset = -(Number.parseFloat(style.paddingTop) + Number.parseFloat(style.paddingBottom));
  } else {
    offset = Number.parseFloat(style.borderTopWidth) + Number.parseFloat(style.borderBottomWidth);
  }
  textarea.style.height = 'auto';
  const height = textarea.scrollHeight + offset;
  if (style.maxHeight !== 'none') {
    textarea.style.overflowY = height > Number.parseFloat(style.maxHeight) ? 'auto' : 'hidden';
  }
  textarea.style.height = `${height}px`;
};
export const MarkdownTextArea = _ref => {
  let {
    initialText,
    snapshotKey,
    isReadOnly,
    onChange,
    onResize,
    onSave,
    onCancel,
    onBlur,
    onFocus
  } = _ref;
  const handleRawChange = useCallback(e => {
    autoResize(e.target);
    onResize?.();
  }, [onResize]);
  const onKeyDown = useCallback(e => {
    if (e.isDefaultPrevented()) return;
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      onSave();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onCancel();
    }
  }, [onCancel, onSave]);
  return ___EmotionJSX(AutocompleteTextArea, {
    initialText: initialText,
    snapshotKey: snapshotKey,
    isReadOnly: isReadOnly,
    onKeyDown: onKeyDown,
    onChange: handleRawChange,
    onTextChange: onChange,
    onBlur: onBlur,
    onFocus: onFocus
  });
};
MarkdownTextArea.displayName = "MarkdownTextArea";