import { useMemo, useState } from 'react';
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getById, getTeamFromColumn } from 'data/entity/column';
import { getAll, getWorkflowStatesForTeam, useTeams } from 'data/entity/team';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function WorkflowSelect(_ref) {
  let {
    selectedId,
    onChange
  } = _ref;
  const {
    teams
  } = useTeams();
  const column = getById(selectedId) ?? null;
  const team = getTeamFromColumn(column);
  const [query, setQuery] = useState('');
  const items = useMemo(() => {
    let isFirst = true;
    return teams.flatMap(team => {
      const items = getWorkflowStatesForTeam(team).filter(state => !query.trim() || team.name.toLowerCase().includes(query.toLowerCase()) || state.name.toLowerCase().includes(query.toLowerCase())).map(state => ___EmotionJSX(ComboboxSelect.Option, {
        key: state.id,
        value: state.id,
        isSelected: state.id === selectedId
      }, ___EmotionJSX(Emojify, null, state.name)));
      if (items.length > 0) {
        items.unshift(___EmotionJSX(ComboboxSelect.LabelItem, {
          key: `${team.id}-name`
        }, team.name));
        if (!isFirst) items.unshift(___EmotionJSX(ComboboxSelect.Divider, {
          key: `${team.id}-divider`
        }));
        isFirst = false;
      }
      return items;
    });
  }, [query, selectedId, teams]);
  return ___EmotionJSX(ComboboxSelect, {
    onChange: onChange
  }, ___EmotionJSX(ComboboxSelect.Trigger, null, ___EmotionJSX(ComboboxSelect.Target, {
    type: "button",
    IconRight: () => ___EmotionJSX(SizedIcon, {
      icon: "ChevronDown"
    })
  }, team && getAll().length > 1 ? team.name + ':' : '', " ", column.name || 'Select Target State')), ___EmotionJSX(ComboboxSelect.Menu, null, ___EmotionJSX(ComboboxSelect.List, null, ___EmotionJSX(ComboboxSelect.Input, {
    onSearch: setQuery
  }), items)));
}
WorkflowSelect.displayName = "WorkflowSelect";