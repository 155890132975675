import ReactDOMServer from 'react-dom/server';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { ShapesProvider } from '@useshortcut/shapes-ds';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ShapesThemeProvider = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(ShapesProvider, {
    themeName: useThemeName()
  }, children);
};
ShapesThemeProvider.displayName = "ShapesThemeProvider";
export const renderComponentToString = children => ReactDOMServer.renderToString(___EmotionJSX(ShapesThemeProvider, null, children));
export const htmlStringToElement = string => {
  const template = document.createElement('template');
  template.innerHTML = string.trim();
  return template.content.firstChild;
};