import { Body } from './components/Body';
import { Sidebar } from './components/Sidebar';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BodyAndSidebar = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, children);
};
BodyAndSidebar.Body = Body;
BodyAndSidebar.Sidebar = Sidebar;