import { Icon } from '@useshortcut/shapes-ds';
import { LoadingDots } from '@clubhouse/shared/animations';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { HeaderIconLink, HideWhenContainerIsSmall } from 'components/layout/header/HeaderIconLink';
import { useOptInSetState } from './useOptInSetState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const OptOutButton = () => {
  const {
    setValue,
    isChangingState
  } = useOptInSetState();
  return ___EmotionJSX(HeaderIconLink, {
    text: ___EmotionJSX(HideWhenContainerIsSmall, null, "Use the old Stories Page"),
    onClick: () => setValue(false),
    icon: isChangingState ? ___EmotionJSX(LoadingDots, null) : ___EmotionJSX(DeprecatedIconAdapter, {
      width: 20,
      fill: "currentColor"
    }, ___EmotionJSX(Icon, {
      icon: "ChevronLeft"
    })),
    disabled: isChangingState
  });
};
OptOutButton.displayName = "OptOutButton";