import { Button } from '@clubhouse/shared/components/Button';
import { PAGE_NAMES, generatePathForPageWithSlug, navigateTo } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationAutomationsCTA = () => {
  return ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.MEDIUM,
    onClick: () => {
      navigateTo({
        url: generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_AUTOMATIONS)
      });
    }
  }, "\uD83E\uDD16 Set Up Automations");
};
IterationAutomationsCTA.displayName = "IterationAutomationsCTA";