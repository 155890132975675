import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { getNumberOfCompletedTasks } from 'data/entity/story';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TaskChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.task_ids?.length) return null;
  const totalTasks = story.task_ids.length;
  const completedTasks = getNumberOfCompletedTasks(story);
  return ___EmotionJSX(StoryCardInternal.Chips.TaskChip, {
    completedTasks: completedTasks,
    totalTasks: totalTasks
  });
};
TaskChip.displayName = "TaskChip";