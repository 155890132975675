import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { useHasPropChanged } from '@clubhouse/shared/utils';
import * as Event from 'app/client/core/js/_frontloader/event';
import Router from 'app/client/core/js/_frontloader/router';
import ApplicationState from 'app/client/core/js/modules/applicationState';
import Boot from 'app/client/core/js/modules/boot';
import { fetchConsolidatedDataAndActivityPromise } from 'app/client/core/js/modules/consolidatedFetch';
import { resetPageCSS as defaultResetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import Updates from 'app/client/core/js/modules/updates';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { LayoutContext } from 'components/layout/Layout';
import { shouldShowBigLogoLayoutForPage, shouldShowCustomLayoutForPage, shouldShowEmptyLayoutForPage } from 'components/layout/utils';
import { loadURL } from 'utils/hotReload';
import { openErrorMessage } from 'utils/message';
import { BootState, bootSequence, useBootService } from './bootStateMachine';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const pageInitializers = {
  /* eslint-disable import/dynamic-import-chunkname */
  appauth: () => import( /* webpackMode: "eager" */'app/client/appauth/js/modules/appauthInit'),
  company: () => import( /* webpackChunkName: "company" */'app/client/company/js/modules/companyInit'),
  dashboard: () => import( /* webpackMode: "eager" */'app/client/dashboard/js/modules/dashboardInit'),
  dialog: () => import( /* webpackMode: "eager" */'app/client/dialog/js/modules/dialogInit'),
  epic: () => import( /* webpackMode: "eager" */'app/client/epics/js/modules/epicsInit'),
  iterations: () => import( /* webpackMode: "eager" */'app/client/iterations/js/modules/iterationsInit'),
  login: () => import( /* webpackMode: "eager" */'app/client/login/js/modules/loginInit'),
  oauth: () => import( /* webpackMode: "eager" */'app/client/oauth/js/modules/oauthInit'),
  oauthPermissionRedirect: () => import( /* webpackMode: "eager" */'app/client/oauthPermissionRedirect/js/modules/oauthPermissionRedirectInit'),
  organizations: () => import( /* webpackMode: "eager" */'app/client/organizations/js/modules/organizationsInit'),
  projects: () => import( /* webpackChunkName: "projects" */'app/client/projects/js/modules/projectsInit'),
  reports: () => import( /* webpackChunkName: "reports" */'app/client/reports/js/modules/reportsInit'),
  settings: () => import( /* webpackMode: "eager" */'app/client/settings/js/modules/settingsInit'),
  status: () => import( /* webpackChunkName: "status" */'app/client/status/js/modules/statusInit'),
  stories: () => import( /* webpackMode: "eager" */'app/client/stories/js/modules/storiesInit'),
  story: () => import( /* webpackMode: "eager" */'app/client/story/js/modules/storyInit'),
  /* eslint-enable import/dynamic-import-chunkname */
  write: () => import( /* webpackChunkName: "write" */'app/client/write/js/modules/writeInit')
};
const commonInit = _ref => {
  let {
    state,
    setReadyState,
    page
  } = _ref;
  if (state.matches(BootState.RenderingContainer)) {
    // Render the nav and header
    setReadyState();
    const isBigLogoLayoutPage = shouldShowBigLogoLayoutForPage(page);
    const isEmptyLayoutPage = shouldShowEmptyLayoutForPage(page);
    const isCustomLayoutPage = shouldShowCustomLayoutForPage(page);
    if (state.context.isReinit || isBigLogoLayoutPage || isEmptyLayoutPage || isCustomLayoutPage) {
      bootSequence.containerRendered();
    }
  } else if (state.matches(BootState.Loading)) {
    // Resume the boot sequence
    Boot.resumeInitialization();
  }
};
export const useUpdates = function () {
  let {
    onUpdates = noop
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [state] = useBootService();
  useEffect(() => {
    if (state.matches(BootState.Booted)) {
      Updates.init(onUpdates);
    }
  }, [onUpdates, state]);
};
export const useInit = _ref2 => {
  let {
    page,
    pageTitle,
    renderId,
    routeMatcher,
    resetPageCSS
  } = _ref2;
  const previousPage = useRef(null);
  const previousRenderId = useRef(null);
  const isCurrentlyReinitializing = useRef(false);
  const [isReady, setIsReady] = useState(false);
  const [isContainerReady, setIsContainerReady] = useState(false);
  const [state] = useBootService();
  const {
    setReadyState
  } = useContext(LayoutContext);
  useAsync(async () => {
    previousPage.current = page;
    previousRenderId.current = renderId;
    isCurrentlyReinitializing.current = true;
    setIsReady(false);

    // This is necessary for specific areas of the App that are still loading less styles outside of core
    if (isFunction(resetPageCSS)) {
      await resetPageCSS();
    } else {
      await defaultResetPageCSS();
    }
    Boot.initialize();
  }, [page, renderId]);
  useEffect(() => {
    commonInit({
      state,
      setReadyState,
      page
    });
    if (state.matches(BootState.RenderingContainer)) {
      setIsContainerReady(true);
    }
    if (state.matches(BootState.Loading) && isCurrentlyReinitializing.current) {
      isCurrentlyReinitializing.current = false;
    }
    if (state.matches(BootState.Booted) && !isCurrentlyReinitializing.current) {
      const init = async () => {
        const title = `${pageTitle} | ${BRAND.NAME}`;
        Router.addPageTitle(page, title);
        if (routeMatcher) {
          Router.add(routeMatcher, pageTitle);
        } else {
          /** Passing loadURL absolutely necesary here because we can get into a place where popstate triggers
           * and it detects a route object but because there is no callback, nothing happens
           * (this is why we would experience empty React pages */
          Router.add(url => url.includes(`${Url.getSlugPath()}/${page}`), title, () => loadURL(`${Url.getSlugPath()}`, page));
        }
        ApplicationState.setLastView();
        Event.onlyOn(`pageRendered.${pageTitle} resize.${pageTitle}`, View.genericResizeHandler);
        if (!state.context.isReinit) {
          try {
            await fetchConsolidatedDataAndActivityPromise();
          } catch (error) {
            if (error instanceof Error) {
              openErrorMessage(error.message);
            }
          }
        }
        Event.trigger('pageRendered', pageTitle);
        setIsReady(true);
      };
      init();
    }
  }, [state, setReadyState, page, pageTitle, routeMatcher]);

  // We need to check if the page and renderId are the same as the previous ones.
  // If they are not, it means we need to wait for the new boot sequence, but
  // loading state is set within a `useAsync` / `useEffect` and will always be
  // changed upon the next render. We absolutely want to prevent the page from
  // rendering until the new boot sequence is done, therefore the use of `shouldReinitialize`.
  const shouldReinitialize = (() => {
    if (previousPage.current === null || previousRenderId.current === null) {
      return false;
    }
    return previousPage.current !== page || previousRenderId.current !== renderId;
  })();
  return {
    isReady: shouldReinitialize === false && isReady,
    isContainerReady
  };
};
export const Init = _ref3 => {
  let {
    page,
    pageTitle,
    children,
    renderId
  } = _ref3;
  const {
    isReady
  } = useInit({
    page,
    pageTitle,
    renderId
  });
  if (!isReady) {
    return null;
  }
  return ___EmotionJSX(React.Fragment, null, children);
};

/*
  For new pages that don't rely on models:
  Don't wait for consolidated fetch to get all the data & activity.
  When the container is ready, org/user data is ready - which is all you need.
*/
export const InitAsync = _ref4 => {
  let {
    page,
    pageTitle,
    children,
    renderId
  } = _ref4;
  const {
    isContainerReady
  } = useInit({
    page,
    pageTitle,
    renderId
  });
  if (!isContainerReady) {
    return null;
  }
  return ___EmotionJSX(React.Fragment, null, children);
};
const loadPageInitializer = async pageInitializerName => {
  try {
    const pageInitializerFunction = pageInitializers[pageInitializerName];
    const {
      default: pageInitializer
    } = await pageInitializerFunction();
    return pageInitializer;
  } catch (err) {
    throw new Error(`There is no initializer defined for page: ${pageInitializerName}`, {
      cause: err
    });
  }
};
export const useLegacyInit = _ref5 => {
  let {
    page,
    pageInitializerName,
    renderId
  } = _ref5;
  const [state] = useBootService();
  const {
    setReadyState
  } = useContext(LayoutContext);

  // We can live with this side effect as we're only preloading the page initializer.
  const pageInitializerPromise = useMemo(() => {
    // We load the page initializer early but we don't wait for it to finish yet.
    return loadPageInitializer(pageInitializerName);
  }, [pageInitializerName]);
  const hasRenderIdChanged = useHasPropChanged(renderId);
  const hasPageChanged = useHasPropChanged(page);
  const hasPageInitializerNameChanged = useHasPropChanged(pageInitializerName);
  const shouldInitialize = hasRenderIdChanged || hasPageChanged || hasPageInitializerNameChanged;
  useEffect(() => {
    if (shouldInitialize) {
      Boot.initialize();
      return;
    }
    commonInit({
      state,
      setReadyState,
      page
    });
    if (state.matches(BootState.RenderingContainer)) {
      const isBigLogoLayoutPage = shouldShowBigLogoLayoutForPage(page);
      const isEmptyLayoutPage = shouldShowEmptyLayoutForPage(page);
      const isCustomLayoutPage = shouldShowCustomLayoutForPage(page);
      if (isBigLogoLayoutPage || isEmptyLayoutPage || isCustomLayoutPage) {
        bootSequence.containerRendered();
      }
    } else if (state.matches(BootState.Booted)) {
      (async () => {
        const pageInitializer = await pageInitializerPromise;

        // We need to digest app assignments but only after we awaited the page initializer.
        Boot.digestAppAssignments();
        pageInitializer.boot();
      })();
    }
  }, [page, pageInitializerPromise, setReadyState, shouldInitialize, state]);
};