import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { CustomFieldSelectList } from 'components/shared/custom-fields/CustomFieldSelectList';
import { saveCustomFieldValue, useStory } from 'data/entity/story';
import { CustomFieldHeader } from './CustomFieldHeader';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CustomFieldAttributes = _ref => {
  let {
    storyId
  } = _ref;
  const story = useStory({
    id: storyId
  });
  const {
    addToast
  } = useToast();
  if (!story) return null;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(CustomFieldHeader, null), ___EmotionJSX(CustomFieldSelectList, {
    storyType: story.story_type,
    storyCustomFields: story.custom_fields,
    onChange: async (fieldId, valueId) => {
      try {
        await saveCustomFieldValue(story, fieldId, valueId);
      } catch (error) {
        addToast({
          timeout: 6000,
          kind: 'alert',
          Content: () => ___EmotionJSX(ToastText, null, "Uh oh, your organization no longer has access to advanced Custom Fields. Please contact one of your Owners to upgrade your plan.", ___EmotionJSX("br", null), ___EmotionJSX("small", null, "Unable to update ", ___EmotionJSX("b", null, story.name))),
          width: 404
        });
      }
    }
  }));
};