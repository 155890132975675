import { StateIcon } from '@clubhouse/shared/components/StateIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const WorkflowStateIcon = _ref => {
  let {
    type
  } = _ref;
  return ___EmotionJSX(StateIcon, {
    type: type
  });
};
WorkflowStateIcon.displayName = "WorkflowStateIcon";