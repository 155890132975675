import Logo from '@clubhouse/assets/png/third-party-logos/airfocus-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Airfocus() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.AIRFOCUS
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Airfocus"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Airfocus Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Use ", ___EmotionJSX(ExternalLink, {
    href: "https://www.airfocus.com"
  }, "airfocus'"), " flexible ", BRAND.NAME, ' ', "integration to prioritize your stories and epics to build more effective roadmaps. The integration keeps high-level strategy in sync with day-to-day development.", ___EmotionJSX("ul", null, ___EmotionJSX("li", null, "Import ", BRAND.NAME, " epics and stories (as items) into airfocus."), ___EmotionJSX("li", null, "Push prioritized items into ", BRAND.NAME, " for development and track their ", BRAND.NAME, " status right from within airfocus."), ___EmotionJSX("li", null, "Create a flexible filtering and mapping workflow, e.g., map multiple ", BRAND.NAME, " states to the same airfocus status."), ___EmotionJSX("li", null, "Keep fields synced (two-way) between both systems, including title, description, status, and custom fields."))), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Visit airfocus'", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://help.airfocus.com/en/articles/4107035-integrate-with-shortcut"
  }, "help center"), ' ', "to learn more about configuring this integration in your airfocus account.")));
}
Airfocus.displayName = "Airfocus";