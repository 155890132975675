function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { Icon } from '@useshortcut/shapes-ds';
import { useRef } from 'react';
import { FloatingElement } from '@clubhouse/shared/components/FloatingElement';
import { PullRight } from '@clubhouse/shared/components/Spaced';
import { useKeyboardMenuNavigation, useToggleState } from '@clubhouse/shared/hooks';
import { useMenuClose, useMenuElement, useMenuItemProps, useSubMenuOnHover } from '@clubhouse/shared/utils/menuState';
import { navigateOnClick } from 'utils/navigation';
import { MenuItemButton, MenuItemIcon, MenuItemLink, MenuItemText, MenuItemWrapper } from './shared';
import { jsx as ___EmotionJSX } from "@emotion/react";
const useMenuItem = _ref => {
  let {
    subMenuId,
    hasSubMenu
  } = _ref;
  const ref = useRef(null);
  const subMenuRef = useRef(null);
  const [isSubMenuOpen, {
    on: onOpenSubMenu,
    off: onCloseSubMenu
  }] = useToggleState();
  const {
    closeThis,
    closeAll,
    closeAllWhenResolved
  } = useMenuClose();
  const menu = useMenuElement();
  const menuItemProps = useMenuItemProps();
  useSubMenuOnHover({
    hasSubMenu,
    subMenuId,
    isSubMenuOpen,
    menuItemRef: ref,
    subMenuRef,
    menuRef: menu,
    onShow: onOpenSubMenu,
    onHide: onCloseSubMenu
  });
  const {
    onKeyDown
  } = useKeyboardMenuNavigation({
    parentRef: menu,
    closeMenu: closeThis !== closeAll ? closeThis : undefined,
    openSubMenu: hasSubMenu ? onOpenSubMenu : undefined
  });
  return {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen: isSubMenuOpen,
    onOpenSubMenu,
    onCloseSubMenu,
    closeAll,
    closeAllWhenResolved
  };
};
const SubMenuButton = _ref2 => {
  let {
    icon,
    label,
    SubMenu
  } = _ref2;
  const {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen,
    onOpenSubMenu,
    onCloseSubMenu,
    closeAll
  } = useMenuItem({
    hasSubMenu: true
  });
  return ___EmotionJSX(MenuItemWrapper, {
    role: "presentation"
  }, ___EmotionJSX(MenuItemButton, _extends({
    ref: ref
  }, menuItemProps, {
    onKeyDown: onKeyDown,
    onClick: onOpenSubMenu,
    role: "menuitem",
    "aria-haspopup": "menu",
    "aria-expanded": subMenuIsOpen,
    isOpen: subMenuIsOpen
  }), icon, ___EmotionJSX(MenuItemText, null, label), ___EmotionJSX(PullRight, null, ___EmotionJSX(Icon, {
    icon: "ChevronRight",
    fill: "disabled"
  }))), subMenuIsOpen && ___EmotionJSX(FloatingElement, {
    trigger: ref.current,
    onClose: closeAll,
    detectClicksOutside: "none",
    offsetMainAxis: 2
  }, ___EmotionJSX(SubMenu, {
    ref: subMenuRef,
    onClose: onCloseSubMenu
  })));
};
SubMenuButton.displayName = "SubMenuButton";
const SplitSubMenuButton = _ref3 => {
  let {
    icon,
    label,
    onClick,
    SubMenu,
    subMenuId
  } = _ref3;
  const {
    ref,
    subMenuRef,
    menuItemProps,
    onKeyDown,
    subMenuIsOpen,
    onCloseSubMenu,
    closeAll,
    closeAllWhenResolved
  } = useMenuItem({
    subMenuId,
    hasSubMenu: true
  });
  return ___EmotionJSX(MenuItemWrapper, {
    role: "presentation"
  }, ___EmotionJSX(MenuItemButton, _extends({}, menuItemProps, {
    onClick: e => {
      const result = onClick(e);
      if (result instanceof Promise) closeAllWhenResolved(result);else closeAll();
    },
    onKeyDown: onKeyDown,
    role: "menuitem",
    "aria-haspopup": "menu",
    "aria-expanded": subMenuIsOpen
  }), icon, ___EmotionJSX(MenuItemText, null, label)), ___EmotionJSX(PullRight, null, ___EmotionJSX(MenuItemButton, _extends({
    ref: ref,
    as: "div",
    id: subMenuId
  }, menuItemProps, {
    isOpen: subMenuIsOpen
  }), ___EmotionJSX(Icon, {
    icon: "ChevronRight",
    fill: "disabled"
  }))), subMenuIsOpen && ___EmotionJSX(FloatingElement, {
    trigger: ref.current,
    onClose: closeAll,
    detectClicksOutside: "none",
    offsetMainAxis: 2
  }, ___EmotionJSX(SubMenu, {
    ref: subMenuRef,
    onClose: onCloseSubMenu
  })));
};
SplitSubMenuButton.displayName = "SplitSubMenuButton";
const ActionButton = _ref4 => {
  let {
    icon,
    label,
    onClick
  } = _ref4;
  const {
    menuItemProps,
    onKeyDown,
    closeAll,
    closeAllWhenResolved
  } = useMenuItem({
    hasSubMenu: false
  });
  return ___EmotionJSX(MenuItemWrapper, {
    role: "presentation"
  }, ___EmotionJSX(MenuItemButton, _extends({}, menuItemProps, {
    onClick: e => {
      const result = onClick(e);
      if (result instanceof Promise) closeAllWhenResolved(result);else closeAll();
    },
    onKeyDown: onKeyDown,
    role: "menuitem"
  }), icon, ___EmotionJSX(MenuItemText, null, label)));
};
ActionButton.displayName = "ActionButton";
const ActionLink = _ref5 => {
  let {
    icon,
    label,
    href
  } = _ref5;
  const {
    menuItemProps,
    onKeyDown,
    closeAll
  } = useMenuItem({
    hasSubMenu: false
  });
  const onClick = navigateOnClick(href);
  return ___EmotionJSX(MenuItemWrapper, {
    role: "presentation"
  }, ___EmotionJSX(MenuItemLink, _extends({}, menuItemProps, {
    href: href,
    onClick: e => {
      closeAll();
      onClick(e);
    },
    onKeyDown: onKeyDown,
    role: "menuitem"
  }), icon, ___EmotionJSX(MenuItemText, null, label)));
};
ActionLink.displayName = "ActionLink";
export const MenuItem = _ref6 => {
  let {
    href,
    onClick,
    SubMenu,
    ...props
  } = _ref6;
  if (onClick && SubMenu) return ___EmotionJSX(SplitSubMenuButton, _extends({}, props, {
    onClick: onClick,
    SubMenu: SubMenu
  }));
  if (SubMenu) return ___EmotionJSX(SubMenuButton, _extends({}, props, {
    SubMenu: SubMenu
  }));
  if (href) return ___EmotionJSX(ActionLink, _extends({}, props, {
    href: href
  }));
  if (onClick) return ___EmotionJSX(ActionButton, _extends({}, props, {
    onClick: onClick
  }));
  throw new Error('A MenuItem requires that either SubMenu or onClick be defined.');
};
export const MenuItemWithIcon = _ref7 => {
  let {
    icon,
    ...props
  } = _ref7;
  return ___EmotionJSX(MenuItem, _extends({}, props, {
    icon: ___EmotionJSX(MenuItemIcon, {
      icon: icon
    })
  }));
};
MenuItemWithIcon.displayName = "MenuItemWithIcon";