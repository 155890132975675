import { EpicBadgesAndLabelsEpicFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { StoriesEpicBadgesAndLabelsEpicStatsFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { PointsEpicBadgesAndLabelsEpicStatsFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import classnames from 'classnames';
import truncate from 'lodash/truncate';
import { gql } from '@clubhouse/datalayer';
import { Chip } from '@clubhouse/shared/components/Chip';
import { EntityProgressBar, getProgressStats } from '@clubhouse/shared/components/EntityProgressBar';
import { Nouns } from '@clubhouse/shared/constants';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { StoryLabel } from 'components/shared/StoryLabel';
import { isDisabled as isEstimateScaleDisabled, isUsingPoints } from 'data/entity/estimateScale';
import { getLabelById } from 'data/entity/label';
import { EpicIcon } from './EpicIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
PointsEpicBadgesAndLabelsEpicStatsFragmentDoc;
StoriesEpicBadgesAndLabelsEpicStatsFragmentDoc;
export const EpicBadgesAndLabelsEpicFragment = EpicBadgesAndLabelsEpicFragmentDoc;
export function EpicBadgesAndLabels(_ref) {
  let {
    className,
    epic,
    showLabels = false
  } = _ref;
  const usingPoints = isUsingPoints();
  const progressUnit = usingPoints ? Nouns.Point : Nouns.Story;
  const statusText = epic.stateObject.name;
  const labels = (() => {
    if (!epic || !epic.labels) {
      return emptyArray;
    }
    if ('edges' in epic.labels) {
      return epic.labels?.edges.map(edge => edge.node) ?? emptyArray;
    }
    return epic.labels;
  })();
  const totalStories = (() => {
    if ('totalStories' in epic) {
      return epic.totalStories;
    }
    return epic?.stories?.pageInfo.totalSize ?? 0;
  })();
  const numPointsTotal = (() => {
    if ('stats' in epic && 'numPointsTotal' in epic.stats) {
      return epic.stats.numPointsTotal ?? 0;
    }
    if ('num_points' in epic) {
      return epic.num_points;
    }
    return 0;
  })();
  return ___EmotionJSX("div", {
    className: classnames('story-badges', className)
  }, ___EmotionJSX(Chip, null, ___EmotionJSX(EpicIcon, {
    epic: epic
  }), ___EmotionJSX("span", null, truncate(statusText, {
    length: 15
  }))), showLabels && labels.length > 0 && ___EmotionJSX("div", {
    className: "epic-labels"
  }, labels.map(label => {
    // If we have `edges` on `epic.labels`, it means we're using GraphQL and we should skip `getLabelById`.
    if (epic?.labels && 'edges' in epic.labels) {
      return ___EmotionJSX(StoryLabel, {
        key: label.id,
        label: label
      });
    }
    return ___EmotionJSX(StoryLabel, {
      key: label.id,
      label: {
        ...label,
        ...getLabelById(label.id)
      }
    });
  })), totalStories > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Chip, null, ___EmotionJSX(Chip.Icon, {
    icon: "Story",
    label: "Story count:"
  }), totalStories), !isEstimateScaleDisabled() && ___EmotionJSX(Chip, null, ___EmotionJSX(Chip.Icon, {
    icon: "Estimate",
    label: "Points in epic:"
  }), numPointsTotal), epic.percentCompleted !== 100 && (usingPoints ? numPointsTotal : totalStories) > 0 && ___EmotionJSX(EntityProgressBar, {
    entityName: Nouns.Epic.singular,
    detail: {
      tooltipStatsPercent: true
    },
    stats: getProgressStats(epic.stats, usingPoints),
    unit: progressUnit.singular,
    unitPlural: progressUnit.plural,
    tooltipType: "summary",
    width: 50
  })));
}
EpicBadgesAndLabels.displayName = "EpicBadgesAndLabels";