function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { isNew } from 'data/entity/story';
import { renderStoryLinks } from 'utils/addNew';
import { useContextMenuForCaveman } from 'utils/contextMenus/useContextMenuForCaveman';
import { updateStoryLinkComponent } from 'utils/storyLink';
import { SEARCH_ENTITY } from '../../searchResults/types';
import { AddRelationshipPopover } from '../AddRelationshipDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function StoryRelationships(_ref) {
  let {
    story
  } = _ref;
  // Toggle visibility using CSS for now since some tests rely on there
  // always being a `.story-relationships` selector available.
  // TODO: Replace with conditional null render
  const display = !story.story_links.length ? 'none' : '';
  const storyAttachmentsRef = useRef(null);
  useEffectOnce(() => {
    if (isNew(story)) {
      renderStoryLinks();
    } else {
      updateStoryLinkComponent(story, storyAttachmentsRef.current);
    }
  });
  const contextMenuProps = useContextMenuForCaveman();
  return ___EmotionJSX("div", {
    ref: storyAttachmentsRef,
    className: "story-attachment",
    "data-type": "relationship",
    style: {
      display
    }
  }, ___EmotionJSX("div", {
    className: "section-head"
  }, "Story Relationships"), ___EmotionJSX("div", _extends({}, contextMenuProps, {
    className: "story-relationships",
    "data-controller": story.id ? 'StoryLink' : 'AddNewStory'
  })), ___EmotionJSX(AddRelationshipPopover, {
    resultTypes: [SEARCH_ENTITY.STORY],
    story: story
  }, _ref2 => {
    let {
      setVisible
    } = _ref2;
    return ___EmotionJSX("div", {
      className: "add-story-link-button"
    }, ___EmotionJSX("button", {
      className: "action micro flat-white add-story-link",
      onClick: () => setVisible(true),
      "data-tabindex": true
    }, ___EmotionJSX("span", {
      className: "fa fa-plus"
    }), " Add Story Relationship..."));
  }));
}
StoryRelationships.displayName = "StoryRelationships";