import { getProfileColor } from '@clubhouse/shared/utils';
import { UserTooltip } from '../UserTooltip';
import { AvatarContainer } from './AvatarContainer';
import { AvatarContent } from './AvatarContent';
import { SIZE } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
export * from './config';
export const Avatar = _ref => {
  let {
    withTooltip,
    profile,
    size,
    showEmail,
    hasBorder,
    style
  } = _ref;
  const color = profile ? getProfileColor(profile) : undefined;
  const content = ___EmotionJSX(AvatarContainer, {
    style: style,
    size: size
  }, ___EmotionJSX(AvatarContent, {
    fullName: profile?.name,
    size,
    hasBorder,
    color,
    imageUrl: profile?.display_icon?.url
  }));
  if (profile && withTooltip) {
    return ___EmotionJSX(UserTooltip, {
      profile: profile,
      showEmail: showEmail
    }, content);
  }
  return content;
};
Avatar.defaultProps = {
  size: SIZE.M,
  style: undefined,
  withTooltip: false,
  showEmail: false
};
Avatar.SIZE = SIZE;