import { Chip } from '@clubhouse/shared/components/Chip';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DoneChip = _ref => {
  let {
    completed
  } = _ref;
  const themeName = useThemeName();
  if (!completed) return null;
  return ___EmotionJSX(Chip, {
    kind: themeName === 'light' ? 'green' : 'greenInverted'
  }, ___EmotionJSX(Chip.Icon, {
    icon: "CheckCircle",
    label: "Done"
  }), "Done");
};
DoneChip.displayName = "DoneChip";