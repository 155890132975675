import "core-js/modules/es.array.push.js";
import Dialog from './dialog';
import * as Event from '../_frontloader/event';
import Is from './is';
import PanelController from '../controllers/panel';
import Utils from './utils';
import { forceUpdate, render as renderPortal, unmount as unmountPortal } from './ViewPortals';
import noop from 'lodash/noop';
import Log from './log';
const exports = {};
exports.AppEvent = Event;
const getNodesWithDataComponent = node => {
  return _.toArray(node.querySelectorAll('[data-component-key]')).reduce((acc, node) => {
    const key = node.getAttribute('data-component-key');
    if (acc.has(key)) {
      throw Error(`Duplicate key = "${key}", More than one node with the same data-component-key is not supported because handling React rerenders is challenging and error prone.`);
    }
    acc.set(key, node);
    return acc;
  }, new Map());
};
const stopEvent = e => {
  e.stopPropagation();
  e.preventDefault();
};
const handleActiveElement = _ref => {
  let {
    templateMountNode,
    restoreActiveElement
  } = _ref;
  const activeElement = document.activeElement;
  if (!restoreActiveElement || !templateMountNode.contains(activeElement)) {
    return () => {};
  }
  const events = ['blur', 'focus'];
  events.forEach(eventName => window.addEventListener(eventName, stopEvent, true));
  return () => {
    try {
      activeElement.focus();
    } finally {
      events.forEach(eventName => window.removeEventListener(eventName, stopEvent, true));
    }
  };
};
exports.getComponentDataAttributes = node => {
  const {
    componentKey,
    componentProps = '{}',
    componentPropertyKey
  } = node.dataset;
  return {
    componentKey,
    componentProps,
    componentPropertyKey
  };
};
const htmlCache = new WeakMap();

/**
 * Render a Caveman template.
 * @param {Object} props
 * @param {Object} props.template A Caveman template object
 * @param {Object} props.templateData An object passed to template
 * @param {HTMLElement} props.templateMountNode The element to render HTML into.
 * @param {boolean} [props.restoreActiveElement=false] If true, will restore focus after render.
 * @param {boolean} [props.onlyRenderIfChanged=true] If true, will only update templateMountNode if the rendered HTML has changed since last render.
 * @returns {boolean} True if the templateMountNode was updated, otherwise false.
 */
exports.renderTemplate = _ref2 => {
  let {
    template,
    templateData = {},
    templateMountNode,
    restoreActiveElement = false,
    onlyRenderIfChanged = true
  } = _ref2;
  if (!templateMountNode) {
    return false;
  }
  const handleActiveElementFocus = handleActiveElement({
    templateMountNode,
    restoreActiveElement
  });
  try {
    const html = template.render(templateData);
    if (onlyRenderIfChanged) {
      if (htmlCache.has(templateMountNode)) {
        if (htmlCache.get(templateMountNode) === html) {
          return false;
        }
      }
      htmlCache.set(templateMountNode, html);
    }
    templateMountNode.innerHTML = html;
  } finally {
    handleActiveElementFocus();
  }
  return true;
};

/**
 * @deprecated: Try to use View.renderComponentDelayed instead
 *
 * View.renderWithComponents({
 *  template: TestTemplate,
 *  templateMountNode: mountNode,
 *  templateData: { text: 'text' },
 *  components: {
 *   reactComponent: {
 *    component: ReactTestComponent,
 *    getProps: ({ props, rerender, unmountComponent }) => {
 *     return props;
 *    }
 *   }
 *  }
 * });
 */
exports.renderWithComponents = _ref3 => {
  let {
    template,
    templateData = {},
    templateMountNode,
    components = {},
    restoreActiveElement = false
  } = _ref3;
  if (!templateMountNode) {
    return;
  }
  const handleActiveElementFocus = handleActiveElement({
    templateMountNode,
    restoreActiveElement
  });
  try {
    const html = template.render(templateData);
    templateMountNode.innerHTML = html;
    getNodesWithDataComponent(templateMountNode).forEach(mountNode => {
      const {
        componentKey,
        componentProps,
        componentPropertyKey
      } = exports.getComponentDataAttributes(mountNode);
      if (!componentPropertyKey) {
        return;
      }
      const {
        component,
        props = {},
        getProps
      } = components[componentPropertyKey];
      exports.renderComponent({
        mountNode,
        component,
        props: {
          ...deserializeProps(componentProps),
          ...props
        },
        getProps,
        componentKey
      });
    });
  } finally {
    handleActiveElementFocus();
  }
};
const defaultPredicate = _ref4 => {
  let {
    containerSelector
  } = _ref4;
  return Boolean(document.querySelector(containerSelector));
};

/**
 * @deprecated Use unmountDetachedComponents instead
 */
exports.unmountComponents = _ref5 => {
  let {
    templateMountNode
  } = _ref5;
  if (!templateMountNode) {
    return;
  }
  getNodesWithDataComponent(templateMountNode).forEach(node => {
    const {
      componentKey
    } = exports.getComponentDataAttributes(node);
    const {
      unmountComponent = () => {}
    } = reactInstances.get(componentKey) || {};
    unmountComponent();
  });
};
exports.serializeProps = obj => {
  return JSON.stringify(obj);
};
const deserializeProps = str => {
  return JSON.parse(str);
};

/**
 * @deprecated
 */
exports.renderComponentConditionally = _ref6 => {
  let {
    containerSelector,
    component,
    props,
    children,
    predicate = defaultPredicate,
    componentKey
  } = _ref6;
  if (!predicate({
    containerSelector,
    component,
    props,
    children
  })) {
    return {
      unmountComponent: noop
    };
  }
  return exports.renderComponent({
    component,
    props,
    containerSelector,
    children,
    componentKey
  });
};
const reactInstances = new Map();
let unmountDetachedComponentsAnimationFrameId;
exports.unmountDetachedComponents = () => {
  cancelAnimationFrame(unmountDetachedComponentsAnimationFrameId);
  unmountDetachedComponentsAnimationFrameId = requestAnimationFrame(() => {
    reactInstances.forEach(_ref7 => {
      let {
        mountNode,
        unmountComponent
      } = _ref7;
      if (!mountNode.isConnected) {
        unmountComponent();
      }
    });
  });
};
const removePageDestroyListener = _ref8 => {
  let {
    componentKey
  } = _ref8;
  if (!componentKey) {
    return;
  }
  Event.off(`pageDestroy.${componentKey}`);
};
const addPageDestroyListener = _ref9 => {
  let {
    componentKey = 'no-component-key',
    unmountComponent
  } = _ref9;
  Event.once(`pageDestroy.${componentKey}`, () => {
    unmountComponent();
    forceUpdate();
  });
};

/**
 * @deprecated: Try to use View.renderComponentDelayed instead. If you must use this function, use the componentKey prop so that the react component instance can be automatically unmounted with a call to View.unmountDetachedComponents
 *
 * View.renderComponent({
 *   componentKey: 'unique-id',
 *   mountNode: document.querySelector('#mount-node'),
 *   component: <Component />
 * });
 *
 */
exports.renderComponent = _ref10 => {
  let {
    containerSelector,
    mountNode = document.querySelector(containerSelector),
    component,
    props = {},
    getProps = () => props,
    children = null,
    preventUnmount = false,
    componentKey
  } = _ref10;
  if (!mountNode) {
    return {
      unmountComponent: () => {}
    };
  }
  const unmountComponent = () => {
    if (componentKey && !reactInstances.has(componentKey)) {
      return false;
    }
    reactInstances.delete(componentKey);
    removePageDestroyListener({
      componentKey
    });
    return unmountPortal({
      componentKey,
      mountNode
    });
  };
  if (componentKey) {
    const {
      mountNode: existingMountNode
    } = reactInstances.get(componentKey) || {};
    if (existingMountNode && existingMountNode !== mountNode) {
      const {
        componentProps
      } = exports.getComponentDataAttributes(mountNode);
      existingMountNode.setAttribute('data-component-props', componentProps);
      mountNode.parentNode.replaceChild(existingMountNode, mountNode);
      mountNode = existingMountNode;
    }
    mountNode.classList.add('r_react');
    reactInstances.set(componentKey, {
      componentKey,
      unmountComponent,
      mountNode
    });
  }
  const rerender = _ref11 => {
    let {
      props,
      children
    } = _ref11;
    return exports.renderComponent({
      componentKey,
      mountNode,
      component,
      props,
      children
    });
  };
  let element = component;
  if (React.isValidElement(element)) {
    element = React.cloneElement(element, getProps({
      props,
      rerender,
      unmountComponent
    }));
  } else {
    element = React.createElement(component, getProps({
      props,
      rerender,
      unmountComponent
    }), children);
  }
  renderPortal({
    componentKey,
    element,
    mountNode
  });
  if (!preventUnmount) {
    removePageDestroyListener({
      componentKey
    });
    addPageDestroyListener({
      componentKey,
      unmountComponent
    });
  }
  return {
    unmountComponent
  };
};
let renderComponentsDelayed = [];
let renderComponentsDelayedTimer = null;
const renderComponentsDelayedTimerFn = () => {
  renderComponentsDelayedTimer = null;
  renderComponentsDelayed.forEach(call => {
    call.unmount = exports.renderComponent(call.args).unmountComponent;
  });
  renderComponentsDelayed = [];
};
const startRenderComponentsTimer = () => {
  if (renderComponentsDelayedTimer) {
    cancelAnimationFrame(renderComponentsDelayedTimer);
  }
  renderComponentsDelayedTimer = requestAnimationFrame(renderComponentsDelayedTimerFn);
};
exports.componentToTemplate = (component, componentKey) => {
  return {
    render(props) {
      return exports.renderComponentDelayed({
        component,
        componentKey,
        props
      }).html;
    }
  };
};

/**
 * Render a React component in a caveman template.
 *
 * Synchronously return an html string for insertion into the DOM, then async mount a react component on the rendered DOM node.
 * Tips: React components are unmounted when a page is destroyed.
 * If a view changes without the page being destroyed, such as toggling between story spaces, or change from column view to table view,
 * then call View.unmountDetachedComponents to unmount all the detached React components.
 *
 * Usage in a caveman template and controller pair
 * // template.html
 * {{- unescape App.Controller.ExampleController.renderReactComponent(data) }}
 *
 * // exampleController.js
 * exports.renderReactComponent = (data) => {
 *   return View.renderComponentDelayed({
 *     componentKey: 'uniqueKey',
 *     component: <ReactComponent />,
 *   }).html;
 * }
 *
 * // example implementation of a controller specific function to unmount components when a view is toggled
 * exports.cleanUpWhenViewIsToggled = () => View.unmountDetachedComponents();
 */
exports.renderComponentDelayed = args => {
  const componentId = `cid-${args.componentKey}`;
  const call = {
    args: {
      ...args,
      containerSelector: `#${componentId}`
    },
    unmount: null
  };
  renderComponentsDelayed.push(call);
  startRenderComponentsTimer();
  const unmountComponent = () => call.unmount && call.unmount();
  const html = `
    <${args.wrapperTag || 'div'}
      data-component-key="${args.componentKey}"
      id="${componentId}"
      ${args.cssStyles ? `style="${args.cssStyles}"` : ''}
      ${args.cssClass ? `class="${args.cssClass} r_react"` : 'class="r_react"'}
      ${args.containerProps ? Object.keys(args.containerProps).map(key => `${key}="${args.containerProps[key]}"`).join('\n') : ''}
    ></${args.wrapperTag || 'div'}>
  `;
  return {
    unmountComponent,
    html
  };
};
exports.unmountComponent = _ref12 => {
  let {
    mountNode
  } = _ref12;
  if (mountNode) {
    ReactDOM.unmountComponentAtNode(mountNode);
  }
};
exports.attach = (html, target, method) => {
  // Valid methods are appendTo, prependTo, insertAfter, insertBefore
  // since they return the new element, not the target
  method = method || 'appendTo';
  return $(html)[method](target);
};
exports.removeTopLevelPageElements = () => {
  $('body > #layout, body > #content, body > #menu, body > #messages').remove();
};
exports.replaceElement = (selectorOrElement, html) => {
  const existing = $(selectorOrElement);
  if (existing.length > 0) {
    const newElement = $(html).insertAfter(existing);
    existing.remove();
    return newElement;
  }
  return false;
};
exports.fadeAndSlideUp = (element, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (Utils.animationDisabled()) {
    $(element).remove();
    callback();
    return false;
  }
  const speed = 250;
  const outerHeight = $(element).outerHeight();
  $(element).animate({
    opacity: 0
  }, speed, function () {
    const shim = $('<div>').insertAfter(this);
    $(this).remove();
    shim.height(outerHeight).slideUp(speed, 'swing', function () {
      $(this).remove();
      callback();
    });
  });
};
exports.fadeAndSlideDown = element => {
  if (Utils.animationDisabled()) {
    return false;
  }
  const speed = 250;
  element.hide().css({
    opacity: 0
  }).slideDown(speed, function () {
    $(this).animate({
      opacity: 1
    }, speed);
  });
};
exports.fadeAndSlideToggleElements = (closingElement, openingElement, callback) => {
  if (!openingElement || !openingElement.length) {
    Log.error(new Event('Aborting fade and slide toggle action, missing opening element'), {
      closingElement
    });
    return;
  }
  const SPEED = 120;
  callback = _.isFunction(callback) ? callback : _.noop;
  openingElement.stop(true, true).css({
    opacity: 0
  });
  closingElement.stop(true, true).css({
    opacity: 1
  }).animate({
    opacity: 0
  }, SPEED, function () {
    $(this).slideUp(SPEED);
    openingElement.slideDown(SPEED, function () {
      $(this).animate({
        opacity: 1
      }, SPEED, callback);
    });
  });
};
exports.slideElementsLeft = (closingElement, openingElement, callback) => {
  _slideElements(closingElement, openingElement, callback, true);
};
exports.slideElementsRight = (closingElement, openingElement, callback) => {
  _slideElements(closingElement, openingElement, callback, false);
};
function _slideElements(closingElement, openingElement, callback, isLeft) {
  const SPEED = 160;
  const DISTANCE = 24;
  const openingLeft = isLeft ? DISTANCE : DISTANCE * -1;
  const closingLeft = isLeft ? DISTANCE * -1 : DISTANCE;
  openingElement.stop(true, true).css({
    opacity: 0,
    left: openingLeft,
    position: 'relative'
  });
  closingElement.stop(true, true).css({
    opacity: 1,
    left: 0,
    position: 'relative'
  }).animate({
    opacity: 0,
    left: closingLeft
  }, SPEED, 'easeInQuad', () => {
    closingElement.hide();
    openingElement.show().animate({
      opacity: 1,
      left: 0
    }, SPEED, 'easeOutQuad', callback);
  });
}
exports.changeButtonToSaving = (selector, verb) => {
  verb = verb || 'Saving';
  const html = '<span class="fa fa-spin fa-star"></span> ' + verb + '...';
  $(selector).html(html).addClass('disabled').attr('disabled', 'disabled');
};
exports.disableElement = selector => {
  $(selector).addClass('disabled').attr('disabled', 'disabled');
};
exports.revertButtonState = (selector, html) => {
  $(selector).html(html).removeClass('disabled').removeAttr('disabled');
};
exports.changeButtonToSuccess = selector => {
  const button = $(selector);
  const originalHtml = button.html();
  button.html('<span class="fa fa-check"></span> Changes saved.').addClass('green');
  setTimeout(() => {
    $(selector).html(originalHtml).removeClass('green');
  }, 2000);
};
exports.freezeBodyOverflow = () => {
  $('html, body').addClass('frozen-overflow');
};
exports.unfreezeBodyOverflow = () => {
  $('html, body').removeClass('frozen-overflow');
};
exports.genericResizeHandler = () => {
  if (!Is.mobile()) {
    Utils.switchMobileDesktopView();
  }
};
exports.canFocusPageElement = () => {
  return !PanelController.isOpen() && !Dialog.isOpen();
};
export { exports as default };