import { Chip } from '@clubhouse/shared/components/Chip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const MentionName = _ref => {
  let {
    mentionName
  } = _ref;
  if (!mentionName) return null;
  return ___EmotionJSX("div", null, ___EmotionJSX(Chip, {
    kind: "default"
  }, `@${mentionName}`));
};
MentionName.displayName = "MentionName";