import { useAsync } from 'react-use';
import { TableNameText, TableNameWithLabelsAndBlockers, TableNameWithLabelsAndBlockersLabels } from 'components/shared/table/base';
import { NameField as SharedNameField } from 'components/shared/table/fields';
import { getBlockedCount, getBlocksCount } from 'data/entity/story';
import { storeGroupByForDialogs } from 'utils/groupBy';
import { TableBlockers } from '../../table/base/TableName/TableBlockers';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const NameFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  const {
    loading,
    value
  } = useAsync(async () => {
    const [blocksCount, blockedCount] = await Promise.all([getBlocksCount(entity), getBlockedCount(entity)]);
    return {
      blocksCount,
      blockedCount
    };
  }, [entity]);
  const blocksCount = value?.blocksCount ?? 0;
  const blockedCount = value?.blockedCount ?? 0;
  const showBlockers = !loading && !!(blocksCount || blockedCount);
  const showLabels = entity.labels.length > 0;
  return ___EmotionJSX(TableNameWithLabelsAndBlockers, {
    onClick: storeGroupByForDialogs,
    url: entity.app_url,
    archived: Boolean(entity.archived),
    navigation: {
      className: 'story',
      id: entity.id
    }
  }, ___EmotionJSX(TableNameText, {
    text: entity.name
  }), (showBlockers || showLabels) && ___EmotionJSX(TableBlockers, {
    blockedCount: blockedCount,
    blocksCount: blocksCount
  }, showLabels && ___EmotionJSX(TableNameWithLabelsAndBlockersLabels, {
    labels: entity.labels
  })));
};
NameFieldComponent.displayName = "NameFieldComponent";
export const NameField = () => SharedNameField({
  Component: NameFieldComponent,
  width: 400
});