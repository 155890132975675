import { useState } from 'react';
import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { capitalize } from '@clubhouse/shared/utils';
import { SelectStoryType, StoryTypes } from '../shared/SelectStoryType';
import { Actions } from './shared/Actions';
import { MappingHeader, MappingList, MappingRow } from './shared/MappingList';
import { Para } from './shared/Para';
import { jsx as ___EmotionJSX } from "@emotion/react";
const from = jiraType => ___EmotionJSX("span", null, capitalize(jiraType.name));
from.displayName = "from";
const to = (i, jiraType, onSelect) => {
  return ___EmotionJSX(SelectStoryType, {
    initialValue: jiraType.target_type,
    onSelect: s => onSelect(i, s)
  });
};
to.displayName = "to";
const initialData = [{
  id: '1',
  name: 'bug'
}, {
  id: '2',
  name: 'subtask'
}, {
  id: '3',
  name: 'task'
}, {
  id: '4',
  name: 'story'
}];
const excludedJiraStoryTypes = ['epic'];
const setInitialStoryTypes = storyTypes => storyTypes.filter(s => !excludedJiraStoryTypes.includes(s.name.toLowerCase())).map(s => !s.target_type ? {
  ...s,
  target_type: s.name.toLowerCase() === StoryTypes.BUG ? StoryTypes.BUG : StoryTypes.FEATURE
} : s);
export const MapToStoryType = _ref => {
  let {
    goBack,
    onDone,
    importMap
  } = _ref;
  const [storyTypes, setStoryTypes] = useState(setInitialStoryTypes(importMap.story_types || initialData));
  const onSelect = (index, selectedValue) => {
    const clone = storyTypes.slice(0);
    clone[index].target_type = selectedValue;
    setStoryTypes(clone);
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Para, null, "Map your Jira Issue Types to one of three Story types in ", BRAND.NAME, ": Feature, Bug, or Chore. We will also apply a Label with the previous Jira Issue Type."), ___EmotionJSX(AlertMessage, {
    kind: AlertMessage.KIND.INFO
  }, ___EmotionJSX("div", {
    "data-perma-id": "map-story-types"
  }, "Jira ", ___EmotionJSX("strong", null, "Epics"), " will be imported as ", BRAND.NAME, " Epics. Jira ", ___EmotionJSX("strong", null, "Subtasks"), " will be imported as ", BRAND.NAME, " Stories with a dependency on the parent Story from Jira.")), ___EmotionJSX(MappingList, null, ___EmotionJSX(MappingHeader, {
    fromLabel: "Jira Issue Type",
    toLabel: `${BRAND.NAME} Story Type`
  }), storyTypes.map((jiraType, i) => ___EmotionJSX(MappingRow, {
    key: jiraType.id,
    from: from(jiraType),
    to: to(i, jiraType, onSelect)
  }))), ___EmotionJSX(Actions, null, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    onClick: goBack
  }, "Go Back"), ___EmotionJSX(Button, {
    kind: Button.KIND.PRIMARY,
    showProgress: false,
    onClick: () => onDone(storyTypes)
  }, "Next: Map Workflow States")));
};