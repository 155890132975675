function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ObjectiveSearchCardObjectiveFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
import { MilestoneState } from '@clubhouse/shared/types';
import { timestampToFromNowDate } from '@clubhouse/shared/utils/date';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useObjectiveContextMenuWithQuickSearchSessionLogging } from 'utils/contextMenus/useObjectiveContextMenu';
import { loadURL } from 'utils/hotReload';
import { SearchCard, SearchCardLink } from '../SearchCard/SearchCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
const objectiveStateNameMap = {
  toDo: 'To Do',
  inProgress: 'In Progress',
  done: 'Done'
};
export const objectiveStateMap = {
  [MilestoneState.InProgress]: 'inProgress',
  [MilestoneState.ToDo]: 'toDo',
  [MilestoneState.Done]: 'done'
};
export const ObjectiveSearchCardObjectiveFragment = ObjectiveSearchCardObjectiveFragmentDoc;
export const ObjectiveSearchCard = _ref => {
  let {
    objective,
    openModalOnClick,
    onClick
  } = _ref;
  const {
    publicId: id,
    objectiveState,
    objectiveName,
    archived,
    updatedAt
  } = objective;
  const url = getObjectiveHref(id);
  const contextMenuProps = useObjectiveContextMenuWithQuickSearchSessionLogging(objective.publicId, useCallback(() => {
    onClick?.({
      ...objective,
      url
    });
  }, [objective, onClick, url]));
  const handleClick = e => {
    onClick?.({
      ...objective,
      url
    });
    if (isClickedWithModifier(e)) return;
    if (onClick) {
      e.preventDefault();
    }

    // This handles whether we navigate to a milestone or add a badge in docs
    if (openModalOnClick) {
      loadURL(url, 'milestones');
    }
  };
  return ___EmotionJSX(SearchCardLink, _extends({
    onClick: handleClick,
    href: url,
    "data-focusable": true
  }, contextMenuProps), ___EmotionJSX(SearchCard, {
    isArchived: archived
  }, ___EmotionJSX(SearchCard.Icon, {
    icon: "Objectives",
    isCompleted: objectiveState === WORKFLOW_STATE_TYPES.DONE,
    kind: objectiveState === 'toDo' ? 'default' : objectiveState === 'done' ? 'green' : 'blue',
    label: "Objective",
    isArchived: archived
  }), objectiveName && ___EmotionJSX(SearchCard.Title, null, ___EmotionJSX(Emojify, null, objectiveName)), ___EmotionJSX(SearchCard.Metadata, null, archived ? ___EmotionJSX(SearchCard.MetadataItem, null, "Archived") : ___EmotionJSX(SearchCard.MetadataItem, null, objectiveStateNameMap[objectiveState]), ___EmotionJSX(SearchCard.MetadataItem, null, "Updated ", timestampToFromNowDate(updatedAt)))));
};
ObjectiveSearchCard.displayName = "ObjectiveSearchCard";
// Collectionize:
export const MilestoneSearchCardAdapter = props => {
  const objective = {
    __typename: 'Objective',
    id: props.milestone.global_id,
    publicId: Number(props.milestone.id),
    objectiveName: props.milestone.name,
    objectiveState: objectiveStateMap[props.milestone.state],
    updatedAt: props.milestone.updated_at,
    archived: props.milestone.archived
  };
  return ___EmotionJSX(ObjectiveSearchCard, _extends({}, props, {
    objective: objective
  }));
};
MilestoneSearchCardAdapter.displayName = "MilestoneSearchCardAdapter";