import * as EditOrganizationTemplate from 'app/client/settingsShared/views/templates/editOrganization.html';
import * as EditOrganizationFormTemplate from 'app/client/settingsShared/views/templates/editOrganizationForm.html';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import { WORKING_DAYS } from '@clubhouse/shared/constants';
import { WorkingDaysSelect } from 'components/settings/WorkingDaysSelect';
import { sendEvent } from 'utils/segment';
import CompanyModel from '../../../core/js/models/company';
import EditProfileController from './editProfile';
import FlashController from '../../../core/js/controllers/flash';
import IconModel from '../../../core/js/models/icon';
import Is from '../../../core/js/modules/is';
import OrganizationModel from '../../../core/js/models/organization';
import ReportsController from '../../../core/js/controllers/reports';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { EVENTS } from 'utils/monitoring';
import defaultShortcutLogo from '@clubhouse/assets/svg/shortcut-logos/default-workspace-logo.svg?url';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const PARENT_SELECTOR = '#edit-organization-form';
const BUTTON_SELECTOR = '#save-org-changes';
const CACHED_WORKING_DAYS_ID = 'WORKING_DAYS';
const ICON_PARENT_SELECTOR = '#edit-organization-icon-form';
exports.title = `General Settings | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/workspace';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
const setWorkingDaysInCache = workingDays => {
  exports[CACHED_WORKING_DAYS_ID] = workingDays;
  if (workingDays.length === 0) {
    View.disableElement(BUTTON_SELECTOR);
  } else {
    View.revertButtonState(BUTTON_SELECTOR, $(BUTTON_SELECTOR).html());
  }
};
const getWorkingDaysInCache = () => {
  return exports[CACHED_WORKING_DAYS_ID];
};
exports.render = function () {
  let callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.noop;
  const me = UserModel.getLoggedInUserPermission();
  const readOnly = !Is.admin(me);
  const html = EditOrganizationTemplate.render({
    active: 'editOrganization',
    me,
    readOnly
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  OrganizationModel.fetchCurrent(() => {
    if (Url.isCurrentPath(exports.route())) {
      const organization = OrganizationModel.getCurrent();
      const company = CompanyModel.getFromOrg({
        id: organization.id
      });
      setWorkingDaysInCache(organization.working_days_in_a_week);
      View.renderWithComponents({
        template: EditOrganizationFormTemplate,
        templateMountNode: document.querySelector('.waiting-for-content'),
        templateData: {
          company,
          me,
          organization,
          readOnly,
          defaultShortcutLogo
        },
        restoreActiveElement: true,
        components: {
          readOnlyMessage: {
            component: AskAnOwnerAlert,
            props: {
              description: 'Only owners and admins can update these settings.'
            }
          },
          workingDaysSelect: {
            component: WorkingDaysSelect,
            props: {
              workingDays: organization.working_days_in_a_week,
              onChange: setWorkingDaysInCache
            }
          },
          uploadIcon: {
            component: ___EmotionJSX(DeprecatedIconAdapter, {
              fill: "currentColor",
              width: 16
            }, ___EmotionJSX(Icon, {
              icon: "Upload"
            }))
          },
          infoIcon: {
            component: ___EmotionJSX(DeprecatedIconAdapter, {
              fill: "var(--iconBlueColor)",
              width: 32
            }, ___EmotionJSX(Icon, {
              icon: "InfoCircle"
            }))
          }
        }
      });
      Validator.init(PARENT_SELECTOR);
      SettingsPage.onDestroy('EditOrganization', () => {
        Validator.destroy(PARENT_SELECTOR);
      });
      if (_.isFunction(callback)) callback();
    }
  });
};
exports.updateCurrentTime = function () {
  return EditProfileController.updateCurrentTime.call(this);
};
exports.saveChanges = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  data.working_days_in_a_week = getWorkingDaysInCache();
  const newWorkingDays = JSON.stringify(data.working_days_in_a_week.sort());
  const oldWorkingDays = JSON.stringify(OrganizationModel.getCurrent().working_days_in_a_week.sort());
  const workingDaysWereChanged = oldWorkingDays !== newWorkingDays;
  const oldButtonHTML = $(BUTTON_SELECTOR).html();
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    OrganizationModel.saveChanges(data, err => {
      if (err) {
        View.revertButtonState(BUTTON_SELECTOR, oldButtonHTML);
        FlashController.error(PARENT_SELECTOR, 'We were unable to update your settings.', err);
      } else {
        if (workingDaysWereChanged) {
          const selectionAsDayNames = data.working_days_in_a_week.sort().map(value => Object.keys(WORKING_DAYS).find(name => WORKING_DAYS[name] === value));
          sendEvent(EVENTS.Interaction_Settings_WorkingDays, {
            num_working_days: selectionAsDayNames.length,
            selection: selectionAsDayNames
          });
        }
        exports.render();
        FlashController.success(PARENT_SELECTOR, 'Settings updated.');
        if (ReportsController && ReportsController.render) {
          ReportsController.render();
        }
      }
    });
  }
  return false;
};
exports.openChooser = () => {
  $(ICON_PARENT_SELECTOR).find('.html-file-upload').click();
  return false;
};
exports.saveIcon = files => {
  IconModel.updateOrgIcon(_.head(files).id, (err, res) => {
    if (err) {
      FlashController.error(ICON_PARENT_SELECTOR, 'We were unable to save your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(ICON_PARENT_SELECTOR, 'New icon saved!');
      });
    }
  });
  return false;
};
exports.deleteIcon = () => {
  IconModel.updateOrgIcon(null, (err, res) => {
    if (err) {
      FlashController.error(PARENT_SELECTOR, 'We were unable to delete your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(PARENT_SELECTOR, 'Icon deleted.');
      });
    }
  });
  return false;
};
export { exports as default };