import { Icon } from '@useshortcut/shapes-ds';
import { COLORS } from '../../colors';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Upload" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const UploadCloud = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    fill: COLORS.GRAY65,
    width: width
  }, ___EmotionJSX(Icon, {
    icon: "Upload"
  }));
};
UploadCloud.displayName = "UploadCloud";