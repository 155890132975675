import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Square = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH,
    className
  } = _ref;
  return ___EmotionJSX("svg", {
    fill: fill,
    width: width,
    height: width,
    viewBox: "0 0 12 12",
    className: className
  }, ___EmotionJSX("path", {
    d: "M9.6,1.1H2.4c-0.4,0-0.7,0.1-0.9,0.4S1.1,2.1,1.1,2.4v7.1c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4h7.1c0.4,0,0.7-0.1,1-0.4 c0.3-0.3,0.4-0.6,0.4-1V2.4c0-0.4-0.2-0.7-0.4-1C10.2,1.2,9.9,1.1,9.6,1.1z M12,2.4v7.1c0,0.7-0.2,1.2-0.7,1.7 c-0.5,0.5-1.1,0.8-1.7,0.8H2.4c-0.7,0-1.2-0.2-1.7-0.7C0.2,10.8,0,10.2,0,9.6V2.4c0-0.7,0.2-1.2,0.7-1.7S1.8,0,2.4,0h7.1 c0.7,0,1.2,0.2,1.7,0.7S12,1.8,12,2.4z"
  }));
};
Square.displayName = "Square";