function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import "core-js/modules/es.array.push.js";
import SearchController from 'app/client/core/js/controllers/search.js';
import TooltipController from 'app/client/core/js/controllers/tooltip.js';
import Search from 'app/client/core/js/modules/search.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Search'], SearchController], [['Controller', 'Tooltip'], TooltipController], [['Search'], Search], [['Tooltip'], Tooltip], [['Controller', 'Search'], SearchController], [['Controller', 'Tooltip'], TooltipController], [['Search'], Search], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { focusOutlineClass } from '@clubhouse/shared/styles/focus';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { jsx as ___EmotionJSX } from "@emotion/react";
const OPERATORS = [{
  name: 'team:',
  tooltip: 'Team names or IDs',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "team:"), "\"Growth Team\"")
}, {
  name: 'project:',
  tooltip: 'Project names or IDs',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "project:"), "Mobile")
}, {
  name: 'owner:',
  tooltip: 'Usernames',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "owner:"), "robertsmith")
}, {
  name: 'requester:',
  tooltip: 'Usernames',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "requester:"), "janesmith")
}, {
  name: 'label:',
  tooltip: '',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "label:"), "q2-design")
}, {
  name: 'state:',
  tooltip: 'Workflow state names or IDs',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "state:"), "\"ready for dev\"")
}, {
  name: 'created:',
  tooltip: '',
  tooltipFn: 'App.Controller.Tooltip.renderSearchByDateTooltip',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "created:"), "*..YYYY-MM-DD")
}, {
  name: 'type:',
  tooltip: 'Feature, bug, or chore',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "type:"), "bug")
}, {
  name: 'completed:',
  tooltip: '',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "completed:"), "yesterday")
}, {
  name: 'is:archived',
  tooltip: '',
  description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "is:archived"), " test")
}];
export const OperatorsDrawer = _ref2 => {
  let {
    rootProps,
    query,
    setQuery,
    commandBar
  } = _ref2;
  const filteredOperators = isProjectsFeatureEnabled() ? OPERATORS : OPERATORS.filter(operator => operator.name !== 'project:');
  return ___EmotionJSX("div", _extends({}, rootProps, {
    className: "search-operators-drawer",
    "data-controller": "Search",
    style: {
      height: commandBar ? '100%' : undefined
    }
  }), !commandBar && /* TODO: This should be a button and not a link */
  ___EmotionJSX("a", {
    href: "#",
    className: `drawer-opener ${focusOutlineClass}`,
    "data-on-click": "toggleDrawer"
  }, ___EmotionJSX("div", {
    className: "drawer-header"
  }, ___EmotionJSX("span", {
    className: "fa fa-caret-right"
  }), " Search Operators")), ___EmotionJSX("div", {
    className: `${commandBar ? 'commandbar-' : ''}drawer-contents`,
    style: {
      display: !commandBar ? 'none' : undefined
    }
  }, ___EmotionJSX("div", {
    className: "example-searches-header"
  }, "COMMON SEARCH OPERATORS"), ___EmotionJSX("table", null, ___EmotionJSX("tbody", null, filteredOperators.map(operator => ___EmotionJSX("tr", {
    key: operator.name
  }, ___EmotionJSX("td", null, ___EmotionJSX("button", _extends({
    "data-focusable": true,
    className: `operator-suggestion action micro white ${focusOutlineClass}`,
    onClick: () => {
      setQuery(`${query} ${operator.name}`);
    },
    "data-tooltip": operator.tooltip
  }, operator.tooltipFn ? {
    'data-tooltip-fn': operator.tooltipFn
  } : {}), operator.name)), ___EmotionJSX("td", null, operator.description))))), ___EmotionJSX("a", {
    href: KNOWLEDGE_BASE_SLUGS.SEARCH_OPERATORS,
    target: "_blank",
    rel: "noopener noreferrer",
    className: `search-help-link ${focusOutlineClass}`
  }, "See all Search Operators ", ___EmotionJSX("span", {
    className: "fa fa-external-link"
  }))));
};
OperatorsDrawer.displayName = "OperatorsDrawer";