import "core-js/modules/es.array.push.js";
import OrganizationModel from 'app/client/core/js/models/organization.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Organization'], OrganizationModel], [['Model', 'Organization'], OrganizationModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { PaidSeatsRemaining } from 'components/shared/PaidSeatsRemaining';
import { isOrgDisabled, useCurrentOrgId } from 'data/entity/organization';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOrOwner, withinOrg } from 'utils/is';
import { navigateTo } from 'utils/navigation';
import { ActiveUsers } from './ActiveUsers';
import { InvitedUsers } from './InvitedUsers';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ActiveUsersDirectory(_ref2) {
  let {
    users,
    scimEnabled,
    invites,
    inviteUsersRoute,
    onUpdateRole
  } = _ref2;
  const workspaceId = useCurrentOrgId();
  const isAdmin = adminOrOwner(getLoggedInUserPermission());
  const isWithinOrg = withinOrg();
  const isWorkspaceDisabled = isOrgDisabled(workspaceId);
  return ___EmotionJSX(React.Fragment, null, isAdmin && isWithinOrg && ___EmotionJSX("div", {
    className: "button-container"
  }, !scimEnabled && ___EmotionJSX("button", {
    onClick: () => navigateTo({
      url: inviteUsersRoute
    }),
    className: "action mini green invite-users-button"
  }, "Invite New Users"), ___EmotionJSX(PaidSeatsRemaining, null)), ___EmotionJSX(ActiveUsers, {
    users: users,
    scimEnabled: scimEnabled,
    onUpdateRole: onUpdateRole
  }), ___EmotionJSX("div", {
    className: "existing-invites",
    "data-model": "Organization",
    "data-id": workspaceId
  }, !!invites?.length && ___EmotionJSX(InvitedUsers, {
    invites: invites,
    isReadOnly: !isAdmin,
    showResendButton: !isWorkspaceDisabled && !scimEnabled
  })), ___EmotionJSX("div", {
    className: "mini-blurb"
  }, "Note that invited users are included in your active user count."));
}