import 'focus-visible';
import ReactDOM from 'react-dom';
import { Layout } from 'components/layout/Layout';
import { initializeMonitoring } from 'utils/monitoring';
import { jsx as ___EmotionJSX } from "@emotion/react";
initializeMonitoring({
  debug: Boolean(process.env.DEBUG),
  env: process.env.ENV_NAME ?? null,
  deployId: process.env.DEPLOY_ID ?? undefined
});
ReactDOM.render(___EmotionJSX(Layout, null), document.getElementById('app-root'));