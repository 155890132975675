import { StatusIcon } from '@useshortcut/shapes-ds';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getStatusIconFromType = type => {
  switch (type) {
    case 'done':
      return ___EmotionJSX(StatusIcon, {
        icon: "Done"
      });
    case 'started':
      return ___EmotionJSX(StatusIcon, {
        icon: "Started"
      });
    case 'backlog':
      return ___EmotionJSX(StatusIcon, {
        icon: "Backlog"
      });
    default:
      return ___EmotionJSX(StatusIcon, {
        icon: "Unstarted"
      });
  }
};