import Logo from '@clubhouse/assets/png/third-party-logos/firehydrant_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function FireHydrant() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.FIREHYDRANT
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "FireHydrant"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "FireHydrant Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Create or sync incidents and action items created in", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://firehydrant.com/integrations/shortcut/"
  }, "FireHydrant"), " during an incident with Stories in Shortcut."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.TAG_INTEGRATIONS
  }, "Learn more"), " on how the FireHydrant integration works in Shortcut.")));
}
FireHydrant.displayName = "FireHydrant";