import { TableGroupHeader, TableGroupHeaderButton, TableGroupHeaderLinks, TableGroupHeaderText } from '@clubhouse/shared/components/Table/components/TableGroupHeader';
import { jsx as ___EmotionJSX } from "@emotion/react";
const isUrl = obj => obj.entityUrl !== undefined;
export const GroupByHeader = _ref => {
  let {
    children,
    ...props
  } = _ref;
  return ___EmotionJSX(TableGroupHeader, null, ___EmotionJSX(TableGroupHeaderButton, null, ___EmotionJSX(TableGroupHeaderText, null, children)), isUrl(props) ? ___EmotionJSX(TableGroupHeaderLinks, {
    entityName: props.entityType.singular,
    url: props.entityUrl
  }) : props.aggregations);
};
GroupByHeader.displayName = "GroupByHeader";