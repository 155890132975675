import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { allActive, getProjectFromStory } from 'data/entity/project';
import { storiesPage as isStoriesPage } from 'utils/is';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ProjectChip = () => {
  const story = useContext(StoryContext);
  if (!story || !isProjectsFeatureEnabled() || !isStoriesPage()) return null;
  const isMoreThanOneProjectActive = allActive().length > 1;
  const project = getProjectFromStory(story);
  if (!project || !isMoreThanOneProjectActive) return null;
  return ___EmotionJSX(StoryCardInternal.Chips.ProjectChip, {
    abbreviation: project.abbreviation,
    color: project.color,
    name: project.name
  });
};
ProjectChip.displayName = "ProjectChip";