import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { useCallback, useEffect } from 'react';
import { useSearchParam } from 'utils/navigation';
import { useAppState } from './appState';
/**
 * @deprecated Choose `useAppState` instead.
 */
export const useUiState = _ref => {
  let {
    queryParam,
    appStateKey,
    format,
    default: defaultValue
  } = _ref;
  const [appState, setAppState] = useAppState({
    appStateKey
  });
  const {
    param,
    setSearchParam: setParam
  } = useSearchParam({
    paramName: queryParam,
    paramType: format,
    defaultValue: appState || defaultValue,
    shouldSetDefaultValueInUrl: true
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want initial value
  useEffect(() => {
    setParam(param);
  }, []);

  // I suspect we probably want some useEffects in here, but two-way sync in React is hard and I
  // don't think we need it just yet, so I'll leave it out. But feel free to add it if you need it!

  const set = useCallback(value => {
    setAppState(value);
    setParam(value);
  }, [setAppState, setParam]);

  // (temporary) specific solution to reset grouping/sorting on new React tables when Projects are disabled.
  // Would be great to refactor in the future to have a list of acceptable/active params, and remove
  // from that whenever necessary, but this will do for now.

  if (param === 'project_id' && !isProjectsFeatureEnabled()) {
    setParam(defaultValue ?? null);
    return [defaultValue, set];
  }
  const value = param ?? appState ?? defaultValue;
  return [value, set];
};