import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { FilterLabel } from './ReportSelectUtils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryPointsToggle = _ref => {
  let {
    onChange,
    checkedValue = 'stories'
  } = _ref;
  return ___EmotionJSX("div", null, ___EmotionJSX(FilterLabel, null, "Sum Using"), ___EmotionJSX(RadioButtons, {
    selected: checkedValue,
    onChange: onChange
  }, ___EmotionJSX(RadioButtons.TextLabel, {
    value: "stories"
  }, "Stories"), ___EmotionJSX(RadioButtons.TextLabel, {
    value: "points"
  }, "Points")));
};
StoryPointsToggle.displayName = "StoryPointsToggle";