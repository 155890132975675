import { jsx as ___EmotionJSX } from "@emotion/react";
export function Divider() {
  return ___EmotionJSX("div", null, ___EmotionJSX("hr", {
    style: {
      height: 0,
      margin: 0,
      padding: 0,
      borderTopStyle: 'solid',
      borderBottomStyle: 'none'
    }
  }));
}
Divider.displayName = "Divider";