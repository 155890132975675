import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/google_sheets_logo.png';
import SignInWithGoogle from '@clubhouse/assets/png/third-party-logos/google_signin.png';
import { LoadingDots } from '@clubhouse/shared/animations';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { deleteIntegration, enableIntegration, useIntegration } from 'data/entity/integration';
import { useIntegrationState } from 'data/entity/organization';
import { isReadOnly } from 'data/entity/user';
import { isDevelopment, isProduction, isStaging } from 'utils/is';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { getCurrentSlug } from 'utils/navigation';
import { ExternalLink } from './components/ExternalLink';
import { IntegrationStatus } from './components/IntegrationStatus';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';
const GSHEETS_INTEG_URL = '/settings/integrations/gsheets';
const getGsheetsAppConfig = () => {
  // 2020-11-24 this ClientID associated with the 'ecaspary-gsheets-test' project in Google Developer Console
  const developmentClientId = '930480451555-jlmsehr693gmnihl42g2u2p1homfsld0.apps.googleusercontent.com';
  if (isDevelopment()) {
    return {
      client_id: developmentClientId,
      redirect_uri: `http://localhost:${process.env.PORT}${GSHEETS_INTEG_URL}`
    };
  } else if (isStaging()) {
    return {
      client_id: developmentClientId,
      redirect_uri: `https://${BRAND.DOMAIN_APP_WEBSITE}${GSHEETS_INTEG_URL}`
    };
  } else if (isProduction()) {
    return {
      client_id: '570712208566-8nl7mnl5cgp0e6u256j1d8ctctcdl4fb.apps.googleusercontent.com',
      redirect_uri: `https://${BRAND.DOMAIN_APP_WEBSITE}${GSHEETS_INTEG_URL}`
    };
  }
};
const getGlobalAuthUrl = slug => `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(SCOPES)}&state=${encodeURIComponent(slug)}&response_type=code&access_type=offline&prompt=consent&${new URLSearchParams(getGsheetsAppConfig()).toString()}`;
export function GoogleSheets() {
  const readOnly = isReadOnly();
  const [isEnabled] = useIntegrationState(INTEGRATION_TYPE.GOOGLE_SHEETS);
  const integration = useIntegration(INTEGRATION_TYPE.GOOGLE_SHEETS);
  const isLoading = typeof isEnabled !== 'boolean';
  const spreadsheetUrl = integration?.gapi_spreadsheet_url;
  const spreadsheetCreator = integration?.creator;
  const hasInstalledIntegration = !!integration && !integration.disabled;
  useEffect(() => {
    if (integration && integration.disabled) enableIntegration(integration);
  }, [integration]);
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.GOOGLE_SHEETS
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Google Sheets"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Google Sheets Logo",
    invertOnDarkMode: true
  }), readOnly ? ___EmotionJSX(Layout.HelpMessage, null, "Observers cannot make changes to integrations.") : ___EmotionJSX(Layout.Blurb, null, "Our Google Sheets integration makes custom analytics easier by creating an up-to-date export of your Story data. Check out our guides on", ' ', ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_GOOGLE_SHEETS
  }, "how to set up our Google Sheets integration"), "."), ___EmotionJSX(Layout.SectionTitle, null, "Integration Status"), isLoading ? ___EmotionJSX(LoadingDots, null) : hasInstalledIntegration ? ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space4"
  }, ___EmotionJSX(IntegrationStatus, {
    isEnabled: true
  }), ___EmotionJSX(Button, {
    onClick: () => deleteIntegration(integration.id),
    kind: Button.KIND.SECONDARY
  }, "Remove Authorization"), spreadsheetUrl ? ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX("a", {
    href: spreadsheetUrl,
    target: "_blank",
    rel: "noreferrer"
  }, "Open Spreadsheet"), spreadsheetCreator && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "blurb-subtext"
  }, "Generated by ", spreadsheetCreator.name), ___EmotionJSX(Layout.Blurb, null, "By default, this Google Sheet is only available to ", spreadsheetCreator.name, ". To give others access to this data, please update the share settings for this Sheet. Please see", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://support.google.com/drive/answer/2494822?hl=en&ref_topic=7000947"
  }, "Google help documentation"), ' ', "for more details."))) : ___EmotionJSX(Layout.Blurb, null, "Your spreadsheet is being generated. Hold tight!")) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Layout.Blurb, null, "Authorize Google Sheets to install the Google Sheets Integration."), ___EmotionJSX("div", {
    className: "large"
  }, ___EmotionJSX("a", {
    href: getGlobalAuthUrl(getCurrentSlug()),
    target: "_self",
    className: "action flat-white",
    style: {
      padding: 0,
      lineHeight: 0
    }
  }, ___EmotionJSX("img", {
    className: "google-signin",
    src: SignInWithGoogle,
    alt: "Authorize Google Sheets",
    style: {
      width: 200
    }
  }))))));
}
GoogleSheets.displayName = "GoogleSheets";