import { PinStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { usePinnedItem } from 'components/shared/pin-items/hooks/usePinnedItems';
import { jsx as ___EmotionJSX } from "@emotion/react";
const PinStoryActionFragment = PinStoryActionFragmentDoc;
export const PinStoryAction = _ref => {
  let {
    story
  } = _ref;
  const {
    isPinned,
    toggle
  } = usePinnedItem({
    id: story.id,
    loggingContext: {
      typename: 'Story'
    }
  });
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Pin",
    onClick: toggle
  }, isPinned ? 'Unpin' : 'Pin', " ", Nouns.Story.singular);
};
PinStoryAction.displayName = "PinStoryAction";