import { EpicPublicIdFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableText } from '@clubhouse/shared/components/Table';
import { jsx as ___EmotionJSX } from "@emotion/react";
const EpicPublicIdFieldFragment = EpicPublicIdFieldFragmentFragmentDoc;
const PublicIdFieldComponent = _ref => {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(TableText, {
    text: entity.publicId
  });
};
PublicIdFieldComponent.displayName = "PublicIdFieldComponent";
export const PublicIdField = () => ({
  name: 'publicId',
  displayName: 'ID',
  headerProps: {
    centered: true
  },
  Component: PublicIdFieldComponent,
  width: 70,
  maxWidth: 90,
  sort: true
});