function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SCALE_DESCRIPTIONS = {
  log: 'Good for seeing everything when you have outliers.',
  linear: 'Good for when you want an accurate sense of scale.'
};
export const ScaleSelect = props => {
  return ___EmotionJSX(ReportSelectFilter, _extends({}, props, {
    label: "Scale",
    descriptions: SCALE_DESCRIPTIONS,
    LoadingComponent: ___EmotionJSX(SkeletonRectangle, {
      width: 22,
      animate: true
    })
  }));
};
ScaleSelect.displayName = "ScaleSelect";