import { MutateStoryDeadlineDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryDeadlineFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableDatePicker } from 'components/shared/table/base';
import { useMutation } from 'gql';
import { useMutationContext } from './FieldContextProvider';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryDeadlineFieldFragment = StoryDeadlineFieldFragmentFragmentDoc;
const MUTATE_STORY_DEADLINE = MutateStoryDeadlineDocument;
function DeadlineFieldComponent(_ref) {
  let {
    entity: {
      id,
      deadline
    }
  } = _ref;
  const mutationContext = useMutationContext();
  const [save] = useMutation(MUTATE_STORY_DEADLINE);
  const handleChange = useCallback(date => {
    save({
      variables: {
        id,
        input: {
          deadline: date
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        storyUpdate: {
          __typename: 'StoryPayload',
          story: {
            __typename: 'Story',
            id,
            deadline: date
          }
        }
      },
      ...mutationContext
    });
  }, [id, mutationContext, save]);
  return ___EmotionJSX(TableDatePicker, {
    colorizeDueDate: true,
    onChange: handleChange,
    value: deadline
  });
}
DeadlineFieldComponent.displayName = "DeadlineFieldComponent";
export const DeadlineField = () => ({
  name: 'deadline',
  displayName: 'Due Date',
  Component: DeadlineFieldComponent,
  width: 120,
  sort: true,
  LoadingComponent: () => ___EmotionJSX(Center, null, ___EmotionJSX(SkeletonRectangle, {
    width: 40
  })),
  headerProps: {
    centered: true
  }
});