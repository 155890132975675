import { jsx as ___EmotionJSX } from "@emotion/react";
export const LinkDots = _ref => {
  let {
    fill = '#3A95C9'
  } = _ref;
  return ___EmotionJSX("svg", {
    width: "33",
    height: "4",
    viewBox: "0 0 33 4",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, ___EmotionJSX("rect", {
    x: "0.5",
    width: "4",
    height: "4",
    rx: "2",
    fill: fill
  }), ___EmotionJSX("g", {
    opacity: "0.8"
  }, ___EmotionJSX("rect", {
    x: "7.5",
    width: "4",
    height: "4",
    rx: "2",
    fill: fill
  })), ___EmotionJSX("g", {
    opacity: "0.6"
  }, ___EmotionJSX("rect", {
    x: "14.5",
    width: "4",
    height: "4",
    rx: "2",
    fill: fill
  })), ___EmotionJSX("g", {
    opacity: "0.4"
  }, ___EmotionJSX("rect", {
    x: "21.5",
    width: "4",
    height: "4",
    rx: "2",
    fill: fill
  })), ___EmotionJSX("g", {
    opacity: "0.2"
  }, ___EmotionJSX("rect", {
    x: "28.5",
    width: "4",
    height: "4",
    rx: "2",
    fill: fill
  })));
};
LinkDots.displayName = "LinkDots";