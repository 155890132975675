import Updates from 'app/client/core/js/modules/updates';
export const delayPollingFrequencyForEditor = () => {
  setPollingFrequency(60000);
};
export const restorePollingFrequency = () => {
  setPollingFrequency(null);
};
const setPollingFrequency = ms => {
  Updates.setPollingIntervalOverride(ms);
  Updates.poll();
};
export const getBasisT = function () {
  return Updates.getTime(...arguments);
};
export const getUpdatesUrl = path => Updates.getUpdatesUrl(path);