import { StoryMoreActionsFieldFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ArchiveStoryAction } from 'components/gql/stories/actions/ArchiveStoryAction';
import { CopyStoryLinkAction } from 'components/gql/stories/actions/CopyStoryLinkAction';
import { GoToStoryAction } from 'components/gql/stories/actions/GoToStoryAction';
import { PinStoryAction } from 'components/gql/stories/actions/PinStoryAction';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryMoreActionsFieldFragment = StoryMoreActionsFieldFragmentDoc;
function MoreActionsFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(MoreActions, null, ___EmotionJSX(MoreActions.TableButton, null), ___EmotionJSX(MoreActions.Menu, null, ___EmotionJSX(GoToStoryAction, {
    story: entity
  }), ___EmotionJSX(MoreActions.Divider, null), ___EmotionJSX(PinStoryAction, {
    story: entity
  }), ___EmotionJSX(CopyStoryLinkAction, {
    story: entity
  }), ___EmotionJSX(MoreActions.Divider, null), ___EmotionJSX(ArchiveStoryAction, {
    story: entity
  })));
}
MoreActionsFieldComponent.displayName = "MoreActionsFieldComponent";
export const MoreActionsField = function () {
  let {
    frozenOverride
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'more_actions',
    displayName: '',
    Component: MoreActionsFieldComponent,
    LoadingComponent: null,
    width: 44,
    frozen: frozenOverride || {
      right: -30
    }
  };
};