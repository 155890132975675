import * as TB from '@radix-ui/react-toolbar';
import { useRef, useState } from 'react';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedEditorIcon, SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { ToolbarButton } from './ToolbarButton';
import { Autocompletes } from './autocompletes';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function OverflowMenu(_ref) {
  let {
    commands,
    items,
    onClick,
    onInsert
  } = _ref;
  const moreActionsRef = useRef(null);
  const [activePicker, setActivePicker] = useState(null);
  const ActiveAutocompleteMenu = activePicker ? Autocompletes[activePicker]?.Menu : null;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MoreActions, {
    isModal: false
  }, ___EmotionJSX(MoreActions.Trigger, {
    asChild: true
  }, ___EmotionJSX(TB.Button, {
    asChild: true
  }, ___EmotionJSX(ToolbarButton, {
    ref: moreActionsRef
  }, ___EmotionJSX(SizedIcon, {
    icon: "More",
    size: 16,
    label: "More actions"
  })))), ___EmotionJSX(MoreActions.Menu, null, items.map((key, i) => {
    if (key.startsWith('divider') && i > 0) return ___EmotionJSX(MoreActions.Divider, {
      key: `divider-${i}`
    });
    const cmd = commands[key];
    const autocomplete = Autocompletes[key];
    if (cmd) return ___EmotionJSX(MoreActions.Item, {
      key: key,
      icon: cmd.icon ? ___EmotionJSX(SizedEditorIcon, {
        icon: cmd.icon,
        size: 16
      }) : cmd.icon,
      onClick: () => onClick(cmd)
    }, cmd.name);
    if (autocomplete) return ___EmotionJSX(MoreActions.Item, {
      key: key,
      icon: ___EmotionJSX(SizedEditorIcon, {
        icon: autocomplete.icon,
        size: 16
      }),
      onClick: () => setActivePicker(key)
    }, autocomplete.label);
    return null;
  }))), ActiveAutocompleteMenu ? ___EmotionJSX(ActiveAutocompleteMenu, {
    triggerRef: moreActionsRef,
    onSelect: value => {
      onInsert(value);
      setActivePicker(null);
    },
    onClose: () => setActivePicker(null)
  }) : null);
}