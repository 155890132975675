import { createContext, useContextSelector } from 'use-context-selector';
import { Nouns } from '@clubhouse/shared/constants';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Context = createContext({
  isLoading: false,
  type: Nouns.Epic.singular
});
export const ContextProvider = _ref => {
  let {
    children,
    isLoading,
    type
  } = _ref;
  return ___EmotionJSX(Context.Provider, {
    value: {
      isLoading,
      type
    }
  }, children);
};
ContextProvider.displayName = "ContextProvider";
export const useIsLoading = () => useContextSelector(Context, _ref2 => {
  let {
    isLoading
  } = _ref2;
  return isLoading;
});
export const useType = () => useContextSelector(Context, _ref3 => {
  let {
    type
  } = _ref3;
  return type;
});