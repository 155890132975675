import { EpicCreatedDateFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { TableDate } from 'components/shared/table/base';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicCreatedDateFieldFragment = EpicCreatedDateFieldFragmentFragmentDoc;
function CreatedDateFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  return ___EmotionJSX(TableDate, {
    value: entity.createdAt
  });
}
CreatedDateFieldComponent.displayName = "CreatedDateFieldComponent";
export const CreatedDateField = () => ({
  name: 'createdAt',
  displayName: 'Created On',
  sort: true,
  Component: CreatedDateFieldComponent,
  width: 110
});