function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { GroupAndOwnersOwnersPermissionFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { UserCohort } from '@clubhouse/shared/components/UserCohort';
import { UserCohortAdapter } from 'components/shared/UserCohortAdapter';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const GroupAndOwnersOwnersPermissionFragment = GroupAndOwnersOwnersPermissionFragmentDoc;
const userCohortDefaultProps = {
  numberOfUsers: 2,
  size: Avatar.SIZE.S,
  withTooltip: true,
  withDropdown: true,
  hasBorder: false
};

/**
 * Legacy implementing of `<Owners>`, expecting Collectionize data and directly linked to `UserCohort`.
 *
 * @deprecated Use `<Owners>` with Data Layer data instead.
 */
export const LegacyOwners = _ref => {
  let {
    showEmail,
    users
  } = _ref;
  return ___EmotionJSX(UserCohort, _extends({}, userCohortDefaultProps, {
    users: users,
    showEmail: showEmail
  }));
};
LegacyOwners.displayName = "LegacyOwners";
/**
 * Newer implementation of `<Owners>`, expecting Data Layer data and linked to `UserCohortAdapter`.
 */
export const Owners = _ref2 => {
  let {
    showEmail,
    users
  } = _ref2;
  return ___EmotionJSX(UserCohortAdapter, _extends({}, userCohortDefaultProps, {
    users: users,
    showEmail: showEmail
  }));
};
Owners.displayName = "Owners";