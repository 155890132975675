function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { UserCohortAdapterPermissionFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { UserCohort } from '@clubhouse/shared/components/UserCohort';
import { assertType } from 'types/utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const UserCohortAdapterPermissionFragment = UserCohortAdapterPermissionFragmentDoc;
export function convertPermissionStateToProfileState(state) {
  // This will throw a compile error when the enum and union are out of sync
  assertType(true);

  // We don't need to do anything here because values defined by the enum are the same as the union,
  // which have been asserted at compile time by the above assertion.
  return state;
}
export function convertProfileStateToPermissionState(state) {
  // This will throw a compile error when the enum and union are out of sync
  assertType(true);

  // We don't need to do anything here because values defined by the enum are the same as the union,
  // which have been asserted at compile time by the above assertion.
  return state;
}

/**
 * This adapter is used to convert the Data Layer's Permission type to a Profile type so
 * we can use `<UserCohort>` with Data Layer.
 */
export function UserCohortAdapter(props) {
  const users = props.users.map(user => {
    return {
      entity_type: 'profile',
      id: user.publicId,
      name: user.displayName,
      mention_name: user.mentionName,
      mentionName: user.mentionName,
      email_address: user.email || undefined,
      display_icon: {
        url: user?.displayIcon?.thumbnailUrl ?? ''
      },
      state: convertPermissionStateToProfileState(user.state)
    };
  });
  return ___EmotionJSX(UserCohort, _extends({}, props, {
    users: users
  }));
}
UserCohortAdapter.displayName = "UserCohortAdapter";