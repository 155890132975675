import HotReloadController from './hotReload';
import Url from '../modules/url';
import { EVENT_SOURCE } from 'pages/write/lib/apollo/segmentContext';
const exports = {};
async function addDocWhenWriteIsLoaded(_ref) {
  let {
    eventSource
  } = _ref;
  const {
    addDoc
  } = await import( /* webpackChunkName: "write" */'pages/write/lib/addDoc');
  addDoc({
    eventSource
  });
}
exports.createDoc = () => {
  const writePath = Url.getSlugPath() + '/write';
  const isWritePage = window.location.pathname.includes(writePath);
  const eventSource = isWritePage ? EVENT_SOURCE.QUICK_ACTION.MAIN : EVENT_SOURCE.QUICK_ACTION.DROPDOWN;
  if (!isWritePage) {
    HotReloadController.loadURL(writePath, 'write');
  }
  addDocWhenWriteIsLoaded({
    numTries: 0,
    eventSource
  });
};
export { exports as default };