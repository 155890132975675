import Logo from '@clubhouse/assets/png/third-party-logos/testlodge_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Testlodge() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.TESTLODGE
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Testlodge"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Testlodge Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Automatically create a Shortcut Story when a test fails in", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.testlodge.com/"
  }, "Testlodge"), "."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://blog.testlodge.com/shortcut-test-management/"
  }, "Learn more"), " on how the Testlodge integration works in Shortcut.")));
}
Testlodge.displayName = "Testlodge";