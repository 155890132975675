import { StoryGroupByEpicFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ButtonText } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TableGroupHeaderLinks } from '@clubhouse/shared/components/Table';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/epics/links';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByEpicFragment = StoryGroupByEpicFragmentFragmentDoc;
export const EpicGroupHeader = _ref => {
  let {
    epic
  } = _ref;
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: 12,
    inline: true,
    align: "center"
  }, ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.Epic
  }, typeof epic?.name === 'string' ? ___EmotionJSX(ButtonText, null, " ", epic.name, " ") : 'No Epic'), typeof epic?.publicId === 'number' && ___EmotionJSX(TableGroupHeaderLinks, {
    entityName: "Epic",
    url: getHref(epic.publicId, true)
  }));
};
EpicGroupHeader.displayName = "EpicGroupHeader";