import { CopyEpicLinkActionFragmentFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { copyToClipboard } from '@clubhouse/shared/utils';
import { getHref } from 'components/gql/epics/links';
import { jsx as ___EmotionJSX } from "@emotion/react";
const CopyEpicLinkActionFragment = CopyEpicLinkActionFragmentFragmentDoc;
export const CopyEpicLinkAction = _ref => {
  let {
    epic
  } = _ref;
  const {
    addToast
  } = useToast();
  return ___EmotionJSX(MoreActions.Item, {
    icon: "Link",
    onClick: async () => {
      const success = await copyToClipboard(getHref(epic.publicId, true));
      if (success) addToast({
        Content: () => ___EmotionJSX(ToastText, null, "Link Copied!"),
        timeout: 2000
      });
    }
  }, "Copy Link");
};
CopyEpicLinkAction.displayName = "CopyEpicLinkAction";