import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TableNameText = _ref => {
  let {
    text = '-'
  } = _ref;
  return ___EmotionJSX(Text, {
    textAlign: "left",
    lineClamp: 2,
    bold: true
  }, ___EmotionJSX(Emojify, null, text === '' ? '-' : text));
};
TableNameText.displayName = "TableNameText";