import EpicsPng from '@clubhouse/assets/png/Epics-illo-light.png';
import { Center } from '@clubhouse/shared/components/Center';
import { EmptyState } from '@clubhouse/shared/components/EmptyState';
import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { CompositeImage } from 'components/shared/CompositeImage';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { useTeamScopeId } from 'components/team-navigation';
import { renderAddNewEpic } from 'utils/addNew';
import { useShowArchivedState } from '../../../components/filters/ShowArchived';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Image = ___EmotionJSX(EmptyStateImage, {
  wrapperWidth: 100
}, ___EmotionJSX(CompositeImage, {
  primaryImage: {
    src: EpicsPng,
    alt: '',
    darkModeOpacity: 1
  }
}));
export function EpicsEmptyState() {
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableCurrentFilters();
  const [isShowingArchived] = useShowArchivedState();
  const {
    value: teamId
  } = useTeamScopeId();
  return ___EmotionJSX(Center, null, ___EmotionJSX(EmptyState, {
    title: `There are no ${isShowingArchived ? 'Archived ' : ''}Epics${hasClearableFilters ? ' matching your filters' : teamId ? ' associated with this team' : ''}.`,
    onClickCTA: hasClearableFilters ? clearFilters : teamId ? () => renderAddNewEpic() : undefined,
    cta: hasClearableFilters ? 'Clear filters' : teamId ? 'Create Epic' : '',
    image: Image,
    body: ""
  }));
}
EpicsEmptyState.displayName = "EpicsEmptyState";