import _JSXStyle from "styled-jsx/style";
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import { List } from 'react-virtualized/dist/es/List';
import { getId, getIsSelected, getValue, recreateOptions } from '@clubhouse/shared/hooks/useOptions';
import { Item as DefaultItem } from './Item';
import { ItemWrapper } from './ItemWrapper';
import { NoItemsFound } from './NoItemsFound';
import { KIND, SIZE } from './enums';
import { jsx as ___EmotionJSX } from "@emotion/react";
const minHeightOfItem = 2;
const useDynamicHeight = _ref => {
  let {
    options
  } = _ref;
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      defaultHeight: 20,
      fixedWidth: true
    });
  }, []);
  const [height, setHeightState] = useState(minHeightOfItem);
  const setHeight = useCallback(() => {
    const newHeight = options.slice(0, 50).reduce((acc, option, index) => {
      const cachedHeight = cache.getHeight(index, 0) || minHeightOfItem;
      return acc + cachedHeight;
    }, 0);
    if (height !== newHeight) {
      setHeightState(newHeight);
    }
  }, [cache, height, options]);
  return {
    height,
    setHeight,
    cache
  };
};
const typeAheadMatch = (text, inputValue) => {
  if (inputValue.length > text.length) {
    return false;
  }
  let offset = 0;
  inputValue = inputValue.toLowerCase();
  text = text.toLowerCase();
  for (let i = 0; i < inputValue.length; i++) {
    const matchOffset = text.substr(offset).indexOf(inputValue[i]);
    if (matchOffset === -1) {
      return false;
    } else {
      offset += matchOffset + 1;
    }
  }
  return true;
};
const isMatch = _ref2 => {
  let {
    item,
    inputValue,
    useTypeAheadMatch = false
  } = _ref2;
  if (!inputValue) {
    return true;
  }
  const {
    text
  } = item;
  if (!text) {
    return false;
  }
  return text.toLowerCase().includes(inputValue.toLowerCase()) || useTypeAheadMatch && typeAheadMatch(text, inputValue);
};
const widths = {
  [SIZE.SMALL]: '200px',
  [SIZE.LARGE]: '240px'
};
const isDisabledClass = 'is-disabled';
const filterUsers = (options, inputValue, useTypeAheadMatch, setFilteredOptions) => {
  const filteredOptions = options.filter(option => {
    if (option.value && option.value.getMatcher) {
      return option.value.getMatcher(inputValue, option);
    }
    return isMatch({
      item: getValue(option),
      inputValue,
      useTypeAheadMatch
    });
  });
  const sortedOptions = [...filteredOptions].sort((a, b) => {
    const {
      text: aText
    } = getValue(a);
    const {
      text: bText
    } = getValue(b);
    if (!aText || !bText) {
      return 0;
    }
    return aText.toLowerCase().indexOf(inputValue.toLowerCase()) - bText.toLowerCase().indexOf(inputValue.toLowerCase());
  });
  setFilteredOptions(sortedOptions);
};
const debouncedFn = debounce(filterUsers, 50);
export const ItemList = _ref3 => {
  let {
    options,
    inputValue,
    highlightedIndex,
    getItemProps = () => ({}),
    getMenuProps = () => ({}),
    size = SIZE.LARGE,
    kind = KIND.DEFAULT,
    useTypeAheadMatch = false,
    isDisabled = false,
    width = widths[size],
    itemReducer
  } = _ref3;
  const [filteredOptions, setFilteredOptionsState] = useState([]);
  const {
    height,
    setHeight,
    cache
  } = useDynamicHeight({
    options: filteredOptions
  });
  const setFilteredOptions = useCallback(options => {
    cache.clearAll();
    if (itemReducer) {
      options = recreateOptions({
        items: itemReducer({
          items: options.map(getValue)
        }),
        previousOptions: options
      });
    }
    setFilteredOptionsState(options);
  }, [cache, itemReducer]);
  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      debouncedFn(options, inputValue, useTypeAheadMatch, setFilteredOptions);
    } else {
      debouncedFn.cancel();
      setFilteredOptions(options);
    }
    return () => {
      debouncedFn.cancel();
    };
  }, [inputValue, options, setFilteredOptions, useTypeAheadMatch]);
  const selectableItemIndexes = filteredOptions.filter(getId).reduce((acc, option, index) => {
    acc[getId(option)] = index;
    return acc;
  }, {});
  const rowRenderer = _ref4 => {
    let {
      index: optionIndex,
      style,
      parent,
      key: rowRendererKey
    } = _ref4;
    const option = filteredOptions[optionIndex];
    const item = getValue(option);
    const id = getId(option);
    const index = selectableItemIndexes[id];
    const isSelected = getIsSelected(option);
    const {
      value,
      component = DefaultItem,
      node,
      text,
      IconLeft,
      key = id,
      textComponent
    } = item;
    const Item = component;
    const isActive = highlightedIndex === index;
    return ___EmotionJSX(CellMeasurer, {
      key: `${key}-${rowRendererKey}`,
      cache: cache,
      columnIndex: 0,
      rowIndex: optionIndex,
      parent: parent
    }, ___EmotionJSX(ItemWrapper, {
      getItemProps,
      isSelected,
      value,
      index,
      item,
      isActive,
      isDisabled,
      style
    }, node ? node : ___EmotionJSX(Item, {
      isSelected,
      isActive,
      IconLeft,
      item,
      kind
    }, textComponent ? textComponent : text)));
  };
  const rowCount = filteredOptions.length;
  return ___EmotionJSX("ul", _extends({
    style: {
      height: rowCount > 0 ? `${height}px` : 'auto',
      minWidth: width
    }
  }, getMenuProps(), {
    className: _JSXStyle.dynamic([["2477605126", [isDisabledClass, SIZE.SMALL, SIZE.LARGE, KIND.AUTOCOMPLETE]]]) + " " + (classNames('item-list', size, kind, {
      [isDisabledClass]: isDisabled
    }) || "")
  }), rowCount > 0 ? ___EmotionJSX(AutoSizer, null, _ref5 => {
    let {
      width,
      height
    } = _ref5;
    return ___EmotionJSX(List, {
      rowHeight: cache.rowHeight,
      height: height,
      width: width,
      rowCount: rowCount,
      deferredMeasurementCache: cache,
      rowRenderer: rowRenderer,
      onRowsRendered: () => setHeight()
    });
  }) : ___EmotionJSX(NoItemsFound, null), ___EmotionJSX(_JSXStyle, {
    id: "2477605126",
    dynamic: [isDisabledClass, SIZE.SMALL, SIZE.LARGE, KIND.AUTOCOMPLETE]
  }, `.item-list.__jsx-style-dynamic-selector{margin:0;padding:8px 0;overflow-y:auto;overflow-x:hidden;}.item-list.${isDisabledClass}.__jsx-style-dynamic-selector{opacity:0.5;}.item-list.${SIZE.SMALL}.__jsx-style-dynamic-selector{max-height:calc(25vh);}.item-list.${SIZE.LARGE}.__jsx-style-dynamic-selector{max-height:calc(50vh);}.item-list.${KIND.AUTOCOMPLETE}.__jsx-style-dynamic-selector{min-width:240px;}`));
};
ItemList.displayName = "ItemList";