import { KIND, Popover } from '@clubhouse/shared/components/Popover';
import { useToggleState } from '@clubhouse/shared/hooks';
import { getDefaultUserEmail } from '@clubhouse/shared/utils/user';
import { getAllLoggedInProfileDetails } from 'data/entity/user';
import { useUserOrgAndApplicationStateFetched } from 'utils/consolidatedFetch';
import { withinOrgAndWithSlug } from 'utils/is';
import { ProfileContent } from './ProfileContent';
import { ProfileTrigger } from './ProfileTrigger';
import { useProfileRenderId } from './profileRenderId';
import { useProfile } from './useProfile';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ProfileComponent = _ref => {
  let {
    profile
  } = _ref;
  useProfileRenderId();
  const [visible, togglePopover] = useToggleState();
  return ___EmotionJSX(Popover, {
    visible: visible,
    onClickOutside: togglePopover.off,
    withArrow: true,
    placement: Popover.PLACEMENT.BOTTOM_RIGHT,
    content: () => ___EmotionJSX(ProfileContent, {
      profile: profile,
      onClose: togglePopover.off
    }),
    kind: KIND.WORKSPACE
  }, ___EmotionJSX(ProfileTrigger, {
    profile: profile,
    visible: visible,
    togglePopover: togglePopover
  }));
};
ProfileComponent.displayName = "ProfileComponent";
const ProfileWithGqlProvider = () => ___EmotionJSX(ProfileComponent, {
  profile: useProfile()
});
ProfileWithGqlProvider.displayName = "ProfileWithGqlProvider";
const mapToExpectedShape = profile => profile ? {
  email: profile.email_address || (getDefaultUserEmail(profile) || {}).email_address || '',
  displayName: profile.name || '',
  id: profile.id || '',
  displayIcon: profile.display_icon
} : null;
const ProfileWithCollectionizeProvider = () => useUserOrgAndApplicationStateFetched() ? ___EmotionJSX(ProfileComponent, {
  profile: mapToExpectedShape(getAllLoggedInProfileDetails())
}) : null;

// TODO: This is a temporary solution until we have schema support for profiles on non-org pages.
export const Profile = () => {
  const hasFetchedUserOrgAndApplicationState = useUserOrgAndApplicationStateFetched();
  if (!hasFetchedUserOrgAndApplicationState) return null;
  return withinOrgAndWithSlug() ? ___EmotionJSX(ProfileWithGqlProvider, null) : ___EmotionJSX(ProfileWithCollectionizeProvider, null);
};