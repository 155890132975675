import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useMilestoneWithFetch } from 'data/entity/milestone';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from './actions';
import { useContextMenuWithLogging, useContextMenuWithLoggingOptionalEvents } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ObjectiveContextMenu = _ref => {
  let {
    id
  } = _ref;
  const {
    milestone: objective,
    loading
  } = useMilestoneWithFetch({
    id
  });
  if (loading) return ___EmotionJSX(ContextMenuLoading, null);
  if (!objective) return null;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getObjectiveHref(id)
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getObjectiveHref(id, {
      includeDomain: true
    }),
    label: "Link"
  }), ___EmotionJSX(CopyContextMenuItem, {
    value: String(id),
    label: "ID"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: objective.global_id,
    entityType: Nouns.Objective,
    loggingContext: {
      typename: 'Objective'
    }
  }));
};
ObjectiveContextMenu.displayName = "ObjectiveContextMenu";
export const useObjectiveContextMenu = objectiveId => {
  const {
    props
  } = useContextMenuWithLogging(() => ___EmotionJSX(ObjectiveContextMenu, {
    id: objectiveId
  }), {
    entityType: 'Objective'
  });
  return props;
};
export const useObjectiveContextMenuWithQuickSearchSessionLogging = (objectiveId, logQuickSearchInteractions) => {
  const {
    props
  } = useContextMenuWithLoggingOptionalEvents(() => ___EmotionJSX(ObjectiveContextMenu, {
    id: objectiveId
  }), {
    entityType: 'Objective'
  }, logQuickSearchInteractions);
  return props;
};