import * as ProjectListTemplate from 'app/client/stories/views/templates/projectList.html';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import AddNewProjectController from '../../../core/js/controllers/addNewProject';
import BulkSelectionModel from '../../../core/js/models/bulkSelection';
import ContextMenuController from '../../../core/js/controllers/contextMenu';
import EpicController from './epic';
import Format from '../../../core/js/modules/format';
import Is from '../../../core/js/modules/is';
import ProjectModel from '../../../core/js/models/project';
import StoriesProjectModel from '../models/storiesProject';
import StoriesStoryController from './storiesStory';
import StoriesView from '../modules/storiesView';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import View from '../../../core/js/modules/view';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
exports.render = () => {
  const projects = ProjectModel.allNotArchivedInActiveTeam();
  const parentElement = $('#project-nav');
  const html = ProjectListTemplate.render({
    projects
  });
  parentElement.find('.project-parent.ui-droppable').droppable('destroy');
  parentElement.html(html);
  exports.initNavDroppable();
  ContextMenuController.refresh();
  return false;
};
exports.initNavDroppable = () => {
  $('#project-nav .project-parent').droppable({
    hoverClass: 'drag-hover',
    drop: function (e, ui) {
      const element = ui.draggable[0];

      // Guard against sortable usage:
      if (!$(element).hasClass('story')) {
        return false;
      }
      const project_id = Utils.data(this, 'id');
      if (BulkSelectionModel.getSelectionCount() > 0) {
        // Bulk editing stories throws an error if a single story is already
        // within that project. TODO: Figure out why this is different to
        // other properties in the endpoint that allow it.
        const selectedStories = BulkSelectionModel.getStoriesForEachSelection();
        selectedStories.forEach(story => {
          if (story.project_id === project_id) {
            BulkSelectionModel.removeFromSelection(story.id);
          }
        });
      }
      StoriesStoryController.updateOneOrManyOnDrop(element, {
        project_id
      });
    },
    tolerance: 'pointer'
  });
};
exports.toggle = function (e, options) {
  options = options || {};
  const project = Utils.getModelFromContext(this);
  if (e.shiftKey || options.force) {
    if (StoriesProjectModel.onlyCurrentProjectActive(project)) {
      return exports.showAllProjects.call(this);
    } else {
      return exports.highlight.call(this);
    }
  }
  $(this).toggleClass('active');
  StoriesProjectModel.toggle(project);
  EpicController.render();
  StoriesView.resetAndDrawStories();
  return false;
};
exports.showAllProjects = () => {
  StoriesProjectModel.showAll();
  EpicController.render();
  StoriesView.resetAndDrawStories();
  return false;
};
exports.hideAllProjects = () => {
  StoriesProjectModel.hideAll();
  EpicController.render();
  StoriesView.resetAndDrawStories();
  return false;
};
exports.highlight = function (project) {
  project = project || Utils.getModelFromContext(this);
  ProjectModel.each(project => {
    project.active = false;
  });
  StoriesProjectModel.toggle(project);
  EpicController.render();
  StoriesView.resetAndDrawStories();
  return false;
};
exports.generalContextMenuItems = () => {
  function addNewProjectFn() {
    // deferring because dropdown closes directly after this call
    _.defer(AddNewProjectController.render);
    return false;
  }
  const secondItem = Is.readOnly(UserModel.getLoggedInUserPermission()) ? {
    name: 'Projects page',
    url: Url.getSlugPath() + '/projects',
    iconLeft: 'fa-link'
  } : {
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "More"
    }))),
    tooltip: 'More actions',
    value: function () {
      ContextMenuController.openMore({
        items: [{
          name: 'Add New Project',
          iconLeft: 'fa-plus',
          value: addNewProjectFn
        }, {
          name: 'Manage Projects',
          url: Url.getSlugPath() + '/projects',
          iconLeft: 'fa-rocket'
        }],
        title: 'Projects',
        target: this,
        width: 180
      });
      return false;
    }
  };
  return [{
    name: 'All',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: 'Show All Projects',
    value: exports.showAllProjects
  }, secondItem];
};
exports.contextMenuItems = function () {
  const project = Utils.getModelFromContext(this);
  const showAll = StoriesProjectModel.onlyCurrentProjectActive(project);
  return [{
    name: showAll ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: showAll ? 'Show all Projects' : 'Show only this Project',
    tooltipShortcut: '(shift-click)',
    value: target => exports.toggle.call(target, project, {
      force: true
    })
  }, {
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Link"
    }))),
    name: 'Project page',
    url: project.url
  }];
};
exports.renderIcon = (icon, size, id) => View.renderComponentDelayed({
  componentKey: 'icon-' + icon + '-' + id,
  component: ___EmotionJSX(SizedIcon, {
    icon: icon,
    size: size
  })
}).html;
exports.projectSidebarTooltip = function () {
  const project = Utils.getModelFromContext(this);
  const name = Format.sanitize(project.name);
  return `<span class="fa fa-square" style="color:${project.color}"></span>&nbsp;&nbsp;${name}`;
};
export { exports as default };