import { Header } from './Header';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StaticHeader = _ref => {
  let {
    children,
    isNavExpanded,
    isActive,
    icon
  } = _ref;
  return ___EmotionJSX(Header, {
    isNavExpanded: isNavExpanded,
    icon: icon,
    isActive: isActive
  }, children);
};
StaticHeader.displayName = "StaticHeader";