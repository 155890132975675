import { StoryGroupByTeamFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { getTint } from '@clubhouse/shared/color';
import { TeamGroupHeader } from 'components/gql/groupBy/TeamGroupHeader';
import { getHref } from 'components/gql/team/utils/links';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByTeamFragment = StoryGroupByTeamFragmentFragmentDoc;
export const BacklogTableTeamGroupHeader = props => {
  if (props.team != null && props.team.name) {
    const colorKey = props.team.colorKey ? getTint(props.team.colorKey) : undefined;
    const teamUrl = getHref(props.team.publicId, true);
    return ___EmotionJSX(TeamGroupHeader, {
      teamName: props.team.name,
      teamUrl: teamUrl,
      colorKey: colorKey,
      avatarUrl: props.team.displayIcon?.thumbnailUrl
    });
  }
  return ___EmotionJSX(TeamGroupHeader, null);
};
BacklogTableTeamGroupHeader.displayName = "BacklogTableTeamGroupHeader";