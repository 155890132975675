import { ContextHookProvider } from '@clubhouse/shared/utils/useContextHook/useContextHook';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { useRenderSpansWithPropChanges } from 'utils/profiler/sentry';
import { BootPage } from './BootPage';
import { ClearCacheAndReload } from './ClearCacheAndReload';
import { SetSearchParamsFromApplicationState } from './SetSearchParamsFromApplicationState';
import { jsx as ___EmotionJSX } from "@emotion/react";
const contextHookProviderValue = {
  useHook: _ref => {
    let {
      ComponentType,
      props
    } = _ref;
    return useRenderSpansWithPropChanges(ComponentType.displayName ?? 'missing-display-name', props);
  }
};
export const GraphQlPage = props => {
  const {
    children,
    pageName,
    applicationFilterStateKey,
    persistedSearchParamKeys,
    TranslateSearchParams,
    renderId
  } = props;
  useRenderSpansWithPropChanges(pageName, props);
  return ___EmotionJSX(ErrorBoundary, {
    pageName: pageName,
    errorNode: ___EmotionJSX(ClearCacheAndReload, null)
  }, ___EmotionJSX(ContextHookProvider, {
    value: contextHookProviderValue
  }, ___EmotionJSX(BootPage, {
    pageName: pageName,
    renderId: renderId
  }, ___EmotionJSX(TranslateSearchParams, null, ___EmotionJSX(SetSearchParamsFromApplicationState, {
    applicationStateKey: applicationFilterStateKey,
    searchParamKeys: persistedSearchParamKeys
  }, children)))));
};
GraphQlPage.displayName = "GraphQlPage";