import { useEffect } from 'react';
import { C3ChartContainer } from './C3ChartContainer';
import { ReportErrorMsg } from './ReportErrorMsg';
import { ReportHeader } from './ReportHeader';
import { mark, permaIdForChart } from '@clubhouse/shared/utils';
import { destroy, saveChart } from 'utils/charts';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportCard = _ref => {
  let {
    chartId,
    chartData,
    errorMsg,
    onChangeFilter,
    renderChart,
    title,
    description,
    chartStyle,
    Explanation,
    Filters = () => null
  } = _ref;
  useEffect(() => {
    if (chartData) {
      renderChart(chartData).then(chartApi => saveChart(chartId, chartApi));
    }
    return () => destroy(chartId);
  }, [chartId, chartData, renderChart]);
  const onChangeReportFilter = () => {
    if (onChangeFilter) onChangeFilter();
    if (chartData) renderChart(chartData).then(chartApi => saveChart(chartId, chartApi));
  };
  return ___EmotionJSX("div", mark(permaIdForChart(chartId)), ___EmotionJSX(ReportHeader, {
    title: title,
    reportExplanation: ___EmotionJSX(Explanation, {
      isEmbedded: false
    }),
    reportFilters: ___EmotionJSX(Filters, {
      onChange: onChangeReportFilter
    }),
    reportDescription: description
  }), ___EmotionJSX("div", {
    id: `${chartId}-metrics`
  }), errorMsg && ___EmotionJSX(ReportErrorMsg, {
    msg: errorMsg
  }), !errorMsg && ___EmotionJSX(C3ChartContainer, {
    customChartStyle: chartStyle,
    chartId: chartId
  }));
};
ReportCard.displayName = "ReportCard";