import "core-js/modules/es.array.push.js";
import SpaceController from 'app/client/stories/js/controllers/space.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const SpaceController = exports;
  [[['Controller', 'Message'], MessageController], [['Controller', 'Space'], SpaceController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html';
import Mousetrap from 'mousetrap';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import ColumnController from './column';
import ContextMenuController from 'app/client/core/js/controllers/contextMenu';
import Dialog from 'app/client/core/js/modules/dialog';
import DropdownController from 'app/client/core/js/controllers/dropdown';
import EpicController from './epic';
import * as Event from 'app/client/core/js/_frontloader/event';
import Format from 'app/client/core/js/modules/format';
import Globals from 'app/client/core/js/_frontloader/globals';
import Iterate from 'app/client/core/js/modules/iterate';
import IterationController from './iteration';
import MessageController from 'app/client/core/js/controllers/message';
import Router from 'app/client/core/js/_frontloader/router';
import SampleWorkspaceController from 'app/client/core/js/controllers/sampleWorkspace';
import SidebarController from './sidebar';
import StoriesColumnModel from '../models/storiesColumn';
import Tests from 'app/client/core/js/modules/tests';
import Url from 'app/client/core/js/modules/url';
import SpaceModel from 'app/client/core/js/models/space';
import FeatureModel from 'app/client/core/js/models/feature';
import { Spaces } from 'components/stories/Spaces/Spaces';
import { SpaceHeader } from 'components/stories/Spaces/SpaceHeader';
import { simpleCompleteTask } from 'utils/quickstart';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { clearSkipFilterStories } from 'data/entity/story';
import View from 'app/client/core/js/modules/view';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import { saveDraft } from 'data/page/stories/spaces';
import { deprecatedGetTeamScopeIdForNonReactAccess, getTeamScopedURL } from 'components/team-navigation';
import { getIcon } from 'data/entity/space';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const NS = '.StoriesSpaceController';
exports.loadByNumber = id => {
  const space = SpaceModel.getVisibleSpaces()[id];
  if (space && !SpaceModel.isActive(space)) {
    SpaceModel.load(space);
    MessageController.info('Space <strong>' + Format.sanitize(space.name) + '</strong> loaded.', {
      id: 'space',
      icon: 'fa-desktop',
      timeout: 2000
    });
  }
};
exports.openHiddenSpaces = function () {
  const teamScopedHiddenSpaces = SpaceModel.getHiddenSpaces().filter(space => space.shared === false);
  const teamScopedSharedSpaces = SpaceModel.getHiddenSpaces().filter(space => space.shared === true);
  const items = [];
  items.push({
    hr: true
  });
  items.push({
    html: '<div class="shared-space-author">Your Hidden Spaces</div>'
  });
  if (teamScopedHiddenSpaces.length > 0) {
    teamScopedHiddenSpaces.forEach(space => {
      const SpaceIcon = getIcon(space);
      const icon = {
        customIconLeft: renderComponentToString(___EmotionJSX("div", {
          style: {
            marginRight: 4
          }
        }, ___EmotionJSX(DeprecatedIconAdapter, {
          width: 16
        }, ___EmotionJSX(SpaceIcon, null))))
      };
      items.push({
        name: Format.sanitize(space.name),
        value: () => {
          SpaceModel.unhide(space);
          simpleCompleteTask('visit-spaces');
        },
        ...icon
      });
    });
  } else {
    items.push({
      html: '<div class="caption"><em>No hidden spaces found.</em></div>'
    });
  }
  if (teamScopedSharedSpaces.length > 0) {
    let lastAuthor = null;
    const sortedScopedSpaced = _.sortBy(teamScopedSharedSpaces, 'author.lowercase_name');
    sortedScopedSpaced.forEach(space => {
      if (!lastAuthor || lastAuthor !== space.author.name) {
        lastAuthor = space.author.name;
        items.push({
          hr: true
        });
        items.push({
          html: '<div class="shared-space-author">Shared by ' + Format.sanitize(space.author.name) + '</div>'
        });
      }
      items.push({
        name: '<div class="shared-space">' + UserAvatarTemplate.render({
          profile: space.author,
          size: 18
        }) + Format.sanitize(space.name) + '</div>',
        value: () => {
          SpaceModel.unhide(space);
          simpleCompleteTask('visit-spaces');
        }
      });
    });
  } else {
    const showHiddenSharedSpacesCopy = teamScopedHiddenSpaces.length > 0;
    if (showHiddenSharedSpacesCopy) {
      items.push({
        html: '<div class="caption"><em>No hidden shared spaces found.</em></div>'
      });
    }
  }
  DropdownController.open({
    items,
    title: 'More Spaces (Click to enable)',
    target: this,
    width: 280
  });
  return false;
};
exports.spaceNotFound = () => {
  // TODO: Why do we need this if statement?
  if (!Dialog.isOpen()) {
    MessageController.alert('<strong>The space you requested was not found.</strong>' + " It's possible that the space was deleted or not shared, or the URL was malformed.");
  }
};
exports.notifyUserAboutSavingTempSpace = space => {
  MessageController.info(`You're looking at a space with a special URL for an individual ${FeatureModel.isProjectsFeatureEnabled() ? 'Project, Epic,' : 'Epic'} or Label. Would you like to save <strong>${Format.sanitize(space.name)}</strong> as your own space?`, {
    actions: '<a href="#" class="action mini elevated" data-controller="Message" ' + 'data-on-click="close">No</a> <a href="#" class="action mini green" data-controller="Space" ' + 'data-on-click="saveTempSpace"><span class="fa fa-check"></span> Save</a>',
    id: 'save-temp-space',
    timeout: 30000
  });
};
exports.saveTempSpace = function () {
  const temp = Globals.get('tempSpace');
  SpaceModel.save({
    name: temp.name
  }, (err, newSpace) => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to save <strong>' + Format.sanitize(temp.name) + '</strong>.'
      });
    }
    if (newSpace) {
      SpaceModel.load(newSpace);
    }
  });
  MessageController.close.call(this);
  return false;
};
exports.init = () => {
  exports.addToRoutes();
  Event.onlyOn('temporarySpaceCreated' + NS, space => {
    clearSkipFilterStories();
    const existing = SpaceModel.find({
      name: space.name
    });
    if (!existing) {
      exports.notifyUserAboutSavingTempSpace(space);
    }
  });
  Event.onlyOn('spaceUpdate' + NS, () => {
    clearSkipFilterStories();
    saveDraft();
  });
  SpaceModel.on('fetched updated removed', _.debounce(async () => {
    if (SpaceModel.isNotBulk()) {
      await SpaceModel.fetchPreferencesAndRecompile();
      ContextMenuController.refresh();
    }
  }, 0));
  SpaceModel.on('fetched', () => {
    if (SampleWorkspaceController.isSampleObserver()) {
      const sharedSpaces = SpaceModel.filter({
        hidden: true,
        shared: true
      });
      Iterate.each(sharedSpaces, space => {
        SpaceModel.update({
          id: space.id,
          hidden: false
        });
      });
    }
  });
  Event.onlyOn('spaceNotFound' + NS, () => {
    exports.spaceNotFound();
  });
  Event.onlyOn('spaceLoaded' + NS, space => {
    clearSkipFilterStories();
    exports.pushState(space);
    SpaceModel.recompilePreferences();
    StoriesColumnModel.resetColumnStoryLimits();
    ColumnController.reset();
    EpicController.render();
    if (FeatureModel.isProjectsFeatureEnabled()) {
      SidebarController.renderProjectsSidebar();
    }
    if (Tests.usesIterations()) {
      IterationController.render();
    }
    SidebarController.renderGroupsSidebar();
    if (areCustomFieldsEnabled()) SidebarController.renderCustomFieldsSidebar();
  });

  // Bind 1-9 as space shortcuts.
  _.times(9, n => {
    Mousetrap.bind(n + 1 + '', function () {
      let e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const hasModifier = e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
      if (!Dialog.isOpen() && !hasModifier) {
        exports.loadByNumber(n);
      }
    });
  });
};
exports.pushState = space => {
  // This could happen if scratch doesn't exist yet.
  if (!space) {
    return;
  }
  AddNewStoryController.renderIfDirectLink();
  const title = space.name + ` | Stories | ${BRAND.NAME}`;
  if (!Dialog.isOpen() && !Url.isStoryURL()) {
    // If this is the first load on the stories page, we want to replace
    // the state to avoid adding a duplicate history entry to the stack.
    const href = getTeamScopedURL(`${window.location.origin}${space.url}`, deprecatedGetTeamScopeIdForNonReactAccess()).href;
    if (Url.getCurrentPathname() === `${Url.getSlugPath()}/stories`) {
      Router.replaceState(href, title);
    } else {
      Router.pushState(href, title);
    }
  }
  Globals.set('initialHref', space.url);
};
exports.renderSpaces = () => {
  return View.renderComponentDelayed({
    componentKey: 'storiesPageSpaces',
    component: ___EmotionJSX(Spaces, null)
  }).html;
};
let hasAddedToRoutes = false;
exports.addToRoutes = () => {
  if (hasAddedToRoutes) {
    return;
  }
  hasAddedToRoutes = true;
  Router.add(url => {
    return url.indexOf('/stories/archived') === -1 && url.indexOf('/stories/') !== -1;
  }, url => {
    const spaceTitle = SpaceModel.getSpaceTitleFromURL(url);
    return spaceTitle + ` | Stories | ${BRAND.NAME}`;
  }, url => {
    const space = SpaceModel.getSpaceFromURL(url);
    if (space && !SpaceModel.isActive(space)) {
      SpaceModel.load(space);
    } else if (!space) {
      exports.spaceNotFound();
    }
  });
};
exports.renderSpaceHeader = () => {
  return View.renderComponentDelayed({
    componentKey: 'spaceHeader',
    component: ___EmotionJSX(SpaceHeader, null)
  }).html;
};
export { exports as default };