import { FilterField } from '@clubhouse/shared/components/FilterField';
import { useNonClearableFilterState } from 'components/gql/filters/hooks/useFilterState';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { GroupBySelect } from 'components/shared/table/GroupBySelect';
import { GROUP_BY, useGroupByOptions } from 'components/shared/table/GroupBySelect/epics';
import { useTeamScopeId } from 'components/team-navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicsGroupByConfig = createSearchParamConfig({
  paramName: 'grouped_by',
  paramType: 'string',
  defaultValue: GROUP_BY.NONE,
  validValues: ['groupId', 'epicState', 'milestone', 'objective']
});
export function EpicsGroupBySelect() {
  const [groupBy, setGroupBy] = useNonClearableFilterState(EpicsGroupByConfig);
  const {
    value: teamId
  } = useTeamScopeId();
  const isTeamScope = Boolean(teamId);
  const groupByOptions = useGroupByOptions(!!isTeamScope);
  return ___EmotionJSX(FilterField, {
    label: "Group By"
  }, ___EmotionJSX(GroupBySelect, {
    items: groupByOptions,
    value: groupBy,
    onChange: setGroupBy
  }));
}
EpicsGroupBySelect.displayName = "EpicsGroupBySelect";