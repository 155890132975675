import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Circle = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH,
    fill
  } = _ref;
  return ___EmotionJSX("svg", {
    fill: fill,
    width: width,
    height: width,
    viewBox: "0 0 100 100"
  }, ___EmotionJSX("circle", {
    clipPath: "circle()",
    r: "50",
    cx: "50",
    cy: "50"
  }));
};
Circle.displayName = "Circle";