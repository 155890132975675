import moment from 'moment';
import { DATE_FORMAT } from '@clubhouse/shared/components/DatePicker';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TextField = _ref => {
  let {
    text
  } = _ref;
  return ___EmotionJSX(Text, {
    textAlign: "left",
    size: Text.SIZE.XSMALL,
    lineClamp: 2
  }, text);
};
TextField.displayName = "TextField";
export const TableDate = _ref2 => {
  let {
    value
  } = _ref2;
  const text = value ? moment(value).format(DATE_FORMAT) : 'No Date';
  return ___EmotionJSX(TextField, {
    text: text
  });
};
TableDate.displayName = "TableDate";