import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllActiveGroups } from 'data/entity/group';
import { getLoggedInUserPermission } from 'data/entity/user';
import { JoinTeamPage } from 'pages/invites/JoinTeamPage';
import { multiInviteSubmit, redirectSuccess } from 'pages/signup/utils/invites';
import { fetchConsolidatedDataBeforeRedirectFromSignup } from 'utils/createOrgOrWorkspaceController';
import { CreateAccountForm } from './CreateAccountForm';
import { EntitlementError, ValidationError, formatInvites } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const InviteSignupForm = _ref => {
  let {
    email,
    name = '',
    isUserGDPR,
    sso,
    is_oauth,
    signupId
  } = _ref;
  const location = useLocation();
  const [step, setStep] = useState('create-account');
  const permission = getLoggedInUserPermission();
  const onCreateAccount = async data => {
    const [inviteIds, workspaceIds] = formatInvites(location.state.selectedValues, location.state.groupedByOrgInvites);
    data.invite_ids = inviteIds;
    data.workspace2_ids = workspaceIds;
    const [resp] = await multiInviteSubmit(data, {
      sso,
      signupId
    });
    if (!resp.success) {
      if (resp.entitlement_tag === 'active-seats-create') {
        throw new EntitlementError();
      } else {
        throw new ValidationError(resp.message);
      }
    } else {
      await fetchConsolidatedDataBeforeRedirectFromSignup();
      if (permission && getAllActiveGroups().length > 0) {
        setStep('join-team');
      } else {
        redirectSuccess();
      }
    }
  };
  return ___EmotionJSX(React.Fragment, null, step === 'create-account' && ___EmotionJSX(CreateAccountForm, {
    onCreateAccount: onCreateAccount,
    email: email,
    name: name,
    isUserGDPR: isUserGDPR,
    is_oauth: is_oauth,
    sso: sso
  }), step === 'join-team' && ___EmotionJSX(JoinTeamPage, {
    permission: permission,
    onContinue: () => redirectSuccess(permission?.id)
  }));
};