import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <ThirdPartyIcon icon="Productboard" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ProductboardLogo = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width
  }, ___EmotionJSX(ThirdPartyIcon, {
    icon: "Productboard"
  }));
};
ProductboardLogo.displayName = "ProductboardLogo";