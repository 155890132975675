import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getIconForStoryType = _ref => {
  let {
    story_type
  } = _ref;
  switch (story_type) {
    case 'feature':
      return _ref2 => {
        let {
          width
        } = _ref2;
        return ___EmotionJSX(DeprecatedIconAdapter, {
          width: width,
          fill: "var(--iconYellowColor)"
        }, ___EmotionJSX(Icon, {
          icon: "Feature"
        }));
      };
    case 'bug':
      return _ref3 => {
        let {
          width
        } = _ref3;
        return ___EmotionJSX(DeprecatedIconAdapter, {
          width: width,
          fill: "var(--iconRedColor)"
        }, ___EmotionJSX(Icon, {
          icon: "Bug"
        }));
      };
    case 'chore':
      return _ref4 => {
        let {
          width
        } = _ref4;
        return ___EmotionJSX(DeprecatedIconAdapter, {
          width: width
        }, ___EmotionJSX(Icon, {
          icon: "Chore",
          fill: "main"
        }));
      };
    default:
      throw new Error('Invalid story type: ' + story_type);
  }
};
export const getIconNameForStoryType = type => type === 'feature' ? 'Feature' : type === 'bug' ? 'Bug' : 'Chore';