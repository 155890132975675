import * as CommentsTemplate from 'app/client/core/views/templates/comments/comments.html';
import * as ParentCommentTemplate from 'app/client/core/views/templates/partialStoryThreadedComments.html';
import * as CommentTemplate from 'app/client/core/views/templates/partialThreadedComment.html';
import * as StoryCommentOrderToggleTemplate from 'app/client/core/views/templates/storyCommentOrderToggle.html';

// Models
import CommentModel from '../models/comment';
import ProfileModel from '../models/profile';
import StoryModel from '../models/story';

// Modules
import Dom from 'app/client/core/js/modules/dom';
import Format from 'app/client/core/js/modules/format';
import Hash from 'app/client/core/js/modules/hash';
import Router from '../_frontloader/router';
import Snapshot from 'app/client/core/js/modules/snapshot';
import Tooltip from 'app/client/core/js/modules/tooltip';
import Utils from 'app/client/core/js/modules/utils';

// Controllers
import MessageController from './message';
import StoryController from './story';

// misc
import { copyToClipboard } from '@clubhouse/shared/utils/copyToClipboard';
import { created } from 'utils/sort';
import View from '../modules/view';
import { StoryCommentEditor } from 'components/stories/StoryCommentEditor';
import { StoryComment } from 'components/stories/StoryComment';
const exports = {};
let commentListMap = new Map([]);
const resetCommentList = function () {
  let newArr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return commentListMap = new Map(newArr);
};
const getCommentElement = _ref => {
  let {
    id
  } = _ref;
  return document.querySelector(`.comment[data-id="${id}"]`);
};
const overrideCommentText = (comment, html) => {
  comment.querySelector('.text').innerHTML = html;
};
const hideCommentReaction = comment => {
  Dom.hide(comment.querySelector('.reactions'));
};

// Cloned from controller/story.copyURLToClipboard
const copyHrefToClipboard = (context, event) => {
  Tooltip.flashNewTooltipMessage(context, event, 'Copied!');
  copyToClipboard(context.getAttribute('href'));
};
exports.handleOnUpdate = function () {
  exports.updateComment.apply(this, arguments);
};
exports.toggleCommentOrder = () => {
  CommentModel.toggleCommentSortOrder();
  const toggle = StoryCommentOrderToggleTemplate.render();
  $('.comment-order-toggle').html(toggle);
  const threadedCommentsElement = document.querySelector('.threaded-comments');
  const commentsContainer = threadedCommentsElement.querySelector('.threaded-comments-container');
  const commentFormContainer = threadedCommentsElement.querySelector('[data-new-comment-form]')?.parentElement;
  if (commentsContainer && commentFormContainer) {
    const sortedCommentElements = [...commentsContainer.children].reverse();
    commentsContainer.replaceChildren();
    commentsContainer.append.apply(commentsContainer, sortedCommentElements);
    threadedCommentsElement.removeChild(commentFormContainer);
    if (CommentModel.commentsAreAscending()) {
      $(commentFormContainer).insertAfter('.threaded-comments-container');
    } else {
      $(commentFormContainer).insertBefore('.threaded-comments-container');
    }

    // TODO: Decouple the Comments order from the Story history order
    const entity = Utils.getModelFromContext(commentsContainer);
    StoryController.reverseStoryHistoryOrder(entity);
  }
  return false;
};
exports.getSortedComments = comments => {
  const sortedComments = comments.sort(created);
  return CommentModel.commentsAreAscending() ? sortedComments.reverse() : sortedComments;
};
let isLoading = false;
exports.renderComments = entity => {
  isLoading = !StoryModel.isFullyLoaded(entity);
  const itsaMe = ProfileModel.getCurrentUserProfileDetails();
  const comments = CommentModel.extractCommentsFromEntity(entity);
  resetCommentList(CommentModel.normalizeComments(comments).map(comment => [comment.id, comment]));

  //filter out deleted comments with no children
  const displayableComments = comments.filter(_ref2 => {
    let {
      id
    } = _ref2;
    const comment = commentListMap.get(id);
    return !CommentModel.isCommentDeleted(comment) || CommentModel.commentHasChildren(comment) && CommentModel.getCommentChildren(comment).some(c => !c.deleted);
  });
  return CommentsTemplate.render({
    entity,
    comments: exports.getSortedComments(displayableComments),
    profile: itsaMe
  });
};
exports.renderMarkdownEditor = (key, storyId, replyingToId) => {
  return View.renderComponentDelayed({
    componentKey: 'commentEditor-' + key + '-' + storyId,
    component: StoryCommentEditor,
    props: {
      id: storyId,
      replyingToId
    }
  }).html;
};
exports.renderStoryCommentThread = (storyId, comment) => {
  return View.renderComponentDelayed({
    componentKey: 'comment-' + comment.id,
    component: StoryComment,
    props: {
      storyId,
      commentId: comment.id
    },
    cssStyles: 'margin-bottom: 10px;'
  }).html;
};
exports.updateComments = entity => {
  if (isLoading) {
    const html = exports.renderComments(entity);
    $('.threaded-comments').html(html);
    isLoading = false;
    return;
  }
  const comments = CommentModel.normalizeComments(CommentModel.extractCommentsFromEntity(entity));
  const newComments = comments.filter(_ref3 => {
    let {
      id
    } = _ref3;
    return !commentListMap.has(id);
  });
  const $container = $('.threaded-comments-container');
  if ($container.length) {
    const comments = entity.comments || [];
    for (const comment of comments) {
      if ($container.find(`[data-component-key="comment-${comment.id}"]`).length === 0 && !comment.parent_id) {
        const html = exports.renderStoryCommentThread(entity.id, comment);
        if (CommentModel.commentsAreAscending()) {
          $container.append(html);
        } else {
          $container.prepend(html);
        }
      }
    }
  }
  const newAttachments = newComments.filter(c => c.entity_type === 'story-file-comment');
  if (newAttachments.length) updateCommentTree(document.querySelector('.threaded-comments-container'), {
    entity,
    comments,
    newComments: newAttachments,
    updatedComments: [],
    removedComments: []
  });
  resetCommentList(CommentModel.normalizeComments(comments).map(comment => [comment.id, comment]));
};
const updateCommentTree = (commentTree, _ref4) => {
  let {
    entity,
    newComments,
    updatedComments,
    removedComments
  } = _ref4;
  const newRootComments = newComments.filter(_ref5 => {
    let {
      parent_id
    } = _ref5;
    return !parent_id;
  });
  const newChildComments = newComments.filter(_ref6 => {
    let {
      parent_id
    } = _ref6;
    return !!parent_id;
  });
  const commentsAreAscending = CommentModel.commentsAreAscending();

  // add new parents
  newRootComments.forEach(comment => {
    const position = commentsAreAscending ? 'beforeend' : 'afterbegin';
    commentTree.insertAdjacentHTML(position, ParentCommentTemplate.render({
      entity,
      comment
    }));
  });

  // add new children
  newChildComments.forEach(comment => {
    // On first load, we must make sure we haven't actually already loaded this comment via caveman
    // Without this check, comments may be duplicated in the DOM.
    const commentElement = commentTree.querySelector(`[data-id="${comment.parent_id}"] > .comment-replies > .comment-replies-wrapper > [data-id="${comment.id}"]`);
    if (!commentElement) {
      commentTree.querySelector(`[data-id="${comment.parent_id}"] > .comment-replies > .comment-replies-wrapper`).insertAdjacentHTML('beforeend', ParentCommentTemplate.render({
        entity,
        comment
      }));
      // Make sure styles show up properly
      commentTree.querySelector(`[data-id="${comment.parent_id}"] > .comment-replies`).classList.add('comment-replies_has-children');
      // Show the thread toggle
      Dom.show(commentTree.querySelector(`[data-id="${comment.parent_id}"] > .comment .comment-meta .toggle-thread`));
    }

    // Update child count
    const childCountElement = commentTree.querySelector(`[data-id="${comment.parent_id}"] > .comment-replies > .comment-replies-count`);
    if (childCountElement) {
      const childCount = entity.comments.find(c => c.id === comment.parent_id)?.comments?.filter(c => !c.deleted).length ?? 0;
      childCountElement.replaceChildren(`${Format.pluralize(childCount, 'comment', 'comments')} hidden`);
    }
  });

  // Update edited comments
  updatedComments.forEach(comment => {
    const commentElement = commentTree.querySelector(`.comment[data-id="${comment.id}"]`);
    if (commentElement) {
      commentElement.replaceChildren();
      commentElement.insertAdjacentHTML('afterbegin', CommentTemplate.render({
        entity,
        comment
      }));
      const snapshot = Snapshot.getSnapshot(entity);
      const key = `edit${comment.id}`;
      if (snapshot && snapshot[key]) {
        $(commentElement).find('> .comment-meta > .actions > .editable-comment-actions > [data-on-click="editComment"]').click();
        $(commentElement).find('textarea').val(snapshot[key]).height(snapshot[key + 'Height']).focus().setSelection(snapshot[key + 'SelectStart'], snapshot[key + 'SelectEnd']);
      }
    }
  });

  // update deleted comments
  removedComments.forEach(id => {
    // The original delete may have already deleted the element locally but a different client might still have it
    const commentElement = commentTree.querySelector(`.comment[data-id="${id}"]`);
    if (commentElement) {
      const comment = commentListMap.get(id);
      if (!CommentModel.commentHasChildren(comment) || CommentModel.getCommentChildren(comment).every(c => c.deleted)) {
        commentElement.remove();
      } else {
        overrideCommentText(commentElement, `<p><span class="ghost">${CommentModel.CONSTANTS.DELETED}</span></p>`);
        hideCommentReaction(commentElement);
        // Check if the edit/delete/reply actions are available, remove them if they are...
        commentElement.querySelector('.editable-comment-actions')?.remove();
        commentElement.querySelector('.add-reaction-link')?.remove();
      }
      if (CommentModel.isCommentRoot(comment)) {
        removeReplyWrapper(commentTree, entity, id);
      } else {
        const parentComment = commentListMap.get(comment.parent_id);
        if (CommentModel.isCommentDeleted(parentComment) && CommentModel.getCommentChildren(parentComment).every(c => c.deleted || c.id === id)) {
          commentTree.querySelector(`.comment[data-id="${comment.parent_id}"]`).remove();
          removeReplyWrapper(commentTree, entity, comment.parent_id);
        } else {
          const childCountElement = commentTree.querySelector(`[data-id="${parentComment.id}"] > .comment-replies > .comment-replies-count`);
          if (childCountElement) {
            const childCount = parentComment.comments.filter(c => !c.deleted && c.id !== id).length ?? 0;
            childCountElement.replaceChildren(`${Format.pluralize(childCount, 'comment', 'comments')} hidden`);
          }
        }
      }
    }
  });
  return true;
};
const removeReplyWrapper = (commentTree, entity, id) => {
  const replyWrapper = commentTree.querySelector(`.comment-wrapper[data-id="${id}"] > .comment-replies > .comment-reply-wrapper`);
  if (replyWrapper) {
    replyWrapper.remove();
  }
};
exports.deleteComment = _ref7 => {
  let {
    target
  } = _ref7;
  const entity = Utils.getModelFromContext(target, 'Story');
  const commentWrapper = target.closest('.comment-wrapper');
  const commentId = Utils.data(commentWrapper, 'id');
  const comment = commentListMap.get(commentId);
  const commentElement = commentWrapper.querySelector('.comment');
  if (window.confirm('Are you sure you want to delete this comment?')) {
    overrideCommentText(commentElement, `<p><span class="ghost">${CommentModel.CONSTANTS.DELETING}</span></p>`);
    CommentModel.deleteComment(entity.id, commentId, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to delete comment.'
        });
        overrideCommentText(commentElement, Format.sanitize(Format.markdownify(comment.text, 'comment-delete-error')));
      } else {
        const snapshot = Snapshot.getSnapshot(entity);
        if (snapshot && snapshot[`edit${commentId}`]) {
          const textarea = $(commentElement).find('.in-place-textarea')[0];
          exports.cancelCommentUpdate.call(textarea);
        }
        MessageController.success('Comment was successfully deleted.');
      }
    });
  }
  return false;
};
const toggleHighlight = (commentWrapper, force) => {
  const highlightClassName = 'highlighted-anchor';
  const commentClasslist = commentWrapper.classList;
  const shouldHighlight = typeof force === 'undefined' ? !commentClasslist.contains(highlightClassName) : force;
  if (shouldHighlight) {
    document.querySelectorAll(`.${highlightClassName}`).forEach(match => match.classList.remove(highlightClassName));
    commentClasslist.add(highlightClassName);
  } else {
    commentClasslist.remove(highlightClassName);
  }
  return shouldHighlight;
};
exports.handleAnchorClick = event => {
  updateAnchorHighlight(event.target.closest('.comment-wrapper'));
  copyHrefToClipboard(event.target, event);
  return false;
};
exports.updateAnchorHighlightForComment = (id, force) => {
  const commentElement = getCommentElement({
    id
  });
  if (!commentElement) {
    return;
  }
  updateAnchorHighlight(commentElement, force);
  // Deferring one second so that images are more likely to load.
  setTimeout(() => {
    document.querySelector(`.comment-wrapper[data-id="${id}"] .comment-meta a`)?.scrollIntoView({
      behavior: 'smooth'
    });
  }, 1000);
};
const updateAnchorHighlight = (commentWrapper, force) => {
  const anchor = commentWrapper?.querySelector('.date a');
  if (!anchor) {
    return;
  }
  const hashString = Hash.extractHashFromRaw(anchor.href.split('#')[1]);

  // We're temporarily disabling the Router to avoid a page reload caused by updating the URL hash.
  Router.disableRouter();
  const newHighlightState = toggleHighlight(commentWrapper, force);
  Hash.set(newHighlightState ? hashString : '');
  Router.enableRouter();
  return false;
};
export { exports as default };