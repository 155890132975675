import { FilterField } from '@clubhouse/shared/components/FilterField';
import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { useSearchParam } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EpicsViewConfig = createSearchParamConfig({
  paramName: 'view',
  paramType: 'string',
  defaultValue: 'table',
  validValues: ['table', 'column']
});
export const useEpicsView = () => useSearchParam(EpicsViewConfig).param;
export function EpicsViewSelector() {
  const {
    param: value,
    setSearchParam: setValue
  } = useSearchParam(EpicsViewConfig);
  return ___EmotionJSX(FilterField, {
    label: "View"
  }, ___EmotionJSX(RadioButtons, {
    selected: value,
    onChange: setValue
  }, ___EmotionJSX(RadioButtons.IconLabel, {
    value: "table",
    tooltip: "Table view",
    icon: "Table"
  }), ___EmotionJSX(RadioButtons.IconLabel, {
    value: "column",
    tooltip: "Column view",
    icon: "Column"
  })));
}
EpicsViewSelector.displayName = "EpicsViewSelector";