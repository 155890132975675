import { useCallback, useState } from 'react';
import { Loading } from '@clubhouse/shared/components/Loading';
import { BILLING_CYCLE_TYPES } from '@clubhouse/shared/types';
import { EntitlementComparisonTable } from 'components/settings/manage-billing/EntitlementComparisonTable';
import { BillingAlerts } from 'components/shared/BillingAlertsNext';
import { getCurrentProduct, getPlanPeriod, useCurrentPlan, usePrices } from 'data/entity/paymentPlan2';
import { isLoggedInUserOwner } from 'data/entity/user';
import { openFeedbackForm } from 'utils/feedback';
import { PLAN_STATUSES, getPlanState } from 'utils/manageBilling/planStateMachine';
import { commitPlanChange } from 'utils/manageBillingController';
import { error as errorToast } from 'utils/message';
import { TIERS, isTier } from 'utils/tiers';
import { PlanSelection } from './Plan2Selection';
import { PlanSummary } from './PlanSummary';
import { UpgradeDialog } from './UpgradeDialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const PaymentPlanTab = () => {
  const {
    paymentPlan: plan
  } = useCurrentPlan();
  const currentProduct = getCurrentProduct(plan);
  const status = getPlanState(plan, currentProduct);
  const [frequency, setFrequency] = useState(BILLING_CYCLE_TYPES.ANNUAL);
  const isOwner = isLoggedInUserOwner();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const {
    loading
  } = usePrices(plan);
  const updateFrequency = useCallback(plan => {
    setFrequency(getPlanPeriod(plan));
  }, []);
  const onPlanChange = async display_key => {
    const isCurrentTier = isTier(currentProduct, display_key);
    const handleUpdate = async _ref => {
      let {
        tier,
        period = null
      } = _ref;
      try {
        const updatedPlan = await commitPlanChange({
          tier,
          plan,
          ...(period ? {
            period
          } : {})
        });
        updateFrequency(updatedPlan);
      } catch (error) {
        errorToast(error, {
          secondary: 'Unable to update plan.'
        });
        throw error;
      }
    };
    if (!isOwner) {
      setShowDialog(true);
      return;
    }
    if (display_key === TIERS.ENTERPRISE) {
      openFeedbackForm({
        subject: 'Upgrade to Enterprise'
      });
    } else if (display_key === TIERS.FREE_LIMITED || isCurrentTier && status !== PLAN_STATUSES.PLAN_TRIALING_AND_NO_SELECTION) {
      await handleUpdate({
        tier: display_key
      });
    } else {
      setSelectedTier(display_key);
      setShowDialog(true);
    }
  };
  const handleDialogClose = updatedPlan => {
    if (updatedPlan) updateFrequency(updatedPlan);
    setShowDialog(false);
    setSelectedTier(null);
  };
  return loading ? ___EmotionJSX(Loading, null) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(PlanSummary, null), ___EmotionJSX(BillingAlerts, null), ___EmotionJSX(UpgradeDialog, {
    key: selectedTier,
    visible: showDialog,
    onClose: handleDialogClose,
    selectedTier: selectedTier,
    showOwners: !isOwner
  }), ___EmotionJSX(PlanSelection, {
    onPlanChange: onPlanChange,
    frequency: frequency,
    allowNonOwnerInteraction: true
  }), ___EmotionJSX(EntitlementComparisonTable, {
    onPlanChange: onPlanChange,
    frequency: frequency,
    allowNonOwnerInteraction: true
  }));
};