function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { Component, Fragment } from 'react';
import * as Log from 'utils/log';
import { useDebounce } from '../hooks/useDebounce';
import { useRefreshKey } from '../hooks/useRefreshKey';
import { ChartErrorState } from './ChartErrorState';
import { jsx as ___EmotionJSX } from "@emotion/react";
class ChartErrorBoundaryInner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static getDerivedStateFromError(error) {
    return {
      error
    };
  }
  componentDidCatch(error, reactErrorInfo) {
    Log.error(error, {
      page: this.props.pageName,
      chart: this.props.chart
    }, {
      react: reactErrorInfo
    });
  }
  render() {
    const {
      error
    } = this.state;
    if (error) {
      return ___EmotionJSX(ChartErrorState, {
        height: this.props.height,
        chartName: this.props.chart,
        onRefreshClick: this.props.onRefreshClick
      });
    }
    return this.props.children;
  }
}
ChartErrorBoundaryInner.displayName = "ChartErrorBoundaryInner";
export const ChartErrorBoundary = props => {
  const [refreshKeys, handleRefresh] = useRefreshKey();
  const debouncedHandleRefresh = useDebounce(() => handleRefresh(props.chartId), 1000);

  // Usage of fragment + nanoid to trigger the component to re-render on clicking the refresh button
  return ___EmotionJSX(Fragment, {
    key: refreshKeys[props.chartId]
  }, ___EmotionJSX(ChartErrorBoundaryInner, _extends({}, props, {
    onRefreshClick: debouncedHandleRefresh
  })));
};
ChartErrorBoundary.displayName = "ChartErrorBoundary";