import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useEffect } from 'react';
import { getCommandBar } from '../getCommandBar';
const listeners = new Map();

/**
 * Adds a callback function to CommandBar.
 * The callback function provided is mapped to callbackKey, and can be attached to commands by referencing callbackKey in a command config.
 */
export function useCommandBarCallback(callbackKey, callbackFn) {
  useEffect(() => {
    const cb = (args, context) => {
      const fns = listeners.get(callbackKey);
      if (!fns) return;
      fns.forEach(fn => fn(args, context));
    };
    if (!listeners.has(callbackKey)) {
      getCommandBar()?.addCallback?.(callbackKey, cb);
      listeners.set(callbackKey, new Set());
    }
    listeners.get(callbackKey)?.add(callbackFn);
    return () => {
      const fns = listeners.get(callbackKey);
      if (!fns) return;
      fns.delete(callbackFn);
      if (fns.size === 0) {
        getCommandBar()?.removeCallback?.(callbackKey);
        listeners.delete(callbackKey);
      }
    };
  }, [callbackFn, callbackKey]);
}