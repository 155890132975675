import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as ___EmotionJSX } from "@emotion/react";
const InternalAutoLinkContext = createContext({
  isArchived: false
});
export const InternalAutoLinkContextProvider = _ref => {
  let {
    children,
    isArchived
  } = _ref;
  return ___EmotionJSX(InternalAutoLinkContext.Provider, {
    value: {
      isArchived
    }
  }, children);
};
InternalAutoLinkContextProvider.displayName = "InternalAutoLinkContextProvider";
export const useIsArchived = () => useContextSelector(InternalAutoLinkContext, _ref2 => {
  let {
    isArchived
  } = _ref2;
  return isArchived;
});