import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ConfirmDeleteDialog = _ref => {
  let {
    onConfirm,
    onCancel,
    isVisible
  } = _ref;
  return ___EmotionJSX(Dialog, {
    ActionComponent: () => ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Button, {
      kind: Button.KIND.SECONDARY,
      onClick: onCancel
    }, "Cancel"), ___EmotionJSX(Button, {
      kind: Button.KIND.WARNING,
      onClick: onConfirm
    }, "Delete")),
    onClose: onCancel,
    visible: isVisible
  }, ___EmotionJSX(DialogTitle, null, "Are you sure?"), ___EmotionJSX(DialogText, null, "Do you want delete this Key Result? This action cannot be undone."));
};
ConfirmDeleteDialog.displayName = "ConfirmDeleteDialog";