import { StoryGroupByWorkflowStateFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { WorkflowStateWithIcon } from 'components/gql/workflowState/WorkflowStateWithIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
StoryGroupByWorkflowStateFragmentFragmentDoc;
export const WorkflowStateGroupHeader = _ref => {
  let {
    workflowState,
    shouldAppendWorkflowName = false
  } = _ref;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.State
  }, typeof workflowState?.type === 'string' && typeof workflowState?.name === 'string' ? ___EmotionJSX(WorkflowStateWithIcon, {
    spacing: 12
    // If applicable (duplicate group names), append the workflow name to the state name.
    ,
    name: shouldAppendWorkflowName ? `${workflowState.name} (${workflowState.workflow.name})` : workflowState.name,
    type: workflowState.type
  }) : 'No State');
};
WorkflowStateGroupHeader.displayName = "WorkflowStateGroupHeader";