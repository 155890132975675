import IntegrationsDialogHeaderImg from '@clubhouse/assets/png/integrations-dialog-header.png';
import { isAProfitablePlan, useCurrentPlan } from 'data/entity/paymentPlan2';
import { getIntegrationsForPlan } from 'utils/integrations';
import { FreeIntegrations } from './FreeIntegrations';
import { PaidIntegrations } from './PaidIntegrations';
import { jsx as ___EmotionJSX } from "@emotion/react";
const MARKETING_INTEGRATIONS_URL = `https://${BRAND.DOMAIN_CORP_WEBSITE}/integrations`;
export const IntegrationSettings = () => {
  const {
    paymentPlan
  } = useCurrentPlan();
  const isProfitable = isAProfitablePlan(paymentPlan);
  const integrations = getIntegrationsForPlan();
  return ___EmotionJSX("div", {
    id: "integration-settings"
  }, ___EmotionJSX("div", {
    className: "dialog-header"
  }, ___EmotionJSX("img", {
    src: IntegrationsDialogHeaderImg,
    alt: "Shortcut Integrations",
    style: {
      position: 'absolute',
      top: 0,
      right: '36px',
      width: '500px'
    }
  }), ___EmotionJSX("h2", null, "Integrations")), isProfitable ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "blurb"
  }, " Connect Shortcut with your favorite apps and tools."), ___EmotionJSX(PaidIntegrations, {
    integrations: integrations
  })) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "blurb"
  }, "Check out our full list of integrations ", ___EmotionJSX("a", {
    href: MARKETING_INTEGRATIONS_URL
  }, "here"), "."), ___EmotionJSX(FreeIntegrations, {
    integrations: integrations
  })));
};
IntegrationSettings.displayName = "IntegrationSettings";