import { useInit } from 'utils/boot';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function BootPage(_ref) {
  let {
    children,
    pageName,
    renderId
  } = _ref;
  useInit({
    page: pageName,
    pageTitle: pageName,
    renderId
  }); // TODO: sc-183047 extract this into a separate boot sequence

  return ___EmotionJSX(React.Fragment, null, children);
}