import Logo from '@clubhouse/assets/png/third-party-logos/bugsee_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Bugsee() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.BUGSEE
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Bugsee"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Bugsee Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.bugsee.com/"
  }, "Bugsee"), " provides bug and crash reports for your applications."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_BUGSEE
  }, "our instructions"), " for how to configure this integration in your Bugsee account.")));
}
Bugsee.displayName = "Bugsee";