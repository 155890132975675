function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { useTableBulkSelection } from 'components/gql/bulkSelect/hooks/useTableBulkSelection';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { CreateBacklogStoryTableAction } from './CreateBacklogStoryTableAction';
import { StoryRow, StoryRowForInfiniteScroll } from './StoryRow';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BacklogTable = _ref => {
  let {
    isLoading,
    isStale,
    stories,
    columns,
    sortColumn,
    sortDirection,
    onSort,
    onAddStory,
    footer,
    infiniteScroll
  } = _ref;
  const [, getIsRowSelected] = useTableBulkSelection();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(StatelessTable, _extends({
    allItems: stories,
    items: stories,
    columns: columns,
    fixedTopRow: ___EmotionJSX(CreateBacklogStoryTableAction, {
      onClick: onAddStory
    }),
    getIsRowSelected: getIsRowSelected,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    onSort: onSort,
    RowComponent: infiniteScroll ? StoryRowForInfiniteScroll : StoryRow,
    footer: footer
  }, isLoading && LOADING_STATE)), isStale && ___EmotionJSX(StaleLoading, null));
};