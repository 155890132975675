import "core-js/modules/es.array.push.js";
import { Icon } from '@useshortcut/shapes-ds';
import moment from 'moment';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { capitalize } from '@clubhouse/shared/utils';
import OrganizationProfileModel from 'app/client/core/js/models/organizationProfile'; // eslint-disable-line import/no-restricted-paths
import PermissionModel from 'app/client/core/js/models/permission'; // eslint-disable-line import/no-restricted-paths
import { DisabledOrgReasons } from 'components/organizations/DisabledOrgReasons';
import { getFromOrg } from 'data/entity/company';
import { getOrgProfileForLoggedInUser, isOrgDisabled, useOrganization } from 'data/entity/organization';
import { getDefaultEmailAddress, getDefaultPermissionFromProfile, getEmailAddressById, getLoggedInUserPermission } from 'data/entity/user';
import { start as startExport } from 'utils/exportData';
import { openHelp } from 'utils/help';
import { generatePathWithSlug } from 'utils/navigation';
import { DragHandle } from '../shared/DragHandle';
import { jsx as ___EmotionJSX } from "@emotion/react";
const openHelpSidebar = e => {
  e.preventDefault();
  openHelp();
};
export function Workspace(_ref) {
  let {
    id
  } = _ref;
  const {
    org
  } = useOrganization(id);
  const profile = getOrgProfileForLoggedInUser(id);
  const permission = getLoggedInUserPermission(id);
  const isDisabled = isOrgDisabled(id);
  const isDefault = profile.default && permission.default;
  const isUserDisabled = permission.disabled;
  const email = getEmailAddressById(profile.email_id) ?? getDefaultEmailAddress();
  const {
    addToast
  } = useToast();
  const onExportStories = e => {
    e.preventDefault();
    startExport({
      workspace2_id: org.id
    }, {
      company_id: getFromOrg(org),
      organization_id: org.id
    });
  };
  const onMakeDefault = async () => {
    const orgProfile = OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id);
    const fns = [];
    if (OrganizationProfileModel.getDefault()?.id !== orgProfile.id) {
      fns.push(cb => OrganizationProfileModel.setDefault(org.id, cb));
    }
    if (getDefaultPermissionFromProfile(orgProfile)?.id !== permission.id) {
      fns.push(cb => PermissionModel.setDefault(permission, cb));
    }
    if (fns.length) {
      try {
        await Promise.all(fns.map(fn => new Promise((resolve, reject) => {
          fn(err => {
            if (err) reject(err);else resolve();
          });
        })));
      } catch {
        addToast({
          kind: 'warning',
          timeout: 5000,
          Content: () => ___EmotionJSX(ToastText, null, "Unable to set default workspace.")
        });
      }
    }
  };
  return ___EmotionJSX("div", {
    className: `org ${isDisabled || isUserDisabled ? 'disabled' : ''}`
  }, ___EmotionJSX("div", {
    className: "title permission"
  }, isDisabled || isUserDisabled ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h3", null, org.name), profile.company.locked_out ? ___EmotionJSX("span", {
    className: "tagged disabled"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 12
  }, ___EmotionJSX(Icon, {
    icon: "Lock"
  })), ' ', "Locked")) : isDisabled ? ___EmotionJSX("span", {
    className: "tagged disabled"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 12
  }, ___EmotionJSX(Icon, {
    icon: "Lock"
  })), ' ', "Disabled")) : isUserDisabled ? ___EmotionJSX("span", {
    className: "tagged disabled"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 12
  }, ___EmotionJSX(Icon, {
    icon: "Lock"
  })), ' ', "User Disabled")) : null) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h3", null, ___EmotionJSX("a", {
    href: `/${org.url_slug}/`
  }, org.name)), ___EmotionJSX("span", {
    className: "tagged blue"
  }, "Active"), isDefault ? ___EmotionJSX("span", {
    className: "tagged orange"
  }, "Default") : ___EmotionJSX("button", {
    className: "action micro flat-white default-action",
    onClick: onMakeDefault
  }, "Set as default"), ___EmotionJSX("span", {
    className: "fa fa-star fa-spin spinner"
  }), ___EmotionJSX(DragHandle, {
    isWorkspace: true
  }), ___EmotionJSX("div", {
    className: "right-panel"
  }, ___EmotionJSX("a", {
    href: "#",
    onClick: onExportStories,
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "currentColor",
    width: 16
  }, ___EmotionJSX(Icon, {
    icon: "Export"
  })), ' ', "Export all Stories as CSV")))), ___EmotionJSX("div", {
    className: "details"
  }, ___EmotionJSX("ul", {
    className: "info"
  }, ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "URL:"), ' ', ___EmotionJSX("a", {
    href: generatePathWithSlug('/:slug/', {
      slug: org.url_slug
    }),
    className: "url-slug"
  }, "https://", BRAND.DOMAIN_APP_WEBSITE, "/", org.url_slug)), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Your Role:"), " ", capitalize(permission.role)), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Primary Email:"), " ", email), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Workspace Created:"), " ", moment(org.created_at).format(DATE_FORMAT.SHORT_DATE))), profile.company.locked_out ? ___EmotionJSX("ul", {
    className: "reasons"
  }, ___EmotionJSX("li", null, "This workspace has been locked. Please", ' ', ___EmotionJSX("a", {
    href: "#",
    onClick: openHelpSidebar
  }, "contact us"), ' ', "if you want to regain access.")) : isDisabled ? ___EmotionJSX("ul", {
    className: "reasons"
  }, ___EmotionJSX(DisabledOrgReasons, {
    orgId: id
  })) : isUserDisabled ? ___EmotionJSX("ul", {
    className: "reasons"
  }, ___EmotionJSX("li", null, "You have been disabled in this workspace. To be re-enabled, please contact one of the admins on this account.")) : null));
}
Workspace.displayName = "Workspace";