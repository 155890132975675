import { useMemo } from 'react';
import { useLatestRef } from '@clubhouse/shared/hooks';
import { BulkSelectContext, createState } from './bulkSelectContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function BulkSelectProvider(_ref) {
  let {
    items,
    children
  } = _ref;
  const itemsRef = useLatestRef(items);
  return ___EmotionJSX(BulkSelectContext.Provider, {
    value: useMemo(() => createState(itemsRef), [itemsRef])
  }, children);
}
BulkSelectProvider.displayName = "BulkSelectProvider";