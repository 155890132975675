import { Children } from 'react';
import { hasProps } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const useSlot = options => {
  const children = Children.toArray(options.children);
  const child = children.filter(hasProps).find(child => child.props.name === options.name);
  if (child) {
    return () => ___EmotionJSX(React.Fragment, null, child.props.children);
  }
  if (options.fallback) {
    return () => ___EmotionJSX(React.Fragment, null, options.fallback);
  }
  throw new Error(`Slot ${options.name} not found`);
};