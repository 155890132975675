import { useContext } from 'react';
import { LaunchDarklyContext } from './LaunchDarklyProvider';
export { FEATURE_TOGGLES } from '@clubhouse/feature-toggles';
export const useVariation = (toggleName, defaultValue) => {
  const {
    isBootstrapped,
    getVariation
  } = useContext(LaunchDarklyContext);
  return {
    /** @deprecated Not needed since the app doesn't render until after loading is completed. */
    loading: !isBootstrapped,
    value: isBootstrapped ? getVariation(toggleName, defaultValue) : undefined
  };
};