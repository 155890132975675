import { PlanFrequencySelection } from 'components/settings/manage-billing/PlanFrequencySelection';
import { PlanSummary } from 'components/settings/manage-billing/PlanSummary';
import { getCurrentProduct, useCurrentPlan, usePlanStatuses } from 'data/entity/paymentPlan2';
import { TIERS, isTier } from 'utils/tiers';
import { jsx as ___EmotionJSX } from "@emotion/react";
const shouldShowFrequencySelection = (plan, trialInfo) => {
  const currentProduct = getCurrentProduct(plan);
  const isTrialing = !!trialInfo;
  const hasSelectedPlan = !isTrialing || trialInfo.has_selected_tier;
  const isFreeLimited = isTier(currentProduct, TIERS.FREE_LIMITED);
  return hasSelectedPlan && !isFreeLimited;
};
export const PlanOverview = () => {
  const {
    paymentPlan: plan
  } = useCurrentPlan();
  const {
    planTrialInfo
  } = usePlanStatuses(plan);
  return [___EmotionJSX(PlanSummary, {
    key: "0"
  }), ...(shouldShowFrequencySelection(plan, planTrialInfo) ? [___EmotionJSX(PlanFrequencySelection, {
    key: "1"
  })] : [])];
};