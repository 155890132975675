import "core-js/modules/es.array.push.js";
import EventModel from 'app/client/core/js/models/event.js';
import EventDefinitionModel from 'app/client/core/js/models/eventDefinition.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Event'], EventModel], [['Model', 'EventDefinition'], EventDefinitionModel], [['Model', 'Event'], EventModel], [['Model', 'EventDefinition'], EventDefinitionModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Chip } from '@clubhouse/shared/components/Chip';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast } from '@clubhouse/shared/components/Toast';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { deleteEventDefinition, updateWorkflowState, useDefinitionsForType } from 'data/entity/eventDefinition';
import { WorkflowSelect } from './WorkflowSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function EventDefinitionsTable(_ref2) {
  let {
    type
  } = _ref2;
  const definitions = useDefinitionsForType(type);
  if (!definitions?.length) return null;
  return ___EmotionJSX("table", {
    className: "table",
    cellPadding: 0,
    cellSpacing: 0
  }, ___EmotionJSX("thead", null, ___EmotionJSX("tr", null, ___EmotionJSX("th", null, "When this happens:"), ___EmotionJSX("th", null, "Move linked story to:"), ___EmotionJSX("th", null, "\xA0"))), ___EmotionJSX("tbody", null, definitions.map(d => ___EmotionJSX("tr", {
    key: d.id,
    "data-model": "EventDefinition",
    "data-id": d.id
  }, ___EmotionJSX("td", null, ___EmotionJSX(Chip, {
    kind: "blue"
  }, d.name), !!d.match_name && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("span", {
    style: {
      fontSize: 14
    }
  }, d.match_type === 'branch' ? ' to ' : ' with '), ___EmotionJSX(Chip, {
    kind: "default"
  }, d.match_name))), ___EmotionJSX("td", null, ___EmotionJSX(WorkflowSelect, {
    selectedId: d.workflow_state_id,
    onChange: async id => {
      try {
        await updateWorkflowState(d, id);
        addToast({
          kind: 'success',
          timeout: 5_000,
          Content: () => ___EmotionJSX(ToastText, null, "Event handler updated.")
        });
      } catch (err) {
        addToast({
          kind: 'alert',
          timeout: 10_000,
          Content: () => ___EmotionJSX(Spaced, {
            vertically: true,
            amount: "space2"
          }, ___EmotionJSX(ToastTitle, null, "Failed to update event handler"), ___EmotionJSX(ToastText, null, err))
        });
      }
    }
  })), ___EmotionJSX("td", null, ___EmotionJSX(Tooltip, {
    content: "Delete Event"
  }, ___EmotionJSX(UnstyledButton, {
    onClick: () => {
      if (window.confirm('Are you sure you want to delete this event handler?')) {
        deleteEventDefinition(d).then(() => {
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => ___EmotionJSX(ToastText, null, "Event handler deleted.")
          });
        }).catch(err => {
          addToast({
            kind: 'alert',
            timeout: 10000,
            Content: () => ___EmotionJSX(ToastText, null, err)
          });
        });
      }
    }
  }, ___EmotionJSX(SizedIcon, {
    icon: "Trash",
    size: 14
  }))))))));
}
EventDefinitionsTable.displayName = "EventDefinitionsTable";