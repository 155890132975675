import { DockedDocFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { isClickedWithModifier } from '@clubhouse/shared/utils/mouse';
import { getHistory } from 'pages/write/lib/history';
import { useDocContextMenu } from 'utils/contextMenus/useDocContextMenu';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage } from 'utils/navigation';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getHref = id => generatePathWithSlug(getPathForPage(PAGE_NAMES.DOC), {
  docId: id
});
const DockedDocFragment = DockedDocFragmentDoc;
export const isDoc = node => {
  return node?.__typename === 'Doc';
};
export function DockedDoc(_ref) {
  let {
    node,
    index,
    onUnpinClicked
  } = _ref;
  const url = getHref(node.urlId);
  const changeWriteUrl = e => {
    if (isClickedWithModifier(e)) return;

    // Manually override the URL only if no modifier keys are pressed.
    getHistory()?.push(`/${node.urlId}`);
  };
  const contextMenuProps = useDocContextMenu(node.id, node.urlId);
  return ___EmotionJSX(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Document",
      fill: "interactive",
      size: 14
    }),
    title: ___EmotionJSX(Title, {
      onClick: changeWriteUrl,
      url: url
    }, node.title || 'Untitled'),
    contextMenuProps: contextMenuProps
  });
}
DockedDoc.displayName = "DockedDoc";