import moment from 'moment';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { getCurrentUser, getDefaultEmailAddress } from 'data/entity/user';
import { openProfileSettings } from 'utils/profile';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function YourProfile() {
  const currentUser = getCurrentUser();
  if (!currentUser) return null;
  return ___EmotionJSX("section", {
    className: "user-profile"
  }, ___EmotionJSX("div", {
    className: "header"
  }, ___EmotionJSX("h2", null, "Your Profile")), ___EmotionJSX("div", {
    className: "details"
  }, ___EmotionJSX("a", {
    href: "/organizations/settings/account",
    onClick: e => {
      e.preventDefault();
      openProfileSettings();
    }
  }, ___EmotionJSX(Avatar, {
    profile: currentUser,
    size: Avatar.SIZE.XXL
  })), ___EmotionJSX("div", {
    className: "info-card no-image"
  }, ___EmotionJSX("div", {
    className: "title"
  }, currentUser.name), ___EmotionJSX("div", {
    className: "subtitle"
  }, getDefaultEmailAddress(currentUser)), ___EmotionJSX("div", {
    className: "info-item"
  }, "Joined on ", moment(currentUser.created_at).format(DATE_FORMAT.SHORT_DATE))), ___EmotionJSX("a", {
    href: "/organizations/settings/account",
    className: "action micro flat-white",
    onClick: e => {
      e.preventDefault();
      openProfileSettings();
    }
  }, "Edit your profile")));
}
YourProfile.displayName = "YourProfile";