import "core-js/modules/es.array.push.js";
import User from 'app/client/core/js/modules/user.js';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['User'], User], [['Controller', 'InviteUsers'], InviteUsersController], [['Controller', 'InviteUsers'], InviteUsersController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import CardModel from '../../../core/js/models/card';
import Constants from '../../../core/js/modules/constants';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import Log from '../../../core/js/modules/log';
import ManageBillingController from './manageBilling';
import PaymentPlanController from '../../../core/js/controllers/paymentPlan';
import Organization from 'app/client/core/js/models/organization';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { EVENTS, logEvent } from 'utils/monitoring';
import { TIERS_DISPLAY_KEYS_MAP } from 'utils/tiers';
import { PaidSeatsRemaining } from '../../../../../components/shared/PaidSeatsRemaining';
import { CardStatuses } from 'components/settings/manage-billing/CardStatuses';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const PARENT_SELECTOR = '#manage-card';
const BUTTON_SELECTOR = '#update-card';
exports.open = () => {
  if ($(SettingsPage.PARENT_SELECTOR).length > 0) {
    ManageBillingController.openPane(() => {
      $('#manage-card-link').click();
    });
  } else {
    ManageBillingController.open();
  }
  return false;
};
exports.initValidation = () => {
  ManageBillingController.destroyValidation();
  Validator.init(PARENT_SELECTOR);
  $('.validate-errors').remove();
  $('#cc-cardholder').focus();
};
const getFlashMessage = _ref2 => {
  let {
    plan,
    noExistingCard
  } = _ref2;
  if (PaymentPlan2Model.isTrialing(plan)) {
    return `Thanks for entering a Credit Card! Get the most out of ${BRAND.NAME} by <a href="#" data-controller="InviteUsers" data-on-click="open">inviting your team members</a>.`;
  }
  return 'Credit card ' + (noExistingCard ? 'added' : 'updated') + '!';
};
exports.updateCardFromData = (data, couponCode) => {
  const currentOrg = Organization.getCurrent();
  const orgHasCardBeforeUpdate = CardModel.organizationHasCard(currentOrg);
  return new Promise((resolve, reject) => {
    CardModel.createCard(data, err => {
      if (err) {
        reject(err);
      } else {
        PaymentPlanController.updateBanner();
        if (!orgHasCardBeforeUpdate) {
          const plan = PaymentPlan2Model.getPlanForOrg(currentOrg.id);
          const product = PaymentPlan2Model.getCurrentProduct(plan);
          const price = PaymentPlan2Model.getPlanPriceTotal({
            prices: plan.prices,
            tier: product?.display_key || TIERS_DISPLAY_KEYS_MAP[plan.current_product_id],
            frequency: plan.subscription_period
          });
          Log.log('Card Added', {
            MRR: price
          });
        }
        logEvent(EVENTS.Credit_card_added, {
          category: 'billing'
        });
        Event.trigger('newCardCreated');
        resolve();
      }
    }, couponCode);
  });
};
exports.updateCard = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  const oldButtonHTML = $(BUTTON_SELECTOR).html();
  const noExistingCard = !CardModel.getCardForOrganization();
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    data.exp_month = $('#exp-month option:selected').attr('name');
    exports.updateCardFromData(data).then(() => {
      exports.open();
      const plan = PaymentPlan2Model.getPlanForCurrentOrg();
      FlashController.success(PARENT_SELECTOR, getFlashMessage({
        plan,
        noExistingCard
      }));
    }).catch(err => {
      View.revertButtonState(BUTTON_SELECTOR, oldButtonHTML);
      FlashController.error(PARENT_SELECTOR, 'We were unable to update your card.', err);
    });
  }
  return false;
};
exports.updateCardType = function () {
  const number = $(this).val();
  const type = CardModel.detectCardType(number);
  const icon = Constants.CARD_ICONS[type] || 'fa-credit-card';
  $(this).closest('.card-number').find('.card-icon').removeClass('fa-cc-visa fa-cc-mastercard fa-cc-amex fa-cc-discover fa-cc-jcb fa-credit-card').addClass(icon);
};
exports.renderPaidSeatsRemaining = () => {
  return View.renderComponentDelayed({
    componentKey: 'billing-card-paid-seats-remaining',
    component: ___EmotionJSX(PaidSeatsRemaining, null)
  }).html;
};
exports.renderCardStatuses = _ref3 => {
  let {
    card,
    paymentPlan2,
    org
  } = _ref3;
  return View.renderComponentDelayed({
    componentKey: 'billing-card-statuses',
    component: CardStatuses,
    props: {
      card,
      paymentPlan: paymentPlan2,
      organization: org
    }
  }).html;
};
export { exports as default };