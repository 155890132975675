import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Swatch } from 'components/shared/Swatch';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getSection = (_, options) => ({
  items: options.map(_ref => {
    let {
      node: {
        id,
        name,
        color
      }
    } = _ref;
    return {
      value: id,
      name: ___EmotionJSX(Emojify, null, name),
      Icon: () => ___EmotionJSX(Swatch, {
        backgroundColor: color
      })
    };
  })
});