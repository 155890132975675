import Logo from '@clubhouse/assets/png/third-party-logos/youtube-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function YouTube() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.YOUTUBE
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "YouTube"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "YouTube Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Users can paste videos from ", ___EmotionJSX(ExternalLink, {
    href: "https://www.youtube.com"
  }, "YouTube"), " to see real-time videos in markdown editors, including comments. The YouTube Integration requires no set up - it should work out of the box with any shareable link from YouTube."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_YOUTUBE
  }, "Learn more"), " on how YouTube embeds work in Shortcut.")));
}
YouTube.displayName = "YouTube";