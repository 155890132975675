import { SelectOptionChecked, SelectOptionDivider } from '@clubhouse/shared/components/Select';
import { NONE_VALUE, SOME_VALUE } from '@clubhouse/shared/constants';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const All = type => ({
  selectedValues: [],
  key: 'all',
  name: ___EmotionJSX("span", null, "All ", type.plural)
});
export const Nothing = () => ({
  key: 'nothing',
  selectedValues: [],
  name: ___EmotionJSX("span", null, "None")
});
export const Any = type => ({
  value: SOME_VALUE,
  name: `Any ${type.singular}`,
  Component: SelectOptionChecked
});
export const None = type => ({
  value: NONE_VALUE,
  name: `No ${type.singular}`,
  Component: SelectOptionChecked
});
export const Divider = name => ({
  key: `divider-${name}`,
  Component: SelectOptionDivider
});