import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
/**
 * EstimateChip
 *
 * Do not use if estimate scale is disabled.
 */
export const EstimateChip = _ref => {
  let {
    estimate
  } = _ref;
  if (typeof estimate !== 'number') return null;
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(ChipTooltip.Text, null, "This Story has an estimate of", ' ', pluralize({
      count: estimate,
      singular: 'point',
      plural: 'points'
    }), ".")
  }, ___EmotionJSX(Chip, null, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(Chip.Icon, {
    icon: "Estimate",
    label: "Estimate"
  })), String(estimate)));
};
EstimateChip.displayName = "EstimateChip";