import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TooltipContent = _ref => {
  let {
    blockingCount,
    hasBlockerLabel
  } = _ref;
  let text = 'This Story ';
  if (blockingCount) {
    text += 'is blocking ' + pluralize({
      count: blockingCount,
      singular: 'Story',
      plural: 'Stories'
    });
  }
  if (hasBlockerLabel) {
    text += `${blockingCount ? ', and ' : ' '}has the "blocker" label`;
  }
  text += '.';
  return ___EmotionJSX(ChipTooltip.Text, null, text);
};
TooltipContent.displayName = "TooltipContent";
export const BlockerChip = _ref2 => {
  let {
    blockingCount,
    hasBlockerLabel
  } = _ref2;
  if (!blockingCount && !hasBlockerLabel) return null;
  const blockerChip = ___EmotionJSX(Chip, {
    kind: "yellow"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(Chip.Icon, {
    icon: "Warn",
    label: "Blocker"
  })), blockingCount || null);
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(TooltipContent, {
      blockingCount: blockingCount,
      hasBlockerLabel: hasBlockerLabel
    })
  }, blockerChip);
};
BlockerChip.displayName = "BlockerChip";