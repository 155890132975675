import { StateIcon } from '@clubhouse/shared/components/StateIcon';
import { renderComponentToString } from '../helpers';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const formatWorkflowState = workflowState => ({
  ...workflowState,
  icon: renderComponentToString(___EmotionJSX(StateIcon, {
    type: workflowState.type
  }))
});
export const formatWorkflowStates = workflowStates => {
  return workflowStates.sort((a, b) => a.position - b.position).map(formatWorkflowState);
};