import * as IterationListTemplate from 'app/client/stories/views/templates/iterationList.html';
import * as IterationListItemsTemplate from 'app/client/stories/views/templates/iterationListItems.html';
import * as ToggleMoreIterationsTemplate from 'app/client/stories/views/templates/toggleMoreIterations.html';
import { Icon, StatusIcon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import ContextMenuController from '../../../core/js/controllers/contextMenu';
import * as Event from '../../../core/js/_frontloader/event';
import Globals from '../../../core/js/_frontloader/globals';
import IterationModel from '../../../core/js/models/iteration';
import SpaceModel from '../../../core/js/models/space';
import StoriesIterationModel from '../models/storiesIteration';
import StoriesStoryController from './storiesStory';
import View from 'app/client/core/js/modules/view';
import StoriesView from '../modules/storiesView';
import Tests from '../../../core/js/modules/tests';
import Utils from '../../../core/js/modules/utils';
import ApplicationState from '../../../core/js/modules/applicationState';
import * as Group from 'data/entity/group';
import * as Stories from 'data/page/stories';
import { capitalize } from '@clubhouse/shared/utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const PARENT_SELECTOR = '#iteration-nav-bucket';
const SHOW_SELECTED_GROUPS_ITERATIONS = 'Stories.ShowOnlyIterationsFromSelectedGroups';
exports.init = () => {
  IterationModel.on('beforeAdd', iteration => {
    iteration.active = SpaceModel.isModelActiveInActiveSpace('Iteration', iteration);
  });
  IterationModel.on('added updated moved removed flushed', () => {
    if (IterationModel.isNotBulk()) {
      exports.render();
    }
  });
  const NS = '.IterationList';
  Event.onlyOn('iterationsEnabled' + NS, exports.render);
  Event.onlyOn('iterationsDisabled' + NS, () => {
    $(PARENT_SELECTOR).addClass('hidden');
  });
};
exports.render = () => {
  const $parent = $(PARENT_SELECTOR);
  if (!Tests.usesIterations()) {
    $parent.addClass('hidden');
    return;
  } else {
    $parent.removeClass('hidden');
  }
  renderHtml($('#iteration-nav'), IterationListTemplate);
  return false;
};
exports.renderListItems = () => {
  const $el = $('#iteration-nav');
  if ($el.find('.iterations').length) {
    return;
  }
  renderHtml($el, IterationListItemsTemplate);
};
exports.renderIterationIcon = (id, status) => {
  let fill;
  if (status === 'done') {
    fill = 'var(--iconGreenColor)';
  } else if (status === 'started') {
    fill = 'var(--iconBlueColor)';
  } else {
    fill = 'currentColor';
  }
  return View.renderComponentDelayed({
    componentKey: `iteration-icon-${id}`,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 13,
      fill: fill
    }, ___EmotionJSX(Icon, {
      icon: "Iteration"
    }))
  }).html;
};
exports.renderStatusIcon = (id, status) => {
  const icon = capitalize(status);
  return View.renderComponentDelayed({
    componentKey: `iteration-icon-${id}`,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 18
    }, ___EmotionJSX(StatusIcon, {
      icon: icon
    }))
  }).html;
};
const renderHtml = ($el, template) => {
  const teamScopeId = getTeamScopedCollectionizeId();
  $el.find('.iteration-parent.ui-droppable').droppable('destroy');
  const iterations = IterationModel.getAllSortedBy('start_date', 'desc');
  const {
    UNSTARTED,
    STARTED,
    DONE
  } = IterationModel.STATUS_VALUES;
  let shownIterations = showIterationsFromSelectedGroups() ? getIterationsFromSelectedGroups(false) : iterations.filter(_ref => {
    let {
      status
    } = _ref;
    return [UNSTARTED, STARTED].includes(status);
  });
  let moreIterations = showIterationsFromSelectedGroups() ? getIterationsFromSelectedGroups(true) : iterations.filter(_ref2 => {
    let {
      status
    } = _ref2;
    return status === DONE;
  });
  if (teamScopeId) {
    shownIterations = iterations.filter(_ref3 => {
      let {
        associated_groups,
        group_ids,
        status
      } = _ref3;
      return [UNSTARTED, STARTED].includes(status) && (group_ids?.includes(teamScopeId) || associated_groups?.map(_ref4 => {
        let {
          group_id
        } = _ref4;
        return group_id;
      }).includes(teamScopeId));
    });
  }
  if (teamScopeId) {
    moreIterations = moreIterations.filter(iteration => {
      return (iteration.group_ids?.includes(teamScopeId) || iteration.associated_groups?.map(_ref5 => {
        let {
          group_id
        } = _ref5;
        return group_id;
      }).includes(teamScopeId)) && !shownIterations.includes(iteration);
    });
  }
  const html = template.render({
    iterations: shownIterations,
    displayStoriesNotInIterations: StoriesIterationModel.shouldDisplayStoriesNotInIterations(),
    moreIterations
  });
  $el.html(html);
  exports.initNavDroppable();
  ContextMenuController.refresh();
};
function getIterationsFromSelectedGroups(isDone) {
  const iterations = IterationModel.all();
  return iterations.filter(iteration => {
    const statusType = isDone ? iteration.status === IterationModel.STATUS_VALUES.DONE : iteration.status !== IterationModel.STATUS_VALUES.DONE;
    return Stories.isNoGroupSelected() && statusType && iteration.group_ids.length === 0 || statusType && iteration.group_ids.some(id => Stories.getIsGroupSelected({
      id
    }) && !Group.getById(id).archived);
  });
}
exports.toggleMoreIterations = () => {
  Globals.set('showMoreIterations', !Globals.get('showMoreIterations'));
  $('.more-iterations').slideToggle(200);
  $('.toggle-more-iterations').html(ToggleMoreIterationsTemplate.render());
  return false;
};
function updateSpaceAndRerender() {
  Event.trigger('spaceUpdate');
  exports.render();
  StoriesView.resetAndDrawStories();
}
exports.initNavDroppable = () => {
  $('#iteration-nav .iteration-parent').droppable({
    hoverClass: 'drag-hover',
    drop: function (e, ui) {
      const element = ui.draggable[0];

      // Guard against sortable usage:
      if (!$(element).hasClass('story')) {
        return false;
      }
      const iteration_id = Utils.data(this, 'id');
      StoriesStoryController.updateOneOrManyOnDrop(element, {
        iteration_id
      });
    },
    tolerance: 'pointer'
  });
};
exports.showAllIterations = () => {
  StoriesIterationModel.displayStoriesNotInIterations();
  StoriesIterationModel.enableAll();
  updateSpaceAndRerender();
  return false;
};
exports.hideAllIterations = () => {
  StoriesIterationModel.displayStoriesNotInIterations();
  StoriesIterationModel.disableAll();
  updateSpaceAndRerender();
  return false;
};
exports.toggleStoriesNotInIteration = e => {
  if (e.shiftKey) {
    const activeIterations = IterationModel.filter('active');
    if (activeIterations.length === 0) {
      return exports.showAllIterations.call(this);
    } else {
      return exports.hideAllIterations.call(this);
    }
  }
  if (StoriesIterationModel.shouldDisplayStoriesNotInIterations()) {
    StoriesIterationModel.hideStoriesNotInIterations();
  } else {
    StoriesIterationModel.displayStoriesNotInIterations();
  }
  updateSpaceAndRerender();
};
exports.highlight = function (iteration) {
  iteration = iteration || Utils.getModelFromContext(this);
  IterationModel.each(iteration => {
    iteration.active = false;
  });
  StoriesIterationModel.hideStoriesNotInIterations();
  StoriesIterationModel.toggle(iteration);
  updateSpaceAndRerender();
  return false;
};
exports.moreActionsDropdownItems = () => {
  const shouldSIterationsFromSelectedGroups = showIterationsFromSelectedGroups();
  return [{
    html: '<div class="caption"><em>Show in this list...</em></div>'
  }, {
    name: 'Iterations from any Team',
    iconLeft: shouldSIterationsFromSelectedGroups ? 'fa-circle-o' : 'fa-circle',
    value: () => exports.showOnlyIterationsFromSelectedGroups(false)
  }, {
    name: 'Only Iterations from Selected Teams',
    iconLeft: shouldSIterationsFromSelectedGroups ? 'fa-circle' : 'fa-circle-o',
    value: () => exports.showOnlyIterationsFromSelectedGroups(true)
  }];
};
exports.generalContextMenuItems = () => {
  const teamScopeId = getTeamScopedCollectionizeId();
  const menuItems = [{
    name: 'All',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: 'Show All Iterations',
    value: exports.showAllIterations
  }, {
    name: 'None',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "ViewOff"
    }))),
    tooltip: 'Deselect All Iterations',
    value: exports.hideAllIterations
  }, ...(teamScopeId ? [] : [{
    tooltip: 'More actions',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "More"
    }))),
    value: function () {
      ContextMenuController.openMore({
        items: exports.moreActionsDropdownItems(),
        showCloseButton: true,
        target: this,
        width: 250
      });
      return false;
    }
  }])];
  return menuItems;
};
exports.showOnlyIterationsFromSelectedGroups = value => {
  if (value === showIterationsFromSelectedGroups()) {
    return false;
  }
  exports.setShowIterationsFromSelectedGroups(value);
  exports.render();
  return false;
};
function showIterationsFromSelectedGroups() {
  return ApplicationState.get(SHOW_SELECTED_GROUPS_ITERATIONS) || false;
}
exports.setShowIterationsFromSelectedGroups = value => ApplicationState.set(SHOW_SELECTED_GROUPS_ITERATIONS, value);
exports.noIterationContextMenuItems = () => {
  const isOnlySelection = StoriesIterationModel.shouldDisplayStoriesNotInIterations() && IterationModel.filter('active').length === 0;
  return [{
    name: isOnlySelection ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: isOnlySelection ? 'Show all Iterations' : 'Show only Stories with no Iteration',
    tooltipShortcut: '(shift-click)',
    value: () => {
      isOnlySelection ? exports.showAllIterations() : exports.hideAllIterations();
      return false;
    }
  }];
};
exports.toggle = function (e, options) {
  options = options || {};
  const iteration = Utils.getModelFromContext(this);
  if (e.shiftKey || options.force) {
    if (StoriesIterationModel.onlyCurrentIterationActive(iteration)) {
      return exports.showAllIterations();
    } else if (!iteration) {
      return exports.hideAllIterations();
    } else {
      return exports.highlight.call(this);
    }
  }
  $(this).toggleClass('active');
  StoriesIterationModel.toggle(iteration);
  updateSpaceAndRerender();
  return false;
};
exports.contextMenuItems = function () {
  const iteration = Utils.getModelFromContext(this);
  const isOnlySelection = StoriesIterationModel.onlyCurrentIterationActive(iteration);
  return [{
    name: isOnlySelection ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: isOnlySelection ? 'Show all Iterations' : 'Show only this Iteration',
    tooltipShortcut: '(shift-click)',
    value: target => {
      if (isOnlySelection) {
        exports.showAllIterations();
      } else {
        exports.highlight.call(target);
      }
      return false;
    }
  }];
};
export { exports as default };