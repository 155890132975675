import { Icon } from '@useshortcut/shapes-ds';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { FixedActionRow } from '@clubhouse/shared/components/Table/components/FixedActionRow';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { jsx as ___EmotionJSX } from "@emotion/react";
const PlusIcon = () => ___EmotionJSX(Icon, {
  icon: "Add",
  fill: "currentColor"
});
PlusIcon.displayName = "PlusIcon";
export function CreateBacklogStoryTableAction(_ref) {
  let {
    onClick
  } = _ref;
  const isReady = useConsolidatedDataFetched();
  const handleClick = useCallback(() => {
    if (isReady) onClick();
  }, [isReady, onClick]);
  return ___EmotionJSX(FixedActionRow, {
    isDisabled: !isReady,
    onClick: handleClick
  }, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.MEDIUM,
    fit: Button.FIT.MEDIUM,
    IconRight: PlusIcon,
    isDisabled: !isReady || isLoggedInUserObserver()
  }, "Create Backlog Story"));
}
CreateBacklogStoryTableAction.displayName = "CreateBacklogStoryTableAction";