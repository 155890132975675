function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useScrollTarget } from './shared';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const makeScrollTarget = Component => {
  return function ScrollTarget(_ref) {
    let {
      id,
      ...rest
    } = _ref;
    const props = useScrollTarget(id);
    return ___EmotionJSX(Component, _extends({}, rest, props));
  };
};