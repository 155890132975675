import { useCallback } from 'react';
import { allByTeam } from 'data/entity/project';
import { saveChanges } from 'data/entity/story';
import { storyProject } from 'utils/sort';
import { FIELD_ID } from 'components/shared/table/types';
import { TableProject } from './tableComponents';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ProjectFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  const handleChange = useCallback(value => {
    saveChanges(entity.id, {
      project_id: value
    });
  }, [entity.id]);
  return ___EmotionJSX(TableProject, {
    onChange: handleChange,
    projects: allByTeam(),
    project: entity.project_id ? entity.project : null
  });
}
ProjectFieldComponent.displayName = "ProjectFieldComponent";
export const ProjectField = () => ({
  name: FIELD_ID.PROJECT,
  displayName: 'Project',
  sort: storyProject,
  Component: ProjectFieldComponent,
  width: 140
});