import { FEATURE_TOGGLES } from '@clubhouse/feature-toggles';
import { useVariation } from 'utils/launch-darkly';
import { PAGE_NAMES, getCurrentPathname, getMatchForPath } from 'utils/navigation';
import { useShouldReplaceLegacyStoriesPage } from './hooks';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoriesV2Router = _ref => {
  let {
    enabled,
    disabled,
    v3
  } = _ref;
  const {
    value: shouldUseV3
  } = useVariation(FEATURE_TOGGLES.STORIES_V3);
  const {
    value: shouldReplaceLegacyStoriesPage,
    loading
  } = useShouldReplaceLegacyStoriesPage();
  const pageName = getMatchForPath({
    path: getCurrentPathname()
  }).pageName;
  if (loading) return null;
  switch (pageName) {
    case PAGE_NAMES.STORIES:
      {
        if (shouldUseV3 && v3) return v3;
        const isEnabled = Boolean(shouldReplaceLegacyStoriesPage);
        return ___EmotionJSX(Condition, {
          isEnabled: isEnabled,
          enabled: enabled,
          disabled: disabled
        });
      }
    case PAGE_NAMES.STORY:
      {
        if (shouldUseV3 && v3) return v3;
        const isEnabled = Boolean(shouldReplaceLegacyStoriesPage);
        return ___EmotionJSX(Condition, {
          isEnabled: isEnabled,
          enabled: enabled,
          disabled: disabled
        });
      }
  }
  return null;
};
const Condition = _ref2 => {
  let {
    isEnabled,
    enabled,
    disabled
  } = _ref2;
  return isEnabled ? enabled : disabled;
};