import chunk from 'lodash/chunk';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { useIsMobile } from '@clubhouse/shared/hooks';
import { getAllDetailsById } from 'data/entity/profile';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TaskOwners(_ref) {
  let {
    task
  } = _ref;
  const ownerRowSize = useIsMobile() ? 3 : 4;
  const ownerGroups = chunk(task.owner_ids, ownerRowSize);
  return ___EmotionJSX(React.Fragment, null, ownerGroups.map((ownerGroup, i) => ___EmotionJSX("span", {
    key: i,
    className: "owners"
  }, ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: 3
  }, ownerGroup.map(ownerId => {
    const owner = getAllDetailsById(ownerId);
    return owner ? ___EmotionJSX(Avatar, {
      key: ownerId,
      profile: owner,
      size: Avatar.SIZE.XS,
      withTooltip: true
    }) : null;
  })))));
}