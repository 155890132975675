import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import { updateApolloCacheFromRestUpdates } from './updateApolloCacheFromRestUpdates';

/**
 * Digests updates from the REST API `/updates` endpoint.
 *
 * This function is meant to be called from the REST API response handler,
 * and could live directly within `updates` module, but because the module
 * isn't in TypeScript and we needed other TypeScript functions like
 * `updateApolloCacheFromRestUpdates` and feature flags, I decided to move
 * it to its own centralized function to avoid polluting the module with it.
 */
export function digestUpdates(res) {
  if (!res?.updates) {
    return;
  }
  if (getFeatureToggle(FEATURE_TOGGLES.UPDATE_APOLLO_CACHE_FROM_UPDATES_ENDPOINT)) {
    updateApolloCacheFromRestUpdates(res);
  }
}