import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { pluralize } from '@clubhouse/shared/utils';
import { ChipTooltip } from '../../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ExternalLinkChip = _ref => {
  let {
    count,
    icon,
    type = 'External'
  } = _ref;
  if (!count) return null;
  return ___EmotionJSX(ChipTooltip, {
    content: `This Story has ${pluralize({
      count,
      singular: `${type} Link`,
      plural: `${type} Links`
    })} attached.`
  }, ___EmotionJSX(Chip, null, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 18
  }, ___EmotionJSX(ThirdPartyIcon, {
    label: pluralize({
      count,
      singular: `${type} Link`,
      plural: type + ' Links'
    }),
    icon: icon
  })), count));
};
ExternalLinkChip.displayName = "ExternalLinkChip";