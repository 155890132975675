import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { TeamTooltip } from 'components/gql/team/TeamTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Group = _ref => {
  let {
    teamId,
    color,
    name,
    thumbnailUrl
  } = _ref;
  return ___EmotionJSX(TeamTooltip, {
    teamId: teamId
  }, ___EmotionJSX("div", null, ___EmotionJSX(TeamIcon, {
    size: 24,
    iconUrl: thumbnailUrl,
    alt: name ? name : 'No team',
    backgroundColor: getTeamColor({
      color
    }),
    iconType: "new-team-icon"
  })));
};
Group.displayName = "Group";