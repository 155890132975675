import { getExternalLinks } from 'data/entity/externalLink';
import { DeprecatedIconExternalLinkChip, ExternalLinkChip } from './components';
import { useNamedExternalLinks } from './hooks';
import { jsx as ___EmotionJSX } from "@emotion/react";
const externalLinkConfigs = getExternalLinks();
export const ExternalLinkChips = _ref => {
  let {
    externalLinks
  } = _ref;
  const {
    deskExternalLinks,
    freshdeskExternalLinks,
    frontExternalLinks,
    figmaExternalLinks,
    gitHubExternalLinks,
    helpScoutExternalLinks,
    intercomExternalLinks,
    jiraServiceDeskExternalLinks,
    kustomerExternalLinks,
    rollbarExternalLinks,
    sentryExternalLinks,
    zendeskExternalLinks
  } = useNamedExternalLinks(externalLinks);
  if (!externalLinks?.length) return null;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: deskExternalLinks.length,
    iconURL: externalLinkConfigs.DESK.ICON,
    type: externalLinkConfigs.DESK.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: freshdeskExternalLinks.length,
    iconURL: externalLinkConfigs.FRESHDESK.ICON,
    type: externalLinkConfigs.FRESHDESK.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: frontExternalLinks.length,
    iconURL: externalLinkConfigs.FRONT.ICON,
    type: externalLinkConfigs.FRONT.NAME
  }), ___EmotionJSX(ExternalLinkChip, {
    count: figmaExternalLinks.length,
    icon: "Figma",
    type: externalLinkConfigs.FIGMA.NAME
  }), ___EmotionJSX(ExternalLinkChip, {
    count: gitHubExternalLinks.length,
    icon: "Github",
    type: externalLinkConfigs.GITHUB.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: helpScoutExternalLinks.length,
    iconURL: externalLinkConfigs.HELPSCOUT.ICON,
    type: externalLinkConfigs.HELPSCOUT.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: intercomExternalLinks.length,
    iconURL: externalLinkConfigs.INTERCOM.ICON,
    type: externalLinkConfigs.INTERCOM.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: jiraServiceDeskExternalLinks.length,
    iconURL: externalLinkConfigs.JIRA_SERVICE_DESK.ICON,
    type: externalLinkConfigs.JIRA_SERVICE_DESK.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: kustomerExternalLinks.length,
    iconURL: externalLinkConfigs.KUSTOMER.ICON,
    type: externalLinkConfigs.KUSTOMER.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: rollbarExternalLinks.length,
    iconURL: externalLinkConfigs.ROLLBAR.ICON,
    type: externalLinkConfigs.ROLLBAR.NAME
  }), ___EmotionJSX(DeprecatedIconExternalLinkChip, {
    count: sentryExternalLinks.length,
    iconURL: externalLinkConfigs.SENTRY.ICON,
    type: externalLinkConfigs.SENTRY.NAME
  }), ___EmotionJSX(ExternalLinkChip, {
    count: zendeskExternalLinks.length,
    icon: "Zendesk",
    type: externalLinkConfigs.ZENDESK.NAME
  }));
};