function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { StyledMenu } from './styledComponent';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DefaultMenuComponent = _ref => {
  let {
    getMenuProps,
    children
  } = _ref;
  const {
    menuRef,
    ...menuProps
  } = getMenuProps({
    refKey: 'menuRef'
  });
  return ___EmotionJSX(StyledMenu, _extends({
    ref: menuRef
  }, menuProps), children);
};
DefaultMenuComponent.displayName = "DefaultMenuComponent";