import "core-js/modules/es.array.push.js";
import AddNewController from 'app/client/core/js/controllers/addNew.js';
import AddNewDocController from 'app/client/core/js/controllers/addNewDoc.js';
import AddNewEpicController from 'app/client/core/js/controllers/addNewEpic.js';
import AddNewIterationController from 'app/client/core/js/controllers/addNewIteration.js';
import AddNewLabelController from 'app/client/core/js/controllers/addNewLabel.js';
import AddNewProjectController from 'app/client/core/js/controllers/addNewProject.js';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory.js';
import GroupController from 'app/client/core/js/controllers/group.js';
import LabelController from 'app/client/core/js/controllers/label.js';
import Dialog from 'app/client/core/js/modules/dialog.js';
import Iteration from 'app/client/core/js/modules/iteration.js';
import User from 'app/client/core/js/modules/user.js';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const AddNewController = exports;
  [[['Controller', 'AddNew'], AddNewController], [['Controller', 'AddNewDoc'], AddNewDocController], [['Controller', 'AddNewEpic'], AddNewEpicController], [['Controller', 'AddNewIteration'], AddNewIterationController], [['Controller', 'AddNewLabel'], AddNewLabelController], [['Controller', 'AddNewProject'], AddNewProjectController], [['Controller', 'AddNewStory'], AddNewStoryController], [['Controller', 'Group'], GroupController], [['Controller', 'Label'], LabelController], [['Dialog'], Dialog], [['Iteration'], Iteration], [['User'], User], [['Controller', 'InviteUsers'], InviteUsersController], [['Controller', 'AddNew'], AddNewController], [['Controller', 'AddNewDoc'], AddNewDocController], [['Controller', 'AddNewEpic'], AddNewEpicController], [['Controller', 'AddNewIteration'], AddNewIterationController], [['Controller', 'AddNewLabel'], AddNewLabelController], [['Controller', 'AddNewProject'], AddNewProjectController], [['Controller', 'AddNewStory'], AddNewStoryController], [['Controller', 'Group'], GroupController], [['Controller', 'Label'], LabelController], [['Dialog'], Dialog], [['Iteration'], Iteration], [['User'], User]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { insertIf } from '@clubhouse/shared/utils';
import ColumnModel from 'app/client/core/js/models/column';
import TeamModel from 'app/client/core/js/models/team';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { backlogStoryDefaults } from 'gql/variables';
import { renderAddNewStory } from 'utils/addNew';
import { PAGE_NAMES } from 'utils/navigation/paths';
import Is from '../modules/is';
import LocalStorage from '../modules/localStorage';
import Url from '../modules/url';
import Utils from '../modules/utils';
const exports = {};
exports.DEFAULT_VERB = 'Create';
exports.TEMPLATE_DROPDOWN_ID = 'template-picker';
exports.SUB_TEMPLATE_DROPDOWN_ID = 'sub-template-picker';
const _getAddStoryButton = () => ({
  id: 'story',
  text: 'Story',
  action: 'App.Controller.AddNewStory.render'
});
exports.addNewStoryFromBacklog = () => {
  const defaults = backlogStoryDefaults();
  const renderAddNewStoryOptions = {
    context: 'backlog'
  };
  if (defaults) {
    renderAddNewStory({
      workflow_state_id: defaults.workflowStateId,
      workflow_id: defaults.workflowId,
      group_id: defaults.teamId
    }, renderAddNewStoryOptions);
    return;
  }

  /* Fall back on Collectionize if there's no story defaults from GQL */
  const activeTeamBacklogWorkflowStates = TeamModel.getWorkflowStatesForTeam(TeamModel.getDefaultTeam()).filter(ColumnModel.isBacklog);
  if (activeTeamBacklogWorkflowStates.length > 0) {
    renderAddNewStory({
      workflow_state_id: activeTeamBacklogWorkflowStates[0].id,
      group_id: TeamModel.getDefaultTeam().id
    }, renderAddNewStoryOptions);
    return;
  }

  /* Fall back on the first backlog story state that exists */
  const allBacklogStates = ColumnModel.filter(ColumnModel.isBacklog);
  if (allBacklogStates.length > 0) {
    renderAddNewStory({
      workflow_state_id: allBacklogStates[0].id
    }, renderAddNewStoryOptions);
    return;
  }

  /* Failure case -- just load the Add New Story dialog with no pre-guessed values */
  renderAddNewStory(null, renderAddNewStoryOptions);
};

// TODO: (@charpeni) Instead of refering to controllers by string in action, we should import them (and prevent circular dependencies).
exports._getButtons = () => {
  return [_getAddStoryButton(), {
    id: 'epic',
    action: 'App.Controller.AddNewEpic.render',
    text: 'Epic',
    currentPage: ['epics']
  }, {
    id: 'epic-story',
    action: 'App.Controller.EpicPage.createStoryInEpic',
    text: 'Story in Epic',
    currentPage: ['epic']
  }, {
    id: 'backlog-story',
    action: 'App.Controller.AddNew.addNewStoryFromBacklog',
    text: 'Story in Backlog',
    currentPage: ['backlog']
  }, {
    id: 'iteration',
    action: 'App.Controller.AddNewIteration.render',
    text: 'Iteration',
    currentPage: ['iterations']
  }, {
    id: 'iteration-story',
    action: 'App.Controller.IterationPage.createStoryInIteration',
    text: 'Story in Iteration',
    currentPage: ['iteration']
  }, ...insertIf(isProjectsFeatureEnabled(), [{
    id: 'project',
    action: 'App.Controller.AddNewProject.render',
    text: 'Project',
    currentPage: ['projects']
  }]), {
    id: 'project-story',
    action: 'App.Controller.ProjectPage.createStoryInProject',
    text: isProjectsFeatureEnabled() ? 'Story in Project' : 'Story',
    currentPage: ['projects']
  }, {
    id: 'label',
    action: 'App.Controller.AddNewLabel.open',
    text: 'Label',
    currentPage: ['labels', PAGE_NAMES.SETTINGS_LABELS]
  }, {
    id: 'label-story',
    action: 'App.Controller.Label.createStoryWithLabel',
    text: 'Story with Label',
    currentPage: ['label', PAGE_NAMES.SETTINGS_LABEL]
  }, {
    id: 'objective',
    text: 'Objective',
    currentPage: ['objectives']
  }, {
    id: 'objective-epic',
    action: 'App.Controller.MilestonePage.createEpicInMilestone',
    text: 'Epic in Objective',
    currentPage: ['objective']
  }, {
    id: 'doc',
    action: 'App.Controller.AddNewDoc.createDoc',
    text: 'Doc',
    currentPage: [PAGE_NAMES.DOC, PAGE_NAMES.MY_DOCS, PAGE_NAMES.WRITE, PAGE_NAMES.COLLECTION]
  }, {
    id: 'user',
    action: 'App.Controller.InviteUsers.open',
    text: 'User',
    verb: 'Invite'
  }, {
    id: 'team',
    action: 'App.Controller.Group.openCreateDialog',
    text: 'Team',
    currentPage: ['teams', PAGE_NAMES.SETTINGS_TEAMS]
  }, {
    id: 'team-story',
    action: 'App.Controller.Group.createStoryInGroup',
    text: 'Story in Team',
    currentPage: ['team', PAGE_NAMES.SETTINGS_TEAM]
  }, {
    id: 'roadmap',
    action: 'App.Controller.Group.openCreateDialog',
    text: 'Team',
    currentPage: ['roadmap']
  }];
};
exports.getButton = id => {
  const buttons = exports._getButtons();
  return _.find(buttons, {
    id
  }) || _.head(buttons);
};
exports.getState = key => {
  const state = LocalStorage.get(key, {
    prefix: true
  });
  if (!state) {
    return state;
  }
  return Utils.parseJSON(state, {
    message: 'Unable to parse ' + key
  });
};
exports.updateState = (key, state) => {
  LocalStorage.set(key, state, {
    prefix: true
  });
};
exports.removeState = key => {
  LocalStorage.remove(key, {
    prefix: true
  });
};
exports.getCurrentButton = pageName => {
  if (!Is.withinOrg()) {
    return undefined;
  }
  const page = pageName ?? Url.getCurrentPage();
  const buttons = exports._getButtons();
  const pageButton = buttons.find(button => {
    const {
      currentPage
    } = button;
    if (!currentPage) {
      return false;
    }
    return currentPage.some(path => path === page);
  });
  return pageButton || _getAddStoryButton();
};
export { exports as default };