function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import Downshift from 'downshift';
import { useCallback } from 'react';
import { useIsSelectOpen } from '@clubhouse/shared/utils/isSelectOpen';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function useStateReducer(_ref) {
  let {
    closeOnSelect,
    initialHighlightedIndex,
    isOpen: _isOpen
  } = _ref;
  const stateReducer = useCallback((state, changes) => {
    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      delete changes.selectedItem;
    }
    const shouldClose = closeOnSelect || changes.selectedItem?.closeOnSelect;
    const isOpen = typeof _isOpen === 'boolean' ? _isOpen : !shouldClose;
    if (changes.type === Downshift.stateChangeTypes.keyDownEnter || changes.type === Downshift.stateChangeTypes.clickItem) {
      return {
        ...changes,
        selectedItem: {
          ...changes.selectedItem
        },
        isOpen,
        inputValue: state.inputValue,
        highlightedIndex: state.highlightedIndex
      };
    }
    if (changes.isOpen === false) {
      return {
        ...changes,
        highlightedIndex: initialHighlightedIndex,
        inputValue: ''
      };
    }
    return changes;
  }, [closeOnSelect, initialHighlightedIndex, _isOpen]);
  return stateReducer;
}
export function MultiDownshift(props) {
  const [, setIsSelectOpen] = useIsSelectOpen();
  const stateReducer = useStateReducer(props);
  const {
    onOpen,
    onClose,
    onFilter
  } = props;
  const onStateChange = useCallback(_ref2 => {
    let {
      isOpen,
      inputValue
    } = _ref2;
    setIsSelectOpen(!!isOpen);
    if (isOpen === true) {
      onOpen?.();
    } else if (isOpen === false) {
      onClose?.();
    }
    if (typeof inputValue === 'string') {
      onFilter?.(inputValue);
    }
  }, [onOpen, onClose, onFilter, setIsSelectOpen]);
  return ___EmotionJSX(Downshift, _extends({}, props, {
    stateReducer: stateReducer,
    selectedItem: null,
    onStateChange: onStateChange
  }), props.children);
}
MultiDownshift.displayName = "MultiDownshift";