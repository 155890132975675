import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { sanitize } from '@clubhouse/shared/utils';
import { ArchiveMessage } from 'components/shared/ArchiveMessage';
import { deleteEpic, unarchiveEpic } from 'data/entity/epic';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ArchivedEpic(_ref) {
  let {
    epic
  } = _ref;
  const {
    addToast
  } = useToast();
  return ___EmotionJSX(ArchiveMessage, {
    typeName: "Epic",
    onUnarchive: () => {
      unarchiveEpic(epic, err => {
        if (err) {
          addToast({
            kind: 'alert',
            Content: () => ___EmotionJSX(ToastText, null, "Unable to unarchive ", ___EmotionJSX("strong", null, sanitize(epic.name)), "."),
            timeout: 5000
          });
        } else {
          addToast({
            kind: 'success',
            Content: () => ___EmotionJSX(ToastText, null, "Epic ", ___EmotionJSX("strong", null, sanitize(epic.name)), " unarchived."),
            timeout: 5000
          });
        }
      });
    },
    onDelete: () => {
      if (window.confirm('Are you sure you want to delete this Epic? No Stories will be harmed during deletion.')) {
        deleteEpic(epic, err => {
          if (err) {
            addToast({
              kind: 'alert',
              Content: () => ___EmotionJSX(ToastText, null, "Unable to delete ", ___EmotionJSX("strong", null, sanitize(epic.name)), "."),
              timeout: 5000
            });
          }
        });
      }
    }
  }, "Archived Epics and its Stories are excluded from reports and charts, as well as from the Stories, Status, and My Work pages. Archived Epics can still be viewed directly using their permalink.");
}
ArchivedEpic.displayName = "ArchivedEpic";