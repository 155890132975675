import { useTheme } from '@emotion/react';
import { Badge } from '@clubhouse/shared/components/Badge';
import { Circle } from '@clubhouse/shared/components/Icons';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SIZE = {
  xsmall: 8,
  // this is not a confirmed design
  small: 10,
  medium: 11,
  // this is not a confirmed design
  large: 12
};
export const BadgeLabelContent = _ref => {
  let {
    label,
    size = 'small',
    icon
  } = _ref;
  const theme = useTheme();
  return ___EmotionJSX(Badge, {
    kind: "outline",
    size: size,
    icon: icon ?? (() => ___EmotionJSX("div", {
      style: {
        marginRight: 4
      }
    }, ___EmotionJSX(Circle, {
      width: SIZE[size],
      fill: label.color ?? theme.labelYellow
    })))
  }, label.name);
};
BadgeLabelContent.displayName = "BadgeLabelContent";