import Logo from '@clubhouse/assets/png/third-party-logos/codestream_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function CodeStream() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.CODESTREAM
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "CodeStream"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "CodeStream Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Attach commits, branches, and merge requests to stories and use", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.codestream.com/"
  }, "CodeStream"), " workflow triggers to automate your workflow."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.codestream.com/integrations/clubhouse"
  }, "Learn more"), " on how the CodeStream integration works in Shortcut.")));
}
CodeStream.displayName = "CodeStream";