import { Icon } from '@useshortcut/shapes-ds';
import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Estimate" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Points = _ref => {
  let {
    fill,
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill
  }, ___EmotionJSX(Icon, {
    icon: "Estimate"
  }));
};
Points.displayName = "Points";