import { Fragment } from 'react';
import { TablePaginationConfig } from 'components/gql/pagination/TablePagination';
import { useTeamScopedApplicationStateKey } from 'components/team-navigation';
import { GraphQlPage } from 'gql/components/GraphQlPage';
import { BacklogPageFilterConfig } from 'pages/backlog/components/filters/useFilters';
import { BacklogGroupByConfig } from 'pages/backlog/entities/stories/components/StoriesGroupBySelect';
import { SORT_BY_PARAM_NAME, SORT_DIR_PARAM_NAME } from 'pages/backlog/entities/stories/components/table/useBacklogTableSortUrlState';
import { BacklogTableView } from './table/BacklogTableView';
import { jsx as ___EmotionJSX } from "@emotion/react";
const pageName = 'backlog';
const applicationFilterStateKey = `${pageName}.filters`;
const persistedSearchParamKeys = [...Object.values(BacklogPageFilterConfig).map(_ref => {
  let {
    paramName
  } = _ref;
  return paramName;
}), TablePaginationConfig.paramName, BacklogGroupByConfig.paramName, SORT_BY_PARAM_NAME, SORT_DIR_PARAM_NAME];
export const BacklogPage = _ref2 => {
  let {
    renderId
  } = _ref2;
  const key = useTeamScopedApplicationStateKey(applicationFilterStateKey);
  return ___EmotionJSX(GraphQlPage, {
    pageName: pageName,
    applicationFilterStateKey: key,
    persistedSearchParamKeys: persistedSearchParamKeys,
    TranslateSearchParams: Fragment,
    renderId: renderId
  }, ___EmotionJSX(BacklogTableView, null));
};
BacklogPage.displayName = "BacklogPage";