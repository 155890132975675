import AddNewStoryController from '../../../core/js/controllers/addNewStory';
import DropdownController from '../../../core/js/controllers/dropdown';
import Format from '../../../core/js/modules/format';
import IterationModel from '../../../core/js/models/iteration';
import MessageController from '../../../core/js/controllers/message';
import Tooltip from '../../../core/js/modules/tooltip';
import UserWarning from '../../../core/js/controllers/userWarning';
import Utils from '../../../core/js/modules/utils';
const exports = {};
exports.openIterationActionsDropdown = function () {
  const iteration = Utils.getModelFromContext(this, 'Iteration');
  Tooltip.close();
  DropdownController.open({
    items: [{
      name: 'Delete Iteration...',
      value: () => deleteIteration(iteration),
      iconLeft: 'fa-trash-o'
    }],
    target: this,
    topOffset: 0,
    width: 200
  });
  return false;
};
exports.createStoryInIteration = id => {
  AddNewStoryController.updateState({
    iteration_id: id
  });
  AddNewStoryController.render({
    context: 'iteration'
  });
};
const deleteIteration = iteration => {
  const {
    name,
    id
  } = iteration;
  UserWarning.open({
    id: 'delete-iteration',
    target: $(`[data-id=${id}]`),
    title: 'Are you sure you want to delete this Iteration?',
    description: 'Deleting this Iteration will remove all Stories from this Iteration. The Stories will not be deleted.',
    question: `Continue deleting <strong>${name}</strong>?`,
    onApprove: () => {
      IterationModel.deleteIteration(iteration, err => {
        if (err) {
          MessageController.error(err, {
            secondary: `We were unable to delete <strong>${Format.sanitize(name)}</strong>.`
          });
        } else {
          MessageController.success(`Iteration <strong>#${id}</strong> deleted.`);
        }
      });
    }
  });
};
export { exports as default };