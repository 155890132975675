import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const JiraSyncChip = () => {
  const story = useContext(StoryContext);
  if (!story || !story.synced_item) return null;
  return ___EmotionJSX(StoryCardInternal.Chips.JiraSyncChip, {
    externalId: story.synced_item.external_id
  });
};
JiraSyncChip.displayName = "JiraSyncChip";