import { DropdownMenu } from '@clubhouse/shared/components/DropdownMenu';
import { capitalize } from '@clubhouse/shared/utils';
import { CheckboxItem, FilterLabel, SecondaryText, SelectContainer } from '../reports-v2/ReportSelectUtils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BurndownSelect = _ref => {
  let {
    onChange,
    value
  } = _ref;
  return ___EmotionJSX(SelectContainer, null, ___EmotionJSX(FilterLabel, null, "Type"), ___EmotionJSX(DropdownMenu, null, ___EmotionJSX(DropdownMenu.Trigger, {
    asChild: true
  }, ___EmotionJSX(DropdownMenu.ToggleButton, null, capitalize(value))), ___EmotionJSX(DropdownMenu.Portal, null, ___EmotionJSX(DropdownMenu.Content, {
    align: "start"
  }, ___EmotionJSX(CheckboxItem, {
    onSelect: () => onChange('daily'),
    checked: value === 'daily'
  }, "Daily", ___EmotionJSX(SecondaryText, null, "Activity is aggregated at the end of each day.")), ___EmotionJSX(CheckboxItem, {
    onSelect: () => onChange('detailed'),
    checked: value === 'detailed'
  }, "Detailed", ___EmotionJSX(SecondaryText, null, "Activity is shown when it occurs throughout the day."))))));
};
BurndownSelect.displayName = "BurndownSelect";