import BacklogPng from '@clubhouse/assets/png/Backlog-illo-light.png';
import { Center } from '@clubhouse/shared/components/Center';
import { EmptyState } from '@clubhouse/shared/components/EmptyState';
import { useClearableCurrentFilters } from 'components/gql/filters/useClearableCurrentFilters';
import { CompositeImage } from 'components/shared/CompositeImage';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Image = ___EmotionJSX(EmptyStateImage, {
  wrapperWidth: 72
}, ___EmotionJSX(CompositeImage, {
  primaryImage: {
    src: BacklogPng,
    alt: '',
    darkModeOpacity: 1
  }
}));
export function BacklogEmptyState() {
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableCurrentFilters();
  return ___EmotionJSX(Center, null, ___EmotionJSX(EmptyState, {
    body: `There are no Stories${hasClearableFilters ? ' matching your filters' : ''}.`,
    onClickCTA: hasClearableFilters ? clearFilters : undefined,
    image: Image
  }));
}
BacklogEmptyState.displayName = "BacklogEmptyState";