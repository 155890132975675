import Logo from '@clubhouse/assets/png/third-party-logos/plecto_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Plecto() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.PLECTO
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Plecto"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Plecto Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Build customized Shortcut dashboards that visualize your business metrics and KPIs in real-time."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://www.plecto.com/integrations/shortcut/"
  }, "Learn more"), " on how Plecto integrates with Shortcut.")));
}
Plecto.displayName = "Plecto";