import { Field as FinalField } from 'react-final-form';
import { Form } from 'components/shared/Form';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const FieldWrapper = _ref => {
  let {
    /** When true, the label appears in the DOM after the field component. */
    labelFollowsField = false,
    shouldForceErrors,
    labelProps,
    Component,
    ...finalFieldProps
  } = _ref;
  return ___EmotionJSX(FinalField, finalFieldProps, _ref2 => {
    let {
      input,
      meta
    } = _ref2;
    const label = labelProps ? ___EmotionJSX(Form.Label, {
      helpText: labelProps.helpText,
      htmlFor: input.name
    }, labelProps.children) : null;
    const formComponent = ___EmotionJSX(Component, {
      input,
      meta,
      id: input.name
    });
    const [first, second] = labelFollowsField ? [formComponent, label] : [label, formComponent];
    let shouldShowError = Boolean(meta.error);
    if (meta.error?.includes('required')) {
      shouldShowError = Boolean(meta.dirty || meta.touched);
    }
    return ___EmotionJSX(Form.Field, null, first, second, shouldShowError && ___EmotionJSX(Form.Errors, null, ___EmotionJSX(Form.Error, null, meta.error)));
  });
};
FieldWrapper.displayName = "FieldWrapper";