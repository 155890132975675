import Logo from '@clubhouse/assets/png/third-party-logos/marker_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function MarkerIO() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.MARKER
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Marker.IO"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Marker.IO Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Make it easy for your whole team to report and fix bugs easily without leaving your preferred workflow."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://marker.io/shortcut-bug-tracking"
  }, "Learn more"), " on how the", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://www.marker.io"
  }, "Marker.io"), " integration works in Shortcut.")));
}
MarkerIO.displayName = "MarkerIO";