function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ColumnHeader } from './internalComponents/ColumnHeader';
import { ColumnHeaderWrapper } from './internalComponents/ColumnHeaderWrapper';
import { HeaderRow } from './internalComponents/HeaderRow';
import { getColumnSortProps } from './utils/getColumnSortProps';
import { getSortName } from './utils/getSortName';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TableHeaderRow(_ref) {
  let {
    collection,
    columns,
    getGridCellNavigationProps,
    allItems,
    sortColumn,
    sortDirection = 'ascending',
    onSort
  } = _ref;
  return ___EmotionJSX(HeaderRow, {
    role: "row"
  }, columns.map(_ref2 => {
    let {
      name,
      displayName,
      width,
      minWidth,
      maxWidth,
      HeaderComponent,
      headerProps,
      sort,
      frozen
    } = _ref2;
    const isSortable = !!sort;
    const sortName = getSortName({
      name,
      sort
    });
    return ___EmotionJSX(ColumnHeaderWrapper, _extends({
      key: name,
      centered: headerProps?.centered,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
      isSortable: isSortable,
      frozen: frozen
    }, getColumnSortProps({
      isSortable,
      displayName,
      name: sortName,
      sortColumn,
      sortDirection,
      onSort
    }), getGridCellNavigationProps({
      header: 'column'
    })), ___EmotionJSX(ColumnHeader, {
      centered: headerProps?.centered,
      displayName: displayName,
      HeaderComponent: HeaderComponent,
      isSorted: sortColumn === sortName,
      leftPosition: headerProps?.leftPosition,
      collection: collection,
      allItems: allItems,
      name: name,
      sortDirection: sortDirection
    }));
  }));
}
TableHeaderRow.displayName = "TableHeaderRow";