function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { ArchiveMessage } from 'components/shared/ArchiveMessage';
import { useType } from '../Context';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ArchivedMessageBar = _ref => {
  let {
    children,
    name,
    onDelete,
    onUnarchive,
    openDeletionConfirmationDialog
  } = _ref;
  const {
    closeDialog
  } = useConfirmationDialogState();
  const type = useType();
  return ___EmotionJSX(ArchiveMessage, _extends({
    typeName: type
  }, onDelete && openDeletionConfirmationDialog ? {
    onDelete: () => {
      openDeletionConfirmationDialog('delete', {
        onClick: async () => {
          closeDialog();
          try {
            await onDelete();
            addToast({
              kind: 'success',
              timeout: 5000,
              Content: () => ___EmotionJSX(ToastText, null, type, ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, name)), ' ', "deleted.")
            });
          } catch (error) {
            addToast({
              kind: 'alert',
              timeout: 5000,
              Content: () => ___EmotionJSX(ToastText, null, "Unable to delete", ' ', ___EmotionJSX("strong", null, ___EmotionJSX(Emojify, null, name)), ".")
            });
          }
        }
      });
    }
  } : {}, {
    onUnarchive: onUnarchive
  }), children);
};
ArchivedMessageBar.displayName = "ArchivedMessageBar";