import { PAGE_NAMES, usePageName } from 'utils/navigation';
import { useOptInToggleState, useShouldRenderOptInToggle } from '../../hooks';
import { OptInButton } from './OptInButton';
import { OptOutButton } from './OptOutButton';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const OptInToggleButton = () => {
  const {
    value,
    loading
  } = useOptInToggleState();
  const shouldRenderStoriesV2OptInToggle = useShouldRenderOptInToggle();
  const pageName = usePageName();
  const isSupportedPage = pageName === PAGE_NAMES.STORIES;
  if (loading || !shouldRenderStoriesV2OptInToggle || !isSupportedPage) {
    return null;
  }
  return value === false ? ___EmotionJSX(OptInButton, {
    kind: "header"
  }) : ___EmotionJSX(OptOutButton, null);
};