import { jsx as ___EmotionJSX } from "@emotion/react";
export function IntegrationStatus(_ref) {
  let {
    isEnabled,
    subject = 'This integration'
  } = _ref;
  return ___EmotionJSX("div", {
    className: "integration-status-text"
  }, subject, " is", ' ', isEnabled ? ___EmotionJSX("span", {
    className: "integration-status integration-status-active"
  }, "enabled") : ___EmotionJSX("span", {
    className: "integration-status"
  }, "disabled"), ' ', "for your workspace.");
}
IntegrationStatus.displayName = "IntegrationStatus";