import { StoryGroupByTypeFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { storyTypeIconMap, storyTypeNameMap } from 'components/shared/stories/groupBy';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByTypeFragment = StoryGroupByTypeFragmentFragmentDoc;
export const StoryTypeGroupHeader = _ref => {
  let {
    type
  } = _ref;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.StoryType
  }, storyTypeIconMap[type], " ", storyTypeNameMap[type] || 'No Type');
};
StoryTypeGroupHeader.displayName = "StoryTypeGroupHeader";