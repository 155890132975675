import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { deprecatedGetTeamScopeIdForNonReactAccess } from '../hooks/TeamScopeProvider';
const searchParamName = 'team_scope_id';
export const removeTeamScopeFromHref = href => {
  const url = new URL(href);
  url.searchParams.delete(searchParamName);
  return url;
};
export const getTeamScopedURL = (href, teamId, base) => {
  const url = new URL(href, base);
  if (teamId) {
    url.searchParams.set(searchParamName, teamId);
  }
  return url;
};
export const isScopedToTeam = () => {
  return Boolean(deprecatedGetTeamScopeIdForNonReactAccess());
};
const getTeamScopedIdFromHref = href => {
  const url = new URL(href);
  return url.searchParams.get(searchParamName) ?? null;
};
export const isScopedToSameTeam = (hrefA, hrefB) => {
  return getTeamScopedIdFromHref(hrefA) === getTeamScopedIdFromHref(hrefB);
};