import { AvatarWithoutProfile, SIZE } from '@clubhouse/shared/components/Avatar';
import { OwnerSelectOption } from '../OwnerSelectOption';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const getItemConfigForPermission = node => {
  const {
    id,
    displayName,
    displayIcon,
    email,
    state
  } = node;
  return {
    value: id,
    name: ___EmotionJSX(OwnerSelectOption, {
      displayName: displayName,
      isDisabled: state === 'disabled'
    }),
    Icon: () => ___EmotionJSX(AvatarWithoutProfile, {
      id: id,
      email: email,
      size: SIZE.XS,
      fullName: displayName,
      imageUrl: displayIcon?.thumbnailUrl
    }),
    additionalData: {
      permission: node
    }
  };
};