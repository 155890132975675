import "core-js/modules/es.array.push.js";
import LabelController from 'app/client/core/js/controllers/label.js';
import StoryController from 'app/client/core/js/controllers/story.js';
import LabelModel from 'app/client/core/js/models/label.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Label'], LabelController], [['Controller', 'Story'], StoryController], [['Model', 'Label'], LabelModel], [['Controller', 'Label'], LabelController], [['Controller', 'Story'], StoryController], [['Model', 'Label'], LabelModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import classnames from 'classnames';
import _ from 'lodash';
import { purify, sanitizeAndEmojify } from '@clubhouse/shared/utils';
import { getDefaultLabelColor, sortByLowercaseName, useLabels } from 'data/entity/label';
import { getImpliedLabels } from 'data/entity/story';
import * as Is from 'utils/is';
import { jsx as ___EmotionJSX } from "@emotion/react";
const LabelContainer = _ref2 => {
  let {
    labels,
    onClick,
    contextMenu,
    onRemove
  } = _ref2;
  const labelIds = labels.map(l => l.id);
  const {
    labels: allLabels
  } = useLabels();
  const selectedLabels = sortByLowercaseName(allLabels.filter(l => labelIds.indexOf(l.id) > -1));
  return ___EmotionJSX(React.Fragment, null, selectedLabels.map(label => {
    return ___EmotionJSX("div", {
      key: label.id,
      className: classnames('tag', {
        archived: label.archived
      }),
      "data-model": "Label",
      "data-id": label.id,
      "data-on-click": onClick,
      "data-tooltip": label.archived ? 'This Label is archived' : undefined,
      "data-tabindex": true
    }, ___EmotionJSX("span", {
      className: "color fa fa-circle",
      style: {
        color: label.color || getDefaultLabelColor()
      }
    }), ___EmotionJSX("span", {
      dangerouslySetInnerHTML: {
        __html: label.formatted_name ? purify(label.formatted_name) : sanitizeAndEmojify(label.name)
      }
    }), contextMenu && ___EmotionJSX("span", {
      className: "more-items fa fa-ellipsis-h",
      "data-tooltip": "Show Label options",
      "data-context-menu": contextMenu,
      "data-context-menu-right": "-5",
      "data-context-menu-top": "-4",
      id: `label-context-${label.id}-${_.random(label.id)}`
    }), onRemove && ___EmotionJSX("span", {
      className: "delete fa fa-times",
      "data-on-click": onRemove,
      "data-tooltip": "Remove Label"
    }));
  }));
};
export const StoryLabels = _ref3 => {
  let {
    story
  } = _ref3;
  const impliedLabels = getImpliedLabels(story);
  return ___EmotionJSX("div", {
    className: "labels-container"
  }, ___EmotionJSX("div", {
    className: "title"
  }, "Labels"), ___EmotionJSX("div", {
    className: "labels story-labels"
  }, story.readOnly && !story.labels.length ? ___EmotionJSX("p", {
    className: "none-found"
  }, "No Story labels.") : ___EmotionJSX(React.Fragment, null, Is.mobile() ? ___EmotionJSX(LabelContainer, {
    labels: story.labels,
    onRemove: "App.Controller.Story.removeLabel"
  }) : ___EmotionJSX(LabelContainer, {
    labels: story.labels,
    contextMenu: "App.Controller.Label.contextMenuItemsForStory"
  }), !story.readOnly && ___EmotionJSX("div", null, ___EmotionJSX("button", {
    id: 'story-dialog-add-label-dropdown',
    "data-tabindex": true,
    "data-on-click": 'App.Controller.Story.addStoryLabel',
    className: classnames('add-labels', 'action', 'micro', 'flat-white')
  }, ___EmotionJSX("span", {
    className: "fa fa-plus"
  }), "Add Labels...")))), impliedLabels.length > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "title"
  }, "Epic Labels", ' ', ___EmotionJSX("span", {
    className: "fa fa-question-circle",
    "data-tooltip": "Labels attached to the parent Epic of this Story."
  })), ___EmotionJSX("div", {
    className: "labels implied-labels"
  }, ___EmotionJSX(LabelContainer, {
    labels: impliedLabels,
    contextMenu: "App.Controller.Label.contextMenuItemsForEpic"
  }))));
};
StoryLabels.displayName = "StoryLabels";