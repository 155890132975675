import { useCallback } from 'react';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxInteractions } from './ComboboxInteractions';
import { ComboboxMenu } from './ComboboxMenu';
import { ComboboxProvider } from './ComboboxProvider';
import { ComboboxCreateItemFromInput, ComboboxItem, ComboboxLabel, ComboboxLabelItem, ComboboxTitleItem, ContentContainer, Divider, ListBox, ListLoading, LoadingItem, UnstyledComboboxItem } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Combobox = _ref => {
  let {
    children,
    onSelect,
    onClose
  } = _ref;
  const handleEvent = useCallback(_ref2 => {
    let {
      type,
      payload
    } = _ref2;
    switch (type) {
      case 'select':
        onSelect(payload);
        break;
      case 'close':
        onClose();
        break;
    }
  }, [onSelect, onClose]);
  return ___EmotionJSX(ComboboxProvider, {
    onEvent: handleEvent
  }, ___EmotionJSX(ComboboxInteractions, null, children));
};
Combobox.displayName = "Combobox";
Combobox.Menu = ComboboxMenu;
Combobox.Label = ComboboxLabel;
Combobox.Input = ComboboxInput;
Combobox.List = ListBox;
Combobox.TitleItem = ComboboxTitleItem;
Combobox.LabelItem = ComboboxLabelItem;
Combobox.SelectableItem = ComboboxItem;
Combobox.SelectableItemUnstyled = UnstyledComboboxItem;
Combobox.CreateItemFromInput = ComboboxCreateItemFromInput;
Combobox.ListLoading = ListLoading;
Combobox.LoadingItem = LoadingItem;
Combobox.ContentContainer = ContentContainer;
Combobox.Divider = Divider;