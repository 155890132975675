function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { jsx as ___EmotionJSX } from "@emotion/react";
const SpaceChangesSavedToast = _ref => {
  let {
    name,
    onClose,
    undoChanges
  } = _ref;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: 8
  }, ___EmotionJSX(ToastText, null, "Saved updates to ", ___EmotionJSX("strong", null, name)), ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: 5
  }, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.SMALL,
    onClick: () => undoChanges().then(() => {
      onClose();
    })
  }, "Undo")));
};
SpaceChangesSavedToast.displayName = "SpaceChangesSavedToast";
export const useSpaceSaveToasts = () => {
  const {
    addToast,
    removeToast
  } = useToast();
  const showSpaceSaveSuccessToast = useCallback(args => {
    return addToast({
      timeout: 16000,
      kind: 'success',
      Content: _ref2 => {
        let {
          onClose
        } = _ref2;
        return ___EmotionJSX(SpaceChangesSavedToast, _extends({}, args, {
          onClose: onClose
        }));
      }
    });
  }, [addToast]);
  const showSpaceSaveErrorToast = useCallback(message => {
    return addToast({
      timeout: 3000,
      kind: 'warning',
      Content: () => ___EmotionJSX(ToastText, null, message)
    });
  }, [addToast]);
  return {
    showSpaceSaveErrorToast,
    showSpaceSaveSuccessToast,
    removeToast
  };
};