import { FilterField } from '@clubhouse/shared/components/FilterField';
import { SearchInput } from '@clubhouse/shared/components/SearchInput';
import { useIsMobile } from '@clubhouse/shared/hooks';
import { useFilterState } from './hooks/useFilterState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const QueryFilter = _ref => {
  let {
    entityType,
    placeholder
  } = _ref;
  const [query, setQuery] = useFilterState({
    paramName: 'query',
    paramType: 'string',
    defaultValue: ''
  });
  const isMobile = useIsMobile();
  placeholder = isMobile ? `Search...` : `Search ${entityType.plural}...`;
  return ___EmotionJSX(FilterField, {
    label: "Filter"
  }, ___EmotionJSX(SearchInput, {
    width: isMobile ? 'auto' : 190,
    value: query,
    placeholder: placeholder,
    onChange: setQuery,
    onChangeDebounceMs: 300
  }));
};
QueryFilter.displayName = "QueryFilter";