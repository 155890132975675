function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { DisplayIconFragmentFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { BrandIcon, CustomFieldIcon, PriorityIcon, SeverityIcon, ShortcutFieldIcon, StatusIcon } from '@useshortcut/shapes-ds';
import { gql } from '@clubhouse/datalayer';
import { DisplayIcon as DisplayIconComponent } from '@clubhouse/shared/components/DisplayIcon';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { getColor } from '../Color';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DisplayIconFragment = DisplayIconFragmentFragmentDoc;
const isInArray = (str, arr) => {
  return !!str && Boolean(arr.find(s => s === str));
};
export const DisplayIcon = _ref => {
  let {
    iconIdentifier,
    color,
    size,
    fill,
    ...props
  } = _ref;
  if (isInArray(iconIdentifier, StatusIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      fill: fill,
      width: size
    }, ___EmotionJSX(StatusIcon, {
      icon: iconIdentifier
    }));
  } else if (isInArray(iconIdentifier, DisplayIconComponent.validIconProp)) {
    return ___EmotionJSX(DisplayIconComponent, _extends({}, props, {
      iconIdentifier: iconIdentifier,
      fill: fill ?? getColor(color),
      size: size
    }));
  } else if (isInArray(iconIdentifier, SeverityIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: size
    }, ___EmotionJSX(SeverityIcon, {
      icon: iconIdentifier
    }));
  } else if (isInArray(iconIdentifier, PriorityIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: size
    }, ___EmotionJSX(PriorityIcon, {
      icon: iconIdentifier
    }));
  } else if (isInArray(iconIdentifier, CustomFieldIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      fill: getColor(color),
      width: size
    }, ___EmotionJSX(CustomFieldIcon, {
      icon: iconIdentifier
    }));
  } else if (isInArray(iconIdentifier, ShortcutFieldIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: size
    }, ___EmotionJSX(ShortcutFieldIcon, {
      icon: iconIdentifier
    }));
  } else if (isInArray(iconIdentifier, BrandIcon.validIconProp)) {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: size
    }, ___EmotionJSX(BrandIcon, {
      icon: iconIdentifier
    }));
  } else {
    return null;
  }
};