import { PORTAL_IDS, getPortalMountNode } from '@clubhouse/shared/utils/portals';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import View from 'app/client/core/js/modules/view';
import { CreateTeamForm } from 'pages/teams/CreateTeamForm';
import { PAGE_NAMES, matchPathForPage } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
const openCreateDialog = function () {
  let {
    onCreate,
    triggerNode = document.getElementById('add-new-button')
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const handleClose = () => {
    unmountComponent();
  };
  const {
    unmountComponent
  } = View.renderComponent({
    componentKey: 'createNewGroup',
    mountNode: getPortalMountNode(PORTAL_IDS.EDITOR_ROOT),
    component: ___EmotionJSX(CreateTeamForm, {
      onClose: handleClose,
      onCreate: onCreate,
      triggerNode: triggerNode
    })
  });
};
export const createStoryInGroup = () => {
  const match = matchPathForPage(window.location.pathname, {
    pageName: PAGE_NAMES.SETTINGS_TEAM
  }) ?? matchPathForPage(window.location.pathname, {
    pageName: PAGE_NAMES.TEAM
  });
  AddNewStoryController.updateState({
    group_id: match?.params?.id
  });
  AddNewStoryController.render({
    context: 'team'
  });
};
export default {
  openCreateDialog,
  createStoryInGroup
};