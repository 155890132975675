import "core-js/modules/es.array.push.js";
import StoryController from 'app/client/core/js/controllers/story.js';
import Iteration from 'app/client/core/js/modules/iteration.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController], [['Iteration'], Iteration], [['Tooltip'], Tooltip], [['Controller', 'Story'], StoryController], [['Iteration'], Iteration], [['Tooltip'], Tooltip]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useMemo } from 'react';
import { useOpenSelect } from '@clubhouse/shared/components/Select/useOpenSelect';
import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { IterationSelect } from 'components/shared/IterationSelect';
import { IterationStatusIcon } from 'components/shared/IterationStatusIcon';
import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { useIterations } from 'data/entity/iteration';
import { setIteration } from 'data/entity/story';
import { getLoggedInUserPermission } from 'data/entity/user';
import { isReadOnly } from 'utils/is';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Target = _ref2 => {
  let {
    story,
    iteration
  } = _ref2;
  const openMenu = useOpenSelect();
  useCommandBarCallback('openIterationSelector', openMenu);
  return ___EmotionJSX("div", {
    "data-testid": `iteration-select-${story?.id}`,
    className: "attribute editable-attribute attribute-has-toggle story-iteration has-icon-on-left",
    "data-tabindex": true
  }, ___EmotionJSX("span", {
    className: "custom-icon"
  }, ___EmotionJSX(IterationStatusIcon, {
    iteration: iteration,
    width: "25px"
  })), ___EmotionJSX("span", {
    "data-testid": "attribute-name",
    className: "name"
  }, "Iteration"), ___EmotionJSX("span", {
    "data-testid": "attribute-value",
    className: "value"
  }, iteration ? iteration.name : ___EmotionJSX("em", null, "None")), !!story.previous_iteration_ids?.length && ___EmotionJSX("div", {
    className: "previous-iterations-badge",
    "data-tooltip": true,
    "data-tooltip-fn": "App.Controller.Story.previousIterationsTooltip"
  }, "+", story.previous_iteration_ids.length));
};
Target.displayName = "Target";
export const StoryIteration = _ref3 => {
  let {
    story,
    iteration
  } = _ref3;
  const {
    iterations
  } = useIterations();
  const [selectedValue, onChange] = useInlineEditing(iteration?.id, iterationId => new Promise((resolve, reject) => {
    setIteration(story, iterationId, (err, story) => {
      if (err) reject(err);else resolve(story);
    });
  }));
  const readOnly = isReadOnly(getLoggedInUserPermission());

  // biome-ignore lint/correctness/useExhaustiveDependencies: We use the iteration ids as cache key to avoid re-rendering when objects (but not the actual values) change.
  const cachedIterations = useMemo(() => iterations, [String(iterations.map(i => i.id))]);
  return ___EmotionJSX(IterationSelect, {
    isReadOnly: readOnly,
    groupId: story.group_id,
    iterationId: selectedValue,
    iterations: cachedIterations,
    onChange: onChange
  }, ___EmotionJSX(Target, {
    story: story,
    iteration: iteration
  }));
};
StoryIteration.displayName = "StoryIteration";