import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { convertAccentedCharacters } from '@clubhouse/shared/utils';
import Async from '../modules/async';
import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import EmailModel from './email';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import Iterate from '../modules/iterate';
import Log from '../modules/log';
import LoginController from '../../../login/js/controllers/login';
import LogoutController from '../controllers/logout';
import MessageController from '../controllers/message';
import OrganizationModel from './organization';
import OrganizationProfileModel from './organizationProfile';
import PaymentPlan2Model from './paymentPlan2';
import PermissionModel from './permission';
import ProfileModel from './profile';
import StoryModel from './story';
import Url from '../modules/url';
import User from '../modules/user';
import UserModel from './user';
import Utils from '../modules/utils';
const exports = {};
exports.Promises = {};

/*

Example Current User entity:

{
  "deactivated": false,
  "email": "sdf@asfdasf.com",
  "permissions": [{
      "role": "member",
      "email": "sdf@asfdasf.com",
      "disabled": false,
      "google_token": null,
      "gravatar_hash": "e1a1f4712360d3a260494fcc6bb8c465",
      "has_notion_token": false,
      "organization": {
          "signup_domain": null,
          "url_slug": "sadfsadfsad",
          "beta": false,
          "disabled": false,
          "name": "sadfsadfsad",
          "locked_out": false,
          "type": "",
          "public_url": null,
          "id": "572aad10-c2b3-4972-92bf-e40eba68664b",
          "estimate_scale": {
              "estimates": [{
                  "id": 500000006,
                  "value": 0
              }, {
                  "id": 500000005,
                  "value": 1
              }, {
                  "id": 500000007,
                  "value": 2
              }, {
                  "id": 500000008,
                  "value": 4
              }, {
                  "id": 500000004,
                  "value": 8
              }],
              "id": 500000003,
              "name": "Default"
          },
          "location": null
      },
      "email_notification_level": "important-events",
      "updated_at": "2016-05-05T15:38:56Z",
      "google_token_expiration_date": null,
      "id": "572b6910-e2ff-4905-8c3f-e0017d5be0ab",
      "created_at": "2016-05-05T15:38:56Z"
  }],
  "two_factor_auth": false,
  "two_factor_auth_activated": false,
  "story_dialog_expanded": false,
  "name": "Šadfasdf Ásdfsadf",
  "username": "sadfasdfasdfsadf",
  "id": "572b6910-40fb-4823-bee0-c72b579b4b5e",
  "emails": [{
      "id": "572b6910-86d8-4b5d-849a-983177738b6f",
      "email_address": "sdf@asfdasf.com",
      "created_at": "2016-05-05T15:38:56Z",
      "updated_at": "2016-05-05T15:38:56Z",
      "confirmed": true,
      "primary": true
  }]
}

*/

Collection.create('User', exports);
exports.isValid = user => {
  return Boolean(user && user.id && user.name);
};
exports.normalize = user => {
  Iterate.each(user.profiles, profile => {
    // Handle case where we're using internal-only DB locally and missing non-Clubhouse companies.
    if (!_.get(profile, 'company.id')) {
      return;
    }
    Iterate.each(profile.permissions, permission => {
      permission.user_id = user.id;
      permission.is_owner = Boolean(profile.is_owner);
      PermissionModel.syncEmailPermissions(permission);
      PermissionModel.updateIfValid(permission);
    });
    const company = profile.company || {};
    _normalizeCompanyUsingProfile(profile, company);
    CompanyModel.updateIfValid(company);
  });
  Iterate.each(user.emails, email => {
    EmailModel.updateIfValid(email);
  });
  user.organization_ids = _.compact(_.uniq((user.organization_ids || []).concat([Globals.get('organizationID') || OrganizationModel.getCurrentID()])));
  user.name = user.name || 'No Name Provided';
  user.lowercase_name = convertAccentedCharacters(user.name.toLowerCase());
};
function _normalizeCompanyUsingProfile(profile, company) {
  const {
    permissions = []
  } = profile;
  const companyOrganizations = Array.isArray(company.organizations) ? company.organizations : [];
  const profileOrganizations = permissions.map(_ref => {
    let {
      organization
    } = _ref;
    return organization;
  });
  const companyOrgIds = new Set(companyOrganizations.map(org => org.id));
  company.organizations = [...companyOrganizations, ...profileOrganizations.filter(org => !companyOrgIds.has(org.id))];
}
exports.mapIDsToUsers = ids => {
  return _.compact(_.map(ids, id => {
    return exports.getById(id);
  }));
};
exports.allActive = () => {
  return exports.filter({
    disabled: false
  });
};
exports.allDisabled = () => {
  return exports.filter('disabled');
};
exports.hasSetPassword = user => {
  if (!Object.prototype.hasOwnProperty.call(user, 'has_set_password')) {
    return true;
  }
  return user.has_set_password === true;
};
exports.allOtherActiveUsers = () => {
  const user = User.getCurrentUser();
  return _.sortBy(_.reject(exports.allActive(), {
    id: user.id
  }), 'lowercase_name');
};
exports.getUserProperties = () => {
  const user = User.getCurrentUser();
  return user.properties || {};
};
exports.getDefaultEmail = user => {
  user = user || User.getCurrentUser();
  if (!user) return null;
  return _.find(user.emails, 'primary');
};
exports._getPreferredEmail = () => {
  return Is.withinOrg() ? EmailModel.getCurrentUsersPrimaryEmail() : exports.getDefaultEmail();
};
exports.getPreferredEmailAddress = () => {
  return _.get(exports._getPreferredEmail(), 'email_address');
};
exports.getRoleOfCurrentUser = () => {
  const user = exports.getLoggedInUserPermission();
  return _.get(user, 'role');
};

// Move to members model once created for refactor with users/profiles/members
exports.fetchAll = callback => {
  let endpoint = '/api/private/members';
  const permission = exports.getLoggedInUserPermission();
  if (Is.ownerOnly(permission)) {
    endpoint = '/api/private/owner/workspaces2/' + permission.organization.id + '/members';
  }
  Backend.get(endpoint, {
    onComplete: res => {
      exports.fetchAllHandler(res, callback);
    }
  });
};
exports.fetchAllHandler = (res, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (res.error) {
    MessageController.error(res.message, {
      id: res.tag || undefined
    });
    callback(res);
    return;
  }
  ProfileModel.fetchAllHandler(res, () => {
    exports.trigger('bulkStart');
    const currentUserProfile = OrganizationProfileModel.getOrgProfileForLoggedInUser();
    Iterate.each(res, profile => {
      if (exports.isValid(profile)) {
        exports.update(profile);
        profile = exports.getById(profile.id);
        if (profile.id === currentUserProfile.id) {
          if (exports.handleRemoteUpdatesToCurrentUser(profile, currentUserProfile) === false) {
            return false;
          }
        }
      }
    });
    exports.trigger('bulkEnd');
    exports.trigger('fetched');
    callback();
  });
};
exports.handleRemoteUpdatesToCurrentUser = (user, currentUser) => {
  if (user.disabled === true) {
    Log.log('User is disabled');
    LogoutController.logoutAndRedirect();
    return false;
  }
  if (currentUser.role !== user.role) {
    currentUser.role = user.role;
    User.setCurrentUser(currentUser);
    setTimeout(() => {
      Event.trigger('CurrentUserRoleChanged');
    }, 250);
  }
};
exports.fetchCurrentUserWithoutRedirect = callback => {
  exports.fetchCurrentUser(callback, true);
};
exports.fetchCurrentUser = (callback, preventRedirect) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.get('/api/private/user', {
    onComplete: user => {
      const notRouteException = Url.notRouteException();
      if (exports.isValid(user)) {
        exports.update(user);
        if (user.locked_out) {
          return LogoutController.logoutAndRedirect({
            messageCode: 1
          });
        }
        const org = OrganizationModel.getCurrent();
        const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser(_.get(org, 'id'), exports.getLoggedInUserProfiles(user));
        if (_hasNoAccessToOrg(org, profile, user)) {
          if (notRouteException) {
            return Utils.redirect('/organizations');
          } else {
            User.setCurrentUser(exports.getById(user.id));
            return callback();
          }
        }

        // TODO: Why do we do this here and not as part of noRouteException?
        // Is it because /invites is a special-case because you can already be logged in?
        const currentPage = Url.getCurrentPage();
        if ('invites' === currentPage) {
          User.setCurrentUser(exports.getById(user.id));
          return callback();
        }
        if (['invite-link', 'contacts'].indexOf(currentPage) !== -1) {
          User.setCurrentUser(exports.getById(user.id));
          return callback();
        }
        const notCurrentOrg = org.url_slug !== Url.getCurrentSlug();
        const notTestEnv = !Is.testEnvironment();
        if (notTestEnv && notRouteException && notCurrentOrg) {
          return Utils.redirectToSlug(org.url_slug);
        }
        User.setCurrentUser(exports.getById(user.id));
        callback();
        // TODO: We will need to check here to only automatically login if their
        // SSO Policy for their organization is "Required for all". See ch43222.
      } else if (notRouteException && !_shouldPreventRedirect(preventRedirect)) {
        LoginController.trySSOLoginOnlyIfRequired(Url.getCurrentSlug(), () => {
          _ifNoUserIsFound(preventRedirect, callback);
        });
      } else {
        _ifNoUserIsFound(preventRedirect, callback);
      }
    }
  });
};
function _ifNoUserIsFound(preventRedirect, callback) {
  User.setCurrentUser(null);
  if (!_shouldPreventRedirect(preventRedirect)) {
    LogoutController.logoutAndRedirect();
    return false;
  }
  callback();
}
function _shouldPreventRedirect(preventRedirect) {
  // preventRedirect is only used on logged out pages, where we
  // just want to check if the user is logged in or not.
  // aaaand edit profile
  return preventRedirect === true;
}

// If the current org isn't found, the user is disabled, the users permission on the org
// is disabled, or this user only belongs to a disabled org (or multiple disabled orgs)...
// We need to just redirect them to the /organizations page here.
function _hasNoAccessToOrg(org, profile, user) {
  const permission = exports.getOrgPermissionFromProfile(profile, _.get(org, 'id'));

  // These are invariants, no one can access if these things are true
  if (!org || permission?.disabled || user?.disabled) {
    return true;
  }

  // Owners are special, they need to log into disabled orgs to manage billing
  if (!permission || !Is.ownerOnly(permission)) {
    return org?.disabled || profile?.company?.disabled || profile?.company?.locked_out;
  }

  // If you ran the gauntlet and survived, congrats!
  return false;
}
exports.startTwoFactorSignup = (password, callback) => {
  Backend.post('/api/private/two-factor-auth', {
    data: {
      password
    },
    onComplete: callback
  });
};
exports.sanitizeTwoFactorCode = (code // Guard against user including spaces "123 456"
) => {
  return code.replace(/[^0-9]/g, '');
};
exports.confirmTwoFactor = (code, callback) => {
  Backend.put('/api/private/two-factor-auth/confirm', {
    data: {
      two_factor_code: exports.sanitizeTwoFactorCode(code)
    },
    onComplete: callback
  });
};
exports.disableTwoFactor = (data, callback) => {
  data.two_factor_code = exports.sanitizeTwoFactorCode(data.two_factor_code);
  Backend.delete('/api/private/two-factor-auth', {
    data,
    onComplete: callback
  });
};

// TODO Move to member model, with member type instead of profile
exports.disableUser = (profile, callback, options) => {
  options = options || {};
  let endpoint = '/api/private/members/' + profile.id + '/disable';
  const orgId = profile.org_id || profile.organization_id;
  if (Is.owner(exports.getLoggedInUserPermission(orgId))) {
    endpoint = '/api/private/owner/workspaces2/' + orgId + '/members/' + profile.id + '/disable';
  }
  Backend.put(endpoint, _.assign({
    onComplete: (res, xhr) => {
      _handleUserChangeResponse(res, xhr, callback);
    }
  }, options));
};

// TODO Move to member model, with member type instead of profile
exports.restoreUser = (profile, callback, options) => {
  options = options || {};
  let endpoint = '/api/private/members/' + profile.id + '/enable';
  const orgId = profile.org_id || profile.organization_id;
  if (Is.owner(exports.getLoggedInUserPermission(orgId))) {
    endpoint = '/api/private/owner/workspaces2/' + orgId + '/members/' + profile.id + '/enable';
  }
  Backend.put(endpoint, _.assign({
    onComplete: (res, xhr) => {
      _handleUserChangeResponse(res, xhr, callback);
    }
  }, options));
};

// TODO Move to member model, with member type instead of profile
exports.updateRole = (profile, role, callback, options) => {
  options = options || {};
  let endpoint = '/api/private/members/' + profile.id + '/role';
  const orgId = profile.org_id || profile.organization_id;
  if (Is.owner(exports.getLoggedInUserPermission(orgId))) {
    endpoint = '/api/private/owner/workspaces2/' + orgId + '/members/' + profile.id + '/role';
  }
  Backend.put(endpoint, _.assign({
    data: {
      role
    },
    onComplete: (res, xhr) => {
      _handleUserChangeResponse(res, xhr, callback);
    }
  }, options));
};
function _handleUserChangeResponse(res, xhr, callback) {
  // We also request a new payment plan with the billable users count updated
  Async.eachInParallelThenWithShortCircuiting([exports.fetchAll, PaymentPlan2Model.fetch], err => {
    if (err) {
      exports.defaultErrorHandler(res, callback);
    } else {
      exports.defaultNoResponseHandler(res, xhr, callback);
    }
  });
}
exports.updateEmailPreference = (level, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  ProfileModel.updateProfile({
    email_notification_level: level
  }, (err, res) => {
    callback(err, res);
  });
};
exports.updateUserProperties = (properties, callback) => {
  const user = User.getCurrentUser();
  exports.updateUser({
    properties: _.assign(user.properties, properties)
  }, callback);
};
exports.updateUser = (data, callback) => {
  Backend.put('/api/private/user', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.deleteUser = callback => {
  Backend.delete('/api/private/user', {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.dismissReferralAction = callback => {
  exports.updateUserProperties({
    ignore_referrals: true
  }, callback);
};
exports.dismissTrainingAction = callback => {
  exports.updateUserProperties({
    ignore_training: true
  }, callback);
};
exports.updateSkinTone = (skinTone, callback) => {
  exports.updateUserProperties({
    skin_tone: skinTone
  }, callback);
};

// Below are for the profiles for the current logged in user.
// These are used for org specific details like position and if it's
// been set as default, but primarily for org specific info on the current user.
// This is is a different format to "member" maps that represent a logged out
// user, and are currently managed in the Profile model.

exports.getPermissionFromOrg = orgID => {
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser(orgID);
  if (!profile) return null;
  return exports.getDefaultPermissionFromProfile(profile) || exports.getOrgPermissionFromProfile(profile) || (profile.permissions || [])[0] || null;
};
exports.getDefaultPermissionFromProfile = profile => {
  return (profile?.permissions || []).find(permission => permission.default);
};
exports.getOrgPermissionFromProfile = (profile, orgID) => {
  return (profile?.permissions || []).find(p => p.organization.id === orgID);
};
exports.getLoggedInUserProfiles = user => {
  return _.get(user || User.getCurrentUser(), 'profiles') || [];
};
exports.getCompanyProfileForLoggedInUser = companyId => {
  return _.find(exports.getLoggedInUserProfiles(), profile => {
    return _.get(profile, 'company.id') === companyId;
  }) || {};
};
exports.isLoggedInProfile = (profile, orgID) => {
  return _.get(profile, 'id') === exports.getLoggedInUserPermissionID(orgID);
};
exports.getAllLoggedInProfileDetails = () => {
  const user = User.getCurrentUser();
  const orgProfile = OrganizationProfileModel.getOrgProfileForLoggedInUser();
  const permission = exports.getDefaultPermissionFromProfile(orgProfile);
  if (!permission) {
    return {};
  }
  return {
    // General information
    id: permission.id,
    org_profile_id: orgProfile.id,
    role: permission.role,
    // Profile information
    email_address: _.get(UserModel.getDefaultEmail(user), 'email_address'),
    email_id: orgProfile.email_id,
    email_notification_level: orgProfile.email_notification_level,
    mention_name: orgProfile.mention_name,
    is_owner: orgProfile.is_owner,
    // Profile information that can be overwritten
    display_icon: orgProfile.display_icon || user.display_icon,
    // Profile information that can be overridden in the future
    name: orgProfile.name || user.name
  };
};
exports.getLoggedInUserPermission = orgID => {
  orgID = orgID || Globals.get('organizationID') || OrganizationModel.getCurrentID();
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser(orgID);
  const permission = _.find(_.get(profile, 'permissions'), permission => {
    return _.get(permission, 'organization.id') === orgID;
  }) || {};
  return permission;
};
exports.getLoggedInUserPermissionID = orgID => {
  return exports.getLoggedInUserPermission(orgID).id;
};
exports.fetchLoggedInUserProfiles = callback => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.get('/api/private/user/profiles', {
    onComplete: res => {
      if (res.error) {
        exports.defaultErrorHandler(res, callback);
      } else {
        const user = User.getCurrentUser();
        user.profiles = res;
        exports.normalize(user);
        callback(null);
      }
    }
  });
};
exports.fetchActiveStoriesForCurrentUser = callback => {
  const me = ProfileModel.getCurrentUserProfileDetails();
  exports.fetchActiveStoriesForUser(me, callback);
};
exports.fetchActiveStoriesForUser = (profile, callback) => {
  const query = {
    archived: false,
    owner_ids: [_.get(profile, 'id')],
    workflow_state_types: ['backlog', 'unstarted', 'started']
  };
  StoryModel.searchStories(query, callback);
};
exports.fetchRecentlyCompletedStoriesForCurrentUser = callback => {
  const me = ProfileModel.getCurrentUserProfileDetails();
  StoryModel.fetchRecentlyCompletedStoriesForProfiles([me], callback);
};
exports.getReportsGroupBy = () => {
  return User.getCurrentUser().reports_group_by || 'day';
};
exports.updateReportsGroupBy = value => {
  const data = {
    reports_group_by: value
  };
  exports.update(data);
  return exports.Promises.updateUser(data);
};
const RECENTLY_CREATED_USER_EXPIRATION_TIME_MS = 24 * 60 * 60 * 1000; // 24 hours

exports.isRecentlyCreatedUser = user => {
  const now = Date.now();
  const createdAt = new Date((user || User.getCurrentUser()).created_at).getTime();
  return now - createdAt < RECENTLY_CREATED_USER_EXPIRATION_TIME_MS;
};
exports.Promises.isGDPR = () => new Promise(resolve => Backend.get(`/api/private/region`, {
  onComplete: data => {
    const {
      is_restricted: isRestricted
    } = data;
    resolve(isRestricted);
  },
  onError: err => {
    Log.error(new Error('Failed to fetch from "/api/private/region"'), {
      errorInfo: JSON.stringify(err)
    });
    resolve(true);
  }
}));
exports.Promises.deleteUser = () => {
  new Promise(resolve => Backend.delete('/api/private/user', {
    onSuccess: data => {
      resolve(data);
      LogoutController.logoutAndRedirect();
    },
    onError: err => {
      Log.error(new Error('Failed to delete user"'), {
        errorInfo: JSON.stringify(err)
      });
      resolve(true);
    }
  }));
};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.updateUser = BaseUtils.promisify(exports.updateUser);
export { exports as default };