import { MutateStartDateFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicStartDateFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableDatePicker } from 'components/shared/table/base';
import { useMutation } from 'gql';
import { jsx as ___EmotionJSX } from "@emotion/react";
const EpicStartDateFieldFragment = EpicStartDateFieldFragmentFragmentDoc;
const MUTATE_START_DATE_FIELD = MutateStartDateFieldDocument;
function StartDateFieldComponent(_ref) {
  let {
    entity: {
      id,
      deadline,
      plannedStartDate
    }
  } = _ref;
  const [save] = useMutation(MUTATE_START_DATE_FIELD);
  const handleChange = useCallback(date => {
    save({
      variables: {
        id,
        input: {
          plannedStartDate: date
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        epicUpdate: {
          __typename: 'EpicPayload',
          epic: {
            __typename: 'Epic',
            id,
            plannedStartDate: date,
            deadline
          }
        }
      }
    });
  }, [deadline, id, save]);
  return ___EmotionJSX(TableDatePicker, {
    maxDate: deadline,
    onChange: handleChange,
    value: plannedStartDate
  });
}
StartDateFieldComponent.displayName = "StartDateFieldComponent";
export const StartDateField = () => ({
  name: 'plannedStartDate',
  displayName: 'Start Date',
  headerProps: {
    centered: true
  },
  sort: true,
  Component: StartDateFieldComponent,
  LoadingComponent: () => ___EmotionJSX(Center, null, ___EmotionJSX(SkeletonRectangle, {
    width: 40
  })),
  width: 130
});