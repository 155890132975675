import { Button } from '@clubhouse/shared/components/Button';
import { FilterField } from '@clubhouse/shared/components/FilterField';
import { useClearableFilters } from './useClearableFilters';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ClearAllFiltersButton(_ref) {
  let {
    filters
  } = _ref;
  const {
    hasClearableFilters,
    clearFilters
  } = useClearableFilters(filters);
  if (!hasClearableFilters) return null;
  return ___EmotionJSX(FilterField, {
    label: ""
  }, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    size: Button.SIZE.MEDIUM,
    onClick: clearFilters
  }, "Clear Filters"));
}
ClearAllFiltersButton.displayName = "ClearAllFiltersButton";