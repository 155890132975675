import "core-js/modules/es.array.push.js";
import InviteModel from 'app/client/core/js/models/invite.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Invite'], InviteModel], [['Model', 'Invite'], InviteModel]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@useshortcut/shapes-ds';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function InvitedUsers(_ref2) {
  let {
    invites,
    isReadOnly,
    showResendButton
  } = _ref2;
  return ___EmotionJSX("div", {
    className: isReadOnly ? 'read-only' : ''
  }, invites.map(invite => ___EmotionJSX("div", {
    key: invite.id,
    className: "existing-invite",
    "data-model": "Invite",
    "data-id": invite.id
  }, ___EmotionJSX("div", {
    className: "person-icon"
  }, ___EmotionJSX(Icon, {
    icon: "User"
  })), ___EmotionJSX("a", {
    href: `mailto:${invite.email}`,
    className: "invite-email-link"
  }, invite.email), ___EmotionJSX("span", {
    className: "tagged blue"
  }, upperFirst(invite.role)), ___EmotionJSX("span", {
    className: "invited-at"
  }, "Invited ", moment(invite.created_at).fromNow()), ___EmotionJSX("span", {
    className: "align-center"
  }, !isReadOnly && ___EmotionJSX(React.Fragment, null, showResendButton && ___EmotionJSX(UnstyledButton, {
    className: "resend-invite",
    "data-on-click": "resendInvite",
    "data-tooltip": "Re-send invite"
  }, ___EmotionJSX(Icon, {
    icon: "Sync"
  })), ___EmotionJSX(UnstyledButton, {
    className: "delete-invite",
    "data-on-click": "deleteInvite",
    "data-tooltip": "Delete invite"
  }, ___EmotionJSX(Icon, {
    icon: "Trash"
  })))))));
}
InvitedUsers.displayName = "InvitedUsers";