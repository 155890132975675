function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useCallback } from 'react';
import { DockedDoc, isDoc } from './DockedDoc';
import { DockedEpic, isEpic } from './DockedEpic';
import { DockedIteration, isIteration } from './DockedIteration';
import { DockedKeyResult, isKeyResult } from './DockedKeyResult';
import { DockedObjective, isObjective } from './DockedObjective';
import { DockedStory, isStory } from './DockedStory';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TypenameFactory = _ref => {
  let {
    node,
    ...rest
  } = _ref;
  if (isStory(node)) return ___EmotionJSX(DockedStory, _extends({}, rest, {
    node: node
  }));
  if (isEpic(node)) return ___EmotionJSX(DockedEpic, _extends({}, rest, {
    node: node
  }));
  if (isIteration(node)) return ___EmotionJSX(DockedIteration, _extends({}, rest, {
    node: node
  }));
  if (isObjective(node)) return ___EmotionJSX(DockedObjective, _extends({}, rest, {
    node: node
  }));
  if (isKeyResult(node)) return ___EmotionJSX(DockedKeyResult, _extends({}, rest, {
    node: node
  }));
  if (isDoc(node)) return ___EmotionJSX(DockedDoc, _extends({}, rest, {
    node: node
  }));
  return null;
};
export const GenericDockedItem = _ref2 => {
  let {
    globalId,
    node,
    index,
    onUnpin
  } = _ref2;
  const unpin = useCallback(() => {
    onUnpin({
      id: globalId,
      loggingContext: {
        typename: node?.__typename ?? '__UNAVAILABLE_TYPE__'
      }
    });
  }, [globalId, node?.__typename, onUnpin]);
  return ___EmotionJSX(TypenameFactory, {
    node: node,
    index: index,
    onUnpinClicked: unpin
  });
};
GenericDockedItem.displayName = "GenericDockedItem";