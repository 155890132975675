import { useEffect } from 'react';
import { isEventInBounds } from './useClickOutside';
export const useMouseOutside = function (refs, callback) {
  let attachHandler = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  useEffect(() => {
    const handleMouseOut = e => {
      if (refs.every(ref => !isEventInBounds(e, ref, 10))) {
        callback();
      }
    };
    if (attachHandler) {
      document.addEventListener('mouseout', handleMouseOut);
    } else {
      document.removeEventListener('mouseout', handleMouseOut);
    }
    return () => {
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, [attachHandler, callback, refs]);
};