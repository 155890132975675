import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { ContextMenuItem } from '../ContextMenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
function TaskContextMenuItem(_ref) {
  let {
    onClick,
    icon,
    tooltip
  } = _ref;
  return ___EmotionJSX(Tooltip, {
    content: tooltip
  }, ___EmotionJSX(ContextMenuItem, {
    onClick: onClick
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: "15"
  }, ___EmotionJSX(Icon, {
    icon: icon
  }))));
}
TaskContextMenuItem.displayName = "TaskContextMenuItem";
export function UpdateTaskOwnersContextMenuItem(_ref2) {
  let {
    onClick
  } = _ref2;
  return ___EmotionJSX(TaskContextMenuItem, {
    icon: "User",
    tooltip: "Add or remove owners to this task",
    onClick: onClick
  });
}
UpdateTaskOwnersContextMenuItem.displayName = "UpdateTaskOwnersContextMenuItem";
export function ConvertTaskToStoryContextMenuItem(_ref3) {
  let {
    onClick
  } = _ref3;
  return ___EmotionJSX(TaskContextMenuItem, {
    icon: "Story",
    tooltip: "Convert to Story",
    onClick: onClick
  });
}
ConvertTaskToStoryContextMenuItem.displayName = "ConvertTaskToStoryContextMenuItem";
export function DeleteTaskContextMenuItem(_ref4) {
  let {
    onClick
  } = _ref4;
  return ___EmotionJSX(TaskContextMenuItem, {
    icon: "Trash",
    tooltip: "Delete this Task",
    onClick: onClick
  });
}
DeleteTaskContextMenuItem.displayName = "DeleteTaskContextMenuItem";