import { FilterField } from '@clubhouse/shared/components/FilterField';
import { RadioButtons } from '@clubhouse/shared/components/RadioButtons';
import { createSearchParamConfig } from 'components/gql/utils/createSearchParamConfigs';
import { useSearchParam } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ObjectivesViewConfig = createSearchParamConfig({
  paramName: 'view',
  paramType: 'string',
  defaultValue: 'column',
  validValues: ['column', 'table']
});
export const useCurrentView = () => useSearchParam(ObjectivesViewConfig).param;
export function ViewSelector() {
  const {
    param: value,
    setSearchParam: setValue
  } = useSearchParam(ObjectivesViewConfig);
  return ___EmotionJSX(FilterField, {
    label: "View"
  }, ___EmotionJSX(RadioButtons, {
    selected: value,
    onChange: setValue
  }, ___EmotionJSX(RadioButtons.IconLabel, {
    icon: "Column",
    tooltip: "Column view",
    value: "column"
  }), ___EmotionJSX(RadioButtons.IconLabel, {
    icon: "Table",
    tooltip: "Table view",
    value: "table"
  })));
}
ViewSelector.displayName = "ViewSelector";