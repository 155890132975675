import { useCallback, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useToggleState } from '@clubhouse/shared/hooks';
import { DragAndDropContext } from './DragAndDropContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DragAndDropContainer(_ref) {
  let {
    children,
    onBeforeDragStart,
    onDragStart,
    onDragEnd
  } = _ref;
  const [isDragging, {
    on: startDragging,
    off: stopDragging
  }] = useToggleState();
  const handleDragEnd = useCallback((result, provided) => {
    stopDragging();
    onDragEnd?.(result, provided);
  }, [onDragEnd, stopDragging]);
  const contextState = useMemo(() => ({
    isDragging
  }), [isDragging]);
  return ___EmotionJSX(DragDropContext, {
    onBeforeDragStart: onBeforeDragStart,
    onDragStart: onDragStart,
    onBeforeCapture: startDragging,
    onDragEnd: handleDragEnd
  }, ___EmotionJSX(DragAndDropContext.Provider, {
    value: contextState
  }, children));
}
DragAndDropContainer.displayName = "DragAndDropContainer";