import { COLORS } from '../../colors';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ChartNoData = _ref => {
  let {
    width
  } = _ref;
  return ___EmotionJSX("svg", {
    width: width,
    height: width,
    viewBox: "0 0 97 80"
  }, ___EmotionJSX("g", {
    fill: "none",
    fillRule: "evenodd"
  }, ___EmotionJSX("path", {
    fill: COLORS.GRAY26,
    fillRule: "nonzero",
    opacity: ".05",
    d: "M.054.695L19.248.652l.18 78.994-19.195.043zM26.129.712h19.194v78.994H26.129zM51.912.706h19.194V79.7H51.912zM77.042.52L96.236.478l.18 78.994-19.195.044z"
  }), ___EmotionJSX("path", {
    stroke: COLORS.GRAY81,
    strokeWidth: "5",
    d: "M91.553 65.947L67.729 38.735 53.506 53.53l-20.153-30.7-11.647 9.395L7.024 15.453"
  })));
};
ChartNoData.displayName = "ChartNoData";