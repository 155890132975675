import Image from '@clubhouse/assets/png/screenshots/example-created-vs-completed-chart-v3.png';
import { getCurrentPage, getSlugPath } from 'utils/navigation';
import { usesIterations } from 'utils/tests';
import { ReportExplanation } from '../../shared/reports/ReportExplanation';
import { ReportsHelpFooter } from '../ReportsHelpFooter';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Description = _ref => {
  let {
    page
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "The Created vs. Completed chart displays work created and completed within a period of time. Work is represented by either points or Story count."), ___EmotionJSX("p", null, "The x-axis represents time.\xA0", page === 'reports' ? ___EmotionJSX(React.Fragment, null, "The intervals can be changed by using the Group By filter at the top of the page. See Group By help for more details.\xA0") : ___EmotionJSX(React.Fragment, null, "The Group By interval (Day, Week, or Month) is calculated based on the number of days in the date range.\xA0"), usesIterations() && ___EmotionJSX(React.Fragment, null, "Note that Group By Iteration is not supported by this chart.\xA0"), "When Group By is set to Day, non-working days will be highlighted on the chart. Working days can be adjusted in your ", ___EmotionJSX("a", {
    href: `${getSlugPath()}/settings/workspace`
  }, "Workspace Settings"), ".\xA0"), ___EmotionJSX("p", null, "The y-axis represents the number of points or Stories, which can be configured within the chart via the Sum Using dropdown on the top-right."), ___EmotionJSX("p", null, "While Sum Using is set to Points, unestimated Stories and Stories estimated at 0 points are excluded from the total."), ___EmotionJSX("ul", null, ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Stories Created"), " measures points or Story counts for all Stories created during that interval."), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Stories Completed"), " measures points or Story counts for all Stories that moved to a completed workflow state during that interval.")));
};
export const Explanation = _ref2 => {
  let {
    isEmbedded = false
  } = _ref2;
  return ___EmotionJSX(ReportExplanation, {
    isEmbedded: isEmbedded,
    title: `${!isEmbedded ? 'About the ' : ''} Created vs. Completed Chart`,
    imgSrc: Image,
    description: ___EmotionJSX(Description, {
      page: getCurrentPage()
    }),
    footer: ___EmotionJSX(ReportsHelpFooter, null)
  });
};
Explanation.displayName = "Explanation";