import { Icon } from '@useshortcut/shapes-ds';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { DetailPageSidebarAction, DetailPageSidebarRow, SecondaryActionButton } from 'components/shared/DetailPageSidebar';
import { getDeadlineClass } from 'data/entity/epic';
import { EmptyValue } from './EmptyValue';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function EpicEndDate(_ref) {
  let {
    epic
  } = _ref;
  const deadlineClass = getDeadlineClass(epic);
  const isOverdue = deadlineClass === 'deadline-overdue';
  const isNearDeadline = deadlineClass === 'deadline-warning';
  return ___EmotionJSX(DetailPageSidebarRow, {
    label: "Target Date",
    "data-perma-id": "epic-deadline-field"
  }, ___EmotionJSX(DetailPageSidebarAction, {
    style: {
      color: isOverdue ? 'var(--iconRedColor)' : isNearDeadline ? 'var(--iconOrangeColor)' : undefined
    },
    icon: ___EmotionJSX(Icon, {
      icon: "Calendar",
      fill: isOverdue || isNearDeadline ? 'currentColor' : 'main'
    }),
    "data-field-name": "deadline",
    "data-on-click": "openDateControl",
    secondarySlot: epic.formatted_deadline ? ___EmotionJSX(Tooltip, {
      content: "Clear Target Date"
    }, ___EmotionJSX(SecondaryActionButton, {
      "data-perma-id": "clearDate",
      "data-field-name": "deadline",
      "data-on-click": "clearDate"
    }, ___EmotionJSX(Icon, {
      icon: "Remove",
      fill: "main",
      label: "Clear Target Date"
    }))) : undefined
  }, epic.formatted_deadline ? epic.formatted_deadline : ___EmotionJSX(EmptyValue, null, "No Date")));
}
EpicEndDate.displayName = "EpicEndDate";