import Logo from '@clubhouse/assets/png/third-party-logos/gcal_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function GoogleCalendar() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.GOOGLE_CALENDAR
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Google Calendar"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Google Calendar Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Visualize the due dates of your Shortcut Stories in your", ' ', ___EmotionJSX(ExternalLink, {
    href: "https://calendar.google.com/"
  }, "Google Calendar"), "."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_GOOGLE_CALENDAR
  }, "Learn more"), " on how the Google Calendar integration works in Shortcut.")));
}
GoogleCalendar.displayName = "GoogleCalendar";