import LockErrorPng from '@clubhouse/assets/png/lock_error.png';
import { CompositeImage } from '../shared/CompositeImage';
import { Problem } from '../shared/Problem';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DEFAULT_MESSAGE = 'We seem to have an issue with the invitation. It has either expired or was deleted.';
export const DEFAULT_TITLE = "Sorry there's been a problem";
export const InviteErrorMedia = () => ___EmotionJSX(CompositeImage, {
  primaryImage: {
    src: LockErrorPng,
    alt: '',
    height: '150px',
    width: '182px'
  }
});
InviteErrorMedia.displayName = "InviteErrorMedia";
export const InviteNotFound = _ref => {
  let {
    title = DEFAULT_TITLE,
    message = DEFAULT_MESSAGE
  } = _ref;
  return ___EmotionJSX(Problem, {
    title: title,
    description: message,
    media: ___EmotionJSX(InviteErrorMedia, null)
  });
};
InviteNotFound.displayName = "InviteNotFound";