function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useMemo } from 'react';
import { GroupedOptionsSelect, useFetchGroupedOptions } from '@clubhouse/shared/components/GroupedOptionsSelect';
import { Divider, Nothing } from '@clubhouse/shared/components/Select';
import { logError } from 'utils/monitoring';
import { jsx as ___EmotionJSX } from "@emotion/react";
const staticItems = [Nothing(), Divider('after-none')];
export const GroupedOptionsField = _ref => {
  let {
    selectedEntities,
    required,
    getSection,
    fetchOptions,
    ...props
  } = _ref;
  const {
    unit,
    maxSelected
  } = props;
  const selectedValues = useMemo(() => selectedEntities.map(_ref2 => {
    let {
      id
    } = _ref2;
    return id;
  }), [selectedEntities]);
  const targetComponentProps = useMemo(() => ({
    selectedEntities: selectedEntities ?? []
  }), [selectedEntities]);
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions,
    selectedValues,
    getSection,
    staticItems: required ? undefined : staticItems,
    onError: logError,
    maxSelected
  });
  return ___EmotionJSX(GroupedOptionsSelect, _extends({
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    selectedValues: selectedValues,
    label: `Update ${maxSelected === 1 ? unit.singular : unit.plural}`,
    targetComponentProps: targetComponentProps
  }, props));
};
GroupedOptionsField.displayName = "GroupedOptionsField";