import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ObjectiveStateDisplay } from './ObjectiveStateDisplay';
import { ObjectiveStateIcon } from './ObjectiveStateIcon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ObjectiveStateIconDisplay = _ref => {
  let {
    state
  } = _ref;
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center",
    inline: true
  }, ___EmotionJSX(ObjectiveStateIcon, {
    state: state
  }), ___EmotionJSX("strong", null, ___EmotionJSX(ObjectiveStateDisplay, {
    state: state
  })));
};
ObjectiveStateIconDisplay.displayName = "ObjectiveStateIconDisplay";