import { useTeamScopeId } from 'components/team-navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IfNoTeamScope = _ref => {
  let {
    children
  } = _ref;
  const {
    value
  } = useTeamScopeId();
  return !value ? ___EmotionJSX(React.Fragment, null, children) : null;
};