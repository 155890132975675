import { capitalize } from '@useshortcut/shapes-ds';
import { useContext } from 'react';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { TooltipLayout } from '@clubhouse/shared/components/Tooltip';
import { DensityContext } from '../../../../DensityContext';
import { ChipTooltip } from '../ChipTooltip';
import { getStatusIconFromType } from './utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
const typeToProps = {
  bug: {
    icon: 'Bug',
    kind: 'red'
  },
  chore: {
    icon: 'Chore',
    kind: 'blue'
  },
  feature: {
    icon: 'Feature',
    kind: 'yellow'
  }
};
export const TypeChip = _ref => {
  let {
    publicId,
    type,
    workflowStateName,
    workflowStateType
  } = _ref;
  const density = useContext(DensityContext);
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(TooltipLayout, {
      title: `${capitalize(type)} #${publicId}`,
      text: workflowStateName
    }, ___EmotionJSX(TooltipLayout.Slot, {
      name: "Icon"
    }, ___EmotionJSX(DeprecatedIconAdapter, {
      width: 18
    }, getStatusIconFromType(workflowStateType))))
  }, ___EmotionJSX(Chip, {
    kind: typeToProps[type].kind
  }, ___EmotionJSX(Chip.Icon, {
    icon: typeToProps[type].icon,
    label: typeToProps[type].icon
  }), ['standard', 'jumbo'].includes(density) && ___EmotionJSX(React.Fragment, null, "#", publicId)));
};
TypeChip.displayName = "TypeChip";