import { VisuallyHidden } from '@react-aria/visually-hidden';
import { ThirdPartyIcon } from '@useshortcut/shapes-ds';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ChipTooltip } from '../ChipTooltip';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const JiraSyncChip = _ref => {
  let {
    externalId
  } = _ref;
  if (!externalId) return null;
  return ___EmotionJSX(ChipTooltip, {
    content: ___EmotionJSX(ChipTooltip.Text, null, "This Story is synced with a JIRA Issue")
  }, ___EmotionJSX(Chip, null, ___EmotionJSX(DeprecatedIconAdapter, {
    width: "16"
  }, ___EmotionJSX(ThirdPartyIcon, {
    icon: "Jira"
  })), ___EmotionJSX(VisuallyHidden, null, "Jira Ticket:"), externalId));
};
JiraSyncChip.displayName = "JiraSyncChip";