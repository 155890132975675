import { ContextMenu, ContextMenuDivider, ContextMenuItem, ContextMenuItemLink, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { getHref } from 'components/gql/team/utils/links';
import { update, useGroupWithFetch } from 'data/entity/group';
import { getLoggedInUserPermissionID } from 'data/entity/user';
import { navigateOnClick } from 'utils/navigation';
import { CopyContextMenuItem } from './actions';
import { useContextMenuWithLogging } from './useContextMenuWithLogging';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ToggleGroupMembership = _ref => {
  let {
    group
  } = _ref;
  const permissionId = getLoggedInUserPermissionID();
  if (!permissionId) return null;
  const isMember = group.member_ids.includes(permissionId);
  return ___EmotionJSX(ContextMenuItem, {
    icon: isMember ? 'UserRemove' : 'UserAdd',
    label: isMember ? 'Leave Team' : 'Join Team',
    onClick: async () => {
      try {
        await update({
          id: group.id,
          member_ids: isMember ? group.member_ids.filter(id => id !== permissionId) : [...group.member_ids, permissionId]
        });
      } catch {}
    }
  });
};
ToggleGroupMembership.displayName = "ToggleGroupMembership";
export const TeamContextMenu = _ref2 => {
  let {
    id,
    onRemoveTeamNav,
    onAddTeamNav
  } = _ref2;
  const {
    group,
    loading
  } = useGroupWithFetch(id);
  if (loading) return ___EmotionJSX(ContextMenuLoading, null);
  if (!id || !group) return null;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(ContextMenuItemLink, {
    icon: "Zoom",
    label: "Go to Team Page",
    href: getHref(id),
    target: "_self",
    onClick: navigateOnClick(getHref(id))
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(id, true),
    label: "Link"
  }), ___EmotionJSX(ToggleGroupMembership, {
    group: group
  }), onRemoveTeamNav || onAddTeamNav ? ___EmotionJSX(ContextMenuDivider, null) : null, onRemoveTeamNav ? ___EmotionJSX(ContextMenuItem, {
    icon: "CloseCircle",
    label: "Remove from menu",
    onClick: onRemoveTeamNav
  }) : null, onAddTeamNav ? ___EmotionJSX(ContextMenuItem, {
    icon: "AddCircle",
    label: "Add to menu",
    onClick: onAddTeamNav
  }) : null);
};
TeamContextMenu.displayName = "TeamContextMenu";
export const useTeamContextMenu = cmpProps => {
  const {
    props
  } = useContextMenuWithLogging(() => ___EmotionJSX(TeamContextMenu, cmpProps), {
    entityType: 'Team'
  });
  return props;
};