import View from 'app/client/core/js/modules/view';
import { ZendeskIntegrationInterstitial } from 'components/settings/integrations/ZendeskIntegrationInterstitial';
import { Zendesk } from 'pages/integrations/Zendesk';
import ApplicationModel from '../../../core/js/models/application';
import Settings from '../../../core/js/modules/settings';
import Url from '../../../core/js/modules/url';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
exports.title = `Zendesk Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + ApplicationModel.getIntegrationSettingsPath(ApplicationModel.getZendeskId());
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Zendesk, 'zendesk-integration'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
exports.renderInterstitial = () => View.renderComponent({
  component: ___EmotionJSX(ZendeskIntegrationInterstitial, null),
  containerSelector: '#auth'
});
export { exports as default };