import * as ContentTemplate from 'app/client/stories/views/templates/content.html';
import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/stories/less/app.less';
import Mousetrap from 'mousetrap';
import AddNewStoryController from '../../../core/js/controllers/addNewStory';
import ApplicationState from '../../../core/js/modules/applicationState';
import Boot from '../../../core/js/modules/boot';
import ColumnController from '../controllers/column';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import DragAndDrop from './draganddrop';
import EpicController from '../controllers/epic';
import EpicModel from '../../../core/js/models/epic';
import * as Event from '../../../core/js/_frontloader/event';
import FilterController from '../controllers/filter';
import FilterModel from '../../../core/js/models/filter';
import Globals from '../../../core/js/_frontloader/globals';
import Is from '../../../core/js/modules/is';
import IterationController from '../controllers/iteration';
import IterationModel from '../../../core/js/models/iteration';
import Layout from '../../../core/js/modules/layout';
import Log from '../../../core/js/modules/log';
import MessageController from 'app/client/core/js/controllers/message';
import ProjectController from '../controllers/project';
import ProjectModel from '../../../core/js/models/project';
import QuickSearchController from '../../../core/js/controllers/quickSearch';
import SidebarController from '../controllers/sidebar';
import StoriesColumnModel from '../models/storiesColumn';
import StoriesEpicModel from '../models/storiesEpic';
import StoriesIterationModel from '../models/storiesIteration';
import StoriesProjectModel from '../models/storiesProject';
import StoriesStoryModel from '../models/storiesStory';
import StoriesStoryController from '../controllers/storiesStory';
import StoriesView from './storiesView';
import ViewSettingsController from 'app/client/core/js/controllers/viewSettings';
import StoryDialogController from '../../../core/js/controllers/storyDialog';
import Swipe from '../../../core/js/modules/swipe';
import Updates from '../../../core/js/modules/updates';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import WorkflowModel from '../../../core/js/models/workflow';
import SpaceController from '../controllers/space';
import SpaceModel from '../../../core/js/models/space';
import TeamModel from '../../../core/js/models/team';
import ProfileModel from '../../../core/js/models/profile';
import Tests from '../../../core/js/modules/tests';
import { simpleCompleteTask } from 'utils/quickstart';
import { openOrFetchAndOpen } from 'utils/storyDialog';
import Dialog from 'app/client/core/js/modules/dialog';
import { replaceState } from '../../../core/js/_frontloader/detangledRouter';
import { getRelativeHref } from 'utils/navigation';
import { isErr } from '@clubhouse/shared/utils/result';
const exports = {};
exports.initShortcuts = () => {
  Mousetrap.bind('d 1', ViewSettingsController.density.setTitleOnly);
  Mousetrap.bind('d 2', ViewSettingsController.density.setMinimal);
  Mousetrap.bind('d 3', ViewSettingsController.density.setStandard);
  Mousetrap.bind('d 4', ViewSettingsController.density.setJumbo);
  if (!Is.readOnly(UserModel.getLoggedInUserPermission())) {
    Mousetrap.bind('n', AddNewStoryController.render);
  }
};
exports.moduleInit = () => {
  const profile = ProfileModel.getCurrentUserProfileDetails();
  DragAndDrop.init({
    preventDrop: !Is.observer(profile)
  });
  if (Is.mobile()) {
    Swipe.init();
  }
  ColumnController.init();
  FilterController.init();
  SidebarController.init();
  StoriesView.init();
  StoriesStoryController.storiesPageInit();

  // if User has an active Quickstart?
  simpleCompleteTask('visit-stories');
};
exports.initEventBindings = () => {
  Event.on('resize', () => {
    SidebarController.onResize();
    StoriesView.onResize();
  });
  Event.on('search', query => {
    FilterModel.addFixture('search', query);
    QuickSearchController.close();
  });
  Event.on('storiesView:groupBy storiesView:viewType StoryDensity.Change CollapseEmptyColumns.Change', () => {
    _reRenderAfterViewChange();
  });
  Event.on('workflowUpdated estimateScaleUpdated', () => {
    ColumnController.reset();
  });
  Event.on('storyDeleted filterAdded', () => {
    StoriesView.drawStories();
  });
  Event.on('somebodyModifiedTeam somebodyDeletedTeam userDeletedTeam', () => {
    WorkflowModel.fetchAll();
    ColumnController.reset();
    SidebarController.init();
    EpicController.render();
    ProjectController.render();
  });
  Event.on('doneFetchingStories', workflowId => {
    const team = TeamModel.getActive('stories');
    const currentWorkflowId = team?.workflow?.id;
    if (currentWorkflowId === workflowId) {
      StoriesView.drawStories();
    }
  });
  StoriesColumnModel.initEventBindings();
  StoriesEpicModel.initEventBindings();
  StoriesProjectModel.initEventBindings();
  StoriesStoryModel.initEventBindings();
};
const _initFetchSettings = () => {
  ConsolidatedFetch.setFetchPageSpecificData(exports.fetchStoriesUsingBestStrategy);
};
exports.initOnce = () => {
  ApplicationState.setLastView(Url.getSlugPath() + '/stories');
  Utils.enableAnimation();
  exports.moduleInit();
  Updates.init(_handleUpdates);
  exports.initShortcuts();
  StoriesView.setColumnHeight();
  if (Is.mobile() && SidebarController.isClosed()) {
    $('#sidebar').css({
      left: -224
    });
    $('#content').css({
      marginLeft: 0
    });
  }
  Event.trigger('pageRendered', 'Stories');
};
const loadStoryDialogFromURL = async () => {
  const url = getRelativeHref();
  const parsedURL = StoryDialogController.parseStoryURL(url);
  try {
    const result = await openOrFetchAndOpen(parsedURL.id, parsedURL.activity);
    if (isErr(result)) {
      MessageController.alert(`Story #${parsedURL.id} does not exist.`);
      Dialog.close();
      replaceState(Url.getSlugPath() + '/stories');
    }
  } catch (e) {}
};
exports.activateProjectsIfNoneSelected = () => {
  if (ProjectModel.filter({
    active: true
  }).length === 0) {
    ProjectModel.each(project => {
      project.active = true;
    });
    ProjectModel.trigger('updated');
  }
};
exports.activateEpicsIfNoneSelected = () => {
  const noEpics = EpicModel.shouldDisplayStoriesNotInEpics();
  if (EpicModel.filter({
    active: true
  }).length === 0 && !noEpics) {
    EpicController.enableAllEpics();
  }
};
exports.activateIterationsIfNoneSelected = () => {
  const noIteration = StoriesIterationModel.shouldDisplayStoriesNotInIterations();
  if (Tests.usesIterations() && IterationModel.filter({
    active: true
  }).length === 0 && !noIteration) {
    IterationController.showAllIterations();
  }
};
exports.hideLoading = () => {
  $('#sidebar, #columns, #stories-table, #stories-page-wrapper').removeClass('transparent');
};
exports.updateStoryNavigationIfOpen = async () => {
  await StoryDialogController.updateNavigationItems();
};
function _handleUpdates(updates) {
  if (Globals.get('isDragging')) {
    Log.debug('Updates: updates found, but user is dragging.');
  } else if (Globals.get('isSaving')) {
    Log.debug('Updates: updates found, but something is mid-save.');
  } else {
    StoriesView.drawStories(updates);
  }
}
exports.fetchStoriesUsingBestStrategy = callback => {
  const space = SpaceModel.getActive();
  const strategyFn = SpaceModel.getStoryLoadStrategyForSpace(space);
  const _callback = () => {
    callback();
    Globals.set('fetchingAllStories', true);
  };
  strategyFn(_callback);
};
const _reRenderAfterViewChange = () => {
  StoriesView.drawStories();
};
const _afterFetch = async () => {
  exports.activateProjectsIfNoneSelected();
  exports.activateEpicsIfNoneSelected();
  exports.activateIterationsIfNoneSelected();
  await exports.updateStoryNavigationIfOpen();
  exports.hideLoading();
  StoriesView.showColumnView();
  exports.initOnce();
};
const _reInit = async () => {
  rerenderTemplate();
  SidebarController.initImmediately();
  SpaceController.init();
  exports.initEventBindings();
  _initFetchSettings();
  SpaceModel.restore(async () => {
    const currentPage = Url.getCurrentPage();

    // We might be hot-reloading this page from the /story page.
    if (currentPage !== 'stories') {
      return false;
    }
    if (Globals.get('fetchingAllStories') !== true) {
      exports.fetchStoriesUsingBestStrategy(async () => {
        const currentPage = Url.getCurrentPage();
        if (currentPage !== 'stories') {
          return false;
        }
        await _afterFetch();
      });
    } else {
      await _afterFetch();
    }
  });
};
const isViewingStoriesOrStoryPage = () => ['stories', 'story'].includes(Url.getPageFromCurrentPathname());
const _init = () => {
  rerenderTemplate();
  SidebarController.initImmediately();
  SpaceController.init();
  exports.initEventBindings();
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(async () => {
    if (!isViewingStoriesOrStoryPage()) {
      return false;
    }
    if (Url.isStoryURL()) {
      await loadStoryDialogFromURL();
      Event.trigger('pageRendered', 'Story');
    }
    SpaceModel.restore(() => {
      exports.fetchStoriesUsingBestStrategy(async () => {
        if (!isViewingStoriesOrStoryPage()) {
          return false;
        }
        Log.debug('Init: Finished migrating and restoring spaces');
        await _afterFetch();
      });
    });
  });

  // set after calling initial fetchConsolidatedDataAndActivity so we don't request stories twice
  _initFetchSettings();
};
const rerenderTemplate = () => {
  Layout.renderContent(ContentTemplate.render({
    isUsingReactStoriesTable: true,
    isSidebarOpen: SidebarController.isOpen()
  }));
};

// make public for tests
exports._init = _init;
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };