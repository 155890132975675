import { BulkEditFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect, useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { Checkbox } from '@clubhouse/shared/components/Checkbox';
import { emptyArray } from '@clubhouse/shared/utils';
import { useBulkEdit, useGroupBulkEdit } from '../../hooks/useTableBulkSelection';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BulkEditFieldFragment = BulkEditFieldFragmentFragmentDoc;
const BulkEditFieldComponent = _ref => {
  let {
    entity: {
      publicId: id
    }
  } = _ref;
  const {
    isSelected,
    select,
    unselect
  } = useBulkEdit(id);
  return ___EmotionJSX(Center, null, ___EmotionJSX(Checkbox, {
    isSelected: isSelected,
    onChange: (_, event) => {
      if (event.shiftKey) document.getSelection()?.removeAllRanges();
      if (isSelected) unselect(event.shiftKey);else select(event.shiftKey);
    }
  }));
};
BulkEditFieldComponent.displayName = "BulkEditFieldComponent";
const mapAndFilterIds = items => items.map(item => item?.publicId).filter(id => typeof id === 'number');
const HeaderComponent = _ref2 => {
  let {
    groupItems = emptyArray
  } = _ref2;
  const [groupItemIds, setGroupItemIds] = useState(() => {
    if (typeof groupItems === 'function') return [];
    return mapAndFilterIds(groupItems);
  });
  useEffect(() => {
    if (typeof groupItems === 'function') groupItems().then(result => setGroupItemIds(mapAndFilterIds(result)));else setGroupItemIds(mapAndFilterIds(groupItems));
  }, [groupItems]);
  const {
    selectAll,
    unselectAll,
    isSelected
  } = useGroupBulkEdit(groupItemIds);
  return ___EmotionJSX(Center, null, ___EmotionJSX(Checkbox, {
    isDisabled: groupItemIds.length === 0,
    isSelected: isSelected,
    onChange: () => {
      if (isSelected) unselectAll();else selectAll();
    }
  }));
};
HeaderComponent.displayName = "HeaderComponent";
export const BulkEditField = function () {
  let {
    frozenOverride
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'selection',
    displayName: '',
    HeaderComponent,
    width: 50,
    maxWidth: 50,
    sort: false,
    headerProps: {
      centered: true
    },
    Component: BulkEditFieldComponent,
    frozen: frozenOverride || {
      left: 25
    }
  };
};