import { Chip } from '@clubhouse/shared/components/Chip';
import { pluralize } from '@clubhouse/shared/utils';
import { convertIsoCalendarDateToDateTimeUTCOffset } from 'utils/convertIsoCalendarDateToDateTimeUTCOffset';
import { momentUtc } from 'utils/momentUtc';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ReportCardHeaderEmptyState(_ref) {
  let {
    iterationStartDate,
    workspaceUtcOffset
  } = _ref;
  const todayWorkspaceUtc = momentUtc().utcOffset(workspaceUtcOffset || '').utc();
  const startDateUtc = momentUtc(convertIsoCalendarDateToDateTimeUTCOffset(iterationStartDate, workspaceUtcOffset));
  const isIterationUnstarted = todayWorkspaceUtc.isBefore(startDateUtc);
  const daysUntilIterationStarts = Math.ceil(startDateUtc.diff(todayWorkspaceUtc, 'days', true));
  const countdownText = `Iteration Starts ${daysUntilIterationStarts === 1 ? 'Tomorrow' : `in ${pluralize({
    count: daysUntilIterationStarts,
    singular: 'day',
    plural: 'days'
  })}`}`;
  return ___EmotionJSX(Chip, null, isIterationUnstarted ? countdownText : 'No Data Available', " ");
}
ReportCardHeaderEmptyState.displayName = "ReportCardHeaderEmptyState";