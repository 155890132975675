import { DockedObjectiveFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref as getObjectiveHref } from 'components/gql/objective/links';
import { useObjectiveContextMenu } from 'utils/contextMenus/useObjectiveContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as ___EmotionJSX } from "@emotion/react";
const DockedObjectiveFragment = DockedObjectiveFragmentDoc;
export const isObjective = node => {
  return node?.__typename === 'Objective';
};
export function DockedObjective(_ref) {
  let {
    node,
    index,
    onUnpinClicked
  } = _ref;
  const url = getObjectiveHref(node.publicId, {
    name: node.objectiveName
  });
  const contextMenuProps = useObjectiveContextMenu(node.publicId);
  return ___EmotionJSX(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: ___EmotionJSX(SizedIcon, {
      icon: "Objectives",
      fill: "interactive",
      size: 14
    }),
    title: ___EmotionJSX(Title, {
      url: url
    }, node.objectiveName),
    contextMenuProps: contextMenuProps
  });
}
DockedObjective.displayName = "DockedObjective";