function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ReportCard } from '../../shared/reports/ReportCard';
import { Explanation } from './Explanation';
import { Filters } from './Filters';
import { jsx as ___EmotionJSX } from "@emotion/react";
const chartStyle = `
  .c3-bar {
    fill-opacity: .75;
    stroke-width: 0;
  }
  .c3-bar._expanded_ {
    fill-opacity: 1;
  }
  .c3-area {
    opacity: 0.75 !important;
  }
`;
export const TimeSpentInWFStateReportCard = props => ___EmotionJSX(ReportCard, _extends({}, props, {
  title: "Time Spent In Workflow State",
  description: "The time completed stories spent in each workflow state.",
  chartStyle: chartStyle,
  Explanation: Explanation,
  Filters: Filters
}));
TimeSpentInWFStateReportCard.displayName = "TimeSpentInWFStateReportCard";