import { StoryCardInternal } from '../../internal';
import { Group, Owners } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const GroupAndOwners = _ref => {
  let {
    children
  } = _ref;
  return ___EmotionJSX(StoryCardInternal.GroupAndOwners, null, children);
};
GroupAndOwners.displayName = "GroupAndOwners";
GroupAndOwners.Group = Group;
GroupAndOwners.Owners = Owners;