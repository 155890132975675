import { Nouns } from '@clubhouse/shared/constants';
import { EpicsFilter } from 'components/gql/epics/EpicsFilter';
import { ClearAllCurrentFiltersButton } from 'components/gql/filters/ClearAllCurrentFiltersButton';
import { CustomFieldsFilter } from 'components/gql/filters/CustomFieldFilter';
import { QueryFilter } from 'components/gql/filters/QueryFilter';
import { StoryTypeFilter } from 'components/gql/filters/StoryTypeFilter';
import { LabelsFilter } from 'components/gql/label/LabelsFilter';
import { OwnersFilter } from 'components/gql/owner/OwnersFilter';
import { PriorityFilter } from 'components/gql/priority/PriorityFilter';
import { RequestersFilter } from 'components/gql/requester/RequestersFilter';
import { TeamsFilter } from 'components/gql/team/TeamsFilter';
import { IfNoTeamScope } from 'components/team-navigation/utils/IfNoTeamScope';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const BacklogFilters = _ref => {
  let {
    enabledCustomFields
  } = _ref;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(StoryTypeFilter, null), ___EmotionJSX(IfNoTeamScope, null, ___EmotionJSX(TeamsFilter, {
    entityType: Nouns.Story
  })), ___EmotionJSX(OwnersFilter, {
    entityType: Nouns.Story
  }), ___EmotionJSX(RequestersFilter, {
    entityType: Nouns.Story
  }), !!enabledCustomFields.priority && ___EmotionJSX(PriorityFilter, {
    entityType: Nouns.Story
  }), ___EmotionJSX(EpicsFilter, {
    entityType: Nouns.Story
  }), ___EmotionJSX(LabelsFilter, {
    entityType: Nouns.Story
  }), ___EmotionJSX(CustomFieldsFilter, {
    entityType: Nouns.Story,
    severityFieldId: enabledCustomFields.severity?.id,
    skillSetFieldId: enabledCustomFields.skillSet?.id,
    technicalAreaFieldId: enabledCustomFields.technicalArea?.id,
    productAreaFieldId: enabledCustomFields.productArea?.id
  }), ___EmotionJSX(ClearAllCurrentFiltersButton, null));
};
export const BacklogFiltersSearch = () => {
  return ___EmotionJSX(QueryFilter, {
    entityType: Nouns.Story
  });
};
BacklogFiltersSearch.displayName = "BacklogFiltersSearch";