import Logo from '@clubhouse/assets/png/third-party-logos/figma_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Figma() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.FIGMA
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Figma"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Figma Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Turn any link to a public ", ___EmotionJSX(ExternalLink, {
    href: "https://www.figma.com/"
  }, "Figma"), " file within any markdown text field in Shortcut into an embedded live preview for easier design collaboration."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_FIGMA
  }, "Learn more"), " on how Figma file embeds work in Shortcut.")));
}
Figma.displayName = "Figma";