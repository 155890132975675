import { Switch } from '@clubhouse/shared/components/Switch';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { ApplicationIds, update, useInstallationsByApplicationId } from 'data/entity/installation';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TypeToApplicationId = {
  bitbucket: ApplicationIds.Bitbucket,
  github: ApplicationIds.Github,
  gitlab: ApplicationIds.Gitlab
};
export function EventHandlerToggle(_ref) {
  let {
    type
  } = _ref;
  const isAdmin = isLoggedInUserAdmin();
  const installations = useInstallationsByApplicationId(TypeToApplicationId[type]);
  if (!installations?.length) return null;
  const isChecked = !installations[installations.length - 1].vcs_auto_move_stories_disabled;
  return ___EmotionJSX(Tooltip, {
    content: "Only an admin can change this value.",
    disabled: isAdmin
  }, ___EmotionJSX(Switch.Label, null, ___EmotionJSX(Switch, {
    isDisabled: !isAdmin,
    isChecked: isChecked,
    onChange: isEnabled => {
      installations.forEach(installation => {
        if (!installation) return;
        update(installation, {
          vcs_auto_move_stories_disabled: !isEnabled
        });
      });
    }
  }), ___EmotionJSX(Text, null, "Automatically move a Story to the first ", ___EmotionJSX("em", null, "Started"), " Workflow State when a Branch, Commit, or", ' ', type === 'gitlab' ? 'Merge Request' : 'Pull Request', "is associated with it.")));
}
EventHandlerToggle.displayName = "EventHandlerToggle";