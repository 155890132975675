import { EpicIconEpicFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { StatusIcon } from '@useshortcut/shapes-ds';
import { gql } from '@clubhouse/datalayer';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as ___EmotionJSX } from "@emotion/react";
const EpicIconEpicFragment = EpicIconEpicFragmentDoc;
export const EpicIcon = _ref => {
  let {
    epic,
    width = '15px'
  } = _ref;
  const {
    type
  } = epic.stateObject;
  if (type === 'started') {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: width
    }, ___EmotionJSX(StatusIcon, {
      icon: "Started"
    }));
  } else if (type === 'done') {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: width
    }, ___EmotionJSX(StatusIcon, {
      icon: "Done"
    }));
  } else {
    return ___EmotionJSX(DeprecatedIconAdapter, {
      width: width
    }, ___EmotionJSX(StatusIcon, {
      icon: "Unstarted"
    }));
  }
};