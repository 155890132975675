function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import classNames from 'classnames';
import { jsx as ___EmotionJSX } from "@emotion/react";
export default function SVG(_ref) {
  let {
    rawSVG,
    url,
    className = undefined,
    ...props
  } = _ref;
  const computedClassName = classNames(className, 'svg-container');
  const rawSVGIsURL = rawSVG != null && ['/static/', 'https:', 'http:', 'data:'].some(protocol => rawSVG.startsWith(protocol));
  if (rawSVGIsURL) {
    console.error('Must use `url` prop for remote SVG instead of `rawSVG`. We gracefully fallback, but please refactor this usage.');
  }
  if (url || rawSVGIsURL) {
    return ___EmotionJSX("img", _extends({
      className: computedClassName,
      src: url ?? rawSVG,
      alt: ""
    }, props));
  }
  return ___EmotionJSX("div", _extends({
    dangerouslySetInnerHTML: {
      __html: rawSVG || ''
    },
    className: computedClassName
  }, props));
}
SVG.displayName = "SVG";