import "core-js/modules/es.array.push.js";
import * as UserDropdownTemplate from 'app/client/core/views/templates/userDropdown.html';
import Async from '../modules/async';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Globals from '../_frontloader/globals';
import Is from '../modules/is';
import Iterate from '../modules/iterate';
import OrganizationModel from './organization';
import PaymentPlan2Model from './paymentPlan2';
import PermissionModel from './permission';
import StoryModel from './story';
import UserModel from './user';
import Utils from '../modules/utils';
import OrganizationProfileModel from './organizationProfile';
import { PROFILE_STATES } from '@clubhouse/shared/constants';
const exports = {};

// NOTE: Until cleanup complete, see below:
// This model references the logged out "members" when all users are fetched.
// This will include the logged in user, for display alongside everyone else
// in the app. If an update or specific reference to a logged in users details
// is required, it should be done in the user model, that also contains
// references to the logged in user profile.

Collection.create('Profile', exports);
exports.isValid = profile => {
  return !!(profile && profile.id && (
  // Temporary until profile/member meanings are teased out
  profile.entity_type === 'profile' || profile.entity_type === 'member' || !!profile.role));
};
exports.normalize = profile => {
  // TODO Look for a better way to keep a reference to the org when fetched from /members endpoint
  // Only needed in org management area of the app due to org dashboard
  profile.org_id = Globals.get('organizationID') || OrganizationModel.getCurrentID();
  Iterate.each(profile.permissions, PermissionModel.updateIfValid);
};
exports.isCurrentProfile = profile => {
  return profile?.id === UserModel.getLoggedInUserPermissionID();
};
exports.mapIDsToProfileDetails = ids => {
  return exports.sortProfileDetails(_.compact(_.map(ids, id => {
    return exports.getAllDetailsById(id);
  })));
};
exports.mapProfileDetails = rawProfile => {
  const profile = _.get(rawProfile, 'profile', rawProfile);
  const name = profile.name || rawProfile.name || 'No Name Provided';
  return {
    // Basic profile information (member permissions, to be cleaned up)
    id: rawProfile.id,
    disabled: rawProfile.disabled,
    role: rawProfile.role,
    replaced_by: rawProfile.replaced_by,
    // Org ID added during normalization
    org_id: rawProfile.org_id,
    // Profile information specific to Organization
    deactivated: profile.deactivated,
    display_icon: profile.display_icon,
    email_address: profile.email_address,
    lowercase_name: profile.lowercase_name || name.toLowerCase(),
    mention_name: profile.mention_name,
    name,
    two_factor_auth_activated: profile.two_factor_auth_activated,
    created_without_invite: rawProfile.created_without_invite,
    group_ids: rawProfile.group_ids,
    state: rawProfile.state,
    is_owner: rawProfile.profile?.is_owner
  };
};

// See if this is worth adding to normalization depending on
// how we use/update it when profiles are fully implemented.
exports.getAllDetailsById = id => {
  const rawProfile = exports.getById(id);
  if (!rawProfile) {
    return null;
  }
  return exports.mapProfileDetails(rawProfile);
};
exports.getAllDetailsByMemberId = id => {
  return exports.getAllDetailsById(_.get(exports.find(profile => {
    return _.get(profile, 'profile.id') === id;
  }), 'id'));
};
exports.getProfileDetailsFromContext = context => {
  return exports.getAllDetailsById(_.get(Utils.getModelFromContext(context, 'Profile'), 'id'));
};
exports.findBy = query => {
  return _.find(exports.getAllProfileDetails(), query);
};
exports.findActiveBy = query => {
  return _.find(exports.getAllActiveProfileDetails(), query);
};
exports.getDetailsByName = name => {
  return exports.findActiveBy({
    name
  });
};
exports.getDetailsByUsername = username => {
  return exports.findActiveBy({
    mention_name: username
  });
};
exports.getAllDetailsSorted = () => {
  return exports.all().map(exports.mapProfileDetails).sort(exports.profileDetailsSorter);
};
exports.profileDetailsSorter = (a, b) => {
  if (a.lowercase_name > b.lowercase_name) {
    return 1;
  }
  if (a.lowercase_name < b.lowercase_name) {
    return -1;
  }
  return 0;
};
exports.sortProfileDetails = (profileDetails // Using native sort here since it gets used multiple times per story normalization.
) => {
  return profileDetails.sort(exports.profileDetailsSorter);
};
exports.getCurrentUserRawProfile = () => {
  return exports.getById(UserModel.getLoggedInUserPermissionID());
};
let cachedCurrentUserProfileDetails = null;

/** @deprecated Use `exports.getCurrentUserProfile` */
exports.getCurrentUserProfileDetails = () => {
  // Temporarily memoizing this as it gets used potentially
  // 1000s of times during the Stories page render.
  if (cachedCurrentUserProfileDetails) {
    return cachedCurrentUserProfileDetails;
  }
  cachedCurrentUserProfileDetails = exports.getAllDetailsById(UserModel.getLoggedInUserPermissionID());
  setTimeout(() => {
    cachedCurrentUserProfileDetails = null;
  }, 0);
  return cachedCurrentUserProfileDetails;
};
exports.getCurrentUserProfile = () => {
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser();
  return profile;
};
exports.getOwnerProfile = () => {
  const owner = exports.get({
    profile: {
      is_owner: true
    }
  });
  return exports.getAllDetailsById(_.get(owner, 'id'));
};
exports.getOwnerEmail = () => {
  return _.get(exports.getOwnerProfile(), 'email_address');
};
exports.getAllProfileDetails = orgID => {
  const profiles = exports.sortProfileDetails(exports.map(profile => {
    return exports.getAllDetailsById(profile.id);
  }));
  return orgID ? _.filter(profiles, {
    org_id: orgID
  }) : profiles;
};
exports.getAllDisabledProfileDetails = orgID => {
  return exports.sortProfileDetails(exports.getAllProfileDetails(orgID).filter(profile => {
    return profile.state === PROFILE_STATES.DISABLED;
  }));
};
exports.getAllImportedProfileDetails = orgID => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(orgID), profile => {
    return profile.disabled && profile.created_without_invite;
  }));
};
exports.getAllActiveProfileDetails = orgID => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(orgID), {
    disabled: false,
    deactivated: false
  }));
};
exports.getAllAssignableProfiles = orgID => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(orgID), profile => profile.state !== PROFILE_STATES.DISABLED && profile.state !== PROFILE_STATES.IMPORTED));
};
exports.getAllActiveObservers = orgID => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(orgID), {
    disabled: false,
    deactivated: false,
    role: 'observer'
  }));
};
exports.getAllActiveOwners = orgID => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(orgID), {
    disabled: false,
    deactivated: false,
    is_owner: true
  }));
};
exports.getAllActiveProfileDetailsAndSpeciallyInclude = specialInclude => {
  return exports.sortProfileDetails(_.filter(exports.getAllProfileDetails(), profile => specialInclude.includes(profile.id) || profile.state !== PROFILE_STATES.DISABLED && profile.state !== PROFILE_STATES.IMPORTED));
};
exports.getAllOtherActiveProfileDetails = orgID => {
  return _.reject(exports.getAllActiveProfileDetails(orgID), {
    id: UserModel.getLoggedInUserPermissionID()
  });
};
exports.getEmailUsername = profile => {
  const {
    email_address
  } = profile;
  return (email_address || '').split('@')[0].toLowerCase();
};
exports.getMatchers = function () {
  let profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _.compact([profile.mention_name, exports.getEmailUsername(profile)]);
};
exports.fuzzyMatch = str => {
  const matches = [];
  const props = ['email_address', 'mention_name', 'name'];
  str = (str || '').toLowerCase();
  if (str === 'me') {
    return [exports.getCurrentUserProfileDetails()];
  }
  Iterate.each(exports.getAllProfileDetails(), profile => {
    Iterate.each(props, prop => {
      if ((profile[prop] || '').toLowerCase().indexOf(str) === 0) {
        matches.push(profile);
      }
    });
  });
  return matches;
};
exports.updateProfile = (data, callback) => {
  Backend.put('/api/private/profile', {
    data,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        UserModel.fetchLoggedInUserProfiles(callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};

// TODO Move to member profile model
// Promotes a user to the owner of a company, and all organizations within.
exports.promote = (profile, callback, options) => {
  Backend.put('/api/private/owner/profiles/' + profile.id + '/promote', _.assign({
    onComplete: (res, xhr) => {
      UserModel.fetchAll(() => {
        if (xhr.status === 200) {
          exports.defaultGetHandler(res, callback);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    }
  }, options));
};

// TODO Move to member profile model
// Demotes a user from the owner of a company, and all organizations within.
exports.demote = (profile, callback, options) => {
  Backend.put('/api/private/owner/profiles/' + profile.id + '/demote', _.assign({
    onComplete: (res, xhr) => {
      Async.eachInSequenceThen([UserModel.fetchLoggedInUserProfiles, UserModel.fetchAll], () => {
        if (xhr.status === 200) {
          exports.defaultGetHandler(res, callback);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    }
  }, options));
};

// Removes a profile from a company.
exports.removeProfile = (profile, callback, options) => {
  Backend.put('/api/private/owner/profiles/' + profile.id + '/remove', _.assign({
    onComplete: (res, xhr) => {
      UserModel.fetchAll(() => {
        if (xhr.status === 200) {
          exports.defaultGetHandler(res, callback);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    }
  }, options));
};

// Adds a removed profile back to a company so they can re-join orgs.
exports.reEnableRemovedProfile = (profile, callback, options) => {
  Backend.put('/api/private/owner/profiles/' + profile.id + '/enable', _.assign({
    onComplete: (res, xhr) => {
      UserModel.fetchAll(() => {
        if (xhr.status === 200) {
          exports.defaultGetHandler(res, callback);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    }
  }, options));
};

// If a profile has been removed and then re-enabled before a user can re-join
// any orgs, this disables it again to disallow them from re-joining.
exports.undoReEnabledRemovedProfile = (profile, callback, options) => {
  Backend.put('/api/private/owner/profiles/' + profile.id + '/disable', _.assign({
    onComplete: (res, xhr) => {
      UserModel.fetchAll(() => {
        if (xhr.status === 200) {
          exports.defaultGetHandler(res, callback);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    }
  }, options));
};

// Move to member model as we get the member back?
// Adds a new org permission to an existing profile within a company
exports.addPermission = _ref => {
  let {
    profile,
    role,
    org,
    callback,
    requestOpts
  } = _ref;
  const roleForRequest = Is.ownerOnly(UserModel.getLoggedInUserPermission()) ? 'owner' : 'admin';
  Backend.post('/api/private/' + roleForRequest + '/workspaces2/' + org.id + '/members', _.assign({
    data: {
      profile_id: profile.id,
      role
    },
    onComplete: (res, xhr) => {
      // We also request a new payment plan with the billable users count updated
      Async.eachInParallelThenWithShortCircuiting([UserModel.fetchAll, PaymentPlan2Model.fetch], () => {
        if (xhr.status === 201) {
          callback(null, res);
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      });
    },
    organization_id: org.id
  }, requestOpts));
};
exports.getItemsForRequesterAutocomplete = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const formatProfile = profile => {
    return options.fullRequesterProfiles ? profile : profile.id;
  };
  const currentProfile = exports.getCurrentUserProfileDetails();
  const profile = {
    name: UserDropdownTemplate.render(currentProfile),
    value: formatProfile(currentProfile)
  };
  const otherActiveUsers = exports.getAllOtherActiveProfileDetails();
  const observers = _.remove(otherActiveUsers, Is.observer);
  const profiles = _.map(otherActiveUsers, profile => {
    return {
      name: UserDropdownTemplate.render(profile),
      value: formatProfile(profile),
      matchers: exports.getMatchers(profile)
    };
  });
  if (observers.length) {
    profiles.push({
      hr: true
    });
    profiles.push({
      html: '<div class="caption">Observers</div>'
    });
    Iterate.each(observers, profile => {
      profiles.push({
        name: UserDropdownTemplate.render(profile),
        disabled: true,
        value: profile,
        matchers: exports.getMatchers(profile),
        tooltip: 'Observers cannot be a requester'
      });
    });
  }
  return [profile, {
    hr: true
  }].concat(profiles);
};
exports.getItemsForOwnerAutocomplete = (entity, owners, Model) => {
  const currentProfile = exports.getCurrentUserProfileDetails();
  const otherActiveUsers = exports.getAllOtherActiveProfileDetails();

  // Remove profiles with observer role from `otherActiveUsers`.
  _.remove(otherActiveUsers, Is.observer);
  const {
    membersByTeam
  } = entity;
  const userInOwners = owners.some(owner => UserModel.isLoggedInProfile(owner));
  const items = [];
  Model = _.isPlainObject(Model) ? Model : StoryModel;
  if (owners && owners.length > 0) {
    Iterate.each(_.sortBy(owners, 'lowercase_name'), profile => {
      items.push({
        name: UserDropdownTemplate.render(profile),
        value: profile,
        matchers: exports.getMatchers(profile),
        className: profile.className || 'selected',
        iconRight: profile.iconRight || 'fa-check'
      });
    });
    items.push({
      hr: true
    });
  }
  if (!userInOwners) {
    items.push({
      name: UserDropdownTemplate.render(currentProfile),
      value: currentProfile,
      matchers: exports.getMatchers(currentProfile),
      iconRight: 'fa-check'
    });
    items.push({
      hr: true
    });
  }
  if (membersByTeam) {
    Object.keys(membersByTeam).forEach(teamName => {
      items.push({
        name: teamName,
        disabled: true,
        className: 'subheading'
      });
      const members = membersByTeam[teamName];
      const membersNotInOwners = members.filter(member => !owners.some(owner => owner.id === member.id) && currentProfile.id !== member.id);
      membersNotInOwners.forEach(profile => {
        items.push({
          name: UserDropdownTemplate.render(profile),
          value: profile,
          matchers: exports.getMatchers(profile),
          className: ''
        });
      });
      items.push({
        hr: true
      });
    });
  }
  Iterate.each(otherActiveUsers, profile => {
    if (!Model.isOwner(entity, profile) && (!Model.isTeamMember || !Model.isTeamMember(entity, profile))) {
      items.push({
        name: UserDropdownTemplate.render(profile),
        value: profile,
        matchers: exports.getMatchers(profile),
        iconRight: 'fa-check'
      });
    }
  });
  items.unshift({
    hr: true
  });
  items.unshift({
    name: '<em>Nobody</em>',
    value: null
  });
  return items;
};
exports.getItemsForFollowerAutocomplete = (entity, Model) => {
  const currentProfile = exports.getCurrentUserProfileDetails();
  const followers = entity.followers;
  const items = [];
  let userInFollowers = false;
  Model = _.isPlainObject(Model) ? Model : StoryModel;
  if (followers && followers.length > 0) {
    Iterate.each(_.sortBy(followers, 'lowercase_name'), profile => {
      items.push({
        name: UserDropdownTemplate.render(profile),
        value: profile,
        matchers: exports.getMatchers(profile),
        className: profile.className || 'selected',
        iconRight: profile.iconRight || 'fa-check'
      });
      if (UserModel.isLoggedInProfile(profile)) {
        userInFollowers = true;
      }
    });
    items.push({
      hr: true
    });
  }
  if (!userInFollowers) {
    items.push({
      name: UserDropdownTemplate.render(currentProfile),
      value: currentProfile,
      matchers: exports.getMatchers(currentProfile),
      iconRight: 'fa-check'
    });
    items.push({
      hr: true
    });
  }
  Iterate.each(exports.getAllOtherActiveProfileDetails(), profile => {
    if (!Model.isFollower(entity, profile)) {
      items.push({
        name: UserDropdownTemplate.render(profile),
        value: profile,
        matchers: exports.getMatchers(profile),
        iconRight: 'fa-check'
      });
    }
  });
  return items;
};
exports.isFollower = function () {
  let followers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let profile = arguments.length > 1 ? arguments[1] : undefined;
  return _.includes(followers, _.get(profile, 'id'));
};
exports.currentUserIsFollower = function () {
  let followers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const profile = exports.getCurrentUserProfileDetails();
  return exports.isFollower(followers, profile);
};
exports.getAllRoles = () => {
  return {
    admin: {
      name: 'Admin',
      value: 'admin',
      note: 'All Member privileges, plus the ability to manage users and integrations.'
    },
    member: {
      name: 'Member',
      value: 'member',
      note: 'Members can view and manage data in this workspace.'
    },
    observer: {
      name: 'Observer',
      value: 'observer',
      note: 'Observers can only view data.'
    }
  };
};
const getAllRolesAsDropdownItems = () => {
  const {
    admin,
    member,
    observer
  } = exports.getAllRoles();
  return [{
    ...admin,
    iconLeft: 'fa-user',
    className: ''
  }, {
    ...member,
    iconLeft: 'fa-user',
    className: ''
  }, {
    ...observer,
    iconLeft: 'fa-user',
    className: ''
  }];
};
exports.getRolesForAutocomplete = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    excludeObserver
  } = options;
  const roles = getAllRolesAsDropdownItems();
  if (excludeObserver) {
    _.remove(roles, {
      value: 'observer'
    });
  }
  return roles;
};
exports.fetchAllHandler = (res, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  exports.trigger('bulkStart');
  Iterate.each(res, profile => {
    exports.updateIfValid(profile);
  });
  exports.trigger('bulkEnd');
  exports.trigger('fetched');
  callback();
};
export { exports as default };