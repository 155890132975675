import Logo from '@clubhouse/assets/png/third-party-logos/everhour_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Everhour() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.EVERHOUR
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Everhour"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Everhour Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Track time on tasks with just one click."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: "https://everhour.com/integrations/shortcut-time-tracking"
  }, "Learn more"), " on how Everhour integrates with Shortcut.")));
}
Everhour.displayName = "Everhour";