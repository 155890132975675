import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/zapier_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { loadScript } from '@clubhouse/shared/utils';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Zapier() {
  const readOnly = isReadOnly();
  useEffect(() => {
    loadScript('https://zapier.com/apps/embed/widget.js?services=clubhouse&html_id=zap-widget', {
      id: 'zap-js-embed'
    });
    return () => {
      document.querySelector('#zap-js-embed')?.remove();
    };
  }, []);
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.ZAPIER
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Zapier"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Zapier Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, ___EmotionJSX(ExternalLink, {
    href: "https://zapier.com/apps/shortcut/integrations"
  }, "Zapier"), " lets you to connect web apps to automate tasks. You can use events that occur in hundreds of apps to automatically create stories in ", BRAND.NAME, "."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, "Check out ", ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_ZAPIER
  }, "our instructions"), " for how to configure this integration in your Zapier account."), ___EmotionJSX("div", {
    id: "zap-widget"
  })));
}
Zapier.displayName = "Zapier";