import { StoryGroupByWorkflowFragmentFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ButtonText } from '@clubhouse/shared/components/Button';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupByHeader } from 'components/gql/groupBy/GroupByHeader';
import { jsx as ___EmotionJSX } from "@emotion/react";
const StoryGroupByWorkflowFragment = StoryGroupByWorkflowFragmentFragmentDoc;
export const WorkflowNameGroupHeader = _ref => {
  let {
    workflowState
  } = _ref;
  const workflow = workflowState?.workflow;
  return ___EmotionJSX(GroupByHeader, {
    entityType: Nouns.Workflow
  }, typeof workflow?.name === 'string' ? ___EmotionJSX(ButtonText, null, ___EmotionJSX(Emojify, null, workflow.name)) : 'No Workflow');
};
WorkflowNameGroupHeader.displayName = "WorkflowNameGroupHeader";