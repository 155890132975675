import { OrganizationsDashboardHeader } from './OrganizationsDashboardHeader';
import { Invites } from './invites/Invites';
import { OrganizationsList } from './organizations/OrganizationsList';
import { YourProfile } from './profile/YourProfile';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function OrganizationsDashboardPage() {
  return ___EmotionJSX("div", {
    className: "org-dashboard"
  }, ___EmotionJSX("article", null, ___EmotionJSX("section", {
    className: "organizations"
  }, ___EmotionJSX(OrganizationsDashboardHeader, null), ___EmotionJSX(Invites, null), ___EmotionJSX(OrganizationsList, {
    kind: "active"
  }), ___EmotionJSX(OrganizationsList, {
    kind: "inactive"
  })), ___EmotionJSX(YourProfile, null)));
}
OrganizationsDashboardPage.displayName = "OrganizationsDashboardPage";