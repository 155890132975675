import { QueryIterationPageReportConfigDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { CHARTS } from '@clubhouse/shared/types';
import { Explanation } from 'components/reports/cumulativeFlow/Explanation';
import { useScrollTarget } from 'components/shared/scrollTabs/shared';
import { useWorkspaceUtcOffset } from 'data/entity/organization';
import { getCurrentAggregationType } from 'data/entity/report';
import { useQuery } from 'gql';
import { getCurrentPage, getParamFromUrl, useWorkspaceSlug } from 'utils/navigation';
import { useReportConfigState } from '../hooks/useReportConfigState';
import { ChartErrorBoundary } from '../reports-v2/ChartErrorBoundary';
import { CHART_NO_DATA_HEIGHT } from '../reports-v2/ChartUnstartedUtils';
import { ReportCard } from '../reports-v2/ReportCard';
import { ReportCardHeaderEmptyState } from '../reports-v2/ReportCardHeaderEmptyState';
import { StoryPointsToggle } from '../reports-v2/StoriesPointsToggle';
import { CUMULATIVE_FLOW_CHART_HEIGHT, CUMULATIVE_FLOW_WORKFLOW_ID_SEARCH_PARAM } from '../reports-v2/constants';
import { CumulativeFlow } from './CumulativeFlow';
import { WorkflowSelect } from './WorkflowSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ITERATION_PAGE_REPORT_CONFIG = QueryIterationPageReportConfigDocument;
const CHART_ID = CHARTS.CUMULATIVE_FLOW;
const CumulativeFlowReportCardInner = _ref => {
  let {
    iterationId,
    startDate,
    endDate,
    iterationStatus
  } = _ref;
  const [{
    workflowId
  }, setWorkflowId] = useReportConfigState(CUMULATIVE_FLOW_WORKFLOW_ID_SEARCH_PARAM, iterationId);
  const [aggregateFilterVal, setAggregateFilterVal] = useState(getParamFromUrl('cf_aggregate') || getCurrentAggregationType());
  const isUnstartedIteration = iterationStatus === 'unstarted';
  const slug = useWorkspaceSlug();
  const {
    data,
    loading,
    error
  } = useQuery(ITERATION_PAGE_REPORT_CONFIG, {
    variables: {
      slug,
      iterationId
    }
  });
  const workflowSelect = data?.workspace2?.iterationPage?.reportConfig?.workflowSelect;
  const handleAggreateFilterVal = val => {
    setAggregateFilterVal(val);
  };
  if (error) {
    throw new Error('Error loading cumulative flow chart data: ', {
      cause: error
    });
  }
  const workspaceUtcOffset = useWorkspaceUtcOffset();
  return ___EmotionJSX(ReportCard, {
    title: "Cumulative Flow Diagram",
    className: "cfd-report-header",
    description: "Work completed over time, grouped by workflow state.",
    Explanation: Explanation,
    Filters: () => isUnstartedIteration ? ___EmotionJSX(ReportCardHeaderEmptyState, {
      iterationStartDate: startDate,
      workspaceUtcOffset: workspaceUtcOffset
    }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(WorkflowSelect, {
      isLoading: loading,
      options: workflowSelect?.valueOptions?.edges || [],
      selectedValue: workflowId || workflowSelect?.value,
      onChange: workflowId => setWorkflowId({
        workflowId
      })
    }), ___EmotionJSX(StoryPointsToggle, {
      checkedValue: aggregateFilterVal,
      onChange: handleAggreateFilterVal
    })),
    chartId: CHART_ID
  }, ___EmotionJSX("div", {
    style: {
      minHeight: iterationStatus ? CHART_NO_DATA_HEIGHT : CUMULATIVE_FLOW_CHART_HEIGHT
    },
    id: "cumulative-flow-container"
  }, ___EmotionJSX(CumulativeFlow, {
    iterationId: iterationId,
    startDate: startDate,
    endDate: endDate,
    workflowId: workflowId,
    isUnstartedIteration: isUnstartedIteration,
    aggregateFilterVal: aggregateFilterVal
  })));
};
CumulativeFlowReportCardInner.displayName = "CumulativeFlowReportCardInner";
export const CumulativeFlowReportCard = props => {
  const pageName = getCurrentPage();
  const scrollTargetProps = useScrollTarget('cumulative-flow-report-card');
  return (
    // This div is used as a scroll target for the chart's scroll into view functionality.
    ___EmotionJSX("div", scrollTargetProps, ___EmotionJSX(ChartErrorBoundary, {
      chart: "cumulative flow",
      pageName: pageName,
      chartId: CHART_ID
    }, ___EmotionJSX(CumulativeFlowReportCardInner, props)))
  );
};
CumulativeFlowReportCard.displayName = "CumulativeFlowReportCard";