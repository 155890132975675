function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { useState } from 'react';
import { useMountedState } from 'react-use';
import { Button } from '@clubhouse/shared/components/Button/';
import { freePlanIneligibleForTrial, pendingTierDowngradeProduct } from 'data/entity/paymentPlan2';
import { isLoggedInUserOwner } from 'data/entity/user';
import { getPlanState } from 'utils/manageBilling/planStateMachine';
import { TIERS, getTier } from 'utils/tiers';
import { jsx as ___EmotionJSX } from "@emotion/react";
const NeutralButton = props => ___EmotionJSX("div", {
  className: "plan-selection-button-container"
}, ___EmotionJSX(Button, _extends({
  kind: Button.KIND.SECONDARY,
  fullWidth: true
}, props)));
NeutralButton.displayName = "NeutralButton";
const SaveButton = props => ___EmotionJSX("div", {
  className: "plan-selection-button-container"
}, ___EmotionJSX(Button, _extends({
  kind: Button.KIND.PRIMARY,
  fullWidth: true
}, props)));
SaveButton.displayName = "SaveButton";
export const PlanSelectButton = _ref => {
  let {
    product,
    plan,
    currentProduct,
    onProductSelect,
    allowNonOwnerInteraction
  } = _ref;
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    const def = onProductSelect(product.display_key);
    if (def) {
      isMounted() && setIsLoading(true);
      // Don't try update state if component has been unmounted
      def.then(() => isMounted() && setIsLoading(false));
    }
  };
  const isTrialing = ('trialing' in plan.statuses);
  const pendingTier = pendingTierDowngradeProduct(plan)?.display_key;
  const eligibleForFree = !isTrialing || !freePlanIneligibleForTrial(plan);
  const planStatus = getPlanState(plan, currentProduct);
  const isOwner = isLoggedInUserOwner();
  const tier = product.display_key;
  const isEnterprise = TIERS.ENTERPRISE === tier;
  const isFree = TIERS.FREE_LIMITED === tier;
  const tierIsPendingProduct = pendingTier === tier;
  const tierIsCurrentProduct = currentProduct?.display_key === tier;
  const isDisabled = isFree && !eligibleForFree || !allowNonOwnerInteraction && !isOwner;
  const {
    title
  } = getTier(product);
  const ButtonComponent = isFree ? NeutralButton : SaveButton;
  if (planStatus === 'PLAN_IS_CHANGING_SELECTION' && tierIsPendingProduct) {
    return ___EmotionJSX(NeutralButton, {
      isDisabled: true
    }, "Pending");
  }
  if (planStatus === 'PLAN_IS_CHANGING_SELECTION' && tierIsCurrentProduct) {
    return ___EmotionJSX(ButtonComponent, {
      isDisabled: isDisabled,
      onClick: handleClick,
      showProgress: isLoading
    }, "Stay on ", title);
  }
  if ((planStatus === 'PLAN_HAS_SELECTION' || planStatus === 'PLAN_TRIALING_AND_HAS_SELECTION') && tierIsCurrentProduct) {
    return ___EmotionJSX(ButtonComponent, {
      isDisabled: true
    }, "Current Plan");
  }
  if (isEnterprise) {
    return ___EmotionJSX(SaveButton, {
      isDisabled: isDisabled,
      onClick: handleClick,
      showProgress: isLoading
    }, "Contact Us");
  }
  if (isDisabled) {
    return ___EmotionJSX(ButtonComponent, {
      isDisabled: true
    }, title, " Plan");
  }
  return ___EmotionJSX(ButtonComponent, {
    isDisabled: isDisabled,
    onClick: handleClick,
    showProgress: isLoading
  }, "Select");
};
PlanSelectButton.displayName = "PlanSelectButton";