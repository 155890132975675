function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { ContextMenu, ContextMenuDivider, ContextMenuLoading } from '@clubhouse/shared/components/ContextMenu';
import { Nouns } from '@clubhouse/shared/constants';
import { getHref } from 'components/gql/stories/links';
import { CopyContextMenuItem, OpenInNewTabContextMenuItem, PinContextMenuItem } from '../actions';
import { useContextMenuWithLogging } from '../useContextMenuWithLogging';
import { MoveToIterationContextMenuItem, ToggleOwnerContextMenuItem } from './components';
import { useFetchStoryContextMenuData } from './hooks';
import { jsx as ___EmotionJSX } from "@emotion/react";
const LOADING_TEMPLATE = '0-000-0000-0';
const StoryContextMenu = _ref => {
  let {
    id
  } = _ref;
  const data = useFetchStoryContextMenuData({
    id
  });
  if (!data) return ___EmotionJSX(ContextMenuLoading, {
    template: LOADING_TEMPLATE
  });
  const {
    publicId,
    name,
    owners,
    type,
    formattedVcsBranchName
  } = data;
  return ___EmotionJSX(ContextMenu, null, ___EmotionJSX(OpenInNewTabContextMenuItem, {
    url: getHref(publicId, name)
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(ToggleOwnerContextMenuItem, {
    id: id,
    name: name,
    owners: owners,
    publicId: publicId,
    type: type
  }), ___EmotionJSX(MoveToIterationContextMenuItem, _extends({}, data, {
    moveTo: "current"
  })), ___EmotionJSX(MoveToIterationContextMenuItem, _extends({}, data, {
    moveTo: "next"
  })), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Link",
    value: getHref(publicId, name, true),
    label: "Link"
  }), ___EmotionJSX(CopyContextMenuItem, {
    value: String(publicId),
    label: "ID"
  }), ___EmotionJSX(CopyContextMenuItem, {
    icon: "CopyDocument",
    value: name,
    label: "Title"
  }), ___EmotionJSX(CopyContextMenuItem, {
    icon: "Branch",
    isDisabled: !formattedVcsBranchName,
    value: formattedVcsBranchName || '',
    label: "Branch Name"
  }), ___EmotionJSX(ContextMenuDivider, null), ___EmotionJSX(PinContextMenuItem, {
    id: id,
    entityType: Nouns.Story,
    loggingContext: {
      typename: 'Story'
    }
  }));
};
StoryContextMenu.displayName = "StoryContextMenu";
export const useStoryContextMenu = id => useContextMenuWithLogging(() => ___EmotionJSX(StoryContextMenu, {
  id: id
}), {
  entityType: 'Story'
}).props;