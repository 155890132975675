function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { TableRow, TableRowForInfiniteScroll } from '@clubhouse/shared/components/Table';
import { useCollectionizeStoryContextMenu } from 'utils/contextMenus/useCollectionizeStoryContextMenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function StoryRow(props) {
  const contextMenuProps = useCollectionizeStoryContextMenu(props.item.publicId);
  return ___EmotionJSX(TableRow, _extends({}, props, {
    contextMenuProps: contextMenuProps
  }));
}
StoryRow.displayName = "StoryRow";
export function StoryRowForInfiniteScroll(props) {
  const contextMenuProps = useCollectionizeStoryContextMenu(props.item.publicId);
  return ___EmotionJSX(TableRowForInfiniteScroll, _extends({}, props, {
    contextMenuProps: contextMenuProps
  }));
}
StoryRowForInfiniteScroll.displayName = "StoryRowForInfiniteScroll";