import { MutateStoryIterationDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryIterationSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { IterationFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { IterationSelectTarget } from 'components/gql/iteration/IterationSelectTarget';
import { getSection } from 'components/gql/iteration/utils/sections';
import { useFieldOptionsQuery } from 'components/gql/stories/table/fields/useFieldOptionsQuery';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useMutationContext } from './FieldContextProvider';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const IterationFieldFragment = IterationFieldFragmentFragmentDoc;
const QUERY_ITERATION_SELECT = QueryStoryIterationSelectDocument;
const MUTATE_ITERATION_FIELD = MutateStoryIterationDocument;
export const IterationFieldComponent = _ref => {
  let {
    entity: {
      id: storyId,
      iteration
    }
  } = _ref;
  const mutationContext = useMutationContext();
  const fetchIterations = useFieldOptionsQuery({
    query: QUERY_ITERATION_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const [updateIteration] = useMutation(MUTATE_ITERATION_FIELD);
  const handleChange = useCallback(async iteration => {
    await updateIteration({
      variables: {
        storyId,
        input: {
          iteration
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        iteration: iteration ? {
          __typename: 'Iteration',
          id: iteration
        } : null
      }),
      ...mutationContext
    });
  }, [updateIteration, storyId, mutationContext]);
  return ___EmotionJSX(GroupedOptionsFieldSingleSelection, {
    selectedEntity: iteration,
    fetchOptions: fetchIterations,
    getSection: getSection,
    onChange: handleChange,
    TargetComponent: IterationSelectTarget,
    unit: Nouns.Iteration
  });
};
IterationFieldComponent.displayName = "IterationFieldComponent";
IterationFieldComponent.displayName = 'IterationFieldComponent';
export const IterationField = () => ({
  name: 'iterationIds',
  displayName: 'Iteration',
  sort: 'iteration',
  LoadingComponent: () => ___EmotionJSX(Center, null, ___EmotionJSX(SkeletonRectangle, null)),
  Component: IterationFieldComponent,
  width: 170
});