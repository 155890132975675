import "core-js/modules/es.array.push.js";
import ArchiveWarningController from 'app/client/core/js/controllers/archiveWarning.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'ArchiveWarning'], ArchiveWarningController], [['Controller', 'ArchiveWarning'], ArchiveWarningController]].reduce((accum, _ref) => {
    let [op, n] = _ref;
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function ArchiveWarning(_ref2) {
  let {
    type,
    title
  } = _ref2;
  return ___EmotionJSX("div", {
    "data-controller": "ArchiveWarning"
  }, ___EmotionJSX("h2", {
    id: "archive-label"
  }, "Are you sure you want to archive this ", type, "?"), ___EmotionJSX("div", {
    className: "blurb",
    id: "archive-description"
  }, ___EmotionJSX("p", null, type === 'Epic' || type === 'Project' ? ___EmotionJSX(React.Fragment, null, "Archiving this ", type, " will exclude it and all of its Stories from reports and charts, as well as from the Stories, Status, and My Work pages.") : type === 'Story' ? ___EmotionJSX(React.Fragment, null, "Archiving this ", type, " will exclude it from all reports and charts, as well as from the Stories page.") : type === 'Label' ? ___EmotionJSX(React.Fragment, null, "Archiving this ", type, " removes it from the Labels dropdown list. It does ", ___EmotionJSX("em", null, "not"), " remove the ", type, ' ', "from associated Stories or Epics.") : null), ___EmotionJSX("p", null, "Continue archiving", ' ', ___EmotionJSX("strong", {
    className: "title"
  }, ___EmotionJSX(Emojify, null, title)), "?")), ___EmotionJSX("div", {
    className: "actions"
  }, ___EmotionJSX("button", {
    type: "button",
    className: "action elevated",
    id: "no-archive-button",
    "data-on-click": "close"
  }, "No"), ___EmotionJSX("button", {
    type: "button",
    className: "action red",
    id: "approve-archive-button",
    "data-on-click": "approve"
  }, "Yes")));
}
ArchiveWarning.displayName = "ArchiveWarning";