function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { TimeUnitSelectReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const TimeUnitSelectReport_SelectorFragement = TimeUnitSelectReport_SelectorFragmentDoc;
export const TimeUnitSelect = props => {
  return ___EmotionJSX(ReportSelectFilter, _extends({}, props, {
    label: "Time Unit"
  }));
};
TimeUnitSelect.displayName = "TimeUnitSelect";