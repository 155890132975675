import { SIZE } from '@clubhouse/shared/components/Avatar';
import { UserCohortPaged } from '@clubhouse/shared/components/UserCohort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const UserCohort = _ref => {
  let {
    totalMemberCount,
    users
  } = _ref;
  return ___EmotionJSX(UserCohortPaged, {
    users: users.slice(0, 6),
    size: SIZE.S,
    hasBorder: false,
    totalNumberOfUsers: totalMemberCount
  });
};
UserCohort.displayName = "UserCohort";