import { ICON_DEFAULT_WIDTH } from '../../utils/icon';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ReportLeadCycleTime = _ref => {
  let {
    width = ICON_DEFAULT_WIDTH
  } = _ref;
  return ___EmotionJSX("svg", {
    width: width,
    height: width,
    viewBox: "0 0 46 46",
    fill: "none"
  }, ___EmotionJSX("path", {
    d: "M40.0966 9.04666H36.4933C36.34 9.04666 36.11 9.04666 35.9566 9.12333L25.07 12.9567C24.61 13.11 24.15 13.11 23.69 12.88L19.7033 10.81C19.0133 10.4267 18.17 10.58 17.6333 11.1933L8.58662 21.5433C8.12662 22.08 8.04996 22.8467 8.35662 23.5367C10.35 26.9867 16.6366 37.95 17.1733 37.95C17.6333 37.95 33.4266 37.7967 40.0966 37.72C41.0166 37.72 41.7833 36.9533 41.7833 36.0333V10.7333C41.7833 9.81333 41.0933 9.04666 40.0966 9.04666Z",
    fill: "#EAEAEA"
  }), ___EmotionJSX("path", {
    d: "M1.22663 34.73L7.81996 22.3867L18.7833 25.9133L26.91 19.0133L36.7233 15.3333L44.6966 18.4767",
    stroke: "#2BA848",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), ___EmotionJSX("path", {
    d: "M19.4733 32.3533C20.0661 32.3533 20.5467 31.8728 20.5467 31.28C20.5467 30.6872 20.0661 30.2067 19.4733 30.2067C18.8805 30.2067 18.4 30.6872 18.4 31.28C18.4 31.8728 18.8805 32.3533 19.4733 32.3533Z",
    fill: "#2BA848"
  }), ___EmotionJSX("path", {
    d: "M27.6766 27.7533C28.2694 27.7533 28.7499 27.2728 28.7499 26.68C28.7499 26.0872 28.2694 25.6067 27.6766 25.6067C27.0838 25.6067 26.6033 26.0872 26.6033 26.68C26.6033 27.2728 27.0838 27.7533 27.6766 27.7533Z",
    fill: "#2BA848"
  }), ___EmotionJSX("path", {
    d: "M38.64 27.7533C39.2328 27.7533 39.7134 27.2728 39.7134 26.68C39.7134 26.0872 39.2328 25.6067 38.64 25.6067C38.0472 25.6067 37.5667 26.0872 37.5667 26.68C37.5667 27.2728 38.0472 27.7533 38.64 27.7533Z",
    fill: "#2BA848"
  }));
};
ReportLeadCycleTime.displayName = "ReportLeadCycleTime";