import { focusOutlineClass } from '@clubhouse/shared/styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function LoadMore(_ref) {
  let {
    loading,
    loadMore
  } = _ref;
  return ___EmotionJSX("div", {
    className: "load-more-search-results-button"
  }, ___EmotionJSX("button", {
    onClick: loadMore,
    className: `${focusOutlineClass} action mini ${loading ? ' light-gray disabled' : 'flat-white'}`
  }, loading && ___EmotionJSX("span", {
    className: "fa fa-spin fa-star"
  }), loading ? 'Loading' : 'Load more...'));
}
LoadMore.displayName = "LoadMore";