import "core-js/modules/es.array.push.js";
import { Icon, StatusIcon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ToggleSidebarVisibility, isSidebarOpen, setSidebarVisibility } from 'components/stories/ToggleSidebarVisibility';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import Dialog from 'app/client/core/js/modules/dialog';
import DropdownController from 'app/client/core/js/controllers/dropdown';
import EpicController from './epic';
import * as Event from 'app/client/core/js/_frontloader/event';
import Is from 'app/client/core/js/modules/is';
import IterationController from './iteration';
import ProjectController from './project';
import SpaceModel from 'app/client/core/js/models/space';
import StoriesView from '../modules/storiesView';
import ViewSettingsController from 'app/client/core/js/controllers/viewSettings';
import TeamModel from 'app/client/core/js/models/team';
import DOM from 'app/client/core/js/modules/dom';
import Tooltip from 'app/client/core/js/modules/tooltip';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import { GroupSidebarFilter } from 'components/stories/GroupSidebarFilter';
import { CustomFieldFilter, CustomFieldSidebarFilters } from 'components/stories/CustomFieldSidebarFilters';
import { ProjectSidebarFilter } from 'components/stories/ProjectSidebarFilter';
import { FacetHeader } from 'components/stories/FacetHeader';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isScopedToTeam } from 'components/team-navigation';
import { capitalize } from '@clubhouse/shared/utils';
import { WorkflowFilter } from 'pages/stories/WorkflowFilter';
import { hasSnapshot } from 'data/page/stories/spaces';
import { FilterHeader } from 'pages/stories/sidebar/FilterHeader';
import { getStateByKey, subscribeToFacetChange } from 'utils/sidebarFilterToggles';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const EVENT_NS = '.SidebarController';
exports.HIDDEN_CLASSNAME = 'sidebar-hidden';
let shadowObserver;
let legacyEventHandlers = [];
exports.onResize = () => {
  const sidebar = $('#sidebar');
  if (sidebar.length === 0 || Is.mobile()) {
    return;
  }
  const marginBottom = 22;
  sidebar.height(1);
  sidebar.height($(window).height() - sidebar.offset().top - marginBottom);
};
exports.onScroll = () => {
  const scrollCheck = document.getElementById('scroll-check');
  if (scrollCheck) {
    shadowObserver = new IntersectionObserver(_ref => {
      let [entry] = _ref;
      const sidebar = document.getElementById('sidebar');
      if (entry.intersectionRatio === 0) {
        sidebar?.classList.add('scroll-shadow');
      } else {
        sidebar?.classList.remove('scroll-shadow');
      }
    }, {
      threshold: [0],
      root: document.getElementById('content')
    });
    shadowObserver.observe(scrollCheck);
  }
};
exports.openDropdown = function () {
  const items = [{
    html: '<div class="caption">Story Density</div>'
  }, {
    iconLeft: ViewSettingsController.density.isTitleOnly() ? 'fa-circle' : 'fa-circle-o',
    name: 'Title Only',
    value: ViewSettingsController.density.setTitleOnly
  }, {
    iconLeft: ViewSettingsController.density.isMinimal() ? 'fa-circle' : 'fa-circle-o',
    name: 'Minimal',
    value: ViewSettingsController.density.setMinimal
  }, {
    iconLeft: ViewSettingsController.density.isStandard() ? 'fa-circle' : 'fa-circle-o',
    name: 'Standard',
    value: ViewSettingsController.density.setStandard
  }, {
    iconLeft: ViewSettingsController.density.isJumbo() ? 'fa-circle' : 'fa-circle-o',
    name: 'Jumbo',
    value: ViewSettingsController.density.setJumbo
  }, {
    hr: true
  }, {
    name: 'Collapse Sidebar',
    value: exports.hide,
    iconLeft: 'fa-caret-left'
  }];
  Tooltip.close();
  DropdownController.open({
    items,
    target: this,
    width: 160
  });
  return false;
};
exports.isClosed = () => !isSidebarOpen();
exports.isOpen = () => isSidebarOpen();
exports.show = () => {
  setSidebarVisibility(true);
  exports.renderVisibilityToggle({
    isExpanded: true
  });
  $('html').removeClass(exports.HIDDEN_CLASSNAME);
  if (Is.mobile()) {
    exports.showMobile();
  }
  StoriesView.updateColumnDimensions();
  return false;
};
exports.showMobile = () => {
  $('#sidebar-toggle').hide();
  $('#sidebar').css({
    display: 'block',
    left: -224
  }).animate({
    left: 0
  }, 200, 'easeOutQuad');
  $('#sidebar-overlay').css({
    position: 'fixed',
    display: 'block',
    minHeight: '100%',
    opacity: 0
  }).animate({
    opacity: 1
  }, 200, 'easeOutQuad');
};
exports.hide = () => {
  setSidebarVisibility(false);
  exports.renderVisibilityToggle({
    isExpanded: false
  });
  $('html').addClass(exports.HIDDEN_CLASSNAME);
  if (Is.mobile()) {
    exports.hideMobile();
  }
  StoriesView.updateColumnDimensions();
  return false;
};
exports.hideMobile = () => {
  $('#sidebar-toggle').show();
  $('#sidebar').css({
    display: 'block',
    left: 0
  }).animate({
    left: -224
  }, 200, 'easeInQuad', function () {
    $(this).css({
      display: 'none'
    });
  });
  $('#sidebar-overlay').css({
    display: 'block',
    opacity: 1
  }).animate({
    opacity: 0
  }, 200, 'easeInQuad', function () {
    $(this).css({
      display: 'none'
    });
  });
  Utils.scrollToTop();
};
exports.initImmediately = () => {
  if (exports.isClosed()) {
    $('html').addClass(exports.HIDDEN_CLASSNAME);
  }
};
exports.renderFilterToggleHeader = () => View.renderComponentDelayed({
  componentKey: 'sidebar-filter-header',
  component: ___EmotionJSX(FilterHeader, null)
}).html;
exports.renderWorkflowFilter = () => View.renderComponentDelayed({
  componentKey: 'workflow-filter',
  component: ___EmotionJSX(WorkflowFilter, {
    onChange: exports.onTeamSelection
  })
}).html;
exports.onTeamSelection = () => {
  Event.trigger('teamSelectedInSidebar');
  const activeSpace = SpaceModel.getActive();
  if (activeSpace && (!activeSpace.readymade || hasSnapshot(activeSpace.id))) {
    Event.trigger('spaceUpdate');
  }
  SpaceModel.reloadActiveSpace();
  EpicController.render();
  ProjectController.render();
  if (TeamModel.activeTeamHasProjects()) {
    AddNewStoryController.resetProject();
  }
  IterationController.render();
};

// After data fetch
exports.init = () => {
  exports.onResize();
  exports.onScroll();
  IterationController.init();
  exports.renderGroupsSidebar();
  if (areCustomFieldsEnabled()) exports.renderCustomFieldsSidebar();
  Event.onlyOn('fullscreenEnabled' + EVENT_NS, exports.show);
  Event.onlyOn('fullscreenDisabled' + EVENT_NS, exports.hide);
  Event.onlyOn('teamSelectedInManageTeamsDialog' + EVENT_NS, () => {
    Dialog.close();
    exports.onTeamSelection();
  });
};
exports.destroy = () => {
  shadowObserver?.disconnect();
  legacyEventHandlers.forEach(unsubscribe => unsubscribe());
  legacyEventHandlers = [];
};
exports.isExpanded = filterId => {
  return getStateByKey(filterId);
};
exports.renderGroupsSidebar = () => {
  if (isScopedToTeam()) {
    return '';
  }
  return View.renderComponentDelayed({
    componentKey: 'storiesPageGroupsSidebar',
    component: ___EmotionJSX(GroupSidebarFilter, {
      onItemsSelected: () => {
        EpicController.render();
        IterationController.render();
        exports.renderGroupsSidebar();
        Event.trigger('spaceUpdate');
        StoriesView.resetAndDrawStories();
      }
    })
  }).html;
};
exports.renderProjectsSidebar = () => {
  return View.renderComponentDelayed({
    componentKey: 'storiesPageProjectsSidebar',
    component: ___EmotionJSX(ProjectSidebarFilter, {
      onItemsSelected: () => {
        EpicController.render();
        IterationController.render();
        exports.renderProjectsSidebar();
        Event.trigger('spaceUpdate');
        StoriesView.resetAndDrawStories();
      }
    })
  }).html;
};

// BEGIN: Support for legacy filters
// This code can be removed once Epics, Iterations, and
//  Workflow States filters are converted to React
const onExpand = {
  Epics: () => EpicController.renderListItems(),
  Iterations: () => IterationController.renderListItems()
};
const addFilterEventListener = (listName, listSelector) => {
  return subscribeToFacetChange(listName, newState => {
    const listElement = document.querySelector(`.sidebar-contents ${listSelector}.list-nav`);
    if (!listElement) return;
    if (newState) {
      onExpand[listName]?.();
      $(listElement).slideDown();
      DOM.show(listElement);
    } else {
      $(listElement).slideUp();
      DOM.hide(listElement);
    }
  });
};
exports.renderFacetHeading = (name, listName, listSelector) => {
  // set up events for state
  legacyEventHandlers.push(addFilterEventListener(listName, listSelector));
  return View.renderComponentDelayed({
    componentKey: `${listName}`,
    component: ___EmotionJSX(FacetHeader, {
      name: name,
      listName: listName
    })
  }).html;
};
// END: Support for legacy filters

exports.renderVisibilityToggle = _ref2 => {
  let {
    isExpanded
  } = _ref2;
  return View.renderComponentDelayed({
    componentKey: 'toggle-sidebar-visibility',
    component: ___EmotionJSX(ToggleSidebarVisibility, {
      isExpanded: isExpanded
    })
  }).html;
};
exports.renderCustomFieldsSidebar = () => {
  return View.renderComponentDelayed({
    componentKey: 'storiesPageCustomFieldsSidebar',
    component: ___EmotionJSX(CustomFieldSidebarFilters, {
      fieldRenderer: field => ___EmotionJSX(CustomFieldFilter, {
        key: field.id,
        field: field,
        onItemSelected: () => {
          EpicController.render();
          IterationController.render();
          exports.renderCustomFieldsSidebar();
          Event.trigger('spaceUpdate');
          StoriesView.resetAndDrawStories();
        }
      })
    })
  }).html;
};
exports.renderStatusIcon = (id, status) => {
  const icon = capitalize(status);
  return View.renderComponentDelayed({
    componentKey: `iteration-icon-${id}`,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 18
    }, ___EmotionJSX(StatusIcon, {
      icon: icon
    }))
  }).html;
};
exports.renderFiltersIcon = id => View.renderComponentDelayed({
  componentKey: `filter-icon-${id}`,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    className: "filter-icon",
    fill: "secondary",
    width: 18
  }, ___EmotionJSX(Icon, {
    icon: "ViewSettings"
  }))
}).html;
export { exports as default };