import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const createEmojiItem = _ref => {
  let {
    IconLeft,
    text,
    value
  } = _ref;
  return {
    IconLeft,
    textComponent: ___EmotionJSX(Emojify, null, text),
    text,
    value
  };
};