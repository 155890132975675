function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { InternalSelect } from '../InternalSelect';
import { LoadingOptionDefault } from '../SelectOption';
import { WIDTH } from '../utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const LoadingItems = [{
  Component: LoadingOptionDefault,
  key: 'loading 1'
}, {
  Component: LoadingOptionDefault,
  key: 'loading 2'
}, {
  Component: LoadingOptionDefault,
  key: 'loading 3'
}];
export function AsyncSelect(_ref) {
  let {
    items,
    onOpen,
    onFetch,
    totalItemCount,
    unit,
    isSearchable = true,
    FooterComponent,
    targetComponentProps,
    ...props
  } = _ref;
  const isLoading = items === null;
  return ___EmotionJSX(InternalSelect, _extends({
    isSearchable: isSearchable,
    items: isLoading ? LoadingItems : items,
    isSelectionDisabled: isLoading,
    totalItemCount: totalItemCount || 0,
    unit: unit?.singular,
    "aria-busy": isLoading ? true : false,
    onOpen: onOpen,
    onBeforeOpen: onFetch,
    onFilter: onFetch,
    inputDebounceMs: 300,
    FooterComponent: FooterComponent,
    targetComponentProps: targetComponentProps
  }, props));
}
AsyncSelect.displayName = "AsyncSelect";
AsyncSelect.WIDTH = WIDTH;