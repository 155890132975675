import { useRef, useState } from 'react';
import { Combobox } from '@clubhouse/shared/components/Combobox';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { FNS } from '../MarkdownDisplay/tableFns';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function TableFnsMenu(_ref) {
  let {
    triggerRef,
    filter
  } = _ref;
  const [query, setQuery] = useState(filter ?? '');
  const lastFilter = useRef(filter);
  if (typeof filter === 'string' && lastFilter.current !== filter) {
    lastFilter.current = filter;
    setQuery(filter);
  }
  const usedQuery = query.toLowerCase().replace(/\(\)?$/, '');
  const matchingItems = Object.entries(FNS).filter(_ref2 => {
    let [key] = _ref2;
    return key.toLowerCase().startsWith(usedQuery);
  });
  if (!matchingItems.length) return null;
  return ___EmotionJSX(Combobox.Menu, {
    triggerRef: triggerRef,
    isModal: false
  }, ___EmotionJSX(Combobox.List, null, matchingItems.map(_ref3 => {
    let [key, {
      label
    }] = _ref3;
    return ___EmotionJSX(Combobox.SelectableItem, {
      key: key,
      value: `=${key}`
    }, ___EmotionJSX(Spaced, {
      vertically: true,
      amount: 2
    }, key, ___EmotionJSX("small", null, label)));
  })));
}
TableFnsMenu.displayName = "TableFnsMenu";