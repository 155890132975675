import { UpdateIterationFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { UpdateEpicFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { UpdateStoryFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { getApolloClient } from 'gql/utils/initApollo';

/**
 * Function to process and update the Apollo Cache based on the REST API `/updates` endpoint.
 *
 * This is not meant to be 1:1 as most things don't have a direct mapping between REST and GraphQL.
 */
export async function updateApolloCacheFromRestUpdates(res) {
  const client = await getApolloClient();
  if (!client) {
    return;
  }
  if (res.updated_stories?.modified?.length) {
    res.updated_stories?.modified.forEach(story => {
      // We can only update Apollo Cache if we have a global id for the updated entity.
      if (!story.global_id) {
        return;
      }
      const updateStoryFragment = UpdateStoryFragmentDoc;
      client.writeFragment({
        id: client.cache.identify({
          id: story.global_id,
          __typename: 'Story'
        }),
        fragment: updateStoryFragment,
        data: {
          __typename: 'Story',
          id: story.global_id,
          archived: story.archived,
          name: story.name,
          type: story.story_type
        }
      });
    });
  }
  if (res.updated_epics?.modified?.length) {
    res.updated_epics?.modified.forEach(epic => {
      // We can only update Apollo Cache if we have a global id for the updated entity.
      if (!epic.global_id) {
        return;
      }
      const updateEpicFragment = UpdateEpicFragmentDoc;
      client.writeFragment({
        id: client.cache.identify({
          id: epic.global_id,
          __typename: 'Epic'
        }),
        fragment: updateEpicFragment,
        data: {
          __typename: 'Epic',
          id: epic.global_id,
          archived: epic.archived,
          name: epic.name
        }
      });
    });
  }
  if (res.updated_iterations?.modified?.length) {
    res.updated_iterations?.modified.forEach(iteration => {
      // We can only update Apollo Cache if we have a global id for the updated entity.
      if (!iteration.global_id) {
        return;
      }
      const updateIterationFragment = UpdateIterationFragmentDoc;
      client.writeFragment({
        id: client.cache.identify({
          id: iteration.global_id,
          __typename: 'Iteration'
        }),
        fragment: updateIterationFragment,
        data: {
          __typename: 'Iteration',
          id: iteration.global_id,
          name: iteration.name,
          state: iteration.status
        }
      });
    });
  }
}