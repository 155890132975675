import { LoadingState } from './shared/LoadingState';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const DownloadingState = _ref => {
  let {
    platformLabel = ''
  } = _ref;
  return ___EmotionJSX(LoadingState, {
    title: `Please wait while we download your ${platformLabel} data.`,
    message: `We will need your help mapping this data into ${BRAND.NAME} when this is completed.`
  });
};
DownloadingState.displayName = "DownloadingState";