function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import { Combobox } from '@clubhouse/shared/components/Combobox';
import { useIsOpen, useTriggerRef } from '../ComboboxSelectContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Menu = Object.assign(props => {
  const triggerRef = useTriggerRef();
  return useIsOpen() ? ___EmotionJSX(Combobox.Menu, _extends({
    triggerRef: triggerRef
  }, props)) : null;
}, {
  Z_INDEX: Combobox.Menu.Z_INDEX
});