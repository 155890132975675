import { TeamHeaderFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { useTeamContextMenu } from 'utils/contextMenus/useTeamContextMenu';
import { Header } from './Header';
import { HeaderText } from './HeaderText';
import { jsx as ___EmotionJSX } from "@emotion/react";
const TeamHeaderFragment = TeamHeaderFragmentFragmentDoc;
export const TeamHeader = _ref => {
  let {
    node,
    isNavExpanded,
    isActive,
    onRemoveTeamNav,
    onAddTeamNav,
    isSelectedTeam = true
  } = _ref;
  const teamColor = getTeamColor(node);
  const iconUrl = node?.displayIcon?.thumbnailUrl;
  const cmProps = useTeamContextMenu({
    id: node?.publicId ?? '',
    onRemoveTeamNav,
    onAddTeamNav
  });
  const content = isSelectedTeam ? ___EmotionJSX(Emojify, null, node?.name ?? '') : `${node?.name} is not in your team navigation. Right click to add.`;
  return ___EmotionJSX(Tooltip, {
    content: content,
    delay: 300
  }, ___EmotionJSX(Header, {
    contextMenuProps: cmProps,
    isNavExpanded: isNavExpanded,
    isActive: isActive,
    icon: ___EmotionJSX(TeamIcon, {
      iconUrl: iconUrl,
      size: 20,
      backgroundColor: teamColor,
      iconType: "new-team-icon"
    })
  }, ___EmotionJSX(HeaderText, {
    isHidden: !isNavExpanded
  }, ___EmotionJSX(Emojify, null, node?.name ?? ''))));
};
TeamHeader.displayName = "TeamHeader";