import { StatusIcon, getTokens } from '@useshortcut/shapes-ds';
import { WORKFLOW_STATE_TYPES } from '../../constants';
import { DeprecatedIconAdapter } from '../Icons';
import { jsx as ___EmotionJSX } from "@emotion/react";
const ICON = {
  [WORKFLOW_STATE_TYPES.BACKLOG]: 'Backlog',
  [WORKFLOW_STATE_TYPES.DONE]: 'Done',
  [WORKFLOW_STATE_TYPES.STARTED]: 'Started',
  [WORKFLOW_STATE_TYPES.UNSTARTED]: 'Unstarted'
};
export const StateIcon = _ref => {
  let {
    type
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: getTokens().space.space4
  }, ___EmotionJSX(StatusIcon, {
    icon: ICON[type]
  }));
};
StateIcon.displayName = "StateIcon";