import { Spaced } from '@clubhouse/shared/components/Spaced';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DetailPageSidebarContainer(_ref) {
  let {
    children
  } = _ref;
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, children);
}
DetailPageSidebarContainer.displayName = "DetailPageSidebarContainer";