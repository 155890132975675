import "core-js/modules/es.array.push.js";
import * as EditProfileTemplate from 'app/client/settingsShared/views/templates/editProfile.html';
import * as EditProfileFormTemplate from 'app/client/settingsShared/views/templates/editProfileForm.html';
import * as ServerMessageTemplate from 'app/client/core/views/templates/serverMessage.html';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import * as constants from '@clubhouse/shared/constants';
import { JoyToggle } from '../../../../../components/settings/JoyToggle';
import Async from '../../../core/js/modules/async';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import Format from '../../../core/js/modules/format';
import IconModel from '../../../core/js/models/icon';
import Is from '../../../core/js/modules/is';
import OrganizationModel from '../../../core/js/models/organization';
import ProfileModel from '../../../core/js/models/profile';
import User from '../../../core/js/modules/user';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import Layout from 'app/client/core/js/modules/layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const PARENT_SELECTOR = '#edit-profile-form';
const BUTTON_SELECTOR = PARENT_SELECTOR + ' button.action';
const CACHED_SHOW_PERSONALITY_ID = 'SHOW_PERSONALITY';
const PARENT_ICON_SELECTOR = '#edit-profile-icon-form';
exports.title = `Your Account | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return slug + '/settings/account';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = onComplete => {
  const withinOrg = Is.withinOrg();
  onComplete = typeof onComplete === 'function' ? onComplete : () => {};
  const html = EditProfileTemplate.render({
    subnav: withinOrg ? [] : SettingsPage.getSubnav(),
    active: 'editProfile',
    withinOrg
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  const fns = [UserModel.fetchCurrentUserWithoutRedirect];
  if (withinOrg) {
    fns.push(UserModel.fetchAll);
  }
  Async.eachInParallelThen(fns, () => {
    const user = User.getCurrentUser();
    const profile = ProfileModel.getCurrentUserProfileDetails();
    if (exports[CACHED_SHOW_PERSONALITY_ID] === undefined) {
      exports[CACHED_SHOW_PERSONALITY_ID] = user.joy_enabled;
    }
    View.renderWithComponents({
      template: EditProfileFormTemplate,
      templateMountNode: document.getElementById('waiting-for-profile-content'),
      templateData: {
        user,
        profile,
        primary_email: UserModel.getPreferredEmailAddress(),
        // Profile icon is only here for users that already have it set, and will be removed soon.
        display_icon: _.get(profile, 'display_icon') || user.display_icon,
        organization: OrganizationModel.getCurrent(),
        within_org: withinOrg,
        constants,
        showJoyToggle: true,
        isScimEnabled: OrganizationModel.isScimEnabled(OrganizationModel.getCurrent().id)
      },
      components: {
        joyToggle: {
          component: JoyToggle,
          props: {
            isEnabled: exports[CACHED_SHOW_PERSONALITY_ID],
            onChange: addJoy => exports[CACHED_SHOW_PERSONALITY_ID] = addJoy
          }
        }
      }
    });
    View.replaceElement('.waiting-for-content', html);
    Validator.init(PARENT_SELECTOR);
    SettingsPage.onDestroy('EditProfile', () => {
      Validator.destroy(PARENT_SELECTOR);
    });
    onComplete();
  });
};

// ----- Actions -----

exports.updateCurrentTime = function () {
  return $('#current-time').text(Format.currentTime(moment().utcOffset($(this).val())));
};
exports.submit = function (e) {
  e.preventDefault();
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  const profile = ProfileModel.getCurrentUserProfile();
  const fns = [];
  if (data.mention_name !== profile.mention_name) {
    const mention_name = data.mention_name === '' ? null : data.mention_name;
    fns.push(ProfileModel.updateProfile.bind(this, {
      mention_name
    }));
  }
  if (exports[CACHED_SHOW_PERSONALITY_ID] !== User.getCurrentUser().joy_enabled) {
    data.joy_enabled = exports[CACHED_SHOW_PERSONALITY_ID];
  }
  delete data.mention_name;
  fns.push(UserModel.updateUser.bind(this, data));
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    Async.eachInParallelThenWithShortCircuiting(fns, err => {
      if (!err) {
        FlashController.success(PARENT_SELECTOR, 'Profile Updated.');
        View.revertButtonState(BUTTON_SELECTOR, 'Save Changes');
        Event.trigger('profileUpdated');
      } else {
        View.revertButtonState(BUTTON_SELECTOR, 'Save Changes');
        const html = ServerMessageTemplate.render({
          className: 'error',
          iconLeft: 'fa-minus-circle',
          title: 'Unable to save changes.',
          message: err
        });
        context.find('.server-messages').hide().html(html).slideDown(200);
      }
    });
  }
  return false;
};
exports.openChooser = () => {
  $(PARENT_ICON_SELECTOR).find('.html-file-upload').click();
  return false;
};
const scrollToIconForm = () => {
  document.querySelector(PARENT_ICON_SELECTOR).scrollIntoView(false);
};
exports.saveIcon = files => {
  IconModel.updateUserIcon(_.head(files).id, (err, res) => {
    if (err) {
      FlashController.error(PARENT_ICON_SELECTOR, 'We were unable to save your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(PARENT_ICON_SELECTOR, 'New icon saved!');
        Layout.renderProfileFromCurrentUser();
        Event.trigger('profileUpdated');
        scrollToIconForm();
      });
    }
  });
  return false;
};
exports.renderInfoIcon = function () {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return View.renderComponentDelayed({
    componentKey: 'infoIcon' + id,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 30,
      fill: "var(--iconBlueColor)"
    }, ___EmotionJSX(Icon, {
      icon: "InfoCircle"
    }))
  }).html;
};
exports.renderWarnIcon = function () {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let fill = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'var(--iconYellowColor)';
  return View.renderComponentDelayed({
    componentKey: 'warnIcon' + id,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 30,
      fill: fill
    }, ___EmotionJSX(Icon, {
      icon: "Warn"
    }))
  }).html;
};
exports.renderLinkIcon = function () {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return View.renderComponentDelayed({
    componentKey: 'linkIcon' + id,
    component: ___EmotionJSX(DeprecatedIconAdapter, {
      width: 30
    }, ___EmotionJSX(Icon, {
      icon: "Link"
    }))
  }).html;
};
exports.renderUploadIcon = () => View.renderComponentDelayed({
  componentKey: 'uploadIcon',
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: 16,
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Upload"
  }))
}).html;
exports.renderSyncIcon = id => View.renderComponentDelayed({
  componentKey: 'syncIcon' + id,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    fill: "var(--iconGreenColor)"
  }, ___EmotionJSX(Icon, {
    icon: "Sync"
  }))
}).html;
exports.renderClearIcon = id => View.renderComponentDelayed({
  componentKey: 'clearIcon' + id,
  component: ___EmotionJSX(DeprecatedIconAdapter, null, ___EmotionJSX(Icon, {
    icon: "Clear"
  }))
}).html;
exports.renderPencilIcon = id => View.renderComponentDelayed({
  componentKey: 'pencilIcon' + id,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: 12,
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Edit"
  }))
}).html;
exports.renderTrashIcon = id => View.renderComponentDelayed({
  componentKey: 'trashIcon' + id,
  component: ___EmotionJSX(DeprecatedIconAdapter, {
    width: 14,
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Trash"
  }))
}).html;
exports.deleteIcon = () => {
  IconModel.updateUserIcon(null, (err, res) => {
    if (err) {
      FlashController.error(PARENT_ICON_SELECTOR, 'We were unable to delete your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(PARENT_ICON_SELECTOR, 'Icon deleted.');
        Layout.renderProfileFromCurrentUser();
        Event.trigger('profileUpdated');
        scrollToIconForm();
      });
    }
  });
  return false;
};
export { exports as default };