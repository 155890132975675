import { jsx as ___EmotionJSX } from "@emotion/react";
export const AvatarImage = _ref => {
  let {
    imageUrl,
    fullName,
    className
  } = _ref;
  return ___EmotionJSX("img", {
    src: imageUrl,
    className: className,
    alt: `${fullName} avatar`
  });
};
AvatarImage.displayName = "AvatarImage";