import { useState } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { Switch } from '@clubhouse/shared/components/Switch';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { useActiveSync } from 'data/entity/sync';
import { NO_TEAM_VALUE } from '../ImportDataPage';
import { CTAs, Description, SwitchLabel, SwitchWrapper, TeamSelect, Title, Wrapper } from './components';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const JiraSyncPage = _ref => {
  let {
    onClickComplete,
    importId,
    workflowId,
    onClickGoBack,
    createSync,
    shouldSync
  } = _ref;
  const [teamId, setTeamId] = useState();
  const activeSync = useActiveSync();
  const [isJiraSyncEnabled, setIsJiraSyncEnabled] = useState(shouldSync);
  const [isCreatingSync, setIsCreatingSync] = useState(false);
  const handleOnChangeTeam = ids => setTeamId(ids[0]);
  const handleCreateSync = async () => {
    if (!isJiraSyncEnabled) return;
    setIsCreatingSync(true);
    await createSync(teamId !== NO_TEAM_VALUE ? teamId : undefined, importId);
    setIsCreatingSync(false);
  };
  const handleOnClickComplete = async () => {
    await onClickComplete();
    await handleCreateSync();
  };
  return ___EmotionJSX(Wrapper, {
    className: "settings-pane settings-pane_react"
  }, ___EmotionJSX(Title, {
    className: "ignore-less-styles"
  }, "Turn on Jira Sync"), ___EmotionJSX(Description, {
    className: "ignore-less-styles"
  }, "JIRA Sync will keep Shortcut Stories in sync with Issues in your JIRA Project. After your historical data is imported, your Shortcut Workspace will sync with your JIRA project. New Stories created in your Workflow mapped to your JIRA Project will generate a new Issue in your JIRA project and new Issues created in the JIRA project will generate a new Story in Shortcut. As new Stories are created and move through Workflow states in Shortcut they will automatically be moved to the corresponding synced workflow state in JIRA.", ' ', ___EmotionJSX("a", {
    href: "https://help.shortcut.com/hc/en-us/articles/13375431277460"
  }, "Learn More")), ___EmotionJSX(SwitchWrapper, null, ___EmotionJSX(Switch.Label, null, ___EmotionJSX(SwitchLabel, null, "Jira Sync"), ___EmotionJSX(Tooltip, {
    content: "Only one sync can be enabled per workspace",
    disabled: !activeSync
  }, ___EmotionJSX("div", null, ___EmotionJSX(Switch, {
    onChange: setIsJiraSyncEnabled,
    isDisabled: Boolean(activeSync),
    isChecked: isJiraSyncEnabled
  }))))), ___EmotionJSX(Title, {
    className: "ignore-less-styles"
  }, "Select a Team below. Newly synced stories will be assigned to this team in ", BRAND.NAME, ":"), ___EmotionJSX(TeamSelect, {
    isDisabled: !isJiraSyncEnabled,
    onChange: handleOnChangeTeam,
    workflowId: workflowId,
    selectedValues: teamId ? [teamId] : []
  }), ___EmotionJSX(CTAs, null, ___EmotionJSX(Button, {
    kind: Button.KIND.SECONDARY,
    onClick: onClickGoBack
  }, "Go Back"), ___EmotionJSX(Button, {
    isDisabled: isCreatingSync,
    kind: Button.KIND.PRIMARY,
    onClick: handleOnClickComplete
  }, "Complete Import")));
};
JiraSyncPage.displayName = "JiraSyncPage";