import "core-js/modules/es.array.push.js";
import { getVelocityConfig } from 'data/entity/report';
import { getCurrentPage, getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendVelocityEvent } from 'utils/segment';
import { usesIterations } from 'utils/tests';
import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const Filters = _ref => {
  let {
    onChange
  } = _ref;
  const filters = [];
  ['vc_aggregate', 'vc_group_by'].forEach(key => {
    if (getCurrentPage() === 'reports' && key === 'vc_group_by') return;
    const {
      defaultValue,
      title,
      values
    } = getVelocityConfig(key);
    let items = values;
    let value = getParamFromUrl(key) || defaultValue;
    if (key === 'vc_group_by' && !usesIterations()) {
      items = items.filter(v => v.value !== 'iteration');
      if (value === 'iteration') {
        value = defaultValue;
        updateParamInUrl(key, defaultValue);
        onChange();
      }
    }
    filters.push(___EmotionJSX(ReportFilterDropdown, {
      key: key,
      configId: key,
      value: value,
      title: title,
      onChange: (configId, selection) => {
        sendVelocityEvent({
          configId,
          selection
        });
        updateParamInUrl(configId, selection);
        onChange();
      },
      items: items
    }));
  });
  return ___EmotionJSX(React.Fragment, null, filters);
};