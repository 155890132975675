import * as ColumnListNavTemplate from 'app/client/stories/views/templates/columnListNav.html';
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'components/shared/command-bar/helpers';
import { AddStoryButton } from 'components/shared/AddStoryButton';
import { NewStoryInput } from 'components/stories/NewStoryInput/NewStoryInput';
import { StoryColumnHeader } from 'components/stories/StoryColumnHeader/StoryColumnHeader';
import ApplicationState from '../../../core/js/modules/applicationState';
import Async from '../../../core/js/modules/async';
import ColumnModel from '../../../core/js/models/column';
import ContextMenuController from '../../../core/js/controllers/contextMenu';
import * as Event from '../../../core/js/_frontloader/event';
import Format from '../../../core/js/modules/format';
import HotReloadController from '../../../core/js/controllers/hotReload';
import Is from '../../../core/js/modules/is';
import Log from '../../../core/js/modules/log';
import SidebarController from './sidebar';
import StoriesColumnModel from '../models/storiesColumn';
import StoriesStoryController from './storiesStory';
import StoriesView from '../modules/storiesView';
import TeamModel from '../../../core/js/models/team';
import Url from '../../../core/js/modules/url';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import { jsx as ___EmotionJSX } from "@emotion/react";
const exports = {};
const EVENT_NS = '.ColumnController';
exports.initialized = false;
exports.COLUMN_NAV_TARGET = '#column-nav';
exports.reset = () => {
  if (!exports.initialized) {
    return false;
  }
  Log.debug('Columns: Resetting column UI');
  StoriesView.redrawAllColumns();
  exports.render();
  if (Is.mobile()) {
    exports.initVisibleMobileColumn();
  }
};
exports.render = () => {
  // This is executed during the spaceLoaded event, which can happen during a space restore.
  if (!exports.initialized) {
    return false;
  }
  Log.debug('Columns: Rendering column UI');
  exports.drawColumnListNav();
  StoriesView.drawMissingColumns();
  StoriesView.hideDisabledColumns();
  StoriesView.renameColumns();
  StoriesView.resetAndDrawStories();
};
exports.drawColumnListNav = () => {
  const columns = ColumnModel.allFromActiveWorkflow();
  const parentElement = $(exports.COLUMN_NAV_TARGET);
  const html = ColumnListNavTemplate.render({
    columns
  });
  parentElement.find('.column-selector-parent.ui-droppable').droppable('destroy');
  parentElement.html(html);
  exports.initNavDroppable();
  ContextMenuController.refresh();
  return false;
};
exports.showAllColumns = () => {
  StoriesColumnModel.showAll();
  exports.render();
  StoriesView.showColumnView();
  return false;
};
exports.hideAllColumns = () => {
  StoriesColumnModel.hideAll();
  exports.render();
  StoriesView.showColumnView();
  return false;
};
exports.remove = function () {
  const column = Utils.getModelFromContext(this);
  StoriesView.removeColumn(column);
  Event.trigger('spaceUpdate');
  return false;
};
exports.removeOnMiddleClick = function (e) {
  if (Utils.keyPressed(e, 'MIDDLE_CLICK')) {
    return exports.remove.call(this);
  }

  // Intentionally not returning false to continue event bubbling.
};
exports.toggleActiveState = function (e, options) {
  options = options || {};
  const column = Utils.getModelFromContext(this);
  if (e.shiftKey || options.force) {
    if (StoriesColumnModel.onlyCurrentColumnActive(column) && StoriesView.isInKanbanView()) {
      return exports.showAllColumns.call(this);
    } else {
      exports.highlight.call(this);
      Event.trigger('spaceUpdate');
      return false;
    }
  }
  if (column.active) {
    StoriesView.removeColumn(column);
  } else {
    StoriesView.addColumn(column, 1);
  }
  StoriesView.drawStories();
  Event.trigger('spaceUpdate');
  return false;
};
exports.highlight = function () {
  const column = Utils.getModelFromContext(this);
  ColumnModel.each(c => {
    c.active = c.id === column.id;
  });
  exports.render();
  return false;
};
exports.initNavDroppable = () => {
  $(`${exports.COLUMN_NAV_TARGET} .column-selector-parent`).droppable({
    hoverClass: 'drag-hover',
    drop: function (e, ui) {
      const element = ui.draggable[0];

      // Guard against sortable usage:
      if (!$(element).hasClass('story')) {
        return false;
      }
      const workflow_state_id = Utils.data(this, 'id');
      StoriesStoryController.updateOneOrManyOnDrop(element, {
        workflow_state_id
      });
    },
    tolerance: 'pointer'
  });
};
exports.scrollToColumn = $columnElement => {
  const windowWidth = $(window).width();
  const leftEdgeOfWindow = $('#content').offset().left;
  const content = $('#content');
  const friction = 0.3;
  const marginOffset = 36;
  let difference = 0;
  Async.callbackTimer({
    speed: 10,
    max: 400,
    fn: () => {
      const xPos = $columnElement.offset().left;
      const rightEdgeOfColumn = xPos + $columnElement.width() + marginOffset;
      const leftEdgeOfColumn = xPos - marginOffset;
      const scrollLeft = content.scrollLeft();
      if (rightEdgeOfColumn > windowWidth) {
        difference = (rightEdgeOfColumn - windowWidth) * friction;
        content.scrollLeft(scrollLeft + difference);
      } else if (leftEdgeOfColumn < leftEdgeOfWindow) {
        difference = (leftEdgeOfWindow - leftEdgeOfColumn) * friction;
        content.scrollLeft(scrollLeft - difference);
      }
    }
  });
};
exports.getColumnToLeft = column => {
  const element = StoriesColumnModel.toElement(column).prev('.column');
  if (element.length > 0) {
    const id = Utils.data(element, 'id');
    return ColumnModel.get({
      id
    });
  }
};
exports.getColumnToRight = column => {
  const element = StoriesColumnModel.toElement(column).next('.column');
  if (element.length > 0) {
    const id = Utils.data(element, 'id');
    return ColumnModel.get({
      id
    });
  }
};
function _resetColumnLength(column) {
  StoriesColumnModel.resetStoryLimitForColumn(column);
  const element = StoriesColumnModel.toElement(column);
  element.find('.story').each(function (i) {
    if (i > 30) {
      $(this).remove();
    }
  });
  $('body').css({
    minHeight: null
  });
}
exports.swipeLeft = () => {
  if (!SidebarController.isClosed()) {
    return false;
  }
  const currentColumn = exports.getVisibleColumn();
  const next = exports.getColumnToLeft(currentColumn);
  if (next) {
    exports.setVisibleMobileColumn(next);
    StoriesColumnModel.toElement(currentColumn).css({
      position: 'relative'
    }).animate({
      left: 200,
      opacity: 0
    }, 200, 'easeInQuad', function () {
      Utils.scrollToTop();
      $(this).css({
        position: 'static',
        left: 0,
        opacity: 1,
        display: 'none'
      });
      StoriesColumnModel.toElement(next).removeClass('mobile-hidden-column').addClass('mobile-visible-column').css({
        display: document.body.classList.contains('using-raven-nav') ? 'flex' : 'block',
        left: -200,
        opacity: 0,
        position: 'relative'
      }).animate({
        left: 0,
        opacity: 1
      }, 200, 'easeOutQuad', () => {
        _resetColumnLength(currentColumn);
        exports.setMobileColumnClass();
      });
      Event.trigger('updateVisibleColumn');
    });
  }
};
exports.swipeRight = () => {
  if (!SidebarController.isClosed()) {
    SidebarController.hide();
    return false;
  }
  const currentColumn = exports.getVisibleColumn();
  const next = exports.getColumnToRight(currentColumn);
  if (next) {
    exports.setVisibleMobileColumn(next);
    StoriesColumnModel.toElement(currentColumn).css({
      position: 'relative'
    }).animate({
      left: -200,
      opacity: 0
    }, 200, 'easeInQuad', function () {
      Utils.scrollToTop();
      $(this).css({
        position: 'static',
        left: 0,
        opacity: 1,
        display: 'none'
      });
      StoriesColumnModel.toElement(next).removeClass('mobile-hidden-column').addClass('mobile-visible-column').css({
        display: document.body.classList.contains('using-raven-nav') ? 'flex' : 'block',
        left: 200,
        opacity: 0,
        position: 'relative'
      }).animate({
        left: 0,
        opacity: 1
      }, 200, 'easeOutQuad', () => {
        _resetColumnLength(currentColumn);
        exports.setMobileColumnClass();
      });
      Event.trigger('updateVisibleColumn');
    });
  }
};
exports.getVisibleColumn = () => {
  const activeTeam = TeamModel.getActive();
  const id = ApplicationState.get('stories.visibleColumn.' + activeTeam.id);
  const foundColumn = id ? ColumnModel.getById(Number.parseInt(id, 10)) : false;
  return foundColumn || ColumnModel.firstActiveFromActiveWorkflow() || ColumnModel.firstFromActiveWorkflow();
};
exports.setVisibleMobileColumn = visibleColumn => {
  const activeTeam = TeamModel.getActive();
  ApplicationState.set('stories.visibleColumn.' + activeTeam.id, visibleColumn.id);
  ColumnModel.each(column => {
    column.visibleOnMobile = column.id === visibleColumn.id;
  });
};
exports.initVisibleMobileColumn = () => {
  const activeTeam = TeamModel.getActive();
  let visibleColumn = exports.getVisibleColumn();
  const columnNotFromActiveTeam = _.get(ColumnModel.getTeamFromColumn(visibleColumn), 'id') !== activeTeam.id;
  if (!visibleColumn || visibleColumn.active === false || columnNotFromActiveTeam) {
    visibleColumn = ColumnModel.firstActiveFromActiveWorkflow() || ColumnModel.firstFromActiveWorkflow();
    exports.setVisibleMobileColumn(visibleColumn);
  }
  ColumnModel.each(column => {
    column.visibleOnMobile = column.id === visibleColumn.id;
  });
  exports.setMobileColumnClass();
};
exports.setMobileColumnClass = () => {
  const visibleColumn = exports.getVisibleColumn();
  $('.column').each(function () {
    if (Utils.data(this, 'id') === visibleColumn.id) {
      $(this).removeClass('mobile-hidden-column').addClass('mobile-visible-column');
    } else {
      $(this).addClass('mobile-hidden-column').removeClass('mobile-visible-column');
    }
  });
};
exports.generalContextMenuItems = () => {
  return [{
    name: 'All',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: 'Show All Columns',
    value: exports.showAllColumns
  }, {
    name: 'Edit Workflow',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Settings"
    }))),
    value: () => HotReloadController.loadURL(Url.getSlugPath() + '/settings/workflow', 'settings')
  }];
};
exports.contextMenuItems = function () {
  const column = Utils.getModelFromContext(this);
  const showAll = StoriesColumnModel.onlyCurrentColumnActive(column);
  return [{
    name: showAll ? 'All' : 'Only',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "View"
    }))),
    tooltip: showAll ? 'Show all States' : 'Show only this State',
    tooltipShortcut: '(shift-click)',
    value: target => exports.toggleActiveState.call(target, column, {
      force: true
    })
  }, {
    name: 'View State on Search page',
    customIcon: renderComponentToString(___EmotionJSX(DeprecatedIconAdapter, {
      width: 15
    }, ___EmotionJSX(Icon, {
      icon: "Zoom"
    }))),
    value: () => {
      _redirectToSearch(column);
      return false;
    }
  }];
};
function _redirectToSearch(column) {
  HotReloadController.loadURL(Url.getSlugPath() + '/search#state:' + column.id, 'search');
}
exports.columnSidebarTooltip = function () {
  const column = Utils.getModelFromContext(this);
  return `<div style="display:flex; gap:4px; align-items:center;">${ColumnModel.renderStateIconFromStateType(column.type ?? 'unstarted')}${Format.sanitize(column.name)}</div>`;
};
const showNewStoryInput = (columnId, context) => {
  const columnElement = document.querySelector(`.column[data-id="${columnId}"]`);
  columnElement.classList.add('open-cta');
  columnElement.dataset.actionContext = context;
  columnElement.querySelector('.new-story-cta-container textarea')?.focus();
};
const hideNewStoryInput = columnId => {
  const columnElement = document.querySelector(`.column[data-id="${columnId}"]`);
  columnElement?.classList.remove('open-cta');
  delete columnElement?.dataset.actionContext;
};
exports.renderNewStoryInput = columnId => View.renderComponentDelayed({
  componentKey: `new-story-input-${columnId}`,
  component: ___EmotionJSX(NewStoryInput, {
    workflowStateId: columnId,
    onCancel: () => {
      hideNewStoryInput(columnId);
    },
    getActionContext: () => {
      return document.querySelector(`.column[data-id="${columnId}"]`)?.dataset.actionContext;
    }
  })
}).html;
const onAddStoryButtonClick = columnId => {
  showNewStoryInput(columnId, 'stories - column button');
};
exports.renderEmptyColumnState = columnId => {
  return View.renderComponentDelayed({
    componentKey: 'emptyColumnState' + columnId,
    component: ___EmotionJSX(AddStoryButton, {
      onClick: () => {
        onAddStoryButtonClick(columnId);
      }
    })
  }).html;
};
exports.renderColumnHeader = columnId => View.renderComponentDelayed({
  componentKey: 'column-header' + columnId,
  component: ___EmotionJSX(StoryColumnHeader, {
    onAddButtonClick: () => {
      showNewStoryInput(columnId, 'stories - column header');
    },
    columnId: columnId
  })
}).html;
exports.init = () => {
  exports.initialized = true;
  exports.reset();
  Event.onlyOn('teamSelectedInSidebar' + EVENT_NS, exports.reset);
  if (Is.mobile()) {
    Event.on('swipeLeft', exports.swipeRight);
    Event.on('swipeRight', exports.swipeLeft);
  }
};
export { exports as default };