import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TooltipText, TooltipTitle } from '@clubhouse/shared/components/Tooltip';
import { useSlot, withSlot } from '@clubhouse/shared/hooks';
import { jsx as ___EmotionJSX } from "@emotion/react";
const _TooltipLayout = _ref => {
  let {
    title,
    text,
    children
  } = _ref;
  const IconTemplate = useSlot({
    children,
    name: 'Icon'
  });
  return ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space2"
  }, ___EmotionJSX(IconTemplate, null), ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, ___EmotionJSX(TooltipTitle, null, title), ___EmotionJSX(TooltipText, null, text)));
};
_TooltipLayout.displayName = "_TooltipLayout";
export const TooltipLayout = withSlot(_TooltipLayout);