import { QueryWorkspace2CanonicalizeEpicsPageFilterStateDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useTranslateUrl } from 'gql/hooks/useTranslateUrl';
import { EpicsPageFilterConfig } from '../../../components/filters/filterConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
const QUERY_EPICS_FILTER_STATE = QueryWorkspace2CanonicalizeEpicsPageFilterStateDocument;
const searchParamsToFieldLookup = {
  [EpicsPageFilterConfig.groupIds.paramName]: 'teamIds',
  [EpicsPageFilterConfig.labelIds.paramName]: 'labelIds',
  [EpicsPageFilterConfig.milestoneIds.paramName]: 'objectiveIds',
  [EpicsPageFilterConfig.ownerIds.paramName]: 'ownerIds',
  [EpicsPageFilterConfig.projectIds.paramName]: 'projectIds',
  [EpicsPageFilterConfig.stateIds.paramName]: 'epicStateIds'
};
export const TranslateEpicsPageSearchParams = _ref => {
  let {
    children
  } = _ref;
  const {
    isReady
  } = useTranslateUrl({
    query: QUERY_EPICS_FILTER_STATE,
    searchParamsToFieldLookup
  });
  if (!isReady) return null;
  return ___EmotionJSX(React.Fragment, null, children);
};