import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { TableDatePicker } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import { saveChanges } from 'data/entity/story';
import { isoDateToTimestamp, timestampToIsoDate } from 'utils/date';
import { storyDeadline } from 'utils/sort';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DueDateFieldComponent(_ref) {
  let {
    entity
  } = _ref;
  const [deadline, handleChange] = useInlineEditing(timestampToIsoDate(entity.deadline) ?? undefined, date => saveChanges(entity.id, {
    deadline: isoDateToTimestamp(date) ?? undefined
  }));
  return ___EmotionJSX(TableDatePicker, {
    colorizeDueDate: entity.stateObject?.type !== 'done',
    minDate: timestampToIsoDate(entity.created_at),
    onChange: handleChange,
    value: timestampToIsoDate(deadline ?? null)
  });
}
DueDateFieldComponent.displayName = "DueDateFieldComponent";
export const DueDateField = () => ({
  name: FIELD_ID.DUE_DATE,
  displayName: 'Due Date',
  headerProps: {
    centered: true
  },
  sort: storyDeadline,
  Component: DueDateFieldComponent,
  width: 130
});