import { CustomFieldIcon, Icon, SeverityIcon } from '@useshortcut/shapes-ds';
import { getTint } from '@clubhouse/shared/color';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { SEVERITY_ICONS } from '@clubhouse/shared/utils/customFields';
import { isCompatibleCustomFieldIconSet } from 'utils/customField';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const serializeCustomField = fieldId => valueId => `${fieldId}@${valueId}`;
export const deserializeCustomField = fieldAndValue => {
  const [fieldId, valueId] = fieldAndValue.split('@');
  return {
    fieldId,
    valueId
  };
};
export function getIcon(canonicalName, colorKey, position, iconSetIdentifier) {
  if (canonicalName === 'severity' && typeof position === 'number') {
    return () => ___EmotionJSX(DeprecatedIconAdapter, null, ___EmotionJSX(SeverityIcon, {
      icon: SEVERITY_ICONS[position]
    }));
  }
  if (canonicalName === 'productArea') {
    return () => ___EmotionJSX(DeprecatedIconAdapter, {
      fill: getTint(colorKey)
    }, ___EmotionJSX(Icon, {
      icon: "FeatureArea"
    }));
  }
  if (canonicalName === 'technicalArea') {
    return () => ___EmotionJSX(DeprecatedIconAdapter, {
      fill: getTint(colorKey)
    }, ___EmotionJSX(Icon, {
      icon: "TechnicalArea"
    }));
  }
  if (canonicalName === 'skillSet') {
    return () => ___EmotionJSX(DeprecatedIconAdapter, {
      fill: getTint(colorKey)
    }, ___EmotionJSX(Icon, {
      icon: "Skillset"
    }));
  }
  if (isCompatibleCustomFieldIconSet(iconSetIdentifier)) {
    return () => ___EmotionJSX(DeprecatedIconAdapter, {
      fill: getTint(colorKey)
    }, ___EmotionJSX(CustomFieldIcon, {
      icon: iconSetIdentifier
    }));
  }
  return () => ___EmotionJSX(DeprecatedIconAdapter, {
    fill: getTint(colorKey)
  }, ___EmotionJSX(Icon, {
    icon: "Field"
  }));
}