import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from './DeprecatedIconAdapter';

/**
 * @deprecated Use <Icon icon="Export" /> from @useshortcut/shapes-ds
 */
import { jsx as ___EmotionJSX } from "@emotion/react";
export const ExportImage = _ref => {
  let {
    fill,
    width = '12px'
  } = _ref;
  return ___EmotionJSX(DeprecatedIconAdapter, {
    width: width,
    fill: fill
  }, ___EmotionJSX(Icon, {
    icon: "Export"
  }));
};
ExportImage.displayName = "ExportImage";