import Logo from '@clubhouse/assets/png/third-party-logos/loom-logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Loom() {
  const readOnly = isReadOnly();
  return ___EmotionJSX(Layout, null, ___EmotionJSX(Layout.Nav, {
    currentActive: INTEGRATION_TYPE.LOOM
  }), ___EmotionJSX(Layout.Content, null, ___EmotionJSX(Layout.Breadcrumbs, {
    name: "Loom"
  }), ___EmotionJSX(Layout.Logo, {
    src: Logo,
    alt: "Loom Logo",
    invertOnDarkMode: true
  }), ___EmotionJSX(Layout.Blurb, null, "Users can paste videos from ", ___EmotionJSX(ExternalLink, {
    href: "https://www.loom.com"
  }, "Loom"), " to see real-time previews in markdown editors, including comments, to help explain complex ideas with pre-recorded video messages. The Loom Integration requires no set up - it should work out of the box with any shareable link from Loom."), !readOnly && ___EmotionJSX(Layout.HelpMessage, null, ___EmotionJSX(ExternalLink, {
    href: KNOWLEDGE_BASE_SLUGS.INTEGRATION_LOOM
  }, "Learn more"), " on how Loom embeds work in Shortcut.")));
}
Loom.displayName = "Loom";