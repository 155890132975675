import { Button } from '@clubhouse/shared/components/Button';
import { Dialog, DialogText, DialogTitle } from '@clubhouse/shared/components/Dialog';
import { PLAN_TABLE_TRIGGER_LOCATIONS, sendPlanTableOpenedEvent } from 'components/settings/manage-billing/tracking';
import { openUpgradeDialog } from 'utils/manageBillingController';
import { useEntitlementViolationMessage } from 'utils/useEntitlementViolation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const EntitlementViolationDialog = () => {
  const {
    message: violation,
    clear: clearViolation
  } = useEntitlementViolationMessage();
  return ___EmotionJSX(EntitlementViolationDialogComponent, {
    violation: violation,
    clearViolation: clearViolation
  });
};
EntitlementViolationDialog.displayName = "EntitlementViolationDialog";
const EntitlementViolationDialogComponent = _ref => {
  let {
    violation,
    clearViolation = () => {}
  } = _ref;
  const message = violation?.message ?? ___EmotionJSX(React.Fragment, null, "You attempted to use a feature that your current plan does not allow.", ___EmotionJSX("strong", null, " Upgrade"), " to a higher tier to get access to this feature.");
  const onCancel = () => {
    // Clear error message to close dialog
    clearViolation();
  };
  const onConfirm = () => {
    if (violation.triggerLocation) {
      sendPlanTableOpenedEvent(violation.triggerLocation, {
        violation
      });
    } else {
      // send the violation without a trigger location to tracking to parse
      sendPlanTableOpenedEvent(PLAN_TABLE_TRIGGER_LOCATIONS.UNKNOWN, {
        violation
      });
    }
    openUpgradeDialog();
    clearViolation();
  };
  const ActionButtons = _ref2 => {
    let {
      onClose
    } = _ref2;
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Button, {
      kind: Button.KIND.SECONDARY,
      onClick: onClose
    }, "Cancel"), ___EmotionJSX(Button, {
      kind: Button.KIND.PRIMARY,
      onClick: onConfirm
    }, "Upgrade"));
  };
  return ___EmotionJSX(Dialog, {
    visible: !!violation,
    onClose: onCancel,
    ActionComponent: ActionButtons
  }, ___EmotionJSX(DialogTitle, null, "Upgrade your plan"), ___EmotionJSX(DialogText, null, message));
};
EntitlementViolationDialogComponent.displayName = "EntitlementViolationDialogComponent";