import { Icon } from '@useshortcut/shapes-ds';
import { Avatar, AvatarWithoutProfile } from '@clubhouse/shared/components/Avatar';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { acceptInvite, usePendingInvite } from 'data/entity/invite';
import { PAGE_NAMES, redirect } from 'utils/navigation';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function Invite(_ref) {
  let {
    id
  } = _ref;
  const invite = usePendingInvite(id);
  const {
    addToast
  } = useToast();
  if (!invite) return null;
  const {
    name,
    url_slug
  } = invite.organization;
  const onAcceptInvite = async () => {
    try {
      const {
        organization
      } = await acceptInvite(id);
      redirect(`/${organization.url_slug}/${PAGE_NAMES.STORIES}`);
    } catch {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => ___EmotionJSX(ToastText, null, "Unable to join workspace.")
      });
    }
  };
  return ___EmotionJSX("div", {
    className: "org pending"
  }, ___EmotionJSX("div", {
    className: "title permission"
  }, ___EmotionJSX("h3", null, name), ___EmotionJSX("span", {
    className: "tagged green"
  }, ___EmotionJSX("span", {
    className: "align-center"
  }, ___EmotionJSX(DeprecatedIconAdapter, {
    width: 12,
    fill: "currentColor"
  }, ___EmotionJSX(Icon, {
    icon: "Mail"
  })), ' ', "Invited")), ___EmotionJSX("button", {
    className: "action micro green",
    onClick: onAcceptInvite
  }, "Join!")), ___EmotionJSX("div", {
    className: "details"
  }, ___EmotionJSX("ul", {
    className: "info"
  }, ___EmotionJSX("li", null, ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "space1",
    align: "center"
  }, ___EmotionJSX(AvatarWithoutProfile, {
    id: invite.created_by.id,
    size: Avatar.SIZE.S,
    fullName: invite.created_by.name,
    imageUrl: invite.created_by.display_icon?.url
  }), ___EmotionJSX(Text, {
    size: Text.SIZE.SMALL
  }, ___EmotionJSX("strong", null, invite.created_by.name), " invited you to join this Workspace!"))), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "URL:"), ' ', ___EmotionJSX("a", {
    href: `/${url_slug}/`,
    className: "url-slug"
  }, "https://", BRAND.DOMAIN_APP_WEBSITE, "/", url_slug)), ___EmotionJSX("li", null, ___EmotionJSX("strong", null, "Email:"), " ", invite.email))));
}
Invite.displayName = "Invite";