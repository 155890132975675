// This function sorts objects (any object containing mentionName and name) based on the query.
// If an object's mention name or name matches the query, it will be sorted first. Otherwise, it will fallback to alphabetical sorting.
// If multiple objects match the query, it will sort those objects depending on where in the name the query appears. Earlier in the query is placed first.
// Eg: Stone is sorted before Asteroid if the query is "st".
export const sortMentions = query => (a, b) => {
  if (query) {
    const am1 = a.mentionName.toLowerCase().indexOf(query);
    const am2 = a.name.toLowerCase().indexOf(query);
    const bm1 = b.mentionName.toLowerCase().indexOf(query);
    const bm2 = b.name.toLowerCase().indexOf(query);
    const am = am1 !== -1 && am2 !== -1 ? Math.min(am1, am2) : Math.max(am1, am2);
    const bm = bm1 !== -1 && bm2 !== -1 ? Math.min(bm1, bm2) : Math.max(bm1, bm2);
    if (am !== bm) {
      if (am !== -1 && bm !== -1) return am - bm;
      if (am !== -1) return -1;
      if (bm !== -1) return 1;
    }
  }
  const res = a.mentionName.localeCompare(b.mentionName);
  if (res !== 0) return res;
  return a.name.localeCompare(b.name);
};