import { Button } from '@clubhouse/shared/components/Button';
import { CopyValue } from '@clubhouse/shared/components/CopyValue';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { TextInput } from '@clubhouse/shared/components/TextInput';
import { Layout } from './Layout';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const CopyWebhookUrl = _ref => {
  let {
    label,
    installation
  } = _ref;
  const {
    copyValue,
    tooltipVisible
  } = CopyValue.useCopyWithTooltip(installation.webhook_url);
  return ___EmotionJSX(Spaced, {
    vertically: true,
    amount: "space2"
  }, ___EmotionJSX(Spaced, {
    horizontally: true,
    amount: "max",
    align: "center"
  }, ___EmotionJSX("div", {
    style: {
      marginBottom: -10
    }
  }, ___EmotionJSX(Layout.SectionTitle, null, label)), ___EmotionJSX(CopyValue.Tooltip, {
    visible: tooltipVisible
  }, ___EmotionJSX(Button, {
    kind: Button.KIND.TERTIARY,
    size: Button.SIZE.SMALL,
    fit: Button.FIT.SMALL,
    IconRight: () => ___EmotionJSX(SizedIcon, {
      icon: "Copy",
      size: 14
    }),
    onClick: () => copyValue()
  }, "Copy to clipboard"))), ___EmotionJSX(TextInput, {
    value: installation.webhook_url,
    isReadOnly: true
  }));
};
CopyWebhookUrl.displayName = "CopyWebhookUrl";