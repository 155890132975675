import { createContext, useContextSelector } from 'use-context-selector';
import { jsx as ___EmotionJSX } from "@emotion/react";
const Context = createContext({
  mutationContext: {}
});
export const FieldContextProvider = _ref => {
  let {
    children,
    value
  } = _ref;
  return ___EmotionJSX(Context.Provider, {
    value: value
  }, children);
};
FieldContextProvider.displayName = "FieldContextProvider";
export const useMutationContext = () => useContextSelector(Context, v => v.mutationContext);