import { NavigationProvider } from './NavigationProvider';
import { useClearBackgroundLocationStateOnPageRefresh } from './backgroundLocationState';
import { useLocationInternal } from './useLocationInternal';
import { jsx as ___EmotionJSX } from "@emotion/react";
export function StatefulNavigationProvider(_ref) {
  let {
    children
  } = _ref;
  useClearBackgroundLocationStateOnPageRefresh();
  const location = useLocationInternal();
  return ___EmotionJSX(NavigationProvider, {
    location: location
  }, children);
}
StatefulNavigationProvider.displayName = "StatefulNavigationProvider";