import { CopyInput } from '@clubhouse/shared/components/CopyInput';
import { jsx as ___EmotionJSX } from "@emotion/react";
export const StoryId = _ref => {
  let {
    story
  } = _ref;
  return ___EmotionJSX("div", {
    className: "attribute story-id"
  }, ___EmotionJSX("span", {
    className: "name"
  }, "Story ID"), ___EmotionJSX(CopyInput, {
    value: story.id,
    className: "clipboard"
  }));
};
StoryId.displayName = "StoryId";